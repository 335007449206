import type { VisibilityState, OnChangeFn } from '@tanstack/react-table';
import { useCallback, useMemo, useState } from 'react';

import { saveDeepDataToStorage, getDeepDataFromStorage } from '@acadeum/helpers';

export function useColumnVisibility(tableId?: string) {
  const key = useMemo(() => `AcadeumTable.${tableId}.columnVisibility`, [tableId]);

  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(() => ((tableId && getDeepDataFromStorage(key)) || {}));

  const onColumnVisibilityChange: OnChangeFn<VisibilityState> = useCallback((updaterOrValue) => {
    const value = typeof updaterOrValue === 'function' ? updaterOrValue(columnVisibility) : updaterOrValue;
    setColumnVisibility(value);
    if (tableId) {
      saveDeepDataToStorage(key, value);
    }
  }, [columnVisibility, setColumnVisibility, tableId]);

  return {
    columnVisibility,
    onColumnVisibilityChange
  };
}
