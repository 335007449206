import PropTypes from 'prop-types';
import React from 'react';

import Collapsible from '../Collapsible';

export function instantSearchCollapsible(options = {}) {
  return function instantSearchCollapsible(component) {
    class InstantSearchCollapsible extends React.Component {
      state = {};

      onToggle = (isExpanded) => this.setState({ isExpanded });

      render() {
        const {
          expanded,
          title,
          collapsibleClassName,
          ...rest
        } = this.props;

        const { isExpanded } = this.state;

        if (!isExpanded) {
          rest['aria-hidden'] = true;
        }

        return (
          <Collapsible
            title={title}
            isOpen={expanded === true}
            onToggle={this.onToggle}
            className={collapsibleClassName}
            overflow={options.overflow ? 'visible' : undefined}>
            {React.createElement(component, rest)}
          </Collapsible>
        );
      }
    }

    InstantSearchCollapsible.propTypes = {
      collapsibleClassName: PropTypes.string,
      expanded: PropTypes.bool,
      title: PropTypes.string.isRequired
    };

    return InstantSearchCollapsible;
  };
}

export default function (component) {
  return instantSearchCollapsible()(component);
}
