import classNames from 'classnames';
import React from 'react';

import type { SegmentedControlProps } from '../SegmentedControl';
import {
  SegmentedControl,
  SegmentedControlItem
} from '../SegmentedControl';

import styles from './NavSegmented.module.scss';

interface Option<T> {
  value: T;
  label: string;
}

export type NavSegmentedProps<T> = SegmentedControlProps & {
  options: Option<T>[];
}

export const NavSegmented = <T extends string>({
  className,
  options,
  ...rest
}: NavSegmentedProps<T>) => {
  return (
    <SegmentedControl
      {...rest}
      className={classNames(styles.root, className)}
    >
      {options.map(option => (
        <SegmentedControlItem
          key={option.value}
          value={option.value}
        >
          {option.label}
        </SegmentedControlItem>
      ))}
    </SegmentedControl>
  );
};
