import formatCountry_ from 'common-lib/lib/formatCountry';
import formatCountryRegion_ from 'common-lib/lib/formatCountryRegion';
import formatCourseLevel_ from 'common-lib/lib/formatCourseLevel';
import formatCurrency_ from 'common-lib/lib/formatCurrency';
import formatDate_ from 'common-lib/lib/formatDate';
import formatEthnicity_ from 'common-lib/lib/formatEthnicity';
import formatGender_ from 'common-lib/lib/formatGender';
import formatRaces_ from 'common-lib/lib/formatRaces';
import formatStudentLevel_ from 'common-lib/lib/formatStudentLevel';
import formatYesOrNo_ from 'common-lib/lib/formatYesOrNo';

import formatTerm_ from 'common-lib/lib/formatTerm';
import formatNumber_ from 'common-lib/lib/formatNumber';
import formatPercent_ from 'common-lib/lib/formatPercent';

export { default as formatPhone } from 'common-lib/lib/formatPhone';

export { default as formatName } from 'common-lib/lib/formatName';
export { default as formatUserName } from 'common-lib/lib/formatUserName';
import COUNTRY_REGIONS from 'common-lib/constants/countryRegions.json';

import { getCountryRegionLabel as getCountryRegionLabel_ } from '@acadeum/helpers';

import { t, getTranslationLanguage } from '../translate';

export function formatYesOrNo(value: string): string {
  return formatYesOrNo_(value, { language: getTranslationLanguage() });
}

export function formatCountry(country: string): string {
  return formatCountry_(country, { language: getTranslationLanguage() });
}

export function formatCountryRegion(country: string, region: string): string {
  return formatCountryRegion_(country, region, { language: getTranslationLanguage() });
}

export function formatGender(gender: string): string {
  return formatGender_(gender, { language: getTranslationLanguage() });
}

export function formatStudentLevel(level: string): string {
  return formatStudentLevel_(level, { language: getTranslationLanguage() });
}

export function formatCourseLevel(level: string): string {
  return formatCourseLevel_(level, { language: getTranslationLanguage() });
}

export function formatTerm(term: string): string {
  return formatTerm_(term, { language: getTranslationLanguage() });
}

export function formatRaces(races: string | string[]): string | string[] {
  return formatRaces_(races, {
    language: getTranslationLanguage(),
    own: true
  });
}

export function formatEthnicity(ethnicity: unknown): unknown {
  return formatEthnicity_(ethnicity, { language: getTranslationLanguage(), own: true });
}

export function formatDate(value: Date, options: { month?: string, utc?: boolean, year?: boolean }): string {
  return formatDate_(value, {
    ...options,
    locale: getTranslationLanguage()
  });
}

export function formatCurrency(value: number, options?: { currency?: string, cents?: boolean, fractional?: boolean }): string {
  return formatCurrency_(value, {
    ...options,
    locale: getTranslationLanguage()
  });
}

export function formatNumber(value, options) {
  return formatNumber_(value, {
    ...options,
    locale: getTranslationLanguage()
  });
}

export function formatPercent(value, options?) {
  return formatPercent_(value, {
    ...options,
    locale: getTranslationLanguage()
  });
}

export function getCountryRegionLabel(country) {
  return getCountryRegionLabel_(country);
}

export function formatReason(reason, reasonNotes, messagesPath) {
  if (reason) {
    if (reason === 'OTHER') {
      return reasonNotes;
    }
    return t(`${messagesPath}.options.${reason}`);
  }
}

export function getCountryRegionOptions(country) {
  let options: { label: string, value: string }[] = [];
  if (COUNTRY_REGIONS[country]) {
    options = options.concat(COUNTRY_REGIONS[country].map((region) => ({
      label: region,
      value: region
    })));
  } else {
    options.push({
      label: 'N/A',
      value: ''
    });
  }
  return options;
}
