import type { FC } from 'react';
import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import formatUserName from 'common-lib/lib/formatUserName';

import type { FetchUserOutput, UseUpdateUserMutation } from '@acadeum/api';
import { getAuthSelector } from '@acadeum/auth';
import { canEditUser, getErrorData, isAcadeumAdministrator } from '@acadeum/helpers';
import { useTranslate } from '@acadeum/translate';
import type { UserProfile, UserRole } from '@acadeum/types';
import { Col, ContentSection, Form, FormField, InstitutionAutocomplete, Row, StickyFormFooter, toast } from '@acadeum/ui';

import styles from './EditUserForm.module.scss';

interface UserFormData {
  email?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  institutionId?: number;
  phone: string;
  phoneExt: string;
  title: string;
  roleIds: number[];
  sftp?: boolean;
  courseImport?: boolean;
}

interface FormValues {
  formAction?: string;
  groupOrOfficeAccount?: boolean;
  title: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  email: string;
  phone: string;
  phoneExt: string;
  institutionId: number;
  roleIds: number;
  systemRoles: string[];
  financialRoles?: string | string[];
  sftp?: boolean;
  courseImport?: boolean;
}

type FormValuesWithOptionalEmailAndWithoutRolesAndWithType =
  Omit<FormValues, 'email' | 'roleIds' | 'groupOrOfficeAccount'>
  & {
    email?: string;
    type: 'GROUP' | 'INDIVIDUAL';
  }

interface EditUserFormProps {
  user: FetchUserOutput;
  roles: Pick<UserRole, 'id' | 'name' | 'description'>[];
  useUpdateUserMutation: UseUpdateUserMutation;
}

export const EditUserForm: FC<EditUserFormProps> = ({
  user,
  roles,
  useUpdateUserMutation
}) => {
  const t = useTranslate('shared-admin-ui.EditUserForm');

  const currentUser = useSelector(getAuthSelector('user'));

  const [updateUserMutation] = useUpdateUserMutation();

  const onSubmit = async (originalValues: FormValues) => {
    try {
      const { roleIds, ...values_ } = originalValues;

      // Set user `type` depending on the "Group or Office Account" switch value.
      const type = values_.groupOrOfficeAccount ? 'GROUP' : 'INDIVIDUAL';
      delete values_.groupOrOfficeAccount;

      const values: FormValuesWithOptionalEmailAndWithoutRolesAndWithType = {
        ...values_,
        type
      };

      if (!isAcadeumAdministrator(currentUser)) {
        delete values.email;
      }

      delete values.formAction;

      const data: UserFormData = isAcadeumAdministrator(currentUser) ? {
        ...values,
        roleIds: [roleIds],
        institutionId: values.institutionId ? values.institutionId :  user.institution.id
      } : {
        ...values,
        roleIds: [roleIds]
      };

      await updateUserMutation({
        id: user.id,
        ...data
      }).unwrap();
      toast.success(t('successMessage', {
        userName: `${formatUserName(user)}'s`
      }));
    } catch (error) {
      const { message } = getErrorData(error);
      toast.error(message);
    }
  };

  const defaultValues = useMemo(() => {
    return {
      ...user,
      groupOrOfficeAccount: !user.firstName && !user.lastName,
      institutionId: user.institution.id,
      sftp: Boolean(user.sftp)
    };
  }, [user]);

  return (
    <Form defaultValues={defaultValues} className={styles.EditUserForm} onSubmit={onSubmit}>
      <EditUserFormContent
        roles={roles}
        user={user}
        currentUser={currentUser}
      />
    </Form>
  );
};

type EditUserFormContentProps = Pick<EditUserFormProps, 'roles' | 'user'> & {
  currentUser?: UserProfile;
}

const EditUserFormContent = ({
  roles,
  user,
  currentUser
}: EditUserFormContentProps) => {
  const t = useTranslate('shared-admin-ui.EditUserForm');

  const { reset, formState: { isDirty }, watch } = useFormContext();

  const groupOrOfficeAccount = watch('groupOrOfficeAccount');

  const canUpdateUser = useMemo(() => {
    return canEditUser('update', {
      user,
      byUser: currentUser,
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      userInstitutionId: currentUser?.institution.id!
    });
  }, [user, currentUser]);

  const roleOptions = useMemo(() => {
    return roles.map((role) => ({
      value: role.id,
      label: role.name,
      description: role.description
    }));
  }, [roles]);

  return (
    <>
      <ContentSection title={t('accountProperties')}>
        <FormField
          readOnly={!canUpdateUser}
          type="switch"
          name="groupOrOfficeAccount"
          label={t('groupAccount')}
        />
      </ContentSection>
      <ContentSection title={t('personalInformation')}>
        <Row>
          {!groupOrOfficeAccount && (
            <>
              <Col md={6}>
                <FormField
                  required
                  readOnly={!canUpdateUser}
                  name="firstName"
                  label={t('firstName')}
                  placeholder={t('firstNamePlaceholder')}
                />
              </Col>
              <Col md={6}>
                <FormField
                  required
                  readOnly={!canUpdateUser}
                  name="lastName"
                  label={t('lastName')}
                  placeholder={t('lastNamePlaceholder')}
                />
              </Col>
              <Col md={6}>
                <FormField
                  readOnly={!canUpdateUser}
                  name="middleName"
                  label={t('middleName')}
                />
              </Col>
            </>
          )}
          <Col md={6}>
            <FormField
              required
              readOnly={!canUpdateUser}
              name="title"
              label={t('userTitle')}
            />
          </Col>
          <Col md={groupOrOfficeAccount ? 6 : 12}>
            <FormField
              required
              readOnly
              name="email"
              label={t('email')}
              placeholder={t('emailPlaceholder')}
            />
          </Col>
          <Col md={6}>
            <FormField
              required
              readOnly={!canUpdateUser}
              type="phone"
              name="phone"
              label={t('phone')}
            />
          </Col>
          <Col md={6}>
            <FormField
              readOnly={!canUpdateUser}
              name="phoneExt"
              label={t('phoneExt')}
            />
          </Col>
        </Row>
      </ContentSection>
      <ContentSection title={t('role', { global: true })}>
        <FormField
          required
          sameWidth
          readOnly={!canUpdateUser}
          type="select"
          name="roleIds"
          label={t('userRole')}
          options={roleOptions}
          defaultValue={user.roles?.[0].id}
        />
      </ContentSection>
      {isAcadeumAdministrator(currentUser) && (
        <ContentSection title={t('institution')}>
          <InstitutionAutocomplete
            superAdmin
            readOnly={!canUpdateUser}
            name="institutionId"
            type="institution"
            label={t('editInstitution')}
          />
        </ContentSection>
      )}
      {isAcadeumAdministrator(currentUser) && (
        <ContentSection title={t('dataTransfer')}>
          <FormField
            required
            border
            readOnly={!canUpdateUser}
            type="radio"
            name="sftp"
            label={t('SFTPAccess')}
            options={[
              {
                value: false,
                label: 'No'
              }, {
                value: true,
                label: 'Yes'
              }
            ]}
          />
          <FormField
            required
            border
            type="radio"
            name="courseImport"
            label={t('courseUploads')}
            options={[
              {
                value: false,
                label: 'No'
              }, {
                value: true,
                label: 'Yes'
              }
            ]}
          />
        </ContentSection>
      )}
      {isDirty && (
        <StickyFormFooter
          onCancelProps={{
            onClick: () => reset()
          }}
          submitProps={{
            action: 'submit',
            children: t('saveChanges')
          }}
        />
      )}
    </>
  );
};
