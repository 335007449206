import React from 'react';

import { t } from '../../translate';

import ExclamationMarkIcon from './exclamation-mark.svg';

import './FormFieldErrorBadge.scss';

export default function FormFieldErrorBadge() {
  return (
    <div className="FormFieldErrorBadge">
      <span className="FormFieldErrorBadge-text">
        {t('error')}
      </span>
      <ExclamationMarkIcon className="FormFieldErrorBadge-icon bi bi-exclamation-circle-fill"/>
    </div>
  );
}
