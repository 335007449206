import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import getCourseSectionPriceForStudentBasedOnCostForInstitution
  from 'common-lib/lib/getCourseSectionPriceForStudentBasedOnCostForInstitution';

import { parseCurrencyInput, parseCurrencyInputCents } from '@acadeum/helpers';
import { FormField, Form, FormSubmit, Text, Button, toast, Row, Col, FormFooter, FormModal } from '@acadeum/ui';

import { useSettingsRoutes } from '../../../../hooks/useSettingsRoutes';
import { PRICING_MODEL, validateCoursePricing } from '../../lib/coursePricing';
import { CoursePreview } from '../CoursePreview';
import { EnrollingStudentCoursePricingDescription } from '../EnrollingStudentCoursePricingDescription';

export const FormEnrollingStudentCoursePricing = ({
  homeStudentCourseEnrollmentPricing,
  onCancel,
  onAfterSubmit,
  formatCurrency,
  setHomeStudentCourseEnrollmentPricing
}) => {
  const { getStudentPortalUrl } = useSettingsRoutes();
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>();
  const defaultValuePricingModel = useMemo(() => getHomeStudentCourseEnrollmentPricingModelName(homeStudentCourseEnrollmentPricing), [homeStudentCourseEnrollmentPricing]);

  const [values, setValues] = useState<{
    pricingModel?: boolean;
    cost?: number;
    values?: number;
    showPrice?: boolean;
  }>();

  const onSubmitModal = useCallback(async () => {
    const value = parseFormValues(values);
    const pricing = getPricing(value);

    if (pricing) {
      validateCoursePricing([pricing]);
      await setHomeStudentCourseEnrollmentPricing([pricing]);
    } else {
      await setHomeStudentCourseEnrollmentPricing();
    }
    if (onAfterSubmit) {
      await onAfterSubmit();
    }
    toast.success('Student Portal course pricing successfully changed. Changes on the student portal will take place in approximately 20 seconds.');
  }, [values]);

  const onSubmit = (values) => {
    setValues(values);
    setShowConfirmationModal(true);
  };

  return (
    <>
      <br/>
      <Form
        onSubmit={onSubmit}
      >
        <FormBody
          formatCurrency={formatCurrency}
          defaultValuePricingModel={defaultValuePricingModel}
          homeStudentCourseEnrollmentPricing={homeStudentCourseEnrollmentPricing}
        />
        <FormFooter align="left">
          {onCancel &&
            <Button
              onClick={onCancel}
              variant="secondary"
            >
              Cancel
            </Button>
          }
          {!onCancel &&
            <Button
              variant="secondary"
              url={getStudentPortalUrl()}
            >
              Cancel
            </Button>
          }
          <FormSubmit>
            Save
          </FormSubmit>
        </FormFooter>
      </Form>
      <FormModal
        show={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
        title="Confirm pricing to display to your students"
        onSubmit={onSubmitModal}
        submitText="Confirm"
      >
        <EnrollingStudentCoursePricingDescription
          pricing={values?.pricingModel && getPricing(parseFormValues(values))}
        />
      </FormModal>
    </>
  );
};

const DUMMY_COURSE_CREDIT_HOURS = 3;

const PRICING_MODEL_OPTIONS: {
  value: string;
  description: string;
  label?: string;
}[] = [
  {
    value: 'fixed-fee',
    description: 'Places a fixed dollar amount above what the Teaching Institution is charging your Institution for the course.'
  },
  {
    value: 'percent-fee',
    description: 'Places a fixed percentage increase above what the Teaching Institution is charging your Institution for the course.'
  },
  {
    value: 'per-credit',
    description: 'Places a fixed multiple dollar value on the course cost based on the number of credit hours.'
  },
  {
    value: 'as-is',
    description: 'Shows the cost that the Teaching Institution is charging your Institution directly to students.'
  },
  {
    value: 'fixed',
    description: 'Shows the same price for all courses regardless of credit hours or cost to your Institution.'
  }
];

for (const option of PRICING_MODEL_OPTIONS) {
  option.label = PRICING_MODEL[option.value].title;
}

function getPricing(values): {
  model: string;
  value?: number;
} | undefined {
  if (!values.showPrice || !values.pricingModel) {
    return;
  }

  const pricing: {
    model: string;
    value?: number;
  } = {
    model: values.pricingModel
  };
  if (values.value) {
    pricing.value = values.value;
  }
  return pricing;
}

const parseFormValues = (values) => {
  if (values.pricingModel && PRICING_MODEL[values.pricingModel].valueType) {
    return {
      ...values,
      value: PRICING_MODEL[values.pricingModel].valueType === 'currency'
        ? parseCurrencyInput(values.value)
        : parseCurrencyInputCents(values.value)
    };
  }

  return values;
};

function getHomeStudentCourseEnrollmentPricingModelName(homeStudentCourseEnrollmentPricing) {
  if (homeStudentCourseEnrollmentPricing) {
    return homeStudentCourseEnrollmentPricing.model;
  }
}


const FormBody = ({
  defaultValuePricingModel,
  homeStudentCourseEnrollmentPricing,
  formatCurrency
}) => {
  const { watch, getValues, setValue } = useFormContext();

  const [dummyCost, setDummyCost] = useState<number>();
  const [dummyHours, setDummyHours] = useState<number>();

  const showPriceWatch = watch('showPrice');
  const pricingModelWatch = watch('pricingModel');
  const valueWatch = watch('value');
  const costWatch = watch('cost');


  const valuePricingModel = useMemo(() => pricingModelWatch || defaultValuePricingModel, [
    defaultValuePricingModel,
    pricingModelWatch
  ]);

  useEffect(() => {
    if (showPriceWatch) {
      if (homeStudentCourseEnrollmentPricing) {
        const value = PRICING_MODEL[valuePricingModel].valueType === 'percent'
          ? homeStudentCourseEnrollmentPricing.value / 100
          : homeStudentCourseEnrollmentPricing.value;
        setValue('value', homeStudentCourseEnrollmentPricing.model === valuePricingModel ? value : null);
      } else {
        setValue('value', null);
      }
    }
  }, [valuePricingModel, homeStudentCourseEnrollmentPricing, showPriceWatch]);
  useEffect(() => {
    if (pricingModelWatch) {
      // If `value` is required for pricing then require that it's not `undefined`.
      if (PRICING_MODEL[valuePricingModel].valueTitle
        ? (getValues('value') !== undefined && getValues('value') !== null)
        : true && getValues('cost') !== undefined) {
        const dummyCost = getCourseSectionPriceForStudentBasedOnCostForInstitution({
          course: {
            credits: DUMMY_COURSE_CREDIT_HOURS,
            level: 'Graduate',
            teachingInstitutionId: 1
          },
          homeStudentCourseEnrollmentPricing: [
            {
              model: pricingModelWatch,
              value: PRICING_MODEL[valuePricingModel].valueType === 'percent'
                ? parseCurrencyInputCents(valueWatch)
                : valueWatch
            }
          ],
          costForInstitution: costWatch
        });
        setDummyCost(dummyCost);
      }
      if (pricingModelWatch === 'per-credit') {
        setDummyHours(DUMMY_COURSE_CREDIT_HOURS);
      }
    }
  }, [pricingModelWatch, valuePricingModel, valueWatch, costWatch]);

  return (
    <>
      <FormField
        name="showPrice"
        defaultValue={homeStudentCourseEnrollmentPricing ? true : false}
        type="radio"
        options={[
          {
            label: 'No Price Shown',
            value: false
          },
          {
            label: 'Universal Pricing Model',
            value: true
          }
        ]}
      />

      <Text mt="md" mb="md">
        {showPriceWatch ?
          'A unique pricing model applied to all approved and scheduled courses.' :
          'You may charge students without displaying a price point within your student portal.'
        }
      </Text>

      <Text mb="md">
        Preview:
      </Text>

      <Row>
        <Col col={4} md={6} xs={12}>
          <CoursePreview
            formatCurrency={formatCurrency}
            dummyCost={dummyCost}
            dummyHours={dummyHours}
            showPrice={showPriceWatch && dummyCost !== undefined}
          />
        </Col>
        {showPriceWatch &&
          <Col col={8} md={6} xs={12} className="enrollment-course-pricing__pricing-settings">
            {/* Pricing model select. */}
            <FormField
              required
              name="pricingModel"
              aria-label="Pricing model"
              defaultValue={defaultValuePricingModel}
              options={PRICING_MODEL_OPTIONS}
              type="select"
            />
            {/* Pricing model value input. */}
            {/* `key` is used on `Field` because `value` should be cleared when changing pricing model. */}
            {/* Also, the current `value` should only be populated for the current pricing model. */}
            {PRICING_MODEL[valuePricingModel]?.valueTitle &&
              <FormField
                required
                currency={PRICING_MODEL[valuePricingModel].valueType === 'currency' ? 'USD' : undefined}
                type={PRICING_MODEL[valuePricingModel].valueType}
                name="value"
                label={`${PRICING_MODEL[valuePricingModel].valueTitle}:`}
              />
            }
            <FormField
              type="currency"
              currency="USD"
              name="cost"
              defaultValue={600}
              label="Teaching Institution Price example:"
            />
          </Col>
        }
      </Row>
    </>
  );
};
