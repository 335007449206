import { Slot } from '@radix-ui/react-slot';
import classNames from 'classnames';
import type { ReactNode, FC } from 'react';
import React from 'react';

import styles from './Required.module.scss';

export interface RequiredProps {
  asChild?: boolean;
  className?: string;
  children: ReactNode;
  required?: boolean;
}

export const Required: FC<RequiredProps> = ({
  asChild,
  className,
  children,
  required = true,
  ...rest
}) => {
  const Comp = asChild ? Slot : 'span';
  return (
    <Comp
      className={classNames(className, styles.root, {
        [styles.required]: required
      })}
      {...rest}
    >
      {children}
    </Comp>
  );
};
