import React from 'react';

import { useTranslate } from '@acadeum/translate';
import {
  Col,
  ContentSection,
  DataBlock,
  Link,
  Row,
  Separator,
  Text
} from '@acadeum/ui';


import './CourseOverviewSection.sass';

const CourseOverviewSection = ({ course, section }) => {
  const t = useTranslate('CourseOverviewSection');

  return (
    <ContentSection
      className="CourseOverviewSection"
      padding="none"
      title={t('courseOverview')}
      border={false}
    >
      <Text className="CourseOverviewSection__subsectionDescription">
        {t('courseOverviewDescription')}
      </Text>
      <ContentSection padding="md">
        <Row>
          <Col md={6}>
            <Text>
              {t('teachingMemberInstitution')}
            </Text>
            <Link to={`/institutions/${course.institution.id}`}>
              {course.institution.name}
            </Link>
          </Col>
          <Col md={6}>
            <Text>
              {t('course')}
            </Text>
            <Link to={`/courses/${section.course.id}`}>
              {`${section.course.code} ${section.course.title}`}
            </Link>
          </Col>
        </Row>
        <Separator/>
        {course.onDemand ? (
          <Row>
            <Col md={12}>
              <Text>
                {t('schedule')}
              </Text>
              <Text className="CourseOverviewSection__columnValue">
                {t('onDemand')}
              </Text>
            </Col>
          </Row>
        ) : (
          <>
            <Row>
              <Col md={5}>
                <Text>
                  {t('dates')}
                </Text>
                <div className="CourseOverviewSection__columnValue">
                  <DataBlock
                    utc
                    type="date"
                    month="short"
                    date={section.session.startDate}
                  />
                  {' — '}
                  <DataBlock
                    utc
                    type="date"
                    month="short"
                    date={section.session.endDate}
                  />
                </div>
              </Col>
              <Col md={3}>
                <Text>
                  {t('addDate')}
                </Text>
                <DataBlock
                  utc
                  className="CourseOverviewSection__columnValue"
                  type="date"
                  month="short"
                  date={section.session.lastAddDate}
                />
              </Col>
              <Col md={4}>
                <Text>
                  {t('dropDate')}
                </Text>
                <DataBlock
                  utc
                  className="CourseOverviewSection__columnValue"
                  type="date"
                  month="short"
                  date={section.session.lastDropDate}
                />
              </Col>
            </Row>
            <Separator/>
            <Row>
              <Col md={5}>
                <Text>
                  {t('term')}
                </Text>
                <Text className="CourseOverviewSection__columnValue">
                  {section.session.term}
                </Text>
              </Col>
              <Col md={3}>
                <Text>
                  {t('session')}
                </Text>
                <Text className="CourseOverviewSection__columnValue">
                  {section.session.name}
                </Text>
              </Col>
              <Col md={4}>
                <Text>
                  {t('section')}
                </Text>
                <Text className="CourseOverviewSection__columnValue">
                  {section.number}
                </Text>
              </Col>
            </Row>
          </>
        )}
      </ContentSection>
    </ContentSection>
  );
};

export default CourseOverviewSection;
