import { useSelector } from 'react-redux';

import { Separator } from '@acadeum/ui';

import { isCourseApproved } from '../../../../helpers/course';

import { AdditionalCostsSection } from './ui/AdditionalCostsSection';
import { CertificatesSection } from './ui/CertificatesSection';
import { CourseDescription } from './ui/CourseDescription/CourseDescription';
import { CourseLocationSection } from './ui/CourseLocationSection';
import { CourseMaterialsSection } from './ui/CourseMaterialsSection';
import { CoursePrerequisitesSection } from './ui/CoursePrerequisitesSection';
import { FacultyCredentialsSection } from './ui/FacultyCredentialsSection';
import { LearningAssessmentsSection } from './ui/LearningAssessmentsSection';
import { MappingSection } from './ui/MappingSection';
import { ScheduleSection } from './ui/ScheduleSection';
import { ViewSyllabus } from './ui/ViewSyllabus';
import { WelcomeLetterSection } from './ui/WelcomeLetterSection';

export function CourseInfo({
  course,
  schedule
}) {
  const { user } = useSelector(state => state.auth);

  return (
    <section>
      <CourseDescription course={course}/>
      {user && course.syllabusUrl && (
        <>
          <br/>
          <ViewSyllabus course={course}/>
        </>
      )}
      <Separator/>
      {user && user.institution.id === course.institution.id ? (
        <TeachingCourseSections course={course}/>
      ) : (
        <NonTeachingCourseSections
          user={user}
          course={course}
          schedule={schedule}
        />
      )}
    </section>
  );
}

// Teaching Courses sections
// 1. Course Location
// 2. Course Materials
// 3. Prerequisites
// 4. Additional Costs
// 5. Certificates
// 6. Learning Assessments
// 7. Faculty Credentials
// 8. Welcome Letter
function TeachingCourseSections({
  course
}) {
  return (
    <>
      <CourseLocationSection course={course}/>
      <Separator/>
      <CourseMaterialsSection course={course}/>
      <Separator/>
      <CoursePrerequisitesSection course={course}/>
      <Separator/>
      <AdditionalCostsSection course={course}/>
      <Separator/>
      <CertificatesSection course={course}/>
      {(course.hasLearningAssessments) && (
        <>
          <Separator/>
          <LearningAssessmentsSection course={course}/>
        </>
      )}
      {(course.hasFacultyCredentials) && (
        <>
          <Separator/>
          <FacultyCredentialsSection course={course}/>
        </>
      )}
      <Separator/>
      <WelcomeLetterSection course={course}/>
    </>
  );
}

// Non Teaching Courses sections
// 1. Course Location
// 2. Course Materials
// 3. Prerequisites
// 4. Additional Costs
// 5. Certificates
// 6. Learning Assessments
// 7. Faculty Credentials
// 8. Home Institution Equivalent Course (Mapping)
// 9. Home Institution Schedule
// 10. Welcome Letter
function NonTeachingCourseSections({
  course,
  schedule,
  user
}) {
  const { onDemand } = course;
  return (
    <>
      <CourseLocationSection course={course}/>
      <Separator/>
      <CourseMaterialsSection course={course}/>
      <Separator/>
      <CoursePrerequisitesSection course={course}/>
      <Separator/>
      <AdditionalCostsSection course={course}/>
      <Separator/>
      <CertificatesSection course={course}/>
      {(course.hasLearningAssessments) && (
        <>
          <Separator/>
          <LearningAssessmentsSection course={course}/>
        </>
      )}
      {(course.hasFacultyCredentials) && (
        <>
          <Separator/>
          <FacultyCredentialsSection course={course}/>
        </>
      )}
      {user && (
        <>
          <Separator/>
          <MappingSection course={course}/>
        </>
      )}
      {!onDemand && user && isCourseApproved(course, user.institution.id) && (
        <>
          <Separator/>
          <ScheduleSection
            user={user}
            course={course}
            schedule={schedule}
            editScheduleUrl={`/courses/${course.id}/approve`}
          />
        </>
      )}
      <Separator/>
      <WelcomeLetterSection course={course}/>
    </>
  );
}
