import { InfoTooltip } from '@acadeum/ui';

export const UserRolePermissionsInfoTooltip = () => {
  return (
    <InfoTooltip>
      <b>"None"</b> gives no access. <br/>
      <b>"All"</b> permits access to any object data. <br/>
      <b>"Own"</b> permits access to one’s own institution data. <br/>
      <b>"Individual"</b> give access to one’s own personal data only.
    </InfoTooltip>
  );
};
