import { injectContactsEndpoints } from '@acadeum/api';

import { rtkApi } from './rtkApi';


export const {
  useFetchContactQuery,
  useFetchContactsQuery,
  useCreateContactMutation,
  useUpdateContactMutation,
  useRemoveContactMutation
} = injectContactsEndpoints(rtkApi);
