import type { FC } from 'react';
import React, { useState } from 'react';

import type { FetchCourseEnrollmentsOutput } from '@acadeum/api';
import { CircleXmarkIcon, NoAccessIcon, NotebookPenIcon, TrashIcon } from '@acadeum/icons';
import { useTranslate } from '@acadeum/translate';
import { Button } from '@acadeum/ui';

import { getCourseEnrollmentStatusTransitions } from '../../lib/getCourseEnrollmentStatusTransitions';
import type { CourseEnrollmentMode } from '../../types';

import ChangeStatus from '../ChangeStatus';
import { CourseEnrollmentsDetailsModal } from '../CourseEnrollmentsDetailsModal';

export interface HomeCourseEnrollmentsActionsProps {
  mode: CourseEnrollmentMode;
  enrollments: FetchCourseEnrollmentsOutput['results'];
  downloadRow: () => Promise<void>;
}

export const CourseEnrollmentsActions: FC<HomeCourseEnrollmentsActionsProps> = ({
  downloadRow,
  enrollments,
  mode
}) => {
  const t = useTranslate('CourseEnrollmentsTable');
  const [show, setShow] = useState(false);

  if (enrollments.length === 0) {
    return null;
  }

  const targetStatuses = enrollments.flatMap((enrollment) => getCourseEnrollmentStatusTransitions({ enrollment, mode }));

  const hasUniformStatuses = enrollments.every(row => row.status === enrollments[0].status);
  const hasUniformTargetStatuses = targetStatuses.every(status => status === targetStatuses[0]);
  const uniqueTargetStatuses = [...new Set(targetStatuses)];

  function getStatusIcon(status) {
    switch (status) {
      case 'REMOVED':
        return TrashIcon;
      case 'COMPLETE':
        return NotebookPenIcon;
      case 'DROPPED':
        return NoAccessIcon;
      case 'WITHDRAWN':
        return CircleXmarkIcon;
      default:
        return undefined;
    }
  }

  return (
    <>
      <CourseEnrollmentsDetailsModal
        show={show}
        onHide={setShow}
        mode={mode}
        enrollments={enrollments}
        downloadRow={downloadRow}
      />

      <Button variant="tertiary" onClick={() => setShow(true)}>
        {t('seeDetails')}
      </Button>

      {hasUniformStatuses && hasUniformTargetStatuses && (
        <ChangeStatus
          mode={mode}
          exportData={downloadRow}
        >
          {({ onChange }) => {
            return (
              <>
                {uniqueTargetStatuses.map((status, index) => (
                  <Button
                    key={index}
                    variant="tertiary"
                    onClick={() => onChange(enrollments, status)}
                    icon={getStatusIcon(status)}
                  >
                    {t(`status.transitionTo.${status.toLowerCase()}`)}
                  </Button>
                ))}
              </>
            );
          }}
        </ChangeStatus>
      )}
    </>
  );
};
