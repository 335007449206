import React, { useCallback } from 'react';
import ReactGA from 'react-ga';

import type { LinkLikeComponentProps } from '@acadeum/core-ui';
import { useLinkComponent } from '@acadeum/core-ui';

import type { GAProps } from '../../utils/ga';
import { unstyled } from '../shared';

export interface UnstyledLinkProps extends LinkLikeComponentProps, Pick<GAProps, 'gaLabel' | 'gaValue'> {
  gaCategory?: string;
  gaAction?: string;
  disabled?: boolean;
  tabIndex?: number;
  /** The url to link to */
  to?: string;
  /** Makes the link open in a new tab */
  external?: boolean;
  /** Tells the browser to download the url instead of opening it. Provides a hint for the downloaded filename if it is a string value */
  download?: string | boolean;
  // Courseshare does not support navigation to external links using the Link component,
  // as a workaround we will explicitly indicate the places where navigation to external address
  useHtmlLink?: boolean;
}

// A `<UnstyledLink/>` component that accepts Google Analytics properties
// and reports that Google Analytics event on click.
export const UnstyledLink = React.forwardRef<HTMLAnchorElement, UnstyledLinkProps>(({
  external,
  disabled,
  tabIndex: propsTabIndex,
  onClick: propsOnClick,
  gaCategory = 'Links',
  gaAction = 'Clicked',
  gaLabel,
  gaValue,
  target,
  to,
  useHtmlLink,
  ...rest
}, ref) => {
  const onClick = useCallback((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (disabled) {
      return event.preventDefault();
    }

    if (gaLabel) {
      const eventData: ReactGA.EventArgs = {
        category: gaCategory as string,
        action: gaAction as string,
        label: gaLabel
      };
      if (gaValue !== undefined) {
        eventData.value = gaValue;
      }
      ReactGA.event(eventData);
    }
    if (propsOnClick) {
      propsOnClick(event);
    }
  }, [
    propsOnClick,
    gaCategory,
    gaAction,
    gaLabel,
    gaValue,
    disabled
  ]);

  const LinkComponent = useLinkComponent();

  const commonProps: React.AnchorHTMLAttributes<HTMLAnchorElement> & {
    ref?: React.Ref<HTMLAnchorElement>
  } = {
    ref,
    onClick,
    tabIndex: disabled ? -1 : propsTabIndex,
    'aria-disabled': disabled
  };

  external = external || target === '_blank';

  if (!to) {
    return (
      <span
        {...rest}
        {...commonProps}
        {...unstyled.props}
      />
    );
  }

  if (!useHtmlLink) {
    return (
      <LinkComponent
        {...rest}
        {...commonProps}
        {...unstyled.props}
        target={external ? '_blank' : undefined}
        rel={external ? 'noopener noreferrer' : undefined}
        href={to}
      />
    );
  }

  return (
    <a
      {...rest}
      {...commonProps}
      target={external ? '_blank' : undefined}
      rel={external ? 'noopener noreferrer' : undefined}
      href={to}
      {...unstyled.props}
    />
  );
});

UnstyledLink.displayName = 'UnstyledLink';
