import { isStringAssert } from '@acadeum/helpers';
import { getRtkApi } from '@acadeum/redux';

const baseUrl = process.env.NEXT_PUBLIC_API_URL;

isStringAssert(baseUrl, '`process.env.NEXT_PUBLIC_API_URL` must be defined for @acadeum/api to work.');

export const adminApi = getRtkApi({
  baseUrl
}, {
  reducerPath: 'adminApi',
  extractRehydrationInfo(action, { reducerPath }) {
    // __NEXT_REDUX_WRAPPER_HYDRATE__ is a constant defined in `next-redux-wrapper`.
    // It's not imported from `next-redux-wrapper` because `acadeum/api` is used in CourseShare that doesn't use `next-redux-wrapper`.
    if (action.type === '__NEXT_REDUX_WRAPPER_HYDRATE__') {
      return action.payload[reducerPath];
    }
  }
});
