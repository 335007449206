import type { RowData } from '@tanstack/react-table';

import { downloadCSV } from '@acadeum/helpers';

import writeXlsxFile from '../../../utils/xlsx';

export interface ExportDataColumnCsv<TData> {
  title: string;
  value: (row: TData) => string | number | boolean | undefined;
  width?: number;
}

export type ExportDataColumnXlsx<TData> = {
  value: (row: TData) => string | number | boolean | undefined;
  width?: number;
} & ({ id: string; title?: never; } | { id?: never; title: string; })

export interface ExportDataOptions<TData> {
  fileName: string;
  data: TData[];
  type: 'xlsx' | 'csv';
  exportDataColumns: ExportDataColumnXlsx<TData>[] | ExportDataColumnCsv<TData>[];
}

export const exportTableData = async <TData extends RowData>({
  data,
  type,
  fileName,
  exportDataColumns
}: ExportDataOptions<TData>) => {
  if (type === 'xlsx') {
    await writeXlsxFile(data, {
      fileName,
      columns: exportDataColumns
    });
  } else {
    await downloadCSV({
      fileName: `${fileName}.csv`,
      columns: exportDataColumns,
      data
    });
  }
};
