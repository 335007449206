import PropTypes from 'prop-types';
import React from 'react';

import RadioButton from '../RadioButton';

let RadioButtonGroup = ({
  value,
  options,
  tabIndex,
  error,
  ...rest
}, ref) => {
  return (
    <>
      {options.map((option, i) => {
        const isFocusable = value === undefined ? i === 0 : value === option.value;
        return (
          <RadioButton
            key={i}
            ref={isFocusable ? ref : undefined}
            value={option.value}
            checked={value === option.value}
            tabIndex={isFocusable ? tabIndex : -1}
            error={Boolean(error)}
            {...rest}
          >
            {option.label}
          </RadioButton>
        );
      })}

      {error &&
        <div className="form__input-error">
          {error}
        </div>
      }
    </>
  );
};

RadioButtonGroup = React.forwardRef(RadioButtonGroup);

RadioButtonGroup.propTypes = {
  value: PropTypes.any,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.any,
    label: PropTypes.string.isRequired
  })).isRequired,
  tabIndex: PropTypes.number,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ])
};

export default RadioButtonGroup;
