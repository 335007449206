import React from 'react';

import { MAPPING_SCHEMA } from '@acadeum/helpers';

import DocumentIcon from '../../../assets/images/icons/document.svg';

import actions from '../../actions';
import DataUploadPage from '../../components/DataUploadPage';
import Link from '../../components/Link';

const { uploadMappings } = actions;

export default function UploadMappings() {
  const getColumnSchema = (column) => MAPPING_SCHEMA[column];

  return (
    <section>
      <h1>Upload Mappings</h1>

      <Link
        to="https://dliov3t26vp8o.cloudfront.net/Data Upload Templates/Equivalents.Mapping.xlsx"
        target="_blank"
        className="upload-data-page__download-template">
        <DocumentIcon className="upload-data-page__download-template-icon" />
        Download Course Mappings Upload Template
      </Link>

      <DataUploadPage
        schema={MAPPING_SCHEMA}
        getColumnSchema={getColumnSchema}
        onUpload={uploadMappings}/>
    </section>
  );
}

UploadMappings.meta = () => ({
  title: 'Upload Mappings'
});

UploadMappings.breadcrumbs = () => [
  ['Admin Tools', '/admin'],
  'Upload Mappings'
];
