import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { DatePicker as DatePicker_ } from 'react-responsive-ui';

import { getTranslationLanguage } from '../../translate';

let DatePicker = ({
  minDate,
  maxDate,
  ...rest
}, ref) => {
  const language = getTranslationLanguage();

  const disabledDays = useMemo(() => {
    if (minDate || maxDate) {
      const disabledDays = {};
      if (minDate) {
        disabledDays.before = toDate(minDate);
      }
      if (maxDate) {
        disabledDays.after = toDate(maxDate);
      }
      return disabledDays;
    }
  }, [minDate, maxDate]);

  const initialCalendarDate = useMemo(() => {
    if (minDate) {
      if (minDate > Date.now()) {
        return new Date(minDate);
      }
    }
    if (maxDate) {
      if (maxDate < Date.now()) {
        return new Date(maxDate);
      }
    }
  }, [minDate, maxDate]);

  return (
    <DatePicker_
      ref={ref}
      {...rest}
      disabledDays={disabledDays}
      initialCalendarDate={initialCalendarDate}
      locale={language}
      format={FORMATS[language]}
      placeholder={PLACEHOLDERS[language]}
    />
  );
};

DatePicker = React.forwardRef(DatePicker);

DatePicker.propTypes = {
  minDate: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.instanceOf(Date)
  ]),
  maxDate: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.instanceOf(Date)
  ])
};

export default DatePicker;

const FORMATS = {
  en: 'mm/dd/yyyy'
};

// They prefer it uppercase.
// https://github.com/Acadeum/Tickets/issues/739#issuecomment-1058463365
const PLACEHOLDERS = {
  en: 'MM/DD/YYYY'
};

function toDate(value) {
  return typeof value === 'number' ? new Date(value) : value;
}
