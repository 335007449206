import FileSaver from 'file-saver';
import { useCallback } from 'react';

// import writeXlsx from '../../helpers/xlsx';
import actions from '../../actions';

const {
  exportCourseSearchResults
} = actions;

const MAX_RESULTS_FOR_IMMEDIATE_DOWNLOAD = 3000;

export default function useExportData({
  mode
}) {
  const onExportData = useCallback(async (searchParameters, { resultsCount }) => {
    const returnResult = resultsCount <= MAX_RESULTS_FOR_IMMEDIATE_DOWNLOAD;

    const { body, headers } = await exportCourseSearchResults({
      mode,
      returnResult,
      searchParameters
    });

    if (!returnResult) {
      return {
        confirmationText: 'Your request to download courses is being processed. This process can take some time, we will email you with a link to the file once it is ready for download.'
      };
    }

    // const contentType = headers['Content-Type'];
    const fileName = headers['Content-Disposition'].match(/^attachment; filename="(.+)"$/)[1];

    FileSaver.saveAs(base64toBlob(body), fileName);

    // writeXlsx(
    //   data.map((row, i) => {
    //     return row.map((cell) => ({
    //       value: cell || undefined,
    //       fontWeight: i === 0 ? 'bold' : undefined
    //     }));
    //   }),
    //   {
    //     fileName: exportedDataTitle + '.xlsx'
    //   }
    // );
  }, [
    mode
  ]);

  return onExportData;
}

// Converts a base64-encoded string to a `Blob`.
// https://stackoverflow.com/a/20151856
// Possible alternative:
// https://stackoverflow.com/a/36183085
function base64toBlob(base64Data, contentType) {
  contentType = contentType || '';
  var sliceSize = 1024;
  var byteCharacters = atob(base64Data);
  var bytesLength = byteCharacters.length;
  var slicesCount = Math.ceil(bytesLength / sliceSize);
  var byteArrays = new Array(slicesCount);

  for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    var begin = sliceIndex * sliceSize;
    var end = Math.min(begin + sliceSize, bytesLength);

    var bytes = new Array(end - begin);
    for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType });
}
