import React from 'react';

import { Grades } from '../../components/Grades';
import type { AppPage } from '../../helpers/app.types';

const TeachingGrades: AppPage = () => (
  <Grades mode="teachingInstitution"/>
);

TeachingGrades.meta = () => ({
  title: 'Grades'
}); 

TeachingGrades.breadcrumbs = () => ['Teaching Grades'];

export default TeachingGrades;
