import { uniq } from 'lodash-es';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import type {
  UseBulkUpsertCoursesJob,
  UseCreateCourseMutation,
  UseUpdateCourseAdvancedSettingsJob
} from '@acadeum/api';
import { getAuthSelector } from '@acadeum/auth';
import { isAcadeumAdministrator } from '@acadeum/helpers';
import { useStep } from '@acadeum/hooks';
import { useTranslateDictionary } from '@acadeum/translate';
import { StepsProgress } from '@acadeum/ui';


import type { DataUploadPageCache } from '../../components/DataUploadPage2';

import type { AddCourseMethod, StepProps } from './types';

import { AdvancedSettingsStep } from './ui/AdvancedSettingsStep';
import { CourseDetailsStep } from './ui/CourseDetailsStep';
import { SelectCourseUploadMethodStep } from './ui/SelectCourseUploadMethodStep';

import styles from './UploadCoursesPage.module.scss';

export interface UploadCoursesPageProps extends Pick<StepProps,
  'goToCoursePage' |
  'goToCoursesPage' |
  'cancelUrl' |
  'goToFinishPage' |
  'useBulkCreateOrUpdate' |
  'onDemandCourseDropDateDaysAfterStartDate' |
  'onDemandCourseEndDateDaysAfterStartDate'
> {
  useBulkCreateOrUpdate: UseBulkUpsertCoursesJob;
  useUpdateCourseSettings: UseUpdateCourseAdvancedSettingsJob;
  useCreateCourseMutation: UseCreateCourseMutation;
}

export const UploadCoursesPage = ({
  // useFileDataImportErrorMutation,
  useUpdateCourseSettings,
  useCreateCourseMutation,
  useBulkCreateOrUpdate,
  ...rest
}: UploadCoursesPageProps) => {
  const updateCourseSettings = useUpdateCourseSettings();
  const adminBulkCreateOrUpdate = useBulkCreateOrUpdate();
  const user = useSelector(getAuthSelector('user'));

  const [createCourse] = useCreateCourseMutation();

  const [cache, setCache] = useState<DataUploadPageCache>();

  // const isFileChosen = Boolean(cache);

  type UploadCourseValues = Parameters<typeof adminBulkCreateOrUpdate>[0]['courseSections'];
  type CreateCourseValues = Parameters<typeof createCourse>[0];
  const [course, setCourse] = useState<UploadCourseValues | CreateCourseValues>();
  const [institutionId, setInstitutionId] = useState<number>();

  // const t = useTranslate('shared-admin-ui.UploadCoursesPage');

  const [method, setMethod] = useState<AddCourseMethod>('upload');

  // const dataUploadPageRef = useRef<DataUploadPageRef>(null);

  const { step, navigation, stepIds, stepNames } = useSteps();

  const onChooseUploadMethod = (method: AddCourseMethod) => {
    setMethod(method);
    navigation.next();
    setCourse(undefined);
  };

  const onAdvancedSettingsSubmit: StepProps['onAdvancedSettingsSubmit'] = async ({
    pricing,
    institutionIds,
    consortiumIds
  }) => {
    if (!course) {
      // This should not be thrown
      throw new Error('Course is not defined');
    }

    let courseIds;
    let courseSectionIds;

    if (Array.isArray(course)) {
      const result = await adminBulkCreateOrUpdate({
        institutionId: isAcadeumAdministrator(user) ? institutionId : undefined,
        courseSections: course
      });
      courseIds = uniq(result.map(_ => _.courseId));
      courseSectionIds = uniq(result.map(_ => _.courseSectionId));
    } else {
      const { id, sections } = await createCourse(course).unwrap();
      courseIds = [id];
      if (sections) {
        courseSectionIds = sections.map(_ => _.id);
      }
    }

    await updateCourseSettings({
      courseIds,
      courseSectionIds,
      pricing,
      consortiumIds,
      institutionIds
    });

    return courseIds;
  };

  return (
    <>
      <div className={styles.UploadCourses__sticky}>
        <StepsProgress
          step={step.id}
          steps={stepIds}
          stepNames={stepNames}
        />
        {/*{step.id === 'addCourseDetails' && method === 'upload' && isFileChosen && (*/}
        {/*  <Button*/}
        {/*    className={styles.UploadCourses__removeFileButton}*/}
        {/*    icon={TrashIcon}*/}
        {/*    variant="black"*/}
        {/*    onClick={() => dataUploadPageRef.current?.removeUploadedFile()}*/}
        {/*  >*/}
        {/*    {t('removeFile')}*/}
        {/*  </Button>*/}
        {/*)}*/}
      </div>
      <br/>
      <div style={(step.id === 'addCourseDetails' && method === 'upload') ? undefined : { maxWidth: '57.5rem' }}>
        <step.component
          {...rest}
          user={user}
          method={method}
          navigation={navigation}
          onChooseUploadMethod={onChooseUploadMethod}
          // dataUploadPageRef={dataUploadPageRef}
          onSubmitCourseForm={setCourse}
          onCoursesUpload={({ courses, institutionId }) => {
            setCourse(courses as never);
            setInstitutionId(institutionId);
          }}
          onAdvancedSettingsSubmit={onAdvancedSettingsSubmit}
          onBackForAddCourseForm={navigation.previous}
          useBulkCreateOrUpdate={useBulkCreateOrUpdate}
          defaultFormValues={Array.isArray(course) ? undefined : course}
          cacheOptions={[cache, setCache]}
        />
      </div>
    </>
  );
};

function useSteps() {
  const STEPS = useMemo(() => [
    {
      id: 'selectCourseUploadMethod',
      component: (props) => <SelectCourseUploadMethodStep {...props}/>
    },
    {
      id: 'addCourseDetails',
      component: (props) => <CourseDetailsStep {...props}/>
    },
    {
      id: 'advancedSettings',
      component: (props) => <AdvancedSettingsStep {...props}/>
    }
  ], []);

  const { step, navigation } = useStep({ steps: STEPS });

  const stepIds = useMemo(() => STEPS.map(_ => _.id), [STEPS]);
  const stepNames = useTranslateDictionary('shared-admin-ui.UploadCoursesPage.steps', stepIds);

  return { STEPS, step, navigation, stepIds, stepNames };
}
