import { z } from 'zod';
import type { ZodEnum } from 'zod';

import formatRace from 'common-lib/lib/formatRace';
import isValidName from 'common-lib/lib/isValidName';
import isValidEmail from 'common-lib/lib/isValidEmail';
import isValidUsername from 'common-lib/lib/isValidUsername';
import isValidPhoneNumber from 'common-lib/lib/isValidPhoneNumber';

import races from 'common-lib/constants/races.json';
import studentLevels from 'common-lib/constants/studentLevels.json';
import countryRegions from 'common-lib/constants/countryRegions.json';
import studentEthnicities from 'common-lib/constants/studentEthnicities.json';

const countries = Object.keys(countryRegions);

function createOneOfSchema<T extends string>(
  values: T[],
  params?: Parameters<typeof z.enum>[1]
): ZodEnum<[T, ...T[]]> {
  if (values.length === 0) {
    throw new Error('`z.one expects non-empty array of strings');
  }
  if (values.some(value => typeof value !== 'string')) {
    throw new Error('`z.one expects non-empty array of strings');
  }
  return z.enum(values as [T, ...T[]], params);
}

function createNameSchema() {
  return z
    .string()
    .refine(isValidName, { message: 'Invalid name format' });
}

function createUsernameSchema() {
  return z
    .string()
    .refine(isValidUsername, { message: 'Invalid username format' });
}

function createEmailSchema() {
  return z
    .string()
    .refine(isValidEmail, { message: 'Invalid email format' });
}

function createPhoneSchema() {
  return z
    .string()
    .refine(isValidPhoneNumber, { message: 'Invalid phone number format' });
}

function createStudentEthnicitySchema() {
  return z.enum(studentEthnicities as [string, ...string[]], {
    message: 'Invalid ethnicity'
  });
}

function createStudentLevelSchema() {
  return z.enum(studentLevels as [string, ...string[]], {
    message: 'Invalid student level'
  });
}

function createDateOfBirthSchema() {
  // Set the minimum allowed date: January 1, 1940
  const minDate = new Date(1940, 0, 1);

  // Calculate the maximum allowed date: exactly 10 years ago from today
  const today = new Date();
  const maxDate = new Date(
    today.getFullYear() - 10,
    today.getMonth(),
    today.getDate()
  );

  return z.date()
    .min(minDate, { message: 'Date of birth cannot be earlier than January 1, 1940.' })
    .max(maxDate, { message: 'Age must be at least 10 years.' });
}


interface CreateRacesSchemaOptions {
  own: boolean;
}

function createRacesSchema({ own }: CreateRacesSchemaOptions) {
  const allowed = races.concat('-') as [string, ...string[]];
  return z
    .array(z.string())
    .superRefine((races, ctx) => {
      const invalidRaces = races.filter(race => !allowed.includes(race));

      const formatRace_ = race => formatRace(race, {
        input: true,
        own,
        language: 'en'
      });

      if (invalidRaces.length > 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `The following races are invalid: ${invalidRaces.join(', ')}. Allowed races are: ${allowed.map((race) => formatRace_(race)).join(', ')}`,
          path: ctx.path
        });
      }

      if (races.includes('-') && races.length > 1) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `"${formatRace_('-')}" cannot be combined with other races`,
          path: ctx.path
        });
      }
    });
}

const COUNTRY_REGEXP = /^[A-Z]{2}$/;

function createCountrySchema() {
  return z.string().regex(COUNTRY_REGEXP, {
    message: 'Country code must consist of exactly two uppercase letters.'
  });
}

function getCountryRegionOptions(country?: string): string[] {
  let options = ['N/A'];
  if (country && countries.includes(country)) {
    options = options.concat(countryRegions[country]);
  }
  return options;
}

function enforceCountryRegionConstraint(
  { property, required = true }: { property: string, required?: boolean },
  { ctx, data }: { data: { country?: string; }, ctx: z.RefinementCtx }
) {
  const countryRegionOptions = getCountryRegionOptions(data.country);

  const value = data[property];

  if (!required && (value === undefined || value === null)) {
    return;
  }

  const result = z
    .string()
    .refine((value) => countryRegionOptions.includes(value), {
      message: countryRegionOptions.length === 1
        ? `Country region must be one of: ${countryRegionOptions.join(', ')}`
        : 'Invalid country region'
    })
    .safeParse(value);


  if (!result.success) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: result.error.errors[0].message,
      path: [property]
    });
  }
}

const zExtensions = {
  ...z,
  oneOf: createOneOfSchema,
  dob: createDateOfBirthSchema,
  races: createRacesSchema,
  name: createNameSchema,
  email: createEmailSchema,
  phone: createPhoneSchema,
  country: createCountrySchema,
  username: createUsernameSchema,
  studentEthnicity: createStudentEthnicitySchema,
  studentLevel: createStudentLevelSchema,
  enforceCountryRegionConstraint: enforceCountryRegionConstraint
};

export { zExtensions as z };
