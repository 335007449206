import PropTypes from 'prop-types';

import { useTranslate } from '@acadeum/translate';

import { useGetConsortiumInputParams } from '../../utils/consortiumAutocomplete';

import { FormField } from '../FormField';
import { Select } from '../Select';

export interface ConsortiumAutocompleteProps {
  [key: string]: any;

  className?: string;
  noMargin?: boolean;
  required?: boolean;
  onChange?: (valueOrEvent: any, ...rest: any[]) => void;
  label?: string;
  name?: string;
  placeholder?: string;
  notFoundLabel?: string;
  noInputLabel?: string;
  isFormField?: boolean;
}

export const ConsortiumAutocomplete = ({
  className,
  noMargin,
  required,
  onChange,
  label,
  multiple,
  name,
  placeholder,
  notFoundLabel,
  noInputLabel,
  isFormField = true,
  ...rest
}: ConsortiumAutocompleteProps) => {
  const getConsortiumInputParams = useGetConsortiumInputParams();

  if (!getConsortiumInputParams) {
    throw new Error('<ConsortiumAutocomplete/>: getConsortiumInputParams is not defined. Please, provide it prop in AppProvider.');
  }

  const consortiumInputParams = getConsortiumInputParams();

  const t = useTranslate('ui.ConsortiumAutocomplete');
  const Component = isFormField ? FormField : Select;

  return (
    <Component
      {...rest}
      {...consortiumInputParams}
      className={className}
      noMargin={noMargin}
      multiple={multiple}
      required={required}
      name={name}
      type="select"
      placeholder={placeholder || t('placeholder')}
      label={label || t('label')}
      notFoundLabel={notFoundLabel || t('notFound')}
      noInputLabel={noInputLabel || t('noInput')}
      onChange={onChange}
    />
  );
};

ConsortiumAutocomplete.propTypes = {
  className: PropTypes.string,
  noMargin: PropTypes.bool,
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  notFoundLabel: PropTypes.string,
  noInputLabel: PropTypes.string,
  superAdmin: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
  defaultValue: PropTypes.any
};
