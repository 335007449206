import userRolePermissionOwnerScopeSubjects from 'common-lib/constants/userRolePermissionOwnerScopeSubjects.json';
import userRolePermissionPublicCreateSubjects from 'common-lib/constants/userRolePermissionPublicCreateSubjects.json';
import userRolePermissionPublicSubjects from 'common-lib/constants/userRolePermissionPublicSubjects.json';

import type { UserRolePermission, UserRolePermissionOperation, UserRolePermissionSubject, UserRolePermissionScope } from '@acadeum/types';

import { SCOPES } from './consts';
import { getScopeIndex } from './getScopeIndex';
import { getScopeLabel } from './getScopeLabel';

export function getPermissionOptions(
  subject: UserRolePermissionSubject,
  operation: UserRolePermissionOperation,
  userPermissions: UserRolePermission[]
) {
  const currentUserPermission = userPermissions.filter(userPermission => {
    return userPermission.subject === subject && userPermission.operations.includes(operation);
  })[0];

  const currentUserScope = currentUserPermission?.scope;
  function isScopeAvailable(scope: UserRolePermissionScope) {
    return (
      getScopeIndex(scope) >= getScopeIndex(currentUserScope) &&
      (userRolePermissionOwnerScopeSubjects.includes(subject) ? true : scope !== 'owner') &&
      ((userRolePermissionPublicSubjects.includes(subject) && operation === 'read' && scope === '*') ? true : scope !== '*') &&
      // @ts-expect-error - TS2345: Argument of type string is not assignable to parameter of type never. After removing items in `userRolePermissionPublicCreateSubjects` we get this error.
      (userRolePermissionPublicCreateSubjects.includes(subject) && operation === 'create' && scope === '*' ? true : scope !== '*')
    );
  }

  const availableScopes = currentUserScope ? SCOPES.filter(isScopeAvailable) : [];
  return [null, ...availableScopes].map(value => ({
    value,
    label: getScopeLabel(value)
  }));
}
