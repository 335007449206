import { useSelector } from 'react-redux';

import { getAuthSelector } from '@acadeum/auth';
import { userHasPermission } from '@acadeum/helpers';

export const useUserCanReadHomeCourseEnrollmentGrade = () => {
  const user = useSelector(getAuthSelector('user'));
  return user && userHasPermission(user, 'homeCourseEnrollmentGrade:read', {
    orgId: user.institution.id
  });
};

export const useUserCanReadTeachingCourseEnrollmentGrade = () => {
  const user = useSelector(getAuthSelector('user'));
  return user && user.institution.teaching && userHasPermission(user, 'teachingCourseEnrollmentGrade:read', {
    orgId: user.institution.id
  });
};

export const useCanEditTeachingGrade = () => {
  const user = useSelector(getAuthSelector('user'));
  return user && userHasPermission(user, 'teachingCourseEnrollmentGrade:update', {
    orgId: user.institution.id,
    ownerId: null
  });
};
