import type { FC } from 'react';
import React, { useState } from 'react';

import type { FetchTeachingCourseEnrollmentRequestsResponseItem } from '@acadeum/api';
import { useTranslate } from '@acadeum/translate';
import type { ModalProps, OnSubmit } from '@acadeum/ui';
import {
  DataBlock,
  Email,
  exportTableData,
  FormSubmit,
  Grid,
  Link,
  LoadingModal,
  Modal,
  Phone,
  ShowMoreButton,
  Text,
  toast
} from '@acadeum/ui';

import { useApproveTeachingCourseEnrollmentMutation } from '../../../../api/courseEnrollment';
import { useFetchTeachingStudentQuery } from '../../../../api/teachingStudents';
import { formatCountry, formatEthnicity, formatGender, formatRaces } from '../../../../helpers/format';
import useOnError from '../../../../hooks/useOnError';

import { useExportDataColumns } from '../../../Students/ui/HomeStudents';


import { DenyCourseEnrollmentModal } from '../DenyCourseEnrollmentModal';

import styles from './CourseEnrollmentRequestStudentDetailsModal.module.scss';

interface FormValues {
  formAction: 'approve' | 'deny';
}

export interface CourseEnrollmentRequestStudentDetailsModalProps extends Pick<ModalProps, 'show' | 'onHide'> {
  enrollment: FetchTeachingCourseEnrollmentRequestsResponseItem['enrollments'][number];
  enrollmentRequest: FetchTeachingCourseEnrollmentRequestsResponseItem;
}

export const CourseEnrollmentRequestStudentDetailsModal: FC<CourseEnrollmentRequestStudentDetailsModalProps> = ({
  show,
  onHide,
  enrollment,
  enrollmentRequest
}) => {
  const onError = useOnError();
  const t = useTranslate('EnrollmentRequests');
  const [approveTeachingCourseEnrollmentMutation] = useApproveTeachingCourseEnrollmentMutation();

  const [showDenyModal, setShowDenyModal] = useState(false);

  const {
    error,
    isLoading,
    data: student,
    isUninitialized
  } = useFetchTeachingStudentQuery({
    id: enrollment.student.id
  }, {
    skip: !show
  });

  const onSubmit: OnSubmit<FormValues> = async (values) => {
    if (values.formAction === 'approve') {
      await approveTeachingCourseEnrollmentMutation({
        ids: [enrollment.id],
        enrollmentRequestId: enrollmentRequest.id
      });
      toast.success(t('approveMessageSuccess', {
        count: 1,
        link: (children) => (
          <Link
            children={children}
            to="/enrollments/live"
            removeUnderline={false}
            className={styles.toastLink}
          />
        )
      }));
      onHide(false);
    } else if (values.formAction === 'deny') {
      setShowDenyModal(true);
    } else {
      console.error('Unknown form action');
    }
  };

  const onAfterDeny = async () => {
    setShowDenyModal(false);
    onHide(false);
  };

  const exportDataColumns = useExportDataColumns();

  const exportStudentData = async () => {
    if (!student) {
      console.error('`student` is not defined');
      return toast.error(t('errorVerbose', { global: true }));
    }

    try {
      await exportTableData({
        type: 'xlsx',
        fileName: t('student'),
        exportDataColumns,
        data: [student]
      });
    } catch (error) {
      console.error(error);
      toast.error(t('errorVerbose', { global: true }));
    }
  };

  if (isUninitialized) {
    return null;
  }

  if (isLoading) {
    return (
      <LoadingModal
        show
        centered={false}
      />
    );
  }

  if (error) {
    return onError(error);
  }

  return (
    <>
      <DenyCourseEnrollmentModal
        enrollmentRequest={enrollmentRequest}
        enrollmentsToDeny={[enrollment]}
        show={showDenyModal}
        onHide={setShowDenyModal}
        onAfterSubmit={onAfterDeny}
      />
      <Modal
        isNestedForm
        show={show}
        onHide={onHide}
        size="wide"
        title={t('student')}
        onSubmit={onSubmit}
        actions={[
          {
            title: t('downloadInformation'),
            onClick: exportStudentData
          }
        ]}
      >
        <Modal.Body>
          <InfoStudent data={student}/>
        </Modal.Body>
        {enrollment.status === 'REQUESTED' && (
          <Modal.Footer>
            <FormSubmit
              action="deny"
              variant="secondary"
            >
              {t('deny')}
            </FormSubmit>
            <FormSubmit action="approve">
              {t('accept')}
            </FormSubmit>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};

const InfoStudent = ({
  data
}) => {
  const t = useTranslate('EnrollmentRequests');

  const [expandedDetails, setExpandedDetails] = useState(false);

  const personalInformation = [
    {
      title: t('student'),
      content: (
        <>
          <DataBlock type="user" user={data}/>
          <Text color="grey" variant="bodyMd">
            ID: {data.id}
          </Text>
        </>
      )
    },
    {
      title: t('studentEmail'),
      content: <Email address={data.email}/>
    },
    {
      title: t('dateOfBirth'),
      content: data.dob && <DataBlock type="date" date={data.dob} utc month="long"/>
    },
    {
      title: t('uSCitizen'),
      content: typeof data.citizenship === 'boolean' ? data.citizenship ? t('yes') : t('no') : 'Unknown'
    },
    {
      title: t('gender'),
      content: formatGender(data.gender)
    },
    {
      title: t('ethnicity'),
      content: data.ethnicity ? formatEthnicity(data.ethnicity) : '-'
    },
    {
      title: t('race'),
      content: data.races ? formatRaces(data.races, { separator: '\n' }) : '-'
    }
  ];

  const academicInformation = [
    {
      title: t('homeInstitution'),
      content: <DataBlock hideLogo type="institution" institution={data.institution}/>
    },
    {
      title: t('level'),
      content: data.level
    },
    {
      title: t('major'),
      content: data.major
    },
    {
      title: t('startDate'),
      content: data.startDate && <DataBlock type="date" date={data.startDate} utc month="long"/>
    },
    {
      title: t('advisorName'),
      content: data.advisorName
    },
    {
      title: t('advisorEmail'),
      content: data.advisorEmail
    }
  ];

  const contactInformation = [
    {
      title: t('country'),
      content: formatCountry(data.country)
    },
    {
      title: t('telephone'),
      content: data.phone && <Phone number={data.phone}/>
    },
    {
      title: t('streetAddress1'),
      content: data.addressLine1
    },
    {
      title: t('streetAddress2'),
      content: data.addressLine2
    },
    {
      title: t('city'),
      content: data.city
    }, {
      title: t('state'),
      content: data.state
    }, {
      title: t('zip'),
      content: data.postalCode
    }, {
      title: t('residency'),
      content: data.residency
    }
  ];

  return (
    <div className={styles.InfoStudent}>
      <Text
        as="h3"
        variant="subtitle2"
        mb="md"
      >
        {t('personalInformation')}
      </Text>
      <Grid
        container
        wrap="wrap"
        columns={24}
        rowSpacing={2}
      >
        {personalInformation.map((_, index) => (
          <React.Fragment key={index}>
            <Grid size={7}>
              {_.title}
            </Grid>
            <Grid size={17}>
              {_.content}
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
      {expandedDetails && (
        <>
          <hr/>
          <Text variant="subtitle2" className={styles.InfoStudent__title}>
            {t('academicInformation')}
          </Text>
          <Grid
            container
            wrap="wrap"
            columns={24}
            rowSpacing={2}
          >
            {academicInformation.map((_, index) => (
              <React.Fragment key={index}>
                <Grid size={7}>
                  {_.title}
                </Grid>
                <Grid size={17}>
                  {_.content}
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
          <hr/>
          <Text variant="subtitle2" className={styles.InfoStudent__title}>
            {t('contactInformation')}
          </Text>
          <Grid
            container
            wrap="wrap"
            columns={24}
            rowSpacing={2}
          >
            {contactInformation.map((_, index) => (
              <React.Fragment key={index}>
                <Grid size={7}>
                  {_.title}
                </Grid>
                <Grid size={17}>
                  {_.content}
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </>
      )}
      <ShowMoreButton
        onClick={setExpandedDetails}
        expanded={expandedDetails}
      />
    </div>
  );
};
