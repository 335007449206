import { GeneralSettingsPage } from '@acadeum/shared-admin-ui';

import { useFetchContactsQuery } from '../../api/contacts';
import type { AppPage } from '../../helpers/app.types';

const GeneralSettings: AppPage = () => {
  return (
    <GeneralSettingsPage
      useFetchContactsQuery={useFetchContactsQuery}
    />
  );
};

GeneralSettings.meta = () => ({
  title: 'General Settings'
});

export default GeneralSettings;
