import classNames from 'classnames';
import type { FC } from 'react';
import React from 'react';

import type { IconSource } from '@acadeum/types';

import { Link } from '../../Link';
import { Loader } from '../../Loader';

import styles from './Header.module.scss';

export interface HeaderProps {
  className?: string;
  logo?: IconSource;
  isLoading?: boolean;
  isAuthenticationLoading?: boolean;
}

export const Header: FC<HeaderProps> = ({
  className,
  logo: Logo,
  isLoading,
  isAuthenticationLoading
}) => {
  return (
    <header className={classNames(styles.root, className)}>
      {Logo && (
        <Link
          to="/"
          aria-label="Homepage"
          monochrome
        >
          <Logo
            focusable="false"
            aria-hidden="true"
            className={styles.logo}
          />
        </Link>
      )}

      {/* TODO: Add Slots */}

      {(isLoading || isAuthenticationLoading) && (
        <Loader
          inline
          variant="linear"
          className={styles.Loader}
        />
      )}
    </header>
  );
};
