import { useMemo } from 'react';
import type { FC } from 'react';
import { useSelector } from 'react-redux';

import formatUserName from 'common-lib/lib/formatUserName';

import { Page, PageLoader, Tabs } from '@acadeum/ui';
import type { TabsProps } from '@acadeum/ui';
import { useFetchUserQuery, useFetchUserLogQuery, useFetchUserRolesQuery, useUpdateUserMutation } from '@acadeum/api';
import { useTranslate } from '@acadeum/translate';
import { getAuthSelector } from '@acadeum/auth';
import { isAcadeumAdministrator } from '@acadeum/helpers';

import { useSettingsRoutes } from '../../../hooks/useSettingsRoutes';
import { useOnError } from '../../../providers/useOnError';
import { useApp } from '../../../providers/useApp';
import { useNavigate } from '../../../providers/useNavigate';
import { useLocation } from '../../../providers/useLocation';

import { EditUserForm } from '../ui/EditUserForm';

import { UserAuthHistoryTable } from '../../../components/UserAuthHistoryTable';
import { AdminUserStatusList } from '../../../components/AdminUserStatusList';
import { AdminUserActions } from '../../../components/AdminUserActions';

interface UserAdminPageProps {
  userId: number;
}

export const UserAdminPage: FC<UserAdminPageProps> = ({ userId }) => {
  const t = useTranslate('shared-admin-ui.UserDetailsPage');
  const onError = useOnError();
  const { app } = useApp();
  const { getUserAdminUrl } = useSettingsRoutes();

  const router = useNavigate();
  const location = useLocation();
  const tab = location.query.tab;

  const {
    error: errorHistory,
    isLoading: isLoadingHistory,
    data: history
  } = useFetchUserLogQuery({ id: userId });

  const currentUser = useSelector(getAuthSelector('user'));

  const {
    data: user,
    isLoading: isLoadingUser,
    refetch: reFetchUser,
    error: userError
  } = useFetchUserQuery({ id: userId, allSettings: isAcadeumAdministrator(currentUser) });

  const {
    data: userRoles,
    isLoading: isLoadingRoles,
    error: userRolesError
  } = useFetchUserRolesQuery({
    pageSize: 1000
  });
  const roles = userRoles?.results;

  const breadcrumbs = useMemo(() => {
    return [
      ...(app === 'courseshare' ? [['Home', '/']] : []),
      ['Acadeum Tools', '/admin'],
      ['User Accounts', '/admin/users/'],
      ...(user ? [formatUserName(user)] : [t('title')])
    ];
  }, [user, app]);

  const onChangeTab: TabsProps['onChangeTab'] = ({ key }) => {
    void router.push(`${getUserAdminUrl(user?.id)}?tab=${key}`);
  };

  const tabs = useMemo<TabsProps['tabs']>(() => {
    if (!user || !roles || !history) {
      return [];
    }
    return [
      {
        key: 'info',
        title: t('info'),
        body: <EditUserForm
          user={user}
          roles={roles}
          useUpdateUserMutation={useUpdateUserMutation}
        />
      },
      {
        key: 'log',
        title: t('userLog'),
        body: <UserAuthHistoryTable hideTitle history={history} />
      }
    ];
  }, [user, roles, history]);

  const error = userError || userRolesError || errorHistory;
  if (error) {
    return onError(error);
  }

  if (isLoadingUser || isLoadingRoles || isLoadingHistory) {
    return <PageLoader />;
  }

  return (
    <Page
      title={user ? formatUserName(user) : t('title')}
      breadcrumbs={breadcrumbs}
      actions={user && (
        <>
          <AdminUserStatusList user={user} />
          <AdminUserActions
            isStudent
            user={user}
            refreshFetchDataStudentsOrUsers={() => reFetchUser()}
          />
        </>
      )}
    >
      <Tabs
        title="User details"
        tabs={tabs}
        onChangeTab={onChangeTab}
        initialActiveTab={tab === 'log' ? 'log' : 'info'}
      />
    </Page>
  );
};
