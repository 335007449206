import type { Middleware } from '@reduxjs/toolkit';
import { isRejectedWithValue } from '@reduxjs/toolkit';

import { notAuthenticatedAction } from '@acadeum/auth';
import { getIsNotAuthenticatedError, isHttpError } from '@acadeum/helpers';

import { httpErrorSlice } from './httpErrorSlice';

type GetErrorHandlerMiddleware = () => Middleware

export const getErrorHandlerMiddleware: GetErrorHandlerMiddleware =
  () =>
    ({ dispatch }) =>
      (next) =>
        (action) => {
          if (!isRejectedWithValue(action)) {
            return next(action);
          }

          const error = action.payload;
          console.error('Async error: ', error);

          if (isHttpError(error)) {
            dispatch(httpErrorSlice.actions.setError(error));

            if (getIsNotAuthenticatedError(error)) {
              dispatch(notAuthenticatedAction());
            }
          }

          return next(action);
        };
