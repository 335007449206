import classNames from 'classnames';
import React from 'react';
import type { FC } from 'react';

import styles from './Main.module.scss';

export interface MainProps {
  className?: string;
  children: React.ReactNode;
}

/* `<main id="main"/>` is used to create an "anchor" for a "Skip to Main Content" hyperlink. */
export const MAIN_ID = 'main';

export const Main: FC<MainProps> = ({
  className,
  children
}) => {
  return (
    <main
      id={MAIN_ID}
      className={classNames(styles.root, className)}
    >
      {children}
    </main>
  );
};
