import type { RtkApi } from '@acadeum/redux';
import type { User, UserRole } from '@acadeum/types';

import { adminApi } from '../adminApi';

type CreateUserRoleInput = Pick<UserRole,
  'name' |
  'description' |
  'permissions' |
  'notifications'>

type CreateUserRoleOutput = Pick<UserRole, 'id'>

interface DeleteUserRoleInput {
  id: UserRole['id'];
}

interface DuplicateUserRoleInput {
  id: UserRole['id'];
}

interface DuplicateUserRoleOutput {
  id: UserRole['id'];
}

interface FetchUserRoleInput {
  id: UserRole['id'];
}

interface FetchUserRoleOutput extends UserRole {
  users: Pick<User, 'id' | 'firstName' | 'lastName' | 'title' | 'email' | 'type'>[];
}

interface FetchUserRolesInput {
  page?: number;
  pageSize: number;
  search?: string;
}

export interface FetchUserRolesOutput {
  page: number;
  pageSize: number;
  totalCount: number;
  results: Array<Omit<UserRole, 'permissions'> & {
    users: Pick<User, 'id' | 'firstName' | 'lastName' | 'title' | 'email' | 'type'>[]
  }>;
}

type UpdateUserRoleInput = (
  Pick<UserRole, 'name' | 'description' | 'permissions'> |
  Pick<UserRole, 'name' | 'description'> |
  Pick<UserRole, 'permissions'> |
  Pick<UserRole, 'notifications'>
  ) & {
  id: UserRole['id']
}

export const injectUserRolesEndpoints = (rtkApi: RtkApi) => rtkApi
  .enhanceEndpoints({ addTagTypes: ['userRoles'] })
  .injectEndpoints({
    endpoints: build => ({
      createUserRole: build.mutation<CreateUserRoleOutput, CreateUserRoleInput>({
        query: (body) => ({
          url: '/user-roles',
          method: 'POST',
          body
        }),
        invalidatesTags: [{ type: 'userRoles', id: 'LIST' }]
      }),
      deleteUserRole: build.mutation<void, DeleteUserRoleInput>({
        query: ({ id }) => ({
          url: `/user-roles/${id}`,
          method: 'DELETE'
        }),
        invalidatesTags: [{ type: 'userRoles', id: 'LIST' }]
      }),
      duplicateUserRole: build.mutation<DuplicateUserRoleOutput, DuplicateUserRoleInput>({
        query: ({ id }) => ({
          url: `/user-roles/${id}`,
          method: 'POST'
        }),
        invalidatesTags: [{ type: 'userRoles', id: 'LIST' }]
      }),
      fetchUserRole: build.query<FetchUserRoleOutput, FetchUserRoleInput>({
        query: ({ id }) => `/user-roles/${id}`,
        providesTags: (result, error, { id }) => [{ type: 'userRoles', id }]
      }),
      fetchUserRoles: build.query<FetchUserRolesOutput, FetchUserRolesInput>({
        query: (params) => ({
          url: '/user-roles',
          params
        }),
        providesTags: [{ type: 'userRoles', id: 'LIST' }]
      }),
      updateUserRole: build.mutation<void, UpdateUserRoleInput>({
        query: ({ id, ...rest }) => ({
          url: `/user-roles/${id}`,
          method: 'PUT',
          body: rest
        }),
        invalidatesTags: (result, error, { id }) => [
          { type: 'userRoles', id },
          { type: 'userRoles', id: 'LIST' }
        ]
      })
    })
  });

export type UseFetchUserRolesQuery = ReturnType<typeof injectUserRolesEndpoints>['useFetchUserRolesQuery'];
export type UseFetchUserRoleQuery = ReturnType<typeof injectUserRolesEndpoints>['useFetchUserRoleQuery'];
export type UseCreateUserRoleMutation = ReturnType<typeof injectUserRolesEndpoints>['useCreateUserRoleMutation'];
export type UseUpdateUserRoleMutation = ReturnType<typeof injectUserRolesEndpoints>['useUpdateUserRoleMutation'];
export type UseDeleteUserRoleMutation = ReturnType<typeof injectUserRolesEndpoints>['useDeleteUserRoleMutation'];
export type UseDuplicateUserRoleMutation = ReturnType<typeof injectUserRolesEndpoints>['useDuplicateUserRoleMutation'];

export const {
  useFetchUserRolesQuery
} = injectUserRolesEndpoints(adminApi);
