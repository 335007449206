import PropTypes from 'prop-types';
import React from 'react';

import convertUtcDateToLocalDateWithSameTime from 'common-lib/lib/convertUtcDateToLocalDateWithSameTime';
import {
  isToday as getIsToday,
  isYesterday as getIsYesterday,
  isCurrentYear as getIsCurrentYear
} from 'common-lib/lib/dateCompare';

import { FormatDate } from '../FormatDate';

import { Text } from '../Text';
import { Time as FormatTime } from '../Time';

export interface FormatDateWithTimeProps {
  date: Date | number;
  utc: boolean;
}

export const FormatDateWithTime: React.FC<FormatDateWithTimeProps> = ({
  date,
  utc
}) => {
  date = typeof date === 'number' ? new Date(date) : date;
  // Since <FormatTime /> does not support the UTC flag, we convert the time from UTC to Local
  date = utc ? convertUtcDateToLocalDateWithSameTime(date) : date;

  const isToday: boolean = getIsToday(date);
  const isYesterday: boolean = getIsYesterday(date);
  const isCurrentYear: boolean = getIsCurrentYear(date);

  return (
    <Text as="span" color="grey">
      {(isToday || isYesterday) ? (
        <FormatTime date={date} utc={false} locale="en">
          {(time) => isYesterday ? `Yesterday, ${time}` : time}
        </FormatTime>
      ) : (
        <FormatDate
          time
          utc={false}
          locale="en"
          date={date}
          year={isCurrentYear ? false : 'numeric'}
          month="long"
          day="numeric"
        />
      )}
    </Text>
  );
};

FormatDateWithTime.propTypes = {
  date: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.number
  ]).isRequired,
  utc: PropTypes.bool.isRequired
};
