import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import reportError from 'common-lib/lib/reportError';

import { ISO_date_matcher as ISO_DATE_MATCHER } from '@acadeum/helpers';
import type { Gender, Email as EmailType, Phone as PhoneType, PhoneExt } from '@acadeum/types';

import { Email } from '../Email';
import type { FormatDateProps } from '../FormatDate';
import { FormatDate } from '../FormatDate';
import { Link } from '../Link';
import { Phone } from '../Phone';

import styles from './Output.module.scss';

export type OutputProps = OutputValueProps & {
  label?: React.ReactNode;
  dl?: boolean;
  inverted?: boolean;
}

export const Output: React.FC<OutputProps> = ({
  label,
  dl,
  inverted,
  ...rest
}) => {
  if (dl) {
    return (
      <div className={classNames(styles.Output, {
        [styles.inverted]: inverted
      })}>
        {label && (
          <dt className={styles.label}>
            {label}
          </dt>
        )}
        <dd className={label ? styles.value : styles.label}>
          <OutputValue {...rest}/>
        </dd>
      </div>
    );
  }

  return (
    <div className={styles.Output}>
      {label && (
        <div className={styles.Output__label}>
          {label}
        </div>
      )}
      <div className={label ? styles.Output__value : styles.Output__label}>
        <OutputValue {...rest}/>
      </div>
    </div>
  );
};

Output.propTypes = {
  label: PropTypes.node,
  value: PropTypes.any,
  type: PropTypes.oneOf(['date', 'phone', 'email', 'gender']),
  ext: PropTypes.string,
  url: PropTypes.string,
  external: PropTypes.bool,
  utc: PropTypes.bool,
  withTime: PropTypes.bool,
  month: PropTypes.oneOf(['short', 'long'])
} as NonNullable<unknown>;

function renderValue(value,
  type,
  { ext, utc, withTime, month }: { ext?: PhoneExt, utc?: boolean, withTime?: boolean, month?: FormatDateProps['month'] } = {}) {
  if (value === undefined || value === null) {
    return '—';
  }
  switch (type) {
    case 'date':
      if (utc === undefined) {
        reportError('`utc` property is required when rendering a `type="date"` or `dates` `<Output/>`');
      }
      if (Array.isArray(value)) {
        return (
          <React.Fragment>
            <FormatDate date={convertToDate(value[0])} month={month} time={Boolean(withTime)} utc={Boolean(utc)}/>
            {' — '}
            <FormatDate date={convertToDate(value[1])} month={month} time={Boolean(withTime)} utc={Boolean(utc)}/>
          </React.Fragment>
        );
      }
      return <FormatDate date={convertToDate(value)} month={month} time={Boolean(withTime)} utc={Boolean(utc)}/>;
    case 'gender':
      return value === true ? 'Male' : (value === false ? 'Female' : 'Unspecified');
    case 'phone':
      return <Phone number={value} ext={ext}/>;
    case 'email':
      return <Email address={value}/>;
    default:
      return value;
  }
}

function convertToDate(date) {
  // Convert a number (timestamp) to a `Date`.
  if (typeof date === 'number') {
    return new Date(date);
  }
  // Convert a ISO string format to a `Date`.
  if (typeof date === 'string' && ISO_DATE_MATCHER.test(date)) {
    return new Date(date);
  }
  return date;
}

interface OutputValueDate {
  type: 'date';
  value?: Date | number | [(Date | number), (Date | number)] | string;
  values?: [(Date | number), (Date | number)];
  utc: boolean;
  withTime?: boolean;
  month?: FormatDateProps['month'];
}

interface OutputValueGender {
  type: 'gender';
  value?: Gender;
}

interface OutputValueEmail {
  type: 'email';
  value: EmailType;
}

interface OutputValuePhone {
  type: 'phone';
  value?: PhoneType;
  ext?: PhoneExt;
}

interface OutputValueAny {
  type?: undefined;
  // eslint-disable-next-line
  value: any;
}

type OutputValueProps = (OutputValueDate | OutputValueGender | OutputValueEmail | OutputValuePhone | OutputValueAny) & {
  url?: string;
  external?: boolean;
}

const OutputValue: React.FC<OutputValueProps> = (props) => {
  const {
    value,
    type,
    url,
    external
  } = props;

  let content;
  switch (type) {
    case 'date':
      // eslint-disable-next-line no-case-declarations
      const { values, utc, withTime, month } = props;
      content = renderValue(value || values, type, { utc, withTime, month });
      break;
    case 'phone':
      // eslint-disable-next-line no-case-declarations
      const { ext } = props;
      content = renderValue(value, type, { ext });
      break;
    default:
      content = renderValue(value, type);
  }

  if (url) {
    return (
      <Link to={url} external={external}>
        {content}
      </Link>
    );
  }
  return (
    <span>
      {content}
    </span>
  );
};
