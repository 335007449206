import classNames from 'classnames';
import type { InferProps } from 'prop-types';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { isEmptyFormValue } from '@acadeum/helpers';

const propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  multiple: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.any,
    label: PropTypes.string.isRequired
  }).isRequired).isRequired,
  inline: PropTypes.bool,
  description: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  CheckboxComponent: PropTypes.elementType.isRequired,
  emptyValue: PropTypes.string,
  alwaysShowAsteriskWhenRequired: PropTypes.bool,
  className: PropTypes.string
};

export type CheckboxGroupProps = InferProps<typeof propTypes>;

export const CheckboxGroup = React.forwardRef<HTMLInputElement, CheckboxGroupProps>(({
  value,
  onChange,
  onBlur,
  options,
  inline,
  multiple,
  description,
  error,
  disabled,
  required,
  CheckboxComponent,
  emptyValue = '-',
  alwaysShowAsteriskWhenRequired = true,
  className,
  ...rest
}, ref) => {
  const onChangeOptionValue = useCallback((optionValue, checked) => {
    if (multiple) {
      if (checked) {
        if (optionValue === emptyValue) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          value = emptyValue;
        } else {
          if (Array.isArray(value)) {
            if (!value.includes(optionValue)) {
              value = value.concat(optionValue)
                // Sort `value` elements according to their order in `options`.
                .sort((a, b) => options.findIndex(_ => _.value === a) - options.findIndex(_ => _.value === b));
            }
          } else {
            // If `value` is `undefined`/`null` or "-".
            value = [optionValue];
          }
        }
      } else {
        if (optionValue === emptyValue) {
          value = [];
        } else {
          // `value` is an array in this case.
          value = value.filter(_ => _ !== optionValue);
        }
        if (value.length === 0) {
          value = undefined;
        }
      }
    } else {
      value = optionValue;
    }
    onChange(value);
  }, [
    multiple,
    emptyValue,
    options,
    onChange
  ]);
  return (
    <section
      {...rest}
      className={classNames('CheckboxGroup', {
        'CheckboxGroup--inline': inline
      }, className)}>
      {description &&
        <p className="CheckboxGroup-description">
          {description}
          {required && (alwaysShowAsteriskWhenRequired || isEmptyFormValue(value)) ? ' *' : null}
        </p>
      }
      {error &&
        <div className="CheckboxGroup-error">
          {error}
        </div>
      }
      <div className="CheckboxGroup-options">
        {options.map((option, i) => (
          <CheckboxComponent
            ref={i === 0 ? ref : undefined}
            key={option.value}
            disabled={disabled}
            error={error ? true : undefined}
            value={isChecked(option.value, value)}
            onChange={checked => onChangeOptionValue(option.value, checked)}
            onBlur={onBlur}
            className="CheckboxGroup-option"
            label={option.label}/>
        ))}
      </div>
    </section>
  );
});

CheckboxGroup.displayName = 'CheckboxGroup';
CheckboxGroup.propTypes = propTypes;

function isChecked(optionValue, value) {
  if (Array.isArray(value)) {
    return value.includes(optionValue);
  }
  return optionValue === value;
}
