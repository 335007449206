import { isEmpty } from 'lodash-es';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { useFetchWithPagination } from '@acadeum/hooks';
import { STUDENT_LEVEL_OPTIONS } from '@acadeum/selection-options';
import { useTranslate } from '@acadeum/translate';
import { Actions, Blank, DataBlock, Filters, ShowMoreWrapper, Table, Email, createTableId } from '@acadeum/ui';

import actions from '../../../../actions';
import type { ReduxState } from '../../../../helpers/app.types';
import { convertFiltersDataToSelectOptions } from '../../../../helpers/convertFiltersDataToSelectOptions';
import { formatName } from '../../../../helpers/format';

const {
  fetchTeachingStudents,
  fetchTeachingStudentsForExport
} = actions;


const TeachingStudents = () => {
  const [filters, setFilters] = useState({});

  const { studentFilters } = useSelector((state: ReduxState) => state.students);

  const t = useTranslate('Students');
  const tTeachingStudents = useTranslate('TeachingStudents');

  const columns = useColumns({ t });
  const exportDataColumns = useExportDataColumns();

  const { getTableProps, onFiltersChange } = useFetchWithPagination({
    fetch: fetchTeachingStudents,
    onError: (error) => console.error(error)
  });

  const onFiltersChange_ = async (values) => {
    const { level, major, advisorName } = values;

    if (!isEmpty(values)) {
      const filters = (level && level.length) || (major && major.length) || (advisorName && advisorName.length) ? {
        level: level || [],
        major: major || [],
        advisorName: advisorName || []
      } : {};

      setFilters(filters);
      onFiltersChange({
        filters: {
          level: level && level.length ? level : undefined,
          major: major && major.length ? major : undefined,
          advisorName: advisorName && advisorName.length ? advisorName : undefined
        }
      });
    } else {
      setFilters(values);
      onFiltersChange(values);
    }
  };

  return (
    <>
      <Filters
        border
        values={filters}
        onFiltersChange={onFiltersChange_}
      >
        <Filters.Row>
          <Filters.Select
            multiple
            name="level"
            label={tTeachingStudents('student.level')}
            options={STUDENT_LEVEL_OPTIONS}
          />
          <Filters.Select
            multiple
            name="major"
            label={tTeachingStudents('student.major')}
            options={convertFiltersDataToSelectOptions(studentFilters?.major)}
          />
          <Filters.Select
            multiple
            name="advisorName"
            label={tTeachingStudents('student.advisor')}
            options={convertFiltersDataToSelectOptions(studentFilters?.advisorName)}
          />
        </Filters.Row>
      </Filters>

      <Table
        {...getTableProps({
          includeGlobalFilter: true
        })}
        id={createTableId('teachingStudents')}
        translate={{
          searchPlaceholder: t('searchPlaceholder'),
          resultText: ({ totalCount }) => t('resultText', { totalCount }),
          selectedResultText: ({ totalCount, selectedRowsCount }) => t('selectedResultText', {
            totalCount,
            selectedRowsCount
          })
        }}
        columns={columns}
        columnPinningRight={['actions']}
        enableRowSelection
        hasColumnVisibility
        exportOptions={{
          type: 'xlsx',
          fileName: t('fileNameTeachingStudent'),
          exportDataColumns,
          fetchDataForExport: (ids) => getFetchStudentsForExport(ids.map(Number))
        }}
      />
    </>
  );
};
export default TeachingStudents;

function getFetchStudentsForExport(ids) {
  const pageSize = 1000;
  let page = 1;
  let allRecords = [];

  function fetchRecords() {
    // eslint-disable-next-line
    const query: any = { page, pageSize };

    // If any rows not selected, export all student rows
    if (ids.length > 0) {
      query.ids = ids; // knownStudentIds
    }

    return fetchTeachingStudentsForExport(query).then((records) => {
      allRecords = allRecords.concat(records);
      page += 1;

      if (records.length === pageSize) {
        return fetchRecords();
      } 
      return allRecords;
      
    });
  }

  return fetchRecords();
}

const useColumns = ({ t }) => {
  return useMemo(() => [
    {
      id: 'student',
      header: t('student'),
      enableSorting: true,
      accessorFn: (row) => formatName(row),
      cell: ({ row }) => (
        <DataBlock
          type="student"
          student={row.original}
          url={`/students/teaching/${row.original.id}`}
        />
      )
    },
    {
      header: t('studentEmail'),
      accessorKey: 'email',
      cell: ({ getValue }) => <Email address={getValue()} />
    },
    {
      accessorKey: 'level',
      header: t('level'),
      enableSorting: true
    },
    {
      accessorKey: 'major',
      header: t('major'),
      enableSorting: true
    },
    {
      accessorKey: 'advisorName',
      header: t('advisor')
    },
    {
      accessorKey: 'institution',
      header: t('homeInstitution'),
      cell: ({ row }) => <DataBlock type="institution" institution={row.original.institution} />
    },
    {
      accessorKey: 'notes',
      header: t('notes'),
      cell: ({ getValue }) => {
        let value = getValue();
        if (typeof value === 'string' && value) {
          value = <ShowMoreWrapper limit={10}>{value}</ShowMoreWrapper>;
        }
        return value || <Blank />;
      }
    },
    {
      id: 'actions',
      size: 60,
      cell: ({ row, downloadRow }) => {
        return (
          <Actions
            variant="kebab"
            actions={[
              {
                title: t('seeDetails', { global: true }),
                url: `/students/teaching/${row.original.id}`
              },
              {
                title: t('download', { global: true }),
                onClick: downloadRow
              }
            ]}
          />
        );
      }
    }
  ], [t]);
};

export const useExportDataColumns = () => {
  return [
    {
      id: 'Home Institution',
      value: row => row.institution.name
    }, {
      id: 'HI Student ID',
      value: row => row.hiStudentId
    }, {
      id: 'Acadeum Student ID',
      value: row => row.id
    }, {
      id: 'First Name',
      value: row => row.firstName
    }, {
      id: 'Middle Name',
      value: row => row.middleName
    }, {
      id: 'Last Name',
      value: row => row.lastName
    }, {
      id: 'Phone',
      value: row => row.phone
    }, {
      id: 'Email',
      value: row => row.email
    }, {
      id: 'Address Line 1',
      value: row => row.addressLine1
    }, {
      id: 'Address Line 2',
      value: row => row.addressLine2
    }, {
      id: 'City',
      value: row => row.city
    }, {
      id: 'State',
      value: row => row.state
    }, {
      id: 'Postal Code',
      value: row => row.postalCode
    }, {
      id: 'Major',
      value: row => row.major
    }, {
      id: 'Level',
      value: row => row.level
    }, {
      id: 'Gender',
      value: row => row.gender
    }, {
      id: 'Date of Birth',
      value: row => row.dob
    }, {
      id: 'Session Start Date',
      value: row => row.startDate
    }, {
      id: 'US Citizenship',
      value: row => row.citizenship
    }, {
      id: 'State Residency',
      value: row => row.residency
    }, {
      id: 'Ethnicity',
      value: row => row.ethnicity
    }, {
      id: 'Race',
      value: row => row.races
    }, {
      id: 'Advisor Name',
      value: row => row.advisorName
    }, {
      id: 'Advisor Email',
      value: row => row.advisorEmail
    }, {
      id: 'Notes',
      value: row => row.notes
    }
  ];
};
