import classNames from 'classnames';
import React, { memo } from 'react';

import styles from './CircularProgress.module.scss';

type Size = 'sm' | 'md';
const sizeClasses: Record<Size, string> = {
  sm: styles.sm,
  md: styles.md
};


export interface CircularProgressProps {
  className?: string;
  size?: Size;
}

export const CircularProgress = memo<CircularProgressProps>(({
  className,
  size = 'md'
}) => {
  return (
    <div
      className={classNames(
        className,
        styles.CircularProgress,
        sizeClasses[size]
      )}
    />
  );
});
