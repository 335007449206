import Alert from '../../../components/Alert/Alert.en.json';
import ChangeInstitutionModal from '../../../components/ChangeInstitutionModal/ChangeInstitutionModal.en.json';
import CloseButton from '../../../components/CloseButton/CloseButton.en.json';
import ConsortiumAutocomplete from '../../../components/ConsortiumAutocomplete/ConsortiumAutocomplete.en.json';
import DownloadButton from '../../../components/DownloadButton/DownloadButton.en.json';
import ErrorPage from '../../../components/ErrorPage/ErrorPage.en.json';
import GradingScale from '../../../components/GradingScale/GradingScale.en.json';
import InfoTooltip from '../../../components/InfoTooltip/InfoTooltip.en.json';
import InstitutionAccreditationAutocomplete from '../../../components/InstitutionAccreditationAutocomplete/InstitutionAccreditationAutocomplete.en.json';
import InstitutionAffiliationAutocomplete from '../../../components/InstitutionAffiliationAutocomplete/InstitutionAffiliationAutocomplete.en.json';
import InstitutionAutocomplete from '../../../components/InstitutionAutocomplete/InstitutionAutocomplete.en.json';
import Price from '../../../components/Price/Price.en.json';
import ShowMoreButton from '../../../components/ShowMoreButton/ShowMoreButton.en.json';
import StickyFormFooter from '../../../components/StickyFormFooter/StickyFormFooter.en.json';
import Table from '../../../components/Table/Table.en.json';

const localization = {
  ErrorPage,
  InstitutionAccreditationAutocomplete,
  InstitutionAffiliationAutocomplete,
  ChangeInstitutionModal,
  ShowMoreButton,
  Alert,
  DownloadButton,
  CloseButton,
  GradingScale,
  InfoTooltip,
  InstitutionAutocomplete,
  StickyFormFooter,
  ConsortiumAutocomplete,
  Price,
  Table
};

const locale = {
  ui: localization
};

export default locale;
