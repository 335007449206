import classNames from 'classnames';
import React from 'react';

import { elementChildren } from '@acadeum/helpers';

import type { MarginsProp } from '../../utils/useMargin';
import { useMargin } from '../../utils/useMargin';

import styles from './ButtonGroup.module.scss';
import { Item } from './ui/Item';


export interface ButtonGroupProps extends Pick<MarginsProp, 'mt' | 'mr' | 'mb' | 'ml'> {
  children?: React.ReactNode;
  fullWidth?: boolean;
  className?: string;
}

export const ButtonGroup: React.FC<ButtonGroupProps> = ({
  children,
  fullWidth,
  className,
  mb,
  mr,
  ml,
  mt
}) => {
  const contents = elementChildren(children).map((child, index) => (
    <Item key={index} button={child}/>
  ));

  const { marginClassNames } = useMargin({ mb, mr, ml, mt });

  return (
    <div
      data-buttongroup-full-width={fullWidth}
      className={classNames(className, marginClassNames, styles.ButtonGroup)}
    >
      {contents}
    </div>
  );
};
