import PropTypes from 'prop-types';
import React from 'react';

import Link from '../Link';

import './Breadcrumbs.sass';

export default function Breadcrumbs({ children, ...rest }) {
  return (
    <nav {...rest} aria-label="Breadcrumbs">
      <ul className="breadcrumbs">
        {children.map((breadcrumb, i) => {
          if (typeof breadcrumb === 'string') {
            return (
              <li key={i}>
                {breadcrumb}
              </li>
            );
          }
          return (
            <li key={i}>
              <Link to={breadcrumb[1]}>
                {breadcrumb[0]}
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
}

Breadcrumbs.propTypes = {
  className: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]))
};
