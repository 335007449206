import type { RtkApi } from '@acadeum/redux';
import type { Course, CourseSubstitute, EquivalentCourse, Id, Institution } from '@acadeum/types';

export type FetchAllCourseSubstitutesOutput = Array<Pick<Course, 'id' | 'code' | 'hours' | 'level' | 'title'> & {
  institution: Pick<Institution, 'id' | 'name'>
  courseSubstitutes: Array<
    Pick<CourseSubstitute, 'id' | 'createdAt'> &
    {
      equivalentCourse: Pick<EquivalentCourse, 'code' | 'title'>
    }
  >
}>

interface FetchAllCourseSubstitutesInput {
  institutionId?: Id;
}

export const injectCourseSubstitutesEndpoints = (rtkApi: RtkApi) =>
  rtkApi
    .enhanceEndpoints({ addTagTypes: ['courseSubstitutes'] })
    .injectEndpoints({
      endpoints: build => ({
        fetchAllCourseSubstitutes: build.query<FetchAllCourseSubstitutesOutput, FetchAllCourseSubstitutesInput>({
          query: (params) => ({
            url: '/data/course-substitutes',
            params
          }),
          providesTags: ['courseSubstitutes']
        })
      })
    });

export type UseLazyFetchAllCourseSubstitutesQuery = ReturnType<typeof injectCourseSubstitutesEndpoints>['useLazyFetchAllCourseSubstitutesQuery'];
