import type { CSSProperties } from 'react';
import { useCallback, useEffect, useId, useRef, useState } from 'react';

import { useDebounce } from '@acadeum/hooks';

const ANIMATION_DURATION = 400;

export function useAccordion({ defaultOpen }) {
  const id = useId();
  const controlId = `accordion-control-${id}`;
  const panelId = `accordion-panel-${id}`;

  const panelRef = useRef<HTMLDivElement>();

  const [show, setShow] = useState<boolean>(() => defaultOpen);
  const debouncedShow = useDebounce(show, ANIMATION_DURATION);
  const [height, setHeight] = useState<string>('0px');
  const [panelStyle, setPanelStyle] = useState<CSSProperties>({});

  useEffect(() => {
    if (show) {
      if (panelRef.current) {
        setHeight(`${panelRef.current?.scrollHeight}px`);
      }
    }
  }, []);

  const onToggle = useCallback(() => {
    const panelDivElement = panelRef.current;
    if (panelDivElement) {
      if (show) {
        setPanelStyle(prevState => ({ ...prevState, maxHeight: `${panelDivElement.scrollHeight}px` }));
      }
      setTimeout(() => {
        setShow(!show);
        setHeight(`${show ? 0 : panelDivElement.scrollHeight}px`);
      }, ANIMATION_DURATION + 100);
    } else {
      setShow(!show);
    }
  }, [show]);


  useEffect(() => {
    if (panelRef.current) {
      let newHeight = height;
      setPanelStyle({
        maxHeight: newHeight,
        // Set the visibility to hidden when the panel is closed
        // to prevent set focus on hidden elements when using the keyboard
        visibility: show || debouncedShow ? 'visible' : 'hidden',
        // Set the overflow to visible when the panel is open to not hide the focus-ring
        overflow: show && debouncedShow ? 'visible' : 'hidden'
      });

      setTimeout(() => {
        if (show && panelRef.current && panelRef.current.scrollHeight === parseInt(height)) {
          newHeight = '100%';
          setPanelStyle(prevState => ({ ...prevState, maxHeight: newHeight }));
        }
      }, ANIMATION_DURATION + 100);
    } else {
      setPanelStyle({
        display: show ? undefined : 'none'
      });
    }
  }, [show, debouncedShow, height]);

  return { id, show, onToggle, panelRef, panelStyle, controlId, panelId };
}
