import classNames from 'classnames';
import React, { useEffect } from 'react';

import BModalFooter from 'react-bootstrap/ModalFooter';

import { useModalUI } from './context/hooks';

import styles from './Modal.module.scss';

export interface ModalFooterProps {
  className?: string;
  children?: React.ReactNode;
  paddingTop?: 'large';
  pressed?: boolean;
  justify?: 'center' | 'start';
}

export const ModalFooter: React.FC<ModalFooterProps> = ({ className, children, paddingTop, pressed, justify }) => {
  const { hasScroll, setHasFooter } = useModalUI();

  useEffect(() => {
    setHasFooter(true);
  }, []);

  return (
    <BModalFooter className={classNames(styles.ModalFooter, className, {
      [styles['ModalFooter--hasScroll']]: hasScroll,
      [styles['ModalFooter--pressed']]: pressed,
      [styles['ModalFooter--paddingTopLarge']]: paddingTop === 'large',
      [styles[`ModalFooter--justify-${justify}`]]: justify
    })}>
      {children}
    </BModalFooter>
  );
};
