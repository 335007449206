import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { UserAdminPage } from '@acadeum/shared-admin-ui';

import actions from '../../actions';
import { formatUserName } from '../../helpers/format';
import type { ReduxState } from '../../helpers/app.types';

const {
  fetchUser,
  fetchUserRoles
} = actions;

const User = () => {
  const { user } = useSelector((state: ReduxState) => state.users);
  return (
    <UserAdminPage
      userId={user.id}
    />
  );
};

export default User;

User.propTypes = {
  user: PropTypes.object
};

User.meta = ({ useSelector }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const user = useSelector(state => state.users.user);
  return {
    title: formatUserName(user)
  };
};

User.load = async ({ params: { id } }) => {
  await Promise.all([
    fetchUser(id, { allSettings: true }),
    fetchUserRoles()
  ]);
};
