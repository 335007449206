import type { RtkApi } from '@acadeum/redux';
import type { User, UserAccountRequest } from '@acadeum/types';

export interface CreateUserAccountRequestInput extends Pick<User,
  'firstName'
  | 'lastName'
  | 'title'
  | 'email'
  | 'phone'
  | 'phoneExt'
> {
  institutionId: number;
  singleSignOn: boolean;
}

interface FetchUserAccountRequestsInput {
  types?: ('PENDING' | 'DENIED' | 'APPROVED')[];
  page?: number;
  pageSize: number;
  search?: string;
}

export interface FetchUserAccountRequestsOutput {
  page: number;
  pageSize: number;
  totalCount: number;
  results: Array<Pick<UserAccountRequest,
    'id'
    | 'firstName'
    | 'middleName'
    | 'lastName'
    | 'email'
    | 'title'
    | 'status'
    | 'createdAt'
    | 'updatedAt'
  > & {
    updatedBy: Pick<User, 'id' | 'firstName' | 'middleName' | 'lastName' | 'title'>
  }>;
}

interface ApproveUserAccountRequestInput {
  ids: number[],
  roleIds: number[]
}

interface DenyUserAccountRequestInput {
  ids: number[],
  notes: string
}

export const injectUserAccountRequestsEndpoints = (rtkApi: RtkApi) => rtkApi
  .enhanceEndpoints({ addTagTypes: ['userAccountRequests'] })
  .injectEndpoints({
    endpoints: build => ({
      createUserAccountRequest: build.mutation<void, CreateUserAccountRequestInput>({
        query: (body) => ({
          url: '/user-account-requests',
          method: 'POST',
          body
        })
      }),
      fetchUserAccountRequests: build.query<FetchUserAccountRequestsOutput, FetchUserAccountRequestsInput>({
        query: (params) => ({
          url: '/user-account-requests',
          method: 'GET',
          params
        }),
        providesTags: [{ type: 'userAccountRequests', id: 'LIST' }]
      }),
      approveUserAccountRequests: build.mutation<void, ApproveUserAccountRequestInput>({
        query: (body) => ({
          url: '/user-account-requests/approve',
          method: 'POST',
          body
        }),
        invalidatesTags: [{ type: 'userAccountRequests', id: 'LIST' }]
      }),
      denyUserAccountRequests: build.mutation<void, DenyUserAccountRequestInput>({
        query: (body) => ({
          url: '/user-account-requests/deny',
          method: 'POST',
          body
        }),
        invalidatesTags: [{ type: 'userAccountRequests', id: 'LIST' }]
      })
    })
  });

export type UseCreateUserAccountRequestMutation = ReturnType<typeof injectUserAccountRequestsEndpoints>['useCreateUserAccountRequestMutation'];
export type UseFetchUserAccountRequestsQuery = ReturnType<typeof injectUserAccountRequestsEndpoints>['useFetchUserAccountRequestsQuery'];
export type UseApproveUserAccountRequestsMutation = ReturnType<typeof injectUserAccountRequestsEndpoints>['useApproveUserAccountRequestsMutation'];
export type UseDenyUserAccountRequestsMutation = ReturnType<typeof injectUserAccountRequestsEndpoints>['useDenyUserAccountRequestsMutation'];
