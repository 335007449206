import type { UserRole, UserRolePermissionSubject } from '@acadeum/types';

import { PERMISSION_OPERATIONS } from './consts';
import type { FormPermissionValues } from './types';

export function prepareFormPermissionsToApiStandard(permissions: FormPermissionValues): UserRole['permissions'] {
  return Object.entries(permissions).reduce<UserRole['permissions']>((result, [subject, values]) => {
    for (const permissionOperation of PERMISSION_OPERATIONS) {
      const scope = values[permissionOperation];
      if (typeof scope === 'string') {
        result.push({
          subject: subject as UserRolePermissionSubject,
          scope,
          operations: [permissionOperation]
        });
      }
    }
    return result;
  }, []);
}
