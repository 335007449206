import classNames from 'classnames';
import { isEmpty } from 'lodash-es';
import React, { memo } from 'react';
import { useFormState } from 'react-hook-form';

import { useTranslate } from '@acadeum/translate';

import { FormFooter } from '../FormFooter';
import type { FormResetProps } from '../FormReset';
import { FormReset } from '../FormReset';
import { FormSubmit } from '../FormSubmit';
import type { FormSubmitProps } from '../FormSubmit';
import { Sticky } from '../Sticky';

import styles from './StickyFormFooter.module.scss';

export interface StickyFormFooterProps {
  onBackProps?: FormSubmitProps;
  onCancelProps?: FormSubmitProps;
  submitProps?: FormSubmitProps;
  resetProps?: FormResetProps;
  fixed?: boolean;
  visibleWhenFormChanged?: boolean;
}

export const StickyFormFooter: React.FC<StickyFormFooterProps> = memo(({
  onBackProps,
  onCancelProps,
  submitProps,
  resetProps,
  fixed,
  visibleWhenFormChanged
}) => {
  const t = useTranslate('ui.StickyFormFooter');
  const { isDirty } = useFormState();

  if (visibleWhenFormChanged && !isDirty) {
    return null;
  }

  return (
    <Sticky fixed={fixed} position="bottom">
      {({ isSticky }) => (
        <FormFooter marginTop={isSticky ? 'none' : undefined}>
          {onCancelProps && (
            <FormSubmit
              className={classNames({
                [styles['StickyFormFooter__cancelLeft']]: !isEmpty(onBackProps)
              })}
              {...onCancelProps}
              action={onCancelProps.action || 'cancel'}
              type={onCancelProps.action ? 'submit' : 'button'}
              variant={isEmpty(onBackProps) ? 'secondary' : 'text'}
            >
              {t('cancel')}
            </FormSubmit>
          )}
          {onBackProps && (
            <FormSubmit
              disabledWhenInvalid
              {...onBackProps}
              action={onBackProps.action || 'back'}
              type={onBackProps.action ? 'submit' : 'button'}
              variant="secondary"
            >
              {t('back')}
            </FormSubmit>
          )}
          {resetProps && (
            <FormReset
              variant="secondary"
              {...resetProps}
            />
          )}
          <FormSubmit {...submitProps}>
            {submitProps?.children || t('submit')}
          </FormSubmit>
        </FormFooter>
      )}
    </Sticky>
  );
});
