import type { RtkApi } from '@acadeum/redux';
import type { Email, Id, Institution, Password, StudentUser, User } from '@acadeum/types';

import { adminApi } from '../../adminApi';

interface StudentUserChangeInstitutionInput {
  /**
   * Student's email address
   * */
  email: Email;
  /**
   * New Home Institution ID
   * */
  institutionId: Id;
}

interface FetchAdminStudentUsersOutputListItem extends Pick<User, 'firstName' | 'email' | 'emailVerified' | 'id' | 'isActive' | 'lastName' | 'latestLoginDate'> { 
  hasPassword: boolean;
  institution: Pick<Institution, 'id'| 'name'| 'vanityUrl'>;
  singleSignOn: boolean;
}

export interface FetchAdminStudentUsersOutput {
  ADMIN_CENTRE?: true;
  page?: number;
  pageSize: number;
  sort?: { by: string; asc: boolean; }[];
  search?: string;
  results: FetchAdminStudentUsersOutputListItem[];
}

interface FetchAdminStudentUsersInput {
  /**
   * Requested results page
   * */
  page: number;
  /**
   * Results page size
   * */
  pageSize: number;
  /**
   * Total results count
   * */
  totalCount: number;
  /**
   * A list of student users
   * */
  results: Array<Omit<StudentUser, 'institution'> & {
    institution: Pick<Institution, 'id' | 'name' | 'vanityUrl'>; /**
     * Whether the student has set up their password
     * */
    hasPassword: boolean
    /**
     * Whether the student's institution uses Single Sign On for authentication
     * */
    singleSignOn: boolean;
  }>;
}

export interface FetchAdminStudentUserOutput extends StudentUser {
  /**
   * Whether the student has set up their password
   * */
  hasPassword: boolean;
  /**
   * Whether the student's institution uses Single Sign On for authentication
   * */
  singleSignOn: boolean;
  institution: Pick<Institution, 'id' | 'name' | 'vanityUrl'>
}

export const injectAdminStudentsUserEndpoints = (rtkApi: RtkApi) => rtkApi
  .injectEndpoints({
    endpoints: build =>({
      adminChangeStudentUserInstitution: build.mutation<null, StudentUserChangeInstitutionInput>({
        query: body => ({
          url: '/admin/student-users/change-institution',
          method: 'POST',
          body
        })
      }),
      fetchAdminStudentUsers: build.query<FetchAdminStudentUsersOutput, FetchAdminStudentUsersInput>({
        query: body => ({
          url: '/admin/student-users',
          params: {...body, ADMIN_CENTER: true}
        })
      }),
      fetchAdminStudentUser: build.query<FetchAdminStudentUserOutput, { id: Id }>({
        query: ({id}) => `/admin/student-users/${id}`
      }),
      studentUserRequestVerifyEmail: build.mutation<null, { id: Id }>({
        query: ({ id }) => ({
          url: `/admin/student-users/${id}/request-verify-email`,
          method: 'POST'
        })
      }),
      studentUserVerifyEmail: build.mutation<null, { id: Id }>({
        query: ({ id }) => ({
          url: `/admin/student-users/${id}/verify-email`,
          method: 'POST'
        })
      }),
      studentUserRequestPasswordReset: build.mutation<null, { id: Id }>({
        query: ({ id }) => ({
          url: `/admin/student-users/${id}/request-password-reset`,
          method: 'POST'
        })
      }),
      studentUserResetPassword: build.mutation<null, { id: Id, password: Password }>({
        query: ({ id, password }) => ({
          url: `/admin/student-users/${id}/reset-password`,
          method: 'POST',
          body: { password }
        })
      })
    })
  });

export type UseFetchAdminStudentUsersQuery = ReturnType<typeof injectAdminStudentsUserEndpoints>['useFetchAdminStudentUsersQuery'];

export const {
  useFetchAdminStudentUsersQuery,
  useStudentUserRequestPasswordResetMutation,
  useStudentUserRequestVerifyEmailMutation,
  useStudentUserVerifyEmailMutation,
  useStudentUserResetPasswordMutation
} = injectAdminStudentsUserEndpoints(adminApi);
