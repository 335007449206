import PropTypes from 'prop-types';
import React from 'react';

import { T } from '@acadeum/translate';

import Link from '../Link';

import styles from './PublicMarketingPageBannerSection.module.scss';

export default function PublicMarketingPageBannerSection({
  icon: Icon,
  title,
  points,
  readMoreUrl,
  readMoreUrlTitle
}) {
  return (
    <section className={styles.PublicMarketingPageBannerSection}>
      <Icon className={styles.PublicMarketingPageBannerSection__icon}/>
      <h3 className={styles.PublicMarketingPageBannerSection__title}>
        {title}
      </h3>

      <ul className={styles.PublicMarketingPageBannerSection__points}>
        {points.map((value, i) => (
          <li key={i} className={styles.PublicMarketingPageBannerSection__points__item}>
            {value}
          </li>
        ))}
      </ul>

      <p className={styles.PublicMarketingPageBannerSection__footer}>
        <T
          label="PublicMarketingPageBannerSection.description"
          link={readMoreUrlTitle}
          linkTag={(children) => (
            <Link to={readMoreUrl} target="_blank">
              {children}
            </Link>
          )}
        />
      </p>
    </section>
  );
}

PublicMarketingPageBannerSection.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired,
  points: PropTypes.arrayOf(PropTypes.string).isRequired,
  readMoreUrl: PropTypes.string.isRequired,
  readMoreUrlTitle: PropTypes.string.isRequired
};
