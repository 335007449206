import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-responsive-ui';

import Link from '../Link';

import './SectionHeaderAction.sass';

import DownloadIcon from '../../../assets/images/icons/download.svg';
import LinkIcon from '../../../assets/images/icons/external-thick.svg';
import PencilIcon from '../../../assets/images/icons/pencil.svg';

export default function Action({
  icon,
  iconSize,
  link,
  linkOpensInNewTab,
  onClick,
  title
}) {
  if (typeof icon === 'string') {
    icon = getIconByName(icon);
  }
  const ActionIcon = icon;
  let Component;
  if (link) {
    Component = Link;
  } else {
    Component = Button;
  }
  return (
    <div className="section-header-action">
      <Component
        target={Component === Link && linkOpensInNewTab ? '_blank' : undefined}
        to={Component === Link ? link : undefined}
        onClick={Component === Button ? onClick : undefined}
        className="section-header-action__inner">
        <ActionIcon className={classNames(
          'action__icon',
          'section-header-action__icon',
          iconSize && `action__icon--${iconSize}`
        )} />
        <span className="section-header-action__title">
          {title}
        </span>
      </Component>
    </div>
  );
}

const actionShape = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string
  ]).isRequired,
  iconSize: PropTypes.string,
  onClick: PropTypes.func,
  link: PropTypes.string,
  linkOpensInNewTab: PropTypes.bool
};

Action.propTypes = {
  ...actionShape
};

export const actionPropType = PropTypes.shape(actionShape);

function getIconByName(iconName) {
  switch (iconName) {
    case 'edit':
      return PencilIcon;
    case 'download':
      return DownloadIcon;
    case 'link':
      return LinkIcon;
  }
}
