import canCompleteCourseEnrollment from 'common-lib/lib/canCompleteCourseEnrollment';
import canDropFromCourseEnrollment from 'common-lib/lib/canDropFromCourseEnrollment';
import canRemoveCourseEnrollment from 'common-lib/lib/canRemoveCourseEnrollment';
import canWithdrawFromCourseEnrollment from 'common-lib/lib/canWithdrawFromCourseEnrollment';

import type { FetchCourseEnrollmentsOutputItem } from '@acadeum/api';
import type { CourseEnrollment } from '@acadeum/types';

import type { CourseEnrollmentMode } from '../types';

export function getCourseEnrollmentStatusTransitions({
  enrollment,
  mode
}: {
  mode: CourseEnrollmentMode;
  enrollment: FetchCourseEnrollmentsOutputItem
}) {
  const toStatuses: CourseEnrollment['status'][] = [];

  if (mode === 'homeInstitution') {
    if (canRemoveCourseEnrollment({
      status: enrollment.status
    })) {
      toStatuses.push('REMOVED');
    }
  }

  if (canDropFromCourseEnrollment({
    status: enrollment.status,
    startedAt: enrollment.startedAt,
    lastDropDate: enrollment.section.lastDropDate,
    onDemand: enrollment.section.course.onDemand,
    onDemandDropDateDaysAfterStartDate: enrollment.section.course.institution.onDemandCourseDropDateDaysAfterStartDate
  })) {
    toStatuses.push('DROPPED');
  }

  if (canWithdrawFromCourseEnrollment({
    status: enrollment.status,
    onDemand: enrollment.section.course.onDemand,
    onDemandDropDateDaysAfterStartDate: enrollment.section.course.institution.onDemandCourseDropDateDaysAfterStartDate,
    startedAt: enrollment.startedAt,
    lastDropDate: enrollment.section.lastDropDate,
    lastWithdrawalDate: enrollment.section.lastWithdrawalDate
  })) {
    toStatuses.push('WITHDRAWN');
  }

  if (mode === 'teachingInstitution') {
    if (canCompleteCourseEnrollment({
      status: enrollment.status,
      onDemand: enrollment.section.course.onDemand
    })) {
      toStatuses.push('COMPLETE');
    }
  }

  return toStatuses;
}
