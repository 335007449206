// Copy-pasted from `react-instantsearch`.
// https://github.com/algolia/react-instantsearch/blob/81a6f1856ae28b4ab00ba660c548037818749220/packages/react-instantsearch-dom/src/components/ClearRefinements.js
// Version 5.5
//
// Changes:
//
// * Added `type="button"`.
// * Added `TrashIcon` icon
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { translatable } from 'react-instantsearch-core';

import { TrashIcon } from '@acadeum/icons';
import { Button } from '@acadeum/ui';

import { createClassNames } from '../core/utils';


const cx = createClassNames('ClearRefinements');

class ClearRefinements extends Component {
  static propTypes = {
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    canRefine: PropTypes.bool.isRequired,
    refine: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
    className: PropTypes.string
  };

  render() {
    const { items, canRefine, refine, translate, className } = this.props;

    return (
      <Button
        icon={TrashIcon}
        variant="text"
        onClick={() => refine(items)}
        disabled={!canRefine}
        className={classNames(cx(''), className)}
      >
        {translate('reset')}
      </Button>
    );
  }
}

export default translatable({
  reset: 'Clear all filters'
})(ClearRefinements);
