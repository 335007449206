import type { InferProps } from 'prop-types';
import PropTypes from 'prop-types';
import React, { useMemo, useRef, useEffect } from 'react';


import COUNTRY_REGIONS from 'common-lib/constants/countryRegions.json';

import type { Options } from '@acadeum/types';

const propTypes = {
  Component: PropTypes.elementType.isRequired,
  country: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  includeNotAvailableOption: PropTypes.bool,
  notAvailableLabel: PropTypes.string.isRequired,
  noCountrySelectedLabel: PropTypes.string.isRequired
};

type CountryRegionSelectProps = InferProps<typeof propTypes>

// eslint-disable-next-line
export const CountryRegionSelect = React.forwardRef<any, CountryRegionSelectProps>(({
  country,
  includeNotAvailableOption,
  notAvailableLabel,
  Component,
  onChange,
  ...rest
}, ref) => {
  const options = useMemo(() => {
    let options: Options<string> = [];
    if (country && COUNTRY_REGIONS[country]) {
      options = options.concat(COUNTRY_REGIONS[country].map((region) => ({
        label: region,
        value: region
      })));
    }
    if (includeNotAvailableOption || !country || !COUNTRY_REGIONS[country]) {
      options = [{ label: notAvailableLabel, value: NOT_AVAILABLE_VALUE }].concat(options);
    }
    return options;
  }, [
    country,
    includeNotAvailableOption,
    notAvailableLabel
  ]);

  const hasMounted = useRef(false);
  useEffect(() => {
    if (hasMounted.current) {
      if (!country) {
        onChange();
      } else if (COUNTRY_REGIONS[country]) {
        onChange();
      } else {
        onChange(NOT_AVAILABLE_VALUE);
      }
    }
  }, [country]);

  useEffect(() => {
    hasMounted.current = true;
  }, []);

  return (
    <Component
      ref={ref}
      options={options}
      onChange={onChange}
      {...rest} />
  );
});

CountryRegionSelect.propTypes = propTypes;

const NOT_AVAILABLE_VALUE = 'N/A';
