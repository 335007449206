import type { FC } from 'react';
import React from 'react';

import courseEnrollmentAcceptedStatuses from 'common-lib/constants/courseEnrollmentAcceptedStatuses.json';

import { useTranslate } from '@acadeum/translate';
import type { CourseEnrollment } from '@acadeum/types';
import { Tag, Tooltip } from '@acadeum/ui';

import type { CourseEnrollmentMode } from '../../types';

export interface CourseEnrollmentStatusBadgeProps {
  mode: CourseEnrollmentMode;
  enrollment: Pick<CourseEnrollment, 'status'>;
}

export const CourseEnrollmentStatusBadge: FC<CourseEnrollmentStatusBadgeProps> = ({
  enrollment,
  mode
}) => {
  const status = enrollment.status;
  const tooltip = useStatusTooltip({ status, mode });

  if (tooltip) {
    return (
      <Tooltip content={tooltip}>
        <Tag variant={status}/>
      </Tooltip>
    );
  }

  return (
    <Tag variant={status}/>
  );
};

interface UseStatusTooltipOptions {
  status: CourseEnrollment['status'];
  mode: CourseEnrollmentMode;
}

function useStatusTooltip({
  status,
  mode
}: UseStatusTooltipOptions) {
  const t = useTranslate('CourseEnrollmentsTable');

  if (mode === 'teachingInstitution') {
    switch (status) {
      case 'PENDING':
        return t('status.teachingInstitution.accepted.pending.tooltip', {
          strong: children => <strong>{children}</strong>
        });
      case 'ACTIVE':
        return t('status.teachingInstitution.accepted.active.tooltip', {
          strong: (children) => (<strong>{children}</strong>)
        });
      case 'DUE':
        return t('status.teachingInstitution.accepted.due.tooltip', {
          strong: (children) => (<strong>{children}</strong>)
        });
      case 'COMPLETE':
        return t('status.teachingInstitution.finalized.complete.tooltip');
      case 'DENIED':
        return t('status.teachingInstitution.finalized.denied.tooltip');
      case 'WITHDRAWN':
        return t('status.teachingInstitution.finalized.withdrawn.tooltip');
      case 'DROPPED':
        return t('status.teachingInstitution.finalized.dropped.tooltip');
      case 'REMOVED':
        return t('status.teachingInstitution.finalized.removed.tooltip');
    }
  } else {
    switch (true) {
      case status === 'REQUESTED':
        return t('status.homeInstitution.live.requested.tooltip');
      case courseEnrollmentAcceptedStatuses.includes(status):
        return t('status.homeInstitution.live.accepted.tooltip');
      case status === 'COMPLETE':
        return t('status.homeInstitution.finalized.complete.tooltip');
      case status === 'DENIED':
        return t('status.homeInstitution.finalized.denied.tooltip');
      case status === 'WITHDRAWN':
        return t('status.homeInstitution.finalized.withdrawn.tooltip');
      case status === 'DROPPED':
        return t('status.homeInstitution.finalized.dropped.tooltip');
      case status === 'REMOVED':
        return t('status.homeInstitution.finalized.removed.tooltip');
    }
  }
}
