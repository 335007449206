import classNames from 'classnames';
import React, { useMemo, useRef } from 'react';

import { useControllableState } from '@acadeum/hooks';

import { Scrollable } from '../Scrollable';

import styles from './Tabs.module.scss';

export interface TabsProps<K extends string = string> {
  title: string;
  initialActiveTab?: K;
  tabs: Array<{
    key: K;
    title: string;
    body?: React.ReactNode | React.ComponentType
  }>;
  activeTab?: K;
  onChangeTab?: (options: { key: K; }) => void;
  theme?: 'black';
  /** Always keep the not active tabs in the DOM. Default: true */
  keepMounted?: boolean;
}

export const Tabs = <K extends string>({
  title,
  tabs,
  initialActiveTab,
  activeTab: propsActiveTab,
  onChangeTab: propsOnChangeTab,
  theme,
  keepMounted = true
}: TabsProps<K>) => {
  const listRef = useRef<HTMLDivElement>(null);

  const [activeTabKey, setActiveTabKey] = useControllableState({
    prop: propsActiveTab,
    defaultProp: initialActiveTab,
    onChange: (state) => {
      propsOnChangeTab?.({ key: state });
    }
  });

  const activeTabIndex = useMemo(() => {
    if (!activeTabKey) {
      return 0;
    }
    return tabs.findIndex(tab => tab.key === activeTabKey);
  }, [activeTabKey, tabs]);

  const onChangeTab = (index: number) => {
    if (index < 0 || index >= tabs.length) {
      return undefined;
    }
    setActiveTabKey(tabs[index].key);
  };

  const moveIndex = (step) => {
    let index = activeTabIndex;
    const tabs = (listRef.current?.children ? [...listRef.current.children] : []) as HTMLButtonElement[];
    if (step === 'start') {
      index = 0;
    } else if (step === 'end') {
      index = tabs.length - 1;
    } else {
      const nextIndex = index + step;
      const tabsLength = tabs.length - 1;

      if (nextIndex > tabsLength) {
        index = 0;
      } else if (nextIndex < 0) {
        index = tabsLength;
      } else {
        index = nextIndex;
      }
    }

    onChangeTab(index);
    tabs[index].focus();
  };

  const onKeyDown = (event) => {
    switch (event.keyCode) {
      case 35: // End
        event.preventDefault();
        return moveIndex('end');
      case 36: // Home
        event.preventDefault();
        return moveIndex('start');
      case 37: // Arrow Left
        return moveIndex(-1);
      case 39: // Arrow right
        return moveIndex(1);
    }
  };

  return (
    <div className={styles.tabs}>
      <Scrollable>
        <div
          className={classNames(styles.tabs__list, {
            [styles['tabs__listBlack']]: theme === 'black'
          })}
          role="tablist"
          ref={listRef}
          aria-label={title}
        >
          {tabs.map((tab, index) => (
            <button
              role="tab"
              type="button"
              key={tab.key}
              id={tab.key}
              tabIndex={activeTabIndex === index ? 0 : -1}
              aria-selected={activeTabIndex === index}
              aria-controls={tab.key + '-tab'}
              className={styles.tabs__link}
              onClick={() => onChangeTab(index)}
              onKeyDown={onKeyDown}
            >
              {tab.title}
            </button>
          ))}
        </div>
      </Scrollable>
      {tabs.map((tab, index) => {
        if (!keepMounted && activeTabIndex !== index) {
          return null;
        }

        return (
          <div
            key={tab.key}
            id={tab.key + '-tab'}
            role="tabpanel"
            tabIndex={0}
            aria-labelledby={tab.key}
            className={styles.tabs__section}
            hidden={activeTabIndex !== index}
          >
            {typeof tab.body === 'function' ? <tab.body/> : tab.body}
          </div>
        );
      })}
    </div>
  );
};
