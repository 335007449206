import { useMemo } from 'react';

import { getSelectionOptionsMessages } from '@acadeum/selection-options';
import { getSharedAdminUITranslations } from '@acadeum/shared-admin-ui';
import { getUiTranslates } from '@acadeum/ui';

import { getMessages, getTranslationLanguage } from '../translate';

export function useTranslations() {
  const language = useMemo(() => getTranslationLanguage(), []);
  const messages = useMemo(() => {
    return {
      ...getUiTranslates(language),
      ...getSharedAdminUITranslations(language),
      ...getSelectionOptionsMessages(language),
      ...getMessages(language)
    };
  }, [language]);

  return { messages, language };
}
