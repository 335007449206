import type { FC } from 'react';
import React from 'react';

import type { FetchTeachingCourseEnrollmentRequestsResponseItem } from '@acadeum/api';
import { getEnrollmentTypeLabel } from '@acadeum/helpers';
import { useTranslate } from '@acadeum/translate';
import type { FormModalProps} from '@acadeum/ui';
import { Card, DataBlock, FormModal, type OnSubmit, Tag, Text } from '@acadeum/ui';
import { Grid } from '@acadeum/ui';

import { useDenyTeachingCourseEnrollmentMutation } from '../../../../api/courseEnrollment';
import ReasonFormFieldUi from '../../../../components/ReasonFormFieldUi';

interface FormValues {
  reason: string;
  reasonNotes: string;
}

interface ModalDenyProps extends Pick<FormModalProps, 'show' | 'onHide'> {
  onAfterSubmit?: () => void;
  enrollmentRequest: FetchTeachingCourseEnrollmentRequestsResponseItem;
  enrollmentsToDeny: FetchTeachingCourseEnrollmentRequestsResponseItem['enrollments'];
}

export const DenyCourseEnrollmentModal: FC<ModalDenyProps> = ({
  show,
  onHide,
  onAfterSubmit,
  enrollmentRequest,
  enrollmentsToDeny
}) => {
  const t = useTranslate('EnrollmentRequests');
  const [denyTeachingCourseEnrollmentMutation] = useDenyTeachingCourseEnrollmentMutation();

  const onSubmit: OnSubmit<FormValues> = async ({
    reason,
    reasonNotes
  }) => {
    await denyTeachingCourseEnrollmentMutation({
      ids: enrollmentsToDeny.map(_ => _.id),
      enrollmentRequestId: enrollmentRequest.id,
      reason,
      reasonNotes
    }).unwrap();
    onAfterSubmit?.();
  };

  return (
    <FormModal
      show={show}
      size="wide"
      onHide={onHide}
      onBack={onHide}
      title={t('titleDeny')}
      onSubmit={onSubmit}
      submitText={t('deny')}
      onCancel={onHide}
    >
      <Card>
        <Text variant="subtitle2">
          {t('titleModal', {
            tag: () => <Tag>{getEnrollmentTypeLabel(enrollmentRequest.enrollments[0])}</Tag>
          })}
        </Text>
        <Grid
          container
          wrap="wrap"
          columns={24}
          rowSpacing={2}
        >
          <Grid size={7}>
            {t('course')}
          </Grid>
          <Grid size={17}>
            <DataBlock type="course" course={enrollmentRequest.section.course}/>
          </Grid>
          <Grid size={7}>
            {t('homeInstitution')}
          </Grid>
          <Grid size={17}>
            <DataBlock type="institution" hideLogo institution={enrollmentRequest.institution}/>
          </Grid>
          <Grid size={7}>
            {t('denyStudents', {
              checkedStudents: enrollmentsToDeny.length,
              allStudents: enrollmentRequest.enrollments.length
            })}
          </Grid>
          <Grid size={17}>
            {enrollmentsToDeny.map(_ => (
              <DataBlock
                key={_.id}
                type="student"
                showId={false}
                student={_.student}
              />
            ))}
          </Grid>
        </Grid>
      </Card>
      <ReasonFormFieldUi
        required
        labelsPath="EnrollmentRequest.denyReason"
        options={DENY_REASONS}
      />
    </FormModal>
  );
};

const DENY_REASONS = [
  'COURSE_AT_CAPACITY',
  'COURSE_CANCELLED',
  'NOT_ALIGN_WITH_REQUIREMENT'
];
