import { sortBy, uniq } from 'lodash-es';
import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { Select } from 'react-responsive-ui';

import '../../helpers/initializeCharts';
import actions from '../../actions';
import { getWeek } from '../../helpers/date';


import { getGraphOptions } from './helpers';

const { fetchWeeklyEnrollments } = actions;

export default function WeeklyEnrollments() {
  const [institutionId, setInstitutionId] = useState();

  const onSelectInstitution = (institutionId) => {
    setInstitutionId(institutionId);
    fetchWeeklyEnrollments(institutionId);
  };

  const weeklyEnrollmentsReport = useSelector(state => state.reports.weeklyEnrollments);

  if (!weeklyEnrollmentsReport) {
    return null;
  }

  const weeklyEnrollments = Object.values(weeklyEnrollmentsReport[0]);
  const institutions = Object.values(weeklyEnrollmentsReport[1]);

  const now = new Date();
  const currentYear = (now).getFullYear();
  const currentWeek = getWeek(now);

  const years = uniq(weeklyEnrollments.map(_ => _.year)).sort().reverse();

  const labels = [];
  for (let i = 0; i <= 52; i++) {
    labels.push(i);
  }

  const datasets = years.map(year => {
    const data = sortBy(weeklyEnrollments.filter(_ => _.year === year), 'week');
    const returnData = [];
    for (let i = 0; i <= 52; i++) {
      const d = data.filter(_ => _.week === i)[0];
      if (d) {
        returnData.push(d.count);
      } else if (year === currentYear && i >= currentWeek) {
        returnData.push(null);
      }
      else {
        returnData.push(0);
      }
    }
    return getGraphOptions(year, returnData);
  });

  const data = {
    labels,
    datasets
  };

  const datasetsSum = years.map(year => {
    const data = sortBy(weeklyEnrollments.filter(_ => _.year === year), 'week');
    const returnData = [];
    let sum = 0;
    for (let i = 0; i <= 52; i++) {
      const d = data.filter(_ => _.week === i)[0];
      if (d) {
        sum += d.count;
      }
      if (year === currentYear && i >= currentWeek) {
        returnData.push(null);
      } else {
        returnData.push(sum);
      }
    }
    return getGraphOptions(year, returnData);
  });

  const dataSum = {
    labels,
    datasets: datasetsSum
  };

  const datasetsDerivative = years.map(year => {
    const data = sortBy(weeklyEnrollments.filter(_ => _.year === year), 'week');
    const returnData = [];
    let pastChange = 0;
    for (let i = 0; i <= 52; i++) {
      const d = data.filter(_ => _.week === i)[0];
      if (d) {
        returnData.push(d.count-pastChange);
        pastChange = d.count;
      } else if (year === currentYear && i >= currentWeek) {
        returnData.push(null);
        pastChange = 0;
      }
      else {
        returnData.push(0);
        pastChange = 0;
      }
    }
    return getGraphOptions(year, returnData);
  });

  const dataDerivative = {
    labels,
    datasets: datasetsDerivative
  };

  return (
    <div>
      <h1>Enrolling Institution</h1>
      <Select
        options={[{ label: 'All' }].concat(institutions.map((institution) => ({
          value: institution.id,
          label: institution.name
        })))}
        onChange={onSelectInstitution}
        value={institutionId}
      />
      <br/>
      <h1>Weekly Enrollment Activity: Cumulative</h1>
      <Line data={dataSum} />
      <h1>Weekly Enrollment Activity: 1st Derivative</h1>
      <Line data={data} />
      <h1>Weekly Enrollment Activity: 2nd Derivative</h1>
      <Line data={dataDerivative} />
    </div>
  );
}

WeeklyEnrollments.meta = () => ({
  title: 'Weekly Enrollments'
});

WeeklyEnrollments.load = async () => {
  await fetchWeeklyEnrollments();
};

WeeklyEnrollments.breadcrumbs = () =>[
  ['Admin Tools', '/admin'],
  'Weekly Enrollments'
];
