import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './LinearProgress.sass';

export default function LinearProgress({
  className
}) {
  return (
    <div className={classNames('LinearProgress', 'LinearProgress--header', className)} role="progressbar">
      <div className="LinearProgress-bar LinearProgress-bar--1"/>
      <div className="LinearProgress-bar LinearProgress-bar--2"/>
    </div>
  );
}

LinearProgress.propTypes = {
  className: PropTypes.string
};
