import React from 'react';

import { decorateRouteComponent } from './helpers/decorateRouteComponent';

import AddStudentUser from './pages/AddStudentUser';
import AddUser from './pages/AddUser';
import AddUserRole from './pages/AddUserRole';
import AdminEditUser from './pages/AdminEditUser';
import AdminStudentUser from './pages/AdminStudentUser';
import AdminStudentUsers from './pages/AdminStudentUsers';
import AdminTools from './pages/AdminTools';
import AdminUser from './pages/AdminUser';
import SearchIndexManualUpdate from './pages/Algolia/Algolia';
import AnnualFees from './pages/AnnualFees';
import App from './pages/App';

import ErrorPage from './pages/Error';
import NotFound from './pages/NotFound';

import Title from './pages/Title';
import Help from './pages/Help';
import RequestCourse from './pages/RequestCourse';
import RequestCourseSection from './pages/RequestCourseSection';
import Course from './pages/Course';
import EditCourse from './pages/EditCourse';
import ApproveCourse from './pages/ApproveCourse';
import UploadCourses from './pages/UploadCourses';
import Courses from './pages/Courses';
import CourseSection from './pages/CourseSection';

import CreateEnrollment from './pages/CreateEnrollment';

import CreateAdvisorRecommendation from './pages/CreateAdvisorRecommendation';

import LogOut from './pages/LogOut';

import EditStudentUser from './pages/EditStudentUser';
import StudentUser from './pages/StudentUser';

import Students from './pages/Students';
import Student from './pages/Student';
import TeachingStudents from './pages/TeachingStudents';
import TeachingStudent from './pages/TeachingStudent';
import CreateStudentPage from './pages/CreateStudentPage';
import EditStudent from './pages/EditStudent';

import Institutions from './pages/Institutions';
import Institution from './pages/Institution';
import EditInstitution from './pages/EditInstitution';
import CreateInstitution from './pages/CreateInstitution';

import Consortia from './pages/Consortia';
import Consortium from './pages/Consortium';
import EditConsortium from './pages/EditConsortium';
import CreateConsortium from './pages/CreateConsortium';

import EnrollmentRequests from './pages/EnrollmentRequests';
import EnrollmentRequest from './pages/EnrollmentRequest';

import Enrollments from './pages/Enrollments';
import EnrollmentsLive from './pages/EnrollmentsLive';
import EnrollmentsFinalized from './pages/EnrollmentsFinalized';
import EnrollmentsTeaching from './pages/EnrollmentsTeaching';
import EnrollmentsTeachingAccepted from './pages/EnrollmentsTeachingAccepted';
import EnrollmentsTeachingFinalized from './pages/EnrollmentsTeachingFinalized';

import CreateUser from './pages/CreateUser';
import EditCurrentUser from './pages/EditCurrentUser';

import ChangeStudentUserInstitution from './pages/ChangeStudentUserInstitution';
import ChangeStudentEmail from './pages/ChangeStudentEmail';

import CourseRegistrationRequestsNew from './pages/CourseRegistrationRequestsNew';
import CourseRegistrationRequestsFinalized from './pages/CourseRegistrationRequestsFinalized';
import CourseRegistrationRequest from './pages/CourseRegistrationRequest';

import EnrollingGrades from './pages/EnrollingGrades';
import TeachingGrades from './pages/TeachingGrades';

import Payments from './pages/Payments';
import Stripe from './pages/StripeAccount';
import MarkAsUnpaid from './pages/MarkAsUnpaid';
import MarkAsConsortial from './pages/MarkAsConsortial';
import ChangeEnrollmentStatus from './pages/ChangeEnrollmentStatus';
import ChangeSessionTerm from './pages/ChangeSessionTerm';

import UploadCourseApprovals from './pages/UploadCourseApprovals';
import UploadMappings from './pages/UploadMappings';
import ApprovedCourses from './pages/ApprovedCourses';

import FinancialBreakdown from './pages/Reports/FinancialBreakdown';
import MonthlyEnrollments from './pages/Reports/MonthlyEnrollments';
import WeeklyEnrollments from './pages/Reports/WeeklyEnrollments';
import SessionOverSession from './pages/Reports/SessionOverSession';
import YearlyEnrollments from './pages/Reports/YearlyEnrollments';
import PartnerRevenue from './pages/Reports/PartnerRevenue';
import WeeklyBreakdown from './pages/Reports/WeeklyBreakdown';
import Map from './pages/Reports/Map';
import Analytics from './pages/Analytics';
import UserDetails from './pages/UserDetails';
import UserManagement from './pages/UserManagement';
import UserRequests from './pages/UserRequests';
import UserRoleDetails from './pages/UserRoleDetails';
import UserRoleManagement from './pages/UserRoleManagement';
import Viz from './pages/Viz';
import Projections from './pages/Projections';

import AllEnrollments from './pages/AllEnrollments';
import AdminUserLog from './pages/AdminUserLog';
import AllCourseSubstitutes from './pages/AllCourseSubstitutes';

import Dashboard from './pages/Dashboard';

import SearchIndexUpdates from './pages/SearchIndexUpdates';
import SearchIndexUpdate from './pages/SearchIndexUpdate';

import HomeInstitutionalAnalytics from './pages/HomeInstitutionalAnalytics';
import TeachingInstitutionalAnalytics from './pages/TeachingInstitutionalAnalytics';

import AdvisorRecommendations from './pages/AdvisorRecommendations';
import AdvisorRecommendation from './pages/AdvisorRecommendation';

import ForumBeeLogin from './pages/ForumBeeLogin';
import ZendeskLogin from './pages/ZendeskLogin';

import SignUp from './pages/SignUp';
import SignUpRequested from './pages/SignUpRequested';

import HS4CC from './pages/HS4CC';

import GeneralSettings from './pages/GeneralSettings';
import AuthenticationSettingsPage from './pages/AuthenticationSettings';
import CourseMappings from './pages/CourseMappings';
import FinancialSettings from './pages/FinancialSettings';
import PaymentHistory from './pages/PaymentHistory';
import TeachingPaymentHistory from './pages/TeachingPaymentHistory';
import InvoiceHistory from './pages/InvoiceHistory';
import TeachingInvoicesPage from './pages/TeachingInvoicesPage';
import StudentPortalPage from './pages/StudentPortal';
import ContactsPage from './pages/Contacts';
import EditUserRolePermissions from './pages/EditUserRolePermissions';
import CourseSharingGroup from './pages/CourseSharingGroup';
import CourseSharingGroups from './pages/CourseSharingGroups';
import CreateCourseSharingGroup from './pages/CreateCourseSharingGroup';
import EditCourseSharingGroup from './pages/EditCourseSharingGroup';

const routes = [
  {
    path: '/',
    Component: App,
    children: [
      {
        Component: Title,
        layout: false
      },
      {
        path: 'hs4cc',
        public: true,
        layout: 'any',
        margin: 'vertical',
        marginTop: false,
        Component: HS4CC
      },
      {
        path: 'sections/:sectionId',
        public: true,
        layout: 'any',
        margin: false,
        Component: CourseSection
      },
      {
        path: 'courses',
        public: true,
        layout: 'any',
        margin: 'vertical',
        marginTop: false,
        Component: Courses
      },
      {
        path: 'courses/request',
        Component: RequestCourse
      },
      {
        path: 'courses/upload',
        Component: UploadCourses
      },
      {
        path: 'courses/:id',
        public: true,
        layout: 'any',
        margin: false,
        Component: Course
      },
      {
        path: 'courses/:id/edit',
        Component: EditCourse
      },
      {
        path: 'courses/:id/request-section',
        Component: RequestCourseSection
      },
      {
        path: 'courses/:id/approve',
        Component: ApproveCourse
      },
      {
        path: 'courses/:id/recommend',
        Component: CreateAdvisorRecommendation
      },
      {
        path: 'sections/:sectionId/enroll',
        Component: CreateEnrollment
      },
      {
        path: 'enrollments',
        children: [
          {
            Component: Enrollments
          },
          {
            path: 'live',
            Component: EnrollmentsLive
          },
          {
            path: 'finalized',
            Component: EnrollmentsFinalized
          },
          {
            path: 'teaching',
            children: [
              {
                Component: EnrollmentsTeaching
              },
              {
                path: 'accepted',
                Component: EnrollmentsTeachingAccepted
              },
              {
                path: 'finalized',
                Component: EnrollmentsTeachingFinalized
              }
            ]
          }
        ]
      },
      {
        path: 'enrollment-requests',
        children: [
          {
            Component: EnrollmentRequests
          },
          {
            path: ':id',
            Component: EnrollmentRequest
          }
        ]
      },
      {
        path: 'grades',
        children: [
          {
            path: 'enrolling',
            Component: EnrollingGrades
          },
          {
            path: 'teaching',
            Component: TeachingGrades
          }
        ]
      },
      {
        path: 'course-registration-requests',
        children: [
          {
            Component: CourseRegistrationRequestsNew
          },
          {
            path: 'new',
            Component: CourseRegistrationRequestsNew
          },
          {
            path: 'finalized',
            Component: CourseRegistrationRequestsFinalized
          },
          {
            path: ':id',
            Component: CourseRegistrationRequest
          }
        ]
      },
      {
        path: 'student-users',
        children: [
          {
            path: 'add',
            Component: AddStudentUser
          },
          {
            path: ':id',
            Component: StudentUser
          },
          {
            path: ':id/edit',
            Component: EditStudentUser
          }
        ]
      },
      {
        path: 'advisor-recommendations',
        Component: AdvisorRecommendations
      },
      {
        path: 'advisor-recommendations/:id',
        Component: AdvisorRecommendation
      },
      {
        path: 'students',
        children: [
          {
            Component: Students
          },
          {
            path: 'add',
            Component: CreateStudentPage
          },
          {
            path: 'teaching',
            children: [
              {
                Component: TeachingStudents
              },
              {
                path: ':id',
                Component: TeachingStudent
              }
            ]
          },
          {
            path: ':id/edit',
            Component: EditStudent
          },
          {
            path: ':id',
            Component: Student
          }
        ]
      },
      {
        path: 'institutions',
        children: [
          {
            public: true,
            layout: 'any',
            margin: 'vertical',
            marginTop: false,
            Component: Institutions
          },
          {
            path: 'add',
            Component: CreateInstitution
          },
          {
            path: ':id/edit',
            Component: EditInstitution
          },
          {
            path: ':id',
            public: true,
            layout: 'any',
            margin: false,
            Component: Institution
          }
        ]
      },
      {
        path: 'consortia',
        children: [
          {
            public: true,
            layout: 'any',
            margin: 'vertical',
            marginTop: false,
            Component: Consortia
          },
          {
            path: 'add',
            Component: CreateConsortium
          },
          {
            path: ':id/edit',
            Component: EditConsortium
          },
          {
            path: ':id',
            public: true,
            layout: 'any',
            margin: false,
            Component: Consortium
          }
        ]
      },
      {
        path: 'settings',
        children: [
          { Component: GeneralSettings },
          {
            path: 'user-management',
            children: [
              { Component: UserManagement },
              {
                path: 'add',
                children: [{ Component: AddUser }]
              },
              {
                path: ':id',
                children: [{ Component: UserDetails }]
              }
            ]
          },
          {
            path: 'user-requests',
            children: [{ Component: UserRequests }]
          },
          {
            path: 'authentication',
            children: [{ Component: AuthenticationSettingsPage }]
          },
          {
            path: 'course-mappings',
            children: [{ Component: CourseMappings }]
          },
          {
            path: 'financial',
            children: [{ Component: FinancialSettings }]
          },
          {
            path: 'payment-history',
            children: [{ Component: PaymentHistory }]
          },
          {
            path: 'payment-history/teaching',
            children: [{ Component: TeachingPaymentHistory }]
          },
          {
            path: 'invoice-history',
            children: [{ Component: InvoiceHistory }]
          },
          {
            path: 'invoice-history/teaching',
            children: [{ Component: TeachingInvoicesPage }]
          },
          {
            path: 'student-portal',
            children: [{ Component: StudentPortalPage }]
          },
          {
            path: 'user-roles',
            children: [
              { Component: UserRoleManagement },
              { path: 'add', children: [{ Component: AddUserRole }] },
              { path: ':id', children: [{ Component: UserRoleDetails }] },
              { path: ':id/edit-permissions', children: [{ Component: EditUserRolePermissions }] }
            ]
          },
          {
            path: 'contacts',
            children: [{ Component: ContactsPage }]
          }
        ]
      },
      {
        path: 'admin',
        children: [
          {
            Component: AdminTools
          },
          {
            path: 'course-approvals/upload',
            Component: UploadCourseApprovals
          },
          {
            path: 'mappings/upload',
            Component: UploadMappings
          },
          {
            path: 'search-index-updates',
            children: [
              {
                Component: SearchIndexUpdates
              },
              {
                path: ':id',
                Component: SearchIndexUpdate
              }
            ]
          },

          {
            path: 'users',
            children: [
              {
                Component: AdminStudentUsers
              },
              {
                path: 'create',
                Component: CreateUser
              },
              {
                path: 'change-institution',
                Component: EditCurrentUser
              },
              {
                path: ':id',
                Component: AdminUser
              },
              {
                path: ':id/log',
                Component: AdminUserLog
              },
              {
                path: ':id/edit',
                Component: AdminEditUser
              }
            ]
          },

          {
            path: 'student-users',
            children: [
              {
                path: 'change-institution',
                Component: ChangeStudentUserInstitution
              },
              {
                path: ':id',
                Component: AdminStudentUser
              }
            ]
          },

          {
            path: 'students',
            children: [
              {
                path: 'change-email',
                Component: ChangeStudentEmail
              }
            ]
          },

          {
            path: 'payments',
            Component: Payments
          },

          {
            path: 'annual-fees',
            Component: AnnualFees
          },

          {
            path: 'stripe',
            Component: Stripe
          },

          {
            path: 'enrollments',
            Component: AllEnrollments
          },

          {
            path: 'course-mappings',
            Component: AllCourseSubstitutes
          },

          {
            path: 'approved-courses',
            Component: ApprovedCourses
          },

          {
            path: 'search-index-manual-update',
            Component: SearchIndexManualUpdate
          },

          {
            path: 'mark-unpaid',
            Component: MarkAsUnpaid
          },

          {
            path: 'mark-consortial',
            Component: MarkAsConsortial
          },

          {
            path: 'change-enrollment-status',
            Component: ChangeEnrollmentStatus
          },

          {
            path: 'change-session-term',
            Component: ChangeSessionTerm
          },

          {
            path: 'analytics',
            Component: Analytics
          },

          {
            path: 'viz',
            Component: Viz
          },

          {
            path: 'projections',
            Component: Projections
          },

          {
            path: 'map',
            Component: Map
          },

          {
            path: 'financial-breakdown',
            Component: FinancialBreakdown
          },

          {
            path: 'monthly-enrollments',
            Component: MonthlyEnrollments
          },

          {
            path: 'weekly-enrollments',
            Component: WeeklyEnrollments
          },

          {
            path: 'session-over-session',
            Component: SessionOverSession
          },

          {
            path: 'yearly-enrollments',
            Component: YearlyEnrollments
          },

          {
            path: 'partner-revenue',
            Component: PartnerRevenue
          },

          {
            path: 'weekly-breakdown',
            Component: WeeklyBreakdown
          },

          {
            path: 'course-sharing-groups',
            children: [
              {
                Component: CourseSharingGroups
              },
              {
                path: 'create',
                Component: CreateCourseSharingGroup
              },
              {
                path: ':id',
                Component: CourseSharingGroup
              },
              {
                path: ':id/edit',
                Component: EditCourseSharingGroup
              }
            ]
          }
        ]
      },
      {
        path: 'home-institutional-analytics',
        Component: HomeInstitutionalAnalytics
      },
      {
        path: 'teaching-institutional-analytics',
        Component: TeachingInstitutionalAnalytics
      },
      {
        path: 'dashboard',
        Component: Dashboard
      },
      {
        path: 'forumbee/login',
        Component: ForumBeeLogin
      },
      {
        path: 'zendesk/login',
        Component: ZendeskLogin
      },
      {
        path: 'sign-up',
        children: [
          {
            public: true,
            Component: SignUp
          },
          {
            path: 'requested',
            public: true,
            Component: SignUpRequested
          }
        ]
      },
      {
        path: 'logout',
        Component: LogOut,
        // `public: true` here is just for the page design.
        // The actual page, of course, is not meant to be used by non-logged-in users.
        public: true,
        margin: false,
        background: false
      },
      {
        path: 'help',
        public: true,
        Component: Help
      },
      {
        path: 'error',
        public: true,
        margin: 'vertical',
        marginTop: 'large',
        background: true,
        Component: ErrorPage,
        status: 500
      },
      {
        path: 'not-found',
        public: true,
        margin: 'vertical',
        marginTop: 'large',
        background: true,
        Component: NotFound,
        status: 404
      },
      {
        path: '*',
        public: true,
        margin: 'vertical',
        marginTop: 'large',
        background: true,
        Component: NotFound,
        status: 404
      }
    ]
  }
];

// Wraps all non-public routes in `Auth()` decorator.
function decorateRoutes(routes) { // , path) {
  for (const route of routes) {
    // const routePath = path === undefined ? '' : path + '/' + route.path;
    if (route.children) {
      decorateRoutes(route.children); // , routePath);
    } else {
      if (route.Component.getServerSideProps) {
        route.Component.load = transformGetServerSidePropsToPageLoader(route.Component.getServerSideProps);
      }
      route.Component = decorateRouteComponent(route.Component, route);
    }
  }
}

function transformGetServerSidePropsToPageLoader(getServerSideProps) {
  return async ({ params }) => {
    const { props, redirect } = getServerSideProps({ params });
    if (redirect) {
      return {
        redirect: {
          url: redirect.destination
        }
      };
    }
    return {
      props
    };
  };
}

decorateRoutes(routes);

export default routes;
