import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { useTranslate } from '@acadeum/translate';

import Button from '../Button';

import styles from './ShowMore.module.scss';

export default function ShowMore({ className, expanded, onClick }) {
  const t = useTranslate('ShowMore');

  return (
    <Button
      text
      onClick={onClick}
      className={classNames(className, styles.ShowMore, {
        [styles['ShowMore--expanded']]: expanded
      })}
    >
      {t(expanded ? 'showLess' : 'showMore')}
    </Button>
  );
}

ShowMore.propTypes = {
  expanded: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};
