import React from 'react';

import { userHasPermission } from '@acadeum/helpers';
import { TeachingInvoicesPage } from '@acadeum/shared-admin-ui';

import { useFetchInvoiceQuery } from '../../api/invoices';
import { useFetchTransfersQuery, useLazyFetchTransfersQuery } from '../../api/stripe';
import type { AppPage } from '../../helpers/app.types';

const Invoices: AppPage = () => {
  return (
    <TeachingInvoicesPage
      useFetchInvoiceQuery={useFetchInvoiceQuery}
      useFetchTransfersQuery={useFetchTransfersQuery}
      useLazyFetchTransfersQuery={useLazyFetchTransfersQuery}
    />
  );
};

Invoices.meta = () => ({
  title: 'Invoices'
});

Invoices.when = ({ user }) => user?.institution.teaching && userHasPermission(user, 'paymentMethod:read', {
  orgId: user?.institution.id,
  ownerId: null
});

export default Invoices;
