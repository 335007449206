import validatePropertyValuesNotUndefined from './validatePropertyValuesNotUndefined.js';

export default function canRemoveCourseEnrollment({
  status
}) {
  const error = validateCanRemoveCourseEnrollment({
    status
  });

  return !error;
}

export function validateCanRemoveCourseEnrollment({
  status
}) {
  validatePropertyValuesNotUndefined({
    status
  });

  if (status !== 'REQUESTED') {
    return 'invalid_status';
  }
}
