import Enrollments from '../Enrollments';

const EnrollmentsFinalized = () => {
  return (
    <Enrollments mode="homeInstitution" status="finalized"/>
  );
};

export default EnrollmentsFinalized;

EnrollmentsFinalized.meta = () => ({
  title: 'Finalized Enrollments'
});

EnrollmentsFinalized.breadcrumbs = () => [
  ['Manage Enrollments', '/enrollments'],
  'Finalized'
];
