import classNames from 'classnames';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { usePopper } from 'react-popper';

import { TextColorIcon } from '@acadeum/icons';

import { BaseButton } from '../../../../../BaseButton';
import type { ColorPickerProps } from '../../../../../ColorPicker';
import { ColorPicker } from '../../../../../ColorPicker';
import { Icon } from '../../../../../Icon';
import { OnClickOrTapOutside } from '../../../../../OnClickOrTapOutside';
import { ReactPortal } from '../../../../../ReactPortal';

import styles from './TextColorButton.module.scss';


export interface TextColorButtonProps {
  className?: string;
  disabled?: boolean;
  value?: ColorPickerProps['value'];
  onChange: ColorPickerProps['onChange'];
}

export const TextColorButton = memo<TextColorButtonProps>(({
  className,
  disabled,
  onChange,
  value
}) => {
  const label = 'Formatting text color';

  const [expanded, setExpanded] = useState(false);

  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  const open = () => setExpanded(true);

  const close = () => setExpanded(false);

  useEffect(() => {
    if (expanded && popperElement) {
      popperElement.focus();
    }
  }, [expanded, popperElement]);

  const modifiers = useMemo(() => [
    {
      name: 'offset',
      enabled: true,
      options: {
        offset: [0, 4]
      }
    }
  ], []);

  const {
    styles: popperStyles,
    attributes: popperAttributes
  } = usePopper(referenceElement, popperElement, {
    strategy: 'fixed',
    placement: 'bottom-start',
    modifiers
  });

  return (
    <div className={classNames( styles.TextColorButton)}>
      <BaseButton
        className={className}
        ref={setReferenceElement}
        disabled={disabled}
        onClick={expanded ? close : open}
        aria-label={label}
        title={label}
        aria-expanded={expanded}
      >
        <Icon icon={TextColorIcon}/>
      </BaseButton>

      {expanded && (
        <ReactPortal>
          <OnClickOrTapOutside
            containerRef={{ current: popperElement }}
            toggleRef={{ current: referenceElement }}
            onClickOutside={close}
            onTapOutside={close}
          >
            <div
              tabIndex={-1}
              ref={setPopperElement}
              style={popperStyles.popper}
              className={styles.Popper}
              {...popperAttributes.popper}
            >
              <ColorPicker
                label={label}
                onClose={close}
                onChange={onChange}
                value={value}
              />
            </div>
          </OnClickOrTapOutside>
        </ReactPortal>
      )}
    </div>
  );
});
