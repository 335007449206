import PropTypes from 'prop-types';
import React from 'react';

import WarningIcon from './WarningTriangleOutlineIcon.svg';

export default function NotifyErrorModal({ children }) {
  return (
    <div style={{ display: 'flex' }}>
      <WarningIcon style={{ width: '1.25rem', height: '1.25rem', marginTop: '0.1rem', marginRight: '1.25rem', flex: 'none' }}/>
      <div style={{ flex: 1, whiteSpace: 'pre-wrap' }}>
        {children}
      </div>
    </div>
  );
}

NotifyErrorModal.propTypes = {
  children: PropTypes.node
};
