import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { Title } from '@acadeum/ui';

import styles from './Subsection.module.scss';

export  function Subsection({
  title,
  description,
  marginTop,
  marginBottom,
  className,
  children,
  heading
}) {
  return (
    <section className={classNames(styles.Subsection, className, {
      [styles['Subsection--marginTopLarge']]: marginTop === 'large',
      [styles['Subsection--marginTopMedium']]: marginTop === 'medium',
      [styles['Subsection--marginBottomLarge']]: marginBottom === 'large'
    })}>
      <header className={classNames(styles.Subsection__header, {
        [styles['Subsection__header--marginBottomLarge']]: !description
      })}>
        <Title level={2} className={classNames(styles.Subsection__heading, {
          [styles['Subsection__heading--new']]: heading === 'new'
        })}>
          {title}
        </Title>
        <div className={styles.Subsection__headingLine}/>
      </header>
      {description && (
        <p className={styles.Subsection__copy}>
          {description}
        </p>
      )}
      {children}
    </section>
  );
}

Subsection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  marginTop: PropTypes.oneOf(['large', 'medium']),
  marginBottom: PropTypes.oneOf(['large']),
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  heading: PropTypes.oneOf(['new'])
};
