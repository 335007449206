import classNames from 'classnames';
import React, { memo } from 'react';

import { HStack } from '../../../Stack';
import { Text } from '../../../Text';
import { Swatch } from '../Swatch';

import styles from './PresetColors.module.scss';

export interface BlockSwatchesProps {
  className?: string;
  colors: string[];
  // eslint-disable-next-line
  onChange: (value: any) => void;
}

export const PresetColors = memo<BlockSwatchesProps>(({
  className,
  colors,
  onChange
}) => {

  const onClick = (hex) => {
    onChange?.({ hex });
  };

  return (
    <div className={classNames(className, styles.PresetColors)}>
      <Text variant="subtitle2" color="secondary" mb="sm">
        presets
      </Text>
      <HStack gap="sm" wrap="wrap" justify="between">
        {colors.map((color, index) => (
          <Swatch
            key={index}
            color={color}
            onClick={onClick}
          />
        ))}
      </HStack>
    </div>
  );
});
