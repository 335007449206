import type { FC } from 'react';
import React from 'react';

import { useTranslate } from '@acadeum/translate';
import type { ModalProps } from '@acadeum/ui';
import { Modal, Text } from '@acadeum/ui';

import { CreditCardForm } from '../CreditCardForm';
import type { CreditCardFormProps } from '../CreditCardForm';


export interface AddCreditCardModalProps extends Pick<ModalProps, 'show' | 'onHide'>,
  Pick<CreditCardFormProps, 'useUpdateBackupPaymentSourceMutation' | 'useCreateBackupPaymentSourceMutation'> {
}

export const AddCreditCardModal: FC<AddCreditCardModalProps> = ({
  show,
  onHide,
  useCreateBackupPaymentSourceMutation,
  useUpdateBackupPaymentSourceMutation
}) => {
  const t = useTranslate('shared-admin-ui.AddCreditCardModal');
  return (
    <Modal
      show={show}
      title={t('title')}
      onHide={onHide}
    >
      <Modal.Body>
        <Text>
          {t('description')}
        </Text>
        <CreditCardForm
          useCreateBackupPaymentSourceMutation={useCreateBackupPaymentSourceMutation}
          useUpdateBackupPaymentSourceMutation={useUpdateBackupPaymentSourceMutation}
        />
      </Modal.Body>
    </Modal>
  );
};
