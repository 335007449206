import type { RtkApi } from '@acadeum/redux';
import type { FeeType, Id, Institution, InvoiceStatus } from '@acadeum/types';

export interface FetchInvoicesInput {
  page?: number;
  pageSize?: number;
  search?: string;
  filters?: {
    ids?: Id[];
    type?: FeeType;
    institutionIds?: Id[];
    status?: InvoiceStatus;
    createdAt?: {
      from?: Date;
      to?: Date;
    };
  }
}

export interface FetchInvoicesListItem {
  id: string;
  amount: number;
  type: FeeType;
  institution?: Pick<Institution, 'id' | 'name' | 'logoUrl' | 'vanityUrl'>;
  createdAt: Date;
  dueDate?: Date;
  status: InvoiceStatus;
  number: string;
  invoicePdfUrl?: string;
  chargeId?: string;
}

export interface FetchInvoicesOutput {
  results: FetchInvoicesListItem[];
  totalCount: number;
  pageSize: number;
  page: number;
}

interface FetchInvoiceOutput {
  id: string;
  created: Date;
  number: string;
  invoicePdf?: string;
  invoiceBase64?: string;
}

export const injectInvoiceEndpoints = (rtkApi: RtkApi) =>
  rtkApi.injectEndpoints({
    endpoints: build => ({
      fetchInvoices: build.query<FetchInvoicesOutput, FetchInvoicesInput>({
        query: (params) => ({
          url: '/payments/invoices',
          params
        })
      }),
      fetchInvoice: build.query<FetchInvoiceOutput, string>({
        query: (invoiceId) => ({ url: `/payments/invoices/${invoiceId}` })
      })
    })
  });

export type UseFetchInvoicesQuery = ReturnType<typeof injectInvoiceEndpoints>['useFetchInvoicesQuery'];
export type UseLazyFetchInvoicesQuery = ReturnType<typeof injectInvoiceEndpoints>['useLazyFetchInvoicesQuery'];
export type UseFetchInvoiceQuery = ReturnType<typeof injectInvoiceEndpoints>['useFetchInvoiceQuery'];
