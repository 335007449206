import React, { memo } from 'react';

import type { UseFetchInvoiceQuery } from '@acadeum/api';
import { base64ToBlob } from '@acadeum/helpers';
import { DownloadIcon, FileInvoiceIcon } from '@acadeum/icons';
import { useTranslate } from '@acadeum/translate';
import type { ActionsProps } from '@acadeum/ui';
import {
  Actions,
  ConfirmActionModal,
  Email,
  FormatDate,
  HStack,
  Link,
  LoadingModal,
  Modal,
  Text,
  VStack
} from '@acadeum/ui';

import { useSettingsRoutes } from '../../../../hooks/useSettingsRoutes';

import styles from './InvoiceDetails.module.scss';

export interface InvoiceDetailsProps {
  invoiceId: string;
  chargeId?: string;
  source?: string;
  onHide: () => void;
  useFetchInvoiceQuery: UseFetchInvoiceQuery;
}

export const InvoiceDetails: React.FC<InvoiceDetailsProps> = memo(({
  invoiceId,
  chargeId,
  source,
  onHide,
  useFetchInvoiceQuery
}) => {
  const t = useTranslate('shared-admin-ui.InvoiceDetails');
  const { getInvoiceHistoryUrl, getPaymentHistoryUrl } = useSettingsRoutes();

  const { data: invoice, isLoading } = useFetchInvoiceQuery(invoiceId, {});

  const ModalTitle = () => {
    const actions: ActionsProps['actions'] = [
      {
        title: t('download'),
        onClick: downloadInvoice
      }
    ];

    if (chargeId) {
      actions.push({
        title: t('seePaymentDetails'),
        url: `${getPaymentHistoryUrl()}?search=${chargeId}`
      });
    }

    return (
      <div className={styles.InvoiceDetails__title}>
        <Text variant="headingMd">
          {t('invoiceDetailsModal.title')}
        </Text>
        <Actions
          variant="kebab"
          actions={actions}
        />
      </div>
    );
  };

  const downloadInvoice = () => {
    const invoicePdf = invoice?.invoicePdf;
    if (!invoicePdf) {
      return;
    }
    const link = document.createElement('a');
    link.href = invoicePdf;
    link.download = `Invoice ${invoice.number}`;
    link.click();
  };

  if (isLoading) {
    return (
      <LoadingModal show title="Loading..."/>
    );
  }

  if (!invoice?.invoicePdf) {
    return (
      <UnableDownloadModal show onHide={onHide}/>
    );
  }

  if (navigator?.pdfViewerEnabled) {
    return (
      <Modal
        show
        onHide={onHide}
        title={<ModalTitle/>}
        size="medium"
      >
        <Modal.Body>
          <div className={styles.InvoiceDetails__content}>
            <Text
              style={{ textTransform: 'uppercase' }}
              color="graphiteDark"
              variant="bodySm"
              mb="md"
            >
              {t('invoice')}
            </Text>
            <HStack className={styles.InvoiceDetails__invoice} mb="lg">
              <FileInvoiceIcon className={styles.fileIcon}/>
              <VStack ml="md">
                {source === 'payments' ? (
                  <Link to={`${getInvoiceHistoryUrl()}?search=${invoice.number}`}>
                    {invoice.number}
                  </Link>
                ) : (
                  <Text>
                    {t('invoiceDetailsModal.invoiceNumber', { number: invoice.number })}
                  </Text>
                )}
                <Text variant="bodyMd" color="grey">
                  <FormatDate
                    utc={false}
                    date={invoice.created}
                    month="long"
                    time
                    day="numeric"
                  />
                </Text>
              </VStack>
            </HStack>

            {invoice.invoiceBase64 && (
              <>
                <Text
                  style={{ textTransform: 'uppercase' }}
                  color="graphiteDark"
                  variant="bodySm"
                  mb="md"
                >
                  {t('preview')}
                </Text>
                <object
                  type="application/pdf"
                  data={`${URL.createObjectURL(base64ToBlob(invoice.invoiceBase64, 'application/pdf'))  }#toolbar=0&navpanes=0&scrollbar=0`}
                  style={{
                    width: 752,
                    height: '900px',
                    maxWidth: '100%'
                  }}
                />
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <ConfirmActionModal
      show
      title={t('downloadModal.title')}
      description={t('downloadModal.description', {
        supportEmail: <Email address={'support@acadeum.com'}/>
      })}
      submitProps={{ icon: DownloadIcon }}
      submitText={t('downloadPDF')}
      onHide={onHide}
      onCancel={onHide}
      onSubmit={downloadInvoice}
    />
  );
});

export const UnableDownloadModal = ({ show, onHide }) => {
  const t = useTranslate('shared-admin-ui.InvoiceDetails');

  return (
    <Modal
      show={show}
      title={t('unableDownloadModal.title')}
      size="narrow"
      onHide={onHide}
    >
      <Modal.Body>
        <Text>
          {t('unableDownloadModal.description', {
            supportEmail: <Email address={'support@acadeum.com'}/>
          })}
        </Text>
      </Modal.Body>
    </Modal>
  );
};
