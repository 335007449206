import classNames from 'classnames';
import React, { useState } from 'react';
import type { FC } from 'react';

import { SortDescIcon } from '@acadeum/icons';

import { Button } from '../Button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger
} from '../DropdownMenu';
import { Icon } from '../Icon';

import styles from './SortingDropdown.module.scss';

export interface SortingDropdownProps<V = any> {
  disabled?: boolean;
  className?: string;
  options: Array<{
    label: string;
    value: V;
  }>;
  value: V;
  onValueChange: (value: V) => void;
  'aria-label'?: string;
}

export const SortingDropdown: FC<SortingDropdownProps> = ({
  disabled,
  className,
  options,
  value,
  onValueChange,
  'aria-label': ariaLabel = 'Sort By'
}) => {
  const [open, setOpen] = useState(false);
  return (
    <DropdownMenu modal={false} open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger asChild className={classNames(className, styles.trigger)}>
        <Button
          disabled={disabled}
          variant="tertiary"
          disclosure={open ? 'up' : 'down'}
          aria-label={ariaLabel}
        >
          <Icon icon={SortDescIcon} size="md"/>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start">
        <DropdownMenuRadioGroup
          value={value}
          onValueChange={onValueChange}
        >
          {options.map(({ label, value: optionValue }) => (
            <DropdownMenuRadioItem
              key={optionValue}
              value={optionValue}
            >
              {label}
            </DropdownMenuRadioItem>
          ))}
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
