import classNames from 'classnames';
import type { ReactNode } from 'react';
import React from 'react';

import {
  AcceptIcon,
  CircleExclamationIcon,
  ConsortialTagIcon,
  DollarCircleIcon,
  DollarCircleNopeIcon,
  FinancialTagIcon,
  StarTagIcon
} from '@acadeum/icons';
import type { AnyCase, IconSource } from '@acadeum/types';

import { Loader } from '../Loader';

import styles from './Tag.module.scss';

const TagDictionary = {
  registration: { label: 'Registration', color: 'light-blue' },
  sent: { label: 'Sent', color: 'light-blue' },
  requested: { label: 'Requested', color: 'light-blue' },
  gradedue: { label: 'Grade Due', color: 'dark-blue' },
  withgrade: { label: 'With Grade', color: 'dark-blue' },
  expired: { label: 'Expired', color: 'grey' },
  expiring: { label: 'Expiring', color: 'light-red' },
  active: { label: 'Active', color: 'green' },
  visible: { label: 'Visible', color: 'green' },
  removed: { label: 'Removed', color: 'grey' },
  pending: { label: 'Pending', color: 'yellow' },
  denied: { label: 'Denied', color: 'orange' },
  drop: { label: 'Drop', color: 'light-red' },
  dropped: { label: 'Dropped', color: 'light-red' },
  draft: { label: 'Draft', color: 'grey' },
  invalid: { label: 'Withdraw', color: 'dark-red' },
  withdraw: { label: 'Withdraw', color: 'dark-red' },
  withdrawn: { label: 'Withdrawn', color: 'dark-red' },
  complete: { label: 'Complete', color: 'purple' },
  nograde: { label: 'No Grade', color: 'purple' },
  verified: { label: 'Verified', color: 'green' },
  unverified: { label: 'Unverified', color: 'yellow' },
  inactive: { label: 'Inactive', color: 'grey' },
  hidden: { label: 'Hidden', color: 'grey' },
  passwordnotset: { label: 'Password not set', color: 'orange' },
  sso: { label: 'SSO', color: 'dark-blue' },
  singlesignon: { label: 'Single Sign On', color: 'dark-blue' },
  cancelled: { label: 'Cancelled', color: 'light-red' },
  due: { label: 'Due', color: 'dark-blue' },
  approved: { label: 'Approved', color: 'green' },
  processing: { label: 'Processing', color: 'green' },
  succeeded: { label: 'Succeeded', color: 'green' },
  failed: { label: 'Failed', color: 'dark-red' },
  updated: { label: 'Updated', color: 'light-blue' },
  unchanged: { label: 'Unchanged', color: 'grey' },
  consortial: { label: 'Consortial', color: 'light-blue' },
  success: { label: 'Success', color: 'green' },
  error: { label: 'Error', color: 'light-red' },
  financial: { label: 'Financial', color: 'green' },
  certificate: { label: 'Embedded Certificate', color: 'dark-blue' },
  duplicate: { label: 'Duplicate', color: 'dark-red' },
  default: { label: 'Default', color: 'purple' },
  deactivated: { label: 'Deactivated', color: 'dark-red' },
  created: { label: 'Created', color: 'yellow' },
  void: { label: 'Void', color: 'light-blue' },
  open: { label: 'Open', color: 'yellow' },
  paid: { label: 'Paid', color: 'green' },
  unpaid: { label: 'Unpaid', color: 'light-red' },
  uncollectible: { label: 'Uncollectible', color: 'dark-red' },
  refunded: { label: 'Refunded', color: 'dark-blue' },
  enrolled: { label: 'Enrolled', color: 'dark-green' },
  applying: { label: 'Applying', color: 'yellow' },
  'opt out':  { label: 'Opt out', color: 'dark-grey' },
  applied:  { label: 'Applied', color: 'orange' },
  accepted:  { label: 'Accepted', color: 'dark-blue' },
  waitlisted:  { label: 'Waitlisted', color: 'pink' }
} as const;

// `keyof typeof TagDictionary` doesn't make any sense, it's just for a better autocomplete
type TagDictionaryKeys = AnyCase<keyof typeof TagDictionary> | keyof typeof TagDictionary;

type DefaultVariants = 'green'
  | 'dark-green'
  | 'purple'
  | 'grey'
  | 'dark-grey'
  | 'orange'
  | 'dark-red'
  | 'light-red'
  | 'dark-blue'
  | 'light-blue'
  | 'purple-light'
  | 'yellow'
  | 'pink';

const defaultKeys: DefaultVariants[] = [
  'green',
  'dark-green',
  'purple',
  'grey',
  'dark-grey',
  'orange',
  'dark-red',
  'light-red',
  'dark-blue',
  'light-blue',
  'purple-light',
  'yellow',
  'pink'
];

export interface TagProps {
  variant?: TagDictionaryKeys | DefaultVariants | string;
  children?: ReactNode;
  loading?: boolean;
  className?: string;
  icon?: IconSource;
  style?: React.CSSProperties
}

export const Tag = React.forwardRef<
  HTMLElement,
  TagProps
>(({
  variant = 'grey',
  children,
  loading,
  className,
  style,
  icon: PropsIcon,
  ...rest
}, ref) => {
  const Icon = PropsIcon || getIcon(variant.toLowerCase());
  const dictionaryItem = TagDictionary[variant.toLowerCase()];
  const color = dictionaryItem ? dictionaryItem.color : variant;
  const content = children || (dictionaryItem ? dictionaryItem.label : children) || (defaultKeys.includes(variant as any) ? null : variant);

  return (
    <span
      ref={ref}
      {...rest}
      className={classNames(styles.root, {
        [styles[color]]: color
      }, className)}
      style={style}
    >
      {loading ? <Loader variant="spinnerSmall"/> : Icon && <Icon/>}
      {content}
    </span>
  );
});

function getIcon(variant: TagProps['variant']) {
  switch (variant) {
    case 'consortial':
      return ConsortialTagIcon;
    case 'success':
      return AcceptIcon;
    case 'error':
      return CircleExclamationIcon;
    case 'financial':
      return FinancialTagIcon;
    case 'certificate':
      return StarTagIcon;
    case 'paid':
      return DollarCircleIcon;
    case 'unpaid':
      return DollarCircleNopeIcon;
    default:
      return null;
  }
}
