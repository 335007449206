import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { readStateFromUrl, writeStateToUrl } from '@acadeum/helpers';
import { PlusIcon } from '@acadeum/icons';
import type { ToggleButtonProps, TabsProps } from '@acadeum/ui';
import { Button, PageHeader, Tabs, ToggleButton } from '@acadeum/ui';

import CourseSearch from '../../components/CourseSearch';
import type { CourseSearchScope, CourseSearchView } from '../../components/CourseSearch/searchStateUtils';

import type { ReduxState } from '../../helpers/app.types';
import { useCanViewCourses } from '../../hooks/permissions';
import useLocation from '../../hooks/useLocation';


import { getCanUploadCourses } from '../UploadCourses';

import './Home.sass';

export default function Home() {
  const canViewCourses = useCanViewCourses();

  if (canViewCourses) {
    return (
      <>
        <br/>
        <CoursesSearchScreen/>
      </>
    );
  }

  return null;
}

Home.propTypes = {
  location: PropTypes.object,
  user: PropTypes.object
};

// The `Home` page shouldn't have any `load()` function
// because it's being shown in `pages/Title.js` without any preloading.
// Home.load = async () => { ... }

// function getInitialSearchUrl(location, search) {
// Using "view"="courses" instead of view=courses here
// because Algolia search URL is JSON-encoded.
// Algolia search URL is JSON-encoded because
// this way the value type information isn't discarded:
// whether a value is a string, a number or a boolean.
// return `${location.pathname}?"view"=${JSON.stringify(viewValue)}&"tab"=${JSON.stringify(tabsValue)}`;
// }

function CoursesSearchScreen() {
  const location = useLocation();

  const institution = useSelector((state: ReduxState) => state.auth.user.institution);

  const [courseSearchSectionsViewUrlQueryString, setCourseSearchSectionsViewUrlQueryString] = useState<string>('?"view"="sections"');
  const [courseSearchCoursesViewUrlQueryString, setCourseSearchCoursesViewUrlQueryString] = useState<string>('?"view"="courses"');

  const [view, setView] = useState<CourseSearchView>('sections');
  const [scope, setScope] = useState<CourseSearchScope>('all');

  useEffect(() => {
    const state = readStateFromUrl(location);
    if (state.view === 'sections' || state.view === 'courses') {
      setView(state.view);
    }
    if (state.scope === 'all' || state.scope === 'own') {
      setScope(state.scope);
    }
  }, []);

  const searchUrlParams = useMemo(() => ({ view, scope }), [view, scope]);

  const optionsToggleButton: ToggleButtonProps<typeof view>['options'] = useMemo(() => [
    {
      label: 'Sections',
      value: 'sections'
    },
    {
      label: 'Courses',
      value: 'courses'
    }
  ], []);

  const updateLocationSearch = (search, scope_: typeof scope) => {
    const state = readStateFromUrl({ search });
    state.scope = scope_;
    history.replaceState(null, '', writeStateToUrl(state, location));
  };

  const onClickToggleButton = (view: 'sections' | 'courses') => {
    setView(view);
    const urlQueryString = window.location.search;

    if (view === 'sections') {
      setCourseSearchCoursesViewUrlQueryString(urlQueryString);
      updateLocationSearch(courseSearchSectionsViewUrlQueryString, scope);
    } else if (view === 'courses') {
      setCourseSearchSectionsViewUrlQueryString(urlQueryString);
      updateLocationSearch(courseSearchCoursesViewUrlQueryString, scope);
    }
  };

  const onClickTabs = (tabs) => {
    setScope(tabs.key);
    updateLocationSearch(window.location, tabs.key);
  };

  const tabs = useMemo(() => {
    const tabs: TabsProps<typeof scope>['tabs'] = [
      {
        title: 'All Acadeum Courses',
        key: 'all',
        body: <></>
      }
    ];
    if (institution.isTeaching) {
      tabs.push({
        title: 'My Teaching Courses',
        key: 'own',
        body: <></>
      });
    }
    return tabs;
  }, []);

  const user = useSelector((state: ReduxState) => state.auth.user);

  return (
    <>
      <br/>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <PageHeader
              title="Courses"
              actions={getCanUploadCourses(user) ? (
                <Button icon={PlusIcon} variant="secondary" url="/courses/upload">
                  Upload Course
                </Button>
              ) : null}
            />
            <Tabs
              title="Course"
              tabs={tabs}
              activeTab={scope}
              onChangeTab={onClickTabs}
            />
            <ToggleButton
              label="View mode"
              value={view}
              onChange={onClickToggleButton}
              options={optionsToggleButton}
            />
            <CourseSearch
              key={view}
              scope={scope}
              view={view}
              enableSavedSearches
              savedSearchesLocalStorageKey={`Acadeum.Home.CourseSearch.${view}.${scope}`}
              clientSettingsSavedSearchesKey={`courseshare.courseSearchFilters.${view}.${scope}`}
              searchUrlParams={searchUrlParams}
              searchFieldBanner
              searchFieldBannerFullWidth
              courseSharingGroupIds={undefined}
              showRequestCourseBanner={undefined}
            />
          </div>
        </div>
      </div>
    </>
  );
}
