import React, { Fragment, useState } from 'react';

import { userHasPermission } from '@acadeum/helpers';
import {
  Accordion,
  Actions,
  ConfirmActionModal,
  Link,
  List,
  Scrollable,
  Separator,
  SuccessModal,
  Text
} from '@acadeum/ui';

import actions from '../../../../../actions';
import { ALGOLIA_UPDATE_PENDING_NOTE } from '../../../../../helpers/algolia';


import styles from '../CourseInfo.module.scss';

const {
  approveCourse
} = actions;

export function ScheduleSection({
  user,
  course,
  schedule,
  editScheduleUrl
}) {
  const [showUseDefaultScheduleModal, setShowUseDefaultScheduleModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const canEditSchedule = userHasPermission(user, 'courseApproval:update', {
    orgId: user.institution.id
  });

  const hasSchedule = Boolean(schedule);

  const actions = canEditSchedule && (
    <Actions
      variant="kebab"
      actions={[
        {
          url: `/courses/${course.id}/approve`,
          title: 'Edit Schedule'
        },
        {
          onClick: () => setShowUseDefaultScheduleModal(true),
          title: 'Return to Default Settings'
        }
      ]}
    />
  );

  let content;
  if (hasSchedule) {
    content = (
      <Scrollable className={styles.ScheduleSection} paddingRight="1.5rem">
        <List unstyled>
          {schedule.map(({ term, sessions }, index) => (
            <Fragment key={index}>
              {index > 0 && <Separator/>}
              <List.Item className={styles.ScheduleSection__item}>
                <Text variant="subtitle2" color="graphiteDark" className={styles.ScheduleSection__itemTitle}>
                  {term}
                  {index === 0 && actions}
                </Text>
                <Text>
                  Sessions
                </Text>
                <Text color="black">
                  {sessions ? sessions.join(', ') : 'All Sessions'}
                </Text>
              </List.Item>
            </Fragment>
          ))}
        </List>
      </Scrollable>
    );
  } else {
    content = (
      <Text color="grey">
        All current terms and sessions of this approved course will be available for your students.
        {canEditSchedule && editScheduleUrl && (
          <>
            {' '}
            <Link to={editScheduleUrl}>
              Edit
            </Link>
          </>
        )}
      </Text>
    );
  }

  return (
    <>
      <Accordion title="Home Institution Schedule" defaultOpen={hasSchedule}>
        {content}
      </Accordion>
      <UseDefaultScheduleModal
        courseId={course.id}
        show={showUseDefaultScheduleModal}
        onHide={() => setShowUseDefaultScheduleModal(false)}
        onSuccessfullSubmit={() => {
          setShowUseDefaultScheduleModal(false);
          setShowSuccessModal(true);
        }}
      />
      <SuccessModal
        show={showSuccessModal}
        onHide={() => setShowSuccessModal(false)}
        children={`All future sections with a start date within 8 months of the current date will be visible within your student portal. ${  ALGOLIA_UPDATE_PENDING_NOTE}`}
      />
    </>
  );
}


function UseDefaultScheduleModal({
  courseId,
  show,
  onHide,
  onSuccessfullSubmit
}) {
  const onSubmit = async () => {
    // Not passing a custom `schedule` to "Approve course"
    // has the effect of resetting the `schedule` to `NULL`.
    await approveCourse(courseId);
    onSuccessfullSubmit();
  };

  return (
    <ConfirmActionModal
      show={show}
      onHide={onHide}
      title="Use Default Schedule"
      description="All future sections with a start date within 8 months of the current date will be visible within your student portal."
      submitText="Confirm"
      onSubmit={onSubmit}
    />
  );
}
