/**
 * Same as `lodash`'s `throttle()` for functions with no arguments.
 * Runs the function initially and then at the ends of the intervals
 * for any subsequent calls.
 * @param  {function} func
 * @param  {number} interval
 * @param  {boolean} [options.leading] — See `lodash` docs. Is `true` by default.
 * @param  {boolean} [options.trailing] — See `lodash` docs. Is `true` by default.
 * @return {function}
 */
export default function throttle(func, interval, {
  leading = true,
  trailing = true
} = {}) {
  let scheduledRun;

  let isAsync;
  let latestResult;

  let startedAt = 0;
  let finishedAt = 0;

  let onResultListeners;

  // Performs a run of the function.
  const run = function () {
    startedAt = Date.now();
    finishedAt = 0;
    latestResult = undefined;

    onResultListeners = [];

    const onResult = (result) => {
      startedAt = 0;
      finishedAt = Date.now();
      latestResult = result;
      for (const { resolve } of onResultListeners) {
        resolve(result);
      }
      return result;
    };

    const onError = (error) => {
      startedAt = 0;
      for (const { reject } of onResultListeners) {
        reject(error);
      }
      return Promise.reject(error);
    };

    try {
      const result = func();

      // If `func` is `async` then `await` for its result.
      if (result && typeof result.then === 'function') {
        isAsync = true;
        return result.then(
          (result) => {
            return onResult(result);
          },
          (error) => {
            return onError(error);
          }
        );
      } 
      isAsync = false;
      return onResult(result);
      
    } catch (error) {
      return onError(error);
    }
  };

  // Returns a result.
  const returnLatestResult = () => {
    if (isAsync) {
      return Promise.resolve(latestResult);
    } 
    return latestResult;
    
  };

  return function () {
    // If the function is currently running, wait for it to finish
    // and then return the result.
    if (isAsync && startedAt) {
      return new Promise((resolve, reject) => {
        onResultListeners.push({ resolve, reject });
      });
    }

    // If the function is not currently running, see if:
    // * either it should already be run.
    // * or it shouldn't be re-run yet, in which case return a cached result.
    const timeUntilNextRun = interval - (Date.now() - finishedAt);

    if (timeUntilNextRun <= 0) {
      // If a run of the function is already scheduled,
      // cancel it and perform an immediate run.
      if (scheduledRun) {
        // Cancel the scheduled run.
        clearTimeout(scheduledRun);
        scheduledRun = undefined;
      }
      // Perform an immediate run.
      // This type of run is called a "leading" one.
      if (leading) {
        return run();
      } 
      return returnLatestResult();
      
    } 
    // It's not the time to re-run the function yet.
    // Schedule a run of the function, if it's not already scheduled.
    if (!scheduledRun) {
      scheduledRun = setTimeout(() => {
        scheduledRun = undefined;
        // Perform a "trailing" run.
        if (trailing) {
          run();
        }
      }, timeUntilNextRun);
    }

    // Return a cached result.
    return returnLatestResult();
    
  };
}
