/**
 * This schema still supports deprecated fields.
 * Both PRE-REQUISITES and PREREQUISITES are also supported since they refer to the same field.
 * One name is deprecated, while the other is not.
 *
 * Both COURSE COST and COST are supported since they refer to the same field.
 * One name is deprecated, while the other is not.
 *
 * The AVAILABLE SEATS field is also supported. It was removed from the template,
 * but some clients still use outdated templates that include this field.
 */

import Joi from 'joi';
import { Integer, URL } from 'read-excel-file';

import isValidISBN from 'common-lib/lib/isValidISBN';
import isValidName from 'common-lib/lib/isValidName';
import isValidUrl from 'common-lib/lib/isValidUrl';

import {
  COURSE_DELIVERY_METHOD_OPTIONS,
  COURSE_DELIVERY_METHODS,
  COURSE_LEVEL_OPTIONS,
  COURSE_LEVELS,
  TERM_OPTIONS,
  YES_OR_NO_OPTIONS
} from '@acadeum/selection-options';
import { FormField } from '@acadeum/ui';

import { validateCCEraDate } from '@acadeum/validate';

const parseText = (value) => {
  if (typeof value !== 'string') {
    return null;
  }
  if (value.toLowerCase() === 'none') {
    return null;
  }
  return value.replace(/\\n/g, '\n');
};

function validateCourseSectionDate(value, helpers) {
  const formValues = helpers.state.ancestors.at(-1);
  const rowIndex = helpers.state.path[1];
  const onDemand = formValues.rows[rowIndex].onDemand;

  if (onDemand) {
    // Ignore
  } else {
    if (!value) {
      return helpers.error('course.requiredWhenNotOnDemand');
    }

    const ccEraError = validateCCEraDate(value);
    if (ccEraError) {
      return helpers.error('invalidCCEraDate');
    }
  }

  return value;
}

function validateName(value, helpers) {
  if (!isValidName(value)) {
    return helpers.error('invalidName');
  }
  return value;
}

function validateUrl(value, helpers) {
  if (!isValidUrl(value)) {
    return helpers.error('invalidUrl');
  }
  return value;
}

function validateISBN(isbn, helpers) {
  if (typeof isbn !== 'string') {
    return helpers.error('invalidISBN', { message: 'ISBN must be a string' });
  }

  const isbns = isbn.split(';').map(_ => _.trim());
  for (const isbn of isbns) {
    if (!isValidISBN(isbn)) {
      return helpers.error('invalidISBN', { message: `Invalid ISBN: ${isbn}` });
    }
  }
  return isbn;
}

const joiCourseImportSchema = Joi.object({
  // Utility fields
  id: Joi.string().allow(null, '').optional(), // It's a field that `useArrayField` uses to identify the row.
  rowIndex: Joi.number().required(), // rowIndex is used to identify the row in the CSV file.
  status:  Joi.string().allow(null).optional(), // status is to indicate row status.

  // Course Schema
  code: Joi.string().required(),
  title: Joi.string().required(),
  description: Joi.string().required(),
  level: Joi.string().valid(...COURSE_LEVELS).required(),
  hours: Joi.number().min(0).required(),
  pathToSyllabus: Joi
    .string()
    .custom(validateUrl)
    .required(),
  courseMaterialISBNs: Joi
    .string()
    .allow(null, '')
    .custom(validateISBN)
    .optional(),
  courseMaterialTitles: Joi.string().allow(null, '').optional(),
  location: Joi.string().allow(null, '').optional(),
  deliveryMethod: Joi.string().valid(...COURSE_DELIVERY_METHODS).allow(null, '').optional(),
  additionalCosts: Joi.bool().allow(null).optional(),
  hasTextbookCost: Joi.bool().allow(null).optional(),
  prerequisiteText: Joi.string().allow(null, '').optional(),
  certificates: Joi.string().allow(null, '').optional(),
  notes: Joi.string().allow(null, '').optional(),
  onDemand: Joi.bool().allow(null).optional(),

  // Category
  c1: Joi.string().required(),
  c2: Joi.string().allow(null, '').optional(),
  c3: Joi.string().allow(null, '').optional(),

  // Faculty
  f1Name: Joi.string().allow(null, '').optional(),
  f1Url: Joi.string().allow(null, '').optional(),
  f2Name: Joi.string().allow(null, '').optional(),
  f2Url: Joi.string().allow(null, '').optional(),
  f3Name: Joi.string().allow(null, '').optional(),
  f3Url: Joi.string().allow(null, '').optional(),
  f4Name: Joi.string().allow(null, '').optional(),
  f4Url: Joi.string().allow(null, '').optional(),
  f5Name: Joi.string().allow(null, '').optional(),
  f5Url: Joi.string().allow(null, '').optional(),

  // Resources
  a1Name: Joi.string().allow(null, '').optional(),
  a1Url: Joi.string().allow(null, '').optional(),
  a2Name: Joi.string().allow(null, '').optional(),
  a2Url: Joi.string().allow(null, '').optional(),
  a3Name: Joi.string().allow(null, '').optional(),
  a3Url: Joi.string().allow(null, '').optional(),

  session: Joi.object({
    cost: Joi.number().required(),

    startDate: Joi.alternatives()
      .try(Joi.date(), Joi.valid(null))
      .custom(validateCourseSectionDate)
      .optional(),
    endDate: Joi.alternatives()
      .try(Joi.date(), Joi.valid(null))
      .custom(validateCourseSectionDate)
      .optional(),
    lastAddDate: Joi.alternatives()
      .try(Joi.date(), Joi.valid(null))
      .custom(validateCourseSectionDate)
      .optional(),
    lastDropDate: Joi.alternatives()
      .try(Joi.date(), Joi.valid(null))
      .custom(validateCourseSectionDate)
      .optional(),
    term: Joi.string().valid(...TERM_OPTIONS).allow(null, '').optional(),
    internalTerm: Joi.string().allow(null, '').optional(),
    name: Joi.string().allow(null, '').optional(),
    internalName: Joi.string().allow(null, '').optional(),

    section: Joi.object({
      number: Joi.string().required(),
      availableSeats: Joi.number().allow(null).optional(),
      isActive: Joi.bool().allow(null).optional(),
      schedule: Joi.string().allow(null, '').optional(),
      instructorFirstName: Joi
        .string()
        .allow(null, '')
        .custom(validateName)
        .optional(),
      instructorMiddleName: Joi
        .string()
        .allow(null, '')
        .custom(validateName)
        .optional(),
      instructorLastName: Joi
        .string()
        .allow(null, '')
        .custom(validateName)
        .optional()
    })
  })
});

export const joiFormSchema = Joi.object({
  rows: Joi.array().items(joiCourseImportSchema).required(),
  // utility fields
  formAction: Joi.string().valid(null).optional() // formAction is used to identify the form action.
}).prefs({
  errors: { label: false },
  messages: {
    'string.base': 'The field must be a string.',
    'invalidCCEraDate': 'Date must be in 2015 or later.',
    'course.requiredWhenNotOnDemand': 'This field is required when course is not marked as On-Demand.',
    'invalidName': 'The name contains invalid characters',
    'invalidUrl': 'Invalid URL',
    'invalidISBN': 'The field contains invalid ISBN: {#message}'
  }
});

const COURSE_SCHEMA = {
  'COURSE ID': {
    prop: 'code',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        name={`rows.${props.row.index}.code`}
      />
    )
  },
  'COURSE TITLE': {
    prop: 'title',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        name={`rows.${props.row.index}.title`}
      />
    )
  },
  // This should be rewritten as just `type: [String]` for `CATEGORY`.
  'CATEGORY 1': {
    prop: 'c1',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        name={`rows.${props.row.index}.c1`}
      />
    )
  },
  // This should be rewritten as just `type: [String]` for `CATEGORY`.
  'CATEGORY 2': {
    prop: 'c2',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        name={`rows.${props.row.index}.c2`}
      />
    )
  },
  // This should be rewritten as just `type: [String]` for `CATEGORY`.
  'CATEGORY 3': {
    prop: 'c3',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        name={`rows.${props.row.index}.c3`}
      />
    )
  },
  'FACULTY 1 NAME': {
    prop: 'f1Name',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        name={`rows.${props.row.index}.f1Name`}
      />
    )
  },
  // This should be rewritten as just `type: [String]` for `CATEGORY`.
  'FACULTY 2 NAME': {
    prop: 'f2Name',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        name={`rows.${props.row.index}.f2Name`}
      />
    )
  },
  // This should be rewritten as just `type: [String]` for `CATEGORY`.
  'FACULTY 3 NAME': {
    prop: 'f3Name',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        name={`rows.${props.row.index}.f3Name`}
      />
    )
  },
  'FACULTY 4 NAME': {
    prop: 'f4Name',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        name={`rows.${props.row.index}.f4Name`}
      />
    )
  },
  // This should be rewritten as just `type: [String]` for `CATEGORY`.
  'FACULTY 5 NAME': {
    prop: 'f5Name',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        name={`rows.${props.row.index}.f5Name`}
      />
    )
  },
  'FACULTY 1 URL': {
    prop: 'f1Url',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="url"
        name={`rows.${props.row.index}.f1Url`}
      />
    )
  },
  // This should be rewritten as just `type: [String]` for `CATEGORY`.
  'FACULTY 2 URL': {
    prop: 'f2Url',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="url"
        name={`rows.${props.row.index}.f2Url`}
      />
    )
  },
  // This should be rewritten as just `type: [String]` for `CATEGORY`.
  'FACULTY 3 URL': {
    prop: 'f3Url',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="url"
        name={`rows.${props.row.index}.f3Url`}
      />
    )
  },
  'FACULTY 4 URL': {
    prop: 'f4Url',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="url"
        name={`rows.${props.row.index}.f4Url`}
      />
    )
  },
  // This should be rewritten as just `type: [String]` for `CATEGORY`.
  'FACULTY 5 URL': {
    prop: 'f5Url',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="url"
        name={`rows.${props.row.index}.f5Url`}
      />
    )
  },
  'ASSESSMENT 1 NAME': {
    prop: 'a1Name',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        name={`rows.${props.row.index}.a1Name`}
      />
    )
  },
  // This should be rewritten as just `type: [String]` for `CATEGORY`.
  'ASSESSMENT 2 NAME': {
    prop: 'a2Name',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        name={`rows.${props.row.index}.a2Name`}
      />
    )
  },
  // This should be rewritten as just `type: [String]` for `CATEGORY`.
  'ASSESSMENT 3 NAME': {
    prop: 'a3Name',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        name={`rows.${props.row.index}.a3Name`}
      />
    )
  },
  'ASSESSMENT 1 URL': {
    prop: 'a1Url',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="url"
        name={`rows.${props.row.index}.a1Url`}
      />
    )
  },
  // This should be rewritten as just `type: [String]` for `CATEGORY`.
  'ASSESSMENT 2 URL': {
    prop: 'a2Url',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="url"
        name={`rows.${props.row.index}.a2Url`}
      />
    )
  },
  // This should be rewritten as just `type: [String]` for `CATEGORY`.
  'ASSESSMENT 3 URL': {
    prop: 'a3Url',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="url"
        name={`rows.${props.row.index}.a3Url`}
      />
    )
  },
  'COURSE DESCRIPTION': {
    prop: 'description',
    parse: parseText,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        multiline
        autoRows
        name={`rows.${props.row.index}.description`}
      />
    )
  },
  'COURSE LEVEL': {
    prop: 'level',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="select"
        options={COURSE_LEVEL_OPTIONS}
        name={`rows.${props.row.index}.level`}
      />
    )
  },
  'CREDITS': {
    prop: 'hours',
    type: Number,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="number"
        name={`rows.${props.row.index}.hours`}
      />
    )
  },
  'SYLLABUS': {
    prop: 'pathToSyllabus',
    type: URL,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="url"
        name={`rows.${props.row.index}.pathToSyllabus`}
      />
    )
  },
  'COURSE MATERIAL ISBNS': {
    prop: 'courseMaterialISBNs',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        multiline
        autoRows
        name={`rows.${props.row.index}.courseMaterialISBNs`}
      />
    )
  },
  'COURSE MATERIAL TITLES': {
    prop: 'courseMaterialTitles',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        multiline
        autoRows
        name={`rows.${props.row.index}.courseMaterialTitles`}
      />
    )
  },
  'COURSE LOCATION': {
    prop: 'location',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        multiline
        autoRows
        name={`rows.${props.row.index}.location`}
      />
    )
  },
  'COURSE DELIVERY METHOD': {
    prop: 'deliveryMethod',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="select"
        options={COURSE_DELIVERY_METHOD_OPTIONS}
        name={`rows.${props.row.index}.deliveryMethod`}
      />
    )
  },
  'ADDITIONAL COSTS': {
    prop: 'additionalCosts',
    type: Boolean,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="select"
        options={YES_OR_NO_OPTIONS}
        name={`rows.${props.row.index}.additionalCosts`}
      />
    )
  },
  'TEXTBOOK COST': {
    prop: 'hasTextbookCost',
    type: Boolean,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="select"
        options={YES_OR_NO_OPTIONS}
        name={`rows.${props.row.index}.hasTextbookCost`}
      />
    )
  },
  'PREREQUISITES': {
    prop: 'prerequisiteText',
    parse: parseText,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        multiline
        autoRows
        name={`rows.${props.row.index}.prerequisiteText`}
      />
    )
  },
  // It's not a typo error, because some client have a deprecated templates where they have 'PRE-REQUISITES' instead of 'PREREQUISITES'.
  'PRE-REQUISITES': {
    prop: 'prerequisiteText',
    parse: parseText,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        multiline
        autoRows
        name={`rows.${props.row.index}.prerequisiteText`}
      />
    )
  },
  'CERTIFICATES': {
    prop: 'certificates',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        multiline
        autoRows
        name={`rows.${props.row.index}.certificates`}
      />
    )
  },
  'NOTES': {
    prop: 'notes',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        multiline
        autoRows
        name={`rows.${props.row.index}.notes`}
      />
    )
  },
  'ON-DEMAND COURSE': {
    prop: 'onDemand',
    type: Boolean,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="select"
        options={YES_OR_NO_OPTIONS}
        name={`rows.${props.row.index}.onDemand`}
      />
    )
  }
};

export const SESSION_SCHEMA = {
  'START DATE': {
    prop: 'startDate',
    type: Date,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="date"
        name={`rows.${props.row.index}.session.startDate`}
      />
    )
  },
  'END DATE': {
    prop: 'endDate',
    type: Date,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="date"
        name={`rows.${props.row.index}.session.endDate`}
      />
    )
  },
  'ADD DATE': {
    prop: 'lastAddDate',
    type: Date,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="date"
        name={`rows.${props.row.index}.session.lastAddDate`}
      />
    )
  },
  'DROP DATE': {
    prop: 'lastDropDate',
    type: Date,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="date"
        name={`rows.${props.row.index}.session.lastDropDate`}
      />
    )
  },
  'TERM': {
    prop: 'term',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="select"
        options={TERM_OPTIONS}
        name={`rows.${props.row.index}.session.term`}
      />
    )
  },
  'INTERNAL TERM': {
    prop: 'internalTerm',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        name={`rows.${props.row.index}.session.internalTerm`}
      />
    )
  },
  'SESSION': {
    prop: 'name',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        name={`rows.${props.row.index}.session.name`}
      />
    )
  },
  'INTERNAL SESSION': {
    prop: 'internalName',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        name={`rows.${props.row.index}.session.internalName`}
      />
    )
  },
  'COST': {
    prop: 'cost',
    type: Number,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        currency="USD"
        type="currency"
        name={`rows.${props.row.index}.session.cost`}
      />
    )
  },
  // COURSE COST is deprecated, but we still need to support it for backwards compatibility.
  'COURSE COST': {
    prop: 'cost',
    type: Number,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="currency"
        currency="USD"
        name={`rows.${props.row.index}.session.cost`}
      />
    )
  }
};

export const SECTION_SCHEMA = {
  'SECTION': {
    prop: 'number',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        name={`rows.${props.row.index}.session.section.number`}
      />
    )
  },
  'AVAILABLE SEATS': {
    prop: 'availableSeats',
    type: Integer,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="number"
        name={`rows.${props.row.index}.session.section.availableSeats`}
      />
    )
  },
  'ACTIVE': {
    prop: 'isActive',
    type: Boolean,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="select"
        options={YES_OR_NO_OPTIONS}
        name={`rows.${props.row.index}.session.section.isActive`}
      />
    )
  },
  'DATE & TIME': {
    prop: 'schedule',
    parse(value) {
      if (!value) {
        return null;
      }
      if (value.toLowerCase() === 'asynchronous') {
        return null;
      }
      return value;
    },
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        name={`rows.${props.row.index}.session.section.schedule`}
      />
    )
  },
  'INSTRUCTOR FIRST NAME': {
    prop: 'instructorFirstName',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="name"
        name={`rows.${props.row.index}.session.section.instructorFirstName`}
      />
    )
  },
  'INSTRUCTOR MIDDLE NAME': {
    prop: 'instructorMiddleName',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="name"
        name={`rows.${props.row.index}.session.section.instructorMiddleName`}
      />
    )
  },
  'INSTRUCTOR LAST NAME': {
    prop: 'instructorLastName',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="name"
        name={`rows.${props.row.index}.session.section.instructorLastName`}
      />
    )
  }
};

export const COURSE_UPLOAD_SCHEMA = {
  ...COURSE_SCHEMA,
  '<SESSION>': {
    prop: 'session',
    type: {
      ...SESSION_SCHEMA,
      '<SECTION>': {
        prop: 'section',
        type: SECTION_SCHEMA
      }
    }
  }
};

export function transformCourseUploadData(rows) {
  return rows.map(row => {
    // Set `category` property.
    //
    // "category" is a concatenation of "category 1", "category 2", "category 3".
    //
    // The property is historically called `category` because in the `courses` SQL table
    // the column is incorrectly called `category` instead of `categories`.
    //
    row.category = [row.c1, row.c2, row.c3].filter(_ => _).join(', ');
    delete row.c1;
    delete row.c2;
    delete row.c3;

    // Invert `zeroTextbookCost` property.
    row.zeroTextbookCost = invertBoolean(row.hasTextbookCost);
    delete row.hasTextbookCost;

    // Set `resources` property.

    row.resources = [];

    const facultyCredentialsAndLearningAssessments = [
      'f1',
      'f2',
      'f3',
      'f4',
      'f5',
      'a1',
      'a2',
      'a3'
    ];

    facultyCredentialsAndLearningAssessments.map((prefix) => {
      if (row[`${prefix  }Name`] && row[`${prefix  }Url`]) {
        row.resources.push({
          name: row[`${prefix  }Name`],
          url: row[`${prefix  }Url`],
          type: prefix.startsWith('f') ? 'FACULTY_CREDENTIAL' : 'LEARNING_ASSESSMENT'
        });
      }
      delete row[`${prefix  }Name`];
      delete row[`${prefix  }Url`];
    });

    return row;
  });
}

function invertBoolean(value) {
  if (value === true) {
    return false;
  }
  if (value === false) {
    return true;
  }
  return value;
}
