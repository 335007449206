import classNames from 'classnames';
import React, { isValidElement, memo } from 'react';
import type { ReactNode, CSSProperties} from 'react';

import { get } from '../../helpers/checkboard';

import styles from './Checkboard.module.scss';

export interface CheckboardProps {
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
  size?: number;
  white?: string;
  grey?: string;
}

export const Checkboard = memo<CheckboardProps>(({
  style: propsStyle,
  className,
  children,
  size = 4,
  white = 'transparent',
  grey = 'rgba(0,0,0,.08)'
}) => {

  const style = {
    ...propsStyle,
    background: `url(${get(white, grey, size)}) center left`
  };

  return isValidElement(children) ?
    React.cloneElement(children, children.props, {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      style: { ...children.props.style, ...style },
      className: classNames(className, styles.Checkboard, children.props.className)
    }) : (
      <div
        style={style}
        className={classNames(className, styles.Checkboard)}
      />
    );
});
