export async function validateImageLoadability(value?: string) {
  if (!value) {
    return;
  }
  const img = new Image();
  img.src = value;
  return await new Promise<string | null>((resolve) => {
    img.onload = () => resolve(null);
    img.onerror = () => resolve('Image cannot be loaded. Please check the image URL.');
  });
}
