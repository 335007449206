import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import actions from '../../actions';
import { useRefreshActionRequiredCounts } from '../../api/actionRequiredCounts';

import FormHeading from '../../components/FormHeading';
import StudentForm from '../../components/StudentForm';
import { formatName } from '../../helpers/format';


import useLocation from '../../hooks/useLocation';


const {
  notify,
  fetchStudent,
  editStudent,
  fetchStudentsNoRace,
  goto
} = actions;

export default function EditStudent() {
  const refreshActionRequiredCounts = useRefreshActionRequiredCounts();
  const {
    query: {
      raceAndEthnicityNotSpecified
    }
  } = useLocation();

  const student = useSelector(state => state.students.student);

  const onSubmit = async (values) => {
    delete values.email;
    await editStudent(student.id, values);
    notify('Student profile has been updated!');
    // Update "No Race / Ethnicity specified" notification bar count.
    if (!student.races && values.races) {
      void refreshActionRequiredCounts();
      const { results: studentsNoRace } = await fetchStudentsNoRace();
      if (studentsNoRace.length > 0) {
        goto('/students?raceAndEthnicityNotSpecified=✓');
      } else {
        goto('/students');
      }
    } else {
      goto(`/students/${student.id}`);
    }
  };

  return (
    <div>
      <FormHeading>
        {student.firstName} {student.lastName}
      </FormHeading>

      <StudentForm
        values={student}
        cancelLocation="/students"
        submitText="Save"
        submitOnMount={raceAndEthnicityNotSpecified !== undefined}
        onSubmit={onSubmit}/>
    </div>
  );
}

EditStudent.propTypes = {
  student: PropTypes.object,
  location: PropTypes.object
};

EditStudent.meta = () => ({
  title: 'Edit Student'
});

EditStudent.breadcrumbs = (state, { location }) => [
  ['Students', `/students${  location.query.raceAndEthnicityNotSpecified ? '?raceAndEthnicityNotSpecified=✓' : ''}`],
  formatName(state.students.student)
];

EditStudent.load = async ({ params: { id } }) => {
  await fetchStudent(id);
};
