import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';

import { FileUpload } from '../../../FileUpload';

export const FormFileUpload = ({
  name,
  defaultValue,
  rules,
  multiple,
  accept,
  disabled,
  readOnly
}) => {
  const { field } = useController({ name, defaultValue, rules });
  const { onChange } = field;

  return (
    <FileUpload
      onChange={onChange}
      onReset={onChange}
      multiple={multiple}
      accept={accept}
      disabled={disabled || readOnly}
    />
  );
};

FormFileUpload.propTypes = {
  name: PropTypes.string,
  rules: PropTypes.object,
  defaultValue: PropTypes.any,
  multiple: PropTypes.bool,
  accept: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
};
