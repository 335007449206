import type { RtkApi } from '@acadeum/redux';
import type {
  ChargeStatus,
  CurrencyInCents,
  FeeType,
  Id,
  Institution,
  InvoiceStatus,
  PaymentIntentStatus
} from '@acadeum/types';

export interface FetchTransfersOutputListItem {
  id: string;
  destinationPaymentId: string;
  createdAt: Date;
  type: FeeType;
  netReceived?: CurrencyInCents;
  netRefund?: CurrencyInCents;
  sourceTransaction?: {
    id: string;
    amount: CurrencyInCents;
    refundedAmount: CurrencyInCents;
    applicationFeeAmount: CurrencyInCents;
    status: ChargeStatus;
    description: string;
    customer: string;
    paymentMethod?: {
      type: string;
    };
    invoice?: {
      id: string;
      amountDue: CurrencyInCents;
      createdAt: Date;
      dueDate?: Date;
      status: InvoiceStatus;
      number: string;
      invoicePdfUrl: string;
    };
    institution?: Pick<Institution, 'id' | 'vanityUrl' | 'name' | 'logoUrl'>;
  };
}

export interface FetchTransfersInput {
  page?: number;
  pageSize?: number;
  filters?: {
    ids?: Id[];
    type?: FeeType;
    institutionIds?: Id[];
    invoiceExists?: boolean;
    invoiceStatus?: InvoiceStatus;
    invoiceCreatedAt?: {
      from?: Date;
      to?: Date;
    };
  }
}

export interface FetchTransfersOutput {
  results: FetchTransfersOutputListItem[];
  totalCount: number;
  page: number;
  pageSize: number;
}

export interface FetchPaymentIntentsOutputListItem {
  id: string;
  amount: CurrencyInCents;
  description: string;
  status: string;
  type: FeeType;
  institution?: Pick<Institution, 'id' | 'vanityUrl' | 'name' | 'logoUrl'>;
  createdAt: Date;
  charge?: {
    id: string;
    refundedAmount: CurrencyInCents;
    paymentMethod?: {
      type: string;
    }
  };
  invoice?: {
    id: string;
    createdAt: Date;
  };
}

export interface FetchPaymentIntentsInput {
  page?: number;
  pageSize?: number;
  search?: string;
  filters?: {
    ids?: string[];
    type?: FeeType;
    institutionIds?: Id[];
    status?: PaymentIntentStatus;
    invoiceCreatedAt?: {
      from?: Date;
      to?: Date;
    };
  }
}

export interface FetchPaymentIntentsOutput {
  results: FetchPaymentIntentsOutputListItem[];
  totalCount: number;
  page: number;
  pageSize: number;
}

export const injectStripeEndpoints = (rtkApi: RtkApi) =>
  rtkApi
    .enhanceEndpoints({ addTagTypes: ['transfer', 'paymentIntent'] })
    .injectEndpoints({
      endpoints: build => ({
        fetchTransfers: build.query<FetchTransfersOutput, FetchTransfersInput>({
          query: (params) => ({
            url: '/stripe/transfers',
            params
          }),
          providesTags: [{ type: 'transfer', id: 'LIST' }]
        }),
        fetchPaymentIntents: build.query<FetchPaymentIntentsOutput, FetchPaymentIntentsInput>({
          query: (params) => ({
            url: '/stripe/payment-intents',
            params
          }),
          providesTags: [{ type: 'paymentIntent', id: 'LIST' }]
        })
      })
    });

export type UseFetchTransfersQuery = ReturnType<typeof injectStripeEndpoints>['useFetchTransfersQuery'];
export type UseLazyFetchTransfersQuery = ReturnType<typeof injectStripeEndpoints>['useLazyFetchTransfersQuery'];
export type UseFetchPaymentIntentsQuery = ReturnType<typeof injectStripeEndpoints>['useFetchPaymentIntentsQuery'];
export type UseLazyFetchPaymentIntentsQuery = ReturnType<typeof injectStripeEndpoints>['useLazyFetchPaymentIntentsQuery'];
