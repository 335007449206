import type { RowData } from '@tanstack/react-table';
import React, { useCallback, useMemo } from 'react';

import { TrashIcon, XMarkIcon } from '@acadeum/icons';

import { Button } from '../../../Button';
import { Chip } from '../../../Chip';

import { Icon } from '../../../Icon';
import { Text } from '../../../Text';
import { isFilterItemObject } from '../helpers';
import type { FilterItem } from '../types';

import styles from './ClientFilters.module.scss';

interface FiltersWrapperProps<TData> {
  filters: FilterItem<TData>[] | FilterItem<TData>[][];
  filterValues: object;
  setFilterValues: (filterValues: object) => void;
  allRows: TData[];
}

export const ClientFilters = <TData extends RowData>({
  filters,
  setFilterValues,
  filterValues,
  allRows
}: FiltersWrapperProps<TData>) => {
  const filteredValues = useMemo(() => {
    return Object.entries(filterValues).filter(([, option]) => {
      // Check option is defined and its value is not null, considering `0` and `false` as valid.
      return option && option.value !== null;
    });
  }, [filterValues]);

  const reset = useCallback(() => {
    const values = structuredClone(filterValues);

    for (const key in values) {
      values[key] = null;
    }
    setFilterValues(values);
  }, [filterValues]);

  const renderFilter = (filter) => {
    if (!isFilterItemObject(filter)) {
      return filter;
    }

    return (
      <div key={filter.id}>
        {filter.render({
          setFilterValues,
          getValue: () => filterValues[filter.id]?.value,
          getFilterValue: (id) => filterValues[id]?.value,
          rows: allRows
        })}
      </div>
    );
  };

  return (
    <div className={styles.FiltersWrapper}>
      {filters.every(_ => Array.isArray(_)) ? (
        filters.map((filterGroup, index) => (
          <div className={styles.filters} key={index}>
            {filterGroup.map(renderFilter)}
          </div>
        ))
      ) : (
        <div className={styles.filters}>
          {filters.map(renderFilter)}
        </div>
      )}
      {filteredValues.length > 0 && (
        <div className={styles.chips}>
          {filteredValues.map(([name, option]) => (
            <Chip
              key={name}
              onClick={() => setFilterValues(prev => ({ ...prev, [name]: null }))}
            >
              <Text as="span" truncate>
                {option?.label}
              </Text>
              <Icon icon={XMarkIcon} ml="sm"/>
            </Chip>
          ))}
          <Button
            variant="text"
            icon={TrashIcon}
            onClick={reset}
          >
            Reset Filters
          </Button>
        </div>
      )}
    </div>
  );
};
