import userRolePermissionSubjects from 'common-lib/constants/userRolePermissionSubjects.json';

import type { UserRolePermissionOperation, UserRolePermissionScope } from '@acadeum/types';

import { formatSubjectName } from './formatSubjectName';
import type { PermissionObjectType } from './types';

// Permission scopes, sorted from most strong to least strong.
export const SCOPES: Readonly<UserRolePermissionScope[]> = <const>['*', 'org', 'owner'];

export const PERMISSION_OPERATIONS: Readonly<UserRolePermissionOperation[]> = <const>[
  'create',
  'read',
  'update',
  'delete'
];

export const DEFAULT_TABLE_PERMISSIONS_SUBJECT: Readonly<PermissionObjectType[]> = userRolePermissionSubjects.map((subject) => ({
  subject,
  name: formatSubjectName(subject),
  create: null,
  read: null,
  update: null,
  delete: null
}));
