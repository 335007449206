import { __DEV__, __STAGE__ } from './env';

export type WebSite = 'courseshare' | 'account' | 'admin' | 'report-center' | 'marketplace-admin' | 'marketplace' | 'students' | 'degree-admin';

const prefix = __STAGE__ === 'prod' ? '' : `${__STAGE__}-`;

export const __COURSESHARE_URL__ = __DEV__
  ? 'http://localhost:3002'
  : `https://${prefix}courseshare.acadeum.com`;

export const __ACCOUNT_CENTER_URL__ = __DEV__
  ? 'http://localhost:3010'
  : `https://${prefix}account.acadeum.com`;

export const __ADMIN_CENTER_URL__ = __DEV__
  ? 'http://localhost:3020'
  : `https://${prefix}admin.acadeum.com`;

export const __MARKETPLACE_ADMIN_URL__ = __DEV__
  ? 'http://localhost:3040'
  : `https://${prefix}admin-marketplace.acadeum.com`;

export const __STUDENTS_SITE_URL__ = __DEV__
  ? 'http://localhost:3000'
  : `https://${prefix}students.acadeum.com`;

export const __REPORT_CENTER_URL__ = __DEV__
  ? 'http://localhost:3050'
  : `https://${prefix}reports.acadeum.com`;

export const __TRANSFER_SITE_URL__ = __DEV__
  ? 'http://localhost:3060'
  : `https://${prefix}transfer.acadeum.com`;
  
export const __DEGREE_ADMIN_SITE_URL__ = __DEV__
  ? 'http://localhost:3080'
  : `https://${prefix}degree-admin.acadeum.com`;

const __MARKETPLACE_URL__ = 'https://{subdomain}.acadeum.com';

export const webSiteUrls: Record<WebSite, string> = {
  courseshare: __COURSESHARE_URL__,
  account: __ACCOUNT_CENTER_URL__,
  admin: __ADMIN_CENTER_URL__,
  'report-center': __REPORT_CENTER_URL__,
  'marketplace-admin': __MARKETPLACE_ADMIN_URL__,
  students: __STUDENTS_SITE_URL__,
  marketplace: __MARKETPLACE_URL__,
  'degree-admin': __DEGREE_ADMIN_SITE_URL__
};
