import * as Popover from '@radix-ui/react-popover';
import classNames from 'classnames';
import type { FC } from 'react';
import React from 'react';

import { Avatar } from '@acadeum/core-ui';
import { isAcadeumAdministrator } from '@acadeum/helpers';
import { CaretDownIcon } from '@acadeum/icons';

import { useTranslate } from '@acadeum/translate';
import type { AsProp, UserProfile } from '@acadeum/types';
import {
  useGetInstitutionLink,
  toast,
  Link,
  Form,
  InstitutionAutocomplete,
  FormFooter,
  FormSubmit
} from '@acadeum/ui';

import styles from './InstitutionMenu.module.scss';

export interface InstitutionMenuProps extends AsProp {
  user: UserProfile;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useAdminChangeOwnInstitutionMutation: any;
  className?: string;
}

export const InstitutionMenu: FC<InstitutionMenuProps> = ({
  user,
  className,
  useAdminChangeOwnInstitutionMutation,
  as: Component = 'div'
}) => {
  const t = useTranslate('shared-admin-ui.InstitutionMenu');
  const getInstitutionLink = useGetInstitutionLink();

  const institution = user.institution;

  const [changeAdminInstitution] = useAdminChangeOwnInstitutionMutation();

  const onChangeInstitution = async (values) => {
    await changeAdminInstitution(values).unwrap();
    toast.success('Your user has been updated, reloading the page...');
    // Wait for the user to see the message
    await new Promise(resolve => setTimeout(resolve, 3000));
    // Reload the page to get the current user re-fetched
    window.location.reload();
  };

  return (
    <Component className={classNames(className, styles.InstitutionMenu)}>
      <Link
        className={styles.InstitutionMenu__link}
        to={getInstitutionLink?.(institution)}
        title={institution.name}
        monochrome
      >
        {institution.name}
        <Avatar
          size={32}
          type="institution"
          url={institution.logoUrl}
        />
      </Link>
      {isAcadeumAdministrator(user) && onChangeInstitution && (
        <Popover.Root>
          <Popover.Trigger className={styles['buttonTrigger']} aria-label={t('textButton')}>
            <CaretDownIcon className={styles['icon']}/>
          </Popover.Trigger>
          <Popover.Portal>
            <Popover.Content className={styles['InstitutionMenu__dropdown']} sideOffset={4} align="end">
              <Form onSubmit={onChangeInstitution}>
                <InstitutionAutocomplete
                  superAdmin
                  type="institution"
                  label={t('institutionId.label')}
                  placeholder={t('institutionId.placeholder')}
                />
                <FormFooter>
                  <FormSubmit>
                    {t('submit')}
                  </FormSubmit>
                </FormFooter>
              </Form>
            </Popover.Content>
          </Popover.Portal>
        </Popover.Root>
      )}
    </Component>
  );
};
