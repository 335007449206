import classNames from 'classnames';
import { useMemo, useState } from 'react';

import { XMarkIcon } from '@acadeum/icons';
import { Icon } from '@acadeum/ui';

import Collapsible from '../../../Collapsible';

export const OldDesigner = ({ options: options_, onChange: onChange_, value }) => {
  const [search, setSearch] = useState<string>();
  const onChangeSearch = (e) => {
    setSearch(e.target.value);
  };

  const onClearSearch = () => {
    setSearch('');
  };

  const options = useMemo(() => {
    return search ? options_.filter(_ => _.label.includes(search)) : options_;
  }, [search, options_]);

  const onChange = (e) => {
    if (e.target.checked) {
      console.log('e.target.value', e.target.value);
      onChange_([...value, e.target.value]);
    } else {
      onChange_(value.filter(_ => _ !== e.target.value));
    }
  };

  return (
    <Collapsible
      title="Course Division"
      isOpen={false}
    >
      <div className="InstantSearch-MultiSelectList">
        <div className="ais-RefinementList">
          <div className="ais-RefinementList-searchBox">
            <div className="ais-SearchBox">
              <div className="ais-SearchBox-form">
                <input
                  className="ais-SearchBox-input"
                  aria-label="Search State"
                  placeholder="Search"
                  value={search}
                  onChange={onChangeSearch}
                  type="search"
                />
                <button
                  title="Clear the search query."
                  className="ais-SearchBox-reset"
                  hidden={!search}
                  onClick={onClearSearch}
                >
                  <Icon icon={XMarkIcon} size="md"/>
                </button>
              </div>
            </div>
            <ul className="ais-RefinementList-list">
              {options.map(_ => (
                <li
                  className={classNames('ais-RefinementList-item', {
                    'ais-RefinementList-item--selected': value.includes(_.value)
                  })}
                  key={_.value}
                >
                  <label className="ais-RefinementList-label">
                    <input
                      type="checkbox"
                      className="ais-RefinementList-checkbox"
                      onChange={onChange}
                      value={_.value}
                    />
                    <span className="ais-RefinementList-labelText">
                      {_.label}
                    </span>
                  </label>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </Collapsible>
  );
};
