import PropTypes from 'prop-types';
import React from 'react';

import { useTranslate } from '@acadeum/translate';
import {
  Col,
  ContentSection,
  Link,
  Row,
  Text
} from '@acadeum/ui';


const CourseOverviewSection = ({ course }) => {
  const t = useTranslate('CreateAdvisorRecommendation');

  return (
    <>
      <Text className="CourseOverview__subsectionDescription">
        {t('courseOverviewDescription')}
      </Text>
      <ContentSection padding="md">
        <Row>
          <Col md={6}>
            <Text>
              {t('teachingMemberInstitution')}
            </Text>
            <Link to={`/institutions/${course.institution.id}`}>
              {course.institution.name}
            </Link>
          </Col>
          <Col md={6}>
            <Text>
              {t('course')}
            </Text>
            <Link to={`/courses/${course.id}`}>
              {`${course.code} ${course.title}`}
            </Link>
          </Col>
        </Row>
      </ContentSection>
    </>
  );
};

CourseOverviewSection.propTypes = {
  course: PropTypes.object.isRequired
};

export default CourseOverviewSection;
