import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

import { useTranslate } from '@acadeum/translate';

import Col from '../Col';
import FormField from '../FormField';
import Row from '../Row';

export default function ReasonFormField({
  required,
  col,
  defaultValue,
  notesDefaultValue,
  other = true,
  labelsPath,
  options
}) {
  const t = useTranslate('ReasonFormField');

  const options_ = useMemo(() => {
    const options_ = options.map((value) => ({
      value,
      label: t(`${labelsPath}.options.${value}`, { global: true })
    }));
    if (other) {
      options_.push({
        label: t('other'),
        value: 'OTHER'
      });
    }
    return options_;
  }, [options]);

  const [reason, setReason] = useState(defaultValue);

  const reasonFormField = (
    <FormField
      required={required}
      name="reason"
      type="select"
      defaultValue={defaultValue}
      options={options_}
      label={t(`${labelsPath}.label`, { global: true })}
      onChange={setReason}
    />
  );

  const reasonNotesFormField = (
    <FormField
      autoFocus
      required
      multiline
      rows={2}
      name="reasonNotes"
      placeholder={t(`${labelsPath}.notesHint`, { global: true, required: false })}
      defaultValue={notesDefaultValue}
      label={t('notes')}
      labelHidden
    />
  );

  if (col) {
    return (
      <>
        <Row>
          <Col col={col}>
            {reasonFormField}
          </Col>
        </Row>
        {reason === 'OTHER' &&
          <Row>
            <Col col={col}>
              {reasonNotesFormField}
            </Col>
          </Row>
        }
      </>
    );
  }

  return (
    <>
      {reasonFormField}
      {reason === 'OTHER' &&
        reasonNotesFormField
      }
    </>
  );
}

ReasonFormField.propTypes = {
  required: PropTypes.bool,
  col: PropTypes.number,
  other: PropTypes.bool,
  labelsPath: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  notesDefaultValue: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string).isRequired
};
