// Sort "Course Type" filter options.
// https://github.com/Acadeum/Tickets/issues/1443#issuecomment-1410812851
export default function sortInstitutionLevelAndFundingSearchFilterOptions(items) {
  return sortSearchFilterOptions(items, LEVEL_AND_FUNDING_OPTIONS_ORDER);
}

export function sortInstitutionLevelSearchFilterOptions(items) {
  return sortSearchFilterOptions(items, LEVEL_OPTIONS_ORDER);
}

export function sortInstitutionFundingSearchFilterOptions(items) {
  return sortSearchFilterOptions(items, FUNDING_OPTIONS_ORDER);
}

function sortSearchFilterOptions(items, ORDER) {
  return items.sort((item1, item2) => {
    const item1Order = ORDER.indexOf(item1.label);
    const item2Order = ORDER.indexOf(item2.label);
    if (item1Order >= 0) {
      if (item2Order >= 0) {
        return item1Order - item2Order;
      } 
      return -1;
      
    } 
    if (item2Order >= 0) {
      return 1;
    } 
    if (item1.label.localeCompare) {
      return item1.label.localeCompare(item2.label);
    }
    // Compare strings using mathematical operators.
    // https://www.freecodecamp.org/news/javascript-string-comparison-how-to-compare-strings-in-js/
    if (item1.label > item2.label) {
      return 1;
    } if (item1.label < item2.label) {
      return -1;
    } 
    return 0;
      
    
    // eslint-disable-next-line no-unreachable
    if (item2Order < 0) {
      return;
    }
    item1.label;
    item2.label;
  });
}

const FUNDING_OPTIONS_ORDER = [
  'Private, Not-For-Profit',
  'Private, For-Profit',
  'Public, Not-For-Profit'
];

const LEVEL_OPTIONS_ORDER = [
  'Four-Year',
  'Two-Year',
  'Professional Development',
  'High School'
];

const LEVEL_AND_FUNDING_OPTIONS_ORDER = [
  'Private, Not-For-Profit',
  'Private, For-Profit',
  'Public, Not-For-Profit',
  'Four-Year',
  'Two-Year',
  'High School',
  'Professional Development'
];
