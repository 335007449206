export const config = {
  barSize: 18,
  crossSize: 18,
  inputSize: 40,
  delay: 150,
  defaultColor: 'rgba(236, 245, 251, 1)',
  defaultGradient:
    'linear-gradient(90deg, rgba(250, 208, 196, 1) 0%, rgba(250, 208, 196, 1) 35%, rgba(255, 209, 255, 1) 100%)'
};

export const defaultLocales = {
  CONTROLS: {
    LABEL: 'Color Type Buttons',
    SOLID: 'Solid',
    GRADIENT: 'Gradient'
  }
};

export const fakePresets = [
  'rgba(202, 108, 69, 1)',
  'rgba(248, 160, 107, 1)',
  'rgba(251, 197, 170, 1)',
  'rgba(212, 227, 167, 1)',
  'rgba(186, 209, 99, 1)',
  'rgba(82, 194, 143, 1)',
  'rgba(65, 172, 123, 1)',
  'rgba(86, 200, 195, 1)',
  'rgba(86, 177, 200, 1)',
  'rgba(170, 214, 243, 1)',
  'rgba(111, 187, 233, 1)',
  'rgba(200, 194, 247, 1)',
  'rgba(165, 155, 240, 1)',
  'rgba(116, 109, 189, 1)'
];

export const fakeGradientPresets = [
  'linear-gradient(90deg, rgba(251, 209, 207, 1) 0%, rgba(255, 209, 247, 1) 100%)',
  'linear-gradient(90deg, rgba(238, 156, 167, 1) 0%, rgba(255, 221, 225, 1) 100%)',
  'linear-gradient(90deg, rgba(245, 239, 239, 1) 0%, rgba(254, 173, 166, 1) 100%)',
  'linear-gradient(90deg, rgba(253, 252, 251, 1) 0%, rgba(226, 209, 195, 1) 100%)',
  'linear-gradient(90deg, rgba(193, 223, 196, 1) 0%, rgba(222, 236, 221, 1) 100%)',
  'linear-gradient(90deg, rgba(172, 203, 238, 1) 0%, rgba(231, 240, 253, 1) 100%)',
  'linear-gradient(90deg, rgba(161, 196, 253, 1) 0%, rgba(194, 233, 251, 1) 100%)',
  'linear-gradient(90deg, rgba(189, 194, 232, 1) 0%, rgba(189, 194, 232, 1) 0%, rgba(230, 222, 233, 1) 100%)'
];
