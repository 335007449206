import type { InferProps } from 'prop-types';
import PropTypes from 'prop-types';
import type React from 'react';
import { useRef, useEffect } from 'react';

const propTypes = {
  notificationsCount: PropTypes.number,
  notificationsIconUrl: PropTypes.string.isRequired
};

type SiteIconProps = InferProps<typeof propTypes>

// When `notificationsCount` is `> 0` then it sets the site icon
// (aka "favicon") to `notificationsIconHref` URL.
export const SiteIcon: React.FC<SiteIconProps> = ({
  notificationsCount,
  notificationsIconUrl
}) => {
  const defaultSiteIconUrl = useRef<string>();

  useEffect(() => {
    const siteIconUrl = document.querySelector('link[rel*="icon"]');
    if (siteIconUrl) {
      defaultSiteIconUrl.current = siteIconUrl.getAttribute('href') as string;
    }
  }, []);

  useEffect(() => {
    if (notificationsCount) {
      setSiteIconUrl(notificationsIconUrl);
    } else {
      setSiteIconUrl(defaultSiteIconUrl.current);
    }
  }, [notificationsCount, notificationsIconUrl]);

  return null;
};

SiteIcon.propTypes = propTypes;

function setSiteIconUrl(href?: string) {
  if (href) {
    // https://stackoverflow.com/questions/260857/changing-website-favicon-dynamically
    document.querySelector('link[rel*="icon"]')?.setAttribute('href', href);
  }
}
