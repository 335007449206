import type { FC } from 'react';
import React, { useState } from 'react';

import { useTranslate } from '@acadeum/translate';
import { Alert, Button } from '@acadeum/ui';

import { VerifyBankAccountFormModal } from '../VerifyBankAccountFormModal';
import type { VerifyBankAccountFormModalProps } from '../VerifyBankAccountFormModal';

export interface VerifyBankAccountNotificationProps extends Pick<VerifyBankAccountFormModalProps, 'useVerifyPrimaryPaymentSourceMutation'> {
  type: 'current' | 'new';
  onAfterVerifyBankAccount: () => void;
}

export const VerifyBankAccountNotification: FC<VerifyBankAccountNotificationProps> = ({
  type,
  onAfterVerifyBankAccount,
  useVerifyPrimaryPaymentSourceMutation
}) => {
  const [showVerifyBankAccountModal, setShowVerifyBankAccountModal] = useState(false);
  const t = useTranslate('shared-admin-ui.VerifyBankAccountNotification');

  return (
    <>
      <Alert variant="warn">
        {t(type === 'new' ? 'helpVerifyNewBankAccount' : 'helpVerifyBankAccount', {
          action: children => <Button variant="text-inline" children={children} onClick={() => setShowVerifyBankAccountModal(true)}/>
        })}
      </Alert>

      <VerifyBankAccountFormModal
        show={showVerifyBankAccountModal}
        type={type}
        onHide={() => setShowVerifyBankAccountModal(false)}
        onAfterSubmit={onAfterVerifyBankAccount}
        useVerifyPrimaryPaymentSourceMutation={useVerifyPrimaryPaymentSourceMutation}
      />
    </>
  );
};
 
