import { useMemo, useState } from 'react';
import type { FC } from 'react';

import { useQueryWithPagination } from '@acadeum/hooks';
import { useTranslate } from '@acadeum/translate';
import { Page, Select, Table } from '@acadeum/ui';
import { useFetchAdminUsersQuery, useFetchAdminStudentUsersQuery } from '@acadeum/api';

import { useOnError } from '../../../providers/useOnError';

import { useColumns } from './helpers';

import styles from './UserAccountsPage.module.scss';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface UserAccountsPageProps { }

export const UserAccountsPage: FC<UserAccountsPageProps> = () => {
  const t = useTranslate('shared-admin-ui.UserAccountsPage');

  const [isStudents, setStudents] = useState(true);

  return (
    <Page
      title={t('title')}
      breadcrumbs={[
        ['Acadeum Tools', '/admin'],
        t('title')
      ]}
    >
      <Select
        className={styles.Select}
        label="Environment"
        value={isStudents ? 'studentUsers' : 'users'}
        onChange={(value) => setStudents(value === 'studentUsers')}
        options={[
          { value: 'studentUsers', label: t('studentApp') },
          { value: 'users', label: t('adminApp') }
        ]}
      />
      <UsersList
        key={isStudents ? 'studentUsers' : 'users'}
        isStudents={isStudents}
      />
    </Page>
  );
};

interface UsersListProps {
  isStudents: boolean;
}

const UsersList: FC<UsersListProps> = ({ isStudents }) => {
  const t = useTranslate('shared-admin-ui.UserAccountsPage');

  const defaultSorting = useMemo(() => [
    { by: 'latestLoginDate', asc: false }
  ], []);

  const queryFn = useMemo(() => {
    return isStudents ? useFetchAdminStudentUsersQuery : useFetchAdminUsersQuery;
  }, [isStudents]);

  const {
    data,
    isFetching,
    isLoading,
    refetch,
    _paginationOptions,
    error,
    _globalFilterOptions
    // @ts-expect-error It's expected error because `queryFn` maybe `UseFetchAdminStudentUsersQuery` or `UseFetchAdminUsersQuery` 
  } = useQueryWithPagination(queryFn, { sort: defaultSorting });

  const onError = useOnError();

  const columns = useColumns({
    refresh: refetch,
    isStudents
  });


  if (error) {
    return onError(error);
  }

  return (
    <Table
      data={data?.results ? data.results : []}
      loading={isLoading}
      isFetching={isFetching}
      paginationOptions={_paginationOptions}
      globalFilterOptions={_globalFilterOptions}
      columns={columns}
      translate={{
        searchPlaceholder: t('searchPlaceholder')
      }}
    />
  );
};
