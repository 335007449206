import formatEthnicity from 'common-lib/lib/formatEthnicity.js';
import studentEthnicities from 'common-lib/constants/studentEthnicities.json';

import { getTranslationLanguage } from '../translate';

const language = getTranslationLanguage();

export const ETHNICITY_OPTIONS = studentEthnicities.map((ethnicity) => ({
  value: ethnicity,
  label: formatEthnicity(ethnicity, { language, input: true, own: false })
}));

export const ETHNICITY_OWN_OPTIONS = studentEthnicities.map((ethnicity) => ({
  value: ethnicity,
  label: formatEthnicity(ethnicity, { language, input: true, own: true })
}));
