import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { useTranslate } from '@acadeum/translate';

import type { ButtonProps } from '../Button';
import { Button } from '../Button';

import styles from './ShowMoreButton.module.scss';

export interface ShowMoreButtonProps extends Pick<ButtonProps, 'className' | 'children'> {
  className?: string;
  children?: React.ReactNode;
  expanded?: boolean;
  onClick: (value: boolean) => void;
}

export function ShowMoreButton({ className, children, expanded, onClick }: ShowMoreButtonProps) {
  const t = useTranslate('ui.ShowMoreButton');
  return (
    <Button
      onClick={() => onClick(!expanded)}
      aria-expanded={expanded}
      disclosure={expanded ? 'up' : 'down'}
      variant="text"
      className={classNames(className, styles.ShowMoreButton)}
    >
      {children ? children : t(expanded ? 'showLess' : 'showMore')}
    </Button>
  );
}

ShowMoreButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  expanded: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};
