import type { MutableRefObject } from 'react';
import React, { useImperativeHandle, useRef } from 'react';

import type { ButtonProps } from '../Button';
import { Button } from '../Button';
import type { FileUploadInputProps } from '../FileUploadInput';
import { FileUploadInput } from '../FileUploadInput';

export type FileUploadButtonProps =
  Pick<FileUploadInputProps, 'multiple' | 'required' | 'accept' | 'onChange' | 'error' | 'disabled'> &
  Omit<ButtonProps, 'required' | 'onChange' | 'disabled'> &
  { children?: React.ReactNode; }

export interface FileUploadButtonRef {
  reset: () => void;
  focus: () => void;
}

export const FileUploadButton = React.forwardRef<FileUploadButtonRef, FileUploadButtonProps>(({
  disabled,
  multiple,
  children= `Browse a file${multiple ? 's' : ''}`,
  error,
  required,
  accept,
  onChange,
  ...rest
}, ref) => {
  const inputRef = useRef() as MutableRefObject<HTMLInputElement>;

  useImperativeHandle(ref, () => ({
    focus: () => inputRef.current.focus(),
    reset: () => inputRef.current.value = ''
  }));

  const onClick = () => {
    inputRef.current.click();
  };

  return (
    <>
      <FileUploadInput
        hidden
        ref={inputRef}
        onChange={onChange}
        multiple={multiple}
        disabled={disabled}
        error={error}
        required={required}
        accept={accept}
      />
      <Button
        {...rest}
        disabled={disabled}
        onClick={onClick}
      >
        {children}
      </Button>
    </>
  );
});
