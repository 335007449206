import classNames from 'classnames';
import React from 'react';

import { useScreenSize } from '@acadeum/hooks';
import { OnFocusOutOrTapOutside, SkipToMainContent } from '@acadeum/ui';

import { Header } from './AppHeader';
import type { SidebarProps } from './AppSidebar';
import { Sidebar } from './AppSidebar';
import styles from './AppTemplate.module.scss';
import type { AppTemplateProviderProps } from './context';
import { AppTemplateProvider } from './context';
import { Footer } from './Footer';
import type { InstitutionMenuProps } from './InstitutionMenu';
import { LoggedInAsBanner } from './LoggedInAsBanner';

import type { MenuItem } from './types';

export interface AppTemplateProps
  extends Pick<AppTemplateProviderProps, 'useRouteChangeStartListener'>,
    Pick<InstitutionMenuProps, 'useAdminChangeOwnInstitutionMutation'>,
    Pick<SidebarProps, 'menuItemsStyle' | 'renderSidebarFooterCustomItems'> {
  headerSlot?: React.ReactNode;
  footerSlot?: React.ReactNode;
  children?: React.ReactNode;
  fullScreen?: boolean;
  menu: MenuItem[];
  apiUrl: string;
  supportUrl: string;
}

export const AppTemplate: React.FC<AppTemplateProps> & {
  Footer: typeof Footer;
  Header: typeof Header;
} = ({
  headerSlot,
  footerSlot,
  children,
  useRouteChangeStartListener,
  fullScreen,
  menuItemsStyle,
  renderSidebarFooterCustomItems,
  useAdminChangeOwnInstitutionMutation,
  menu,
  apiUrl,
  supportUrl
}) => {
  const { isSmallScreen } = useScreenSize();
  const shouldRenderSidebar = isSmallScreen ? true : !fullScreen;
  return (
    <>
      <SkipToMainContent/>
      <AppTemplateProvider
        apiUrl={apiUrl}
        supportUrl={supportUrl}
        useRouteChangeStartListener={useRouteChangeStartListener}
        useAdminChangeOwnInstitutionMutation={useAdminChangeOwnInstitutionMutation}
      >
        {({ sidebarRef, mainRef, buttonRef, onTapOutside, minimized }) => (
          <>
            {headerSlot}
            {shouldRenderSidebar && (
              <OnFocusOutOrTapOutside
                toggleRef={buttonRef}
                containerRef={sidebarRef}
                onTapOutside={onTapOutside}
                onFocusOut={onTapOutside}
              >
                <Sidebar
                  ref={sidebarRef}
                  menu={menu}
                  location={location}
                  menuItemsStyle={menuItemsStyle}
                  renderSidebarFooterCustomItems={renderSidebarFooterCustomItems}
                />
              </OnFocusOutOrTapOutside>
            )}
            <div
              className={classNames(styles.content, {
                [styles['content--full-screen']]: fullScreen,
                [styles['content--minimized']]: minimized,
                [styles['content--menuItemsStyle-column']]: !minimized && menuItemsStyle === 'column'
              })}
            >
              <LoggedInAsBanner/>
              <main
                /* `<main id="main"/>` is used to create an "anchor" for a "Skip to Main Content" hyperlink. */
                id="main"
                ref={mainRef}
                className={classNames(styles.main, {
                  [styles.paddedContent]: !fullScreen
                })}
              >
                {children}
              </main>
              {footerSlot}
            </div>
          </>
        )}
      </AppTemplateProvider>
    </>
  );
};

AppTemplate.Footer = Footer;
AppTemplate.Header = Header;
