import PropTypes from 'prop-types';
import React from 'react';

import './Tabs.sass';

export default function Tabs({ children }) {
  return (
    <div className="Tabs">
      {children}
    </div>
  );
}

Tabs.propTypes = {
  children: PropTypes.node.isRequired
};
