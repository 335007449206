import { capitalize } from 'lodash-es';
import React from 'react';

import { CircleExclamationSolidIcon } from '@acadeum/icons';

import styles from './ErrorBlock.module.scss';

export interface ErrorBlockProps {
  message?: string;
  children?: React.ReactNode;
}

export const ErrorBlock: React.FC<ErrorBlockProps> = ({ message, children }) => {
  return (
    <span className={styles.ErrorBlock}>
      {children ? (
        children
      ) : (
        capitalize(message)
      )}
      <CircleExclamationSolidIcon/>
    </span>
  );
};
