import { useSelector } from 'react-redux';

import { userHasPermission } from '@acadeum/helpers';

import type { ReduxState } from '../helpers/app.types';

export function useCanViewCourses() {
  const user = useSelector((state: ReduxState) => state.auth.user);
  return Boolean(user) && userHasPermission(user, 'course:read');
}

export function useCanViewCoursePrices() {
  const user = useSelector((state: ReduxState) => state.auth.user);
  return Boolean(user) && userHasPermission(user, 'homeInstitutionCourseEnrollmentPricing:read', {
    orgId: user.institution.id
  });
}

export function useCanEnrollStudentsInCourses() {
  const user = useSelector((state: ReduxState) => state.auth.user);
  return Boolean(user) && userHasPermission(user, 'homeCourseEnrollment:create', {
    orgId: user.institution.id
  });
}
