import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { Select } from 'react-responsive-ui';

import '../../helpers/initializeCharts';
import actions from '../../actions';

import { getGraphOptions } from './helpers';

const options = {
  scales: {
    y: {
      beginAtZero: true
    }
  },
  legend: {
    display: false
  }
};

const { fetchYearlyEnrollments } = actions;

export default function YearlyEnrollments() {
  const [institutionId, setInstitutionId] = useState();

  const onSelectInstitution = (institutionId) => {
    setInstitutionId(institutionId);
    fetchYearlyEnrollments(institutionId);
  };

  const yearlyEnrollmentsReport = useSelector(state => state.reports.yearlyEnrollments);

  if (!yearlyEnrollmentsReport) {
    return null;
  }

  const yearlyEnrollments = Object.values(yearlyEnrollmentsReport[0]);
  const institutions = Object.values(yearlyEnrollmentsReport[1]);

  const years = yearlyEnrollments.map(_ => _.year);
  const yearData = yearlyEnrollments.map(_ => _.count);

  const data = {
    labels: years,
    datasets: [getGraphOptions('All', yearData)]
  };

  const dataSum = {
    labels: years,
    datasets: [getGraphOptions('All', yearData.reduce((a, x, i) => [...a, x + (a[i - 1] || 0)], []))]
  };

  const dataDerivative = {
    labels: years,
    datasets: [getGraphOptions('All', yearData.reduce((a, x, i) => [...a, x - (a[i - 1] || 0)], []))]
  };

  return (
    <div>
      <h1>Enrolling Institution</h1>
      <Select
        options={[{ label: 'All' }].concat(institutions.map((institution) => ({
          value: institution.id,
          label: institution.name
        })))}
        onChange={onSelectInstitution}
        value={institutionId}
      />
      <br />
      <h1>Yearly Enrollment Activity: Cumulative</h1>
      <Line data={dataSum} options={options} />
      <h1>Yearly Enrollment Activity: 1st Derivative</h1>
      <Line data={data} options={options} />
      <h1>Yearly Enrollment Activity: 2nd Derivative</h1>
      <Line data={dataDerivative} options={options}/>
    </div>
  );
}

YearlyEnrollments.meta = () => ({
  title: 'Yearly Enrollments'
});

YearlyEnrollments.load = async () => {
  await fetchYearlyEnrollments();
};

YearlyEnrollments.breadcrumbs = () =>[
  ['Admin Tools', '/admin'],
  'Yearly Enrollments'
];
