import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-responsive-ui';


import AddIcon from '../../../assets/images/icons/add.svg';
import CloseIcon from '../../../assets/images/icons/close.svg';
import DownloadIcon from '../../../assets/images/icons/download.svg';
import ArrowLeft from '../../../assets/images/icons/download.svg';
import FileIcon from '../../../assets/images/icons/file-text.svg';
import PencilIcon from '../../../assets/images/icons/pencil.svg';
import Link from '../Link';

import './Action.sass';

export default function Action({
  inline,
  height,
  icon: Icon,
  iconSize,
  link,
  onClick,
  // `onNavigate` is currently only used in `EnrollStudents.js`.
  onNavigate,
  className,
  children,
  ...rest
}) {
  if (typeof Icon === 'string') {
    Icon = getIconByName(Icon);
  }
  const content = (
    <React.Fragment>
      {Icon &&
        <Icon className={classNames(
          'action__icon',
          iconSize && `action__icon--${iconSize}`
        )}/>
      }
      <span className="action__label">
        {children}
      </span>
    </React.Fragment>
  );
  className = classNames(className, 'action', {
    'action--inline': inline,
    'action--link': link,
    'action--auto-height': height === 'auto',
    // `.rrui__input` adds the `height`
    // but it will be overridden if `height` is `"auto"`.
    'rrui__input': true
  });
  if (link) {
    return (
      <Link
        {...rest}
        to={link}
        onClick={onNavigate}
        className={className}>
        {content}
      </Link>
    );
  }
  return (
    <Button
      {...rest}
      onClick={onClick}
      className={className}>
      {content}
    </Button>
  );
}

Action.propTypes = {
  link: PropTypes.string,
  // `onNavigate` is passed in `EnrollStudents.js`.
  onNavigate: PropTypes.func,
  onClick: PropTypes.func,
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.elementType
  ]),
  iconSize: PropTypes.string,
  height: PropTypes.oneOf(['auto']),
  className: PropTypes.string,
  children:PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]).isRequired
};

function getIconByName(iconName) {
  switch (iconName) {
    case 'add':
      return AddIcon;
    case 'edit':
      return PencilIcon;
    case 'download':
      return DownloadIcon;
    case 'remove':
      return CloseIcon;
    case 'file':
      return FileIcon;
    case 'arrow-left':
      return ArrowLeft;
  }
}
