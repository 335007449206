import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { useTranslate } from '@acadeum/translate';
import type { Student } from '@acadeum/types';
import { Tabs } from '@acadeum/ui';

import Enrollments from './Enrollments';
import Info from './Info';

interface StudentDetailsProps {
  type?: 'EM' | 'TM';
  className?: string;
  student: Student;
  showEnrollments?: boolean;
}

export default function StudentDetails({ student, type, className, showEnrollments }: StudentDetailsProps) {
  const t = useTranslate('StudentDetails');

  const tabs = useMemo(() => {
    return [
      {
        key: 'info',
        title: t('info'),
        body: (
          <Info
            type={type}
            student={student}
            className={className}
          />
        )
      },
      ...(showEnrollments ? [{
        key: 'enrollments',
        title: t('enrollments'),
        body: (
          <Enrollments
            idStudent={student.id}
            type={type}
          />
        )
      }] : [])
    ];
  }, [showEnrollments, student, type]);

  return (
    <Tabs
      title="student"
      tabs={tabs}
    />
  );
}

StudentDetails.propTypes = {
  student: PropTypes.object.isRequired,
  className: PropTypes.string,
  type: PropTypes.oneOf(['EM', 'TM'])
};
