import React, { memo } from 'react';

import { CircularProgress } from '../CircularProgress';
import { HStack } from '../Stack';

interface ContentLoadingProps {
  minHeight?: string;
}

export const ContentLoading = memo<ContentLoadingProps>(({
  minHeight = '20rem'
}) => {
  return (
    <HStack justify="center" style={{
      flex: 1,
      minHeight
    }}>
      <CircularProgress/>
    </HStack>
  );
});
