import PropTypes from 'prop-types';
import React from 'react';

import Switch from '../Switch';

let InstantSearchSwitch = ({
  Icon,
  label,
  ...rest
}, ref) => {
  let children = label;
  if (Icon) {
    children = (
      <span className="InstantSearch-ToggleIconAndLabel">
        <Icon className="InstantSearch-ToggleIcon"/>
        {label}
      </span>
    );
  }
  return (
    <Switch
      ref={ref}
      {...rest}
      className="InstantSearch-ToggleSwitch">
      {children}
    </Switch>
  );
};

InstantSearchSwitch = React.forwardRef(InstantSearchSwitch);

InstantSearchSwitch.propTypes = {
  label: PropTypes.string.isRequired,
  Icon: PropTypes.elementType
};

export default InstantSearchSwitch;
