import React from 'react';

import { useTranslate } from '@acadeum/translate';
import { ContentSection, FormField } from '@acadeum/ui';

export interface DescriptionSectionValues {
  description: string;
}

export function DescriptionSection() {
  const t = useTranslate('shared-admin-ui.CourseForm');
  return (
    <ContentSection title={t('description.title')}>
      <FormField
        required
        noMargin
        name="description"
        type="markdown"
        label={t('description.description.label')}
        placeholder={t('description.description.placeholder')}
      />
    </ContentSection>
  );
}
