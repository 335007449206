import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { useUpdateEffect } from '@acadeum/hooks';

import actions from '../../actions';
import DatePicker from '../../components/DatePicker';
import Row from '../../components/Row';
import Section from '../../components/Section';

import { formatCurrency } from '../../helpers/format';


import './Dashboard.sass';

const { fetchDashboardData } = actions;

export default function Dashboard() {
  const data = useSelector(state => state.dashboard.dashboard);

  const [refreshing, setRefreshing] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  async function refresh() {
    try {
      setRefreshing(true);
      await fetchDashboardData({
        startDate,
        endDate
      });
    } finally {
      setRefreshing(false);
    }
  }

  useUpdateEffect(() => {
    refresh();
  }, [startDate]);

  useUpdateEffect(() => {
    refresh();
  }, [endDate]);

  const renderTable = () => {
    if (!data || refreshing) {
      return 'Loading...';
    }

    return (
      <div className="accounting-table-wrapper">
        <table className="dashboard-table">
          <tbody>
            <tr>
              <td>Total Enrollments as an EM</td>
              <td>{Number(data[0]['0'].count)}</td>
            </tr>
            <tr>
              <td>Total Enrollments as an TM</td>
              <td>{Number(data[1]['0'].count)}</td>
            </tr>
            <tr>
              <td>Total EM Revenue</td>
              <td>{formatCurrency(Number(data[2]['0'].count))}</td>
            </tr>
            <tr>
              <td>Total TM Revenue</td>
              <td>{formatCurrency(Number(data[3]['0'].count))}</td>
            </tr>
            <tr>
              <td>Total Revenue</td>
              <td>{formatCurrency(Number(data[2]['0'].count) + Number(data[3]['0'].count))}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <Section title="Dashboard">
      <Row>
        <DatePicker
          disabled={refreshing}
          className="col-2"
          label="Start Date"
          value={startDate}
          onChange={setStartDate}
        />
        <DatePicker
          disabled={refreshing}
          className="col-2"
          label="End Date"
          value={endDate}
          onChange={setEndDate}
        />
      </Row>

      <br />
      <br />

      {renderTable()}
    </Section>
  );
}

Dashboard.meta = () => ({
  title: 'Dashboard'
});

Dashboard.load = async () => {
  await fetchDashboardData({});
};

Dashboard.breadcrumbs = () => ['Dashboard'];
