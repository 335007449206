import { $isCodeNode, getCodeLanguages, getDefaultCodeLanguage } from '@lexical/code';
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import { $isListNode, ListNode } from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $isHeadingNode } from '@lexical/rich-text';
import { $getSelectionStyleValueForProperty, $patchStyleText, $selectAll } from '@lexical/selection';
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils';
import classNames from 'classnames';
import {
  $getNodeByKey,
  $getSelection,
  $isRangeSelection,
  $isTextNode,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  REDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  UNDO_COMMAND
} from 'lexical';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { FC } from 'react';

import {
  RedoIcon,
  TextAlignCenterIcon,
  TextAlignJustifyIcon,
  TextAlignLeftIcon,
  TextAlignRightIcon,
  TextBoldIcon,
  TextClearFormatIcon,
  TextItalicIcon,
  TextLinkIcon,
  TextUnderlineIcon,
  UndoIcon
} from '@acadeum/icons';

import { BaseButton } from '../../../BaseButton';
import { Icon } from '../../../Icon';
import { ReactPortal } from '../../../ReactPortal';

import { getSelectedNode } from '../../helpers';


import styles from './ToolbarPlugin.module.scss';
import { Divider } from './ui/Divider';
import { FloatingLinkEditor } from './ui/FloatingLinkEditor';
import { FormattingOptionsButton, getIsSupportedBlockTypes } from './ui/FormattingOptionsButton';
import { TextColorButton } from './ui/TextColorButton';

const LowPriority = 1;

interface ToolbarPluginProps {
  markdown?: boolean;
  disabled?: boolean;
}

export const ToolbarPlugin: FC<ToolbarPluginProps> = ({
  markdown,
  disabled
}) => {
  const [editor] = useLexicalComposerContext();
  const toolbarRef = useRef(null);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [blockType, setBlockType] = useState('paragraph');
  const [selectedElementKey, setSelectedElementKey] = useState<string | null>(null);
  const [codeLanguage, setCodeLanguage] = useState('');
  // const [isRTL, setIsRTL] = useState(false);
  const [isLink, setIsLink] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  // const [isStrikethrough, setIsStrikethrough] = useState(false);
  // const [isCode, setIsCode] = useState(false);
  const [isEditable, setIsEditable] = useState(() => editor.isEditable());
  const [fontColor, setFontColor] = useState<string>('#000');

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        setSelectedElementKey(elementKey);
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          setBlockType(type);
          if ($isCodeNode(element)) {
            setCodeLanguage(element.getLanguage() || getDefaultCodeLanguage());
          }
        }
      }
      // Update text format
      setFontColor(
        $getSelectionStyleValueForProperty(selection, 'color', '#000')
      );
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));
      // setIsStrikethrough(selection.hasFormat('strikethrough'));
      // setIsCode(selection.hasFormat('code'));
      // setIsRTL($isParentElementRTL(selection));

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }
    }
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerEditableListener((editable) => {
        setIsEditable(editable);
      }),
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateToolbar();
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return false;
        },
        LowPriority
      )
    );
  }, [editor, updateToolbar]);

  const codeLanguges = useMemo(() => getCodeLanguages(), []);
  const onCodeLanguageSelect = useCallback((e) => {
    editor.update(() => {
      if (selectedElementKey !== null) {
        const node = $getNodeByKey(selectedElementKey);
        if ($isCodeNode(node)) {
          node.setLanguage(e.target.value);
        }
      }
    });
  }, [editor, selectedElementKey]);

  const insertLink = useCallback(() => {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, 'https://');
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [editor, isLink]);

  const clearFormatting = useCallback(() => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        $selectAll(selection);
        selection.getNodes().forEach((node) => {
          if ($isTextNode(node)) {
            node.setFormat(0);
            node.setStyle('');
          }
        });
      }
      editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left');
    });
  }, [editor]);

  const applyStyleText = useCallback((styles: Record<string, string>) => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        $patchStyleText(selection, styles);
      }
    });
  }, [editor]);

  const onFontColorSelect = useCallback((color) => {
    applyStyleText({ color: color.hex });
  }, [applyStyleText]);

  return (
    <div className={styles.toolbar} ref={toolbarRef}>
      <div className={styles.inner}>
        {getIsSupportedBlockTypes(blockType) && (
          <>
            <FormattingOptionsButton
              editor={editor}
              className={styles.item}
              blockType={blockType}
              disabled={disabled}
            />
            <Divider/>
          </>
        )}

        {blockType === 'code' ? (
          <>
            <select
              disabled={disabled}
              className="toolbar-item code-language"
              onChange={onCodeLanguageSelect}
              value={codeLanguage}
            >
              <option hidden value=""/>
              {codeLanguges.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <i className="chevron-down inside"/>
          </>
        ) : (
          <>
            <BaseButton
              disabled={disabled}
              title="Bold (Ctrl+B)"
              aria-label="Format text as bold. Shortcut: Ctrl+B"
              className={classNames(styles.item, {
                [styles.active]: isBold
              })}
              onClick={() => {
                editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
              }}
            >
              <Icon icon={TextBoldIcon}/>
            </BaseButton>
            <BaseButton
              disabled={disabled}
              title="Italic (Ctrl+I)"
              aria-label="Format text as italics. Shortcut: Ctrl+I"
              className={classNames(styles.item, {
                [styles.active]: isItalic
              })}
              onClick={() => {
                editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
              }}
            >
              <Icon icon={TextItalicIcon}/>
            </BaseButton>
            {!markdown && (
              <>
                <BaseButton
                  disabled={disabled}
                  title="Underline (Ctrl+U)"
                  aria-label="Format text to underlined. Shortcut: Ctrl+U"
                  className={classNames(styles.item, {
                    [styles.active]: isUnderline
                  })}
                  onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
                  }}
                >
                  <Icon icon={TextUnderlineIcon}/>
                </BaseButton>
                <TextColorButton
                  disabled={!isEditable || disabled}
                  onChange={onFontColorSelect}
                  className={classNames(styles.item)}
                  value={fontColor}
                />
              </>
            )}
            <Divider/>

            {!markdown && (
              <>
                <BaseButton
                  disabled={disabled}
                  onClick={() => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left')}
                  className={styles.item}
                  aria-label="Left Align"
                  title="Left Align"
                >
                  <Icon icon={TextAlignLeftIcon}/>
                </BaseButton>
                <BaseButton
                  disabled={disabled}
                  onClick={() => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right')}
                  className={styles.item}
                  aria-label="Right Align"
                  title="Right Align"
                >
                  <Icon icon={TextAlignRightIcon}/>
                </BaseButton>
                <BaseButton
                  disabled={disabled}
                  onClick={() => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'justify')}
                  className={styles.item}
                  aria-label="Justify Align"
                  title="Justify Align"
                >
                  <Icon icon={TextAlignJustifyIcon}/>
                </BaseButton>
                <BaseButton
                  disabled={disabled}
                  onClick={() => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center')}
                  className={styles.item}
                  aria-label="Center Align"
                  title="Center Align"
                >
                  <Icon icon={TextAlignCenterIcon}/>
                </BaseButton>

                <Divider/>
              </>
            )}
            <BaseButton
              disabled={disabled}
              className={classNames(styles.item, {
                [styles.active]: isLink
              })}
              onClick={insertLink}
              aria-label="Insert Link"
              title="Insert Link"
            >
              <Icon icon={TextLinkIcon}/>
            </BaseButton>
            {isLink && (
              <ReactPortal>
                <FloatingLinkEditor editor={editor}/>
              </ReactPortal>
            )}

            <Divider/>

            <BaseButton
              disabled={disabled}
              onClick={clearFormatting}
              className={styles.item}
              title="Clear text formatting"
              aria-label="Clear all text formatting"
            >
              <Icon icon={TextClearFormatIcon}/>
            </BaseButton>
          </>
        )}
      </div>
      <div className={styles.inner}>
        <BaseButton
          disabled={!canUndo || disabled}
          onClick={() => editor.dispatchCommand(UNDO_COMMAND, undefined)}
          className={classNames(styles.item)}
          aria-label="Undo"
        >
          <Icon icon={UndoIcon}/>
        </BaseButton>
        <BaseButton
          disabled={!canRedo || disabled}
          onClick={() => editor.dispatchCommand(REDO_COMMAND, undefined)}
          className={styles.item}
          aria-label="Redo"
        >
          <Icon icon={RedoIcon}/>
        </BaseButton>
      </div>
    </div>
  );
};
