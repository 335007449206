import React from 'react';

import { userHasPermission } from '@acadeum/helpers';
import { CourseMappingsTable } from '@acadeum/shared-admin-ui';
import { useTranslate } from '@acadeum/translate';

import { Page } from '@acadeum/ui';

import { useLazyFetchAllCourseSubstitutesQuery } from '../../api/courseSubstitutes';
import { useLazyFetchInstitutionQuery } from '../../api/institutions';

import type { AppPage } from '../../helpers/app.types';

const CourseMappings: AppPage = () => {
  const t = useTranslate();

  return (
    <Page
      title="Course Mappings"
      breadcrumbs={[[t('settings', { global: true }), '/'], 'Course Mappings']}
    >
      <CourseMappingsTable
        useLazyFetchAllCourseSubstitutesQuery={useLazyFetchAllCourseSubstitutesQuery}
        useLazyFetchInstitutionQuery={useLazyFetchInstitutionQuery}
      />
    </Page>
  );
};

CourseMappings.meta = () => ({
  title: 'Course Mappings'
});

CourseMappings.when = ({ user }) => userHasPermission(user, 'courseMapping:read', {
  orgId: user?.institution.id,
  ownerId: null
});

export default CourseMappings;
