import type { ErrorData } from './httpError';
import { isObject } from './isObject';


function isQueryError(error: unknown): error is ErrorData {
  if (isObject(error)) {
    return 'message' in error || 'code' in error;
  }
  return false;
}

export function parseQueryError(queryError: unknown) {
  try {
    if (typeof queryError === 'string') {
      const error = JSON.parse(queryError);
      if (isQueryError(error)) {
        return error;
      }
    }
  } catch (error) {
    // Ignore.
  }
}

