import type { FC, ReactNode } from 'react';

import { DownloadIcon } from '@acadeum/icons';
import { useTranslate } from '@acadeum/translate';

import type { ButtonProps } from '../Button';
import { Button } from '../Button';

import { toast } from '../toast';

export interface DownloadButtonProps extends ButtonProps {
  successMessage?: string;
  errorMessage?: string;
  children?: ReactNode;
}

export const DownloadButton: FC<DownloadButtonProps> = ({
  onClick: propsOnClick,
  successMessage = 'Success! Check your downloads folder for your information.',
  errorMessage = 'Couldn\'t download file',
  children,
  ...rest
}) => {
  const t = useTranslate('ui.DownloadButton');

  const onClick = async (event) => {
    try {
      if (typeof propsOnClick === 'function') {
        await propsOnClick(event);
      }
      toast.success(successMessage);
    } catch (error) {
      console.error(error);
      toast.error(errorMessage);
    }
  };

  return (
    <Button
      {...rest}
      download
      icon={DownloadIcon}
      onClick={onClick}
      variant="tertiary"
    >
      {children || t('download')}
    </Button>
  );
};
