import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { toast } from '@acadeum/ui';

import actions from '../../actions';
import { useCreateStudentUserMutation } from '../../api/studentUsers';

import Section from '../../components/Section';
import StudentUserForm from '../../components/StudentUserForm';
import type { ReduxState } from '../../helpers/app.types';

import useLocation from '../../hooks/useLocation';


const {
  saveAdvisorRecommendationCreationState,
  goto
} = actions;

export default function AddStudentUser() {
  const location = useLocation();
  const {
    query: {
      from
    }
  } = location;

  const [createStudentUser] = useCreateStudentUserMutation();

  const advisorRecommendationCreationState = useSelector((state: ReduxState) => state.advisorRecommendations.advisorRecommendationCreationState);

  const cancelLocation = from === 'advisorRecommendation' && advisorRecommendationCreationState
    ? `/courses/${advisorRecommendationCreationState.courseId}/recommend`
    : '/students';
  const onSubmit = async (values) => {
    const {
      id,
      knownStudent,
      ...userInfo
    } = await createStudentUser(values).unwrap();
    if (from === 'advisorRecommendation' && advisorRecommendationCreationState) {
      // const { courseId, studentIds = [] } = advisorRecommendationCreationState;
      // if (!studentIds.includes(knownStudent.id)) {
      //   saveAdvisorRecommendationCreationState({
      //     studentIds: studentIds.concat(knownStudent.id)
      //   });
      // }
      const { courseId, students = [] } = advisorRecommendationCreationState;
      if (!students.includes(_ => _ === knownStudent.id)) {
        saveAdvisorRecommendationCreationState({
          students: students.concat({
            id: knownStudent.id,
            studentUserId: id,
            ...userInfo
          })
        });
      }
      toast.success('Student successfully added and marked as selected.');
      goto(`/courses/${courseId}/recommend`);
    } else {
      toast('Student successfully added!');
      goto(`/student-users/${id}`);
    }
  };

  return (
    <Section title="Add a Student">
      <StudentUserForm
        cancelLocation={cancelLocation}
        submitText="Add Student"
        onSubmit={onSubmit}
      />
    </Section>
  );
}

AddStudentUser.propTypes = {
  location: PropTypes.shape({
    query: PropTypes.shape({
      from: PropTypes.oneOf(['advisorRecommendation'])
    }).isRequired
  }),
  advisorRecommendationCreationState: PropTypes.shape({
    courseId: PropTypes.number.isRequired,
    courseSubstituteId: PropTypes.number,
    sectionIds: PropTypes.arrayOf(PropTypes.number),
    students: PropTypes.arrayOf(PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      hiStudentId: PropTypes.string,
      id: PropTypes.number.isRequired,
      studentUserId: PropTypes.number
    }))
    // studentIds: PropTypes.arrayOf(PropTypes.number)
  })
};

AddStudentUser.meta = () => ({
  title: 'Add a Student'
});

AddStudentUser.breadcrumbs = (state, {
  location: {
    query: {
      from
    }
  }
}) => {
  const { advisorRecommendationCreationState } = state.advisorRecommendations;
  if (from === 'advisorRecommendation' && advisorRecommendationCreationState) {
    const { courseId } = advisorRecommendationCreationState;
    return [
      ['Course', `/courses/${courseId}`],
      ['Recommend Course', `/courses/${courseId}/recommend`],
      ['Add Students', `/courses/${courseId}/recommend`],
      'New Student'
    ];
  } 
  return [
    ['Students', '/students'],
    'Add a Student'
  ];
  
};
