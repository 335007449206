import classNames from 'classnames';
import React from 'react';

import { ErrorMessage } from '../ErrorMessage';
import { ErrorTooltip } from '../ErrorTooltip';
import type { LabelProps } from '../Label';
import { Label } from '../Label';
import { Text } from '../Text';

import styles from './FormFieldWrapper.module.scss';

interface FormFieldWrapperProps {
  id?: string;
  error?: string;
  label?: string;
  required?: boolean;
  className?: string;
  width?: 'wide';
  password?: boolean;
  noMargin?: boolean;
  style?: React.CSSProperties;
  helpText?: string;
  hidden?: boolean;
  labelAction?: LabelProps['action'];
  labelTooltip?: LabelProps['tooltip'];
  children: React.ReactNode;
  showErrorTooltip?: boolean;
}

export const FormFieldWrapper: React.FC<FormFieldWrapperProps> = ({
  id,
  error,
  label,
  labelAction,
  children,
  required,
  className,
  width,
  password,
  noMargin,
  style,
  helpText,
  labelTooltip,
  hidden,
  showErrorTooltip
}) => {
  return (
    <div
      hidden={hidden}
      style={style}
      className={classNames(styles.root, className, {
        [styles['root--wide']]: width === 'wide',
        [styles['root--noMargin']]: noMargin,
        [styles['root--password']]: password
      })}
    >
      {(label || labelAction) && (
        <Label
          htmlFor={id}
          action={labelAction}
          tooltip={labelTooltip}
          children={label}
          required={required}
          mb="sm"
        />
      )}
      <div className={styles.body}>
        <div className={styles.children}>
          {children}
        </div>
        {error && showErrorTooltip && (
          <ErrorTooltip>
            {error}
          </ErrorTooltip>
        )}
      </div>
      {error && !showErrorTooltip && (
        <ErrorMessage id={id + '-hint'}>
          {error}
        </ErrorMessage>
      )}
      {helpText && (
        <Text
          mt="sm"
          color="grey"
          variant="bodyMd"
        >
          {helpText}
        </Text>
      )}
    </div>
  );
};
