import PropTypes from 'prop-types';
import React, { Component } from 'react';

import InstantSearch from '../InstantSearch';

export default class ConsortiumSearch extends Component {
  static propTypes = {
    className: PropTypes.string
  };

  render() {
    const {
      className,
      ...rest
    } = this.props;

    const indexes = [
      { value: 'consortia', label: 'Name (A-Z)' },
      { value: 'consortia_by_name_desc', label: 'Name (Z-A)' }
    ];

    return (
      <InstantSearch
        searchFieldLabel="Consortium Name"
        indexes={indexes}
        className={className}
        {...rest} />
    );
  }
}
