import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import formatName from 'common-lib/lib/formatName';

import { isUserSessionEphemeral, getAuthSelector } from '@acadeum/auth';
import { useTranslate } from '@acadeum/translate';

import styles from './LoggedInAsBanner.module.scss';

export function LoggedInAsBanner() {
  const user = useSelector(getAuthSelector('user'));

  const t = useTranslate('shared-admin-ui.LoggedInAsBanner');

  const isEphemeralUserSession = useMemo(() => isUserSessionEphemeral(), [user]);

  if (!user) {
    return null;
  }

  if (!isEphemeralUserSession) {
    return null;
  }

  return (
    <section className={styles.LoggedInAsBanner}>
      {t('loggedInAs', {
        userName: formatName(user),
        tag: (children) => (
          <span className={styles.LoggedInAsBanner__name}>
            {children}
          </span>
        )
      })}
    </section>
  );
}
