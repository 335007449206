import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-responsive-ui';

import actions from '../../actions';

const {
  notifyError
} = actions;

export default class TableAction extends React.Component {
  onClick = () => {
    const { action } = this.props;
    const result = action.onClick(this.getSelectedRows());
    if (result && typeof result.then === 'function') {
      return result.then(
        () => {},
        (error) => notifyError(error && error.message || 'Error')
      );
    }
  };

  getSelectedRows() {
    const { data, selectedRowIds } = this.props;
    return data.filter(_ => selectedRowIds.includes(_.id));
  }

  getTitle() {
    const {
      action: {
        title
      }
    } = this.props;
    if (typeof title === 'function') {
      return title(this.isActive() ? this.getSelectedRows() : [], { isActive: this.isActive() });
    }
    return title;
  }

  isActive() {
    const {
      selectedRowIds,
      action: {
        isActive
      }
    } = this.props;
    if (selectedRowIds.length === 0) {
      return false;
    }
    if (isActive && !isActive(this.getSelectedRows())) {
      return false;
    }
    return true;
  }

  render() {
    const {
      className
    } = this.props;
    return (
      <Button
        onClick={this.onClick}
        disabled={!this.isActive()}
        className={classNames(className, 'form__button', 'form__button--primary')}>
        {this.getTitle()}
      </Button>
    );
  }
}

export const ActionPropType = PropTypes.shape({
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]).isRequired,
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.func
});

TableAction.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  selectedRowIds: PropTypes.arrayOf(PropTypes.any).isRequired,
  action: ActionPropType.isRequired,
  className: PropTypes.string
};
