import PropTypes from 'prop-types';
import type { FC } from 'react';

import { useTranslate } from '@acadeum/translate';
import type { SearchInstitutionType } from '@acadeum/types';

import { useGetInstitutionInputParams } from '../../utils/institutionAutocomplete';

import { FormField } from '../FormField';
import { Select } from '../Select';

export interface InstitutionAutocompleteProps {
  [key: string]: any;
  className?: string;
  noMargin?: boolean;
  type: SearchInstitutionType;
  required?: boolean;
  superAdmin?: boolean;
  multiple?: boolean;
  onChange?: (valueOrEvent: any, ...rest: any[]) => void;
  label?: string;
  name?: string;
  placeholder?: string;
  notFoundLabel?: string;
  noInputLabel?: string;
  isFormField?: boolean;
}

export const InstitutionAutocomplete: FC<InstitutionAutocompleteProps> = ({
  className,
  noMargin,
  type,
  required = true,
  superAdmin,
  onChange,
  label,
  multiple,
  name = 'institutionId',
  placeholder,
  notFoundLabel,
  noInputLabel,
  isFormField = true,
  ...rest
}) => {
  const t = useTranslate('ui.InstitutionAutocomplete');

  const getInstitutionInputParams = useGetInstitutionInputParams();
  if (!getInstitutionInputParams) {
    throw new Error('getInstitutionInputParams is not defined');
  }

  const institutionInputParams = getInstitutionInputParams({ type, superAdmin });
  const Component = isFormField ? FormField : Select;

  return (
    <Component
      {...rest}
      {...institutionInputParams}
      className={className}
      noMargin={noMargin}
      multiple={multiple}
      required={required}
      name={name}
      type="select"
      placeholder={placeholder || t('placeholder')}
      label={label || t('label')}
      notFoundLabel={notFoundLabel || t('notFound')}
      noInputLabel={noInputLabel || t('noInput')}
      onChange={onChange}
    />
  );
};

InstitutionAutocomplete.propTypes = {
  className: PropTypes.string,
  noMargin: PropTypes.bool,
  multiple: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  notFoundLabel: PropTypes.string,
  noInputLabel: PropTypes.string,
  type: PropTypes.oneOf<SearchInstitutionType>([
    'institution',
    'homeInstitution',
    'teachingInstitution',
    'stripeSenderInstitution',
    'stripeRecipientInstitution'
  ]).isRequired,
  superAdmin: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string
} as NonNullable<unknown>;
