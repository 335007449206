import PropTypes from 'prop-types';
import React from 'react';
import { Checkbox } from 'react-responsive-ui';

import Field from '../Field';

export default function RoleFormField({
  // eslint-disable-next-line no-unused-vars
  additional,
  role,
  defaultValue,
  getFormValues,
  setFieldValue,
  ...rest
}) {
  return (
    <Field
      {...rest}
      multiline
      type="checkbox"
      name={`role-${role.id}`}
      value={defaultValue}
      component={Checkbox}
      onChange={(value) => {
        onRoleFormCheckboxChange(
          `role-${role.id}`,
          value,
          getFormValues(),
          setFieldValue
        );
      }}
    />
  );
}

RoleFormField.propTypes = {
  defaultValue: PropTypes.bool,
  getFormValues: PropTypes.func,
  setFieldValue: PropTypes.func,
  additional: PropTypes.bool,
  role: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired
};

export function getRoleIdsFromRoleFormValues(values, { remove = true } = {} ) {
  const roleIds = [];
  for (const key of Object.keys(values)) {
    if (key.indexOf('role-') === 0) {
      if (values[key]) {
        roleIds.push(Number(key.slice('role-'.length)));
      }
      if (remove) {
        delete values[key];
      }
    }
  }
  return roleIds;
}

function onRoleFormCheckboxChange(
  roleFieldName,
  value,
  fieldValues,
  setFieldValue
) {
  if (value) {
    // Deselect all other roles.
    for (const fieldName of Object.keys(fieldValues)) {
      if (fieldName.indexOf('role-') === 0) {
        if (fieldName !== roleFieldName) {
          setFieldValue(fieldName, false);
        }
      }
    }
  }
}
