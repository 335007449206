import PropTypes from 'prop-types';
import type { FC, ReactNode } from 'react';
import React from 'react';

import formatTime from 'common-lib/lib/formatTime.js';

import type { Language } from '@acadeum/types';

export interface TimeProps extends Omit<React.HTMLAttributes<HTMLTimeElement>, 'children'> {
  date: number | Date,
  utc: boolean,
  children?: (time: string) => ReactNode
  locale?: Language | string,
}

const propTypes = {
  date: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.number
  ]).isRequired,
  utc: PropTypes.bool.isRequired,
  children: PropTypes.func,
  locale: PropTypes.oneOf(['en'])
};

export const Time: FC<TimeProps> = ({
  locale = 'en',
  date,
  utc,
  children,
  ...rest
}) => {
  if (!date) {
    return null;
  }

  // Convert a number (timestamp) to a `Date`.
  date = typeof date === 'number' ? new Date(date) : date;

  const timeText = formatTime(date, { locale, utc });
  return (
    <time
      dateTime={date.toISOString()}
      title={date.toString()}
      {...rest}>
      {children ? children(timeText) : timeText}
    </time>
  );
};

Time.displayName = 'Time';
Time.propTypes = propTypes;
