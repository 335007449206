import { useQueryParameters } from '@acadeum/hooks';

import actions from '../actions';

import useLocation from './useLocation';
import useRouterReady from './useRouterReady';


const {
  redirect
} = actions;

/**
 * Reads URL query parameters on mount.
 */
export default function useQueryParameters_(callback) {
  const location = useLocation();

  useQueryParameters(callback, {
    useRouterReady,
    query: location.query,
    replaceQuery: (query) => redirect({
      pathname: location.pathname,
      query,
      hash: location.hash
    })
  });
}
