import PropTypes from 'prop-types';
import React from 'react';

import formatName from 'common-lib/lib/formatName';

import { AppLoading, Breadcrumbs, toast } from '@acadeum/ui';

import actions from '../../actions';
import { useUpdateStudentUserMutation, useFetchStudentUserQuery } from '../../api/studentUsers';
import FormHeading from '../../components/FormHeading';
import StudentUserForm from '../../components/StudentUserForm';

import useLocation from '../../hooks/useLocation';


const { goto } = actions;

export default function EditStudentUser({ id }) {
  const {
    query: {
      from
    }
  } = useLocation();
  const { data: studentUser, isLoading } = useFetchStudentUserQuery({ id });

  const [updateStudentUser] = useUpdateStudentUserMutation();

  const profileLocation = `/student-users/${studentUser?.id}`;
  const cancelLocation = from === 'profile' ? profileLocation : '/students';

  if (!studentUser || isLoading) {
    return <AppLoading circle />;
  }

  const onSubmit = async (values) => {
    delete values.email;
    await updateStudentUser({ id: studentUser.id, body: values });
    toast.success('Student profile has been updated!');
    goto(profileLocation);
  };

  return (
    <div>
      <Breadcrumbs
        children={[['Students', '/students'],
          formatName(studentUser)]}
      />
      <FormHeading>
        {formatName(studentUser)}
      </FormHeading>
      <StudentUserForm
        values={studentUser}
        cancelLocation={cancelLocation}
        submitText="Save"
        onSubmit={onSubmit}
      />
    </div>
  );
}

EditStudentUser.propTypes = {
  id: PropTypes.number.isRequired,
  location: PropTypes.shape({
    query: PropTypes.shape({
      from: PropTypes.oneOf(['profile'])
    }).isRequired
  })
};

EditStudentUser.meta = () => ({
  title: 'Edit Student'
});

EditStudentUser.load = async ({ params }) => {
  return {
    props: params
  };
};
