import React from 'react';

import { StudentUpload } from '@acadeum/shared-admin-ui';

import actions from '../../../../actions';
import { useBulkCreateOrUpdateStudentsJob } from '../../../../api/student';
import CreateStudent from '../../../CreateStudent';

const {
  goto
} = actions;

type AddStudentMethod = 'manual' | 'upload';

interface CreateStudentDetailsProps {
  method: AddStudentMethod
}

const CreateStudentDetails = ({ method }: CreateStudentDetailsProps) => {
  if (method === 'manual') {
    return (
      <CreateStudent/>
    );
  }

  return (
    <StudentUpload
      onAfterUpload={() => goto('/students')}
      useBulkCreateOrUpdateStudentsJob={useBulkCreateOrUpdateStudentsJob}
    />
  );
};

CreateStudentDetails.breadcrumbs = () => [
  ['Students', '/students'],
  'Create Students'
];

export default CreateStudentDetails;
