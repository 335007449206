import React, { useMemo, useState } from 'react';

import { getEnrollmentTypeLabel } from '@acadeum/helpers';
import { useTranslate } from '@acadeum/translate';
import { PageLoader } from '@acadeum/ui';

import actions from '../../actions';
import {
  useApproveTeachingCourseEnrollmentMutation,
  useDenyTeachingCourseEnrollmentMutation
} from '../../api/courseEnrollment';
import { useFetchTeachingCourseEnrollmentRequestQuery } from '../../api/courseEnrollmentRequest';
import { useLazyFetchTeachingStudentQuery } from '../../api/teachingStudents';
import Button from '../../components/Button';
import Email from '../../components/Email';
import FormButtons from '../../components/FormButtons';
import InstitutionLink from '../../components/InstitutionLink';
import Output, { OutputPlaceholder } from '../../components/Output';
import { formatName } from '../../helpers/format';
import getInstitutionLink from '../../helpers/getInstitutionLink';

import Outputs from '../../components/Outputs';
import Row from '../../components/Row';
import Table from '../../components/Table';
import Section from '../../components/Section';
import StudentDetails from '../../components/StudentDetails';
import FormModal from '../../components/FormModal';
import ReasonFormField from '../../components/ReasonFormField';


import useOnError from '../../hooks/useOnError';


import './EnrollmentRequest.sass';

const {
  notify,
  goto
} = actions;

export default function EnrollmentRequest({
  courseEnrollmentRequestId
}) {
  const onError = useOnError();

  const {
    data,
    error,
    isLoading
  } = useFetchTeachingCourseEnrollmentRequestQuery({
    id: courseEnrollmentRequestId
  });

  if (error) {
    return onError(error);
  }

  if (isLoading || !data) {
    return <PageLoader/>;
  }

  return (
    <EnrollmentRequestDetails
      courseEnrollmentRequest={data}
    />
  );
}

EnrollmentRequest.meta = () => ({
  title: 'Enrollment Requests'
});

EnrollmentRequest.load = async ({ params: { id } }) => {
  return {
    props: {
      courseEnrollmentRequestId: id
    }
  };
};

EnrollmentRequest.breadcrumbs = () => [
  ['Enrollment Requests', '/enrollment-requests'],
  'Approve Enrollments'
];

const DENY_REASONS = [
  'COURSE_AT_CAPACITY',
  'COURSE_CANCELLED',
  'NOT_ALIGN_WITH_REQUIREMENT'
];

function EnrollmentRequestDetails({
  courseEnrollmentRequest
}) {
  const t = useTranslate('EnrollmentRequest');

  const [approveTeachingCourseEnrollmentMutation] = useApproveTeachingCourseEnrollmentMutation();
  const [denyTeachingCourseEnrollmentMutation] = useDenyTeachingCourseEnrollmentMutation();
  const [lazyFetchTeachingStudentQuery] = useLazyFetchTeachingStudentQuery();

  const {
    requestedEnrollments,
    processedEnrollments
  } = useMemo(() => {
    return courseEnrollmentRequest.enrollments.reduce((acc, item) => {
      if (item.status === 'REQUESTED') {
        acc.requestedEnrollments.push(item);
      } else {
        acc.processedEnrollments.push(item);
      }
      return acc;
    }, {
      requestedEnrollments: [],
      processedEnrollments: []
    });
  }, [courseEnrollmentRequest]);

  const [wait, setWait] = useState(false);
  const [showDenyPopup, setShowDenyPopup] = useState(false);
  const [studentEnrollmentsDetails, setStudentEnrollmentsDetails] = useState({});
  const [checkedRows, setCheckedRows] = useState(() => requestedEnrollments.map(_ => _.id));

  const lock = () => setWait(true);
  const unlock = () => setWait(false);

  async function onSubmit(actionType, parameters) {
    try {
      lock();
      const ids = checkedRows;
      switch (actionType) {
        case 'approve':
          await approveTeachingCourseEnrollmentMutation({
            ids,
            enrollmentRequestId: courseEnrollmentRequest.id,
            ...parameters
          }).unwrap();
          break;
        case 'deny':
          await denyTeachingCourseEnrollmentMutation({
            ids,
            enrollmentRequestId: courseEnrollmentRequest.id,
            ...parameters
          }).unwrap();
          break;
        default:
          throw new Error('Unknown action type');
      }
    } catch (error) {
      unlock();
      throw error;
    }
  }

  async function onAfterSubmit(actionType) {
    const count = checkedRows.length;
    resetSelection();
    switch (actionType) {
      case 'approve':
        notify(`Success! ${  count === 1 ? 'The enrollment has' : `The ${count} enrollments have`  } been added to your Accepted Enrollments.`);
        break;
      case 'deny':
        notify(`${count === 1 ? 'The enrollment has' : `The ${count} enrollments have`  } been denied.`);
        break;
    }
    unlock();
    const hasMore = requestedEnrollments.length > count;
    if (!hasMore) {
      goto('/enrollment-requests');
    }
  }

  function onDenySelectedEnrollments() {
    if (checkedRows.length === 0) {
      return notify('Select some students first');
    }
    setShowDenyPopup(true);
  }

  async function onSubmitDenySelectedEnrollments({ reason, reasonNotes }) {
    await onSubmit('deny', { reason, reasonNotes });
  }

  async function onAfterSubmitDenySelectedEnrollments() {
    await onAfterSubmit('deny');
  }

  async function onApproveSelectedEnrollments() {
    if (checkedRows.length === 0) {
      return notify('Select some students first');
    }
    await onSubmit('approve');
    await onAfterSubmit('approve');
  }

  function resetSelection() {
    setCheckedRows([]);
    setStudentEnrollmentsDetails({});
  }

  async function onToggleDetails(studentEnrollment) {
    // Hide details.
    if (studentEnrollmentsDetails[studentEnrollment.id]) {
      return setStudentEnrollmentsDetails({
        ...studentEnrollmentsDetails,
        [studentEnrollment.id]: undefined
      });
    }
    // Show details.
    lock();
    try {
      const studentInfo = await lazyFetchTeachingStudentQuery({ id: studentEnrollment.student.id }).unwrap();
      setStudentEnrollmentsDetails({
        ...studentEnrollmentsDetails,
        [studentEnrollment.id]: studentInfo
      });
    } finally {
      unlock();
    }
  }

  function getStudentEnrollmentsTableColumns(wait) {
    return [{
      title: 'STUDENT NAME',
      content: studentEnrollment => (
        <>
          {formatName(studentEnrollment.student)}
          {studentEnrollmentsDetails[studentEnrollment.id] && (
            <StudentDetails
              student={studentEnrollmentsDetails[studentEnrollment.id]}
              type="TM"
            />
          )}
        </>
      )
    }, {
      title: 'STUDENT ID',
      content: studentEnrollment => studentEnrollment.student.hiStudentId
    }, {
      title: 'EMAIL',
      content: studentEnrollment => <Email address={studentEnrollment.student.email}/>
    }, {
      content: studentEnrollment => (
        <div className="data-table__row-actions">
          <Button
            border
            secondary
            disabled={wait}
            onClick={() => onToggleDetails(studentEnrollment)}>
            Details
          </Button>
        </div>
      )
    }];
  }

  const section = courseEnrollmentRequest.section;
  const session = section.session;
  const course = session.course;

  return (
    <Section
      title={`Enrollment Request — ${getEnrollmentTypeLabel(courseEnrollmentRequest.enrollments[0])}`}
      className="new-teaching-enrollment-request-page">

      <Outputs>
        {/* For some reason a combination of `.outputs`, `.row` and `.output`
            prevents the institution logo from being hugely sized in IE11. */}
        <Row singleLineEqualHeight>
          <OutputPlaceholder col={1} className="align-children-center">
            {/* Adding ".logo-grid" CSS class to the link
                seems to prevent the institution logo
                from being hugely sized in IE11. */}
            <InstitutionLink
              institution={courseEnrollmentRequest.institution}
              target="_blank"
              className="logo-grid">
              <img
                src={courseEnrollmentRequest.institution.logoUrl}
                alt={courseEnrollmentRequest.institution.name}
                className="logo-grid"/>
            </InstitutionLink>
          </OutputPlaceholder>

          <Output
            label="Member Institution"
            col={4}
            value={courseEnrollmentRequest.institution.name}
            linkTo={getInstitutionLink(courseEnrollmentRequest.institution)}
            linkOpensInNewTab/>

          <Output
            label="Date Requested"
            col={2}
            type="date"
            value={courseEnrollmentRequest.createdAt}
            utc={false}/>

          <Output
            label="Number of Enrollments"
            col={5}
            type="integer"
            value={courseEnrollmentRequest.seatsRequested}/>
        </Row>

        <Row>
          <Output
            label="Course"
            col={7}
            value={`${course.code  } ${  course.title}`}
            linkTo={`/courses/${course.id}`}
            linkOpensInNewTab/>

          <Output
            label="LEVEL"
            col={5}
            value={course.level}/>
        </Row>

        <Row>
          <Output
            label="Term"
            col={2}
            value={session.term}/>

          <Output
            label="Session"
            col={3}
            value={session.name}/>

          <Output
            label="Section"
            col={2}
            value={section.number}
            linkTo={`/sections/${section.id}`}
            linkOpensInNewTab/>

          <Output
            label="Dates"
            col={5}
            type="date"
            values={[session.startDate, session.endDate]}
            utc
          />
        </Row>

        <Table
          disabled={wait}
          checkedRows={checkedRows}
          setCheckedRows={setCheckedRows}
          selectableRows={requestedEnrollments.length > 0}
          showTotalResultsCount={false}
          data={requestedEnrollments.length > 0 ? requestedEnrollments : processedEnrollments}
          columns={getStudentEnrollmentsTableColumns(wait)}
          className="new-teaching-enrollment-request-page__students-table"
        />

        {requestedEnrollments.length === 0 && (
          <div>
            All student enrollments have been reviewed for this enrollment request.
          </div>
        )}

        {requestedEnrollments.length > 0 && (
          <FormButtons>
            <Button
              border
              secondary
              disabled={wait}
              onClick={onDenySelectedEnrollments}>
              Deny
            </Button>

            <Button
              disabled={wait}
              onClick={onApproveSelectedEnrollments}>
              Accept
            </Button>
          </FormButtons>
        )}
      </Outputs>

      <FormModal
        title={t('deny.title', { count: checkedRows.length })}
        description={t('deny.description', { count: checkedRows.length })}
        show={showDenyPopup}
        onHide={() => setShowDenyPopup(false)}
        submitText={t('deny.submit')}
        onSubmit={onSubmitDenySelectedEnrollments}
        onAfterSubmit={onAfterSubmitDenySelectedEnrollments}>
        <ReasonFormField
          required
          labelsPath="EnrollmentRequest.denyReason"
          options={DENY_REASONS}
        />
      </FormModal>
    </Section>
  );
}
