import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { AdminUserActions } from '@acadeum/shared-admin-ui';
import { Outputs, Output } from '@acadeum/ui';

import actions from '../../actions';

import Section from '../../components/Section';
import Subsection from '../../components/Subsection';
import UserAuthHistoryTable from '../../components/UserAuthHistoryTable';
import { formatName } from '../../helpers/format';
import type { ReduxState } from '../../helpers/app.types';
import getInstitutionLink from '../../helpers/getInstitutionLink';

import './AdminStudentUser.sass';

const {
  adminFetchStudentUser
} = actions;

export default function AdminStudentUser_() {
  const studentUser = useSelector((state: ReduxState) => state.studentUsersAdmin.studentUser);
  return <AdminUser user={studentUser} student />;
}

AdminStudentUser_.meta = ({ useSelector }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const studentUser = useSelector(state => state.studentUsersAdmin.studentUser);
  return {
    title: formatName(studentUser)
  };
};

AdminStudentUser_.breadcrumbs = (state) => [
  ['Admin Tools', '/admin'],
  ['User Accounts', '/admin/users'],
  formatName(state.studentUsersAdmin.studentUser)
];

AdminStudentUser_.load = async ({ params: { id } }) => {
  await adminFetchStudentUser(id);
};

export function AdminUser({ student, user }) {
  return (
    <Section
      title="User Log"
      className="AdminStudentUser-header">

      <Section
        title={(
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              {formatName(user)}
              {user.emailVerified
                ? <VerifiedIcon
                  title="Verified Account"
                  className="AdminStudentUser-statusIcon"
                />
                : <UnverifiedIcon
                  title="Unverified Account"
                  className="AdminStudentUser-statusIcon"
                />
              }
              {!user.isActive &&
                <InactiveIcon
                  title="Inactive Account"
                  className="AdminStudentUser-statusIcon AdminStudentUser-statusIcon--regular"
                />
              }
              {user.singleSignOn &&
                <CloudKeyIcon
                  title="Single Sign On"
                  className="AdminStudentUser-statusIcon"
                />
              }
              {!user.singleSignOn && !user.hasPassword &&
                <QuestionMarkKeyIcon
                  title="Password Not Set"
                  className="AdminStudentUser-statusIcon"
                />
              }
            </div>
            <div className="AdminStudentUser-actionsContainer">
              <AdminUserActions
                user={user}
                refreshFetchDataStudentsOrUsers={() => adminFetchStudentUser(user.id)}
              />
            </div>
          </div>
        )}>

        <Subsection title="Details">
          <Outputs>
            <Output
              label="EMAIL"
              value={user.email}
              type="email"
            />

            <Output
              label="PHONE"
              value={user.phone}
              type="phone"
            />

            <Output
              label="HOME INSTITUTION"
              url={getInstitutionLink(user.institution)}
              value={user.institution.name}
            />

            {student &&
              <Output
                label="STUDENT ID"
                value={user.hiStudentId}
              />
            }

            <Output
              label="LAST LOGIN"
              value={user.latestLoginDate}
              type="date"
              month="numeric"
              utc
            />

            <Output
              label="SIGNED UP"
              value={user.createdAt}
              type="date"
              month="numeric"
              utc
            />
          </Outputs>
        </Subsection>

        <Subsection title="History" marginTop="large">
          <UserAuthHistoryTable history={user.history} />
        </Subsection>
      </Section>
    </Section>
  );
}

AdminUser.propTypes = {
  student: PropTypes.bool,
  user: PropTypes.object
};

// Copy-pasted from an `*.svg` file because of adding a `<title/>`.
//
// function VerifiedIcon({ title, ...rest }) {
//   return (
//     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240" {...rest}>
//       <title>{title}</title>
//       <path fill="none" stroke="currentColor" strokeWidth="10" d="M133.8,8l-5.8,3.9c-12.6,10.7-14.5,10.7-26.2,3.9C89.2,7,85.3,8,79.5,22.5c-5.8,11.6-7.7,12.6-22.3,11.6
//         c-13.6-1.9-16.5,0-16.5,14.5c0,15.5-1,16.5-14.5,21.3c-8.7,2.9-11.6,4.8-11.6,10.7v3.9l3.9,6.8l3.9,7.7v2.9c0,4.8-1,5.8-7.7,12.6
//         C4,126.1,4,130,16.6,138.7c10.7,7.7,11.6,10.7,6.8,23.2c-4.8,12.6-2.9,17.4,8.7,19.4l6.8,1c9.7,1.9,11.6,4.8,12.6,17.4
//         c1.9,13.6,5.8,17.4,16.5,14.5c10.7-3.9,12.6-3.9,16.5-2.9c2.9,1,5.8,4.8,8.7,9.7c8.7,14.5,11.6,14.5,26.2,1.9
//         c6.8-5.8,11.6-5.8,20.3,0c13.6,7.7,17.4,6.8,23.2-4.8c4.8-13.6,7.7-15.5,22.3-13.6c8.7,1,11.6,0,14.5-3.9l1-1.9v-9.7
//         c0-14.5,1-16.5,14.5-20.3c13.6-4.8,14.5-8.7,7.7-20.3c-6.8-12.6-5.8-15.5,3.9-25.2c9.7-10.7,9.7-14.5-1.9-22.3
//         c-10.7-8.7-11.6-10.7-6.8-26.2c4.8-11.6,2.9-14.5-11.6-17.4c-12.6-2.9-15.5-4.8-16.5-17.4c-1.9-14.5-4.8-17.4-17.4-13.6
//         c-14.5,3.9-16.5,2.9-25.2-11.6C142.5,8,138.6,5.1,133.8,8"/>
//       <path fill="currentColor" d="M169.8,74.8c-1.8,0.9-1.7,0-28.7,28l-34.9,34.1c-2.6,2.3-0.9,2.9-17.2-12.3c-13.1-13.1-14.6-12.9-18.2-9.4
//         c-3.4,3.3-2.4,5,0.5,8.4c2.3,2.7,28.9,26.7,31.4,28.8c3.7,2.8,2.1,3.8,27.7-23l34.4-34.9c14-14,14.1-14.6,10.4-18.4
//         C172.9,73.8,171.6,74.2,169.8,74.8"/>
//     </svg>
//   );
// }

// VerifiedIcon.propTypes = {
//   title: PropTypes.string.isRequired
// };

// Copy-pasted from an `*.svg` file because of adding a `<title/>`.
function VerifiedIcon({ title, ...rest }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1500 1500" {...rest}>
      <title>{title}</title>
      <path stroke="currentColor" strokeWidth="70" fill="none" d="M842.5,280.8c-57.5,6.5-118.7,8.7-168.8,42.1c-55.3,36.9-78.6,93.1-88.5,156.6c-14.6,94.6,2.2,192.9,35.6,281.8
        c16,42.5,36.1,84.3,61.4,122.1c16.3,24.3,38.8,43.8,20.7,74.5c-20.6,35.2-75.2,42.9-110.5,55.8c-98.9,36.1-245.4,60.8-287.2,171.2
        c-20.3,53.5-27.9,116.4-14.1,172c5.2,21.3,10.5,44.8,29.3,58c22.8,16,54.5,21,81.1,25.9c65.6,12.2,131.9,13.5,198.3,16.9
        c126.8,6.4,254.7,2.4,381.8,2.4c125.2,0,255.5,3.8,378.5-22.6c24.7-5.2,56.7-12.2,74.2-31.8c11.1-12.4,14.2-32.5,18.6-48.1
        c17-58.7,6.4-139.6-22.9-193.3c-25.4-46.7-70-71.8-117.6-91.7c-52.1-21.8-105.9-38.8-158.9-58.4c-35.2-12.9-94.5-20.7-112.9-58
        c-15.8-32.1,6.7-50.5,23.5-75.9c28.2-42.5,48.5-88.5,66-136.3c29-79.5,40.4-166.7,31.1-250.7c-5.8-52.2-21.5-105-59.5-143.3
        c-22-22.1-48.9-36.1-77.5-47.4c-24.3-9.6-51.9-15.6-77.8-17.5C912.3,282.7,877,277,842.5,280.8"/>
      <path fill="currentColor" d="M205.3,413.5L53.3,261.4L3.5,311.1l201.8,201.8l432-433.4L589,31.2L205.3,413.5z" />
    </svg>
  );
}

VerifiedIcon.propTypes = {
  title: PropTypes.string.isRequired
};

// Copy-pasted from an `*.svg` file because of adding a `<title/>`.
function UnverifiedIcon({ title, ...rest }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1500 1500" {...rest}>
      <title>{title}</title>
      <path stroke="currentColor" strokeWidth="70" fill="none" d="M842.5,280.8c-57.5,6.5-118.7,8.7-168.8,42.1c-55.3,36.9-78.6,93.1-88.5,156.6c-14.6,94.6,2.2,192.9,35.6,281.8
        c16,42.5,36.1,84.3,61.4,122.1c16.3,24.3,38.8,43.8,20.7,74.5c-20.6,35.2-75.2,42.9-110.5,55.8c-98.9,36.1-245.4,60.8-287.2,171.2
        c-20.3,53.5-27.9,116.4-14.1,172c5.2,21.3,10.5,44.8,29.3,58c22.8,16,54.5,21,81.1,25.9c65.6,12.2,131.9,13.5,198.3,16.9
        c126.8,6.4,254.7,2.4,381.8,2.4c125.2,0,255.5,3.8,378.5-22.6c24.7-5.2,56.7-12.2,74.2-31.8c11.1-12.4,14.2-32.5,18.6-48.1
        c17-58.7,6.4-139.6-22.9-193.3c-25.4-46.7-70-71.8-117.6-91.7c-52.1-21.8-105.9-38.8-158.9-58.4c-35.2-12.9-94.5-20.7-112.9-58
        c-15.8-32.1,6.7-50.5,23.5-75.9c28.2-42.5,48.5-88.5,66-136.3c29-79.5,40.4-166.7,31.1-250.7c-5.8-52.2-21.5-105-59.5-143.3
        c-22-22.1-48.9-36.1-77.5-47.4c-24.3-9.6-51.9-15.6-77.8-17.5C912.3,282.7,877,277,842.5,280.8"/>
      <g fill="currentColor">
        <path d="M199.5,1.1C334.4-13.2,442,116.2,395.8,243.9c-21.7,60.3-77.4,91.7-110,144c-15.4,25.1-26,48-26,77.7c0,14,6,46.8-5.7,57.1
          c-6,5.1-16.9,3.4-24,3.4c-20.9,0-42,1.4-62.8,0c-18-1.1-17.1-15.7-17.1-29.7c0-29.4-4.6-62.8,5.1-91.1c20.6-60.3,82-100,120-148.8
          c24.6-31.7,31.7-78.8,8.9-113.1c-41.1-62-125.4-40.3-179.7-11.7c-16,8.6-42.3,37.1-61.4,31.4c-8.6-2.3-11.4-10.9-15.7-18.3
          C18.7,129.6-13,91.3,5.9,75.3C60.2,29.9,130.7,8.5,199.5,1.1"/>
        <path d="M157.3,604.6c21.4-6.3,52.8-0.6,75.1-0.6c7.7,0,18.9-2.3,24.6,4.9c4.9,6,2.9,16.6,2.9,23.7V692c0,8.3,0.3,18-9.1,20.9
          c-20.9,6.6-52.3,0.9-74.3,0.9c-6.9,0-19.7,2.3-24.3-4.3c-10.6-15.4-2-62.6-2-81.4C150.4,620.3,147.8,607.2,157.3,604.6z"/>
      </g>
    </svg>
  );
}

UnverifiedIcon.propTypes = {
  title: PropTypes.string.isRequired
};

// Copy-pasted from an `*.svg` file because of adding a `<title/>`.
function InactiveIcon({ title, ...rest }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" {...rest}>
      <title>{title}</title>
      <line stroke="currentColor" strokeWidth="5" x1="2" y1="2" x2="98" y2="98" />
      <line stroke="currentColor" strokeWidth="5" x1="2" y1="98" x2="98" y2="2" />
    </svg>
  );
}

InactiveIcon.propTypes = {
  title: PropTypes.string.isRequired
};

// Copy-pasted from an `*.svg` file because of adding a `<title/>`.
function CloudKeyIcon({ title, ...rest }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...rest}>
      <title>{title}</title>
      <g fill="currentColor">
        <path d="M419.5,141.7c0-1.4,0.2-2.7,0.2-4.1c0-73.4-60.3-133.7-133.3-133.7c-52.6,0-97.9,31.8-119.1,76.5
          c-9.2-4.7-19.5-7.3-30.6-7.3c-33.8,0-61.7,27-67.2,59.3C29.4,146.3,0.4,182.8,0.4,228c0,56.8,45.8,104.5,102.2,104.5h23.7l18.3-18.3
          h-41c-46.7,0-84.7-39.8-84.7-86.8c0-36.4,23.1-66.6,57.3-78.5l9.6-3.4l1.7-10c4.1-24.6,25.2-44.8,50.1-44.8c7.9,0,15.6,1.8,22.7,5.5
          l15.4,7.8l7.4-15.6c18.9-39.8,59.5-66.4,103.2-66.4c63.1,0,115.1,50.3,115.1,113.8c0,15.2-0.2,23.2-0.2,23.2l17.3,0.1
          c41.9,0.6,74.9,34.8,74.9,76.9s-34,77.8-75.9,78.1l-3.7,0.5H402l-9.1,18.3H418c51.4,0,93.6-44.4,93.6-96.2
          C511.6,184.3,471.2,141.9,419.5,141.7z"/>
        <path d="M278.4,168.2c-58.8,0-106.2,47.3-106.2,106.2c0,13.5,3,26.4,7.5,38.4L50.7,441.8c-3.5,4-6,7.5-6,13v31.9
          c0,11.5,10,21.4,21.4,21.4H98c5.5,0,9.5-2.5,13-6l15.4-15.4h24.9c12,0,21.4-9.5,21.4-21.4v-21.4h21.4c12,0,21.4-9.5,21.4-21.4
          v-24.9l25.4-25.4c12,4.5,24.9,7.5,38.4,7.5c58.8,0,106.2-47.3,106.2-106.2S336.8,168.2,278.4,168.2z M278.4,359.6
          c-15.9,0-30.4-4.5-42.9-12l-3.5,3.5l-12,12l-20.4,20.4c-4,4-6,9.5-6,15v24.9h-21.4c-12,0-21.4,9.5-21.4,21.4v21.4h-24.9
          c-5.5,0-11,2-15,6l-15,15H66.1v-30.4l123.6-124.1l0,0l15.4-15.4c-7.5-12.5-12-27.4-12-42.9c0-46.8,37.9-85.2,85.2-85.2
          s85.2,37.9,85.2,85.2S325.3,359.6,278.4,359.6z"/>
        <path d="M340.2,255.4c-12-16.4-26.4-30.9-42.9-42.9c-2.5-2-6-2.5-9.5-1.5c-15,5-24.9,15.4-29.9,29.9c-0.5,1-0.5,2.5-0.5,3.5
          c0,2,0.5,4,2,6c12,16.4,26.4,30.9,42.9,42.9c2.5,2,6,2.5,9.5,1.5c15-5,24.9-15.4,29.9-29.9c0.5-1,0.5-2.5,0.5-3.5
          C342.2,259.4,341.2,257.4,340.2,255.4z M308.3,285.3c-15.9-11.5-29.4-24.9-40.4-40.4c4-12,12-19.4,23.4-23.4
          c15.9,11.5,29.4,24.9,40.4,40.4C327.3,273.4,319.3,280.8,308.3,285.3z"/>
      </g>
    </svg>
  );
}

CloudKeyIcon.propTypes = {
  title: PropTypes.string.isRequired
};

// Copy-pasted from an `*.svg` file because of adding a `<title/>`.
function QuestionMarkKeyIcon({ title, ...rest }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...rest}>
      <title>{title}</title>
      <g fill="currentColor">
        <path d="M345,158.3c-58.8,0-106.2,47.3-106.2,106.2c0,13.5,3,26.4,7.5,38.4l-129,129c-3.5,4-6,7.5-6,13v31.9
          c0,11.5,10,21.4,21.4,21.4h31.9c5.5,0,9.5-2.5,13-6l15.4-15.4h24.9c12,0,21.4-9.5,21.4-21.4V434h21.4c12,0,21.4-9.5,21.4-21.4
          v-24.9l25.4-25.4c12,4.5,24.9,7.5,38.4,7.5c58.8,0,106.2-47.3,106.2-106.2S403.4,158.3,345,158.3z M345,349.7
          c-15.9,0-30.4-4.5-42.9-12l-3.5,3.5l-12,12l-20.4,20.4c-4,4-6,9.5-6,15v24.9h-21.4c-12,0-21.4,9.5-21.4,21.4v21.4h-24.9
          c-5.5,0-11,2-15,6l-15,15h-29.8v-30.4l123.6-124.1l0,0l15.4-15.4c-7.5-12.5-12-27.4-12-42.9c0-46.8,37.9-85.2,85.2-85.2
          s85.2,37.9,85.2,85.2S391.9,349.7,345,349.7z"/>
        <path d="M406.8,245.4c-12-16.4-26.4-30.9-42.9-42.9c-2.5-2-6-2.5-9.5-1.5c-15,5-24.9,15.4-29.9,29.9c-0.5,1-0.5,2.5-0.5,3.5
          c0,2,0.5,4,2,6c12,16.4,26.4,30.9,42.9,42.9c2.5,2,6,2.5,9.5,1.5c15-5,24.9-15.4,29.9-29.9c0.5-1,0.5-2.5,0.5-3.5
          C408.8,249.4,407.8,247.4,406.8,245.4z M374.9,275.3c-15.9-11.5-29.4-24.9-40.4-40.4c4-12,12-19.4,23.4-23.4
          c15.9,11.5,29.4,24.9,40.4,40.4C393.9,263.5,385.9,270.8,374.9,275.3z"/>
        <path d="M121.8,35.1c47.2-5,84.9,40.3,68.7,85c-7.6,21.1-27.1,32.1-38.5,50.4c-5.4,8.8-9.1,16.8-9.1,27.2c0,4.9,2.1,16.4-2,20
          c-2.1,1.8-5.9,1.2-8.4,1.2c-7.3,0-14.7,0.5-22,0c-6.3-0.4-6-5.5-6-10.4c0-10.3-1.6-22,1.8-31.9c7.2-21.1,28.7-35,42-52.1
          c8.6-11.1,11.1-27.6,3.1-39.6c-14.4-21.7-43.9-14.1-62.9-4.1c-5.6,3-14.8,13-21.5,11C64,91,63,88,61.5,85.4
          c-3-5.3-14.1-18.7-7.5-24.3C73,45.2,97.7,37.7,121.8,35.1"/>
        <path d="M107,246.4c7.5-2.2,18.5-0.2,26.3-0.2c2.7,0,6.6-0.8,8.6,1.7c1.7,2.1,1,5.8,1,8.3v20.8c0,2.9,0.1,6.3-3.2,7.3
          c-7.3,2.3-18.3,0.3-26,0.3c-2.4,0-6.9,0.8-8.5-1.5c-3.7-5.4-0.7-21.9-0.7-28.5C104.6,251.9,103.7,247.3,107,246.4z"/>
      </g>
    </svg>
  );
}

QuestionMarkKeyIcon.propTypes = {
  title: PropTypes.string.isRequired
};
