import { setUser as sentrySetUser } from '@sentry/react';

import actions from '../actions';

import { formatUserName } from './format.js';
import initializeGoogleAnalytics from './googleAnalytics';
import { trackHubspotUser, untrackHubspotUser } from './hubspotUserTracking';
import { activateIntercomWidget, deactivateIntercomWidget } from './intercom';
import { prefillZendeskChatWidget, unprefillZendeskChatWidget } from './zendeskChatWidget';


const {
  refreshCourseEnrollmentPricingIfRequired,
  setCourseEnrollmentPricing,
  setHomeInstitutionTeachingInstitutionRelationships,
  setUserDataLoaded
} = actions;

function setupSentryUser(user) {
  if (user) {
    sentrySetUser({
      id: user.id,
      email: user.email,
      institutionId: user.institution.id,
      institutionName: user.institution.name
    });
  } else {
    sentrySetUser(null);
  }
}

export default function onUserAuthenticatedOrNotAuthenticated(user) {
  sendVitallyAnalytics(user);

  // Auto-populate Zendesk Chat
  if (user) {
    prefillZendeskChatWidget(user);
  } else {
    unprefillZendeskChatWidget();
  }

  // When run in "production" mode.
  if (process.env.NODE_ENV === 'production') {
    setupSentryUser(user);

    // Activate intercom widget.
    if (user) {
      activateIntercomWidget(user);
    } else {
      deactivateIntercomWidget();
    }

    if (user) {
      trackHubspotUser(user);
    } else {
      untrackHubspotUser();
    }
  }

  // Initialize Google Analytics with this user ID.
  initializeGoogleAnalytics(user && user.id);

  if (user) {
    try {
      // Set the initial value for the "course enrollment pricing" in Redux state
      // for this Home Institution.
      // Even though the function name says "refresh", it doesn't actually refresh anything.
      // It just sets an "initial" value and caches it for the defined time interval.
      // A simple `setCourseEnrollmentPricing()` function call wouldn't replace it
      // because it wouldn't start a cache interval timer.
      refreshCourseEnrollmentPricingIfRequired({
        forceValueUpdate: true,
        valueAlreadyFetched: true,
        value: user.institution.courseEnrollmentPricing
      });

      setHomeInstitutionTeachingInstitutionRelationships({
        homeInstitutionTeachingInstitutionRelationshipIds: user.institution.homeInstitutionTeachingInstitutionRelationshipIds,
        homeInstitutionTeachingInstitutionRelationshipIdMax: user.institution.homeInstitutionTeachingInstitutionRelationshipIdMax,
        homeConsortiumTeachingInstitutionRelationships: user.institution.homeConsortiumTeachingInstitutionRelationships
      });

      setUserDataLoaded(true);
    } catch (error) {
      // `userDataLoaded === false` means there was an error while loading user data.
      setUserDataLoaded(false);
      throw error;
    }
  } else {
    // Clear course enrollment pricing.
    // The rationale is that course enrollment pricing belongs to some Home Institution
    // and for another Home Institution it would be different, so clearing it
    // prevents any theoretically hypothetical bugs that could possibly happen
    // when some other user logs in using the same web browser tab,
    // or when the same user re-logs-in having changed their Home Institution.
    setCourseEnrollmentPricing({
      courseEnrollmentPricing: undefined,
      courseEnrollmentPricingConsortia: undefined,
      homeInstitutionTeachingInstitutionRelationshipIds: undefined,
      homeConsortiumTeachingInstitutionRelationships: undefined
    });

    // Clear Home Institution / Teaching Institution relationship IDs.
    // The rationale is that this type of mapping belongs to some Home Institution
    // and for another Home Institution it would be different, so clearing it
    // prevents any theoretically hypothetical bugs that could possibly happen
    // when some other user logs in using the same web browser tab,
    // or when the same user re-logs-in having changed their Home Institution.
    setHomeInstitutionTeachingInstitutionRelationships({
      homeInstitutionTeachingInstitutionRelationshipIds: undefined,
      homeInstitutionTeachingInstitutionRelationshipIdMax: undefined,
      homeConsortiumTeachingInstitutionRelationships: undefined
    });
  }
}

function sendVitallyAnalytics(user) {
  if (process.env.NODE_ENV !== 'production' || !user || !Vitally) {
    return;
  }

  Vitally.organization({
    organizationId: user.institution.id,
    traits: {
      name: user.institution.name
    }
  });

  Vitally.account({
    accountId: user.institution.id,
    organizationId: user.institution.id,
    traits: {
      name: user.institution.name
    }
  });

  Vitally.user({
    userId: user.email,
    accountId: user.institution.id,
    organizationId: user.institution.id,
    traits: {
      name: formatUserName(user),
      email: user.email
    }
  });

  Vitally.track({
    userId: user.email,
    accountId: user.institution.id,
    organizationId: user.institution.id,
    event: 'user-login',
    properties: {
      name: formatUserName(user),
      email: user.email
    }
  });
}
