// Copy-pasted from `react-instantsearch`.
// https://github.com/algolia/react-instantsearch/blob/81a6f1856ae28b4ab00ba660c548037818749220/packages/react-instantsearch-dom/src/components/RefinementList.js
// Version 5.5
//
// Changes:
//
// * Added `tabIndex={-1}` on checkboxes when `aria-hidden` is passed.
// * Added `aria-hidden` property passed to `<List/>`.
// * Added `aria-describedby` property passed to `<List/>`.
// * Added `aria-label` property passed to `<List/>`.

import { pick } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { translatable } from 'react-instantsearch-core';

import HighlightImport from 'react-instantsearch-dom/dist/cjs/widgets/Highlight';

import { createClassNames } from '../core/utils';

import List from './List';

// For some weird reason, because of `type: "module"`, those imports are wrapped in `{ default }`.
const Highlight = HighlightImport.default ? HighlightImport.default : HighlightImport;

const cx = createClassNames('RefinementList');

class RefinementList extends Component {
  static propTypes = {
    translate: PropTypes.func.isRequired,
    refine: PropTypes.func.isRequired,
    searchForItems: PropTypes.func.isRequired,
    searchable: PropTypes.bool,
    createURL: PropTypes.func.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.arrayOf(PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number
        ])).isRequired,
        count: PropTypes.number.isRequired,
        isRefined: PropTypes.bool.isRequired
      })
    ),
    isFromSearch: PropTypes.bool.isRequired,
    canRefine: PropTypes.bool.isRequired,
    showMore: PropTypes.bool,
    limit: PropTypes.number,
    showMoreLimit: PropTypes.number,
    transformItems: PropTypes.func,
    className: PropTypes.string
  };

  static defaultProps = {
    className: ''
  };

  state = {
    query: ''
  };

  selectItem = (item, resetQuery) => {
    resetQuery();
    this.props.refine(item.value);
  };

  renderItem = (item, resetQuery) => {
    const label = this.props.isFromSearch ? (
      <Highlight attribute="label" hit={item}/>
    ) : (
      item.label
    );

    return (
      <label className={cx('label')}>
        <input
          className={cx('checkbox')}
          type="checkbox"
          checked={item.isRefined}
          onChange={() => this.selectItem(item, resetQuery)}
          tabIndex={this.props['aria-hidden'] ? -1 : undefined}
        />
        <span className={cx('labelText')}>{label}</span>{' '}
        <span className={cx('count')}>{item.count.toLocaleString()}</span>
      </label>
    );
  };

  render() {
    return (
      <List
        renderItem={this.renderItem}
        selectItem={this.selectItem}
        cx={cx}
        {...pick(this.props, [
          'aria-describedby',
          'aria-label',
          'translate',
          'items',
          'showMore',
          'limit',
          'showMoreLimit',
          'isFromSearch',
          'searchForItems',
          'searchable',
          'canRefine',
          'className',
          'aria-hidden'
        ])}
        query={this.state.query}
      />
    );
  }
}

export default translatable({
  showMore: extended => (extended ? 'Show less' : 'Show more'),
  noResults: 'No results',
  submit: null,
  reset: null,
  resetTitle: 'Clear the search query.',
  submitTitle: 'Submit your search query.',
  placeholder: 'Search here…'
})(RefinementList);
