import React from 'react';

import { OnFocusOut } from '../OnFocusOut';
import { OnTapOutside } from '../OnTapOutside';

export interface OnFocusOutOrTapOutsideProps {
  children: JSX.Element;
  containerRef: React.MutableRefObject<HTMLElement>;
  toggleRef: React.MutableRefObject<HTMLElement>;
  onFocusOut?: (event: React.FocusEvent) => void;
  onTapOutside?: (event: TouchEvent) => void;
}

export const OnFocusOutOrTapOutside = ({
  containerRef,
  toggleRef,
  children,
  onFocusOut,
  onTapOutside
}) => {
  return (
    <OnTapOutside
      toggleRef={toggleRef}
      containerRef={containerRef}
      onTapOutside={onTapOutside}
    >
      <OnFocusOut
        toggleRef={toggleRef}
        containerRef={containerRef}
        onFocusOut={onFocusOut}
      >
        {children}
      </OnFocusOut>
    </OnTapOutside>
  );
};
