import type { RtkApi } from '@acadeum/redux';
import type {
  Id,
  PaginatedResponse,
  CourseEnrollmentRequest,
  CourseEnrollment,
  CourseSection,
  Course,
  Institution,
  Student,
  Session, EquivalentCourse
} from '@acadeum/types';

import { COURSE_ENROLLMENT_REQUEST_ENDPOINTS_TAG_TYPES } from '../consts';


export interface FetchHomeCourseEnrollmentRequestsInput {
  search?: string;
  page?: number;
  pageSize?: number;
  dataExport?: boolean;
  filters?: {
    id?: Id | Id[];
    status?: CourseEnrollment['status'][];
  };
}

export type FetchHomeCourseEnrollmentRequestsOutput = PaginatedResponse<Pick<CourseEnrollmentRequest, 'id' | 'status' | 'createdAt' | 'seatsRequested'> & {
  // equivalentCourse included only when `dataExport` is `true`
  equivalentCourse?: Pick<EquivalentCourse, 'title' | 'code'>
  section: Pick<CourseSection,
    // Properties that included always in the response.
    'id' | 'code' | 'term' | 'session' | 'cost' | 'startDate' | 'endDate' | 'lastAddDate' | 'lastDropDate' | 'lastWithdrawalDate'
    // Properties that included only when `dataExport` is `true`.
    | 'internalSession' | 'internalTerm'
  >
  enrollments: Array<Pick<CourseEnrollment,
    // Properties that included always in the response.
    'id' | 'status' | 'type' | 'createdAt' | 'startedAt' | 'endedAt' | 'letterGrade' | 'numericalGrade'
    // Properties that included only when `dataExport` is `true`.
    | 'enrollReason' | 'enrollReasonNotes' | 'studentDropReason' | 'studentDropReasonNotes' | 'studentWithdrawReason' | 'studentWithdrawReasonNotes' | 'homeDropReason' | 'homeDropReasonNotes' | 'homeWithdrawReason' | 'homeWithdrawReasonNotes' | 'teachingDropReason' | 'teachingDropReasonNotes' | 'teachingWithdrawReason' | 'teachingWithdrawReasonNotes' | 'denyReason' | 'denyReasonNotes' | 'removeReason' | 'removeReasonNotes' | 'gradeNotes' | 'paid' | 'refunded' | 'costForInstitution' | 'costForStudent'
  > & {
    student: Pick<Student,
      // Properties that included always in the response
      'id' | 'hiStudentId' | 'firstName' | 'middleName' | 'lastName' | 'email'
      // Properties that included only when `dataExport` is `true`.
      | 'phone' | 'addressLine1' | 'addressLine2' | 'city' | 'state' | 'country' | 'postalCode' | 'major' | 'residency' | 'level' | 'gender' | 'races' | 'ethnicity' | 'dob' | 'citizenship' | 'startDate' | 'advisorName' | 'advisorEmail'
    >
  }>;
}>

export interface FetchTeachingCourseEnrollmentRequestsInput {
  page?: number;
  pageSize?: number;
  search?: string;
  type?: 'accepted' | 'finalized';
  dataExport?: boolean;
  ids?: Id[];
  filters?: {
    id?: Id | Id[];
    status?: CourseEnrollmentRequest['status'][];
  };
}

export interface FetchTeachingCourseEnrollmentRequestsResponseItem extends Pick<CourseEnrollmentRequest, 'id' | 'status' | 'createdAt' | 'seatsRequested'> {
  institution: Pick<Institution, 'id' | 'name' | 'vanityUrl' | 'logoUrl'>;
  section: Pick<CourseSection, 'id' | 'code' | 'session' | 'term' | 'cost' | 'startDate' | 'endDate' | 'lastAddDate' | 'lastDropDate' | 'lastWithdrawalDate'> & {
    course: Pick<Course, 'id' | 'code' | 'title' | 'onDemand' | 'level' | 'hours'> & {
      institution: Pick<Institution, 'onDemandCourseEndDateDaysAfterStartDate' | 'onDemandCourseDropDateDaysAfterStartDate'>;
    }
  };
  enrollments: Array<Pick<CourseEnrollment, 'id' | 'status' | 'type' | 'createdAt' | 'startedAt' | 'endedAt' | 'letterGrade' | 'numericalGrade'> & {
    student: Pick<Student, 'id' | 'hiStudentId' | 'firstName' | 'lastName' | 'middleName' | 'email'>;
  }>;
}

export type FetchTeachingCourseEnrollmentRequestsOutput = PaginatedResponse<FetchTeachingCourseEnrollmentRequestsResponseItem>

export interface FetchTeachingCourseEnrollmentRequestInput {
  id: Id;
}

export interface FetchTeachingCourseEnrollmentRequestOutput extends Pick<CourseEnrollmentRequest, 'id' | 'status' | 'createdAt' | 'seatsRequested'> {
  institution: Pick<Institution, 'id' | 'name' | 'vanityUrl' | 'logoUrl' | 'onDemandCourseDropDateDaysAfterStartDate' | 'onDemandCourseEndDateDaysAfterStartDate'>;
  enrollments: Array<Pick<CourseEnrollment, 'id' | 'status' | 'type' | 'createdAt' | 'startedAt' | 'endedAt' | 'letterGrade'> & {
    student: Pick<Student, 'id' | 'hiStudentId' | 'firstName' | 'lastName' | 'middleName' | 'email'>;
  }>;
  section: Pick<CourseSection, 'id' | 'number'> & {
    session: Pick<Session, 'id' | 'name' | 'term' | 'cost' | 'startDate' | 'endDate' | 'lastAddDate' | 'lastDropDate' | 'lastWithdrawalDate'> & {
      course: Pick<Course, 'id' | 'code' | 'title' | 'onDemand' | 'level'> & {
        institution: Pick<Institution, 'id' | 'name' | 'vanityUrl' | 'logoUrl' | 'onDemandCourseDropDateDaysAfterStartDate' | 'onDemandCourseEndDateDaysAfterStartDate'>;
      }
    }
  };
}

export const injectCourseEnrollmentRequestEndpoints = (rtkApi: RtkApi) =>
  rtkApi
    .enhanceEndpoints({
      addTagTypes: COURSE_ENROLLMENT_REQUEST_ENDPOINTS_TAG_TYPES
    })
    .injectEndpoints({
      endpoints: (build) => ({
        // Home Course Enrollment Requests
        fetchHomeCourseEnrollmentRequests: build.query<FetchHomeCourseEnrollmentRequestsOutput, FetchHomeCourseEnrollmentRequestsInput>({
          query: (params) => ({
            url: '/home-course-enrollment-requests',
            params
          }),
          providesTags: [{ type: 'homeCourseEnrollmentRequests', id: 'LIST' }]
        }),

        // Teaching Course Enrollment Requests
        fetchTeachingCourseEnrollmentRequests: build.query<FetchTeachingCourseEnrollmentRequestsOutput, FetchTeachingCourseEnrollmentRequestsInput>({
          query: (params) => ({
            url: '/teaching-course-enrollment-requests',
            params
          }),
          providesTags: [{ type: 'teachingCourseEnrollmentRequests', id: 'LIST' }]
        }),
        fetchTeachingCourseEnrollmentRequest: build.query<FetchTeachingCourseEnrollmentRequestOutput, FetchTeachingCourseEnrollmentRequestInput>({
          query: ({ id }) => `/teaching-enrollment-requests/${id}`,
          providesTags: (result, error, arg) => [{ type: 'teachingCourseEnrollmentRequests', id: arg.id }]
        })
      })
    });
