import React from 'react';
import { useSelector } from 'react-redux';

import { AdminUserActions } from '@acadeum/shared-admin-ui';
import { Outputs, Output, Row } from '@acadeum/ui';

import actions from '../../actions';

import Section from '../../components/Section';
import Subsection from '../../components/Subsection';
import UserAuthHistoryTable from '../../components/UserAuthHistoryTable';
import { formatName, formatUserName } from '../../helpers/format';
import type { ReduxState } from '../../helpers/app.types';

import './AdminUserLog.sass';

const {
  fetchUserAdmin
} = actions;

export default function AdminUserLog() {
  const user = useSelector((state: ReduxState) => state.usersAdmin.user);

  return (
    <Section
      title="User Log"
      className="AdminUserLog-header">

      <Section
        title={(
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {formatUserName(user)}
            <div className="AdminUserLog-actionsContainer">
              <AdminUserActions
                user={user}
                refreshFetchDataStudentsOrUsers={() => fetchUserAdmin(user.id)}
              />
            </div>
          </div>
        )}>

        <Subsection title="Personal Information">
          <Outputs>
            <Row>
              <Output
                label="First Name"
                value={user.firstName}
              />
              <Output
                label="Middle Name"
                value={user.middleName}
              />
              <Output
                label="Last Name"
                value={user.lastName}
              />
              <Output
                label="Email"
                type="email"
                value={user.email}
              />
            </Row>
          </Outputs>
        </Subsection>

        <Subsection title="History" marginTop="large">
          <UserAuthHistoryTable history={user.history}/>
        </Subsection>
      </Section>
    </Section>
  );
}

AdminUserLog.meta = ({ useSelector }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const user = useSelector(state => state.usersAdmin.user);
  return {
    title: formatUserName(user)
  };
};

AdminUserLog.breadcrumbs = (state) => [
  ['User Accounts', '/admin/users'],
  formatName(state.usersAdmin.user)
];

AdminUserLog.load = async ({ params: { id } }) => {
  await fetchUserAdmin(id);
};
