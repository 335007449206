import type { FetchCourseEnrollmentsInput, FetchCourseEnrollmentsOutputItem } from '@acadeum/api';

import { useLazyFetchCourseEnrollmentsQuery } from '../../../api/courseEnrollment';

export function useFetchCourseEnrollmentsForExport({
  filters,
  ...rest
}: FetchCourseEnrollmentsInput) {
  const [lazyFetchHomeCourseEnrollmentsQuery] = useLazyFetchCourseEnrollmentsQuery();

  return function (selectedRowIds: string[]) {
    const pageSize = 100;
    let page = 1;
    let allRecords: FetchCourseEnrollmentsOutputItem[] = [];

    function fetchRecords() {
      const input: FetchCourseEnrollmentsInput = {
        ...rest,
        page,
        pageSize,
        export: true,
        filters: { ...filters }
      };

      if (!input.filters) {
        input.filters = {};
      }

      if (selectedRowIds.length > 0) {
        input.filters.id = selectedRowIds.map(Number);
      }

      return lazyFetchHomeCourseEnrollmentsQuery(input)
        .unwrap()
        .then(result => {
          const data = result.results;
          allRecords = allRecords.concat(data);
          page += 1;
          if (data.length === pageSize) {
            return fetchRecords();
          }
          return allRecords;
        })
        .catch(error => {
          console.error(error);
          throw error;
        });
    }

    return fetchRecords();
  };
}
