import type { FC } from 'react';
import React from 'react';

import isValidCurrencyAmount from 'common-lib/lib/isValidCurrencyAmount';

import type { UseVerifyPrimaryPaymentSourceMutation } from '@acadeum/api';
import { getErrorData, parseCurrencyInputCents } from '@acadeum/helpers';
import { useTranslate } from '@acadeum/translate';
import type { OnSubmit } from '@acadeum/ui';
import { FormField, FormModal, Text } from '@acadeum/ui';

import { FINANCIAL_SCREEN, useFinancialNavigationContext } from '../../context';

import styles from './VerifyBankAccountFormModal.module.scss';

export interface VerifyBankAccountFormModalProps {
  show: boolean;
  type: 'current' | 'new';
  onHide: () => void;
  onAfterSubmit: () => void;
  useVerifyPrimaryPaymentSourceMutation: UseVerifyPrimaryPaymentSourceMutation
}

interface FormValues {
  firstDeposit: number;
  secondDeposit: number;
}

export const VerifyBankAccountFormModal: FC<VerifyBankAccountFormModalProps> = ({
  show,
  type,
  onHide,
  onAfterSubmit,
  useVerifyPrimaryPaymentSourceMutation
}) => {
  const [verifyPrimaryPaymentSourceMutation] = useVerifyPrimaryPaymentSourceMutation();
  const t = useTranslate('shared-admin-ui.VerifyBankAccountFormModal');
  const { changeView } = useFinancialNavigationContext();

  const validateCurrencyAmount = (value) => {
    if (!isValidCurrencyAmount(value)) {
      return t('invalidCurrencyAmount');
    }
    if (value <= 0) {
      return t('Deposit must be greater than 0');
    }
  };

  const onSubmit: OnSubmit<FormValues> = async ({ firstDeposit, secondDeposit }) => {
    firstDeposit = parseCurrencyInputCents(firstDeposit);
    secondDeposit = parseCurrencyInputCents(secondDeposit);

    try {
      await verifyPrimaryPaymentSourceMutation({ amounts: [firstDeposit, secondDeposit], type }).unwrap();
      if (typeof onAfterSubmit === 'function') {
        await onAfterSubmit();
      }
      onHide();
    } catch (error: unknown) {
      const errorData = getErrorData(error);
      if (errorData?.message?.includes('Your bank account has been deleted')) {
        // TODO: Refetch primary payment
        return changeView({ screen: FINANCIAL_SCREEN.DASHBOARD });
      }
      throw error;
    }
  };

  return (
    <FormModal
      show={show}
      title={t('title')}
      submitText={t('verify')}
      onHide={onHide}
      onCancel={onHide}
      onSubmit={onSubmit}
    >
      <Text className={styles.VerifyBankAccountFormModal__description}>
        {t('description')}
      </Text>
      <FormField
        required
        type="currency"
        label={t('firstDepositHint')}
        name="firstDeposit"
        defaultValue={0}
        placeholder="0.12"
        currency="USD"
        validate={validateCurrencyAmount}
      />
      <FormField
        required
        type="currency"
        label={t('secondDepositHint')}
        name="secondDeposit"
        defaultValue={0}
        placeholder="0.15"
        currency="USD"
        validate={validateCurrencyAmount}
      />
    </FormModal>
  );
};
