import { useMemo } from 'react';

import { HStack, VStack } from '@acadeum/core-ui';
import { Title, Tag, DataBlock } from '@acadeum/ui';

import styles from './CoursePreview.module.scss';

export const DUMMY_SECTION = {
  id: 0,
  number: '',
  session: {
    id: 0,
    name: 'Session',
    term: 'Term',
    earliestStartDate: new Date(0),
    earliestEndDate: new Date(0),
    startDate: new Date(0),
    endDate: new Date(0),
    cost: 0,
    // scheduledBy: [],
    course: {
      id: 0,
      hours: 0,
      code: '',
      title: '',
      categories: [],
      approvedBy: [],
      customSchedules: {},
      facultyCredentials: [],
      learningAssessments: [],
      hasFacultyCredentials: false,
      hasLearningAssessments: false,
      onDemand: false,
      qualityMattersDesignation: false,
      syllabusUrl: 'https://example.com',
      institution: {
        id: 0,
        name: '',
        logoUrl: '',
        accreditations: []
      }
    }
  }
};


export const CoursePreview = ({ dummyCost, dummyHours, showPrice, formatCurrency }) => {
  const section = useMemo(() => ({
    ...DUMMY_SECTION,
    cost: dummyCost
  }), [dummyCost]);

  const courseTitle = (
    <HStack justify="between" align="center" mb="sm">
      <Title className={styles.title} level={2} marginBottom="none">
        Course Name
      </Title>

      <HStack gap="sm">
        <Tag>
          Status
        </Tag>
        <Tag>
          {dummyHours} Credit{dummyHours === 1 ? '' : 's'}
        </Tag>
      </HStack>
    </HStack>
  );

  const { onDemand } = section.session.course;

  const enrollableSessions = [section.session];

  return (
    <section className={styles.root}>
      <header>
        {courseTitle}
        <DataBlock type="institution" url={false} institution={({ name: 'University Name', id: 0 })} />
      </header>
      <VStack gap="sm" className={styles['sessions-list']}>
        {enrollableSessions.map((session) => (
          <p key={session.id} className={styles.sessions}>
            <>
              <span className={styles['sessions__span']}>
                {onDemand ? 'On-Demand' : session.term}
              </span>
              {!onDemand && (
                <span className={styles['sessions__span']}>
                  Dates
                </span>
              )}
            </>
            {showPrice &&
              <span className={styles['sessions__span']}>
                {dummyCost === 0 ? 'No Charge' : (
                  <Price value={dummyCost} formatCurrency={formatCurrency}/>
                )}
              </span>
            }
          </p>
        ))}
      </VStack>
    </section>
  );
};

const Price = ({ value, formatCurrency }) => {
  const formatted = formatCurrency(value);
  const digitsStartAt = formatted.search(/\d/);
  const currency = formatted.slice(0, digitsStartAt);
  const amount = formatted.slice(digitsStartAt);
  if (currency) {
    return (
      <>
        <span className={styles.price}>
          {currency}
        </span>
        {amount}
      </>
    );
  }
  return amount;
};
