import type { FC } from 'react';
import React, { useMemo } from 'react';

import { useTranslate } from '@acadeum/translate';
import type { OnSubmit } from '@acadeum/ui';
import { Form, FormFooter, FormField, ContentSection, FormSubmit, Button, Text } from '@acadeum/ui';

import type { AddCourseMethod } from '../../types';

export interface SelectCourseUploadMethodStepProps {
  onChooseUploadMethod: (method: AddCourseMethod) => void;
  cancelUrl: string;
}

interface FormValues {
  method?: AddCourseMethod;
}

export const SelectCourseUploadMethodStep: FC<SelectCourseUploadMethodStepProps> = ({
  onChooseUploadMethod,
  cancelUrl
}) => {
  const t = useTranslate('shared-admin-ui.SelectCourseUploadMethodStep');

  const onSubmit: OnSubmit<FormValues> = ({ method = 'upload' }) => {
    onChooseUploadMethod(method);
  };

  const options = useMemo(() => [
    {
      value: 'manual',
      label: t('manual.title'),
      description: t('manual.description')
    },
    {
      value: 'upload',
      label: t('upload.title'),
      description: t('upload.description')
    }
  ], []);

  return (
    <Form onSubmit={onSubmit}>
      <ContentSection title={t('title')}>
        <Text color="grey">
          {t('description')}
        </Text>
        <br/>
        <FormField
          border
          required
          name="method"
          type="radio"
          options={options}
        />
      </ContentSection>

      <FormFooter>
        <Button url={cancelUrl} variant="secondary">
          {t('cancel')}
        </Button>
        <FormSubmit>
          {t('next')}
        </FormSubmit>
      </FormFooter>
    </Form>
  );
};
