import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { formatUserName } from '../../helpers/format';
import Link from '../Link';

import EmailVerificationFinished from './EmailVerificationFinished.svg';
import EmailVerificationStarted from './EmailVerificationStarted.svg';
import LoginAs from './LoginAs.svg';
import LoginUsingPassword from './LoginUsingPassword.svg';
import LoginUsingSingleSignOn from './LoginUsingSingleSignOn.svg';
import PasswordResetFinished from './PasswordResetFinished.svg';
import PasswordResetStarted from './PasswordResetStarted.svg';
import PasswordUpdate from './PasswordUpdate.svg';

import './UserAuthHistoryRecord.sass';

export default function UserAuthHistoryRecord({
  event,
  error,
  adminUser
}) {
  const Icon = getIcon(event);
  return (
    <div className={classNames('UserAuthHistoryEvent', {
      'UserAuthHistoryEvent--error': error
    })}>
      <div className="UserAuthHistoryEvent-iconAndLabel">
        <Icon className="UserAuthHistoryEvent-icon"/>
        <p className="UserAuthHistoryEvent-label">
          {getHistoryEventLabel(event)}
        </p>
      </div>
      {/*error &&
        <span className="UserAuthHistoryEvent-error">
          <CrossIcon className="UserAuthHistoryEvent-errorIcon"/>
          Error
        </span>
      */}
      {typeof error === 'object' && error !== null &&
        <pre className="UserAuthHistoryEvent-errorDetails">
          {Object.keys(error).map((key) => {
            return `${key}: ${error[key]}`;
          }).join('\n')}
        </pre>
      }
      {adminUser &&
        <div className="UserAuthHistoryEvent-adminUser">
          By
          {' '}
          <Link
            to={`/admin/users/${adminUser.id}`}
            style={{ color: 'inherit' }}>
            {formatUserName(adminUser)}
          </Link>
        </div>
      }
      {event.automatic &&
        <div className="UserAuthHistoryEvent-automatic">
          Automatic
        </div>
      }
    </div>
  );
}

UserAuthHistoryRecord.propTypes = {
  event: PropTypes.object.isRequired,
  error: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object
  ]),
  adminUser: PropTypes.shape({
    id: PropTypes.number.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    title: PropTypes.string
  }),
  date: PropTypes.instanceOf(Date).isRequired,
  ip: PropTypes.string.isRequired
};

function getIcon(event) {
  switch (event.type) {
    case 'login':
      switch (event.method) {
        case 'as':
          return LoginAs;
        case 'password':
          return LoginUsingPassword;
        case 'sso':
          return LoginUsingSingleSignOn;
      }
      break;
    case 'password_update':
      return PasswordUpdate;
    case 'password_reset':
      switch (event.stage) {
        case 'auth':
          return PasswordResetStarted;
        case 'finish':
          return PasswordResetFinished;
      }
      break;
    case 'email_verification':
      switch (event.stage) {
        case 'auth':
          return EmailVerificationStarted;
        case 'finish':
          return EmailVerificationFinished;
      }
      break;
    case 'email_change':
      switch (event.stage) {
        case 'auth':
          return EmailVerificationStarted;
        case 'cancel':
          return EmailVerificationStarted;
        case 'finish':
          return EmailVerificationFinished;
      }
      break;
  }
  throw new Error(`Unsupported event:\n${JSON.stringify(event, null, 2)}`);
}

function getHistoryEventLabel(event) {
  switch (event.type) {
    case 'login':
      switch (event.method) {
        case 'as':
          return 'Log In As';
        case 'sso':
          return 'Log In Using SSO';
        case 'password':
          return 'Log In Using Password';
      }
      break;
    case 'email_verification':
      switch (event.stage) {
        case 'auth':
          return 'Send Email Verification Letter';
        case 'finish':
          return 'Finish Email Verification';
      }
      break;
    case 'email_change':
      switch (event.stage) {
        case 'auth':
          return 'Send Email Change Letter';
        case 'cancel':
          return 'Cancel Email Change';
        case 'finish':
          return 'Finish Email Change';
      }
      break;
    case 'password_update':
      return 'Password Update';
    case 'password_reset':
      switch (event.stage) {
        case 'auth':
          if (event.initial) {
            return 'Send Initial Password Setup Email';
          }
          return 'Send Password Reset Email';
        case 'finish':
          return 'Finish Password Reset';
      }
      break;
  }
  throw new Error(`Unsupported event:\n${JSON.stringify(event, null, 2)}`);
}
