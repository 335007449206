import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

export default function Col({
  col,
  xs,
  sm,
  md,
  lg,
  xl,
  className,
  children
}) {
  return (
    <div className={classNames(
      className,
      col && `col-${col}`,
      xs && `col-xs-${xs}`,
      sm && `col-sm-${sm}`,
      md && `col-md-${md}`,
      lg && `col-lg-${lg}`,
      xl && `col-xl-${xl}`
    )}>
      {children}
    </div>
  );
}

Col.propTypes = {
  className: PropTypes.string,
  col: PropTypes.number,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
  children: PropTypes.node
};
