import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './FormFooter.scss';

export default function FormFooter({ className, marginTop, children }) {
  return (
    <div className={classNames('FormFooter', className, 'd-flex align-items-center justify-content-end', {
      'FormFooter--marginTopNone': marginTop === 'none',
      'FormFooter--marginTopSmall': marginTop === 'small'
    })}>
      {children}
    </div>
  );
}

FormFooter.propTypes = {
  marginTop: PropTypes.oneOf(['none', 'small']),
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};

FormFooter.ActionDescription = function ActionDescription(props) {
  return <p {...props} className="FormFooter-actionDescription"/>;
};

function FormFooterActions({
  reverseOrderOnSmallScreens,
  children,
  align
}) {
  return (
    <div className={classNames('FormFooter-actions', {
      'FormFooter-actions--reverseOrderOnSmallScreens': reverseOrderOnSmallScreens,
      'FormFooter-actions--alignStart': align === 'start'
    })}>
      {children}
    </div>
  );
}

FormFooterActions.propTypes = {
  reverseOrderOnSmallScreens: PropTypes.bool,
  children: PropTypes.node.isRequired,
  align: PropTypes.oneOf(['start'])
};

FormFooter.Actions = FormFooterActions;
