import { createColumnHelper } from '@tanstack/react-table';
import { get } from 'lodash-es';
import type { FC } from 'react';
import React, { useMemo, useState } from 'react';

import type {
  FetchTransfersOutputListItem,
  UseFetchInvoiceQuery,
  UseFetchTransfersQuery,
  UseLazyFetchTransfersQuery
} from '@acadeum/api';
import { useQueryWithPagination } from '@acadeum/hooks';
import { FEE_TYPE_OPTIONS, optionsWithAllOption, PAYMENT_INTENT_STATUS_OPTIONS } from '@acadeum/selection-options';
import { useTranslate } from '@acadeum/translate';
import type { ActionsProps, TableExportDataColumns } from '@acadeum/ui';
import { Actions, DataBlock, Filters, Page, Table, TabsNavigation, Tag, createTableId } from '@acadeum/ui';

import { formatCurrency } from '../../helpers/format';
import { useSettingsRoutes } from '../../hooks/useSettingsRoutes';

import { useApp } from '../../providers/useApp';
import { useOnError } from '../../providers/useOnError';

import { InvoiceDetails } from './ui/InvoiceDetails';

const columnHelper = createColumnHelper<FetchTransfersOutputListItem>();
const feeTypesOptions = optionsWithAllOption(FEE_TYPE_OPTIONS);
const paymentIntentStatusOptions = optionsWithAllOption(PAYMENT_INTENT_STATUS_OPTIONS);
function useColumns(useFetchInvoiceQuery: UseFetchInvoiceQuery) {
  const t = useTranslate('shared-admin-ui.financial');
  return useMemo(() => {
    const columns = [
      columnHelper.accessor((originalRow) => {
        const netReceived = originalRow.netReceived;
        if (typeof netReceived === 'number') {
          return formatCurrency(netReceived, { currency: 'USD', cents: true });
        }
        return null;
      }, {
        id: 'netReceived',
        header: t('amount'),
        enableSorting: true
      }),
      columnHelper.accessor(originalRow => {
        const netRefund = originalRow.netRefund;
        if (typeof netRefund === 'number') {
          return formatCurrency(netRefund, { currency: 'USD', cents: true });
        }
        return null;
      }, {
        id: 'netRefund',
        header: t('amountRefunded'),
        enableSorting: true
      }),
      columnHelper.accessor((originalRow) => t(`paymentTypes.${originalRow.type}`), {
        id: 'type',
        header: t('transactionType'),
        enableSorting: false
      }),
      columnHelper.accessor((originalRow) => originalRow.type === 'ANNUAL_FEE'
        ? 'Acadeum'
        : originalRow.sourceTransaction?.institution?.name, {
        id: 'counterparty',
        header: t('counterparty'),
        enableSorting: false,
        cell: ({ row }) => row.original.type === 'ANNUAL_FEE'
          ? t('acadeum')
          : row.original.sourceTransaction?.institution ? (
            <DataBlock
              type="institution"
              institution={row.original.sourceTransaction.institution}
            />
          ) : null
      }),
      columnHelper.accessor('sourceTransaction.invoice.createdAt', {
        header: t('invoiceDate'),
        enableSorting: true,
        cell: ({ getValue }) => {
          const createdAt = getValue();
          return (
            <DataBlock
              utc
              type="date"
              month="numeric"
              date={createdAt}
            />
          );
        }
      }),
      columnHelper.accessor('sourceTransaction.status', {
        header: t('paymentStatus'),
        enableSorting: false,
        cell: ({ getValue }) => {
          const status = getValue();
          return <Tag variant={status}/>;
        }
      }),
      columnHelper.accessor('sourceTransaction.paymentMethod.type', {
        header: t('paymentMethod'),
        enableSorting: false,
        cell: ({ getValue }) => t(`paymentMethodTypes.${getValue()}`, {
          defaultMessage: getValue()
        })
      }),
      columnHelper.accessor('sourceTransaction.id', {
        header: t('hiPaymentId'),
        enableSorting: false
      }),
      columnHelper.accessor('destinationPaymentId', {
        header: t('tiPaymentId'),
        enableSorting: false
      }),
      columnHelper.accessor('sourceTransaction.invoice.id', {
        header: t('stripeInvoiceId'),
        enableSorting: true
      }),
      columnHelper.accessor('sourceTransaction.description', {
        header: t('description'),
        enableSorting: false
      }),
      columnHelper.accessor('createdAt', {
        header: t('createdAt'),
        enableSorting: true,
        cell: ({ getValue }) => {
          const createdAt = getValue();
          return (
            <DataBlock
              utc
              type="date"
              month="numeric"
              date={createdAt}
            />
          );
        }
      }),
      columnHelper.display({
        id: 'actions',
        cell: ({ row, downloadRow }) => (
          <ActionsCell
            downloadRow={downloadRow}
            originalRow={row.original}
            useFetchInvoiceQuery={useFetchInvoiceQuery}
          />
        )
      })
    ];

    const exportDataColumns = columns
      .reduce<TableExportDataColumns<'xlsx', FetchTransfersOutputListItem>>((res, column) => {
        if (column.id === 'actions') {
          return res;
        }

        if (typeof column.header !== 'string') {
          throw new Error('Column header must be a string');
        }

        res.push({
          title: column.header,
          value: row => {
            if (typeof column['accessorKey'] === 'string') {
              return get(row, column['accessorKey']);
            } if (typeof column['accessorFn'] === 'function') {
              return column['accessorFn'](row);
            }
            throw new Error(`Invalid column accessor: ${column.header}`);
          }
        });

        return res;
      }, []);
    return { columns, exportDataColumns };
  }, [useFetchInvoiceQuery]);
}

export interface TeachingPaymentHistoryPageProps {
  useFetchTransfersQuery: UseFetchTransfersQuery;
  useFetchInvoiceQuery: UseFetchInvoiceQuery;
  useLazyFetchTransfersQuery: UseLazyFetchTransfersQuery;
}

export const TeachingPaymentHistoryPage: FC<TeachingPaymentHistoryPageProps> = ({
  useFetchTransfersQuery,
  useFetchInvoiceQuery,
  useLazyFetchTransfersQuery
}) => {
  const { app } = useApp();
  const t = useTranslate('shared-admin-ui.financial');
  const { getSettingsUrl, getPaymentHistoryUrl, getTeachingPaymentHistoryUrl } = useSettingsRoutes();
  const onError = useOnError();

  const { columns, exportDataColumns } = useColumns(useFetchInvoiceQuery);

  const {
    data,
    error,
    isFetching,
    _globalFilterOptions,
    _paginationOptions,
    _filtersOptions,
    _fetchDataForExport,
    _sortingOptions
  } = useQueryWithPagination(
    useFetchTransfersQuery,
    {
      sort: [{ by: 'createdAt', asc: false }]
    },
    useLazyFetchTransfersQuery
  );

  if (error) {
    return onError(error);
  }

  return (
    <Page
      title={t('paymentHistory')}
      breadcrumbs={[
        [t(app === 'admin' ? 'general' : 'settings', { global: true }), getSettingsUrl()],
        t('paymentHistory')
      ]}
    >
      <TabsNavigation
        menuItems={[
          {
            title: t('enrollingPayments'),
            url: getPaymentHistoryUrl()
          },
          {
            title: t('teachingPayments'),
            url: getTeachingPaymentHistoryUrl(),
            active: true
          }
        ]}
      />
      <Filters
        border
        values={_filtersOptions.filters}
        onFiltersChange={(newValue) => {
          // @ts-expect-error
          if (newValue.type === 'ANNUAL_FEE') {
            // @ts-expect-error
            delete newValue.institutionIds;
          }
          // @ts-expect-error
          if (newValue?.institutionIds?.length === 0) {
            // @ts-expect-error
            delete newValue?.institutionIds;
          }
          _filtersOptions.onFiltersChange(newValue);
        }}
      >
        <Filters.Row>
          <Filters.Select
            name="type"
            label={t('transactionType')}
            getFilterLabel={(value) => t(`paymentTypes.${value}`)}
            options={feeTypesOptions}
          />
          {_filtersOptions.filters.type !== 'ANNUAL_FEE' && (
            <Filters.Institution
              multiple
              type="institution"
              name="institutionIds"
              label="Counterparty"
            />
          )}
          <Filters.Select
            name="status"
            label="Status"
            options={paymentIntentStatusOptions}
          />
          <Filters.DateRange
            utc
            name="createdAt"
            label="Invoice Date"
          />
        </Filters.Row>
      </Filters>
      <Table
        id={createTableId('teachingPaymentHistory')}
        hasColumnVisibility
        enableRowSelection
        loading={isFetching}
        data={data?.results}
        columns={columns}
        sortingOptions={_sortingOptions}
        globalFilterOptions={_globalFilterOptions}
        paginationOptions={_paginationOptions}
        exportOptions={{
          type: 'xlsx',
          fileName: 'teaching_payments',
          exportDataColumns,
          fetchDataForExport: _fetchDataForExport
        }}
      />
    </Page>
  );
};

interface ActionsCellProps {
  downloadRow: () => void;
  originalRow: FetchTransfersOutputListItem;
  useFetchInvoiceQuery: UseFetchInvoiceQuery;
}

const ActionsCell: FC<ActionsCellProps> = ({
  originalRow,
  downloadRow,
  useFetchInvoiceQuery
}) => {
  const [showInvoiceDetails, setShowInvoiceDetails] = useState(false);

  const actions = useMemo(() => {
    const actions: ActionsProps['actions'] = [
      {
        title: 'Download',
        onClick: () => downloadRow()
      }
    ];
    if (originalRow.sourceTransaction?.invoice?.id) {
      actions.push({
        title: 'See Invoice Details',
        onClick: () => setShowInvoiceDetails(true)
      });
    }

    return actions;
  }, [downloadRow, originalRow.sourceTransaction?.invoice?.id, setShowInvoiceDetails]);

  return (
    <>
      <Actions
        variant="kebab"
        actions={actions}
      />
      {showInvoiceDetails && originalRow.sourceTransaction?.invoice?.id && (
        <InvoiceDetails
          source="payments"
          useFetchInvoiceQuery={useFetchInvoiceQuery}
          invoiceId={originalRow.sourceTransaction.invoice?.id}
          onHide={() => setShowInvoiceDetails(false)}
        />
      )}
    </>
  );
};
