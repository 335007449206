import React, { useState } from 'react';

import { config } from '../constants';
import { usePicker } from '../context';

import type { ColorFormat, LocalesProps } from '../shared/types';

import AdvancedControls from './AdvancedControls';
import ComparibleColors from './ComparibleColors';
import EyeDropper from './EyeDropper';
import GradientControls from './GradientControls';


import { InputsIcon, PaletteIcon, SlidersIcon } from './icon';

const { defaultColor, defaultGradient } = config;

const ColorTypeBtns = ({
  hideColorTypeBtns,
  isGradient,
  setSolid,
  setGradient,
  locales
}: {
  hideColorTypeBtns?: boolean
  isGradient?: boolean
  setSolid: () => void
  setGradient: () => void
  locales?: LocalesProps
}) => {
  isGradient = Boolean(isGradient);

  if (hideColorTypeBtns) {
    return <div style={{ width: 1 }}/>;
  } else {
    return (
      <div
        className="rbgcp-control-btn-wrapper rbgcp-conrols"
        role="group"
        aria-label={locales?.CONTROLS?.LABEL}
        style={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <div
          role="button"
          aria-pressed={!isGradient}
          onClick={setSolid}
          data-selected={!isGradient}
          className="rbgcp-control-btn rbgcp-solid-btn"
        >
          {locales?.CONTROLS?.SOLID}
        </div>
        <div
          role="button"
          aria-pressed={isGradient}
          onClick={setGradient}
          data-selected={isGradient}
          className="rbgcp-control-btn rbgcp-gradient-btn"
        >
          {locales?.CONTROLS?.GRADIENT}
        </div>
      </div>
    );
  }
};

const InputTypeDropdown = ({
  openInputType,
  setOpenInputType
}: {
  openInputType?: boolean
  setOpenInputType: (arg0: boolean) => void
}) => {
  const { inputType, setInputType } = usePicker();
  const vTrans = openInputType
    ? 'visibility 0ms linear'
    : 'visibility 100ms linear 150ms';
  const zTrans = openInputType
    ? 'z-index 0ms linear'
    : 'z-index 100ms linear 150ms';
  const oTrans = openInputType
    ? 'opacity 120ms linear'
    : 'opacity 150ms linear 50ms';

  const handleInputType = (e: any, val: ColorFormat) => {
    if (openInputType) {
      e.stopPropagation();
      setInputType(val);
      setOpenInputType(false);
    }
  };

  return (
    <div
      className="rbgcp-color-model-dropdown"
      style={{
        visibility: openInputType ? 'visible' : 'hidden',
        zIndex: openInputType ? '' : -100,
        opacity: openInputType ? 1 : 0,
        transition: `${oTrans}, ${vTrans}, ${zTrans}`
      }}
    >
      <div
        onClick={(e) => handleInputType(e, 'rgb')}
        className="rbgcp-control-btn rbgcp-color-model-dropdown-btn"
        data-selected={inputType === 'rgb'}
      >
        RGB
      </div>
    </div>
  );
};

const Controls = ({
  locales,
  hideEyeDrop = false,
  hideAdvancedSliders = false,
  hideColorGuide = false,
  hideInputType = false,
  hideColorTypeBtns = false,
  hideGradientControls = false,
  hideGradientType = false,
  hideGradientAngle = false,
  hideGradientStop = false
}: {
  locales?: LocalesProps
  hideEyeDrop?: boolean
  hideAdvancedSliders?: boolean
  hideColorGuide?: boolean
  hideInputType?: boolean
  hideColorTypeBtns?: boolean
  hideGradientControls?: boolean
  hideGradientType?: boolean
  hideGradientAngle?: boolean
  hideGradientStop?: boolean
}) => {
  const { onChange, isGradient, handleChange, previous } = usePicker();
  const [openComparibles, setOpenComparibles] = useState(false);
  const [openInputType, setOpenInputType] = useState(false);
  const [openAdvanced, setOpenAdvanced] = useState(false);

  const noTools =
    hideEyeDrop && hideAdvancedSliders && hideColorGuide && hideInputType;

  const solidColor = previous?.color ?? defaultColor;
  const gradientColor = previous?.gradient ?? defaultGradient;

  const setSolid = () => {
    onChange(solidColor);
  };

  const setGradient = () => {
    onChange(gradientColor);
  };

  const allRightControlsHidden =
    hideEyeDrop && hideAdvancedSliders && hideColorGuide && hideInputType;
  const allControlsHidden = allRightControlsHidden && hideColorTypeBtns;

  if (allControlsHidden) {
    if (isGradient && !hideGradientControls) {
      return (
        <GradientControls
          hideGradientType={hideGradientType}
          hideGradientAngle={hideGradientAngle}
          hideGradientStop={hideGradientStop}
        />
      );
    } else {
      return null;
    }
  } else {
    return (
      <div style={{ paddingTop: 12, paddingBottom: 4 }}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
          className="rbgcp-controls-wrapper"
        >
          <ColorTypeBtns
            hideColorTypeBtns={hideColorTypeBtns}
            setGradient={setGradient}
            isGradient={isGradient}
            setSolid={setSolid}
            locales={locales}
          />

          {!allRightControlsHidden && (
            <div
              style={{ display: noTools ? 'none' : '' }}
              className="rbgcp-control-btn-wrapper"
            >
              {!hideEyeDrop && <EyeDropper onSelect={handleChange}/>}
              {!hideAdvancedSliders && (
                <div
                  onClick={() => setOpenAdvanced(!openAdvanced)}
                  data-selected={openAdvanced}
                  className="rbgcp-control-icon-btn rbgcp-advanced-btn"
                >
                  <SlidersIcon color={openAdvanced ? '#568CF5' : ''}/>
                </div>
              )}
              {!hideColorGuide && (
                <div
                  onClick={() => setOpenComparibles(!openComparibles)}
                  className="rbgcp-control-icon-btn rbgcp-comparibles-btn"
                  data-selected={openComparibles}
                >
                  <PaletteIcon color={openComparibles ? '#568CF5' : ''}/>
                </div>
              )}
              {!hideInputType && (
                <div
                  onClick={() => setOpenInputType(!openInputType)}
                  className="rbgcp-control-icon-btn rbgcp-color-model-btn"
                  data-selected={openInputType}
                >
                  <InputsIcon color={openInputType ? '#568CF5' : ''}/>
                  <InputTypeDropdown
                    openInputType={openInputType}
                    setOpenInputType={setOpenInputType}
                  />
                </div>
              )}
            </div>
          )}
        </div>
        {!hideAdvancedSliders && (
          <AdvancedControls openAdvanced={openAdvanced}/>
        )}
        {!hideColorGuide && (
          <ComparibleColors openComparibles={openComparibles}/>
        )}
        {isGradient && !hideGradientControls && (
          <GradientControls
            hideGradientType={hideGradientType}
            hideGradientAngle={hideGradientAngle}
            hideGradientStop={hideGradientStop}
          />
        )}
      </div>
    );
  }
};

export default Controls;
