import type { FC, ReactNode } from 'react';
import React from 'react';

import { BaseTooltip, BaseTooltipContent, BaseTooltipTrigger } from '../BaseTooltip';

export interface TooltipProps {
  children: ReactNode;
  content?: ReactNode;
  asChild?: boolean;
}

export const Tooltip: FC<TooltipProps> = ({
  children,
  content
}) => {
  return (
    <BaseTooltip>
      <BaseTooltipTrigger asChild>
        {children}
      </BaseTooltipTrigger>
      <BaseTooltipContent>
        {content}
      </BaseTooltipContent>
    </BaseTooltip>
  );
};

Tooltip.displayName = 'Tooltip';
