// THIS MAY STOP WORKING WHEN YOU UPDATE react-instantsearch, read the ui-state diagram of the new version and see for
// yourself
// https://www.algolia.com/doc/api-reference/widgets/ui-state/react/
// https://www.algolia.com/doc/deprecated/instantsearch/react/v6/api-reference/ui-state/

import { START_DATE_AND_END_DATE_FILTER_ATTRIBUTE_PLACEHOLDER } from './constants';
import { getCourseDivisionAttributeNameByView } from './DivisionFilter';
import { getCourseSearchAttributeName } from './getCourseSearchAttributeName';

export type CourseSearchView = 'sections' | 'courses';
export type CourseSearchScope = 'all' | 'own';

interface Toggle {
  [key: string]: boolean | undefined;
}

interface ExcludePast {
  [key: string]: boolean | undefined;
}

interface RefinementList {
  [key: string]: string[] | string;
}

interface Range {
  'session.cost'?: {
    max: number | undefined;
    min: number | undefined;
  };
  'session.startDate'?: { min: number | undefined; max: undefined };
  'session.earliestEndDate'?: { min: undefined; max: number | undefined };
  'sessions.startDate'?: { min: number | undefined; max: undefined };
  'sessions.earliestEndDate'?: { min: undefined; max: number | undefined };
}

export interface SearchStateAlgoliaV6 {
  query?: string;
  page?: number;
  toggle?: Toggle;
  refinementList?: RefinementList;
  excludePast?: ExcludePast;
  range?: Range;
  scope?: CourseSearchScope;
  view?: CourseSearchView;
}

export interface SavedSearchState {
  resultsPerPage?: number;
  filters: {
    query?: string;
    scope?: CourseSearchScope;
    approved?: boolean;
    consortial?: boolean;
    onDemand?: boolean;
    lastAddDate?: boolean;
    noPrerequisites?: boolean;
    zeroTextbookCost?: boolean;
    regionallyAccredited?: boolean;
    hasFacultyCredentials?: boolean;
    hasLearningAssessments?: boolean;
    qualityMattersDesignation?: boolean;
    hidePast?: boolean; // excludePast
    startDate?: number; // startDate.min
    endDate?: number; // endDate.max
    priceMin?: number;
    priceMax?: number;
    categories?: string[] | string;
    consortia?: string[] | string;
    hours?: string[] | string;
    teachingInstitutionName?: string[] | string;
    teachingInstitutions?: string[] | string;
    teachingInstitutionLevels?: string[] | string;
    teachingInstitutionTypes?: string[] | string;
    teachingInstitutionAffiliations?: string[] | string;
    teachingInstitutionAccreditations?: string[] | string;
    teachingInstitutionLearningManagementSystems?: string[] | string;
    countries?: string[] | string;
    countryRegions?: string[] | string; // states
    courseSharingGroups?: string[] | string;
    terms?: string[] | string;
    levels?: string[] | string;
    sessions?: string[] | string;
    division?: string[] | string;
  };
}

export const encodeSearchState = (searchState: SearchStateAlgoliaV6, view: 'sections' | 'courses') => {
  const { query, refinementList, toggle, range, excludePast, scope } = searchState;
  const courseDivisionAttributeName = getCourseDivisionAttributeNameByView(view);
  const result: SavedSearchState = {
    filters: {
      query: query,
      [courseDivisionAttributeName]: searchState[courseDivisionAttributeName]
    }
  };

  if (range) {
    const endDate = range[getCourseSearchAttributeName('session.earliestEndDate', view)];
    const startDate = range[getCourseSearchAttributeName('session.startDate', view)];
    const price = range[getCourseSearchAttributeName('session.cost', view)];

    if (endDate) {
      result.filters.endDate = endDate.max;
    }
    if (startDate) {
      result.filters.startDate = startDate.min;
    }
    if (price) {
      result.filters.priceMin = price.min;
      result.filters.priceMax = price.max;
    }
  }

  if (refinementList) {
    result.filters = {
      ...result.filters,
      consortia: refinementList[getCourseSearchAttributeName('session.course.institution.consortiaNames', view)],
      categories: refinementList[getCourseSearchAttributeName('session.course.categories', view)],
      countries: refinementList[getCourseSearchAttributeName('session.course.institution.country', view)],
      terms: refinementList[getCourseSearchAttributeName('session.term', view)],
      levels: refinementList[getCourseSearchAttributeName('session.course.level', view)],
      countryRegions: refinementList[getCourseSearchAttributeName('session.course.institution.state', view)],
      sessions: refinementList[getCourseSearchAttributeName('session.name', view)],
      hours: refinementList[getCourseSearchAttributeName('session.course.hours', view)],
      courseSharingGroups: refinementList[getCourseSearchAttributeName('session.course.courseSharingGroups.name', view)],
      teachingInstitutionName: refinementList[getCourseSearchAttributeName('session.course.institution.name', view)],
      teachingInstitutionLevels: refinementList[getCourseSearchAttributeName('session.course.institution.level', view)],
      teachingInstitutionTypes: refinementList[getCourseSearchAttributeName('session.course.institution.control', view)],
      teachingInstitutionAffiliations: refinementList[getCourseSearchAttributeName('session.course.institution.affiliations', view)],
      teachingInstitutionAccreditations: refinementList[getCourseSearchAttributeName('session.course.institution.accreditations', view)],
      teachingInstitutionLearningManagementSystems: refinementList[getCourseSearchAttributeName('session.course.institution.learningManagementSystem', view)]
    };
  }
  if (toggle) {
    result.filters = {
      ...result.filters,
      approved: toggle[getCourseSearchAttributeName('session.course.approvedBy', view)] || false,
      consortial: toggle[getCourseSearchAttributeName('session.course.institution.consortialEnrollingInstitutionIds', view)] || false,
      onDemand: toggle[getCourseSearchAttributeName('session.course.onDemand', view)] || false,
      noPrerequisites: toggle[getCourseSearchAttributeName('session.course.hasPrerequisites', view)] || false,
      zeroTextbookCost: toggle[getCourseSearchAttributeName('session.course.zeroTextbookCost', view)] || false,
      regionallyAccredited: toggle[getCourseSearchAttributeName('session.course.institution.regionallyAccredited', view)] || false,
      qualityMattersDesignation: toggle[getCourseSearchAttributeName('session.course.institution.qualityMattersDesignation', view)] || false,
      hasFacultyCredentials: toggle[getCourseSearchAttributeName('session.course.hasFacultyCredentials', view)] || false,
      hasLearningAssessments: toggle[getCourseSearchAttributeName('session.course.hasLearningAssessments', view)] || false
    };
  }

  // `searchState` is:
  //
  // When `view: "sections"`:
  //
  // * Initial: `{}`
  //   * `hidePast` is `undefined`.
  // * After enabling "Show Past" filter: `{ excludePast: { session.lastAddDate: false } }`.
  //   * `hidePast` is set to `false`.
  // * After disabling "Show Past" filter: `{ excludePast: { session.lastAddDate: undefined } }`.
  //   * `hidePast` is set to `undefined`.
  //
  // So `hidePast` is `undefined` whenever "Show Past" filter is at its default value.
  // And `hidePast` is not `undefined` whenever "Show Past" filter is at a non-default value.
  //
  // When `view: "courses"`:
  //
  // * Initial: `{}`
  //   * `hidePast` is `undefined`.
  // * After enabling "Available Sections" filter: `{ excludePast: { session.lastAddDate: true } }`.
  //   * `hidePast` is set to `true`.
  // * After disabling "Available Sections" filter: `{ excludePast: { session.lastAddDate: undefined } }`.
  //   * `hidePast` is set to `undefined`.
  //
  // So `hidePast` is `undefined` whenever "Available Sections" filter is at its default value.
  // And `hidePast` is not `undefined` whenever "Available Sections" filter is at a non-default value.
  //
  // The code in `CourseSearch_.js` relies on that principle:
  // that `hidePast !== undefined` check could be used to determine if it's set to a non-default value.
  //
  if (excludePast) {
    result.filters = {
      ...result.filters,
      scope,
      hidePast: excludePast[getCourseSearchAttributeName('session.lastAddDate', view)]
    };
  }
  return result;
};

export const decodeSearchState = (searchState: SavedSearchState, view: 'sections' | 'courses') => {
  const { filters } = searchState;
  const courseDivisionAttributeName = getCourseDivisionAttributeNameByView(view);
  const result: SearchStateAlgoliaV6 = {
    scope: filters.scope,
    [courseDivisionAttributeName]: filters[courseDivisionAttributeName] || ''
  };
  result.refinementList = {
    [getCourseSearchAttributeName('session.course.institution.consortiaNames', view)]: filters.consortia || '',
    [getCourseSearchAttributeName('session.course.categories', view)]: filters.categories || '',
    [getCourseSearchAttributeName('session.course.institution.country', view)]: filters.countries || '',
    [getCourseSearchAttributeName('session.term', view)]: filters.terms || '',
    [getCourseSearchAttributeName('session.course.level', view)]: filters.levels || '',
    [getCourseSearchAttributeName('session.course.institution.state', view)]: filters.countryRegions || '',
    [getCourseSearchAttributeName('session.name', view)]: filters.sessions || '',
    [getCourseSearchAttributeName('session.course.hours', view)]: filters.hours || '',
    [getCourseSearchAttributeName('session.course.courseSharingGroups.name', view)]: filters.courseSharingGroups || '',
    [getCourseSearchAttributeName('session.course.institution.name', view)]: filters.teachingInstitutionName || '',
    [getCourseSearchAttributeName('session.course.institution.level', view)]: filters.teachingInstitutionLevels || '',
    [getCourseSearchAttributeName('session.course.institution.control', view)]: filters.teachingInstitutionTypes || '',
    [getCourseSearchAttributeName('session.course.institution.affiliations', view)]: filters.teachingInstitutionAffiliations || '',
    [getCourseSearchAttributeName('session.course.institution.accreditations', view)]: filters.teachingInstitutionAccreditations || '',
    [getCourseSearchAttributeName('session.course.institution.learningManagementSystem', view)]: filters.teachingInstitutionLearningManagementSystems || ''
  };
  result.toggle = {
    [getCourseSearchAttributeName('session.course.approvedBy', view)]: filters.approved,
    [getCourseSearchAttributeName('session.course.institution.consortialEnrollingInstitutionIds', view)]: filters.consortial,
    [getCourseSearchAttributeName('session.course.onDemand', view)]: filters.onDemand,
    [getCourseSearchAttributeName('session.course.hasPrerequisites', view)]: filters.noPrerequisites,
    [getCourseSearchAttributeName('session.course.zeroTextbookCost', view)]: filters.zeroTextbookCost,
    [getCourseSearchAttributeName('session.course.institution.regionallyAccredited', view)]: filters.regionallyAccredited,
    [getCourseSearchAttributeName('session.course.institution.qualityMattersDesignation', view)]: filters.qualityMattersDesignation,
    [getCourseSearchAttributeName('session.course.hasFacultyCredentials', view)]: filters.hasFacultyCredentials,
    [getCourseSearchAttributeName('session.course.hasLearningAssessments', view)]: filters.hasLearningAssessments
  };
  result.excludePast = {
    [getCourseSearchAttributeName('session.lastAddDate', view)]: filters.hidePast
  };

  if (filters.query) {
    result.query = filters.query;
  }

  if (filters) {
    if (filters.priceMin || filters.priceMax) {
      result.range = {
        ...result.range,
        'session.cost': {
          min: filters.priceMin,
          max: filters.priceMax
        }
      };
    }
    result.range = {
      ...result.range,
      [getCourseSearchAttributeName('session.startDate', view)]: { min: filters.startDate, max: undefined },
      [getCourseSearchAttributeName('session.earliestEndDate', view)]: { min: undefined, max: filters.endDate }
    };
  }
  return result;
};

const getSearchStateSimpleObject = (searchState: SearchStateAlgoliaV6, view?: 'sections' | 'courses') => {
  // Flatten `searchState` from a two-level object into a one-level object.
  // Such simplified structure of the object is more convenient for detecting
  // if any filters have non-default values.
  const courseDivisionAttributeName = getCourseDivisionAttributeNameByView(view);
  return {
    ...searchState.toggle,
    ...searchState.excludePast,
    ...searchState.refinementList,
    ...searchState.range,
    [courseDivisionAttributeName]: searchState[courseDivisionAttributeName]
  };
};

export const getAttributesHavingNonDefaultFilterValues = (searchState: SearchStateAlgoliaV6, view?: 'sections' | 'courses') => {
  const result: string[] = [];
  // eslint-disable-next-line
  const searchStateObject: {[key: string]: any} = getSearchStateSimpleObject(searchState, view);

  // check whether the filters have any value
  Object.entries(searchStateObject).forEach(([key, value]) => {
    if (key === 'session.lastAddDate' && typeof value === 'boolean') {
      result.push(key);
    } else if (key === 'session.earliestEndDate' || key === 'session.startDate') {
      if (Boolean(value?.max) || Boolean(value?.min)) {
        result.push(START_DATE_AND_END_DATE_FILTER_ATTRIBUTE_PLACEHOLDER);
      }
    } else if (value || typeof value === 'object') {
      result.push(key);
    }
  });

  return result;
};
