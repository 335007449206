import type { FC } from 'react';
import React, { useMemo } from 'react';

import formatUserName from 'common-lib/lib/formatUserName';

import type { UseFetchUsersQuery } from '@acadeum/api';
import { useTranslate } from '@acadeum/translate';
import type { Id } from '@acadeum/types';
import type { FormProps } from '@acadeum/ui';
import { ContentSection, Form, FormField, StickyFormFooter } from '@acadeum/ui';

import { useOnError } from '../../../../providers/useOnError';

interface FormValues {
  userIds: Id[];
}

export interface AssignUsersToRoleFormProps {
  defaultValues?: FormValues;
  onSubmit: FormProps<FormValues>['onSubmit'];
  onCancel: FormProps<FormValues>['onSubmit'] | (() => void);
  onBack?: FormProps<FormValues>['onSubmit'];
  submitText: string;
  useFetchUsersQuery: UseFetchUsersQuery;
}

export const AssignUsersToRoleForm: FC<AssignUsersToRoleFormProps> = ({
  defaultValues,
  onSubmit: propsOnSubmit,
  onCancel,
  onBack,
  submitText,
  useFetchUsersQuery
}) => {
  const t = useTranslate('shared-admin-ui.AssignUsersToRoleForm');
  const onError = useOnError();

  const {
    data: usersData,
    error
  } = useFetchUsersQuery({
    pageSize: 10000,
    filters: {
      status: 'ACTIVE'
    }
  });

  const onSubmit: FormProps<FormValues & { formAction: 'submit' | 'cancel' | 'back' }>['onSubmit'] = async (
    values,
    options
  ) => {
    const { formAction, userIds } = values;
    if (formAction) {
      const handler = formAction === 'back' ? onBack : formAction === 'cancel' ? onCancel : propsOnSubmit;
      await handler?.({ userIds }, options);
    }
  };

  const loading = !usersData;
  const options = useMemo(() => usersData ? usersData.results.map(user => ({
    label: formatUserName(user),
    value: user.id,
    description: user.email
  })) : [], [usersData]);

  if (error) {
    return onError(error);
  }

  return (
    <Form
      defaultValues={defaultValues}
      onSubmit={onSubmit} style={{ maxWidth: '920px' }}
    >
      <ContentSection title={t('assignUsers')}>
        <FormField
          name="userIds"
          type="choiceList"
          size="large"
          loading={loading}
          options={options}
        />
      </ContentSection>

      <StickyFormFooter
        onCancelProps={{
          action: 'cancel',
          disabledWhenInvalid: false
        }}
        onBackProps={{
          action: 'back',
          disabledWhenInvalid: false
        }}
        submitProps={{
          action: 'submit',
          children: submitText
        }}
      />
    </Form>
  );
};
