import config from 'config';
import React from 'react';


import { userHasPermission } from '@acadeum/helpers';
import { FinancialSettingsPage } from '@acadeum/shared-admin-ui';
import { useTranslate } from '@acadeum/translate';
import { Page } from '@acadeum/ui';

import {
  useCreateBackupPaymentSourceMutation,
  useCreatePrimaryPaymentSourceMutation,
  useDeleteBackupPaymentSourceMutation,
  useFetchBackupPaymentSourceQuery,
  useFetchFinancialSettingsStatusQuery,
  useFetchPrimaryPaymentSourceQuery,
  useUpdateBackupPaymentSourceMutation,
  useUpdatePrimaryPaymentSourceMutation,
  useVerifyPrimaryPaymentSourceMutation
} from '../../api/payments';

import type { AppPage } from '../../helpers/app.types';

const FinancialPage: AppPage = () => {
  const t = useTranslate('FinancialSettingsPage');

  return (
    <Page
      title={t('title')}
      breadcrumbs={[[t('general', { global: true }), '/'], t('title')]}
      description={t('description')}
    >
      <FinancialSettingsPage
        stripeApiKey={config.stripe.apiKey}
        useVerifyPrimaryPaymentSourceMutation={useVerifyPrimaryPaymentSourceMutation}
        useUpdatePrimaryPaymentSourceMutation={useUpdatePrimaryPaymentSourceMutation}
        useCreatePrimaryPaymentSourceMutation={useCreatePrimaryPaymentSourceMutation}
        useDeleteBackupPaymentSourceMutation={useDeleteBackupPaymentSourceMutation}
        useUpdateBackupPaymentSourceMutation={useUpdateBackupPaymentSourceMutation}
        useCreateBackupPaymentSourceMutation={useCreateBackupPaymentSourceMutation}
        useFetchPrimaryPaymentSourceQuery={useFetchPrimaryPaymentSourceQuery}
        useFetchBackupPaymentSourceQuery={useFetchBackupPaymentSourceQuery}
        useFetchFinancialSettingsStatusQuery={useFetchFinancialSettingsStatusQuery}
      />
    </Page>
  );
};

FinancialPage.meta = () => ({
  title: 'Financial Settings'
});

FinancialPage.when = ({ user }) => userHasPermission(user, 'paymentMethod:read', {
  orgId: user?.institution.id,
  ownerId: null
});

export default FinancialPage;
