import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import actions from '../../../actions';
import FormField from '../../Field';
import FormModal from '../../FormModal';
import { waitForModalToClose } from '../../Modal';


const {
  updateCourseSession,
  notify
} = actions;

export default function ChangeLastAddDateModal({
  session,
  show,
  onHide
}) {
  const onSubmit = async ({ lastAddDate }) => {
    await updateCourseSession(session.id, { lastAddDate });
    onHide();
    await waitForModalToClose();
    notify('The course session has been updated. Changes will take place in a couple of seconds. You\'ll have to refresh the page in order to see them.');
  };

  // Must be the same as in `ASP-API/sessions/update/index.js`.
  const validateLastAddDate = useCallback((lastAddDate) => {
    if (lastAddDate.getTime() > session.lastDropDate.getTime()) {
      return 'The "Add Date" must be earlier than or equal to the "Drop Date"';
    }

    // Support has requested to disable the "today" check.
    // https://github.com/Acadeum/Tickets/issues/1194#issuecomment-1145402397
    //
    // // Start/End/Add/Drop dates are in UTC+0 time zone.
    // // Therefore, to compare dates day-by-day, they'd first have
    // // to be normalized into UTC+0 time zone (both of the compared dates).
    // // "Last Add Date" is already in UTC+0, so "today"'s date also has to be
    // // converted to UTC+0 time zone. Comparing dates in different time zones
    // // simply wouldn't make sense.
    // //
    // const now = new Date();
    // const todayUtc0 = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate()));
    // if (lastAddDate.getTime() < todayUtc0.getTime()) {
    //   return 'The "Add Date" must be later than or equal to today';
    // }

    const DAY = 24 * 60 * 60 * 1000;
    if (lastAddDate.getTime() < session.startDate.getTime() - 20 * DAY) {
      return 'The "Add Date" must be later than or equal to "Start Date" minus 20 days';
    }
  }, [session]);

  return (
    <FormModal
      show={show}
      onHide={onHide}
      title="Change Last Add Date"
      description="Choose a new Last Add Date for the course session"
      submitText="Save"
      onSubmit={onSubmit}>
      <FormField
        required
        type="date"
        name="lastAddDate"
        label="Last Add Date"
        value={session.lastAddDate}
        validate={validateLastAddDate}
      />
    </FormModal>
  );
}

ChangeLastAddDateModal.propTypes = {
  session: PropTypes.object.isRequired,
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired
};
