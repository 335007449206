import React, { useCallback } from 'react';

import { useController } from 'react-hook-form';

import type { LexicalEditorProps } from '../../../LexicalEditor';
import { LexicalEditor } from '../../../LexicalEditor';

export const FormLexicalEditor = ({
  name,
  rules,
  defaultValue,
  ...rest
}) => {
  const { field } = useController({ name, rules, defaultValue });

  const onChange: LexicalEditorProps['onChange'] = useCallback((editorState) => {
    field.onChange(JSON.stringify(editorState.toJSON()));
  }, [field.onChange]);

  return (
    <LexicalEditor
      {...rest}
      {...field}
      initialValue={field.value}
      onChange={onChange}
    />
  );
};
