import classNames from 'classnames';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { FormatDate } from '@acadeum/ui';

import actions from '../../actions';
import Button from '../../components/Button';
import Link from '../../components/Link';
import Loading from '../../components/Loading';
import Section from '../../components/Section';
import Table from '../../components/Table';


import './SearchIndexUpdates.sass';

const {
  fetchSearchIndexUpdates
} = actions;

const DEFAULT_PAGE_SIZE = 20;

export default function SearchIndexUpdates() {
  const { searchIndexUpdates } = useSelector(state => state.searchIndexUpdates);

  // eslint-disable-next-line
  const [isLoading, setLoading] = useState();

  const [page, setPage] = useState(1);
  // eslint-disable-next-line
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const { totalCount } = searchIndexUpdates;
  const pageCount = Math.ceil(totalCount / pageSize);
  const pageNumbers = [];

  let i = 1;
  while (i <= pageCount) {
    pageNumbers.push(i);
    i++;
  }

  return (
    <Section>
      <Table
        title="Search Index Updates"
        data={searchIndexUpdates.results}
        totalRowsCount={searchIndexUpdates.totalCount}
        columns={COLUMNS}
        selectableRows={false}
      />

      <br/>

      <div className="Pagination">
        {pageNumbers.map((pageNumber) => (
          <Button
            key={pageNumber}
            onClick={() => setPage(pageNumber)}
            disabled={isLoading}
            style={{ marginTop: '0.5rem' }}
            className={classNames('Pagination-pageButton', {
              'Pagination-pageButton--selected': pageNumber === page
            })}>
            {pageNumber}
          </Button>
        ))}
      </div>

      <br/>

      {isLoading &&
        <Loading margin="0" width="16px" height="16px"/>
      }

      {!isLoading &&
        <div style={{ height: '16px' }}/>
      }
    </Section>
  );
}

SearchIndexUpdates.load = async () => {
  await fetchSearchIndexUpdates({ pageSize: DEFAULT_PAGE_SIZE });
};

SearchIndexUpdates.meta = () => ({
  title: 'Search Index Updates'
});

SearchIndexUpdates.breadcrumbs = () => [
  ['Admin Tools', '/admin'],
  'Search Index Updates'
];

const COLUMNS = [
  {
    title: '#',
    content: (row) => (
      <Link to={`/admin/search-index-updates/${row.id}`}>
        {row.id}
      </Link>
    )
  },
  {
    title: 'Index',
    content: (row) => (
      <code>
        {row.indexType}
      </code>
    )
  },
  {
    title: 'Status',
    content: (row) => {
      return (
        <div className={classNames('SearchIndexUpdates-status', {
          'SearchIndexUpdates-status--pending': row.status === 'STARTED',
          'SearchIndexUpdates-status--ok': row.status === 'FINISHED',
          'SearchIndexUpdates-status--error': row.status === 'ERROR' || row.status === 'TIMEOUT'
        })}>
          {row.status}
        </div>
      );
    }
  },
  {
    title: 'IDs',
    content: (row) => {
      if (row.objectIds) {
        return (
          <div>
            {row.objectIds.join(', ')}
          </div>
        );
      }
    }
  },
  {
    title: 'Reason',
    content: (row) => (
      <pre>{JSON.stringify(row.reason, null, 2)}</pre>
    )
  },
  // {
  //   title: 'Error',
  //   content: (row) => {
  //     if (row.error) {
  //       return (
  //         <pre>{JSON.stringify(row.error, null, 2)}</pre>
  //       )
  //     }
  //   }
  // },
  // {
  //   title: 'Requested At',
  //   content: (row) => {
  //     return <FormatDate date={row.createdAt} time utc={false}/>;
  //   }
  // },
  {
    title: 'Started At',
    content: (row) => {
      if (row.startedAt) {
        return <FormatDate date={row.startedAt} time utc={false}/>;
      }
    }
  }
  // {
  //   title: 'Ended At',
  //   content: (row) => {
  //     if (row.endedAt) {
  //       return <FormatDate date={row.endedAt} time utc={false}/>;
  //     }
  //   }
  // }
];
