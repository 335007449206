import { getDefaultMiddleware } from '@reduxjs/toolkit';

import { adminApi } from '@acadeum/api';
import { getErrorHandlerMiddleware } from '@acadeum/redux';

import { rtkApi } from '../api/rtkApi';

export const reduxMiddleware = getDefaultMiddleware({
  serializableCheck: false,
  immutableCheck: false
}).concat([
  rtkApi.middleware,
  adminApi.middleware,
  getErrorHandlerMiddleware()
]);
