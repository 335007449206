import { DocumentViewIcon } from '@acadeum/icons';
import { Accordion, Link, Text } from '@acadeum/ui';

export function LearningAssessmentsSection({
  course
}) {
  const assessments = course.learningAssessments;
  const hasAssessments = Boolean(assessments.length);

  let content;
  if (hasAssessments) {
    content = (
      assessments.map((assessment, index) => (
        <div key={index}>
          <Link
            external
            to={assessment.url}
          >
            <DocumentViewIcon className="control-icon control-icon--left"/>
            {assessment.name}
          </Link>
        </div>
      ))
    );
  } else {
    content = 'No learning assessments have been added to this course yet.';
  }

  return (
    <Accordion title="Learning Assessments" defaultOpen={hasAssessments}>
      <Text as="div" color="grey">
        {content}
      </Text>
    </Accordion>
  );
}
