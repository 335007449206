import { injectCourseEnrollmentEndpoints } from '@acadeum/api';

import { rtkApi } from './rtkApi';

export const {
  useFetchCourseEnrollmentsPropertyValuesQuery,
  useFetchCourseEnrollmentsQuery,
  useFetchCourseEnrollmentQuery,
  useLazyFetchCourseEnrollmentsQuery,
  useLazyAdminFetchEnrollmentQuery,
  useAdminFetchCourseEnrollmentsQuery,
  useAdminChangeCourseEnrollmentStatusMutation,
  useAdminMarkCourseEnrollmentAsConsortialMutation,
  useAdminMarkCourseEnrollmentAsUnpaidMutation,
  useWithdrawStudentsFromHomeCourseMutation,
  useDropStudentsFromHomeCourseMutation,
  useRemoveStudentsFromHomeCourseMutation,
  useApproveTeachingCourseEnrollmentMutation,
  useDenyTeachingCourseEnrollmentMutation,
  useCompleteTeachingCourseEnrollmentMutation,
  useWithdrawTeachingCourseEnrollmentMutation,
  useDropTeachingCourseEnrollmentMutation,
  useUpdateTeachingCourseEnrollmentGradeMutation
} = injectCourseEnrollmentEndpoints(rtkApi);
