import React, { createContext, useMemo } from 'react';

export interface RadioCardGroupContextProps {
  name?: string;
  selectedValue: string,
  onSelectValue: (value: string) => void;
  disabled?: boolean;
}

export const RadioCardGroupContext = createContext<RadioCardGroupContextProps | undefined>(undefined);

export interface RadioCardGroupProps {
  value: string;
  name?: string;
  children: React.ReactNode;
  onChange: (value: string) => void;
  disabled?: boolean;
}

const Group: React.FC<RadioCardGroupProps> = ({
  value,
  name,
  children,
  onChange,
  disabled
}) => {
  const context = useMemo(() => ({
    name,
    disabled,
    selectedValue: value,
    onSelectValue: onChange
  }), [
    disabled,
    name,
    value,
    onChange
  ]);

  return (
    <RadioCardGroupContext.Provider value={context}>
      {children}
    </RadioCardGroupContext.Provider>
  );
};

export default Group;
