import type { RowData } from '@tanstack/react-table';

import { Checkbox } from '../../../Checkbox';
import type { FilterItemObject } from '../types';

export const createTableSwitchFilter = <TData extends RowData>({
  id,
  label,
  getFilterValue
}: {
  getFilterValue: (row: TData) => boolean,
  label: string;
  id: string
}): FilterItemObject<TData> => {
  const getFilteredValue = (row: TData) => {
    if (!getFilterValue) {
      throw new Error();
    }
    return getFilterValue(row);
  };

  return {
    id: id,
    filter: (row, value) => getFilteredValue(row) === value,
    render({ getValue, setFilterValues }) {
      return (
        <Checkbox
          type="switch"
          label={label}
          checked={getValue() ?? false}
          onChange={(event) => {
            setFilterValues(prev => {
              const { checked } = event.target;
              const option = checked ? { value: checked, label } : null;
              return ({
                ...prev,
                [id]: option
              });
            });
          }}
        />
      );
    }
  };
};
