import type { FC } from 'react';
import React, { useCallback, useId, useMemo, useState } from 'react';

import { CaretDownIcon, CaretUpIcon } from '@acadeum/icons';
import type { Id, Options } from '@acadeum/types';

import { Icon } from '../../Icon';
import { InstitutionAutocomplete } from '../../InstitutionAutocomplete';
import { Popover, PopoverContent, PopoverTrigger } from '../../Popover';
import { Radio } from '../../Radio';

import type { FilterDefinition } from '../context';
import { useRegisterFilter } from '../context';

import styles from './CounterpartyFilter.module.scss';

type Value = null | Id[];

interface CounterpartyFilterDefinition extends FilterDefinition<Value> {
  options?: Options<Id>
}

interface CounterpartyFilterProps {
  name: string;
  label?: string;
  onChange?: (value: Value) => any;
}

export const CounterpartyFilter: FC<CounterpartyFilterProps> = ({
  name,
  label = 'Counterparty',
  onChange: propsOnChange
}) => {
  const id = useId();
  const radioName = id + 'radio';

  const [show, setShow] = useState<boolean>(false);

  const getFilterLabel = useCallback((value, item) => {
    const label = item?.options?.find(_ => _.value === value)?.label;
    if (label) {
      return label;
    }
    return value;
  }, []);

  const filterDefinition = useMemo(() => ({
    name,
    label,
    getFilterLabel,
    shouldShow: (item) => Boolean(item.value?.length)
  }), [
    name,
    label,
    getFilterLabel
  ]);

  const {
    value,
    onChange
  } = useRegisterFilter<Value, CounterpartyFilterDefinition>({
    propsOnChange,
    filter: filterDefinition
  });

  const isAcadeum = typeof value === 'undefined' || value === null;

  const onChangeMode = (mode: 'acadeum' | 'institution') => {
    if (mode === 'acadeum') {
      onChange(null, filterDefinition);
    } else {
      onChange([], filterDefinition);
    }
  };

  const onInstitutionChange = (institutionIds, options) => {
    onChange(institutionIds, { ...filterDefinition, options });
  };

  return (
    <Popover open={show} onOpenChange={setShow}>
      <PopoverTrigger className={styles.trigger}>
        <span>
          Counterparty
        </span>
        <Icon icon={show ? CaretUpIcon : CaretDownIcon} className={styles.dropdown__toggleIcon}/>
      </PopoverTrigger>
      <PopoverContent
        align="start"
        className={styles.content}
      >
        <Radio
          name={radioName}
          className={styles.radioWrap}
          label="Acadeum"
          value="Acadeum"
          checked={isAcadeum}
          onChange={() => onChangeMode('acadeum')}
        />
        <Radio
          name={radioName}
          className={styles.radioWrap}
          label="Other"
          value="Other"
          checked={!isAcadeum}
          onChange={() => onChangeMode('institution')}
        />
        {!isAcadeum && (
          <InstitutionAutocomplete
            noMargin
            multiple
            isFormField={false}
            className={styles.dropdown__institution}
            required={false}
            type="institution"
            value={value}
            onChange={onInstitutionChange}
          />
        )}
      </PopoverContent>
    </Popover>
  );
};
