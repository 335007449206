import classNames from 'classnames';
import React, { memo } from 'react';
import type { ReactNode } from 'react';

import { CircleExclamationSolidIcon } from '@acadeum/icons';

import { Icon } from '../Icon';

import styles from './ErrorMessage.module.scss';

export interface ErrorMessageProps {
  id?: string;
  className?: string;
  children: ReactNode;
  withIcon?: boolean;
}

export const ErrorMessage = memo<ErrorMessageProps>(({
  id,
  withIcon = true,
  children,
  className
}) => {
  return (
    <p
      id={id}
      className={classNames(className, styles.root, {
        [styles.withIcon]: withIcon
      })}
    >
      {withIcon && (
        <Icon icon={CircleExclamationSolidIcon} className={styles.icon}/>
      )}
      {children}
    </p>
  );
});
