import EnrollmentsTeaching from '../EnrollmentsTeaching';

const EnrollmentsTeachingAccepted = () => {
  return (
    <EnrollmentsTeaching/>
  );
};

EnrollmentsTeachingAccepted.meta = () => ({
  title: 'Accepted Students'
});

EnrollmentsTeachingAccepted.breadcrumbs = () => [
  ['Manage Enrollments', '/enrollments'],
  ['Teaching Enrollments', '/enrollments/teaching'],
  'Accepted'
];

export default EnrollmentsTeachingAccepted;
