import React, { useEffect, useId, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import courseLevels from 'common-lib/constants/courseLevels.json';

import { convertFileToBase64 } from '@acadeum/helpers';
import { PlusIcon } from '@acadeum/icons';
import { useTranslate } from '@acadeum/translate';
import type { CourseResource, CreateCourseResource } from '@acadeum/types';
import type { OnSubmit } from '@acadeum/ui';
import { Actions, Button, ContentSection, FormField, FormModal, FormRow, Label, Link, Separator } from '@acadeum/ui';

import { Mode } from '../helpers';

export interface CreateAcademicSectionValues {
  onDemand: boolean;
  level: string;
  hours: number;
  resources: CreateCourseResource[];
  // sections: {
  //   startDate: Date;
  //   endDate: Date;
  //   code: string;
  // }[];
}

export interface EditAcademicSectionValues {
  onDemand: boolean;
  level: string;
  hours: number;
}

interface AcademicSectionProps {
  mode: Mode;
}

const courseLevelsOptions = courseLevels.map((courseLevel) => ({
  value: courseLevel,
  label: courseLevel
}));

export function AcademicSection({
  mode
}: AcademicSectionProps) {
  const t = useTranslate('shared-admin-ui.CourseForm');
  const onDemand = useWatch({ name: 'onDemand' });

  return (
    <ContentSection title={t('academic.title')}>
      <FormField
        disabled={mode === Mode.EDIT}
        name="onDemand"
        type="switch"
        label={t('academic.onDemand.label')}
        helpText={t('academic.onDemand.helpText')}
      />
      <FormField
        required
        name="level"
        type="select"
        options={courseLevelsOptions}
        label={t('academic.level.label')}
        placeholder={t('academic.level.placeholder')}
      />
      {mode === Mode.CREATE && (
        <FormRow>
          <FormField
            required
            name="hours"
            type="number"
            label={t('academic.hours.label')}
            placeholder={t('academic.hours.placeholder')}
          />
          <FormField
            required
            name="sections.0.code"
            label={t('academic.section.label')}
            placeholder={t('academic.section.placeholder')}
          />
        </FormRow>
      )}
      {mode === Mode.EDIT && (
        <FormField
          required
          name="hours"
          type="number"
          label={t('academic.hours.label')}
          placeholder={t('academic.hours.placeholder')}
        />
      )}
      {mode === Mode.CREATE && !onDemand && (
        <>
          <FormRow>
            <FormField
              required
              name="sections.0.startDate"
              label={t('academic.startDate.label')}
              type="date"
            />
            <FormField
              required
              name="sections.0.endDate"
              label={t('academic.endDate.label')}
              type="date"
            />
          </FormRow>
        </>
      )}

      <SyllabusField/>

    </ContentSection>
  );
}

const FILE_UPLOAD_SYLLABUS_EXTENSIONS = ['.pdf'];

interface SyllabusFormValues {
  url?: string;
  file?: File;
}

// TODO: All this solution is bullshit. Someone must refactor this code
function SyllabusField() {
  const id = useId();

  const { getValues, setValue, trigger, watch } = useFormContext();

  // This is a workaround for a weird behavior of `react-hook-form`:
  // since there's no field with `name="resources"`, the `resources` field is "programmatic",
  // and because of that, when the user doesn't touch the syllabus, it doesn't include it
  // in `values` when submitting the form, even if it's present in `defaultValue`.
  // That's the weird behavior of `react-hook-form`.
  useEffect(() => {
    const resources = getValues('resources');
    setValue('resources', resources);
  }, []);

  // "When defaultValue is not defined, the first render of watch will return undefined because
  //  it is called before register. It's recommended to provide defaultValues at useForm to avoid
  //  this behaviour, but you can set the inline defaultValue as the second argument."
  const resources: CourseResource[] | undefined = watch('resources', getValues('resources'));
  const syllabusResource = resources?.find(_ => _.type === 'SYLLABUS');

  const [show, setShow] = useState(false);
  const [uploadType, setUploadType] = useState<'file' | 'url' | null>(null);

  const isEdit = Boolean(syllabusResource);

  const onSubmit: OnSubmit<SyllabusFormValues> = async ({ url, file }) => {
    if (!url && !file) {
      throw new Error('Please choose a file or enter a URL');
    }

    const syllabus = {
      type: 'SYLLABUS',
      file: file ? await convertFileToBase64(file) : undefined,
      url: file ? undefined : url
    };

    setValue('resources', [syllabus], { shouldDirty: true });
    void trigger('resources');

    onClose();
  };

  const onClose = () => {
    setShow(false);
    setUploadType(null);
  };

  const onChooseUrlOrFileFile = (type: 'file' | 'url') => (event) => {
    setUploadType(event.target.value ? type : null);
  };

  const syllabusResourceUploadFileUrl = syllabusResource && syllabusResource['file']
    ? `data:application/pdf;base64,${encodeURI(syllabusResource['file'].data)}`
    : undefined;

  const syllabusResourceUrl = syllabusResource && (syllabusResource.url || syllabusResourceUploadFileUrl);

  const openSyllabusResourceUploadFile = (event) => {
    // For some weird reason, target="blank" doesn't open the pdf in a new tab,
    // https://stackoverflow.com/questions/2805330/opening-pdf-string-in-new-window-with-javascript
    event.preventDefault();
    window.open()?.document.write(`<iframe width="100%" height="100%" src="${syllabusResourceUploadFileUrl}"></iframe>`);
  };

  // Initially, a syllabus was required for any course.
  // Then, several years later, that requirement was relaxed and a few institutions
  // were allowed to upload courses without a syllabus.
  // So strictly speaking, providing a Syllabus in this form is not allowed.
  // But perhaps Acadeum would prefer to "secretly" enforce adding a syllabus for most institutions.
  // Perhaps that's the rationale why the "Syllabus" field is marked as required here.
  return (
    <>
      <Label required htmlFor={id} mb="sm">
        Syllabus
      </Label>
      <ContentSection padding="md">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {syllabusResourceUrl ? (
            <>
              <Link
                icon="file"
                external
                to={syllabusResourceUrl}
                onClick={syllabusResourceUploadFileUrl ? openSyllabusResourceUploadFile : undefined}
              >
                View
                {' '}
                Syllabus
              </Link>

              <Actions
                variant="kebab"
                actions={[
                  {
                    title: 'Edit',
                    onClick: () => setShow(true)
                  }
                ]}/>
            </>
          ) : (
            <Button
              variant="text"
              icon={PlusIcon}
              onClick={() => setShow(true)}
            >
              Add Syllabus
            </Button>
          )}
        </div>

        <FormModal
          isNestedForm
          show={show}
          onHide={onClose}
          onSubmit={onSubmit}
          submitText={isEdit ? 'Update' : 'Add'}
          title={`${isEdit ? 'Edit' : 'Add'} Syllabus`}
        >
          <FormField
            type="file"
            name="file"
            label="Syllabus Document"
            disabled={uploadType === 'url'}
            accept={FILE_UPLOAD_SYLLABUS_EXTENSIONS}
            onChange={onChooseUrlOrFileFile('file')}
          />
          <Separator mt="none">or</Separator>
          <FormField
            noMargin
            name="url"
            type="url"
            disabled={uploadType === 'file'}
            onChange={onChooseUrlOrFileFile('url')}
            label="Syllabus URL"
          />
        </FormModal>
      </ContentSection>
    </>
  );
}
