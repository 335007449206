import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useEffect, useMemo, useState } from 'react';
import type { FC } from 'react';

import type { UseCreateBackupPaymentSourceMutation, UseUpdateBackupPaymentSourceMutation } from '@acadeum/api';
import { getErrorData } from '@acadeum/helpers';
import { useTranslate } from '@acadeum/translate';
import { Button, Form, FormFooter, FormSubmit, toast } from '@acadeum/ui';

export interface CreditCardFormProps {
  update?: boolean;
  onAfterSubmit?: () => void;
  onCancel?: () => void;
  useCreateBackupPaymentSourceMutation: UseCreateBackupPaymentSourceMutation;
  useUpdateBackupPaymentSourceMutation: UseUpdateBackupPaymentSourceMutation;
}

export const CreditCardForm: FC<CreditCardFormProps> = ({
  update,
  onAfterSubmit,
  onCancel,
  useUpdateBackupPaymentSourceMutation,
  useCreateBackupPaymentSourceMutation
}) => {
  const [createBackupPaymentSource] = useCreateBackupPaymentSourceMutation();
  const [updateBackupPaymentSource] = useUpdateBackupPaymentSourceMutation();

  const [valid, setValid] = useState(false);

  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

  const t = useTranslate('shared-admin-ui.CreditCardForm');

  const onSubmit = async () => {
    if (!valid) {
      return toast.error(t('detailsNotValid'));
    }

    const cardElement = elements?.getElement(CardElement);

    // https://stripe.com/docs/js/tokens_sources/create_source
    const result = cardElement && await stripe?.createSource(cardElement, { type: 'card' });

    if (result?.error) {
      // https://stripe.com/docs/api/errors
      console.error(result.error);
      return toast.error(result.error.message || t('addCardError'));
    }

    try {
      const sourceId = result?.source.id;
      if (sourceId) {
        await (update ? updateBackupPaymentSource : createBackupPaymentSource)({ sourceId }).unwrap();
      }

      if (cardElement) {
        cardElement.clear?.();
      }

      onAfterSubmit?.();

      toast.success(t('successMessage'));
    } catch (error: unknown) {
      console.error(error);
      const errorData = getErrorData(error);
      return toast.error(errorData.message);
    }
  };

  const onChange = (event) => {
    if (event && event.complete && !event.error) {
      setValid(true);
    } else {
      setValid(false);
    }
  };

  return (
    <Form onSubmit={onSubmit}>
      <CardElement
        options={options}
        onChange={onChange}
      />
      <FormFooter align="left">
        {onCancel && (
          <Button
            variant="secondary"
            onClick={onCancel}
          >
            {t('cancel', { global: true })}
          </Button>
        )}
        <FormSubmit>
          {t('update')}
        </FormSubmit>
      </FormFooter>
    </Form>
  );
};

function useOptions() {
  const fontSize = useResponsiveFontSize();
  return useMemo(() => ({
    style: {
      base: {
        fontSize,
        color: '#000',
        letterSpacing: '0.025em',
        fontFamily: 'Source Code Pro, monospace',
        padding: '15px',
        '::placeholder': {
          color: '#777c8c'
        }
      },
      invalid: {
        color: '#9e2146'
      }
    },
    hidePostalCode: true
  }), [fontSize]);
}

function useResponsiveFontSize() {
  const getFontSize = () => window.innerWidth < 450 ? '16px' : '18px';
  const [fontSize, setFontSize] = useState(getFontSize);

  useEffect(() => {
    const onResize = () => setFontSize(getFontSize());
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return fontSize;
}
