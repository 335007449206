import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connectCurrentRefinements } from 'react-instantsearch-dom';

import { formatCurrency, formatDate } from '../../helpers/format';

import { getDateForFromDateFilterValue, getDateForToDateFilterValue } from './InstantSearchDate';

import InstantSearchRefinementsClose from './InstantSearchRefinementsClear.svg';

import './InstantSearchRefinements.sass';

class InstantSearchRefinements extends Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    refine: PropTypes.func.isRequired,
    canRefine: PropTypes.bool,
    transformRefinements: PropTypes.func.isRequired,
    filterNames: PropTypes.object.isRequired,
    clearRefinementsButton: PropTypes.node,
    className: PropTypes.string
  };

  static defaultProps = {
    transformRefinements: ({ items }) => items,
    filterNames: {}
  };

  /**
   * Clears a refinement.
   * @param  {Object} refinement
   * @param  {string} refinement.attribute
   * @param  {*}      [refinement.value]
   * @param  {*[]}    [refinement.values]
   */
  clear({ value, itemsToClear }) {
    // `refine` seems to clear a refinement rather than "refine".
    // And `value` is a function called "clearState"
    // which takes `searchState` and returns cleared `searchState`.
    // Again, poor naming from `react-instantsearch` authors.
    const { refine } = this.props;

    // `itemsToClear` is an array of `item`s to be cleared.
    if (itemsToClear) {
      refine(itemsToClear);
    } else {
      refine(value);
    }
  }

  getFilterName(attribute) {
    const { filterNames } = this.props;
    return filterNames[attribute];
  }

  render() {
    const {
      transformRefinements,
      clearRefinementsButton,
      className
    } = this.props;

    const items = transformRefinements(this.props.items);

    if (items.length === 0) {
      return null;
    }

    return (
      <React.Fragment>
        <div className={classNames(className, 'InstantSearch-Refinements')}>
          {clearRefinementsButton}
          <div className="InstantSearch-RefinementsList">
            {items.map((item) =>
              (<span key={item.label}>
                {item.items ? this.renderList(item) : this.renderItem(item)}
              </span>)
            )}
          </div>
        </div>
        <hr className="InstantSearch-Line InstantSearch-Line--refinements"/>
      </React.Fragment>
    );
  }

  // Renders a multi-refinement.
  // E.g. renders a multi-checkbox list refinements.
  renderList(containerItem) {
    return containerItem.items.map((item) =>
      (<span key={item.label}>
        {this.renderItem({
          ...item,
          attribute: containerItem.attribute
        })}
      </span>)
    );
  }

  // Renders a singlular refinement.
  renderItem(item) {
    // `canRefine` seems to indicate whether any filters are set.

    // WAI-ARIA filter announcement.
    // Toggle filter refinement labels are the same as the filters' names.
    // "Multi Select" filter refinement labels though don't have the actual filter name in them,
    // and a "screen reader" user might not always get the correct context
    // just by hearing the selected value of a "Multi Select" filter.
    // That's the reason why `filterNames` are passed for such filters.
    const filterName = this.getFilterName(item.attribute);
    const filterDescription = item['aria-label'] || (filterName ? `${filterName}: ${item.label}` : item.label);

    return (
      <span
        title={filterDescription}
        className="InstantSearch-Refinement">
        {item.label}
        <button
          type="button"
          onClick={() => this.clear(item)}
          title={`Clear filter: ${filterDescription}`}
          className="rrui__button-reset InstantSearch-ClearRefinement">
          <InstantSearchRefinementsClose className="InstantSearch-ClearRefinementIcon"/>
        </button>
      </span>
    );
  }
}

export default connectCurrentRefinements(InstantSearchRefinements);

// Transforms default Algolia range refinements with stylized ones.
// E.g. "$0 – $1000", "Any Start Date - 01/01/2017".
export function transformRangeRefinements(originalItems, ranges) {
  let items = [];
  const dateRangeItems = [];

  // Using a `forEach` loop here to be able to
  // force the next outer loop from an inner `for` loop.
  originalItems.forEach((item) => {
    // If only one value is selected use "Any [Filter Name]" i.e.,
    // "Any Start Date" and "Any End Date" for dates and "Any Price" for price.

    for (const range of ranges) {
      if (range.type === 'date') {
        if (range.fromAttributeName === item.attribute) {
          return dateRangeItems.push(item);
        } if (range.toAttributeName && range.toAttributeName === item.attribute) {
          return dateRangeItems.push(item);
        }
      } else if (range.attribute === item.attribute) {
        const min = item.currentRefinement.min;
        const max = item.currentRefinement.max;
        const format = getRangeGaugeLabelFormatter(range);
        const fromLabel = min === undefined ? range.fromPlaceholder || 'Any' : format(min);
        const toLabel = max === undefined ? range.toPlaceholder || 'Any' : format(max);
        return items.push({
          ...item,
          'aria-label': `${range.title}: from ${fromLabel} to ${toLabel}`,
          label: `${fromLabel} — ${toLabel}`
        });
      }
    }

    items.push(item);
  });

  // Add date range refinements to the list.
  items = items.concat(createDateRangesItems(collectDateRanges(dateRangeItems, ranges)));

  return items;
}

/**
 * Unites individual refinements corresponding to the same date range.
 * @param  {Object[]} dateRanges
 * @return {Object[]} items
 */
function createDateRangesItems(dateRanges) {
  const items = [];
  for (const dateRange of dateRanges) {
    // Add "Start Date - End Date" refinement.
    let startLabel;
    let endLabel;
    const itemsToClear = [];
    if (dateRange.startDate) {
      startLabel = formatDate(getDateForFromDateFilterValue(dateRange.startDate), { utc: true });
      itemsToClear.push(dateRange.startDateRefinement);
    }
    if (dateRange.endDate) {
      endLabel = formatDate(getDateForToDateFilterValue(dateRange.endDate), { utc: true });
      itemsToClear.push(dateRange.endDateRefinement);
    }
    if (itemsToClear.length > 0) {
      const fromLabel = startLabel || dateRange.range.fromPlaceholder;
      const toLabel = endLabel || dateRange.range.toPlaceholder;
      const fromAriaLabel = dateRange.startDate
        ? formatDate(dateRange.startDate, { month: 'long', utc: true })
        : dateRange.range.fromPlaceholder;
      const toAriaLabel = dateRange.endDate
        ? formatDate(dateRange.endDate, { month: 'long', utc: true })
        : dateRange.range.toPlaceholder;
      items.push({
        itemsToClear,
        'aria-label': `${dateRange.range.title}: from ${fromAriaLabel} to ${toAriaLabel}`,
        label: `${fromLabel} — ${toLabel}`
      });
    }
  }
  return items;
}

/**
 * Collects individual refinements corresponding to the same date range.
 * @param  {Object[]} items
 * @param  {Object[]} ranges
 */
function collectDateRanges(items, ranges) {
  const dateRanges = [];
  for (const item of items) {
    ranges.forEach((range, i) => {
      // If it's a "Start Date" refinement.
      if (range.fromAttributeName === item.attribute) {
        dateRanges[i] = dateRanges[i] || { range };
        // "Start Date" refinement (can be `clear()`ed).
        dateRanges[i].startDateRefinement = item;
        // "Start Date" value (for label).
        dateRanges[i].startDate = item.currentRefinement.min;
      }
      // If it's an "End Date" refinement.
      else if (range.toAttributeName && range.toAttributeName === item.attribute) {
        const i = ranges.indexOf(range);
        dateRanges[i] = dateRanges[i] || { range };
        // "End Date" refinement (can be `clear()`ed).
        dateRanges[i].endDateRefinement = item;
        // "End Date" value (for label).
        dateRanges[i].endDate = item.currentRefinement.max;
      }
    });
  }
  return dateRanges.filter(_ => _);
}

function getRangeGaugeLabelFormatter(range) {
  if (range.formatGaugeLabel) {
    return range.formatGaugeLabel;
  }
  switch (range.type) {
    case 'currency':
      return formatCurrency;
    default:
      return _ => _;
  }
}

// function createSetDateRange(attribute, min, max) {
//   return function(searchState) {
//     return {
//       ...searchState,
//       range: {
//         ...searchState.ranges,
//         [attribute]: {
//           min,
//           max
//         }
//       }
//     };
//   };
// }

//
// function findFirstDateRangeItemForRange(dateRangeItems, range) {
//   for (const item of dateRangeItems) {
//     if (range.fromAttributeName === item.attribute ||
//       range.toAttributeName === item.attribute) {
//       return item;
//     }
//   }
// }
