import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState, useRef, useCallback } from 'react';

import { useUpdateEffect, useForwardedRef } from '@acadeum/hooks';
import { XMarkIcon, SearchIcon } from '@acadeum/icons';

import { Button } from '../Button';
import { Icon } from '../Icon';
import { Input } from '../Input';

import styles from './SearchBar.module.scss';

export interface SearchBarProps extends React.InputHTMLAttributes<HTMLInputElement> {
  loading?: boolean;
  clearLabel?: string;
  clearNone?: boolean;
  waitForIdle?: boolean;
  onValueChange?: (value: string) => void;
}

export const SearchBar = React.forwardRef<HTMLInputElement, SearchBarProps>(({
  value: value_ = '',
  onChange: onChange_,
  onValueChange,
  placeholder = 'Search',
  className,
  loading,
  clearLabel = 'CLEAR',
  clearNone,
  waitForIdle,
  ...rest
}, ref) => {
  const { setRef, internalRef } = useForwardedRef(ref);
  const [value, setValue] = useState(value_);

  useUpdateEffect(() => {
    setValue(value_);
  }, [value_]);

  const timer = useRef<NodeJS.Timeout>();

  const handleChange = useCallback((event) => {
    const inputValue = event.target.value;
    setValue(inputValue);

    if (onChange_ || onValueChange) {
      if (waitForIdle) {
        clearTimeout(timer.current);

        timer.current = setTimeout(() => {
          onChange_?.(event);
          onValueChange?.(inputValue);
        }, 300);
      } else {
        onChange_?.(event);
        onValueChange?.(inputValue);
      }
    }
  }, []);

  const onClear = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    // Set focus to input because otherwise it will be set to <body>
    internalRef.current.focus();
    const descriptor = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value');
    if (descriptor?.set) {
      descriptor.set.call(internalRef.current, '');
      internalRef.current.dispatchEvent(new Event('input', { bubbles: true }));
    }
  }, []);

  return (
    <div className={classNames(styles.SearchBar, className)}>
      <Input
        aria-label="Search"
        {...rest}
        ref={setRef}
        value={value}
        onChange={handleChange}
        loading={loading}
        placeholder={placeholder}
        addonPrefix={<Icon color="primary" icon={SearchIcon}/>}
        addonSuffix={
          value ? (
            <Button
              onClick={onClear}
              suffixIcon={XMarkIcon}
              variant="text-inline"
              className={styles.clearButton}
            >
              {!clearNone && (
                <span className={styles.clearText}>
                  {clearLabel}
                </span>
              )}
            </Button>
          ) : undefined
        }
      />
    </div>
  );
});

SearchBar.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  loading: PropTypes.bool,
  clearLabel: PropTypes.string,
  clearNone: PropTypes.bool,
  waitForIdle: PropTypes.bool
};
