import classNames from 'classnames';
import type { FC, Ref } from 'react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { isObject } from '@acadeum/helpers';
import { useIsMounted } from '@acadeum/hooks';
import { CaretDownIcon, CaretUpIcon, CheckIcon } from '@acadeum/icons';
import type { Option, Options } from '@acadeum/types';

import { EMPTY_VALUE, useFilteredOptions } from '../../utils/useFilteredOptions';

import { BaseButton } from '../BaseButton';
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from '../Command';
import type { FormProps, OnSubmit } from '../Form';
import { Form } from '../Form';
import { Icon } from '../Icon';
import { useModalSelect } from '../Modal';
import { Popover, PopoverContent, PopoverTrigger } from '../Popover';
import { SearchBar } from '../SearchBar';

import { Badge } from './Badge';
// import { Tooltip } from '../Tooltip';

import styles from './Select.module.scss';

const COUNT_LIMIT = 2;

type SupportedValue = string | number | boolean | null;
type EmptyValue = typeof EMPTY_VALUE;

/**
 * ```
 * There cant be `any` type it's only for preventing type errors in `onSelect` callback
 * SingleSelectValue = V | EmptyValue
 * MultiSelectValue = (V | EmptyValue)[] | EmptyValue
 * ```
 * */
// type Value<V> = V | (V | EmptyValue)[] | EmptyValue;
type Value<V> = V | (V | EmptyValue)[] | EmptyValue | any;

export interface SelectProps<V> {
  id?: string;
  className?: string;
  style?: React.CSSProperties;
  multiple?: boolean;
  value?: Value<V | null>;
  onSelect?: (value: V | EmptyValue) => void;
  onChange?: (value: Value<V | null>, selectedOptionOrOptions: Option<V | null> | Options<V | null>, prevValue?: Value<V | null>) => void;
  options?: Options<V | null>;
  onBlur?: () => void;
  name?: string;
  label?: string;
  placeholder?: string;
  error?: string | boolean;
  disabled?: boolean;
  readOnly?: boolean;

  // Fetching props
  async?: boolean;
  isLoading?: boolean;
  findOptionByValue?: (value: V) => Promise<Option<V>>;
  fetchOptions?: (search: string) => Promise<Options<V>>;

  // Customization
  // TODO: Remove `isFilter` prop and use `label` to render label instead of default button children content
  /** Render label instead of default button children content */
  isFilter?: boolean;
  /** Add button on bottom of the dialog */
  action?: ActionButtonProps;
  /** Add form and button to create new option */
  createOptionProps?: {
    label: string;
    children: FormProps['children'];
    onSubmit: (values: any) => Promise<Option<V>>;
  };
  tooltipProps?: {
    id: string;
    content: React.ReactNode;
  },
  formatOptionLabel?: (option: Option<V | null>) => React.ReactNode;
}

type SelectRef = Ref<HTMLButtonElement>;

const SelectComponent = <V extends SupportedValue = SupportedValue>({
  id,
  className,
  style,
  multiple,
  value: propsValue = EMPTY_VALUE,
  onBlur,
  onSelect: propsOnSelect,
  onChange: propsOnChange,
  placeholder = 'Select an Option',
  async,
  fetchOptions: propsFetchOptions,
  findOptionByValue: propsFindOptionByValue,
  isLoading: propsIsLoading = false,
  options: propsOptions = [],
  formatOptionLabel = option => option.label,
  action,
  createOptionProps,
  error,
  disabled: propsDisabled,
  readOnly,
  name,
  isFilter,
  label,
  tooltipProps
}: SelectProps<V>, ref: SelectRef) => {
  const disabled = propsDisabled || readOnly;

  const isMounted = useIsMounted();

  const useSearch = useMemo(() => async ? true : propsOptions.length > 8, [propsOptions, async]);

  const {
    options, isLoading, fetchOptions, findOptionByValue, findOptionsByValues
  } = useFilteredOptions({
    async,
    fetchOptions: propsFetchOptions,
    findOptionByValue: propsFindOptionByValue,
    isLoading: propsIsLoading,
    options: propsOptions
  });

  const once = useRef(false);
  const once2 = useRef(false);

  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [showAddNewOptionForm, setShowAddNewOptionForm] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState<Options<V | null>>([]);

  useEffect(() => {
    async function fetchOptionByValue() {
      if (isMounted()) {
        if (multiple) {
          if (Array.isArray(propsValue) && propsValue.length > 0) {
            await findOptionsByValues(propsValue);
          }
        } else {
          if (propsValue !== EMPTY_VALUE) {
            await findOptionByValue(propsValue);
          }
        }
      }
    }

    if (!once.current) {
      void fetchOptionByValue();
      once.current = true;
    }
  }, [multiple, isMounted, propsValue, findOptionByValue, findOptionsByValues]);

  useEffect(() => {
    if (!once2.current) {
      const newSelectedOptions = Array.isArray(propsValue)
        ? options.filter(_ => propsValue.includes(_.value))
        : options.filter(_ => _.value === propsValue);

      // Sync once after component initialization
      if (Array.isArray(propsValue) ? propsValue.length === newSelectedOptions.length : newSelectedOptions.length === 1) {
        once2.current = true;
        setSelectedOptions(newSelectedOptions);
      }
    }
  }, [options, propsValue]);

  const modalSelectContext = useModalSelect();

  const onOpenChange = useCallback((open: boolean) => {
    setOpen(open);
    if (open) {
      modalSelectContext?.onOpenCombobox();
    } else {
      modalSelectContext?.onCloseCombobox();
      setShowAddNewOptionForm(false);
      onBlur?.();
    }
  }, [onBlur, setShowAddNewOptionForm, setOpen, modalSelectContext]);

  const onSearchChange = useCallback((search: string) => {
    setSearch(search);
    fetchOptions(search);
  }, [fetchOptions]);

  const onSelect = useCallback((newSelectedOption: Option<V | null>) => {
    const prevValue = propsValue;
    const optionValue = newSelectedOption.value;
    propsOnSelect?.(optionValue);
    if (multiple) {
      const newValues = Array.isArray(prevValue)
        ? prevValue.includes(optionValue) ? prevValue.filter(_ => _ !== optionValue) : [...prevValue, optionValue]
        : [optionValue];
      const newSelectedOptions = selectedOptions.some(_ => _.value === optionValue)
        ? selectedOptions.filter(_ => _.value !== optionValue)
        : selectedOptions.concat(newSelectedOption);
      propsOnChange?.(newValues, newSelectedOptions, prevValue);
      setSelectedOptions(newSelectedOptions);
    } else {
      propsOnChange?.(optionValue, newSelectedOption, prevValue);
      setSelectedOptions([newSelectedOption]);
      onOpenChange(false);
    }
  }, [multiple, onOpenChange, propsOnSelect, propsValue, setSelectedOptions, selectedOptions, propsOnChange]);

  const onSubmitCreateNewOption = useCallback<OnSubmit>(async (values, { clearForm }) => {
    const option = await createOptionProps?.onSubmit(values);
    if (!isObject(option)) {
      throw new Error('option should be object');
    }
    if (!('value' in option && 'label' in option)) {
      throw new Error('option must have "value" and "label" keys');
    }
    onSelect(option);
    clearForm();
    setShowAddNewOptionForm(false);
  }, [createOptionProps, onSelect]);

  const onCommandKeyDown = useCallback<React.KeyboardEventHandler<HTMLElement>>((event) => {
    if (multiple && event.key === 'Backspace' && search.length === 0) {
      const lastSelectedOption = selectedOptions[selectedOptions.length - 1];
      if (lastSelectedOption) {
        onSelect(lastSelectedOption);
      }
    }
  }, [search, multiple, onSelect, selectedOptions]);

  const buttonContent = useMemo(() => {
    let children: React.ReactNode = null;

    if (isFilter && label) {
      return label;
    }

    if (multiple) {
      // If open render all badges, else render only the first one and box with + {count}
      const first2SelectedOptions = selectedOptions.slice(0, COUNT_LIMIT);
      const badges = open ? selectedOptions : first2SelectedOptions;
      if (badges.length > 0) {
        const numberOfHiddenBadges = selectedOptions.length - COUNT_LIMIT;
        children = (
          <>
            <div className={styles.tags}>
              {badges.map((option, index) => (
                <Badge
                  key={`tag-${index}`}
                  label={option.label}
                  onRemove={() => onSelect(option)}
                />
              ))}
            </div>
            {!open && numberOfHiddenBadges > 0 && (
              <span className={styles.numberOfHiddenTags}>
                + {numberOfHiddenBadges}
              </span>
            )}
          </>
        );
      }
    } else {
      children = selectedOptions.map(formatOptionLabel);
    }

    if (!children || React.Children.count(children) === 0) {
      children = placeholder;
    }

    return children;
  }, [
    open,
    onSelect,
    multiple,
    placeholder,
    selectedOptions
  ]);

  return (
    <Popover
      open={open}
      onOpenChange={onOpenChange}
    >
      <PopoverTrigger asChild>
        <BaseButton
          ref={ref}
          id={id}
          name={name}
          role="combobox"
          disabled={disabled}
          aria-label={typeof buttonContent === 'string' ? buttonContent : undefined}
          aria-expanded={open}
          aria-invalid={Boolean(error)}
          aria-describedby={tooltipProps ? tooltipProps.id : undefined}
          data-active={selectedOptions.length > 0}
          className={classNames(className, styles.button, {
            [styles.multiple]: multiple,
            [styles.white]: isFilter
          })}
          style={style}
        >
          <span className={styles.buttonContent}>
            {buttonContent}
          </span>
          <Icon icon={open ? CaretUpIcon : CaretDownIcon} className={styles.caretIcon}/>
        </BaseButton>
      </PopoverTrigger>
      {tooltipProps && (
        <span className={styles.errorTooltip}>
          {tooltipProps.content}
        </span>
      )}
      <PopoverContent
        asChild
        align="start"
        className={styles.dialog}
      >
        <Command
          onKeyDown={onCommandKeyDown}
          shouldFilter={!async}
        >
          {createOptionProps && showAddNewOptionForm ? (
            <Form
              autoFocus
              isNestedForm
              className={styles.form}
              onSubmit={onSubmitCreateNewOption}
              children={createOptionProps.children}
            />
          ) : (
            <>
              {useSearch && (
                <div className={styles.inputWrapper}>
                  <CommandInput
                    asChild
                    value={search}
                    onValueChange={onSearchChange}
                    placeholder="Search an option"
                  >
                    <SearchBar/>
                  </CommandInput>
                </div>
              )}
              <CommandList>
                <CommandEmpty className={styles.empty}>
                  {isLoading ?
                    'Loading...'
                    : search.length > 0
                      ? `No option found for "${search}"`
                      : 'No option found. Try to search something.'}
                </CommandEmpty>
                <CommandGroup className={styles.group}>
                  {!isLoading && (options.map((option) => (
                    <ComboboxOption
                      key={`option-${option.value}`}
                      comboboxValue={propsValue}
                      option={option}
                      multiple={multiple}
                      formatOptionLabel={formatOptionLabel}
                      onSelect={() => onSelect(option)}
                    />
                  )))}
                </CommandGroup>
              </CommandList>
            </>
          )}
          {createOptionProps && !showAddNewOptionForm && (
            <ActionButton
              content={createOptionProps.label}
              onClick={() => setShowAddNewOptionForm(true)}
            />
          )}
          {action && (
            <ActionButton {...action} />
          )}
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export const Select = React.forwardRef(SelectComponent) as typeof SelectComponent;

interface ComboboxOptionProps<V> {
  multiple?: boolean;
  comboboxValue: Value<V>;
  option: Option<V>;
  formatOptionLabel: (option: Option<V>) => React.ReactNode;
  onSelect: (value: string) => void;
}

const ComboboxOption = <V extends SupportedValue = SupportedValue>({
  comboboxValue,
  multiple,
  onSelect,
  option,
  formatOptionLabel
}: ComboboxOptionProps<V>) => {
  const { value, count, disabled, description } = option;
  const active = multiple && Array.isArray(comboboxValue)
    ? comboboxValue.includes(value)
    : comboboxValue === value;
  return (
    <CommandItem
      className={classNames({
        [styles.singleOption]: !multiple,
        [styles.multiOption]: multiple
      })}
      onSelect={onSelect}
      data-multiple={Boolean(multiple)}
      data-active={active}
      disabled={Boolean(disabled)}
    >
      <div className={styles.optionHeader}>
        {multiple && (
          <span className={styles.multiCheckbox}>
            {active && <Icon icon={CheckIcon}/>}
          </span>
        )}
        <span className={styles.optionLabel}>
          {formatOptionLabel(option)}
        </span>
        {typeof count === 'number' && (
          <span className={styles.optionCount}>
            {count.toLocaleString()}
          </span>
        )}
        {!multiple && active && (
          <Icon
            icon={CheckIcon}
            className={styles.singleCheckIcon}
          />
        )}
      </div>
      {description && (
        <span className={styles.optionDescription}>
          {description}
        </span>
      )}
    </CommandItem>
  );
};

interface ActionButtonProps {
  content: string;
  onClick?: () => void;
}

const ActionButton: FC<ActionButtonProps> = ({ content, onClick }) => (
  <BaseButton
    className={styles.action}
    onClick={onClick}
  >
    {content}
  </BaseButton>
);
