import type { FC } from 'react';
import React from 'react';

import type { FetchPrimaryPaymentSourceOutput } from '@acadeum/api';
import { useTranslate } from '@acadeum/translate';
import { Button } from '@acadeum/ui';

import { useFinancialNavigationContext } from '../../context';

import { BankAccountForm } from '../BankAccountForm';
import type { BankAccountInfoProps } from '../BankAccountInfo';
import { BankAccountInfo } from '../BankAccountInfo';
import { StepWrapper } from '../StepWrapper';

export interface BankAccountStepProps extends Pick<BankAccountInfoProps, 'useCreatePrimaryPaymentSourceMutation' | 'useUpdatePrimaryPaymentSourceMutation'> {
  primaryPaymentSource?: FetchPrimaryPaymentSourceOutput;
}

export const BankAccountStep: FC<BankAccountStepProps> = ({
  primaryPaymentSource,
  useCreatePrimaryPaymentSourceMutation,
  useUpdatePrimaryPaymentSourceMutation
}) => {
  const { changeView } = useFinancialNavigationContext();
  const t = useTranslate('shared-admin-ui.BankAccountStep');

  const primaryPayment = primaryPaymentSource?.current;

  const nextStep = () => changeView({ activeStep: 1 });

  return (
    <StepWrapper
      title={t('title')}
      description={t('description')}
    >
      {primaryPayment ? (
        <>
          <BankAccountInfo
            type="current"
            primaryPaymentSource={primaryPaymentSource}
            useCreatePrimaryPaymentSourceMutation={useCreatePrimaryPaymentSourceMutation}
            useUpdatePrimaryPaymentSourceMutation={useUpdatePrimaryPaymentSourceMutation}
          />
          <div style={{ marginTop: '1rem', display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={nextStep}>
              {t('continue', { global: true })}
            </Button>
          </div>
        </>
      ) : (
        <BankAccountForm
          type="current"
          cancelText="skip"
          onAfterSubmit={nextStep}
          onCancel={nextStep}
          useUpdatePrimaryPaymentSourceMutation={useUpdatePrimaryPaymentSourceMutation}
          useCreatePrimaryPaymentSourceMutation={useCreatePrimaryPaymentSourceMutation}
        />
      )}
    </StepWrapper>
  );
};
