import type { ColumnDef } from '@tanstack/react-table';
import type { MutableRefObject } from 'react';
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { parseExcelDate } from 'read-excel-file';

import type { FileDataImportErrorInput, UseFileDataImportErrorMutation } from '@acadeum/api';
import { getErrorData } from '@acadeum/helpers';
import { useTranslate } from '@acadeum/translate';
import type { AlertProps, FileUploadRef, TableProps } from '@acadeum/ui';
import {
  Alert,
  Blank,
  Checkbox,
  DataBlock,
  DownloadButton,
  FileUpload,
  FormatDate,
  ShowMoreWrapper,
  Table,
  Tag,
  Tooltip,
  toast,
  createTableId
} from '@acadeum/ui';

import { StickyFooter } from '../StickyFooter';

import styles from './DataUploadPage.module.scss';
import type { ParseFileReturn } from './lib/parseFile';
import { parseFile } from './lib/parseFile';


export interface DataUploadPageRef {
  removeUploadedFile: () => void;
}

type ExpectedResult = { status: string | 'CREATED' | 'UPDATED' | 'UNCHANGED' | 'DUPLICATE' }[];

export interface DataUploadPageCache<T = object[]> {
  fileName?: string;
  hasError?: boolean;
  columns: ColumnDef<unknown>[];
  ignoredColumns?: string[];
  tableData?: ParseFileReturn['tableData'];
  parsedData: ParseFileReturn<T>['parsedData'];
  expectedResult?: ExpectedResult;
}

type CacheOptions = [(DataUploadPageCache | undefined), React.Dispatch<React.SetStateAction<DataUploadPageCache | undefined>>];

export interface DataUploadPageProps {
  type: 'UPLOAD_COURSES' | 'UPLOAD_STUDENTS' | 'UPLOAD_COURSE_APPROVALS' | 'UPLOAD_MAPPINGS' | 'UPLOAD_LEARNING_PROGRAMS';
  templateLink: string;
  schema: NonNullable<unknown>;
  getColumnSchema?: (column) => object;
  columnPinningRight?: TableProps<unknown>['columnPinningRight'];
  columnPinningLeft?: TableProps<unknown>['columnPinningLeft'];
  cacheOptions?: CacheOptions;
  onBack?: () => void;
  submitText?: string;
  transformRows?: (rows: object[]) => object[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onUpload: (rows: any[]) => Promise<any> | Promise<void> | void;
  useFileDataImportErrorMutation: UseFileDataImportErrorMutation;
  validate?: (data: ParseFileReturn) => Promise<FileDataImportErrorInput['errors']>;
  getExpectedResult?: ({ rows }: { rows: object[] }) => Promise<ExpectedResult>;
  onShowDetailsModal?: (value: true) => void;
  preventSuccessToast?: boolean;
}

export const DataUploadPage = forwardRef<DataUploadPageRef, DataUploadPageProps>(({
  type,
  onShowDetailsModal,
  templateLink,
  schema,
  cacheOptions,
  columnPinningRight = ['status'],
  columnPinningLeft,
  transformRows = rows => rows,
  onUpload,
  getColumnSchema,
  submitText,
  onBack,
  useFileDataImportErrorMutation,
  preventSuccessToast,
  validate,
  getExpectedResult
}, ref) => {
  const t = useTranslate('shared-admin-ui.DataUploadPage');
  const [fileDataImportError] = useFileDataImportErrorMutation();

  const cacheState = useState<DataUploadPageCache>();
  const [cache, updateCache] = cacheOptions || cacheState;
  const [errorsOnlyData, setErrorsOnlyData] = useState<ParseFileReturn['tableData']>([]);

  const [fileUploadError, setFileUploadError] = useState<string>();

  const updatedRecordsCount = cache?.expectedResult?.filter(_ => _.status === 'UPDATED').length;
  const fileUploadAlertMessage = updatedRecordsCount && t('hasUpdatedMessage', { count: updatedRecordsCount });
  const rows = cache?.parsedData.rows || [];
  const columns = cache?.columns || [];
  const tableData = cache?.tableData || [];
  const ignoredColumns = cache?.ignoredColumns || [];
  const hasError = cache?.hasError || false;
  const fileName = cache?.fileName || '';
  const uniqErrorsIds: number[] = getUniqErrorsIds() || [];

  const fileUploadRef = useRef() as MutableRefObject<FileUploadRef>;
  const rawLoadedDataRef = useRef<ParseFileReturn>();

  const resetState = (resetFile = false) => {
    updateCache(undefined);
    setFileUploadError(undefined);
    if (resetFile) {
      fileUploadRef.current.reset();
    }
  };

  useImperativeHandle(ref, () => {
    return {
      removeUploadedFile: () => resetState(true)
    };
  });

  const sendEmailWithErrors = async (
    fileName: string,
    parsedErrors: FileDataImportErrorInput['errors']
  ) => {
    try {
      await fileDataImportError({
        type,
        fileName: fileName,
        errors: parsedErrors
      });
    } catch (error: unknown) {
      console.error(error);
      toast.error('Failed to send email with errors');
    }
  };

  const onFileChosen = async (file) => {
    resetState();

    const parseFileReturn = await parseFile(file, { schema });

    if (parseFileReturn.parsedData.rows.length === 0) {
      return setFileUploadError(t('noData'));
    }

    const validatedErrors = await validate?.(parseFileReturn) ?? [];

    const { tableData, parsedData, headerRow } = parseFileReturn;
    const parsedRows = transformRows(parsedData.rows);
    const parsedErrors = parsedData.errors.concat(validatedErrors);
    const hasError = parsedErrors.length > 0;

    let expectedResult: DataUploadPageCache['expectedResult'] = [];

    rawLoadedDataRef.current = parseFileReturn;

    if (hasError) {
      // Ignore awaiting promise here, as don't want to block the UI
      // and optimistically assume the email will be sent.
      void sendEmailWithErrors(file.name, parsedErrors);
      setFileUploadError(t('errorMessage'));
    } else {
      expectedResult = await getExpectedResult?.({ rows: parsedRows });
    }

    // When a column has an empty header, the corresponding element
    // in `columns` array is gonna be `null`.
    // Passing `null` as a child of `<List.Item/>` will output a React warning
    // so it replaces `null` with "<No Header>" string.
    //
    // To work around that, assign some placeholder value to `null` (empty) column titles.
    //
    // https://github.com/Acadeum/Tickets/issues/1560
    const _headerRow = headerRow.map((header, i) => header || `<#${i + 1}>`);

    const ignoredColumns = getIgnoredColumns(_headerRow, schema);
    const columns = parseHeaders({
      errors: parsedErrors,
      getColumnSchema,
      headerRow: _headerRow,
      expectedResult,
      schema,
      t
    });

    updateCache({
      fileName: file.name,
      hasError,
      ignoredColumns,
      columns,
      tableData,
      parsedData: {
        ...parsedData,
        errors: parsedErrors
      },
      expectedResult
    });
  };

  const onUploadData = async () => {
    if (!onUpload) {
      return;
    }

    try {
      let message;
      const result = await onUpload(rows);
      if (preventSuccessToast) {
        return;
      }
      if (result && result.message) {
        message = result.message;
      } else if (typeof result?.count === 'number') {
        message = t('successMessage', { count: rows.length });
      } else {
        message = t('fileSuccessfullyProcessed');
        if (typeof result?.count === 'object') {
          const count = Object.keys(result.count).map(key => `${result.count[key]} ${key}`).join(', ');
          message = t('successMessage', { count });
        }
      }
      toast.success(message);
    } catch (error: unknown) {
      const { message } = getErrorData(error);
      toast.error(t('errorMessageApi', { message }));
    }
  };

  function getUniqErrorsIds() {
    return cache?.parsedData.errors.reduce((uniqueIds: number[], element) => {
      if (!uniqueIds.includes(element['row'])) {
        uniqueIds.push(element['row']);
      }
      return uniqueIds;
    }, []);
  }

  const alertProps: AlertProps = hasError ? {
    variant: 'error',
    dismissible: false,
    children: t('errorMessageDetails', { count: uniqErrorsIds?.length })
  } : {};

  const onShowErrorOnly = () => {
    const errorsOnly: ParseFileReturn['tableData'] = [];
    if (errorsOnlyData.length === 0) {
      const recalculatedErrors: ParseFileReturn['parsedData']['errors'] = [];
      uniqErrorsIds.map(id => {
        for (let i = 0; i < tableData.length; i++) {
          if (i === id - 2) {
            errorsOnly.push(tableData[i]);

            if (cache?.parsedData.errors.length) {
              for (let j = 0; j < cache.parsedData.errors.length; j++) {
                if (cache?.parsedData.errors[j].row === id) {
                  recalculatedErrors.push({
                    ...cache?.parsedData.errors[j],
                    row: errorsOnly.length + 1
                  });
                }
              }
            }
          }
        }
      });

      const columns = parseHeaders({
        errors: recalculatedErrors,
        getColumnSchema,
        headerRow: rawLoadedDataRef?.current?.headerRow,
        expectedResult: [],
        schema,
        t
      });

      updateCache(prevState => ({
        ...prevState,
        columns,
        parsedData: {
          rows: prevState ? prevState.parsedData.rows : [],
          errors: recalculatedErrors
        }
      }));

      setErrorsOnlyData(errorsOnly);
    } else {
      const columns = parseHeaders({
        errors: rawLoadedDataRef?.current?.parsedData.errors,
        getColumnSchema,
        headerRow: rawLoadedDataRef?.current?.headerRow,
        expectedResult: [],
        schema,
        t
      });

      updateCache(prevState => ({
        ...prevState,
        columns,
        parsedData: {
          rows: prevState ? prevState.parsedData.rows : [],
          errors: rawLoadedDataRef?.current?.parsedData.errors || []
        }
      }));

      setErrorsOnlyData([]);
    }
  };

  return (
    <div>
      <DownloadButton url={templateLink}>
        {t('downloadTemplate')}
      </DownloadButton>

      <FileUpload
        ref={fileUploadRef}
        className={styles.DataUploadPage__dropZone}
        children={t('fileUploadText')}
        fileName={fileName}
        accept={[
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'text/csv'
        ]}
        uploadButtonTitle={t('uploadButtonTitle')}
        removeButtonTitle={t('removeButtonTitle')}
        onChange={onFileChosen}
        onReset={resetState}
        alertProps={fileUploadError ? {
          variant: 'error',
          children: fileUploadError
        } : onShowDetailsModal ? {
          show: Boolean(fileUploadAlertMessage),
          variant: 'info',
          children: fileUploadAlertMessage,
          action: {
            content: t('seeDetails'),
            onClick: () => onShowDetailsModal?.(true)
          }
        } : undefined}
      />

      {ignoredColumns.length > 0 && (
        <Alert className={styles.DataUploadPage__warning} variant="warn">
          {t('ignoredColumns', { columns: ignoredColumns.join(', ') })}
        </Alert>
      )}

      {rows.length > 0 && (
        <>
          <Table
            id={createTableId('DataUploadPage')}
            alertProps={alertProps}
            className={styles.DataUploadPage__table}
            columns={columns}
            data={errorsOnlyData.length ? errorsOnlyData : tableData}
            columnPinningRight={columnPinningRight}
            columnPinningLeft={columnPinningLeft}
            meta={{
              getRowHasError: ({ row }) => {
                const rowHasError = uniqErrorsIds.find(id => Number(row.id) === id - 2);
                return Boolean(rowHasError);
              }
            }}
            renderTopLeftToolbarCustomActions={() => {
              if (hasError) {
                return (
                  <Checkbox
                    type="switch"
                    label={t('showErrorOnly')}
                    onChange={onShowErrorOnly}
                  />
                );
              }

              return;
            }}
          />

          {!hasError && (
            <StickyFooter
              cancelProps={{ onClick: () => resetState() }}
              backProps={onBack ? { onClick: onBack } : undefined}
              submitProps={{ children: submitText, onClick: onUploadData }}
            />
          )}
        </>
      )}
    </div>
  );
});

function createHeaderColumnDescriptor(columnName, index, errors, getColumnSchema, t) {
  const schema = getColumnSchema(columnName);
  const getCellError = (row) => {
    return schema && errors.filter(error => error.column === columnName && error.row === row.index + 2)[0];
  };
  return {
    id: columnName,
    header: columnName,
    accessorFn: row => row[index],
    cell: ({ row, getValue }) => {
      let value = getValue();
      if (schema) {
        if (schema.parse) {
          try {
            value = schema.parse(value);
          } catch (error) {
            console.error(error);
            value = '-';
          }
        }
        if (schema.type === Date) {
          if (typeof value === 'number') {
            value = parseExcelDate(value);
          }
        }
      }
      if (value instanceof Date) {
        value = <FormatDate date={value} utc/>;
      }
      // React renders `false` as empty.
      if (typeof value === 'boolean') {
        value = value.toString();
      }
      if (typeof value === 'string' && value) {
        value = <ShowMoreWrapper>{value}</ShowMoreWrapper>;
      }

      const error = getCellError(row);

      if (error) {
        let key = error.reason ? error.reason : error.error;

        const match = key.match(/(min_date|max_date)_(.*)/);
        if (match) {
          key = match[1];
        }
        const date = match ? new Date(match[2]) : null;

        return (
          <Tooltip content={t(`tooltip.${key}`, {
            lineBreak: () => <br/>,
            date
          })}>
            {error.error === 'required' ? (
              <DataBlock type="error" message={error.error}/>
            ) : (
              <DataBlock type="error" children={value}/>
            )}
          </Tooltip>
        );
      }

      return value || <Blank/>;
    },
    meta: {
      getCellHasError: ({ row }) => Boolean(getCellError(row))
    }
  };
}

export function getIgnoredColumns(columns, schema) {
  columns = columns.slice();
  removeInSchemaColumns(columns, schema);
  return columns;
}

function removeInSchemaColumns(columns, schema) {
  for (let property of Object.keys(schema)) {
    if (typeof schema[property].type === 'object') {
      removeInSchemaColumns(columns, schema[property].type);
    } else {
      // Remove an optional "required" asterisk ("*") at the end.
      property = property.replace(/\*$/, '');
      const index = columns.indexOf(property);
      if (index >= 0) {
        columns.splice(index, 1);
        if (columns.indexOf(property) >= 0) {
          console.error(`Duplicate column "${property}" found in the uploaded file`);
        }
      }
    }
  }
}

function parseHeaders({
  headerRow,
  errors,
  expectedResult,
  getColumnSchema,
  schema,
  t
}) {
  const headers = headerRow.map((columnName, index) => {
    return createHeaderColumnDescriptor(columnName, index, errors, getColumnSchema || (column => schema[column]), t);
  });

  headers.push({
    header: t('status'),
    accessorKey: 'status',
    cell: ({ row }) => {
      const hasError = errors.filter(error => error.row === row.index + 2).length > 0;
      const status = expectedResult && expectedResult.length > 0 ? expectedResult[row.index].status : undefined;

      return status ? (
        <Tooltip content={t(`statusTooltip.${status.toLowerCase()}`)}>
          <Tag
            className={styles.DataUploadPage__statusTag}
            variant={status}
          />
        </Tooltip>
      ) : hasError ? (
        <Tooltip content={t('statusTooltip.failed')}>
          <Tag
            className={styles.DataUploadPage__statusTag}
            variant="failed"
          />
        </Tooltip>
      ) : (
        <Tooltip content={t('statusTooltip.succeeded')}>
          <Tag
            className={styles.DataUploadPage__statusTag}
            variant="succeeded"
          />
        </Tooltip>
      );
    }
  });

  return headers;
}
