import React from 'react';

import normalizePath from '../../helpers/normalizePath';
import useLocation from '../../hooks/useLocation';
import { useNavSection } from '../SideNav';

import CourseRegistrationRequestsNav from './nav/CourseRegistrationRequestsNav';


import './SecondaryNav.sass';

export default function SecondaryNav() {
  const section = useNavSection();
  const location = useLocation();

  const siteSection = SecondaryNav.SECTIONS[section];
  if (siteSection) {
    const path = normalizePath(location.pathname);
    const subsections = siteSection.SECTIONS;
    for (const subsection of subsections) {
      if (
        (normalizePath(subsection.link) === path) ||
        (subsection.alias && normalizePath(subsection.alias) === path)
      ) {
        return (
          <nav className="SecondaryNav">
            {React.createElement(siteSection)}
          </nav>
        );
      }
    }
  }

  return null;
}

SecondaryNav.SECTIONS = {
  courseRegistrationRequests: CourseRegistrationRequestsNav
};
