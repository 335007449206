/* eslint-disable @typescript-eslint/no-unused-vars, no-unused-vars */

import type { FC } from 'react';
import React, { useMemo, useState } from 'react';
import { useFormState } from 'react-hook-form';

import { useTranslate } from '@acadeum/translate';
import type { DeepPartial, Id } from '@acadeum/types';
import type { OnSubmit } from '@acadeum/ui';
import type { StickyFormFooterProps } from '@acadeum/ui';
import {
  ConsortiumAutocomplete,
  ContentSection,
  Form,
  FormField, FormFieldArray,
  InstitutionAutocomplete,
  Separator,
  StickyFormFooter,
  Text
} from '@acadeum/ui';

export interface FormValues {
  consortiumIds?: Id[];
  institutionIds?: Id[];
  pricing?: { institutionId: Id; value: number; }[];
}

type AllFormValues = FormValues & {
  closedConsortiumSwitcher: boolean;
  limitedInstitutionSharingSwitcher: boolean;
  differentialPricingSwitcher: boolean;
};

type Mode = 'create' | 'edit';

export interface AdvancedCourseSettingsFormProps extends Pick<StickyFormFooterProps, 'onCancelProps' | 'onBackProps' | 'submitProps'> {
  onSubmit: OnSubmit<FormValues>;
  defaultValues?: DeepPartial<FormValues>;
  mode?: Mode;
}

export const AdvancedCourseSettingsForm: FC<AdvancedCourseSettingsFormProps> = ({
  onSubmit: propsOnSubmit,
  submitProps,
  onBackProps,
  onCancelProps,
  defaultValues = {},
  mode = 'create'
}) => {
  const [enabledCloseConsortium, setEnabledCloseConsortium] = useState(() => Boolean(defaultValues && defaultValues.consortiumIds && defaultValues.consortiumIds.length > 0));
  const [enabledLimitedInstitutionSharing, setEnabledLimitedInstitutionSharing] = useState(() => Boolean(defaultValues && defaultValues.institutionIds && defaultValues.institutionIds.length > 0));
  const [enabledDifferentialPricing, setEnabledDifferentialPricing] = useState(() => Boolean(defaultValues && defaultValues.pricing && defaultValues.pricing.length > 0));

  const t = useTranslate('shared-admin-ui.AdvancedCourseSettingsForm');

  const onCloseConsortiumChange = ({ target: { checked } }) => setEnabledCloseConsortium(checked);
  const onLimitedInstitutionSharingChange = ({ target: { checked } }) => setEnabledLimitedInstitutionSharing(checked);
  const onDifferentialPricingChange = ({ target: { checked } }) => setEnabledDifferentialPricing(checked);

  const onSubmit: OnSubmit<AllFormValues> = async ({
    consortiumIds,
    institutionIds,
    pricing,
    limitedInstitutionSharingSwitcher,
    differentialPricingSwitcher,
    closedConsortiumSwitcher
  }, options) => {
    const values: FormValues = {
      pricing: enabledDifferentialPricing ? pricing : [],
      consortiumIds: enabledCloseConsortium ? consortiumIds : [],
      institutionIds: enabledLimitedInstitutionSharing ? institutionIds : []
    };

    await propsOnSubmit(values, options);
  };

  const preparedDefaultValues = useMemo(() => {
    const {
      consortiumIds,
      institutionIds,
      pricing
    } = defaultValues || {};

    return {
      consortiumIds,
      institutionIds,
      pricing,
      closedConsortiumSwitcher: Boolean(consortiumIds && consortiumIds.length > 0),
      limitedInstitutionSharingSwitcher: Boolean(institutionIds && institutionIds.length > 0),
      differentialPricingSwitcher: Boolean(pricing && pricing.length > 0)
    };
  }, [defaultValues]);

  return (
    <Form
      defaultValues={preparedDefaultValues}
      onSubmit={onSubmit}
    >
      <ContentSection as="div">
        <ContentSection border={false} padding={false} title={t('closedConsortium.title')}>
          <FormField
            type="switch"
            name="closedConsortiumSwitcher"
            onChange={onCloseConsortiumChange}
            label={t('closedConsortium.label')}
            description={t('closedConsortium.description')}
          />
          <Text>
            {t('closedConsortium.notes')}
          </Text>
          <br />
          {enabledCloseConsortium && (
            <ConsortiumAutocomplete
              multiple
              required
              label={t('consortium.label')}
              name="consortiumIds"
              placeholder={t('consortium.placeholder')}
            />
          )}
        </ContentSection>

        <Separator mt="none" />

        <ContentSection border={false} padding={false} title={t('limitedInstitutionSharing.title')}>
          <FormField
            type="switch"
            name="limitedInstitutionSharingSwitcher"
            onChange={onLimitedInstitutionSharingChange}
            label={t('limitedInstitutionSharing.label')}
            description={t('limitedInstitutionSharing.description')}
          />
          <Text>
            {t('limitedInstitutionSharing.notes')}
          </Text>
          <br />
          {enabledLimitedInstitutionSharing && (
            <InstitutionAutocomplete
              multiple
              label={t('institution.label')}
              type="institution"
              name="institutionIds"
              placeholder={t('institution.placeholder')}
            />
          )}
        </ContentSection>

        <Separator mt="none" />

        <ContentSection border={false} padding={false} title={t('differentialPricing.title')}>
          <FormField
            type="switch"
            name="differentialPricingSwitcher"
            onChange={onDifferentialPricingChange}
            label={t('differentialPricing.label')}
            description={t('differentialPricing.description')}
          />
          <Text>
            {t('differentialPricing.notes')}
          </Text>
          <br />
          {enabledDifferentialPricing && (
            <>
              <FormFieldArray
                name="pricing"
                addButtonText={t('addInstitutionOrConsortium')}
                border={false}
              >
                {({ index }) => (
                  <>
                    <InstitutionAutocomplete
                      label={t('institutionOrConsortium.label')}
                      type="institution"
                      name={`pricing.${index}.institutionId`}
                      placeholder={t('institutionOrConsortium.placeholder')}
                    />
                    <FormField
                      required
                      label={t('price.label')}
                      name={`pricing.${index}.value`}
                      type="currency"
                      currency="USD"
                      placeholder={t('price.placeholder')}
                    />
                  </>
                )}
              </FormFieldArray>
            </>
          )}
        </ContentSection>
      </ContentSection>

      <AdvancedCourseSettingsFormFooter
        onBackProps={onBackProps}
        onCancelProps={onCancelProps}
        submitProps={submitProps}
        mode={mode}
      />
    </Form>
  );
};

function AdvancedCourseSettingsFormFooter({
  onBackProps,
  onCancelProps,
  submitProps,
  mode
}) {
  const { isDirty } = useFormState();

  if (mode === 'edit' && !isDirty) {
    return null;
  }

  return (
    <StickyFormFooter
      onBackProps={onBackProps}
      onCancelProps={onCancelProps}
      submitProps={submitProps}
    />
  );
}
