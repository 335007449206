import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { userHasPermission } from '@acadeum/helpers';
import { toast } from '@acadeum/ui';

import { Accordion, Button, ButtonGroup, ConfirmActionModal, ConfirmationModal, List, Text } from '@acadeum/ui';

import actions from '../../../../../actions';
import { ALGOLIA_UPDATE_PENDING_NOTE } from '../../../../../helpers/algolia';
import { waitForModalToClose } from '../../../../Modal';


import styles from '../CourseInfo.module.scss';

import MappingForm from './MappingForm';


const {
  deleteCourseSubstitute
} = actions;

export function MappingSection({
  course
}) {
  const user = useSelector(state => state.auth.user);
  const substitutes = course.substitutes.filter(_ => _.institutionId === user.institution.id);
  const hasMapping = substitutes.length > 0;

  const canEditMapping = user && userHasPermission(user, 'courseMapping:create', {
    orgId: user.institution.id
  });

  const [courseSubstitute, setCourseSubstitute] = useState();

  const [showAddMappingModal, setShowAddMappingModal] = useState();
  const [showDeleteMappingModal, setShowDeleteMappingModal] = useState();
  const [showCanNotRemoveTheOnlyMappingModal, setShowCanNotRemoveTheOnlyMappingModal] = useState();


  const onAddMapping = () => {
    setShowAddMappingModal(true);
    setCourseSubstitute();
  };

  const onEditMapping = (courseSubstitute) => {
    setShowAddMappingModal(true);
    setCourseSubstitute(courseSubstitute);
  };

  const onDeleteMapping = (courseSubstitute) => {
    const substitutes = course.substitutes.filter(_ => _.institutionId === user.institution.id);
    if (substitutes.length === 1) {
      return setShowCanNotRemoveTheOnlyMappingModal(true);
    }
    setShowDeleteMappingModal(true);
    setCourseSubstitute(courseSubstitute);
  };

  const hideDeleteMappingModal = async () => {
    setShowDeleteMappingModal(false);
    await waitForModalToClose();
    setCourseSubstitute();
  };

  async function onDeleteCourseSubstitute() {
    await deleteCourseSubstitute(courseSubstitute.id);
    // Update course mappings as if they were re-fetched from the database.
    course.substitutes = course.substitutes.filter(_ => _ !== courseSubstitute);
    await hideDeleteMappingModal();
    toast.success(`Course Mapping has been deleted. ${ALGOLIA_UPDATE_PENDING_NOTE}`);
  }

  const hideAddMappingModal = () => {
    setShowAddMappingModal(false);
    // `courseSubstitute` is still used for displaying
    // the edit modal title while it's being closed.
    setCourseSubstitute();
  };


  let content;
  if (hasMapping) {
    content = (
      <List unstyled>
        {substitutes.map((substitute) => (
          <CourseSubstitute
            key={substitute.id}
            substitute={substitute}
            onEditMapping={onEditMapping}
            onDeleteMapping={onDeleteMapping}
            canEditMapping={canEditMapping}
          />
        ))}
      </List>
    );
  } else {
    content = 'No equivalents at your school have been mapped to this course yet.\n' +
      'Approve the course in order to add Home Institution Equivalent Course. ';
  }

  return (
    <>
      <Accordion title="Home Institution Equivalent Course" defaultOpen={hasMapping}>
        <Text as="div" color="grey">
          {content}

          {canEditMapping && (
            <>
              <br/>
              <Button variant="secondary" onClick={onAddMapping}>
                Add Mapping
              </Button>
            </>
          )}
        </Text>
      </Accordion>

      <MappingForm
        course={course}
        courseSubstitute={courseSubstitute}
        institutionId={user.institution.id}
        show={showAddMappingModal}
        onHide={hideAddMappingModal}
      />

      {courseSubstitute && (
        <ConfirmActionModal
          danger
          show={showDeleteMappingModal}
          onHide={hideDeleteMappingModal}
          onSubmit={onDeleteCourseSubstitute}
          submitText="Delete"
          title="Delete Course Mapping"
          description={
            <>
              Delete mapping <strong>{courseSubstitute.code} {courseSubstitute.title}</strong>?
            </>
          }
        />
      )}

      <ConfirmationModal
        title="Can't Remove Course Mapping"
        show={showCanNotRemoveTheOnlyMappingModal}
        onHide={setShowCanNotRemoveTheOnlyMappingModal}
      >
        A new course mapping must be added before this mapping may be removed.
      </ConfirmationModal>
    </>
  );
}

function CourseSubstitute({
  substitute,
  canEditMapping,
  onEditMapping,
  onDeleteMapping
}) {
  return (
    <List.Item className={styles.courseSubstitute}>
      {substitute.code}
      {' '}
      {substitute.title}

      <ButtonGroup>
        {canEditMapping && onEditMapping && (
          <Button variant="text" onClick={() => onEditMapping(substitute)}>
            Edit
          </Button>
        )}
        {canEditMapping && onDeleteMapping && (
          <Button variant="text" onClick={() => onDeleteMapping(substitute)}>
            Remove
          </Button>
        )}
      </ButtonGroup>
    </List.Item>
  );
}
