import { createColumnHelper } from '@tanstack/react-table';
import React, { useMemo, useState } from 'react';

import getLastDropDateForCourseEnrollment from 'common-lib/lib/getLastDropDateForCourseEnrollment';

import type { FetchTeachingCourseEnrollmentRequestsResponseItem } from '@acadeum/api';
import { getEnrollmentTypeLabel } from '@acadeum/helpers';
import { EyeIcon, FileImportIcon } from '@acadeum/icons';
import { useTranslate } from '@acadeum/translate';
import { Button, createTableId, DataBlock, EmptyState, HStack, PageLoader, Table, Tag, Text } from '@acadeum/ui';


import { useFetchTeachingCourseEnrollmentRequestsQuery } from '../../api/courseEnrollmentRequest';
import Section from '../../components/Section';
import useOnError from '../../hooks/useOnError';

import { CourseEnrollmentRequestDetailsModal } from './ui/CourseEnrollmentRequestDetailsModal';
import { useExportDataColumns } from './useExportDataColumns';


import './EnrollmentRequests.sass';

const columnHelper = createColumnHelper<FetchTeachingCourseEnrollmentRequestsResponseItem>();

const EnrollmentRequests = () => {
  const onError = useOnError();
  const t = useTranslate('EnrollmentRequests');

  const {
    data,
    error,
    isLoading,
    isFetching
  } = useFetchTeachingCourseEnrollmentRequestsQuery({
    filters: {
      status: ['REQUESTED']
    }
  });

  const exportDataColumns = useExportDataColumns();

  const columns = useMemo(() => [
    columnHelper.accessor('section.course.code', {
      header: t('course'),
      cell: ({ row }) => <DataBlock type="course" course={row.original.section.course}/>
    }),
    columnHelper.accessor('institution.name', {
      header: t('homeInstitution'),
      cell: ({ row }) => <DataBlock type="institution" institution={row.original.institution}/>
    }),
    columnHelper.display({
      id: 'courseEnrollment.type',
      header: t('type'),
      cell: ({ row }) => <Tag variant={getEnrollmentTypeLabel(row.original.enrollments[0])}/>
    }),
    columnHelper.accessor('section.session', {
      enableSorting: true,
      header: t('termAndSession'),
      meta: {
        emptyWhen: ({ row }) => row.section.course.onDemand
      },
      cell: ({ row }) => (
        <div>
          <Text>
            {row.original.section.term}
          </Text>
          <Text color="grey">
            {row.original.section.session}
          </Text>
        </div>
      )
    }),
    columnHelper.display({
      id: 'section.dates',
      header: t('dates'),
      meta: {
        emptyWhen: ({ row }) => {
          const { lastDropDate } = getLastDropDateForCourseEnrollment({
            startedAt: row.enrollments[0]?.startedAt,
            onDemand: row.section.course.onDemand,
            lastDropDate: row.section.lastDropDate,
            onDemandDropDateDaysAfterStartDate: row.section.course.institution.onDemandCourseDropDateDaysAfterStartDate
          });
          return !lastDropDate;
        }
      },
      cell: ({ row }) => {
        const onDemand = row.original.section.course.onDemand;
        if (onDemand) {
          const startedAt = row.original.enrollments[0]?.startedAt;
          if (!startedAt) {
            return null;
          }
          const {
            lastDropDate,
            lastDropDateIsInUtc0TimeZone
          } = getLastDropDateForCourseEnrollment({
            onDemand,
            startedAt,
            lastDropDate: row.original.section.lastDropDate,
            onDemandDropDateDaysAfterStartDate: row.original.section.course.institution.onDemandCourseDropDateDaysAfterStartDate
          });
          if (lastDropDate) {
            return (
              <Text as="div" nowrap>
                <HStack gap="xs">
                  Drop Date:
                  <DataBlock
                    date={lastDropDate}
                    utc={lastDropDateIsInUtc0TimeZone}
                    type="date"
                    month="short"
                  />
                </HStack>
              </Text>
            );
          }
        }

        return (
          <DataBlock
            type="courseSessionDates"
            withLastDropDate
            session={row.original.section}
          />
        );
      }
    }),
    columnHelper.accessor('seatsRequested', {
      header: t('seats')
    }),
    columnHelper.accessor('createdAt', {
      header: t('submittedOn'),
      enableSorting: true,
      cell: ({ row }) => (
        <DataBlock
          utc
          type="date"
          month="long"
          as="span"
          date={row.original.createdAt}
        />
      )
    }),
    columnHelper.display({
      id: 'actions',
      cell: ({ row }) => <ActionsCell enrollmentRequest={row.original}/>
    })
  ], []);

  if (error) {
    return onError(error);
  }

  return (
    <Section
      title={t('title')}
    >
      {isLoading ? (
        <PageLoader/>
      ) : (
        <>
          {data?.totalCount === 0 ? (
            <EmptyState
              icon={FileImportIcon}
              title={t('noData')}
            />
          ) : (
            <Table
              id={createTableId('courseEnrollmentRequests')}
              hasColumnVisibility
              enableRowSelection
              columns={columns}
              data={data?.results}
              isFetching={isFetching}
              loading={isLoading}
              exportOptions={{
                type: 'xlsx',
                fileName: t('title'),
                exportDataColumns
              }}
            />
          )}
        </>
      )}
    </Section>
  );
};

EnrollmentRequests.meta = () => ({
  title: 'New Students'
});

EnrollmentRequests.breadcrumbs = () => ['Enrollment Requests'];

export default EnrollmentRequests;

interface ActionsCellProps {
  enrollmentRequest: FetchTeachingCourseEnrollmentRequestsResponseItem;
}

const ActionsCell = ({
  enrollmentRequest
}: ActionsCellProps) => {
  const t = useTranslate('EnrollmentRequests');
  const [show, setShow] = useState(false);

  return (
    <>
      <Button
        variant="tertiary"
        onClick={() => setShow(true)}
        icon={EyeIcon}
      >
        {t('review')}
      </Button>
      <CourseEnrollmentRequestDetailsModal
        show={show}
        onHide={setShow}
        enrollmentRequest={enrollmentRequest}
      />
    </>
  );
};
