import type { FC } from 'react';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import formatUserName from 'common-lib/lib/formatUserName';

import type {
  UseActivateUserMutation,
  UseAdminRequestUserEmailVerificationMutation,
  UseAdminRequestUserPasswordResetMutation,
  UseDeactivateUserMutation,
  UseFetchContactsQuery,
  UseFetchUserLogQuery,
  UseFetchUserQuery,
  UseFetchUserRolesQuery,
  UseFetchUsersQuery,
  UseUpdateContactMutation,
  UseUpdateUserMutation
} from '@acadeum/api';
import { getAuthSelector } from '@acadeum/auth';
import { canEditUser, isAcadeumAdministrator } from '@acadeum/helpers';
import { useTranslate } from '@acadeum/translate';
import type { ActionsProps, TabsProps } from '@acadeum/ui';
import { Actions, AppLoading, ConfirmActionModal, Page, Tabs, toast } from '@acadeum/ui';

import { useSettingsRoutes } from '../../../hooks/useSettingsRoutes';
import { useApp } from '../../../providers/useApp';
import { useLocation } from '../../../providers/useLocation';
import { useNavigate } from '../../../providers/useNavigate';
import { useOnError } from '../../../providers/useOnError';

import { DeactivateUserModal } from '../ui/DeactivateUserModal';

import { EditUserForm } from '../ui/EditUserForm';

import UserLog from './ui/UserLog';

export interface UserDetailsPageProps {
  userId: number;
  useFetchUserRolesQuery: UseFetchUserRolesQuery;
  useFetchContactsQuery: UseFetchContactsQuery;
  useActivateUserMutation: UseActivateUserMutation;
  useFetchUserQuery: UseFetchUserQuery;
  useUpdateUserMutation: UseUpdateUserMutation;
  useFetchUserLogQuery: UseFetchUserLogQuery;
  useDeactivateUserMutation: UseDeactivateUserMutation;
  useFetchUsersQuery: UseFetchUsersQuery;
  useUpdateContactMutation: UseUpdateContactMutation;
  useAdminRequestUserPasswordResetMutation: UseAdminRequestUserPasswordResetMutation;
  useAdminRequestUserEmailVerificationMutation: UseAdminRequestUserEmailVerificationMutation;
}

export const UserDetailsPage: FC<UserDetailsPageProps> = ({
  userId,
  useActivateUserMutation,
  useFetchUserQuery,
  useFetchUserRolesQuery,
  useFetchContactsQuery,
  useUpdateUserMutation,
  useFetchUserLogQuery,
  useDeactivateUserMutation,
  useFetchUsersQuery,
  useUpdateContactMutation,
  useAdminRequestUserEmailVerificationMutation,
  useAdminRequestUserPasswordResetMutation
}) => {
  const { app } = useApp();
  const { getUserDetailsUrl, getSettingsUrl, getUserManagementUrl } = useSettingsRoutes();
  const onError = useOnError();
  const t = useTranslate('shared-admin-ui.UserDetailsPage');

  const [showResetPasswordEmailModal, setShowResetPasswordEmailModal] = useState(false);
  const [showVerificationEmailModal, setShowVerificationEmailModal] = useState(false);
  const [showActivateUserModal, setShowActivateUserModal] = useState(false);
  const [showDeactivateUserModal, setShowDeactivateUserModal] = useState(false);

  const currentUser = useSelector(getAuthSelector('user'));

  const [activateUser] = useActivateUserMutation();

  const {
    data: user,
    refetch: reFetchUser,
    error: userError
  } = useFetchUserQuery({ id: userId, allSettings: isAcadeumAdministrator(currentUser) });

  const {
    data: userRoles,
    error: userRolesError
  } = useFetchUserRolesQuery({
    pageSize: 1000
  });
  const roles = userRoles?.results;

  const [adminRequestUserEmailVerificationMutation] = useAdminRequestUserEmailVerificationMutation();
  const [adminRequestUserPasswordResetMutation] = useAdminRequestUserPasswordResetMutation();

  const sendPasswordResetEmail = async (user) => {
    try {
      await adminRequestUserPasswordResetMutation({ id: user.id }).unwrap();
      toast.success(t('sendPasswordResetEmail.successMessage'));
      setShowResetPasswordEmailModal(false);
    } catch (error: unknown) {
      console.log(error);
      toast.error(t('sendPasswordResetEmail.errorMessage'));
    }
  };

  const sendVerificationEmail = async (user) => {
    try {
      await adminRequestUserEmailVerificationMutation({ id: user.id }).unwrap();
      toast.success(t('sendVerificationEmail.successMessage'));
      setShowVerificationEmailModal(false);
    } catch (error: unknown) {
      console.log(error);
      toast.error(t('sendVerificationEmail.errorMessage'));
    }
  };

  const onActivateUser = async (user) => {
    try {
      await activateUser({ id: user.id });
      await reFetchUser();
      toast.success(t('activateUser.successMessage'));
      setShowActivateUserModal(false);
    } catch (error: unknown) {
      console.log(error);
      toast.error(t('activateUser.errorMessage'));
    }
  };

  const actions = useMemo<ActionsProps['actions']>(() => {
    const actions: ActionsProps['actions'] = [];

    if (user && canEditUser('update', {
      user,
      byUser: currentUser,
      userInstitutionId: currentUser?.institution.id as number
    })) {
      actions.push({
        title: t('sendPasswordResetEmail.title'),
        onClick: () => setShowResetPasswordEmailModal(true)
      });

      actions.push({
        title: t('sendVerificationEmail.title'),
        onClick: () => setShowVerificationEmailModal(true)
      });

      if (!user.isActive) {
        actions.push({
          title: t('activateUser.title'),
          onClick: () => setShowActivateUserModal(true)
        });
      }
    }

    if (user && canEditUser('delete', {
      user,
      byUser: currentUser,
      userInstitutionId: currentUser?.institution.id as number
    })) {
      if (user.isActive) {
        actions.push({
          title: t('deactivateUser.title'),
          onClick: () => setShowDeactivateUserModal(true)
        });
      }
    }

    return actions;
  }, [user]);

  const router = useNavigate();
  const location = useLocation();
  const tab = location.query.tab;
  
  const tabs = useMemo<TabsProps['tabs']>(() => {
    if (!user || !roles) {
      return [];
    }

    return [
      {
        key: 'info',
        title: t('info'),
        body: <EditUserForm
          user={user}
          roles={roles}
          useUpdateUserMutation={useUpdateUserMutation}
        />
      },
      {
        key: 'log',
        title: t('userLog'),
        body: <UserLog userId={user?.id} useFetchUserLogQuery={useFetchUserLogQuery}/>
      }
    ];
  }, [
    user,
    roles
  ]);

  const onChangeTab: TabsProps['onChangeTab'] = ({ key }) => {
    void router.push(`${getUserDetailsUrl(user?.id)}?tab=${key}`);
  };

  const error = userError || userRolesError;
  if (error) {
    return onError(error);
  }

  return (
    <>
      <Page
        title={user ? formatUserName(user) : t('title')}
        breadcrumbs={[
          [t(app === 'admin' ? 'general' : 'settings', { global: true }), getSettingsUrl()],
          [t('userManagement'), getUserManagementUrl()],
          user ? formatUserName(user) : t('title')
        ]}
        actions={user && actions.length > 0 ? (<Actions actions={actions}/>) : null}
      >
        {(!user || !roles || !currentUser) ? (
          <AppLoading inline/>
        ) : (
          <>
            <Tabs
              title="User details"
              tabs={tabs}
              onChangeTab={onChangeTab}
              initialActiveTab={tab === 'log' ? 'log' : 'info'}
            />

            {canEditUser('update', {
              user,
              byUser: currentUser,
              userInstitutionId: currentUser.institution.id
            }) && (
              <>
                <ConfirmActionModal
                  title={t('sendPasswordResetEmail.title')}
                  description={t('sendPasswordResetEmail.description', {
                    userName: <strong>{formatUserName(user)}</strong>,
                    email: <strong>{user.email}</strong>
                  })}
                  submitText={t('sendPasswordResetEmail.submitText')}
                  show={showResetPasswordEmailModal}
                  onSubmit={() => sendPasswordResetEmail(user)}
                  onHide={() => setShowResetPasswordEmailModal(false)}
                />

                <ConfirmActionModal
                  title={t('sendVerificationEmail.title')}
                  description={t('sendVerificationEmail.description', {
                    userName: <strong>{formatUserName(user)}</strong>,
                    email: <strong>{user.email}</strong>
                  })}
                  submitText={t('sendVerificationEmail.submitText')}
                  show={showVerificationEmailModal}
                  onSubmit={() => sendVerificationEmail(user)}
                  onHide={() => setShowVerificationEmailModal(false)}
                />

                <ConfirmActionModal
                  title={t('activateUser.title')}
                  description={t('activateUser.description', {
                    userName: <strong>{formatUserName(user)}</strong>
                  })}
                  submitText={t('activateUser.submitText')}
                  show={showActivateUserModal}
                  onSubmit={() => onActivateUser(user)}
                  onHide={() => setShowActivateUserModal(false)}
                />

              </>
            )}
            {canEditUser('delete', {
              user,
              byUser: currentUser,
              userInstitutionId: currentUser.institution.id
            }) && (
              <DeactivateUserModal
                user={user}
                onHide={setShowDeactivateUserModal}
                show={showDeactivateUserModal}
                useDeactivateUserMutation={useDeactivateUserMutation}
                useFetchUsersQuery={useFetchUsersQuery}
                useUpdateContactMutation={useUpdateContactMutation}
                useFetchContactsQuery={useFetchContactsQuery}
              />
            )}
          </>
        )}
      </Page>
    </>
  );
};
