import { getAlgoliaIndex } from './algolia';

/**
 * Returns a list of `<Autocomplete/>` options by consortium name.
 * @param  {string} name
 * @return {object[]} consortiumOptions — Objects with shape `{ value, label }`.
 */
export async function getConsortiumOptionsByName(name) {
  const consortia = await getIndex().findAll({
    query: name,
    limit: 10
  });
  return consortia.map(getOptionForConsortium);
}

/**
 * Returns an `<Autocomplete/>` option by consortium ID.
 * @param  {number} id
 * @return {object} [consortiumOption] — Object with shape `{ value, label }`.
 */
export async function getConsortiumOptionByValue(id) {
  const consortium = await getConsortiumById(id);
  if (consortium) {
    return getOptionForConsortium(consortium);
  }
}

function getOptionForConsortium(consortium) {
  return {
    value: consortium.id,
    label: consortium.name
  };
}

/**
 * Searches consortium by its ID in Algolia.
 * @param  {number} id
 * @return {object} [consortium]
 */
export async function getConsortiumById(id) {
  return getIndex().get(String(id));
}

function getIndex() {
  return getAlgoliaIndex('consortia');
}
