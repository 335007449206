import React, { memo } from 'react';

import { isAcadeumAdminRole} from '@acadeum/helpers';
import { KeyIcon } from '@acadeum/icons';
import type { UserRole } from '@acadeum/types';

import styles from './RolesBlock.module.scss';

export interface RolesBlockProps {
  roles: Pick<UserRole, 'id' | 'name'>[];
}

export const RolesBlock: React.FC<RolesBlockProps> = memo(({
  roles
}) => {
  return (
    <div className={styles.RolesBlock}>
      {roles.map((role, i) => (
        <React.Fragment key={i}>
          <span>
            {isAcadeumAdminRole(role) &&
              <KeyIcon style={{
                width: '1.25rem',
                height: '1.25rem',
                marginBottom: '-0.2rem',
                marginRight: '0.4rem',
                flex: 'none'
              }}/>
            }
            {role.name}
          </span>
          {i < roles.length - 1 &&
            ', '
          }
        </React.Fragment>
      ))}
    </div>
  );
});
