import React from 'react';
import { useSelector } from 'react-redux';

import actions from '../../actions';
import { formatUserName } from '../../helpers/format';

import { EditUser } from './EditUser';


const {
  fetchUser,
  fetchUserRoles,
  updateUserAdmin
} = actions;

export default function EditUser_() {
  const { user, userRoles } = useSelector(state => state.users);
  const { user: currentUser } = useSelector(state => state.auth);
  return (
    <EditUser
      adminPanel
      user={user}
      userRoles={userRoles}
      currentUser={currentUser}
      updateUser={updateUserAdmin}
      redirectTo="/admin/users/{0}"
      cancelLink="/admin/users/{0}"
    />
  );
}

EditUser_.meta = () => ({
  title: 'Edit User'
});

EditUser_.breadcrumbs = (state) => [
  ['Admin Tools', '/admin'],
  [formatUserName(state.users.user), `/admin/users/${state.users.user.id}`],
  'Edit'
];

EditUser_.load = async ({ params: { id } }) => {
  await Promise.all([
    fetchUser(id, { allSettings: true }),
    fetchUserRoles()
  ]);
};
