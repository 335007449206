import requestBuilder from 'algoliasearch-helper/src/requestBuilder';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connectStats } from 'react-instantsearch-dom';


import { DownloadIcon } from '@acadeum/icons';

import { Button } from '@acadeum/ui';

import ConfirmationModal from '../ConfirmationModal';

import './InstantSearchExportData.sass';

class InstantSearchExportData_ extends React.Component {
  static contextTypes = {
    ais: PropTypes.object.isRequired
  };

  render() {
    return <InstantSearchExportData {...this.props} ais={this.context.ais}/>;
  }
}

export default connectStats(InstantSearchExportData_);

function InstantSearchExportData({
  variant = 'text',
  onExportData,
  nbHits,
  ais
}) {
  const [showConfirmationModal, setShowConfirmationModal] = useState();
  const [confirmationModalText, setConfirmationModalText] = useState();

  const getSearchParameters = () => {
    const getSearchParameters = ais.getSearchParameters;
    // This code used to use `getSearchParameters().sharedParameters`.
    // After `react-instantsearch` was updated from `4.x` to `5.x`
    // `getSearchParameters().sharedParameters` seems to be `undefined`.
    // I guess `mainParameters` can be a substitute (whatever that is).
    return requestBuilder._getHitsSearchParams(getSearchParameters().mainParameters);
  };

  const onExportDataClick = async () => {
    try {
      const result = await onExportData(getSearchParameters(), { resultsCount: nbHits });
      if (result) {
        const { confirmationText } = result;
        if (confirmationText) {
          setConfirmationModalText(confirmationText);
          setShowConfirmationModal(true);
        }
      }
    } catch (error) {
      console.error(error);
      alert(error.message);
    }
  };

  return (
    <>
      <Button
        download
        icon={variant === 'tertiary' && DownloadIcon}
        variant={variant}
        onClick={onExportDataClick}
        className={classNames({
          'InstantSearch-ExportData__button': variant === 'text'
        })}>
        Download
      </Button>

      <ConfirmationModal
        show={showConfirmationModal}
        onHide={() => setShowConfirmationModal()}
        text={confirmationModalText}
      />
    </>
  );
}

InstantSearchExportData.propTypes = {
  onExportData: PropTypes.func.isRequired,
  nbHits: PropTypes.number.isRequired,
  variant: PropTypes.oneOf(['text', 'tertiary']),
  ais: PropTypes.object.isRequired
};

// function formatCellContent(value) {
//   if (typeof value === 'string') {
//     return `"${value}"`;
//   } else if (Array.isArray(value)) {
//     return `"${value.join(', ')}"`;
//   }
//   return value;
// }
