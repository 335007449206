import React from 'react';
import { useSelector } from 'react-redux';

import type { UseUpdateUserRoleMutation } from '@acadeum/api';
import { getAuthSelector } from '@acadeum/auth';
import { userHasPermission } from '@acadeum/helpers';
import type { UserRole } from '@acadeum/types';
import { toast } from '@acadeum/ui';


import type { UserRoleEmailNotificationFormProps } from '../../ui/UserRoleEmailNotificationForm';
import { UserRoleEmailNotificationForm } from '../../ui/UserRoleEmailNotificationForm';

interface NotificationsTabProps {
  role: UserRole;
  useUpdateUserRoleMutation: UseUpdateUserRoleMutation;
}

const NotificationsTab: React.FC<NotificationsTabProps> = ({
  role,
  useUpdateUserRoleMutation
}) => {
  const user = useSelector(getAuthSelector('user'));

  const [updateUserRoleMutation] = useUpdateUserRoleMutation();

  const onSubmit: UserRoleEmailNotificationFormProps['onSubmit'] = async (values) => {
    await updateUserRoleMutation({
      id: role.id,
      ...values
    }).unwrap();
    toast.success('Role notifications updated');
  };

  return (
    <UserRoleEmailNotificationForm
      mode={role.type === 'DEFAULT' ? 'readOnly' : 'edit'}
      defaultValues={{ notifications: role.notifications }}
      disabled={user && !userHasPermission(user, 'userRole:update', {
        orgId: user.institution.id,
        ownerId: null
      })}
      onSubmit={onSubmit}
      submitText="Save"
    />
  );
};

export default NotificationsTab;
