import PropTypes from 'prop-types';
import React from 'react';

import { FormatDate } from '@acadeum/ui';

import Table from '../Table';
import UserAuthHistoryRecord from '../UserAuthHistoryRecord';


import './UserAuthHistoryTable.sass';

// Must be equal to the limit set in `server-lib/lib/auth/addAuthenticationHistoryEvent.js`.
const AUTH_HISTORY_MAX_ENTRIES = 12;

export default function UserAuthHistoryTable({ history }) {
  if (history.length === 0) {
    return (
      <p>
        No records
      </p>
    );
  }

  return (
    <>
      {history.length === AUTH_HISTORY_MAX_ENTRIES &&
        <p className="UserAuthHistoryTable-showingTheLatestRecords">
          Showing the last {AUTH_HISTORY_MAX_ENTRIES} records
        </p>
      }

      {history.length > 0 &&
        <Table
          data={history}
          columns={COLUMNS}
          selectableRows={false}
          showTotalResultsCount={false}
        />
      }
    </>
  );
}

UserAuthHistoryTable.propTypes = {
  history: PropTypes.arrayOf(PropTypes.object).isRequired
};

const COLUMNS = [
  {
    title: 'Event',
    content: (row) => (
      <UserAuthHistoryRecord {...row}/>
    )
  },
  {
    title: 'Date',
    content: (row) => (
      <FormatDate
        time
        utc={false}
        date={row.date}
        month="numeric"
        day="numeric"
      />
    )
  },
  {
    title: 'IP Address',
    content: (row) => (
      <a
        target="_blank"
        href={`https://infobyip.com/ip-${row.ip}.html`}
        className="UserAuthHistoryTable-ipAddress">
        {row.ip}
      </a>
    )
  }
];
