import type { FC } from 'react';
import React, { useState } from 'react';

import contactTypesList from 'common-lib/constants/contactTypes.json';

import type {
  UseCreateContactMutation,
  UseCreateUserMutation,
  UseFetchContactsQuery,
  UseFetchUserQuery,
  UseFetchUserRolesQuery,
  UseFetchUsersQuery,
  UseRemoveContactMutation,
  UseUpdateContactMutation
} from '@acadeum/api';
import { getErrorData } from '@acadeum/helpers';
import { useTranslate } from '@acadeum/translate';
import type { Id, InstitutionContact, InstitutionContactUser } from '@acadeum/types';
import { AppLoading, Breadcrumbs, Button, Sticky, Text, Title, toast } from '@acadeum/ui';

import { useSettingsRoutes } from '../../hooks/useSettingsRoutes';

import { useApp } from '../../providers/useApp';

import { ContactSection } from './ContactSection';

import styles from './ContactsPage.module.scss';

interface ContactItem {
  id: Id;
  action: 'ADD' | 'UPDATE' | 'REMOVE';
  type: InstitutionContact['type'];
  user: InstitutionContactUser;
}

export type ContactsList = ContactItem[];

export interface ContactsPageProps {
  useFetchUserRolesQuery: UseFetchUserRolesQuery;
  useFetchUsersQuery: UseFetchUsersQuery;
  useCreateContactMutation: UseCreateContactMutation;
  useFetchContactsQuery: UseFetchContactsQuery;
  useRemoveContactMutation: UseRemoveContactMutation;
  useUpdateContactMutation: UseUpdateContactMutation;
  useCreateUserMutation: UseCreateUserMutation;
  useFetchUserQuery: UseFetchUserQuery;
}

export const ContactsPage: FC<ContactsPageProps> = ({
  useUpdateContactMutation,
  useFetchUserRolesQuery,
  useFetchUsersQuery,
  useRemoveContactMutation,
  useFetchContactsQuery,
  useCreateContactMutation,
  useFetchUserQuery,
  useCreateUserMutation
}) => {
  const t = useTranslate('shared-admin-ui.ContactsPage');
  const { app } = useApp();
  const { getSettingsUrl } = useSettingsRoutes();

  const [isUpdated, setUpdated] = useState<boolean>();
  const [contactsList, setContactsList] = useState<ContactsList>([]);

  const {
    data: contacts,
    isLoading: isLoadingContacts
  } = useFetchContactsQuery();

  const {
    data: usersData,
    refetch: reFetchUsers
  } = useFetchUsersQuery({
    pageSize: 10000
  });

  const {
    data: userRoles
  } = useFetchUserRolesQuery({
    pageSize: 1000
  });
  const roles = userRoles?.results || [];

  const [createContact, { isLoading: isLoadingCreate }] = useCreateContactMutation();
  const [updateContact, { isLoading: isLoadingUpdate }] = useUpdateContactMutation();
  const [removeContact, { isLoading: isLoadingRemove }] = useRemoveContactMutation();

  const onSubmit = async () => {
    if (contactsList.length === 0) {
      return;
    }

    const toastId = toast.loading(t('loading'));

    const contactsListLocal = contactsList.filter(item => ((item.user && item.user.id) || (!item.user && item.action === 'REMOVE')));

    const promises = contactsListLocal.map(item => {
      const contact = { userId: item.user?.id, type: item.type };

      switch (item.action) {
        case 'REMOVE':
          return removeContact({ id: item.id }).unwrap();
        case 'UPDATE':
          return updateContact({ id: item.id, ...contact }).unwrap();
      }
      return createContact(contact).unwrap();
    });
    try {
      await Promise.all(promises);
      toast.success(t('successMessage'));
      setContactsList([]);
      setUpdated(false);
    } catch (error) {
      const { message } = getErrorData(error);
      toast.error(message);
    } finally {
      toast.dismiss(toastId);
    }
  };

  return (
    <>
      <Breadcrumbs
        children={[
          [t(app === 'admin' ? 'general' : 'settings', { global: true }), getSettingsUrl()],
          t('title')
        ]}
      />
      <Title>{t('title')}</Title>
      <Text color="grey" style={{ maxWidth: '598px' }}>
        {t('description')}
      </Text>

      {(isLoadingContacts) ? (
        <AppLoading inline/>
      ) : (
        <>
          <div>
            {contactTypesList.map((contactType, index) => {
              let data;

              if (contacts) {
                data = contacts.filter(item => item.type === contactType);
              }

              return (
                <ContactSection
                  key={index}
                  title={t(`${contactType}.title`)}
                  description={t(`${contactType}.description`)}
                  contactType={contactType}
                  contacts={data}
                  roles={roles}
                  users={usersData?.results}
                  contactsList={contactsList}
                  setContactsList={setContactsList}
                  onUpdate={setUpdated}
                  onUpdateUsers={reFetchUsers}
                  useFetchUserQuery={useFetchUserQuery}
                  useCreateUserMutation={useCreateUserMutation}
                />
              );
            })}
          </div>
          {isUpdated && (
            <Sticky position="bottom">
              <div className={styles.footer}>
                <Button variant="secondary" url={getSettingsUrl()}>
                  {t('cancel', { global: true })}
                </Button>
                <Button
                  loading={isLoadingRemove || isLoadingCreate || isLoadingUpdate}
                  onClick={onSubmit}
                >
                  {t('save', { global: true })}
                </Button>
              </div>
            </Sticky>
          )}
        </>
      )}
    </>
  );
};
