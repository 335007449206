import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

import './ApproveSessionsSwitcher.sass';

const ApproveSessionsSwitcher = forwardRef(({
  label,
  name,
  defaultValue = false,
  onActivate,
  onDeactivate
}, ref) => {
  const onClick = (event) => {
    const el = event.target;
    if (!el.checked) {
      event.preventDefault();
      onDeactivate();
    } else {
      onActivate();
    }
  };

  return (
    <div className="ApproveSessionsSwitcher">
      <label>
        <span className="ApproveSessionsSwitcher__control">
          <input
            ref={ref}
            type="checkbox"
            name={name}
            defaultChecked={defaultValue}
            onClick={onClick}
          />
          <span className="ApproveSessionsSwitcher checkmark"/>
        </span>
        <span>
          {label}
        </span>
      </label>
    </div>
  );
});

ApproveSessionsSwitcher.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.bool,
  onActivate: PropTypes.func.isRequired,
  onDeactivate: PropTypes.func.isRequired
};

export default ApproveSessionsSwitcher;
