import { isEqual } from 'lodash-es';
import type { CSSProperties } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';

export function useStickyTop() {
  const stickyRef = useRef<HTMLDivElement>(null);
  const [isSticky, setSticky] = useState(false);

  useEffect(() => {
    function observe() {
      if (!stickyRef.current) {
        return;
      }

      const refPageOffset = stickyRef.current.getBoundingClientRect().top;
      const stickyOffset = parseInt(getComputedStyle(stickyRef.current).top);
      const stickyActive = refPageOffset <= stickyOffset;

      if (stickyActive && !isSticky) {
        setSticky(true);
      } else if (!stickyActive && isSticky) {
        setSticky(false);
      }
    }

    observe();

    // Bind events
    document.addEventListener('scroll', observe);
    window.addEventListener('resize', observe);
    window.addEventListener('orientationchange', observe);

    return () => {
      document.removeEventListener('scroll', observe);
      window.removeEventListener('resize', observe);
      window.removeEventListener('orientationchange', observe);
    };
  }, [isSticky]);

  return { ref: stickyRef, isSticky };
}

export function useStickyBottom() {
  const { ref, inView } = useInView({ threshold: 1 });
  return { ref, isSticky: !inView };
}

export function useInnerStyles() {
  const [innerStyles, setInnerStyles] = useState<CSSProperties>();

  useEffect(() => {
    const mainElement = document.querySelector('#main');

    if (!mainElement) {
      return;
    }

    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        const {
          width,
          paddingLeft,
          paddingRight
        } = window.getComputedStyle(entry.target);
        const paddingLeftNum = parseInt(paddingLeft, 10);
        const paddingRightNum = parseInt(paddingRight, 10);

        const styles = {
          width: width,
          marginLeft: paddingLeftNum * -1,
          paddingLeft: paddingLeftNum,
          paddingRight: paddingRightNum
        };

        if (isEqual(styles, innerStyles)) {
          return;
        }

        setInnerStyles(styles);
      }
    });

    resizeObserver.observe(mainElement);

    return () => {
      resizeObserver.unobserve(mainElement);
    };
  }, []);

  return innerStyles;
}
