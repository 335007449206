import React, { useEffect, useState } from 'react';
import Iframe from 'react-iframe';
import { useSelector } from 'react-redux';

import actions from '../../actions';

const { fetchChartHomeInstitutionalToken } = actions;

export default function HomeInstitutionalAnalytics() {
  const chartToken = useSelector(state => state.reports.chartHomeInstitutionalToken);

  const [height, setHeight] = useState(1200);

  useEffect(() => {
    const listener = (message) => {
      if (message.origin !== 'https://embed.chartio.com') {
        return;
      }
      if (message.data && message.data.height) {
        setHeight(message.data.height);
      }
    };
    window.addEventListener('message', listener);
    () => {
      window.removeEventListener('message', listener);
    };
  }, []);

  return (
    <div>
      {chartToken &&
        <Iframe url={`https://embed.chartio.com/d/acadeum/home-institutional-analytics/?embed_token=${chartToken}`}
          width="100%"
          height={`${height  }px`}
          frameBorder="0"
          id="chartio-embed-iframe"
          display="initial"
          position="relative" />
      }
    </div>
  );
}

HomeInstitutionalAnalytics.meta = () => ({
  title: 'Home Institutional Analytics'
});

HomeInstitutionalAnalytics.load = async () => {
  await fetchChartHomeInstitutionalToken();
};

HomeInstitutionalAnalytics.breadcrumbs = () => ['Home Institutional Analytics'];
