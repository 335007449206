import classNames from 'classnames';
import type { CSSProperties } from 'react';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';

import type { AsProp } from '../../utils/props';
import type { MarginsProp } from '../../utils/useMargin';
import { useMargin } from '../../utils/useMargin';

import styles from './Text.module.scss';

type HeadingVariant = 'headingLg' | 'headingMd' | 'headingSm';
type SubTitleVariant = 'subtitle1' | 'subtitle2';
type BodyVariant = 'bodyLg' | 'bodyLgBold' | 'bodyMd' | 'bodyMdBold' | 'bodySm' | 'bodySmBold';
type ExclusiveVariant = 'courseCardTitle' | 'sidebar';

type Variant = HeadingVariant | SubTitleVariant | BodyVariant | ExclusiveVariant;

type Color = 'grey' | 'black' | 'success' | 'warn' | 'danger' | 'graphiteDark' | 'primary' | 'secondary' | 'tertiary' | 'accent';

type Alignment = 'start' | 'center' | 'end' | 'justify'

type TextTransform = 'uppercase';

export interface TextProps extends AsProp, React.HTMLAttributes<HTMLElement>, Pick<MarginsProp, 'mt' | 'mb'> {
  children: React.ReactNode | string;
  className?: string;
  style?: CSSProperties;
  alignment?: Alignment;
  variant?: Variant;
  textTransform?: TextTransform;
  color?: Color;
  nowrap?: boolean;
  /** Truncate text overflow with ellipsis */
  truncate?: boolean;
  /** When `true` then the text will be parsed as markdown */
  markdown?: boolean;
  allowLinks?: boolean;
  /** When `true` then the text will be split by double new line characters */
  split?: boolean;
  isLoading?: boolean;
}

export const Text: React.FC<TextProps> = ({
  as: Component = 'p',
  children: text,
  className,
  variant,
  color,
  truncate,
  style,
  alignment,
  nowrap,
  textTransform,
  mb = 'none',
  mt = 'none',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore // TODO: Remove this line when the `size` prop is removed
  size,
  allowLinks,
  markdown,
  split,
  isLoading,
  ...rest
}) => {
  variant = getCorrectVariantFromSize(size) || variant;

  const { marginClassNames } = useMargin({ mb, mt });

  const className_ = classNames(className,
    styles.Text,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    styles[variant!],
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    styles[color!],
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    styles[alignment!],
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    styles[textTransform!],
    marginClassNames,
    {
      [styles.nowrap]: nowrap,
      [styles.truncate]: truncate,
      [styles.block]: truncate,
      [styles.isLoading]: isLoading
    }
  );

  if (typeof text === 'string') {
    // https://github.com/rexxars/react-markdown
    if (markdown) {
      // * `skipHtml` option ignores any embedded HTML markup.
      // * `unwrapDisallowed` option instructs the parser
      //   to not skip unsupported markdown elements entirely
      //   but instead to replace those unsupported elements
      //   with their children (recursively).
      //   The option should have been called:
      //   "unwrap disallowed elements' children".
      // * `allowedElements` is a list of allowed markdown elements.
      // * `remarkPlugins` provides a list of additional plugins
      //   for the "remark" markdown engine.
      return (
        <ReactMarkdown
          {...rest}
          skipHtml
          unwrapDisallowed
          allowedElements={allowLinks ? ALLOWED_MARKDOWN_ELEMENTS_INCLUDING_LINKS : ALLOWED_MARKDOWN_ELEMENTS}
          remarkPlugins={REMARK_PLUGINS}
          className={classNames('Text', className)}>
          {text}
        </ReactMarkdown>
      );
    }

    if (split) {
      return (
        <React.Fragment>
          {text.split('\n\n').map((paragraph, i) => (
            <Component
              key={i}
              className={classNames('Text', className_)}
              style={RENDER_NEW_LINES_STYLE}>
              {paragraph.trim()}
            </Component>
          ))}
        </React.Fragment>
      );
    }
  }

  return (
    <Component {...rest}  style={style} className={className_}>
      {text}
    </Component>
  );
};

function getCorrectVariantFromSize(size: string | undefined) {
  switch (size) {
    case 'default':
      return 'bodyLg';
    case 'small':
      return 'bodyMd';
    case 'tiny':
      return 'bodySm';
  }
}


// Allowing users to use any markdown is not a sane approach.
// https://github.com/rexxars/react-markdown#node-types
const ALLOWED_MARKDOWN_ELEMENTS = [
  'strong',
  'em',
  'br',
  'p',
  'ul',
  'ol',
  'li',
  'u'
];

const ALLOWED_MARKDOWN_ELEMENTS_INCLUDING_LINKS = [
  ...ALLOWED_MARKDOWN_ELEMENTS,
  'a'
];

const RENDER_NEW_LINES_STYLE: React.CSSProperties = {
  // Render new line characters.
  whiteSpace: 'pre-wrap'
};

const REMARK_PLUGINS = [
  // Fixes markdown formatter to format "\n" as "<br/>".
  remarkBreaks
];
