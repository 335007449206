import type { FC } from 'react';
import React from 'react';

import { QualityMattersDesignationIcon } from '@acadeum/icons';

import { Icon } from '../Icon';
import { Tooltip } from '../Tooltip';

import styles from './QualityMattersBadge.module.scss';

export interface QualityMattersBadgeProps {
  variant: 'icon' | 'full' | 'short';
}

export const QualityMattersBadge: FC<QualityMattersBadgeProps> = ({
  variant
}) => {
  return (
    <Tooltip content="Quality Matters Online Course Sharing">
      {variant === 'icon' ? (
        <Icon icon={QualityMattersDesignationIcon} className={styles.icon}/>
      ) : (
        <span className={styles.root}>
          <Icon icon={QualityMattersDesignationIcon}/>
          {variant === 'full' && 'QM Online Course Sharing'}
        </span>
      )}
    </Tooltip>
  );
};
