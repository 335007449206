import classNames from 'classnames';
import type { ReactNode } from 'react';
import React, { memo } from 'react';

import type { AvatarProps } from '@acadeum/core-ui';
import { Avatar, HStack } from '@acadeum/core-ui';

import { Text } from '../../../Text';

import styles from './SimpleBlock.module.scss';

export interface SimpleBlockProps {
  firstRow: ReactNode;
  secondRow?: ReactNode;
  image?: Pick<AvatarProps, 'type' | 'url'>;
}

export const SimpleBlock = memo<SimpleBlockProps>(({
  firstRow,
  secondRow,
  image
}) => {
  return (
    <HStack className={classNames(styles.root)} gap="sm">
      {image && (
        <Avatar
          size={32}
          {...image}
          className={styles.avatar}
        />
      )}
      <div>
        <Text className={styles.firstRow}>
          {firstRow}
        </Text>
        {secondRow && (
          <Text className={styles.secondRow} color="secondary">
            {secondRow}
          </Text>
        )}
      </div>
    </HStack>
  );
});
