import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { instantSearchCollapsible } from './instantSearchCollapsible';

import './InstantSearchDateRange.sass';

class InstantSearchDateRange extends Component {
  render() {
    const { className, children } = this.props;
    return (
      <div className={classNames('InstantSearch-DateRange', className)}>
        {React.Children.map(children, (child, i) => React.cloneElement(child, {
          className: classNames(child.props.className, i === 0 ? 'InstantSearch-Date--from' : 'InstantSearch-Date--to'),
          tabIndex: this.props['aria-hidden'] ? -1 : undefined
        }))}
      </div>
    );
  }
}

InstantSearchDateRange.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default instantSearchCollapsible({ overflow: true })(InstantSearchDateRange);
