import type { FC } from 'react';
import React, { useState } from 'react';

import type { FetchBackupPaymentSourceOutput, UseDeleteBackupPaymentSourceMutation } from '@acadeum/api';
import { useTranslate } from '@acadeum/translate';
import { Actions, BackButton, ConfirmActionModal, CreditCard, Text, toast } from '@acadeum/ui';

import { FINANCIAL_SCREEN, useFinancialNavigationContext } from '../../context';

import type { CreditCardFormProps } from '../CreditCardForm';
import { CreditCardForm } from '../CreditCardForm';
import { Subsection } from '../Subsection';

import styles from './ManageCreditCardScreen.module.scss';

export interface ManageCreditCardScreenProps extends Pick<CreditCardFormProps, 'useUpdateBackupPaymentSourceMutation' | 'useCreateBackupPaymentSourceMutation'> {
  backupPaymentSource: FetchBackupPaymentSourceOutput;
  useDeleteBackupPaymentSourceMutation: UseDeleteBackupPaymentSourceMutation;
}

export const ManageCreditCardScreen: FC<ManageCreditCardScreenProps> = ({
  backupPaymentSource,
  useDeleteBackupPaymentSourceMutation,
  useUpdateBackupPaymentSourceMutation,
  useCreateBackupPaymentSourceMutation
}) => {
  const t = useTranslate('shared-admin-ui.ManageCreditCardScreen');
  const [backupPaymentSourceMutation] = useDeleteBackupPaymentSourceMutation();

  const { changeView } = useFinancialNavigationContext();

  const [showRemoveCreditCardModal, setShowRemoveCreditCardModal] = useState<boolean>(false);

  const onConfirmRemoveCreditCard = async () => {
    setShowRemoveCreditCardModal(false);
    await backupPaymentSourceMutation().unwrap();
    toast.success(t('successMessage'));
    changeView({ screen: FINANCIAL_SCREEN.DASHBOARD });
  };

  return (
    <section className={styles.ManageCreditCard}>
      <BackButton onClick={() => changeView({ screen: FINANCIAL_SCREEN.DASHBOARD })}>
        {t('financial')}
      </BackButton>
      <h2>
        {t('title')}
      </h2>
      <Text className={styles.ManageCreditCard__description}>
        {t('description')}
      </Text>

      <div className={styles.ManageCreditCard__cardSection}>
        <div className={styles.ManageCreditCard__actions}>
          <Actions
            variant="kebab"
            actions={[
              {
                title: t('delete', { global: true }),
                onClick: () => setShowRemoveCreditCardModal(true)
              }
            ]}
          />

          <ConfirmActionModal
            danger
            show={showRemoveCreditCardModal}
            title={t('confirmRemoveModal.title')}
            description={t('confirmRemoveModal.description', {
              danger: text => <span className="danger">{text}</span>
            })}
            submitText={t('remove', { global: true })}
            onHide={setShowRemoveCreditCardModal}
            onCancel={setShowRemoveCreditCardModal}
            onSubmit={onConfirmRemoveCreditCard}
          />
        </div>

        <CreditCard card={backupPaymentSource.card}/>

        <Subsection
          heading="new"
          marginTop="medium"
          title={t('updateSectionTitle')}
        >
          <CreditCardForm
            update
            useUpdateBackupPaymentSourceMutation={useUpdateBackupPaymentSourceMutation}
            useCreateBackupPaymentSourceMutation={useCreateBackupPaymentSourceMutation}
          />
        </Subsection>
      </div>
    </section>
  );
};
