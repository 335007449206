import React from 'react';

import { TextInput } from 'react-responsive-ui';

import { PhoneInput as PhoneNumberInput } from '@acadeum/ui';

let PhoneInput = (props, ref) => {
  return (
    <PhoneNumberInput
      ref={ref}
      inputComponent={TextInput}
      {...props}/>
  );
};

PhoneInput = React.forwardRef(PhoneInput);

export default PhoneInput;
