import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import classNames from 'classnames';
import React from 'react';

import styles from './BaseTooltip.module.scss';

export const BaseTooltipProvider = TooltipPrimitive.Provider;

export const BaseTooltip: React.FC<React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Root>> = ({
  children,
  delayDuration = 0,
  ...props
}) => (
  <BaseTooltipProvider>
    <TooltipPrimitive.Root
      {...props}
      delayDuration={delayDuration}
    >
      {children}
    </TooltipPrimitive.Root>
  </BaseTooltipProvider>
);

export const BaseTooltipTrigger = TooltipPrimitive.Trigger;

export const BaseTooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, children, ...props }, ref) => (
  <TooltipPrimitive.Portal>
    <TooltipPrimitive.Content
      ref={ref}
      sideOffset={sideOffset}
      className={classNames(className, styles.content)}
      {...props}
    >
      <BaseTooltipArrow/>
      {children}
    </TooltipPrimitive.Content>
  </TooltipPrimitive.Portal>
));
BaseTooltipContent.displayName = TooltipPrimitive.Content.displayName;

export const BaseTooltipArrow = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Arrow>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Arrow>
>(({ className, ...props }, ref) => (
  <TooltipPrimitive.Arrow
    ref={ref}
    className={classNames(className, styles.arrow)}
    {...props}
  />
));
BaseTooltipArrow.displayName = TooltipPrimitive.Arrow.displayName;
