import { useMemo } from 'react';

import parseTerm from 'common-lib/lib/parseTerm';

import type { FetchCourseEnrollmentsPropertyValuesInput } from '@acadeum/api';

import { useFetchCourseEnrollmentsPropertyValuesQuery } from '../../../api/courseEnrollment';

const createOption = (v) => ({ value: v, label: v });

export function useCourseEnrollmentsPropertyValues(input: FetchCourseEnrollmentsPropertyValuesInput) {
  const {
    data,
    error,
    isLoading: isLoadingPropertyValues
  } = useFetchCourseEnrollmentsPropertyValuesQuery(input);

  const {
    letterGradeOptions,
    courseOptions,
    sessionOptions,
    termOptions,
    yearOptions,
    codeOptions
  } = useMemo(() => {
    if (!data) {
      return {
        letterGradeOptions: [],
        courseOptions: [],
        sessionOptions: [],
        termOptions: [],
        yearOptions: [],
        codeOptions: []
      };
    }

    const letterGradeOptions = data.letterGrade.map(createOption);
    const sessionOptions = data.section.session.map(createOption);
    const codeOptions = data.section.code.map(createOption);
    const courseOptions = data.course.map((course) => ({
      value: course.id,
      label: `${course.title} (${course.code})`
    }));

    const { term, year } = data.section.term.reduce(
      (acc: { term: string[]; year: string[] }, item: string) => {
        const [parsedTerm, parsedYear] = parseTerm(item);
        if (parsedTerm) acc.term.push(parsedTerm);
        if (parsedYear) acc.year.push(parsedYear);
        return acc;
      },
      { term: [], year: [] }
    );

    const termOptions = Array.from(new Set(term)).map(createOption);
    const yearOptions = Array.from(new Set(year)).map(createOption);

    return {
      letterGradeOptions,
      sessionOptions,
      courseOptions,
      termOptions,
      yearOptions,
      codeOptions
    };
  }, [data]);

  return {
    letterGradeOptions,
    sessionOptions,
    courseOptions,
    termOptions,
    yearOptions,
    codeOptions,
    isLoading: isLoadingPropertyValues,
    error
  };
}
