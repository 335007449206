import type { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { onAccessTokenReceived } from './accessToken';
import {
  loginFailureAction,
  loginStartedAction,
  loginSuccessfulAction,
  notAuthenticatedAction,
  setAccessTokenExpiresAtAction,
  setIsAuthenticationLoadingAction,
  setUserAction,
  updateUserAction
} from './actions';
import type { AuthState } from './state';
import {
  getAccessTokenExpiresAtUpdatedState,
  getAuthenticationStartState,
  getIsAuthenticationLoadingUpdatedState,
  getLogInStartState,
  getLogInSuccessState,
  getNotAuthenticatedState,
  getUserUpdatedState
} from './state';
import type { AuthCookieConfig } from './types';

export const getAuthSlice = ({
  authCookieConfig,
  extraReducers
}: {
  authCookieConfig?: AuthCookieConfig
  extraReducers?: (builder: ActionReducerMapBuilder<AuthState>) => void
}) => {
  const initialState: AuthState = getAuthenticationStartState();

  return createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(loginStartedAction, () => getLogInStartState())
        .addCase(loginSuccessfulAction, (state, action) => {
          const { user, token, expiresIn, expiresAt } = action.payload;
          onAccessTokenReceived(token, { expiresIn, expiresAt }, { authCookieConfig });
          return getLogInSuccessState({ user, accessTokenExpiresAt: expiresAt });
        })
        .addCase(loginFailureAction, () => getNotAuthenticatedState())
        .addCase(notAuthenticatedAction, () => getNotAuthenticatedState())
        .addCase(setUserAction, (state, action) => getUserUpdatedState(state, action.payload))
        .addCase(setAccessTokenExpiresAtAction, (state, action) => {
          return getAccessTokenExpiresAtUpdatedState(state, action.payload);
        })
        .addCase(setIsAuthenticationLoadingAction, (state, action) => {
          return getIsAuthenticationLoadingUpdatedState(state, action.payload);
        })
        .addCase(updateUserAction, (state, action) => {
          return getUserUpdatedState(state, {
            ...state.user,
            ...action.payload
          });
        });

      // Error: `builder.addCase` should only be called before calling `builder.addMatcher`
      extraReducers?.(builder);
    }
  });
};
