import React from 'react';

import { useTranslate } from '@acadeum/translate';

import ConfirmationModal from '../ConfirmationModal';

export default function ResetPasswordConfirmationModal(props) {
  const t = useTranslate('ResetPassword');
  return (
    <ConfirmationModal
      {...props}
      title={t('successTitle')}
      text={t('successText')}
    />
  );
}
