import type { ColumnDef } from '@tanstack/react-table';
import { capitalize } from 'lodash-es';
import React, { useCallback, useMemo } from 'react';

import courseEnrollmentAcceptedStatuses from 'common-lib/constants/courseEnrollmentAcceptedStatuses.json';
import courseEnrollmentFinalizedStatuses from 'common-lib/constants/courseEnrollmentFinalizedStatuses.json';
import courseEnrollmentGradeStatuses from 'common-lib/constants/courseEnrollmentGradeStatuses.json';
import courseEnrollmentLiveStatuses from 'common-lib/constants/courseEnrollmentLiveStatuses.json';
import formatName from 'common-lib/lib/formatName';
import getLastDropDateForCourseEnrollment from 'common-lib/lib/getLastDropDateForCourseEnrollment';

import type { FetchCourseEnrollmentsOutputItem } from '@acadeum/api';
import { useQueryWithPagination } from '@acadeum/hooks';
import { useTranslate } from '@acadeum/translate';
import { createTableId, DataBlock, Filters, HStack, PageLoader, Table, Text } from '@acadeum/ui';
import type { TableProps } from '@acadeum/ui';

import { useFetchCourseEnrollmentsQuery } from '../../api/courseEnrollment';
import useOnError from '../../hooks/useOnError';


import { useCourseEnrollmentsPropertyValues } from './lib/useCourseEnrollmentsPropertyValues';
import { useExportCourseEnrollmentsColumns } from './lib/useExportCourseEnrollmentsColumns';
import { useFetchCourseEnrollmentsForExport } from './lib/useFetchCourseEnrollmentsForExport';
import type { CourseEnrollmentLifecyclePhase, CourseEnrollmentMode } from './types';

import { CourseEnrollmentActions } from './ui/CourseEnrollmentActions';
import { CourseEnrollmentsActions } from './ui/CourseEnrollmentsActions';
import { CourseEnrollmentStatusBadge } from './ui/CourseEnrollmentStatusBadge';
import { SameStatusSelectionAlert } from './ui/SameStatusSelectionAlert';

const STATUSES: { [Key in CourseEnrollmentLifecyclePhase]: string[] } = {
  accepted: courseEnrollmentAcceptedStatuses,
  live: courseEnrollmentLiveStatuses,
  finalized: courseEnrollmentFinalizedStatuses
};

interface CourseEnrollmentsProps {
  isGrade?: false;
  mode: CourseEnrollmentMode;
  lifecyclePhase: CourseEnrollmentLifecyclePhase;
}

interface GradeCourseEnrollmentsProps {
  isGrade: true;
  mode: CourseEnrollmentMode;
  lifecyclePhase?: never;
}

export type CourseEnrollmentsTableProps = CourseEnrollmentsProps | GradeCourseEnrollmentsProps;

const createOption = v => ({ value: v, label: capitalize(v) });

export const CourseEnrollmentsTable = ({
  lifecyclePhase,
  isGrade,
  mode
}: CourseEnrollmentsTableProps) => {
  const onError = useOnError();
  const t = useTranslate('CourseEnrollmentsTable');
  const tableId = createTableId(
    isGrade
      ? `grades.${mode}`
      : `courseEnrollments.${mode}.${lifecyclePhase}`
  );
  const exportDataFileName = t(
    `fileName.${mode}.${isGrade ? 'grades' : lifecyclePhase}`
  );

  const enrolmentStatuses = useMemo(() => {
    if (isGrade) {
      return courseEnrollmentGradeStatuses;
    }
    return STATUSES[lifecyclePhase];
  }, [lifecyclePhase]);

  const statusOptions = enrolmentStatuses.map(createOption);
  const {
    error: propertyValuesError,
    isLoading: isLoadingOptions,
    courseOptions,
    sessionOptions,
    termOptions,
    yearOptions,
    codeOptions,
    letterGradeOptions
  } = useCourseEnrollmentsPropertyValues({
    mode,
    filters: {
      status: enrolmentStatuses
    }
  });

  const onFiltersChange = (filters_) => {
    const filters = structuredClone(filters_);

    if ('status_' in filters) {
      filters['status'] = [filters['status_']];
    } else {
      filters['status'] = enrolmentStatuses;
    }

    _filtersOptions.onFiltersChange(filters);
  };

  const {
    data,
    isLoading: isLoadingEnrollmentRequests,
    isFetching: isFetchingEnrollmentRequests,
    error: enrollmentsError,
    _filtersOptions,
    _paginationOptions,
    _globalFilterOptions,
    _sortingOptions
  } = useQueryWithPagination(useFetchCourseEnrollmentsQuery, {
    additional: {
      mode
    },
    filters: {
      hasGrade: isGrade,
      status: enrolmentStatuses
    }
  });

  const columns = useColumns({ isGrade, mode });
  const fetchDataForExport = useFetchCourseEnrollmentsForExport({
    mode,
    filters: _filtersOptions.filters,
    search: _globalFilterOptions.globalFilter
  });

  const exportDataColumns = useExportCourseEnrollmentsColumns({
    teaching: mode === 'teachingInstitution',
    finalized: isGrade ? true : lifecyclePhase === 'finalized',
    grades: Boolean(isGrade)
  });

  const rows = data?.results;
  const isLoading = isLoadingOptions || isLoadingEnrollmentRequests;
  const error = enrollmentsError || propertyValuesError;

  const renderTopBanner: TableProps<FetchCourseEnrollmentsOutputItem>['renderTopBanner'] = useCallback((props) => {
    if (isGrade) {
      return null;
    }

    return (
      <SameStatusSelectionAlert
        tableId={tableId}
        enrollments={props.selectedRows}
      />
    );
  }, []);

  const renderTopLeftToolbarCustomActions: TableProps<FetchCourseEnrollmentsOutputItem>['renderTopLeftToolbarCustomActions'] = useCallback((props) => {
    if (isGrade) {
      return null;
    }

    return (
      <CourseEnrollmentsActions
        mode={mode}
        enrollments={props.selectedRows}
        downloadRow={props.downloadRows}
      />
    );
  }, []);

  if (error) {
    return onError(error);
  }

  if (isLoading) {
    return (
      <PageLoader/>
    );
  }

  return (
    <>
      <Filters
        border
        values={_filtersOptions.filters}
        onFiltersChange={onFiltersChange}
      >
        <Filters.Row>
          {mode === 'teachingInstitution' && (
            <Filters.Institution
              name="homeInstitution.id"
              label="Home Institution"
              type="institution"
            />
          )}
          {mode === 'homeInstitution' && (
            <Filters.Institution
              name="section.course.institution.id"
              label="Teaching Institution"
              type="teachingInstitution"
            />
          )}
          <Filters.Select
            name="section.course.id"
            label="Course"
            // @ts-expect-error - options is an array of Option<number> TODO: fix types
            options={courseOptions}
          />
          <Filters.Select
            name="section.year"
            label="Year"
            options={yearOptions}
          />
          <Filters.Select
            name="section.term"
            label="Term"
            options={termOptions}
          />
          <Filters.Select
            name="section.session"
            label="Session"
            options={sessionOptions}
          />
          {isGrade ? (
            <Filters.Select
              name="letterGrade"
              label="Letter Grade"
              options={letterGradeOptions}
            />
          ) : (
            <>
              <Filters.Select
                name="section.code"
                label="Section"
                options={codeOptions}
              />
              <Filters.Select
                name="status_"
                label="Status"
                options={statusOptions}
              />
            </>
          )}
        </Filters.Row>
      </Filters>
      <Table
        id={tableId}
        data={rows}
        columns={columns}
        enableRowSelection
        hasColumnVisibility
        isFetching={isFetchingEnrollmentRequests}
        paginationOptions={_paginationOptions}
        globalFilterOptions={_globalFilterOptions}
        sortingOptions={_sortingOptions}
        columnPinningRight={['actions']}
        renderTopBanner={renderTopBanner}
        renderTopLeftToolbarCustomActions={renderTopLeftToolbarCustomActions}
        exportOptions={{
          type: 'xlsx',
          fileName: exportDataFileName,
          exportDataColumns,
          fetchDataForExport
        }}
      />
    </>
  );
};

function useColumns({
  mode,
  isGrade
}: {
  isGrade?: boolean;
  mode: CourseEnrollmentMode;
}) {
  const t = useTranslate('CourseEnrollmentsTable');
  return useMemo<ColumnDef<FetchCourseEnrollmentsOutputItem>[]>(() => {
    const institutionColumn: ColumnDef<FetchCourseEnrollmentsOutputItem> = mode === 'homeInstitution' ? {
      accessorKey: 'section.course.institution.name',
      enableSorting: true,
      header: t('teachingInstitution'),
      cell: ({ row }) => (
        <DataBlock
          type="institution"
          institution={row.original.section.course.institution}
        />
      )
    } : {
      accessorKey: 'homeInstitution.name',
      enableSorting: true,
      header: t('homeInstitution'),
      cell: ({ row }) => {
        if (!row.original.homeInstitution) {
          return null;
        }
        return (
          <DataBlock
            type="institution"
            institution={row.original.homeInstitution}
          />
        );
      }
    };

    return [
      {
        id: 'student.name',
        enableSorting: true,
        accessorFn: (row) => formatName(row.student),
        header: t('student'),
        cell: ({ row }) => {
          if (!row.original.student) {
            return null;
          }

          return (
            <DataBlock
              teaching={mode === 'teachingInstitution'}
              type="student"
              student={row.original.student}
            />
          );
        }
      },
      institutionColumn,
      ...(isGrade
        ? []
        : (mode === 'homeInstitution' ? [
          {
            accessorKey: 'createdAt',
            enableSorting: true,
            header: t('requested'),
            cell: ({ row }) => (
              <DataBlock
                type="date"
                date={row.original.createdAt}
                utc={false}
                month="short"
              />
            )
          }
        ] : [
          {
            accessorKey: 'type',
            enableSorting: true,
            header: t('type')
          }
        ])),
      {
        accessorKey: 'section.course.code',
        enableSorting: true,
        header: t('course'),
        cell: ({ row }) => (
          <DataBlock
            type="course"
            course={row.original.section.course}
          />
        )
      },
      ...(isGrade ? [{
        accessorKey: 'section.course.hours',
        header: t('credits'),
        enableSorting: true
      }] : []),
      {
        accessorKey: 'section.session',
        enableSorting: true,
        header: t('termSession'),
        meta: {
          emptyWhen: ({ row }) => row.section.course.onDemand
        },
        cell: ({ row }) => (
          <div>
            <Text>
              {row.original.section.term}
            </Text>
            <Text color="grey">
              {row.original.section.session}
            </Text>
          </div>
        )
      },
      ...(!isGrade || (isGrade && mode === 'homeInstitution') ? [{
        accessorKey: 'section.code',
        enableSorting: true,
        header: t('section')
      }] : []),
      ...(isGrade ? [
        {
          accessorKey: 'letterGrade',
          header: t('letterGrade'),
          enableSorting: true
        },
        {
          accessorKey: 'numericalGrade',
          header: t('numericalGrade'),
          enableSorting: true
        }
      ] : [
        {
          accessorKey: 'section.dates',
          header: t('dates'),
          meta: {
            emptyWhen: ({ row }) => row.section.course.onDemand && !row.startedAt
          },
          cell: ({ row }) => {
            const onDemand = row.original.section.course.onDemand;
            if (onDemand) {
              if (row.original.startedAt) {
                const {
                  lastDropDate,
                  lastDropDateIsInUtc0TimeZone
                } = getLastDropDateForCourseEnrollment({
                  onDemand: row.original.section.course.onDemand,
                  onDemandDropDateDaysAfterStartDate: row.original.section.course.institution.onDemandCourseDropDateDaysAfterStartDate,
                  startedAt: row.original.startedAt,
                  lastDropDate: row.original.section.lastDropDate
                });
                if (lastDropDate) {
                  return (
                    <Text as="div" nowrap>
                      <HStack gap="xs">
                        Drop Date:
                        <DataBlock
                          date={lastDropDate}
                          utc={lastDropDateIsInUtc0TimeZone}
                          type="date"
                          month="short"
                        />
                      </HStack>
                    </Text>
                  );
                }
              }
              return null;
            }

            return (
              <DataBlock
                type="courseSessionDates"
                withLastDropDate
                session={row.original.section}
              />
            );
          }
        },
        {
          accessorKey: 'status',
          enableSorting: true,
          header: t('status', { global: true }),
          cell: ({ row }) => (
            <CourseEnrollmentStatusBadge
              mode={mode}
              enrollment={row.original}
            />
          )
        }
      ]),
      {
        id: 'actions',
        size: 60,
        cell: ({ row, downloadRow }) => (
          <CourseEnrollmentActions
            enrollment={row.original}
            downloadRow={downloadRow}
            isGrade={isGrade}
            mode={mode}
          />
        )
      }
    ];
  }, [mode, isGrade]);
}
