import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { getErrorData } from '@acadeum/helpers';
import { useTranslate } from '@acadeum/translate';
import {
  ContentSection,
  Form,
  FormField,
  FormFooter,
  FormSubmit,
  Separator,
  StickyFormFooter,
  Text
} from '@acadeum/ui';

import actions from '../../../../actions';
import CourseOverviewSection from '../CourseOverviewSection';


import './CourseOverview.sass';

const {
  addCourseSubstitute,
  fetchCourseSubstitutes,
  updateEnrollmentState,
  notify,
  goto
} = actions;

export default function CourseOverview({ navigation }) {
  const section = useSelector(state => state.sections.section);
  const enrollmentState = useSelector(state => state.enroll.enrollmentState);
  const substitutes = useSelector(state => state.courseSubstitutes.substitutes);

  const onSubmit = async (values) => {
    updateEnrollmentState({
      sectionId: section.id,
      courseSubstituteId: Number(values.courseSubstituteId),
      currentStepId: 'enrollStudents'
    });
    navigation.next();
  };

  return (
    <EnrollmentForm
      section={section}
      substitutes={substitutes}
      courseSubstituteId={enrollmentState && enrollmentState.courseSubstituteId}
      onSubmit={onSubmit}
    />
  );
}

CourseOverview.propTypes = {
  navigation: PropTypes.shape({
    previous: PropTypes.func,
    next: PropTypes.func,
    go: PropTypes.func
  }).isRequired
};

function EnrollmentForm({
  section,
  substitutes,
  courseSubstituteId,
  onSubmit
}) {
  const { session } = section;
  const { course } = session;

  const t = useTranslate('CreateEnrollment');

  const getSubstituteOptions = () => {
    return substitutes.map(({ id, equivalentCourse }) => ({
      value: id,
      label: `${equivalentCourse.code} ${equivalentCourse.title}`
    }));
  };

  const substituteForm = () => {
    return (
      <div>
        <FormField
          required
          name="code"
          label={t('substituteForm.code.label')}
          placeholder={t('substituteForm.code.placeholder')}
        />
        <FormField
          required
          name="title"
          label={t('substituteForm.title.label')}
          placeholder={t('substituteForm.title.placeholder')}
        />
        <FormFooter>
          <FormSubmit>
            {t('save', { global: true })}
          </FormSubmit>
        </FormFooter>
      </div>
    );
  };

  const createNewSubstitutionOptionProps = {
    label: t('substituteForm.createNew'),
    children: substituteForm,
    onSubmit: async (values) => {
      if (!values.code || !values.title) {
        throw new Error(t('substituteForm.substituteError'));
      }

      let substitute;
      try {
        substitute = await addCourseSubstitute({
          homeCourseCode: values.code,
          homeCourseTitle: values.title,
          courseId: course.id
        });
        await fetchCourseSubstitutes(course.id);
      }
      catch (error) {
        if (getErrorData(error).message === 'duplicate_course_substitute') {
          return notify(t('substituteDuplicateError'));
        }
        throw error;
      }

      return {
        value: substitute.id,
        label: `${values.code} ${values.title}`
      };
    }
  };

  return (
    <ContentSection border={false} padding="none">
      <Form onSubmit={onSubmit}>
        <ContentSection className="CourseOverview">
          <CourseOverviewSection
            course={course}
            section={section}
          />
          <Separator/>
          <ContentSection
            padding="none"
            title={t('courseSubstitution')}
            border={false}
          >
            <ContentSection padding="none" border={false}>
              <Text className="CourseOverview__subsectionDescription">
                {t('courseSubstitutionDescription')}
              </Text>
              <FormField
                required
                noMargin
                type="select"
                label={t('equivalentCourseAtHomeInstitution')}
                name="courseSubstituteId"
                placeholder={t('selectCourse')}
                defaultValue={courseSubstituteId}
                options={getSubstituteOptions()}
                createOptionProps={createNewSubstitutionOptionProps}
              />
            </ContentSection>
          </ContentSection>
        </ContentSection>
        <StickyFormFooter
          onCancelProps={{
            children: 'Cancel',
            onClick: () => goto(`/sections/${section.id}`)
          }}
          submitProps={{
            children: 'Next'
          }}
        />
      </Form>
    </ContentSection>
  );
}

EnrollmentForm.propTypes = {
  section: PropTypes.object.isRequired,
  substitutes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    equivalentCourse: PropTypes.shape({
      code: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired
    })
  })),
  courseSubstituteId: PropTypes.number,
  onSubmit: PropTypes.func.isRequired
};
