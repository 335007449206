import { createSlice } from '@reduxjs/toolkit';

import type { HttpError } from '@acadeum/helpers';

type HttpErrorState = null | HttpError;

interface PartialReduxState {
  httpError: HttpErrorState;
}

export const httpErrorSlice = createSlice({
  name: 'httpError',
  initialState: null as HttpErrorState,
  reducers: {
    setError: (state, action) => action.payload,
    clearError: () => null
  },
  extraReducers: (builder) => {
    builder.addDefaultCase((state, action) => {
      if (action.type === '__NEXT_REDUX_WRAPPER_HYDRATE__') {
        return action.payload.httpError;
      }
    });
  }
});

export const selectHttpError = (state: PartialReduxState) => state.httpError;
