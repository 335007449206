import type { RowData } from '@tanstack/react-table';
import { uniq } from 'lodash-es';

import type { Institution } from '@acadeum/types';

import { Select } from '../../../Select';

import type { FilterItemObject } from '../types';

export const createTableInstitutionFilter = <TData extends RowData>({
  getInstitutions,
  getInstitution = row => (row['section']['session']['course']['institution'] as Institution),
  label = 'Institution'
}: {
  getInstitution?: (row: TData) => Pick<Institution, 'name' | 'id'>,
  getInstitutions?: (row: TData) => Pick<Institution, 'name' | 'id'>[],
  label?: string;
} = {}): FilterItemObject<TData> => {
  return {
    id: 'institution',
    filter(row, value) {
      if (getInstitutions) {
        return getInstitutions(row).some(institution => institution.name === value);
      } else {
        return getInstitution(row).name === value;
      }
    },
    render({ getValue, rows, setFilterValues }) {
      function getSessionNameIfFits(institution) {
        return institution.name;
      }

      let institutions: string[];

      if (getInstitutions) {
        institutions = rows.reduce<string[]>((all, row) =>
          all.concat(
            getInstitutions(row)
              .map((institution) => getSessionNameIfFits(institution))
              .filter(_ => _)
          ), []);
      } else {
        institutions = rows
          .map((row) => getSessionNameIfFits(getInstitution(row)))
          .filter(_ => _);
      }

      institutions = uniq(institutions);

      const options = [
        { label: 'All', value: null },
        ...institutions.map((institution) => ({
          label: institution,
          value: institution
        }))
      ];

      return (
        <Select
          isFilter
          label={label}
          value={getValue() ?? null}
          options={options}
          onChange={(value, option) => {
            setFilterValues(prev => ({
              ...prev,
              institution: option
            }));
          }}
        />
      );
    }
  };
};
