import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import tc from 'tinycolor2';

import { Select } from '../../Select';

import { usePicker } from '../context';
import { formatInputValues } from '../utils/formatters';

const Input = ({
  suffix,
  value,
  callback,
  max = 100
}: {
  max?: number;
  suffix?: string;
  value: number | string;
  callback: (arg0: number) => void;
}) => {
  const [temp, setTemp] = useState(value);
  const { hideOpacity } = usePicker();
  const width = hideOpacity ? '22%' : '18%';

  useEffect(() => {
    setTemp(value);
  }, [value]);

  const onChange = (e: any) => {
    const newVal = formatInputValues(parseFloat(e.target.value), 0, max);
    setTemp(newVal);
    callback(newVal);
  };

  return (
    <div style={{ width: width, flex: '1 1 0px', position: 'relative' }}>
      <input
        className={classNames('rbgcp-input', {
          'rbgcp-opacity-input': suffix
        })}
        value={temp}
        onChange={(e) => onChange(e)}
      />
      {suffix && (
        <span className="rbgcp-input-suffix">{suffix}</span>
      )}
    </div>
  );
};

const HexInput = ({ opacity }: { opacity: number }) => {
  const { handleChange, tinyColor } = usePicker();
  const [disable, setDisable] = useState('');
  const hex = tinyColor.toHex();
  const [newHex, setNewHex] = useState(hex);

  useEffect(() => {
    if (disable !== 'hex') {
      setNewHex(hex);
    }
  }, [tinyColor, disable, hex]);

  const hexFocus = () => {
    setDisable('hex');
  };

  const hexBlur = () => {
    setDisable('');
  };

  const handleHex = (e: any) => {
    const tinyHex = tc(e.target.value);
    setNewHex(e.target.value);
    if (tinyHex.isValid()) {
      const { r, g, b } = tinyHex.toRgb();
      const newColor = `rgba(${r}, ${g}, ${b}, ${opacity})`;
      handleChange(newColor);
    }
  };

  return (
    <div style={{ width: '23%', flex: '1 1 0px' }}>
      <input
        value={newHex}
        onBlur={hexBlur}
        onFocus={hexFocus}
        onChange={(e) => handleHex(e)}
        className="rbgcp-input rbgcp-hex-input"
      />
    </div>
  );
};

const RGBInputs = () => {
  const { handleChange, hc } = usePicker();

  const handleRgb = ({ r, g, b }: { r: number; g: number; b: number }) => {
    handleChange(`rgba(${r}, ${g}, ${b}, ${hc?.a})`);
  };

  return (
    <div className="rbgcp-rgb-inputs">
      <span className="rbgcp-rgb-label">R:</span>
      <input
        className="rbgcp-rgb-input"
        value={hc.r}
        onChange={(e) => handleRgb({r: Number(e.target.value), g: hc.g, b: hc.b})}
      />
      <span className="rbgcp-rgb-label">G:</span>
      <input
        className="rbgcp-rgb-input"
        value={hc.g}
        onChange={(e) => handleRgb({r: hc.r, g: Number(e.target.value), b: hc.b})}
      />
      <span className="rbgcp-rgb-label">B:</span>
      <input
        className="rbgcp-rgb-input"
        value={hc.b}
        onChange={(e) => handleRgb({r: hc.r, g: hc.g, b: Number(e.target.value)})}
      />
    </div>
  );
};

const Inputs = ({ inputType, setInputType }) => {
  const { handleChange, hideOpacity, hc } = usePicker();

  const onChange = (value) => {
    setInputType(value);
    const newColor = value === 'hex' ? tc(hc).toHex8String().toUpperCase() : tc(hc).toRgbString();
    handleChange(newColor);
  };

  return (
    <div
      style={{
        paddingTop: 14,
        display: 'flex',
        gap: '1rem',
        justifyContent: 'space-between'
      }}
      className="rbgcp-inputs-wrap"
    >
      {inputType !== 'rgb' && <HexInput opacity={hc?.a}/>}
      {inputType === 'rgb' && <RGBInputs/>}

      {!hideOpacity && (
        <Input
          suffix="%"
          value={Math.round(hc?.a * 100)}
          callback={(newVal: number) =>
            handleChange(`rgba(${hc?.r}, ${hc?.g}, ${hc?.b}, ${newVal / 100})`)
          }
        />
      )}

      <Select
        value={inputType}
        options={[
          {value: 'hex', label: 'HEX'},
          {value: 'rgb', label: 'RGB'}
        ]}
        onChange={onChange}
      />
    </div>
  );
};

export default Inputs;
