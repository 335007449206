import classNames from 'classnames';
import React from 'react';
import type { FC } from 'react';

import { SkipToMainContent } from '../SkipToMainContent';

import styles from './AppWrapper.module.scss';
import { Header } from './Header';
import { Main } from './Main';


export interface AppWrapperProps {
  className?: string;
  children: React.ReactNode;
}

export const AppWrapper: FC<AppWrapperProps> & {
  Header: typeof Header;
  Main: typeof Main;
} = ({
  className,
  children
}) => {
  return (
    <>
      <SkipToMainContent/>
      <div className={classNames(styles.root, className)}>
        {children}
      </div>
    </>
  );
};

AppWrapper.Header = Header;
AppWrapper.Main = Main;
