import { useEffect, useMemo, useState } from 'react';
import type { FC } from 'react';

import getLastDropDateForCourseEnrollment from 'common-lib/lib/getLastDropDateForCourseEnrollment';


import { useOnError } from '@acadeum/shared-admin-ui';
import { useTranslate, useTranslateOptions } from '@acadeum/translate';
import type { Id } from '@acadeum/types';
import {
  Actions,
  createTableCourseFilter,
  createTableInstitutionFilter,
  createTableSessionFilter,
  createTableStatusFilter,
  createTableYearFilter,
  DataBlock,
  HStack,
  Table,
  Tag,
  Text,
  ToggleButton,
  createTableId
} from '@acadeum/ui';

import { useFetchStudentCourseEnrollmentsQuery } from '../../../api/student';
import { useFetchTeachingStudentCourseEnrollmentsQuery } from '../../../api/teachingStudents';


import styles from './Enrollments.module.scss';
import { useExportDataColumns } from './useExportDataColumns';

interface EnrollmentsProps {
  idStudent: Id;
  type?: 'EM' | 'TM';
}

type EnrollmentType = 'live' | 'finalized' | 'accepted';

const Enrollments: FC<EnrollmentsProps> = ({ type, idStudent }) => {
  const t = useTranslate('StudentDetails');
  const exportDataColumnsAccepted = useExportDataColumns({ teaching: type === 'TM' });
  const [typeEnrollments, setTypeEnrollments] = useState<EnrollmentType>(type === 'TM' ? 'accepted' : 'live');
  const [query, setQuery] = useState<object>({
    pageSize: 100000,
    filters: {
      type: typeEnrollments
    }
  });

  const onError = useOnError();

  const { data, isLoading, isFetching, error } = (type === 'TM' ? useFetchTeachingStudentCourseEnrollmentsQuery : useFetchStudentCourseEnrollmentsQuery)({ id: idStudent, ...query });
  const columns = useMemo(() => [
    {
      accessorKey: 'course',
      header: 'Course',
      cell: ({ row }) => <DataBlock course={row.original.courseSection.course} type="course" />
    },

    {
      accessorKey: 'institution',
      header: type === 'TM' ? 'Teaching Institution' : 'Home Institution',
      cell: ({ row }) => <DataBlock type="institution" institution={row.original.courseSection.course.institution} />
    },
    {
      id: 'session',
      accessorFn: (original) => `${original.courseSection.name} ${original.courseSection.term}`,
      header: 'Term & Session',
      cell: ({ row }) => <DataBlock type="term&session" session={row.original.courseSection} />
    },
    {
      header: 'Section',
      accessorKey: 'courseSection.number'
    },
    {
      accessorKey: 'dates',
      header: 'dates',
      cell: ({ row }) => {
        const onDemand = row.original.courseSection.course.onDemand;
        if (onDemand) {
          const {
            lastDropDate,
            lastDropDateIsInUtc0TimeZone
          } = getLastDropDateForCourseEnrollment({
            onDemand,
            onDemandDropDateDaysAfterStartDate: row.original.courseSection.course.institution.onDemandCourseDropDateDaysAfterStartDate,
            startedAt: row.original.startedAt,
            lastDropDate: row.original.courseSection.lastDropDate
          });
          if (lastDropDate) {
            return (
              <Text as="div" nowrap>
                <HStack gap="xs">
                  Drop Date:
                  <DataBlock
                    date={lastDropDate}
                    utc={lastDropDateIsInUtc0TimeZone}
                    type="date"
                    month="short"
                  />
                </HStack>
              </Text>
            );
          }
          return null;
        }

        return (
          <DataBlock
            type="courseSessionDates"
            withLastDropDate
            session={row.original.courseSection}
          />
        );
      }
    },
    {
      header: 'Grade',
      accessorKey: 'letterGrade'
    },
    {
      header: 'status',
      accessorKey: 'status',
      cell: ({ row }) => <Tag variant={row.original.status} />
    },
    {
      id: 'actions',
      size: 60,
      cell: ({ downloadRow }) => (
        <Actions
          variant="kebab"
          actions={[
            {
              title: 'Download',
              onClick: downloadRow
            }
          ]}
        />
      )
    }
  ], [type]);

  useEffect(() => {
    setQuery({
      ...query,
      filters: {
        type: typeEnrollments
      }
    });
  }, [typeEnrollments]);

  const filters = useMemo(() => [
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    createTableInstitutionFilter<any>({
      label: type === 'TM' ? t('teachingInstitution') : t('homeInstitution'),
      getInstitution: row => {
        return row.courseSection.course.institution;
      }
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    createTableCourseFilter<any>({
      getCourse: (row) => row.courseSection.course
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    createTableYearFilter<any>({
      getTerm: (row) => row.courseSection.term
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    // createTableTermFilter<any>({
    //   getTerm: row => row.session.term
    // }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    createTableSessionFilter<any>({
      getSession: row => row.courseSection.session
    }),
    // // // eslint-disable-next-line @typescript-eslint/no-explicit-any
    // createTableDefaultFilter<any>({
    //   id: 'section',
    //   label: 'Section',
    //   getFilterValue: row => row.number
    // }),
    createTableStatusFilter({
      sort: (a, b) => {
        const orderA = STATUS_FILTER_OPTIONS_ORDER.indexOf(a);
        const orderB = STATUS_FILTER_OPTIONS_ORDER.indexOf(b);
        if (orderA > orderB) {
          return 1;
        } if (orderA < orderB) {
          return -1;
        } 
        return 0;
        
      }
    })
  ], [type]);

  const toggleOptions = useTranslateOptions<EnrollmentType>('Enrollments', type === 'TM' ? ['accepted', 'finalized'] : ['live', 'finalized']);

  if (error) {
    return onError(error);
  }

  return (
    <>
      <ToggleButton
        value={typeEnrollments}
        onChange={setTypeEnrollments}
        label={t('manageEnrollments')}
        options={toggleOptions}
        className={styles.ToggleButton}
      />
      <Table
        enableGlobalFilter
        data={data?.results}
        loading={isLoading}
        isFetching={isFetching}
        hasColumnVisibility
        enableRowSelection
        clientFilters={filters}
        id={createTableId('studentEnrollmentsAll')}
        columns={columns}
        exportOptions={{
          type: 'xlsx',
          fileName: t('exportFileName'),
          exportDataColumns: exportDataColumnsAccepted
        }}
      />
    </>
  );
};

export default Enrollments;

const STATUS_FILTER_OPTIONS_ORDER = [
  'SENT',
  'REGISTRATION_REQUESTED',
  'REMOVED'
];
