import { useMemo, useState } from 'react';
import type { FC } from 'react';
import { useSelector } from 'react-redux';

import getMaxCourseSectionStartDateTimeTillWhichStudentsAreAbleToRequestEnrollment from 'common-lib/lib/getMaxCourseSectionStartDateTimeTillWhichStudentsAreAbleToRequestEnrollment';

import { isAcadeumAdministrator, userHasPermission, getCourseSectionCost } from '@acadeum/helpers';
import { Alert, FormField, FormModal, SectionCard, SuccessModal } from '@acadeum/ui';
import type { OnSubmit } from '@acadeum/ui';

import actions from '../../../../actions';
import { ALGOLIA_UPDATE_PENDING_NOTE } from '../../../../helpers/algolia';
import type { ReduxState } from '../../../../helpers/app.types';
import { canEnrollInSection, isCourseApproved, isSessionScheduled } from '../../../../helpers/course';
import { useCanViewCoursePrices, useCanEnrollStudentsInCourses } from '../../../../hooks/permissions';
import IconButton from '../../../IconButton';
import ChangeLastAddDateModal from '../ChangeLastAddDateModal';
import SectionCostExplanationModal from '../SectionCostExplanationModal';
import UseDefaultScheduleModal from '../UseDefaultScheduleModal';


import styles from './CourseSection.module.scss';

const { deactivateSection } = actions;

const {
  resetEnrollmentState
} = actions;


interface SubmitDeactivateCourse {
  notes?: string;
}

export function CourseSection({
  course,
  section,
  session,
  onRecommend
}) {
  const user = useSelector((state: ReduxState) => state.auth.user);

  const userDataLoaded = useSelector((state: ReduxState) => state.app.userDataLoaded);

  const {
    courseEnrollmentPricing,
    homeInstitutionTeachingInstitutionRelationshipIds,
    homeConsortiumTeachingInstitutionRelationships
  } = useSelector((state: ReduxState) => state.coursePricing);

  const [showRemoveCourseSectionModal, setShowRemoveCourseSectionModal] = useState(false);
  const [showRemoveCourseSectionSuccessModal, setShowRemoveCourseSectionSuccessModal] = useState(false);
  const [showEditLastAddDateModal, setShowEditLastAddDateModal] = useState(false);
  const [showSectionCostExplanationModal, setShowSectionCostExplanationModal] = useState(false);
  const [showUseDefaultScheduleModal, setShowUseDefaultScheduleModal] = useState(false);

  const cost = useGetCourseSectionCost({
    section,
    session,
    course,
    user,
    courseEnrollmentPricing,
    homeInstitutionTeachingInstitutionRelationshipIds,
    homeConsortiumTeachingInstitutionRelationships
  });

  const showEnroll = useCanEnrollStudentsInCourses();
  const isAvalableForEnrollment = canEnrollInSection(section, course);
  const canEdit = user && user.institution.id === course.institution.id && userHasPermission(user, 'course:update', {
    orgId: course.institution.id,
    ownerId: null
  });
  const canEditSchedule = user && userHasPermission(user, 'courseApproval:update', {
    orgId: user.institution.id,
    ownerId: null
  });

  const showApprovedIcon = user && course.institution.id !== user.institution.id && isCourseApproved(course, user.institution.id);
  const isScheduled = user && isSessionScheduled(section.session, course, user.institution.id);
  const { onDemand } = course;

  const isStartingTooLateToRegister = useMemo(() => {
    return section.session.startDate.getTime() > getMaxCourseSectionStartDateTimeTillWhichStudentsAreAbleToRequestEnrollment();
  }, [section]);

  const onDeactivateCourseSection: OnSubmit<SubmitDeactivateCourse> = async ({ notes }) => {
    await deactivateSection(section.id, { notes });
    setShowRemoveCourseSectionModal(false);
    setShowRemoveCourseSectionSuccessModal(true);
  };

  const onRemove = () => setShowRemoveCourseSectionModal(true);

  /* When a course page is viewed by a non-logged-in user, there'll be no `cost` property
     because it's not present in `public-courses` Algolia index. */
  /* When a course page is viewed by a logged-in user, there'll be a `cost` property
     but the app should wait for the "user data" to be loaded.
     "User data" includes the pricing settings that're required to show the correct price
     in order to not misinform the user by briefly showing an incorrect price
     while the application is still loading. In those cases, while it's still loading,
     the `cost` will be set to `undefined`. */
  const costToShow = typeof cost === 'number' ? (userDataLoaded ? cost : undefined) : undefined;

  const costActionsElement = user && isAcadeumAdministrator(user) && (
    <IconButton
      onClick={() => setShowSectionCostExplanationModal(true)}
      title="Info"
      icon="info"
      className={styles.iconButton}
    />
  );

  const showRecommend = userHasPermission(user, 'homeCourseAdvisorRecommendation:create', {
    orgId: user?.institution.id
  });

  return (
    <>
      <SectionCard>
        <SectionCard.Header
          sectionUrl={`/sections/${section.id}`}
          onRemove={!onDemand && canEdit ? onRemove : undefined}
          section={{ name: section.number }}
        />
        <>
          {onDemand ? (
            <SectionCard.OnDemandRow
              cost={costToShow}
              costActionsElement={costActionsElement}
              onDemandCourseDropDateDaysAfterStartDate={course.institution.onDemandCourseDropDateDaysAfterStartDate}
              onDemandCourseEndDateDaysAfterStartDate={course.institution.onDemandCourseEndDateDaysAfterStartDate}
            />
          ) : (
            <>
              <SectionCard.TermSessionStartEndDateRow
                section={{
                  session: session.name,
                  endDate: session.endDate,
                  startDate: session.startDate,
                  term: session.term
                }}
              />
              <SectionCard.Separator />
              <SectionCard.AddAndDropDateCostRow
                cost={costToShow}
                section={{
                  lastDropDate: session.lastDropDate,
                  lastAddDate: session.lastAddDate
                }}
                costActionsElement={costActionsElement}
                addDateActionsElement={(
                  <>
                    {user && isAcadeumAdministrator(user) && (
                      <IconButton
                        onClick={() => setShowEditLastAddDateModal(true)}
                        title="Edit"
                        icon="edit"
                        className={styles.iconButton}
                      />
                    )}
                  </>
                )}
              />
              <SectionCard.Separator />
              <SectionCard.ScheduleRow schedule={section.schedule} />
            </>
          )}
        </>

        {user && (
          <>
            <SectionCard.Separator />

            <SectionCard.Footer
              onDemand={onDemand}
              isScheduled={isScheduled}
              showApprovedIcon={showApprovedIcon}
              courseUrl={`/courses/${course.id}`}
              isAvalableForEnrollment={isAvalableForEnrollment}
              isStartingTooLateToRegister={isStartingTooLateToRegister}
              editScheduleUrl={canEditSchedule ? `/courses/${course.id}/approve` : undefined}
              onResetSchedule={canEditSchedule ? () => setShowUseDefaultScheduleModal(true) : undefined}
              enrollProps={showEnroll ? {
                onEnroll: () => resetEnrollmentState(),
                url: `/sections/${section.id}/enroll`
              } : undefined}
              recommendProps={showRecommend ? {
                onRecommend,
                url: `/courses/${course.id}/recommend${section ? `?sectionId=${  section.id}` : ''}`
              } : undefined}
            />
          </>
        )}
      </SectionCard>

      <RemoveCourseSectionModal
        show={showRemoveCourseSectionModal}
        onHide={() => setShowRemoveCourseSectionModal(false)}
        onDeactivateCourseSection={onDeactivateCourseSection}
      />
      <SuccessModal
        show={showRemoveCourseSectionSuccessModal}
        onHide={() => setShowRemoveCourseSectionSuccessModal(false)}
        children={`Course section has been removed successfully. ${  ALGOLIA_UPDATE_PENDING_NOTE}`}
      />
      <UseDefaultScheduleModal
        show={showUseDefaultScheduleModal}
        onHide={() => setShowUseDefaultScheduleModal(false)}
        courseId={course.id}
      />
      {user && isAcadeumAdministrator(user) &&
        <>
          <ChangeLastAddDateModal
            session={session}
            show={showEditLastAddDateModal}
            onHide={() => setShowEditLastAddDateModal(false)}
          />
          <SectionCostExplanationModal
            section={section}
            session={session}
            course={course}
            show={showSectionCostExplanationModal}
            onHide={() => setShowSectionCostExplanationModal(false)}
          />
        </>
      }
    </>
  );
}

function useGetCourseSectionCost({
  section,
  session,
  course,
  user,
  courseEnrollmentPricing,
  homeInstitutionTeachingInstitutionRelationshipIds,
  homeConsortiumTeachingInstitutionRelationships
}) {
  const canViewCoursePrices = useCanViewCoursePrices();

  return useMemo(() => {
    // When `user` is authenticated, it switches from using `public-sections` Algolia index
    // to using `sections` Algolia index. Until it switches the index, `session.cost` is `undefined`.
    // That's why it waits for both `user` and `session.cost` to be defined.
    if (user && session.cost && canViewCoursePrices) {
      return getCourseSectionCost({
        cost: session.cost,
        courseCustomCosts: {
          customCostByHomeInstitutionRelationshipId: course.customCostByHomeInstitutionRelationshipId,
          customCostByHomeConsortiumRelationshipId: course.customCostByHomeConsortiumRelationshipId
        },
        courseSectionCustomCosts: {
          // `section.customCosts` is a legacy property name for `section.customCostByHomeInstitutionRelationshipId`.
          customCostByHomeInstitutionRelationshipId: section.customCostByHomeInstitutionRelationshipId || section.customCosts,
          customCostByHomeConsortiumRelationshipId: section.customCostByHomeConsortiumRelationshipId
        },
        course: {
          credits: course.hours,
          level: course.level,
          teachingInstitutionId: course.institution.id
        },
        courseEnrollmentPricing,
        homeInstitutionId: user.institution.id,
        homeInstitutionTeachingInstitutionRelationshipIds,
        homeConsortiumTeachingInstitutionRelationships
      });
    }
  }, [
    section,
    session,
    course,
    user,
    courseEnrollmentPricing,
    homeInstitutionTeachingInstitutionRelationshipIds,
    homeConsortiumTeachingInstitutionRelationships,
    canViewCoursePrices
  ]);
}


interface RemoveCourseSectionModalProps {
  show: boolean;
  onHide: () => void;
  onDeactivateCourseSection: OnSubmit<SubmitDeactivateCourse>;
}

const RemoveCourseSectionModal: FC<RemoveCourseSectionModalProps> = ({
  show,
  onHide,
  onDeactivateCourseSection
}) => {

  return (
    <FormModal
      danger
      show={show}
      onHide={onHide}
      onCancel={onHide}
      onSubmit={onDeactivateCourseSection}
      title="Remove Course Section"
      submitText="Remove"
    >
      <Alert variant="warn">
        Removing a course section does not affect previously accepted enrollments.
      </Alert>
      <br />
      <FormField
        multiline
        name="notes"
        label="Please, tell us why you are removing this course section."
        placeholder="Type here..."
      />
    </FormModal>
  );
};
