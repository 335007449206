import classNames from 'classnames';
import type { FC } from 'react';
import React, { useImperativeHandle, useMemo, useState, useRef } from 'react';
import tc from 'tinycolor2';

import { useRect } from '@acadeum/hooks';
import { CaretDownIcon } from '@acadeum/icons';

import { BaseButton } from '../BaseButton';
import { ColorPicker } from '../GradientColorPicker';
import type { ColorFormat } from '../GradientColorPicker/shared/types';
import { Icon } from '../Icon';
import { Popover, PopoverContent, PopoverTrigger } from '../Popover';
import { HStack } from '../Stack';

import styles from './ColorInput.module.scss';

export interface ColorInputProps {
  className?: string;
  value?: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  readOnly?: boolean;
  label: string;
}

export const ColorInput: FC<ColorInputProps> = React.forwardRef(({
  value,
  onChange,
  disabled,
  readOnly,
  label
}, ref) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const rect = useRect(buttonRef);
  useImperativeHandle(ref, () => ({
    focus: () => {
      /* noop */
    }
  }));

  const width = typeof rect?.width === 'number' ? rect.width - 32 : undefined;

  const [open, setOpen] = useState(false);
  const [ inputType, setInputType ] = useState<ColorFormat>('hex');

  const colorByType = useMemo(() => {
    if (inputType === 'hex') {
      return tc(value).toHex8String().toUpperCase();
    }

    return value;
  }, [inputType, value]);

  return (
    <>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <BaseButton
            ref={buttonRef}
            className={styles.Toggle}
            disabled={disabled || readOnly}
            aria-label={label}
          >
            <HStack as="span" gap="sm">
              <div
                className={styles.preview}
                style={{ background: value }}
              />
              {value?.includes('gradient') ? 'Gradient' : `Solid - ${colorByType}`}
            </HStack>
            <Icon
              icon={CaretDownIcon}
              className={classNames({
                [styles.hidden]: readOnly
              })}
            />
          </BaseButton>
        </PopoverTrigger>
        <PopoverContent className={styles.PopoverContent}>
          <ColorPicker
            className={styles.ColorPicker}
            width={width}
            height={136}
            value={value}
            onChange={onChange}
            hideColorGuide
            hideInputType
            hideAdvancedSliders
            inputType={inputType}
            setInputType={setInputType}
          />
        </PopoverContent>
      </Popover>
    </>
  );
});
