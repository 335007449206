import { $convertFromMarkdownString, TRANSFORMERS } from '@lexical/markdown';
import type { InitialConfigType } from '@lexical/react/LexicalComposer';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import classNames from 'classnames';
import type { FC } from 'react';
import React, { useImperativeHandle } from 'react';

import type { EditorState, LexicalEditor as LexicalEditorType } from 'lexical';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';

import { LexicalNodes } from '@acadeum/core-ui';

import styles from './LexicalEditor.module.scss';
import { AutoLinkPlugin } from './plugins/AutoLinkPlugin';
import { CodeHighlightPlugin } from './plugins/CodeHighlightPlugin';
import { ToolbarPlugin } from './plugins/ToolbarPlugin';
import { TreeViewPlugin } from './plugins/TreeViewPlugin';

import { defaultTheme } from './themes/defaultTheme';


type OnChange = (editorState: EditorState, editor: LexicalEditorType, tags: Set<string>) => void;

export interface LexicalEditorProps {
  initialValue?: string;
  onChange?: OnChange;
  markdown?: boolean;
  threeView?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
}

export const LexicalEditor: FC<LexicalEditorProps> = React.forwardRef(({
  initialValue,
  onChange,
  markdown,
  threeView,
  disabled,
  readOnly
}, ref) => {
  const config: InitialConfigType = {
    namespace: 'MyEditor',
    theme: defaultTheme,
    onError(error) {
      console.error(error);
      throw error;
    },
    editorState: ((editor) => {
      if (markdown) {
        $convertFromMarkdownString(initialValue || '', TRANSFORMERS);
        return;
      }

      if (initialValue) {
        editor.setEditorState(editor.parseEditorState(initialValue));
      }
    }),
    editable: !disabled && !readOnly,
    nodes: LexicalNodes
  };

  return (
    <LexicalComposer initialConfig={config}>
      <LexicalRefPlugin ref={ref}/>
      <div className={styles.container}>
        <ToolbarPlugin
          markdown={markdown}
          disabled={disabled || readOnly}
        />
        <div className={classNames(styles.inner, {
          [styles.disabled]: disabled,
          [styles.readOnly]: readOnly
        })}>
          <RichTextPlugin
            ErrorBoundary={LexicalErrorBoundary}
            contentEditable={<ContentEditable className={styles.input}/>}
            placeholder={<div className={styles.placeholder}>Enter some text...</div>}
          />
          <HistoryPlugin/>
          {threeView && (
            <TreeViewPlugin/>
          )}
          <CodeHighlightPlugin/>
          <ListPlugin/>
          <LinkPlugin/>
          <AutoLinkPlugin/>
          {markdown && (
            <MarkdownShortcutPlugin transformers={TRANSFORMERS}/>
          )}
          {onChange && (
            <OnChangePlugin
              onChange={onChange}
              ignoreSelectionChange
              ignoreHistoryMergeTagChange
            />
          )}
        </div>
      </div>
    </LexicalComposer>
  );
});

const LexicalRefPlugin = React.forwardRef((props, ref) => {
  const [editor] = useLexicalComposerContext();

  useImperativeHandle(ref, () => ({
    focus: () => {
      editor.focus();
    }
  }));

  return null;
});
