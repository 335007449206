import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { getErrorData } from '@acadeum/helpers';

import actions from '../../actions';
import Col from '../Col';
import Field from '../Field';
import Form from '../Form';
import FormSubmit from '../FormSubmit';
import Input from '../Input';
import Row from '../Row';


const {
  addCourseSubstitute,
  fetchCourseSubstitutes,
  notify
} = actions;

class CourseSubstituteForm extends Component {
  static propTypes = {
    courseId: PropTypes.number.isRequired,
    substitutes: PropTypes.arrayOf(PropTypes.object),
    substituteId: PropTypes.number,
    substituteCourseCode: PropTypes.string,
    substituteCourseTitle: PropTypes.string,
    onSubstituteChange: PropTypes.func.isRequired,
    onSubmitSelectedSubstitute: PropTypes.func
  };

  form = React.createRef();

  constructor() {
    super();
    this.submit = this.submit.bind(this);
  }

  focus() {
    // if (!this.get('substituteId')) {
    //   return this.form.current.focus('code');
    // }
    this.form.current.focus();
  }

  async submit(values) {
    const {
      courseId,
      onSubstituteChange,
      onSubmitSelectedSubstitute,
      setLoading
    } = this.props;

    // If it's not "Other" that's currently selected
    // then don't add a new course substitute.
    if (!values.code) {
      if (onSubmitSelectedSubstitute) {
        onSubmitSelectedSubstitute();
      }
      return;
    }

    let substitute;

    try {
      substitute = await addCourseSubstitute({
        homeCourseCode: values.code,
        homeCourseTitle: values.title,
        courseId
      });
    }
    catch (error) {
      if (getErrorData(error).message === 'duplicate_course_substitute') {
        return notify('This course substitute already exists. Select it from the list instead of adding it.');
      }
      throw error;
    }

    // Select the newly added substitute.
    // Setting `substituteId` before re-fetching the list of course substitutes
    // because if `substituteId` wasn't set when the re-fetched list of course substitutes
    // re-renders then the "Code" and "Title" input fields below the select wouldn't disappear.
    onSubstituteChange(substitute.id);
    // Re-fetch the list of course substitutes and re-render the form.
    try {
      setLoading(true);
      await fetchCourseSubstitutes(courseId);
    } finally {
      setLoading(false);
    }
  }

  getSubstituteOptions() {
    const { substitutes } = this.props;

    return substitutes.map(({ id, equivalentCourse }) => ({
      value: id,
      label: `${equivalentCourse.code} ${equivalentCourse.title}`
    })).concat({
      label: 'Other'
    });
  }

  onSubstituteChange = (value) => {
    const { onSubstituteChange } = this.props;
    onSubstituteChange(value);
    this.forceUpdate();
  };

  get(name) {
    if (this.form.current) {
      return this.form.current.get(name);
    }
    const {
      substituteId,
      substituteCourseCode,
      substituteCourseTitle
    } = this.props;
    switch (name) {
      case 'substituteId':
        return substituteId;
      case 'code':
        return substituteCourseCode;
      case 'title':
        return substituteCourseTitle;
    }
  }

  render() {
    const {
      substituteId,
      substituteCourseCode,
      substituteCourseTitle
    } = this.props;

    return (
      <Form
        ref={this.form}
        autoFocus
        className="form"
        onSubmit={this.submit}>

        <Row>
          <Field
            name="substituteId"
            value={substituteId}
            label="Equivalent Course at Home Institution"
            col={12}
            placeholder="Pick an Equivalent Course at Home Institution"
            onChange={this.onSubstituteChange}
            options={this.getSubstituteOptions()} />
        </Row>

        {!this.get('substituteId') &&
          <Row alignChildren="bottom">
            <Field
              required
              name="code"
              value={substituteCourseCode}
              label="Course Code"
              col={3}
              placeholder="Course Code"
              onChange={() => this.forceUpdate()} />

            <Field
              required
              name="title"
              value={substituteCourseTitle}
              label="Course Title"
              col={6}
              placeholder="Course Title"
              onChange={() => this.forceUpdate()} />

            <Col col={3} xs={12}>
              <FormSubmit className="form__button--inline-except-xs form__button--wide">
                {substituteCourseCode && this.get('code') === substituteCourseCode && this.get('title') === substituteCourseTitle ? 'Accept' : 'Add'} Substitute
              </FormSubmit>
            </Col>
          </Row>
        }
      </Form>
    );
  }
}

export default class CourseSubstituteFormContainer extends Component {
  static propTypes = CourseSubstituteForm.propTypes;

  form = React.createRef();

  state = {};

  focus() {
    this.form.current.focus();
  }

  render() {
    const {
      substitutes
    } = this.props;

    const {
      loading
    } = this.state;

    {/* A placeholder for "loading substitutes" */}
    if (!(substitutes && !loading)) {
      return (
        <Input
          disabled
          label="Equivalent Course at Home Institution"
          options={[]}
          onChange={() => {}}
          className="form__field"/>
      );
    }

    return (
      <CourseSubstituteForm
        ref={this.form}
        setLoading={loading => this.setState({ loading })}
        {...this.props}
      />
    );
  }
}
