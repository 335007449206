import classNames from 'classnames';
import React from 'react';

import { useImageLoadingStatus } from '@acadeum/hooks';

import styles from './Avatar.module.scss';
import InstitutionAvatar from './institution-avatar.svg';
import UserAvatar from './user-avatar.svg';
import UserMarketplaceAvatar from './user-marketplace-avatar.svg';

type Size = 16 | 32 | 42 | 48 | 96;
type Type = 'institution' | 'user' | 'userMarketplace';
type Shape = 'square' | 'round';

export interface AvatarProps {
  className?: string;
  url?: string;
  size?: Size;
  type?: Type;
  shape?: Shape;
  name?: string;
  style?: React.CSSProperties;
}

export const Avatar: React.FC<AvatarProps> = ({
  className,
  url,
  shape = 'round',
  size = 16,
  type = 'user',
  name,
  style
}) => {
  const DefaultAvatar = getDefaultAvatar(type);

  const status = useImageLoadingStatus(url);

  return (
    <figure
      style={{
        width: size,
        height: size,
        ...style
      }}
      className={classNames(className, styles.Avatar, {
        [styles.round]: shape === 'round'
      })}
    >
      {url && status === 'loaded' ? (
        <img
          className={styles.Image}
          src={url}
          alt={name ? name : ''}
        />
      ) : (
        <DefaultAvatar className={styles.Svg}/>
      )}
    </figure>
  );
};

function getDefaultAvatar(type) {
  switch (type) {
    case 'institution':
      return InstitutionAvatar;
    case 'userMarketplace':
      return UserMarketplaceAvatar;
    default:
      return UserAvatar;
  }
}
