// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { cloneDeep } from 'lodash-es';
import writeXlsxFile from 'write-excel-file';

export function writeXlsx({
  formatGender,
  formatRaces,
  formatEthnicity,
  formatPhone
}) {
  const COLUMNS = getColumns({
    formatGender,
    formatRaces,
    formatEthnicity,
    formatPhone
  });

  return (data, { columns, fileName }) => {
    // If columns schema is passed then it means that `data` is a list of objects.
    // If no columns schema is passed then it means that `data` is a list of rows of cells.
    if (!columns) {
      return writeXlsxFile(data, {
        fileName,
        dateFormat: 'mm/dd/yyyy'
      });
    }
    // Proceed with a columns schema.
    columns = cloneDeep(columns);
    for (const column of columns) {
      if (column.id) {
        const props = COLUMNS[column.id];
        if (!props) {
          throw new Error(`Unknown *.xlsx export column: ${column.id}`);
        }
        for (const key in props) {
          if (key === 'format') {
            const getValue = column.value;
            column.value = (row) => {
              let value = getValue(row);
              if (value !== undefined && value !== null) {
                value = props.format(value);
              }
              return value;
            };
          } else {
            column[key] = props[key];
          }
        }
        column.title = column.id;
        delete column.id;
      }
      if (column.type === 'Currency') {
        column.type = Number;
        column.format = '#,##0.00';
      }
      column.alignVertical = 'top';
      column.column = column.title;
      delete column.title;
    }
    return writeXlsxFile(data, {
      schema: columns,
      fileName: fileName + '.xlsx',
      dateFormat: 'mm/dd/yyyy'
    });
  };
}

const getColumns = ({
  formatGender,
  formatRaces,
  formatEthnicity,
  formatPhone
}) => ({
  'Start Date': {
    type: Date,
    width: 15
  },
  'Submitted At': {
    type: Date,
    width: 15
  },
  'Date of Birth': {
    type: Date,
    width: 15
  },
  'Gender': {
    format: value => formatGender(value),
    width: 22
  },
  'Level': {
    width: 15
  },
  'Major': {
    width: 20
  },
  'Postal Code': {
    width: 12
  },
  'City': {
    width: 15
  },
  'State': {
    width: 8
  },
  'Country': {
    width: 18
  },
  'State Residency': {
    width: 18
  },
  'US Citizenship': {
    type: Boolean,
    width: 15
  },
  'Address Line 1': {
    width: 25
  },
  'Address Line 2': {
    width: 14
  },
  'Phone': {
    format: value => formatPhone(value),
    width: 20
  },
  'Email': {
    width: 38
  },
  'First Name': {
    width: 17
  },
  'Middle Name': {
    width: 15
  },
  'Last Name': {
    width: 17
  },
  'ID': {
    width: 15
  },
  'HI Student ID': {
    width: 15
  },
  'Acadeum Student ID': {
    width: 20
  },
  'Status': {
    width: 15
  },
  'Seats': {
    width: 8
  },
  'Home Institution': {
    width: 34
  },
  'Teaching Institution': {
    width: 34
  },
  'Enrollment Type': {
    width: 22
  },
  'TI Course ID': {
    width: 20
  },
  'TI Course Title': {
    width: 40
  },
  'TI Course Section ID': {
    width: 20
  },
  'HI Course ID': {
    width: 20
  },
  'HI Course Title': {
    width: 40
  },
  'Course Cost': {
    type: 'Currency',
    width: 14
  },
  'Student Cost': {
    type: 'Currency',
    width: 14
  },
  'Student Cost Is An Estimate': {
    type: Boolean,
    width: 20
  },
  'Credits': {
    type: Number,
    width: 10
  },
  'Term': {
    width: 15
  },
  'Internal Term': {
    width: 15
  },
  'Session': {
    width: 18
  },
  'Internal Session': {
    width: 16
  },
  'Section': {
    width: 10
  },
  'Session Start Date': {
    type: Date,
    width: 18
  },
  'Session End Date': {
    type: Date,
    width: 18
  },
  'Session Add Date': {
    type: Date,
    width: 18
  },
  'Session Drop Date': {
    type: Date,
    width: 18
  },
  'Paid': {
    type: Boolean,
    width: 10
  },
  'Refunded': {
    type: Boolean,
    width: 10
  },
  'Letter Grade': {
    width: 15
  },
  'Numerical Grade': {
    type: Number,
    width: 16
  },
  'Grade Notes': {
    width: 25,
    wrap: true
  },
  'Notes': {
    width: 40,
    wrap: true
  },
  'Enroll Reason': {
    width: 40,
    wrap: true
  },
  'Remove Reason': {
    width: 40,
    wrap: true
  },
  'Deny Reason': {
    width: 40,
    wrap: true
  },
  'Student Drop Reason': {
    width: 40,
    wrap: true
  },
  'Home Drop Reason': {
    width: 40,
    wrap: true
  },
  'Teaching Drop Reason': {
    width: 40,
    wrap: true
  },
  'Student Withdraw Reason': {
    width: 40,
    wrap: true
  },
  'Home Withdraw Reason': {
    width: 40,
    wrap: true
  },
  'Teaching Withdraw Reason': {
    width: 40,
    wrap: true
  },
  'Reason for Course Need': {
    width: 40,
    wrap: true
  },
  'Advisor Name': {
    width: 24
  },
  'Advisor Email': {
    width: 35
  },
  'Race': {
    width: 26,
    wrap: true,
    format: value => formatRaces(value)
  },
  'Ethnicity': {
    width: 26,
    format: value => formatEthnicity(value)
  },
  'Requested Date': {
    type: Date,
    width: 18
  },
  'Last Day to Approve': {
    type: Date,
    width: 22
  }
});
