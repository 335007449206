import React from 'react';

import formatName from 'common-lib/lib/formatName';

import { Output, Outputs, Breadcrumbs, AppLoading } from '@acadeum/ui';


import { useFetchStudentUserQuery } from '../../api/studentUsers';
import Section from '../../components/Section';
import Subsection from '../../components/Subsection';


const StudentUser = ({ id }) => {
  const { data: user, isLoading } = useFetchStudentUserQuery({ id });

  if (!user || isLoading) {
    return <AppLoading circle/>;
  }

  return (
    <>
      <Breadcrumbs children={[
        ['Students', '/students'],
        formatName(user)
      ]} />
      <Section
        title={formatName(user)}
        inlineActions
        actions={[{
          icon: 'edit',
          title: 'Edit Profile',
          // link: `/student-users/${user.id}/edit?from=profile`,
          link: `/students/add?values=${encodeURIComponent(JSON.stringify({
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            hiStudentId: user.hiStudentId
          }))}`
        }]}>

        <Subsection title="Personal Information">
          <Outputs>
            <Output
              label="First Name"
              value={user.firstName}
            />
            <Output
              label="Last Name"
              value={user.lastName}
            />
            <Output
              label="Student ID"
              value={user.hiStudentId}
            />
            <Output
              label="Student Email"
              type="email"
              value={user.email}
            />
          </Outputs>
        </Subsection>
      </Section>
    </>
  );
};

export default StudentUser;

StudentUser.meta = () => {
  return {
    title: 'Student'
  };
};

StudentUser.load = async ({ params }) => {
  return {
    props: params
  };
};
