import type React from 'react';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

export interface ReactPortalProps {
  children?: React.ReactNode;
  layer?: 'top';
}

export const ReactPortal: React.FC<ReactPortalProps> = ({ children, layer }) => {
  const [container] = useState(() => document.createElement('div'));

  useEffect(() => {
    if (layer === 'top') {
      container.style.zIndex = '2147483647';
    }
    document.body.appendChild(container);
    return () => {
      document.body.removeChild(container);
    };
  }, [layer]);

  return createPortal(children, container);
};
