import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Modal } from 'react-responsive-ui';

import { gaEvent } from '@acadeum/helpers';
import { useTranslate } from '@acadeum/translate';

import CloseButton from './CloseButton';


import './Modal.scss';

export default function AcadeumModal({
  show,
  onHide: onHide_,
  closeIcon,
  medium,
  wide,
  confirmation,
  closeLabel,
  overlayClassName,
  className,
  children,
  backdropOpaque,
  atTheBottomOnSmallScreens,
  onCancelGaLabel
}) {
  if (!confirmation) {
    if (closeIcon === undefined) {
      closeIcon = true;
    }
  }

  const t = useTranslate();
  closeLabel = closeLabel || t('cancel', { global: true });

  const [wait, setWait] = useState();
  const isMounted = useRef();

  const onHide = useCallback(() => {
    if (wait) {
      return;
    }
    if (onCancelGaLabel) {
      gaEvent({
        category: 'Links',
        action: 'Clicked',
        label: onCancelGaLabel
      });
    }
    onHide_();
  }, [
    wait,
    onHide_,
    onCancelGaLabel
  ]);

  const context = useMemo(() => ({
    wait: (value) => {
      if (isMounted.current) {
        setWait(value);
      }
    }
  }), [
    setWait
  ]);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <Modal
      isOpen={show}
      close={() => {
        if (!wait) {
          onHide();
        }
      }}
      wait={wait}
      appElementId="react"
      overlayClassName={classNames(overlayClassName, 'ModalBackDrop', {
        'ModalBackDrop--wait': wait,
        'ModalBackDrop--wide': wide,
        'ModalBackDrop--medium': medium,
        'ModalBackDrop--opaque': backdropOpaque,
        'ModalBackDrop--atTheBottomOnSmallScreens': atTheBottomOnSmallScreens
      })}
      className={classNames(className, 'Modal', {
        'Modal--confirmation': confirmation,
        'Modal--atTheBottomOnSmallScreens': atTheBottomOnSmallScreens
      })}>
      {closeIcon &&
        <CloseButton
          onClick={onHide}
          disabled={wait}>
          {closeLabel}
        </CloseButton>
      }
      <ModalContext.Provider value={context}>
        <Modal.Content>
          {children}
        </Modal.Content>
      </ModalContext.Provider>
    </Modal>
  );
}

AcadeumModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  closeIcon: PropTypes.bool,
  wide: PropTypes.bool,
  medium: PropTypes.bool,
  confirmation: PropTypes.bool,
  closeLabel: PropTypes.string,
  backdropOpaque: PropTypes.bool,
  overlayClassName: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  atTheBottomOnSmallScreens: PropTypes.bool,
  onCancelGaLabel: PropTypes.string
};

export const ModalContext = React.createContext();

export function waitForModalToClose() {
  // <Modal/> hide CSS animation duration is about 180 milliseconds.
  return new Promise(resolve => setTimeout(resolve, 180));
}
