import checkUserPermissions_ from 'common-lib/lib/checkUserPermissions.js';

export default function checkUserPermissions(user, requestedPermissions, subjectInfo, requestedPermissionParameters) {
  const result = checkUserPermissions_(
    user.id,
    user.institution.id,
    user.roles.map(_ => _.permissions),
    requestedPermissions,
    subjectInfo,
    requestedPermissionParameters
  );

  if (!result.permitted) {
    let reason;
    if (result.possibleMatches) {
      reason = 'is not covered by the user\'s permissions:' + `\n${  result.possibleMatches.map(_ => JSON.stringify(_)).join('\n')}`;
    } else {
      reason = 'is not granted';
    }
    const error = new Error(`Unauthorized: "${requestedPermissions}" permission ${reason}`);
    error.status = 403;
    throw error;
  }
}
