import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import getDateDiff from 'common-lib/lib/getDateDiff';

import { waitForJob } from '@acadeum/helpers';

import actions from '../../actions';
import Email from '../../components/Email';
import Field from '../../components/Field';
import Form from '../../components/Form';
import FormButtons from '../../components/FormButtons';
import FormSubmit from '../../components/FormSubmit';
import InstitutionLink from '../../components/InstitutionLink';
import RawDataTable, { compileColumns } from '../../components/RawDataTable';
import Table from '../../components/Table';
import { formatName } from '../../helpers/format';


import Section from '../../components/Section';
import Row from '../../components/Row';

import './Payments.sass';


const {
  notify,
  notifyError,
  fetchFuturePaymentData,
  runJob,
  getJobStatus
} = actions;

export default function Payments() {
  const futurePayments = useSelector(state => state.payments.futurePayments);

  const stopPollingSendHistoricalInvoicesForEnrollmentsJobStatus = useRef();
  const stopPollingChargeForEnrollmentsJobStatus = useRef();

  useEffect(() => {
    return () => {
      if (stopPollingSendHistoricalInvoicesForEnrollmentsJobStatus.current) {
        stopPollingSendHistoricalInvoicesForEnrollmentsJobStatus.current();
      }
      if (stopPollingChargeForEnrollmentsJobStatus.current) {
        stopPollingChargeForEnrollmentsJobStatus.current();
      }
    };
  }, []);

  async function sendHistoricalInvoicesForEnrollments(values) {
    let {
      enrollmentIds,
      sendTeachingInstitutionInvoices,
      sendHomeInstitutionInvoices
    } = values;

    enrollmentIds = enrollmentIds.split(',').map(Number);

    if (!sendHomeInstitutionInvoices && !sendTeachingInstitutionInvoices) {
      return notifyError('Select which invoices to send');
    }

    if (sendHomeInstitutionInvoices === undefined) {
      sendHomeInstitutionInvoices = false;
    }

    if (sendTeachingInstitutionInvoices === undefined) {
      sendTeachingInstitutionInvoices = false;
    }

    await waitForJob(
      await runJob('payments-sendHistoricalInvoicesForEnrollments', {
        enrollmentIds,
        sendTeachingInstitutionInvoices,
        sendHomeInstitutionInvoices
      }),
      getJobStatus,
      (cancel) => stopPollingSendHistoricalInvoicesForEnrollmentsJobStatus.current = cancel
    );

    notify('Invoices have been sent');
  }

  async function chargeForEnrollments(selectedRows) {
    await waitForJob(
      await runJob('chargeForEnrollments', selectedRows.map(_ => _.id)),
      getJobStatus,
      (cancel) => stopPollingChargeForEnrollmentsJobStatus.current = cancel
    );
    await fetchFuturePaymentData();
    notify('Customers have been charged');
  }

  const duePaymentsTableActions = [{
    title: (selectedRows) => {
      let title = 'Charge Customers';
      if (selectedRows.length > 0) {
        title += ` for ${selectedRows.length} enrollment${selectedRows.length > 1 ? 's' : ''}`;
      }
      return title;
    },
    onClick: chargeForEnrollments
  }];

  futurePayments.data = futurePayments.data || [];

  return (
    <section>
      {futurePayments &&
        <React.Fragment>
          {/*
          <Table
            title="Send Invoices For Student Enrollments"
            data={futurePayments.data}
            columns={FUTURE_PAYMENTS_TABLE_COLUMNS}
            actions={futurePaymentsTableActions}
            className="payments-page__table" />
          */}

          {/*
          <Table
            title="Charge Home Institutions For Student Enrollments"
            data={futurePayments.data.filter(_ => getDateDiff(new Date(), (_.enrollmentRequest || _).section.session.lastDropDate, 'days') > 0)}
            columns={FUTURE_PAYMENTS_TABLE_COLUMNS}
            actions={duePaymentsTableActions}
            className="payments-page__table" />
          */}

          <RawDataTable
            selectableRows={false}
            title="Unpaid Student Enrollments"
            data={futurePayments.data}
            columns={FUTURE_PAYMENTS_TABLE_COLUMNS}
            className="payments-page__table" />

          <RawDataTable
            title="Charge Home Institutions For Student Enrollments"
            data={futurePayments.data.filter(_ => getDateDiff(new Date(), (_.enrollmentRequest || _).section.session.lastDropDate, 'days') > 0)}
            columns={FUTURE_PAYMENTS_TABLE_COLUMNS}
            actions={duePaymentsTableActions}
            className="payments-page__table" />

          <br/>
          <br/>
          <br/>

          <Section
            title="Send Historical Invoices For Student Enrollments">
            <Form
              className="form"
              onSubmit={sendHistoricalInvoicesForEnrollments}>
              <Row>
                <Field
                  required
                  name="enrollmentIds"
                  label="Student Enrollment IDs (comma-separated)"
                  col={6}
                />
              </Row>
              <Row>
                <Field
                  name="sendHomeInstitutionInvoices"
                  label="Send Home Institution Invoices"
                  type="checkbox"
                  col={12}
                />
                <Field
                  name="sendTeachingInstitutionInvoices"
                  label="Send Teaching Institution Invoices"
                  type="checkbox"
                  col={12}
                />
              </Row>

              <FormButtons>
                <FormSubmit>
                  Send
                </FormSubmit>
              </FormButtons>
            </Form>
          </Section>

          <br/>
          <br/>
          <br/>
          <br/>
          <br/>

          <Table
            selectableRows={false}
            title="Teaching Institutions Stripe Info"
            columns={STRIPE_INFO_TABLE_COLUMNS}
            data={futurePayments.tm}
            noDataMessage="No future payments"
            className="payments-page__table" />

          <Table
            selectableRows={false}
            title="Home Institutions Stripe Info"
            columns={STRIPE_INFO_TABLE_COLUMNS}
            data={futurePayments.em}
            noDataMessage="No future payments"
            className="payments-page__table" />
        </React.Fragment>
      }
      {!futurePayments &&
        'No future payments'
      }
    </section>
  );
}

Payments.meta = () => ({
  title: 'Payments'
});

Payments.load = async () => {
  await fetchFuturePaymentData();
};
Payments.breadcrumbs = () => [
  ['Admin Tools', '/admin'],
  'Payments'
];

// function paymentRow(studentEnrollment) {
//   const session = studentEnrollment.section.session;
//   const course = session.course;
//   return (
//     <tr key={studentEnrollment.id}>
//       <td>
//         {course.institution.name}
//       </td>
//       <td>
//         {studentEnrollment.institution.name}
//       </td>
//       <td>
//         {studentEnrollment.student.firstName}
//       </td>
//       <td>
//         {studentEnrollment.student.lastName}
//       </td>
//       <td>
//         <Email address={studentEnrollment.student.email}/>
//       </td>
//       <td>
//         <FormatDate date={session.lastDropDate} utc/>
//       </td>
//       <td>
//         {formatCurrency(studentEnrollment.costForInstitution)}
//       </td>
//     </tr>
//   );
// }

const FUTURE_PAYMENTS_TABLE_COLUMNS = compileColumns([
  {
    title: 'TI',
    accessor: 'section.session.course.institution.name'
  }, {
    title: 'HI',
    accessor: 'student.institution.name'
  }, {
    title: 'FIRST NAME',
    accessor: 'student.firstName'
  }, {
    title: 'LAST NAME',
    accessor: 'student.lastName'
  }, {
    title: 'EMAIL',
    accessor: 'student.email'
  }, {
    title: 'DROP DATE',
    accessor: 'section.session.lastDropDate'
  }, {
    title: 'COST',
    type: 'currency',
    accessor: 'costForInstitution'
  }
]);

// const FUTURE_PAYMENTS_TABLE_COLUMNS = [
//   {
//     title: 'TEACHING INSTITUTION',
//     content: (row) => (
//       <InstitutionLink>
//         {row.section.session.course.institution}
//       </InstitutionLink>
//     )
//   },
//   {
//     title: 'HOME INSTITUTION',
//     content: (row) => (
//       <InstitutionLink>
//         {row.student.institution}
//       </InstitutionLink>
//     )
//   },
//   {
//     title: 'STUDENT',
//     content: (row) => (
//       <React.Fragment>
//         {formatName(row.student)}, <Email address={row.student.email}/>
//       </React.Fragment>
//     )
//   },
//   {
//     title: 'DROP DATE',
//     content: (row) => (
//       <FormatDate date={row.section.session.lastDropDate} utc/>
//     )
//   },
//   {
//     title: 'COST',
//     // Currency is stored in DECIMAL columns which is are read as strings in Sequelize.
//     content: (row) => formatCurrency(row.costForInstitution)
//   }
// ];

function EmailAddress({ address }) {
  if (address === 'notfound') {
    return 'Not found';
  }
  if (!address) {
    return '—';
  }
  return <Email address={address}/>;
}

EmailAddress.propTypes = {
  address: PropTypes.string
};

const STRIPE_INFO_TABLE_COLUMNS = [
  // {
  //   title: 'ID',
  //   content: row => row.id
  // },
  {
    title: 'INSTITUTION',
    content: (row) => (
      <InstitutionLink>
        {row}
      </InstitutionLink>
    )
  },
  {
    title: 'CONTACTS',
    content: ({ contacts }) => (
      <React.Fragment>
        {contacts && contacts.map((contact, i) => (
          <div key={i}>
            {formatName(contact)}
            {contact.email && ', '}
            <EmailAddress address={contact.email}/>
          </div>
        ))}
      </React.Fragment>
    )
  },
  {
    title: 'EMAIL',
    content: (row) => (
      <EmailAddress address={row.email}/>
    )
  }
];
