import type { FC } from 'react';
import React, { useState } from 'react';

import type {
  FetchBackupPaymentSourceOutput,
  FetchFinancialSettingsStatusOutput,
  FetchPrimaryPaymentSourceOutput
} from '@acadeum/api';
import { useTranslate } from '@acadeum/translate';
import { Alert, toast, Button } from '@acadeum/ui';


import { FINANCIAL_SCREEN, useFinancialNavigationContext } from '../../context';
import { getBankAccountStatuses } from '../../lib/getBankAccountStatuses';
import { getCreditCardStatuses } from '../../lib/getCreditCardStatuses';


import type { AddBankAccountModalProps } from '../AddBankAccountModal';
import { AddBankAccountModal } from '../AddBankAccountModal';
import type { AddCreditCardModalProps } from '../AddCreditCardModal';
import { AddCreditCardModal } from '../AddCreditCardModal';
import { CreditCardForm } from '../CreditCardForm';
import { PaymentCard } from '../PaymentCard';
import { Subsection } from '../Subsection';
import type { VerifyBankAccountNotificationProps } from '../VerifyBankAccountNotification';
import { VerifyBankAccountNotification } from '../VerifyBankAccountNotification';

import styles from './FinancialDashboardScreen.module.scss';

export interface FinancialDashboardScreenProps extends Pick<BankAccountSectionProps, 'useVerifyPrimaryPaymentSourceMutation' | 'useCreatePrimaryPaymentSourceMutation' | 'useUpdatePrimaryPaymentSourceMutation'>,
  Pick<CreditCardSectionProps, 'useUpdateBackupPaymentSourceMutation' | 'useCreateBackupPaymentSourceMutation'> {
  financialSettingsStatus: FetchFinancialSettingsStatusOutput;
  primaryPaymentSource?: FetchPrimaryPaymentSourceOutput;
  backupPaymentSource?: FetchBackupPaymentSourceOutput;
}

export const FinancialDashboardScreen: FC<FinancialDashboardScreenProps> = ({
  primaryPaymentSource,
  backupPaymentSource,
  financialSettingsStatus,
  useVerifyPrimaryPaymentSourceMutation,
  useUpdatePrimaryPaymentSourceMutation,
  useCreatePrimaryPaymentSourceMutation,
  useCreateBackupPaymentSourceMutation,
  useUpdateBackupPaymentSourceMutation
}) => {
  return (
    <section className={styles.FinancialDashboard}>
      {financialSettingsStatus?.paymentMethod === 'ACH' && (
        <BankAccountSection
          primaryPaymentSource={primaryPaymentSource}
          useCreatePrimaryPaymentSourceMutation={useCreatePrimaryPaymentSourceMutation}
          useUpdatePrimaryPaymentSourceMutation={useUpdatePrimaryPaymentSourceMutation}
          useVerifyPrimaryPaymentSourceMutation={useVerifyPrimaryPaymentSourceMutation}
        />
      )}
      {financialSettingsStatus && (
        <CreditCardSection
          financialSettingsStatus={financialSettingsStatus}
          backupPaymentSource={backupPaymentSource}
          status={getCreditCardStatuses(backupPaymentSource, primaryPaymentSource)}
          useCreateBackupPaymentSourceMutation={useCreateBackupPaymentSourceMutation}
          useUpdateBackupPaymentSourceMutation={useUpdateBackupPaymentSourceMutation}
        />
      )}
    </section>
  );
};

interface BankAccountSectionProps extends Pick<AddBankAccountModalProps, 'useUpdatePrimaryPaymentSourceMutation' | 'useCreatePrimaryPaymentSourceMutation'>,
  Pick<VerifyBankAccountNotificationProps, 'useVerifyPrimaryPaymentSourceMutation'> {
  primaryPaymentSource?: FetchPrimaryPaymentSourceOutput;
}

const BankAccountSection: FC<BankAccountSectionProps> = ({
  primaryPaymentSource,
  useCreatePrimaryPaymentSourceMutation,
  useUpdatePrimaryPaymentSourceMutation,
  useVerifyPrimaryPaymentSourceMutation
}) => {
  const [showAddBankAccountModal, setShowAddBankAccountModal] = useState(false);

  const { changeView } = useFinancialNavigationContext();
  const t = useTranslate('shared-admin-ui.FinancialDashboardScreen.bankAccountSection');

  const isBankAccountSetup = Boolean(primaryPaymentSource);
  const currentBankAccount = primaryPaymentSource?.current;
  const newBankAccount = primaryPaymentSource?.new;

  const onAfterAddBankAccount = async () => {
    setShowAddBankAccountModal(false);
    toast.success(t('successMessage'));
  };

  const goToManageBankAccount = () => changeView({ screen: FINANCIAL_SCREEN.MANAGE_BANK_ACCOUNT });
  const goToManageNewBankAccount = () => changeView({ screen: FINANCIAL_SCREEN.MANAGE_NEW_BANK_ACCOUNT });

  return (
    <Subsection
      title={t('primaryPaymentMethod')}
      description={t('primaryPaymentDescription')}
    >
      {!isBankAccountSetup && (
        <Alert className={styles.FinancialDashboard__bankAccountWarning} variant="warn">
          {t('notSetup', {
            action: children => (
              <Button
                variant="text-inline"
                children={children}
                onClick={() => setShowAddBankAccountModal(true)}
              />
            )
          })}
        </Alert>
      )}

      {newBankAccount && (
        <>
          {newBankAccount.status === 'PENDING' && (
            <VerifyBankAccountNotification
              type="new"
              onAfterVerifyBankAccount={goToManageBankAccount}
              useVerifyPrimaryPaymentSourceMutation={useVerifyPrimaryPaymentSourceMutation}
            />
          )}
          <PaymentCard
            title={t('newBankAccountInfo.title')}
            description={t('newBankAccountInfo.description', {
              lastFourDigits: newBankAccount.bankAccount.lastFourDigits
            })}
            onSeeDetails={goToManageNewBankAccount}
            statuses={[newBankAccount.status]}
          />
        </>
      )}

      {currentBankAccount && (
        <>
          {currentBankAccount.status === 'PENDING' && (
            <VerifyBankAccountNotification
              type="current"
              onAfterVerifyBankAccount={goToManageBankAccount}
              useVerifyPrimaryPaymentSourceMutation={useVerifyPrimaryPaymentSourceMutation}
            />
          )}
          <PaymentCard
            title={t('bankAccountInfo.title')}
            description={t('bankAccountInfo.description', {
              lastFourDigits: currentBankAccount.bankAccount.lastFourDigits
            })}
            onSeeDetails={goToManageBankAccount}
            statuses={getBankAccountStatuses(currentBankAccount)}
          />
        </>
      )}
      <AddBankAccountModal
        type="current"
        show={showAddBankAccountModal}
        onHide={() => setShowAddBankAccountModal(false)}
        onAfterSubmit={onAfterAddBankAccount}
        useCreatePrimaryPaymentSourceMutation={useCreatePrimaryPaymentSourceMutation}
        useUpdatePrimaryPaymentSourceMutation={useUpdatePrimaryPaymentSourceMutation}
      />
    </Subsection>
  );
};

interface CreditCardSectionProps extends Pick<AddCreditCardModalProps, 'useUpdateBackupPaymentSourceMutation' | 'useCreateBackupPaymentSourceMutation'> {
  financialSettingsStatus: FetchFinancialSettingsStatusOutput;
  backupPaymentSource?: FetchBackupPaymentSourceOutput;
  status: FetchBackupPaymentSourceOutput['status'][];
}

const CreditCardSection: FC<CreditCardSectionProps> = ({
  financialSettingsStatus,
  backupPaymentSource,
  status,
  useUpdateBackupPaymentSourceMutation,
  useCreateBackupPaymentSourceMutation
}) => {
  const [showCreditCardModal, setShowCreditCardModal] = useState(false);
  const { paymentMethod } = financialSettingsStatus;

  const { changeView } = useFinancialNavigationContext();
  const t = useTranslate('shared-admin-ui.FinancialDashboardScreen.creditCardSection');

  const onCreditCardModalHide = () => {
    setShowCreditCardModal(false);
  };

  const onSeeDetails = () => {
    changeView({ screen: FINANCIAL_SCREEN.MANAGE_CREDIT_CARD });
  };

  return (
    <Subsection
      title={paymentMethod === 'CARD' ? t('creditCard') : t('title')}
      description={paymentMethod === 'CARD' ? t('descriptionCCOnly') : t('description')}
    >
      {backupPaymentSource ? (
        <PaymentCard
          title={t('creditCardInfo.title')}
          description={t('creditCardInfo.description', {
            lastFourDigits: backupPaymentSource.card.lastFourDigits
          })}
          onSeeDetails={onSeeDetails}
          statuses={status}
        />
      ) : (
        paymentMethod === 'CARD' ? (
          <>
            <Alert className={styles.FinancialDashboard__bankAccountWarning} variant="warn">
              {t('cardNotSetup')}
            </Alert>

            <Subsection heading="new" title={t('addCreditCard')}>
              <CreditCardForm
                useUpdateBackupPaymentSourceMutation={useUpdateBackupPaymentSourceMutation}
                useCreateBackupPaymentSourceMutation={useCreateBackupPaymentSourceMutation}
              />
            </Subsection>
          </>
        ) : (
          <>
            <Alert className={styles.FinancialDashboard__bankAccountWarning} variant="warn">
              {t('notSetup', {
                action: children => (
                  <Button
                    variant="text-inline"
                    children={children}
                    onClick={() => setShowCreditCardModal(true)}
                  />
                )
              })}
            </Alert>

            <AddCreditCardModal
              show={showCreditCardModal}
              onHide={onCreditCardModalHide}
              useCreateBackupPaymentSourceMutation={useCreateBackupPaymentSourceMutation}
              useUpdateBackupPaymentSourceMutation={useUpdateBackupPaymentSourceMutation}
            />
          </>
        )
      )}
    </Subsection>
  );
};
