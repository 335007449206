import PropTypes from 'prop-types';
import React from 'react';
import { useController } from 'react-hook-form';

import { Select } from '../../../Select';

export function FormSelect({ name, defaultValue, rules, ...rest }) {
  // By default, `react-hook-form` fields have `undefined` value.
  // And, at the same time, `undefined` might be a valid option:
  // for example, when choosing a gender, there options:
  // "Male", "Female", or "Prefer not to answer" (`undefined`).
  // So an option with `value` being `undefined` would be suitable.
  // But, `react-hook-form` doesn't know how to properly handle
  // `undefined` being a `defaultValue`:
  // https://github.com/react-hook-form/react-hook-form/issues/2990
  // For that reason, `defaultValue` of `null` is passed to `<Controller/>`
  // so that when a field is empty, its value would be `null` instead of `undefined`.
  // This way, an option with value `null` would be automatically selected
  // by default, if there is one.
  if (!rest.multiple) {
    defaultValue = defaultValue === undefined ? null : defaultValue;
  }

  const { field } = useController({ name, defaultValue, rules });

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Select {...rest} {...field}/>
  );
}

FormSelect.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
  rules: PropTypes.object.isRequired
};
