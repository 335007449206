import classNames from 'classnames';
import React from 'react';

import  { Link } from '@acadeum/ui';
import type { LinkProps as LinkPropsUI } from '@acadeum/ui';

import styles from './Link.module.scss';

interface LinkProps extends LinkPropsUI {
  monochrome?: boolean;
  instantBack?: string;
}

const Link_ = React.forwardRef<HTMLAnchorElement, LinkProps>(({
  // Exclude `instantBack` from `...rest`.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  instantBack,
  className,
  monochrome,
  ...rest
}, ref) => {
  return (
    <Link
      {...rest}
      ref={ref}
      className={classNames(className, {
        [styles.monochrome]: monochrome
      })}
    />
  );
});

export default Link_;
