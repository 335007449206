import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { getDataFromStorageWithExpiry, saveDataToStorageWithExpiry } from '@acadeum/helpers';
import { useSettingsRoutes } from '@acadeum/shared-admin-ui';

import actions from '../../actions';
import userHasPermission from '../../helpers/userHasPermission';
import ConfirmActionModal from '../ConfirmActionModal';


const {
  goto
} = actions;

const ONE_DAY = 24 * 60 * 60 * 1000;
const STORAGE_KEY = 'CompleteFinancialOnboarding';

export default function CompleteFinancialOnboarding() {
  const { user } = useSelector(state => state.auth);
  const { financialOnboardingComplete } = useSelector(state => state.financialSettings);

  const { getFinancialSettingsUrl } = useSettingsRoutes();

  const [show, setShow] = useState(true);

  const ignore = useMemo(() => {
    return getDataFromStorageWithExpiry(STORAGE_KEY) === 'hide';
  }, []);

  const onHide = () => {
    setShow(false);
    saveDataToStorageWithExpiry(STORAGE_KEY, 'hide', {
      expiresIn: ONE_DAY
    });
  };

  // The "Complete Financial Onboarding" modal notification was requested to be removed.
  // https://acadeum.atlassian.net/browse/CS-19
  const shouldShowModal = false &&
    user &&
    userHasPermission(user, 'paymentMethod:update', {
      orgId: user.institution.id
    }) &&
    financialOnboardingComplete === false;

  if (!shouldShowModal) {
    return null;
  }

  return (
    <ConfirmActionModal
      show={show && !ignore}
      onHide={onHide}
      onSubmit={() => goto(getFinancialSettingsUrl())}
      title="Financial Onboarding"
      description="You must complete financial onboarding."
      submitText="Go to Settings"
    />
  );
}
