import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

import { YES_OR_NO_OPTIONS } from '@acadeum/selection-options';
import { getValueForJsonEditor, getValueFromJsonEditor } from '@acadeum/ui';
import {
  validateConsortiumCourseEnrollmentPricing,
  validateConsortiumPrimaryContact
} from '@acadeum/validate';

import Field from '../Field';
import Form from '../Form';
import FormButtons from '../FormButtons';
import FormSubmit from '../FormSubmit';
import InstitutionSearch from '../InstitutionSearch';
import CCPropTypes from '../PropTypes';
import Row from '../Row';

export default function ConsortiumForm({
  consortium,
  onSubmit,
  submitTitle,
  className
}) {
  const [memberInstitutions, setMemberInstitutions] = useState(consortium ? consortium.institutions.slice() : []);
  const [memberInstitutionIds, setMemberInstitutionIds] = useState(consortium
    ? consortium.institutions.map(_ => _.id)
    : []);

  const initialCourseEnrollmentPricing = useMemo(() => {
    return getValueForJsonEditor(consortium ? consortium.courseEnrollmentPricing : null);
  }, []);

  const onPick = (institution, value) => {
    if (value) {
      setMemberInstitutions(memberInstitutions.concat(institution));
      setMemberInstitutionIds(memberInstitutionIds.concat(institution.id));
    } else {
      setMemberInstitutions(memberInstitutions.filter(_ => _.id !== institution.id));
      setMemberInstitutionIds(memberInstitutionIds.filter(_ => _ !== institution.id));
    }
  };

  const onSubmit_ = async (values) => {
    values.courseEnrollmentPricing = getValueFromJsonEditor(values.courseEnrollmentPricing);
    values.primaryContact = extractPrimaryContactInfo(values);
    values.courseLevels = values.courseLevels.split(/\s*,\s*/);

    // When an SVG logo is supplied a rasterized copy
    // should also be provided for social sharing.
    if (/\.svg$/i.test(values.logoUrl) && !values.logoRasterUrl) {
      throw new Error('You supplied a "vector" (SVG) logo for the consortium. Social sharing also requires a rasterized copy of the logo. Upload a rasterized copy of the SVG logo and paste its URL as a "Rasterized Logo URL" field value.');
    }

    validateConsortiumCourseEnrollmentPricing(values.courseEnrollmentPricing);
    validateConsortiumPrimaryContact(values.primaryContact);

    // Member institutions list should be provided.
    if (memberInstitutionIds.length === 0) {
      throw new Error('Choose at least one member institution');
    }

    values.institutions = memberInstitutionIds.sort((a, b) => a - b);

    await onSubmit(values, memberInstitutions);
  };

  return (
    <Form
      autoFocus
      className={classNames('form', className)}
      onSubmit={onSubmit_}>

      <Row>
        <Field
          required
          name="name"
          value={consortium && consortium.name}
          label="Name"
          col={6}/>

        <Field
          name="shortName"
          value={consortium && consortium.shortName}
          label="Short Name"
          col={2}/>

        <Field
          required
          name="vanityUrl"
          value={consortium && consortium.vanityUrl}
          label="Vanity URL"
          col={2}/>

        <Field
          required
          name="isActive"
          disabled={consortium ? undefined : true}
          value={consortium ? consortium.isActive : true}
          label="Is Active"
          options={YES_OR_NO_OPTIONS}
          col={2}/>
      </Row>

      <Row>
        <Field
          required
          type="url"
          name="website"
          value={consortium && consortium.website}
          label="Website"
          col={4}/>

        <Field
          required
          type="url"
          name="logoUrl"
          value={consortium && consortium.logoUrl}
          label="Logo URL"
          col={4}/>

        <Field
          type="url"
          name="logoRasterUrl"
          value={consortium && consortium.logoRasterUrl}
          label="Rasterized Logo URL"
          col={4}/>
      </Row>

      <Row>
        <Field
          name="primaryContactTitle"
          value={consortium && consortium.primaryContact.title}
          label="Primary Contact Title"
          col={4}/>

        <Field
          required
          name="primaryContactFirstName"
          value={consortium && consortium.primaryContact.firstName}
          label="Primary Contact First Name"
          col={4}/>

        <Field
          required
          name="primaryContactLastName"
          value={consortium && consortium.primaryContact.lastName}
          label="Primary Contact Last Name"
          col={4}/>
      </Row>

      <Row>
        <Field
          required
          type="email"
          name="primaryContactEmail"
          value={consortium && consortium.primaryContact.email}
          label="Primary Contact Email"
          col={4}/>

        <Field
          name="primaryContactPhone"
          type="phone"
          value={consortium && consortium.primaryContact.phone}
          label="Primary Contact Phone"
          col={4}/>

        <Field
          name="primaryContactPhoneExt"
          value={consortium && consortium.primaryContact.phoneExt}
          type="phone-ext"
          label="Primary Contact Phone Ext"
          col={4}/>
      </Row>

      <Row>
        <Field
          required
          name="goals"
          multiline
          value={consortium && consortium.goals}
          label="Purpose"
          col={6}/>

        <Field
          required
          name="requirements"
          multiline
          value={consortium && consortium.requirements}
          label="Membership"
          col={6}/>
      </Row>

      <Row>
        <Field
          required
          name="courseLevels"
          multiline
          value={consortium && consortium.courseLevels.join(', ')}
          label="Course Levels"
          description="A comma-separated list of course levels offered by this consortium"
          col={6}/>

        <Field
          required
          name="description"
          multiline
          value={consortium && consortium.description}
          label="About"
          description="About this consortium"
          col={6}/>
      </Row>

      {/*
      <Row>
        <Field
          required
          name="memberInstitutions"
          multiline
          value={consortium && consortium.institutions.map(_ => _.id).join(', ')}
          label="Member Institutions (IDs)"
          col={12} />
      </Row>
      */}

      <Row>
        <Field
          name="courseEnrollmentPricing"
          type="json"
          value={initialCourseEnrollmentPricing}
          label="Course Enrollment Pricing"
          height={320}
          col={12}
        />
      </Row>

      <Row>
        <label className="form-input__label col-12">
          Member Schools
        </label>

        <InstitutionSearch
          className="col-12 form__field"
          layout="row"
          onPick={onPick}
          pickedIds={memberInstitutionIds}
          isInsideForm/>
      </Row>

      <FormButtons>
        <FormSubmit>
          {submitTitle}
        </FormSubmit>
      </FormButtons>
    </Form>
  );
}

ConsortiumForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  submitTitle: PropTypes.string.isRequired,
  consortium: CCPropTypes.consortium,
  className: PropTypes.string
};

// function isEmpty(value) {
//   if (typeof value === 'string') {
//     return value.trim() === '';
//   }
//   return value === undefined || value === null;
// }

export const extractPrimaryContactInfo = (values) => {
  let primaryContact;

  if (values.primaryContactFirstName) {
    if (!values.primaryContactLastName) {
      throw new Error('Enter primary contact last name.');
    }
    if (!values.primaryContactEmail) {
      throw new Error('Enter primary contact email address.');
    }
    primaryContact = {
      firstName: values.primaryContactFirstName,
      lastName: values.primaryContactLastName,
      email: values.primaryContactEmail
    };
    if (values.primaryContactTitle) {
      primaryContact.title = values.primaryContactTitle;
    }
    if (values.primaryContactPhone) {
      primaryContact.phone = values.primaryContactPhone;
    }
    if (values.primaryContactPhoneExt) {
      primaryContact.phoneExt = values.primaryContactPhoneExt;
    }
    delete values.primaryContactFirstName;
    delete values.primaryContactLastName;
    delete values.primaryContactTitle;
    delete values.primaryContactEmail;
    delete values.primaryContactPhone;
    delete values.primaryContactPhoneExt;
  } else {
    if (values.primaryContactLastName ||
      values.primaryContactEmail ||
      values.primaryContactPhone ||
      values.primaryContactPhoneExt) {
      throw new Error('Primary contact first name is empty, but some other primary contact info is present. Either erase all primary contact info, or provide the minimum.');
    }
  }

  return primaryContact;
};
