import * as ToggleGroupPrimitive from '@radix-ui/react-toggle-group';
import classNames from 'classnames';
import React from 'react';

import styles from './SegmentedControl.module.scss';

export type SegmentedControlProps = React.ComponentPropsWithoutRef<typeof ToggleGroupPrimitive.Root>;

export const SegmentedControl = React.forwardRef<
  React.ElementRef<typeof ToggleGroupPrimitive.Root>,
  SegmentedControlProps
>(({
  className,
  children,
  ...props
}, ref) => {
  return (
    <ToggleGroupPrimitive.Root
      {...props}
      ref={ref}
      className={classNames(styles.root, className)}
    >
      {children}
    </ToggleGroupPrimitive.Root>
  );
});

export const SegmentedControlItem = React.forwardRef<
  React.ElementRef<typeof ToggleGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof ToggleGroupPrimitive.Item>
>(({
  className,
  children,
  ...props
}, ref) => {
  return (
    <ToggleGroupPrimitive.Item
      ref={ref}
      className={classNames(styles.item, className)}
      {...props}
    >
      {children}
    </ToggleGroupPrimitive.Item>
  );
});
