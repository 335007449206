import type { FC } from 'react';
import React, { useState } from 'react';

import { getDeepDataFromStorage, saveDeepDataToStorage } from '@acadeum/helpers';
import { useTranslate } from '@acadeum/translate';
import type { CourseEnrollment } from '@acadeum/types';
import { Alert } from '@acadeum/ui';

export interface SameStatusSelectionAlertProps {
  tableId: string;
  enrollments: Pick<CourseEnrollment, 'status'>[];
}

export const SameStatusSelectionAlert: FC<SameStatusSelectionAlertProps> = ({
  tableId,
  enrollments
}) => {
  const t = useTranslate('CourseEnrollmentsTable');
  const [show, setShow] = useState(() => !getDeepDataFromStorage(`${tableId}.hideSameStatusAlert`));

  if (
    !show ||
    enrollments.length === 0 ||
    enrollments.every(enrollment => enrollment.status === enrollments[0].status)
  ) {
    return null;
  }

  const onClose = () => {
    setShow(false);
    saveDeepDataToStorage(`${tableId}.hideSameStatusAlert`, true);
  };

  return (
    <Alert
      show={show}
      onClose={onClose}
      dismissible
      variant="info"
    >
      {t('selectedEnrollmentsHaveDifferentStatus')}
    </Alert>
  );
};
