import { groupBy } from 'lodash-es';
import { ReduxModule } from 'react-pages';

import contactTypes from 'common-lib/constants/contactTypes.json';

const redux = new ReduxModule();

export const fetchContacts = redux.action(
  () => async (http) => {
    const contacts = await http.get('/contacts');
    const contactsByType = groupBy(contacts, 'type');
    return contactTypes.map((contactType) => ({
      type: contactType,
      users: contactsByType[contactType] ? contactsByType[contactType].map(_ => _.user) : []
    }));
  },
  'contactsByType'
);

export const updateContact = redux.action(
  (userId, data) => http => http.patch(`/contacts/${userId}`, data)
);

export default redux.reducer();
