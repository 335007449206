import classNames from 'classnames';
import type { CSSProperties } from 'react';
import React from 'react';

import type { MarginSizes, MarginsProp } from '../../utils/useMargin';
import { useMargin } from '../../utils/useMargin';

import styles from './Separator.module.scss';

export interface SeparatorProps extends Pick<MarginsProp, 'mb' | 'mt' | 'ml' | 'mr'> {
  className?: string;
  children?: React.ReactNode;
  orientation?: 'horizontal' | 'vertical';
  m?: MarginSizes;
  alignLeft?: boolean;
  style?: CSSProperties;
}

export const Separator: React.FC<SeparatorProps> = ({
  className,
  children,
  orientation = 'horizontal',
  alignLeft,
  style,
  m = 'lg',
  mt,
  mb,
  mr,
  ml
}) => {
  const { marginClassNames } = useMargin({ mt, ml, mb, mr, [orientation === 'vertical' ? 'mx' : 'my']: m });
  return (
    <div
      style={style}
      // https://github.com/Acadeum/Tickets/issues/1157
      // role="separator"
      // aria-orientation={orientation}
      className={classNames(className,
        styles.Separator,
        marginClassNames,
        styles[`Separator--${orientation}`],
        {
          [styles['Separator--alignLeft']]: alignLeft
        }
      )}
    >
      {children && (
        <h4 className={styles.Separator__content} role="presentation">
          {children}
        </h4>
      )}
    </div>
  );
};
