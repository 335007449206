import type { UserRolePermissionSubject } from '@acadeum/types';

import { formatSubjectName } from './formatSubjectName';
import type { FormPermissionValues, PermissionObjectType } from './types';

export function prepareFormPermissionsToObjectPermissions(permissions: FormPermissionValues): PermissionObjectType[] {
  return Object.entries(permissions).reduce<PermissionObjectType[]>((result, [subject, values]) => {
    result.push({
      subject: subject as UserRolePermissionSubject,
      name: formatSubjectName(subject),
      create: values.create,
      read: values.read,
      update: values.update,
      delete: values.delete
    });
    return result;
  }, []);
}
