import type { ColumnDef } from '@tanstack/react-table';
import React, { useMemo, useState } from 'react';
import type { FC } from 'react';

import type {
  UseActivateCourseSharingGroupMutation,
  UseDeactivateCourseSharingGroupJob,
  UseDeleteCourseSharingGroupMutation
} from '@acadeum/api';
import type { FetchCourseSharingGroupsOutput } from '@acadeum/api';
import { useTranslate } from '@acadeum/translate';
import { Actions, Table, Tag, toast } from '@acadeum/ui';
import type { ActionsProps } from '@acadeum/ui';

import { DeleteCourseSharingGroupModal } from '../ui/DeleteCourseSharingGroupModal';


import styles from './CourseSharingGroupsPage.module.scss';

interface CourseSharingGroupsPageProps {
  data: FetchCourseSharingGroupsOutput | undefined;
  isFetching: boolean;
  isLoading: boolean;
  isError: boolean;
  // TODO: Remove any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  globalFilterOptions: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  paginationOptions: any;
  useActivateCourseSharingGroupMutation: UseActivateCourseSharingGroupMutation;
  useDeactivateCourseSharingGroupJob: UseDeactivateCourseSharingGroupJob;
  useDeleteCourseSharingGroupMutation: UseDeleteCourseSharingGroupMutation;
  useRefreshCourseSharingGroup: () => void;
}

export const CourseSharingGroupsPage: FC<CourseSharingGroupsPageProps> = ({
  data,
  isFetching,
  isLoading,
  isError,
  globalFilterOptions,
  paginationOptions,
  useActivateCourseSharingGroupMutation,
  useDeactivateCourseSharingGroupJob,
  useDeleteCourseSharingGroupMutation,
  useRefreshCourseSharingGroup
}) => {
  const t = useTranslate('shared-admin-ui.CourseSharingGroupsPage');

  const columns = useColumns({
    useActivateCourseSharingGroupMutation,
    useDeactivateCourseSharingGroupJob,
    useDeleteCourseSharingGroupMutation,
    useRefreshCourseSharingGroup,
    t
  });

  return (
    <Table
      columns={columns}
      data={isError ? [] : data?.results}
      loading={isLoading}
      globalFilterOptions={globalFilterOptions}
      isFetching={isFetching}
      paginationOptions={paginationOptions}
      translate={{
        searchPlaceholder: t('searchByTitle'),
        resultText: ({ totalCount }) => t('resultText', { totalCount }),
        selectedResultText: ({ totalCount, selectedRowsCount }) => t('selectedResultText', {
          totalCount,
          selectedRowsCount
        })
      }}
    />
  );
};

function useColumns({
  useActivateCourseSharingGroupMutation,
  useDeactivateCourseSharingGroupJob,
  useDeleteCourseSharingGroupMutation,
  useRefreshCourseSharingGroup,
  t
}) {
  return useMemo<ColumnDef<FetchCourseSharingGroupsOutput['results'][number]>[]>(() => [
    {
      id: 'name',
      accessorFn: (row) => row.name,
      header: t('table.title'),
      cell: ({ row }) => {
        return (
          <>
            {row.original.name}
            {!row.original.isActive &&
              <Tag
                className={styles.CourseSharingGroup__status}
                variant="inactive"
              />
            }
          </>
        );
      }
    },
    {
      header: t('table.institutions'),
      accessorKey: 'institutions',
      cell: ({ row }) => {
        const { institutions } = row.original;
        if (institutions.length === 0) {
          return null;
        }
        return (
          <ul style={{ margin: 0, padding: 0 }}>
            {institutions.map((institution) => (
              <li key={institution.id} style={{ listStyleType: 'none' }}>
                {institution.name}
              </li>
            ))}
          </ul>
        );
      }
    },
    {
      header: t('table.consortia'),
      accessorKey: 'consortia',
      cell: ({ row }) => {
        const { consortia } = row.original;
        if (consortia.length === 0) {
          return null;
        }
        return (
          <ul style={{ margin: 0, padding: 0 }}>
            {consortia.map((consortium) => (
              <li key={consortium.id} style={{ listStyleType: 'none' }}>
                {consortium.name}
              </li>
            ))}
          </ul>
        );
      }
    },
    {
      id: 'actions',
      cell: ({ row }) => (
        <ActionsCell
          t={t}
          row={row}
          useActivateCourseSharingGroupMutation={useActivateCourseSharingGroupMutation}
          useDeactivateCourseSharingGroupJob={useDeactivateCourseSharingGroupJob}
          useDeleteCourseSharingGroupMutation={useDeleteCourseSharingGroupMutation}
          useRefreshCourseSharingGroup={useRefreshCourseSharingGroup}
        />
      )
    }
  ], [t]);
}

function ActionsCell({
  useActivateCourseSharingGroupMutation,
  useDeactivateCourseSharingGroupJob,
  useDeleteCourseSharingGroupMutation,
  useRefreshCourseSharingGroup,
  row,
  t
}) {
  const [showDeleteCourseSharingGroupModal, setShowDeleteCourseSharingGroupModal] = useState(false);

  const [activateCourseSharingGroup] = useActivateCourseSharingGroupMutation();
  const deactivateCourseSharingGroup = useDeactivateCourseSharingGroupJob();
  const refreshCourseSharingGroup = useRefreshCourseSharingGroup();

  const actions: ActionsProps['actions'] = [
    {
      title: t('seeDetails', { global: true }),
      url: `/admin/course-sharing-groups/${row.original.id}`
    }
  ];

  if (row.original.isActive) {
    actions.push({
      title: t('deactivate'),
      onClick: () => toast.promise(async () => {
        await deactivateCourseSharingGroup({id: row.original.id});
        await refreshCourseSharingGroup();
      })
    });
    actions.push({
      title: t('edit', { global: true }),
      url: `/admin/course-sharing-groups/${row.original.id}/edit`
    });
  } else {
    actions.push({
      title: t('activate'),
      onClick: () => toast.promise(async () => {
        await activateCourseSharingGroup({ id: row.original.id }).unwrap();
      })
    });
    actions.push({
      title: t('delete', { global: true }),
      danger: true,
      onClick: () => setShowDeleteCourseSharingGroupModal(true)
    });
  }

  return (
    <>
      <DeleteCourseSharingGroupModal
        show={showDeleteCourseSharingGroupModal}
        onHide={setShowDeleteCourseSharingGroupModal}
        courseSharingGroup={row.original}
        useDeleteCourseSharingGroupMutation={useDeleteCourseSharingGroupMutation}
      />
      <Actions variant="kebab" actions={actions}/>
    </>
  );
}
