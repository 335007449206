import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useId } from 'react';
import { TextInput } from 'react-responsive-ui';

import { ScreenReaderOnly } from '@acadeum/core-ui';
import { useTranslate } from '@acadeum/translate';
import { SearchInput as CCSearchInput } from '@acadeum/ui';


import Icon from '../Icon';

import './SearchInput.sass';

export default function SearchInput({
  value,
  onChange,
  onWait,
  waitForIdle,
  ...rest
}) {
  return (
    <CCSearchInput
      value={value}
      onChange={onChange}
      onWait={onWait}
      waitForIdle={waitForIdle}
      Component={Component}
      {...rest}
    />
  );
}

SearchInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onWait: PropTypes.func,
  waitForIdle: PropTypes.bool
};

function Component({
  value,
  onChange,
  placeholder = 'Search',
  className,
  'aria-label': ariaLabel
}) {
  const t = useTranslate('SearchInput');

  const id = useId();
  const descriptionElementId = `InstantSearch-SearchInput-${id}-Description`;

  return (
    <div className={classNames(className, 'search-input')}>
      <TextInput
        className="search-input__field"
        aria-label={ariaLabel || 'Search'}
        aria-describedby={descriptionElementId}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
      <button
        type="button"
        className="search-input__button"
        aria-label="Search">
        <Icon name="search" />
      </button>
      <ScreenReaderOnly id={descriptionElementId} as="span">
        {t('description')}
      </ScreenReaderOnly>
    </div>
  );
}

Component.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  clear: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string
};
