import { flatten, groupBy } from 'lodash-es';
import React from 'react';
import { useSelector } from 'react-redux';

import actions from '../../actions';

import './Report.sass';

const {
  fetchSessionOverSessionReport
} = actions;

export default function SessionOverSession() {
  const data = useSelector(state => state.reports.sessionOverSession);

  const currentYear = (new Date()).getFullYear() + 1;
  const startYear = 2016;
  const years = [];
  for (let i = startYear; i <= currentYear; i++) {
    years.push(i);
  }

  const headerLookup = {
    Sp1: 'Spring 1',
    Sp2: 'Spring 2',
    Sm1: 'Summer 1',
    Sm2: 'Summer 2',
    F1: 'Fall 1',
    F2: 'Fall 2',
    W: 'Winter',
    Total: 'total'
  };

  const header = Object.keys(headerLookup);

  const valueKeyList = flatten(years.map(year => header.map(h => ({ year, header: headerLookup[h] }))));
  valueKeyList.push('total');

  console.log(valueKeyList);

  const Table = ({ data }) => {
    data = Object.values(data);
    const groupedData = groupBy(data, 'name');
    const schools = Object.keys(groupedData);
    return (
      <div className="accounting-table-wrapper">
        <table className="accounting-table">
          <thead>
            <tr>
              <th></th>
              {years.map((_, i) => <th key={i} colSpan="8">{_}</th>)}
              <th></th>
            </tr>
            <tr>
              <th>School</th>
              {years.map((_, i) => header.map((_, j) => <th key={String(i) + j}>{_}</th>))}
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {schools.map((school, i) => (<tr key={i}><td>{school}</td>{
              valueKeyList.map((key, j) => <td key={j}>{getvalue(groupedData[school], key)}</td>)
            }</tr>))}
            <tr><td>Total</td>{valueKeyList.map((key, i) => <td key={i}>{data.filter(matchSession(key)).map(_ => Number(_.count)).reduce((a, b) => a + b, 0)}</td>)}</tr>
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="reports-page reports-page--session-over-session">
      <h1>All Requests EM</h1>
      <Table data={data[0]}/>
      <h1>All Requests TM</h1>
      <Table data={data[1]}/>
      <h1>Official Requests EM</h1>
      <Table data={data[2]}/>
      <h1>Official Requests TM</h1>
      <Table data={data[3]}/>
      <h1>Booked Requests EM</h1>
      <Table data={data[4]}/>
      <h1>Booked Requests TM</h1>
      <Table data={data[5]}/>
    </div>
  );
}

SessionOverSession.meta = () => ({
  title: 'Session over Session'
});

SessionOverSession.breadcrumbs = () => [
  ['Admin Tools', '/admin'],
  'Session over Session'
];

SessionOverSession.load = async () => {
  await fetchSessionOverSessionReport();
};

function getvalue(row, key) {
  if (key === 'total') {
    return row.map(_ => Number(_.count)).reduce((a, b) => a + b, 0);
  }
  if (key.header === 'total') return row.filter(matchSession(key)).map(_ => Number(_.count)).reduce((a, b) => a + b, 0);
  const val = row.filter(matchSession(key))[0];
  if (val) return Number(val.count);
  return '';
}

function matchSession(key) {
  if (key === 'total') return () => true;
  if (key.header === 'total') return _ => Number(_.year) === key.year;
  return _ => Number(_.year) === key.year && _.newSession.toLowerCase() === key.header.toLowerCase();
}
