import React, { useCallback, useMemo } from 'react';

import { getDataFromStorage, removeDataFromStorage, saveDataToStorage } from '@acadeum/helpers';

import useLocation from '../../hooks/useLocation.js';

import PublicMarketingPageBanner from '../PublicMarketingPageBanner';
import PublicMarketingPageBannerSection from '../PublicMarketingPageBannerSection';

import styles from './DefaultPublicMarketingPageBanner.module.sass';
import InstitutionIcon from './InstitutionIcon.svg';
import StudentIcon from './StudentIcon.svg';


const SHOW_ADDITIONAL_INFO_FLAG_NAME = 'DefaultPublicMarketingPageBanner.showAdditionalInfo';

export default function DefaultPublicMarketingPageBanner() {
  const location = useLocation();
  const isHS4CC = location.query.hs4cc === '✓';

  const initialShowAdditionalInformation = useMemo(() => {
    const showAdditionalInfo = getDataFromStorage(SHOW_ADDITIONAL_INFO_FLAG_NAME);
    if (showAdditionalInfo === false) {
      return false;
    }
    return true;
  }, []);

  const onChangeShowAdditionalInformation = useCallback((showAdditionalInfo) => {
    if (showAdditionalInfo === false) {
      saveDataToStorage(SHOW_ADDITIONAL_INFO_FLAG_NAME, false);
    } else {
      removeDataFromStorage(SHOW_ADDITIONAL_INFO_FLAG_NAME);
    }
  }, []);

  return(
    <PublicMarketingPageBanner
      dismissible={!isHS4CC}
      className={styles.DefaultPublicMarketingPageBanner}
      initialShowAdditionalInformation={initialShowAdditionalInformation}
      onChangeShowAdditionalInformation={onChangeShowAdditionalInformation}
      title="Welcome to Acadeum Course Share!"
      description="Acadeum increases access to in-demand certifications, courses, and programs to meet the needs of today’s learners."
    >
      <PublicMarketingPageBannerSection
        icon={StudentIcon}
        title="I’m a student. How does course sharing help me?"
        points={['Find and register for courses that aren’t available on your campus', 'Access fully online courses that align with your busy schedule', 'Eliminate the hassle and paperwork associated with transfer courses', 'Use financial aid just like you would for courses at your home institution']}
        readMoreUrl="https://go.acadeum.com/students"
        readMoreUrlTitle="go.acadeum.com/students"
      />
      <PublicMarketingPageBannerSection
        icon={InstitutionIcon}
        title="I’m an academic leader. How does course sharing help me?"
        points={['Give students access to an expanded course catalog while maintaining oversight and autonomy', 'Ensure quality by reviewing syllabi, faculty credentials, learning outcomes, and more', 'Improve retention by keeping students on-track when scheduling challenges arise']}
        readMoreUrl="https://www.acadeum.com"
        readMoreUrlTitle="www.acadeum.com"
      />
    </PublicMarketingPageBanner>
  );
}
