import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connectRange } from 'react-instantsearch-dom';
import RheostatImport from 'rheostat';

import { formatCurrency } from '../../../helpers/format';
import instantSearchCollapsible from '../instantSearchCollapsible';

import './InstantSearchRange.sass';

// For some weird reason, because of `type: "module"`, those imports are wrapped in `{ default }`.
const Rheostat = RheostatImport.default ? RheostatImport.default : RheostatImport;

// Copy-pasted from
// https://community.algolia.com/react-instantsearch/widgets/RangeSlider.html

function Handle(props) {
  return (
    <button
      type="button"
      aria-label={props['data-handle-key'] === 0 ? 'From' : 'To'}
      {...props}
      tabIndex={props['aria-disabled'] ? -1 : props.tabIndex}/>
  );
}

function PriceHandleUSD(props) {
  return (
    <Handle
      {...props}
      aria-label={props['data-handle-key'] === 0 ? 'Minimum price' : 'Maximum price'}
      aria-valuetext={`$${  props['aria-valuenow']}`}/>
  );
}

class InstantSearchRange extends Component {
  static propTypes = {
    inputs: PropTypes.bool.isRequired,
    snap: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
    currentRefinement: PropTypes.object,
    refine: PropTypes.func.isRequired,
    canRefine: PropTypes.bool.isRequired,
    currency: PropTypes.string,
    noRangeMessage: PropTypes.func.isRequired
  };

  static defaultProps = {
    inputs: false,
    noRangeMessage: () => 'No filtering available'
  };

  state = {
    minValueInput: this.props.min,
    maxValueInput: this.props.max,
    min: this.props.min,
    max: this.props.max
  };

  componentDidUpdate() {
    const { canRefine, currentRefinement } = this.props;
    if (canRefine) {
      if (
        this.state.min !== currentRefinement.min ||
        this.state.max !== currentRefinement.max
      ) {
        this.setState({
          // Set text input field values.
          minValueInput: currentRefinement.min,
          maxValueInput: currentRefinement.max,
          // Set slider handle positions.
          min: currentRefinement.min,
          max: currentRefinement.max
        });
      }
    }
  }

  onValuesUpdated = (sliderState) => {
    this.setState({
      // Set slider handle positions.
      min: sliderState.values[0],
      max: sliderState.values[1],
      // Set text input field values.
      minValueInput: sliderState.values[0],
      maxValueInput: sliderState.values[1]
    });
  };

  onChange = (sliderState) => {
    const { currentRefinement, refine } = this.props;

    if (sliderState.values[0] !== currentRefinement.min ||
      sliderState.values[1] !== currentRefinement.max) {
      refine({
        min: sliderState.values[0],
        max: sliderState.values[1]
      });
    }
  };

  formatValue(value) {
    const { currency } = this.props;
    if (currency) {
      return formatCurrency(value, currency);
    }
    return value;
  }

  onMinValueChange = (value) => {
    const { min } = this.state;
    value = parseInt(value, 10);
    this.setState({
      minValueInput: isNaN(value) ? min : value
    });
  };

  onMaxValueChange = (value) => {
    const { max } = this.state;
    value = parseInt(value, 10);
    this.setState({
      maxValueInput: isNaN(value) ? max : value
    });
  };

  onMinValueBlur = () => {
    const { min, currentRefinement, refine } = this.props;
    const { maxValueInput } = this.state;
    let { minValueInput } = this.state;

    if (minValueInput > maxValueInput) {
      minValueInput = maxValueInput;
      this.setState({
        minValueInput
      });
    }

    if (minValueInput < min) {
      minValueInput = min;
      this.setState({
        minValueInput
      });
    }

    if (minValueInput !== currentRefinement.min ||
      maxValueInput !== currentRefinement.max) {
      refine({
        min: minValueInput,
        max: maxValueInput
      });
    }
  };

  onMaxValueBlur = () => {
    const { max, currentRefinement, refine } = this.props;
    const { minValueInput } = this.state;
    let { maxValueInput } = this.state;

    if (maxValueInput < minValueInput) {
      maxValueInput = minValueInput;
      this.setState({
        maxValueInput
      });
    }

    if (maxValueInput > max) {
      maxValueInput = max;
      this.setState({
        maxValueInput
      });
    }

    if (minValueInput !== currentRefinement.min ||
      maxValueInput !== currentRefinement.max) {
      refine({
        min: minValueInput,
        max: maxValueInput
      });
    }
  };

  onMinValueKeyDown = (event) => {
    // Apply changes on Enter key down
    if (event.keyCode === 13) {
      this.onMinValueBlur();
    }
  };

  onMaxValueKeyDown = (event) => {
    // Apply changes on Enter key down
    if (event.keyCode === 13) {
      this.onMaxValueBlur();
    }
  };

  renderInputCurrencySymbol(currency) {
    return (
      <div className="InstantSearch-Range-value-currency">
        {currency === 'USD' ? '$' : currency}
      </div>
    );
  }

  render() {
    const {
      snap,
      min,
      max,
      currentRefinement,
      currency,
      canRefine,
      noRangeMessage
    } = this.props;

    let { minValueInput, maxValueInput } = this.state;

    // Fixes React warning:
    // "A component is changing an uncontrolled input of type text to be controlled".
    minValueInput = minValueInput || '';
    maxValueInput = maxValueInput || '';

    // `canRefine` means has any search results
    // as far as I could guess. Variable names are
    // extremely cryptic in this algolia React library.

    if (!canRefine) {
      return <div>Nothing found</div>;
    }

    if (min === max) {
      return <div>{noRangeMessage(min)}</div>;
    }

    return (
      <div className={classNames('InstantSearch-Range', {
        'InstantSearch-Range--currency': currency
      })}>
        <div className="InstantSearch-Range-from-to-values">
          <label>
            From
            <div className="InstantSearch-Range-from">
              {currency && this.renderInputCurrencySymbol(currency)}

              <TextInputComponent
                paddingLeft
                className="InstantSearch-Range-from-input"
                aria-label={currency === 'USD' ? 'Minimum price' : 'From'}
                tabIndex={this.props['aria-hidden'] ? -1 : undefined}
                value={minValueInput}
                onChange={this.onMinValueChange}
                onBlur={this.onMinValueBlur}
                onKeyDown={this.onMinValueKeyDown}/>
            </div>
          </label>
          <label>
            To
            <div className="InstantSearch-Range-to">
              {currency && this.renderInputCurrencySymbol(currency)}
              <TextInputComponent
                paddingLeft
                className="InstantSearch-Range-to-input"
                aria-label={currency === 'USD' ? 'Maximum price' : 'To'}
                tabIndex={this.props['aria-hidden'] ? -1 : undefined}
                value={maxValueInput}
                onChange={this.onMaxValueChange}
                onBlur={this.onMaxValueBlur}
                onKeyDown={this.onMaxValueKeyDown}/>
            </div>
          </label>
        </div>

        <Rheostat
          snap={snap}
          handle={currency === 'USD' ? PriceHandleUSD : Handle}
          min={min}
          max={max}
          values={[currentRefinement.min, currentRefinement.max]}
          onChange={this.onChange}
          onValuesUpdated={this.onValuesUpdated}
          disabled={this.props['aria-hidden'] ? true : undefined}/>

        <div className="InstantSearch-Range-values">
          <div className="InstantSearch-Range-value">
            {this.formatValue(this.state.min)}
          </div>
          <div className="InstantSearch-Range-value">
            {this.formatValue(this.state.max)}
          </div>
        </div>
      </div>
    );
  }
}

function TextInputComponent({
  onChange,
  paddingLeft,
  className,
  ...rest
}) {
  return (
    <input
      {...rest}
      type="text"
      className={classNames(className, 'InstantSearchTextInput', {
        'InstantSearchTextInput--paddingLeft': paddingLeft
      })}
      onChange={(event) => onChange(event.target.value)}
    />
  );
}

export default instantSearchCollapsible(connectRange(InstantSearchRange));
