import type { FC } from 'react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import type { FetchCourseEnrollmentsOutputItem } from '@acadeum/api';
import { getAuthSelector } from '@acadeum/auth';
import { userHasPermission } from '@acadeum/helpers';
import { useTranslate } from '@acadeum/translate';
import { Actions } from '@acadeum/ui';

import { getCourseEnrollmentStatusTransitions } from '../../lib/getCourseEnrollmentStatusTransitions';
import type { CourseEnrollmentMode } from '../../types';

import ChangeStatus from '../ChangeStatus';
import { CourseEnrollmentGradeModal } from '../CourseEnrollmentGradeModal';
import { CourseEnrollmentsDetailsModal } from '../CourseEnrollmentsDetailsModal';

export interface HomeEnrollmentActionsProps {
  mode: CourseEnrollmentMode;
  isGrade?: boolean;
  enrollment: FetchCourseEnrollmentsOutputItem;
  downloadRow: () => Promise<void>;
}

export const CourseEnrollmentActions: FC<HomeEnrollmentActionsProps> = ({
  isGrade,
  enrollment,
  downloadRow,
  mode
}) => {
  const t = useTranslate('CourseEnrollmentsTable');
  const [show, setShow] = useState(false);
  const [showGradeModal, setShowGradeModal] = useState(false);

  const user = useSelector(getAuthSelector('user'));
  const canEdit = userHasPermission(user, 'teachingCourseEnrollmentGrade:update', {
    orgId: user?.institution.id,
    ownerId: null
  });

  if (isGrade) {
    return (
      <>
        <CourseEnrollmentGradeModal
          mode={mode}
          show={showGradeModal}
          onHide={setShowGradeModal}
          downloadRow={downloadRow}
          enrollmentId={enrollment.id}
        />

        <Actions
          variant="kebab"
          actions={[
            {
              title: mode === 'teachingInstitution' && canEdit ? t('edit', { global: true }) : t('seeDetails', { global: true }),
              onClick: () => setShowGradeModal(true)
            },
            {
              title: t('download', { global: true }),
              onClick: downloadRow
            }
          ]}
        />
      </>
    );
  }

  return (
    <>
      <CourseEnrollmentsDetailsModal
        mode={mode}
        show={show}
        onHide={setShow}
        downloadRow={downloadRow}
        enrollments={[enrollment]}
      />

      <ChangeStatus
        mode={mode}
        exportData={downloadRow}
      >
        {({ onChange }) => (
          <Actions
            variant="kebab"
            actions={[
              {
                title: t('seeDetails', { global: true }),
                onClick: () => setShow(true)
              },
              {
                title: t('download', { global: true }),
                onClick: downloadRow
              },
              ...(getCourseEnrollmentStatusTransitions({
                mode,
                enrollment
              }).map((status) => ({
                title: t(`status.transitionTo.${status.toLowerCase()}`),
                danger: status === 'REMOVED',
                onClick: () => onChange([enrollment], status)
              })))
            ]}
          />
        )}
      </ChangeStatus>
    </>
  );
};
