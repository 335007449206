import React from 'react';

import { useQueryWithPagination } from '@acadeum/hooks';
import { PlusIcon } from '@acadeum/icons';
import { CourseSharingGroupsPage } from '@acadeum/shared-admin-ui';
import { useOnError } from '@acadeum/shared-admin-ui';
import { useTranslate } from '@acadeum/translate';
import { Button, Page } from '@acadeum/ui';

import {
  useFetchCourseSharingGroupsQuery,
  useActivateCourseSharingGroupMutation,
  useDeleteCourseSharingGroupMutation,
  useRefreshCourseSharingGroup,
  useDeactivateCourseSharingGroupJob
} from '../../api/admin/courseSharingGroups';

const CourseSharingGroups = () => {
  const t = useTranslate('CourseSharingGroups');

  const onError = useOnError();

  const {
    data,
    isFetching,
    isLoading,
    isError,
    error,
    _paginationOptions,
    _globalFilterOptions
  } = useQueryWithPagination(useFetchCourseSharingGroupsQuery);

  if (error) {
    return onError(error);
  }

  return (
    <Page
      title="Course Sharing Groups"
      breadcrumbs={[
        [t('home', { global: true }), '/'],
        [t('adminTools', { global: true }), '/admin'],
        t('courseSharingGroups')
      ]}
      actions={(
        <Button variant="secondary" icon={PlusIcon} url="/admin/course-sharing-groups/create">
          {t('createCourseSharingGroups')}
        </Button>
      )}
    >
      <CourseSharingGroupsPage
        data={data}
        isFetching={isFetching}
        paginationOptions={_paginationOptions}
        isLoading={isLoading}
        isError={isError}
        globalFilterOptions={_globalFilterOptions}
        useActivateCourseSharingGroupMutation={useActivateCourseSharingGroupMutation}
        useDeactivateCourseSharingGroupJob={useDeactivateCourseSharingGroupJob}
        useDeleteCourseSharingGroupMutation={useDeleteCourseSharingGroupMutation}
        useRefreshCourseSharingGroup={useRefreshCourseSharingGroup}
      />
    </Page>
  );
};

export default CourseSharingGroups;
