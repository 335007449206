import type { FC, ReactNode } from 'react';
import React, { useState } from 'react';

import type { FetchPrimaryPaymentSourceOutput } from '@acadeum/api';
import { useTranslate } from '@acadeum/translate';
import type { ActionsProps } from '@acadeum/ui';
import { Actions, ConfirmActionModal, HStack, Tag } from '@acadeum/ui';


import { getBankAccountStatuses } from '../../lib/getBankAccountStatuses';
import { AddBankAccountModal } from '../AddBankAccountModal';
import type { AddBankAccountModalProps } from '../AddBankAccountModal';


import styles from './BankAccountInfo.module.scss';

export interface BankAccountInfoProps extends Pick<DeleteBankAccountProps, 'useUpdatePrimaryPaymentSourceMutation' | 'useCreatePrimaryPaymentSourceMutation'> {
  onAfterRequestDeleteBankAccount?: () => void;
  primaryPaymentSource: FetchPrimaryPaymentSourceOutput;
  type: 'current' | 'new';
}

export const BankAccountInfo: FC<BankAccountInfoProps> = ({
  primaryPaymentSource,
  type,
  onAfterRequestDeleteBankAccount,
  useCreatePrimaryPaymentSourceMutation,
  useUpdatePrimaryPaymentSourceMutation
}) => {
  const [showDeleteBankAccount, setShowDeleteBankAccount] = useState(false);

  const status = primaryPaymentSource[type]?.status;
  const bankAccount = primaryPaymentSource[type]?.bankAccount;

  const t = useTranslate('shared-admin-ui.BankAccountInfo');

  const actions: ActionsProps['actions'] = [];

  if (
    type === 'current' &&
    status === 'ACTIVE' &&
    !primaryPaymentSource.new &&
    onAfterRequestDeleteBankAccount
  ) {
    actions.push({
      title: t('delete', { global: true }),
      danger: true,
      onClick: () => setShowDeleteBankAccount(true)
    });
  }

  return (
    <div className={styles.BankAccountInfo__accountInformation}>
      <div className={styles.BankAccountInfo__actions}>
        {actions.length > 0 && (
          <Actions variant="kebab" actions={actions}/>
        )}

        <DeleteBankAccount
          show={showDeleteBankAccount}
          onHide={() => setShowDeleteBankAccount(false)}
          useUpdatePrimaryPaymentSourceMutation={useUpdatePrimaryPaymentSourceMutation}
          useCreatePrimaryPaymentSourceMutation={useCreatePrimaryPaymentSourceMutation}
        />
      </div>

      <BankAccountInfoRow
        label={t('bankAccount.holderName')}
        value={bankAccount?.accountHolderName}
      />

      <BankAccountInfoRow
        label={t('bankAccount.holderType')}
        value={bankAccount?.accountHolderType}
      />

      <BankAccountInfoRow
        label={t('bankAccount.number')}
        value={'*'.repeat(8) + bankAccount?.lastFourDigits}
      />

      <BankAccountInfoRow
        label={t('bankAccount.routingNumber')}
        value={bankAccount?.routingNumber}
      />

      <BankAccountInfoRow
        label={t('bankAccount.country')}
        value={bankAccount?.country}
      />

      <BankAccountInfoRow
        label={t('bankAccount.currency')}
        value={bankAccount?.currency.toUpperCase()}
      />

      <BankAccountInfoRow
        label={t('bankAccount.status')}
        value={(
          <HStack gap="sm">
            {getBankAccountStatuses(primaryPaymentSource?.[type]).map((status, index) => (<Tag key={index}
              variant={status}/>))}
          </HStack>
        )}
      />
    </div>
  );
};

interface BankAccountInfoRow {
  label: string;
  value?: string | ReactNode;
}

function BankAccountInfoRow({ label, value }: BankAccountInfoRow) {
  return (
    <div className={styles.BankAccountInfoRow}>
      <div className={styles.BankAccountInfoRow__label}>
        {label}
      </div>

      <div className={styles.BankAccountInfoRow__value}>
        {value}
      </div>
    </div>
  );
}

interface DeleteBankAccountProps extends Pick<AddBankAccountModalProps, 'useCreatePrimaryPaymentSourceMutation' | 'useUpdatePrimaryPaymentSourceMutation'> {
  show: boolean;
  onHide: () => void;
  onAfterUpdateBankAccount?: () => void;
}

function DeleteBankAccount({
  show,
  onHide,
  onAfterUpdateBankAccount,
  useUpdatePrimaryPaymentSourceMutation,
  useCreatePrimaryPaymentSourceMutation
}: DeleteBankAccountProps) {
  const t = useTranslate('shared-admin-ui.ManageBankAccountScreen.deleteBankAccountConfirm');

  const [showAddBankAccountModal, setShowAddBankAccountModal] = useState(false);

  const onAddBackAccount = () => {
    onHide();
    setShowAddBankAccountModal(true);
  };

  const onAfterSubmit = async () => {
    setShowAddBankAccountModal(false);

    if (typeof onAfterUpdateBankAccount === 'function') {
      await onAfterUpdateBankAccount();
    }
  };

  return (
    <>
      <ConfirmActionModal
        show={show}
        onHide={onHide}
        onCancel={onHide}
        onSubmit={onAddBackAccount}
        title={t('title')}
        description={t('description')}
        submitText={t('confirm')}
      />

      <AddBankAccountModal
        type="new"
        show={showAddBankAccountModal}
        onHide={() => setShowAddBankAccountModal(false)}
        onAfterSubmit={onAfterSubmit}
        useCreatePrimaryPaymentSourceMutation={useCreatePrimaryPaymentSourceMutation}
        useUpdatePrimaryPaymentSourceMutation={useUpdatePrimaryPaymentSourceMutation}
      />
    </>
  );
}
