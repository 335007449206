import PropTypes from 'prop-types';
import React from 'react';

import './FormHeader.scss';

export default function FormHeader({ children }) {
  return (
    <div className="FormHeader">
      {children}
    </div>
  );
}

FormHeader.propTypes = {
  children: PropTypes.node.isRequired
};
