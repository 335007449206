import classNames from 'classnames';
import type { ReactNode, FC } from 'react';
import React from 'react';

import styles from './Row.module.scss';

export interface RowProps {
  className?: string;
  children: ReactNode;
}

export const Row: FC<RowProps> = ({
  className,
  children
}) => {
  return (
    <div className={classNames(className, styles.root)}>
      {children}
    </div>
  );
};
