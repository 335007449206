import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import reportError from 'common-lib/lib/reportError';

import { FormatDate } from '@acadeum/ui';

import Email from '../Email';
import Link from '../Link';
import Phone from '../Phone';

import './Output.sass';

export default function Output({
  col,
  label,
  multiline,
  className,
  ...rest
}) {
  return (
    <div className={classNames('output', col && `col-${col}`, col && 'col-xs-12', className)}>
      <div className="output__label form-input__label">
        {label}
      </div>
      <div className={classNames('output__value', {
        'output__value--line-height': !multiline
      })}>
        <Value {...rest}/>
      </div>
    </div>
  );
}

function Value({
  value,
  values,
  ext,
  utc,
  type,
  linkTo,
  linkOpensInNewTab,
  month = 'long',
  time
}) {
  const ValueComponent = linkTo ? LinkTo : React.Fragment;
  const valueComponentProperties = linkTo ? { linkTo, linkOpensInNewTab } : undefined;
  return (
    <ValueComponent {...valueComponentProperties}>
      {renderValue(value || values, type, { ext, utc, month, time })}
    </ValueComponent>
  );
}

Value.propTypes = {
  value: PropTypes.any,
  ext: PropTypes.string,
  utc: PropTypes.bool,
  values: PropTypes.arrayOf(PropTypes.any),
  type: PropTypes.oneOf([
    'date',
    'state',
    'gender',
    'number',
    'integer',
    'email',
    'phone',
    'code',
    'textarea'
  ]),
  month: PropTypes.oneOf(['numeric', 'long']),
  linkTo: PropTypes.string,
  linkOpensInNewTab: PropTypes.bool
};

function LinkTo({ linkTo, linkOpensInNewTab, children }) {
  return (
    <Link
      to={linkTo}
      target={linkOpensInNewTab ? '_blank' : undefined}>
      {children}
    </Link>
  );
}

LinkTo.propTypes = {
  linkTo: PropTypes.string.isRequired,
  linkOpensInNewTab: PropTypes.bool,
  children: PropTypes.node
};

function renderValue(value, type, { ext, utc, month, time }) {
  if (value === undefined || value === null) {
    return '—';
  }
  switch (type) {
    case 'date':
      if (utc === undefined) {
        reportError('`utc` property is required when rendering a `type="date"` or `dates` `<Output/>`');
      }
      if (Array.isArray(value)) {
        return (
          <React.Fragment>
            <FormatDate date={value[0]} month={month} time={time} utc={utc}/>
            {' — '}
            <FormatDate date={value[1]} month={month} time={time} utc={utc}/>
          </React.Fragment>
        );
      }
      return <FormatDate date={value} month={month} time={time} utc={utc}/>;
    case 'gender':
      return value === true ? 'Male' : (value === false ? 'Female' : 'Unspecified');
    case 'phone':
      return <Phone number={value} ext={ext}/>;
    case 'email':
      return <Email address={value}/>;
    case 'code':
      return <code>{value}</code>;
    default:
      return value;
  }
}

Output.propTypes = {
  col: PropTypes.number,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
  ...Value.propTypes
};

export function OutputPlaceholder({
  col,
  className,
  children
}) {
  return (
    <div className={classNames('output', col && `col-${col}`, col && 'col-xs-12', className)}>
      {children}
    </div>
  );
}

OutputPlaceholder.propTypes = {
  col: PropTypes.number,
  className: PropTypes.string,
  children: PropTypes.node
};
