import { cloneDeep, isEqual, reduce } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import Form from '../../components/Form';
import FormSubmit from '../../components/FormSubmit';

import Spreadsheet from './x-data-spreadsheet@1.1.9';

const INDEX_COLUMN_WIDTH = 182;

export default function CustomSpreadsheet({
  id,
  content,
  name,
  columns,
  onSubmit
}) {
  const [data, setData] = useState({});
  const [initialDataSnapshot, setInitialDataSnapshot] = useState([]);

  useEffect(() => {
    const rows = content.reduce((row, currentCell, idx) => {
      return {
        ...row,
        [idx + 1]: {
          cells: currentCell
        }
      };
    }, {
      0: {
        cells: columns.reduce((combinedColumns, currentCell, index) => ({
          ...combinedColumns,
          [index]: {
            text: currentCell.label,
            editable: false
          }
        }), {})
      }
    });

    setInitialDataSnapshot(getRawProjectionData(cloneDeep(rows)));

    const spreadsheet = new Spreadsheet(`#${id}`, {
      view: {
        height: () => document.documentElement.clientHeight,
        width: () => document.documentElement.clientWidth
      },
      row: { len: content.length + 1 },
      col: { len: columns.length + 1 },
      showToolbar: false,
      showBottomBar: false,
      showContextmenu: false,
      mode: 'edit'
    })
      .loadData({
        name,
        freeze: 'A2',
        cols: {
          1: {
            width: INDEX_COLUMN_WIDTH
          }
        },
        rows
      }) // load data
      .change(setData);

    // data validation
    spreadsheet.validate();
  }, []);

  const submitChanges = async () => {
    if (!Object.keys(data).length || data.rows.length === 0) {
      return;
    }

    const currentRawData = getRawProjectionData(data.rows);

    // perform deepDiff to isolate which content have changed'
    const keyNames = columns.map(c => c.key);
    const updatedData = deepDiff(currentRawData, initialDataSnapshot, keyNames);
    await onSubmit(updatedData, id);

    // reset Spreadsheet
    setInitialDataSnapshot(getRawProjectionData(cloneDeep(data.rows)));
  };

  return (
    <section>
      <h1>{name}</h1>

      <div id={id} />

      <br />

      <Form
        className="form"
        onSubmit={submitChanges}>
        <FormSubmit>
          Submit
        </FormSubmit>
      </Form>
    </section>
  );
}

CustomSpreadsheet.propTypes = {
  id: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired
    })
  ).isRequired,
  content: PropTypes.arrayOf(PropTypes.object).isRequired,
  name: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired
};

function getRawProjectionData(rows) {
  // removes style information
  const result = Object.entries(rows).map(([, value]) => {
    if (!value.cells) return null;

    return Object.values(value.cells).filter(_ => _.text);
  });
  // remove column headers
  result.shift();
  return result;
}

function deepDiff(obj1, obj2, keyNames) {
  return reduce(obj1, (result, value, key) => {
    if (obj2[key] && !isEqual(value, obj2[key])) {
      const changes = Object.values(value).reduce((previousCell, currentCell, cellIdx) => {
        if (cellIdx > 1 && obj2[key][cellIdx].text !== currentCell.text) {
          return {
            ...previousCell,
            [keyNames[cellIdx]]: Number(currentCell.text)
          };
        }
        return previousCell;
      }, {});
      result[value[0].text] = changes;
    }
    return result;
  }, {});
}
