import React from 'react';

import actions from '../../actions';
import Col from '../../components/Col';
import Field from '../../components/Field';
import Form from '../../components/Form';
import FormHeading from '../../components/FormHeading';
import FormSubmit from '../../components/FormSubmit';
import Row from '../../components/Row';


const {
  changeSessionsTerm,
  notify,
  notifyError
} = actions;

export default function ChangeSessionTerm() {
  const onSubmit = async (values) => {
    let sessionIds;
    if (values.sessionIds) {
      sessionIds = parseCommaSeparatedIds(values.sessionIds);
    }
    if (!sessionIds) {
      return notifyError('Enter session IDs');
    }
    await changeSessionsTerm(sessionIds, values.term);
    notify('The term has been changed');
  };

  return (
    <section>
      <FormHeading>
        Change Session Term
      </FormHeading>

      <Form
        autoFocus
        className="form"
        onSubmit={onSubmit}>

        <Row alignChildren="bottom">
          <Field
            required
            name="sessionIds"
            label="Session IDs (comma-separated)"
            col={8}/>

          <Field
            required
            name="term"
            label="Term"
            col={2}/>

          <Col col={2} xs={12}>
            <FormSubmit className="form__button--inline-except-xs form__button--wide">
              Update
            </FormSubmit>
          </Col>
        </Row>
      </Form>
    </section>
  );
}

ChangeSessionTerm.meta = () => ({
  title: 'Change Session Term'
});

ChangeSessionTerm.breadcrumbs = () => [
  ['Admin Tools', '/admin'],
  'Change Session Term'
];

function parseCommaSeparatedIds(value) {
  const ids = value.split(',').map(_ => _.trim()).filter(_ => _);
  if (ids.length > 0) {
    return ids.map((id) => {
      const _id = Number(id);
      if (id !== String(_id)) {
        throw new Error(`Invalid ID: ${id}`);
      }
      return _id;
    });
  }
}
