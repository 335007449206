import classNames from 'classnames';
import { Field } from 'easy-react-form';
import PropTypes from 'prop-types';
import React, { useId } from 'react';

import { useTranslate } from '@acadeum/translate';

import Input from '../Input';

import onField from './onField';

import './FormField.scss';

let FormField = function ({
  id,
  type: _type,
  validate: _validate,
  loading,
  optional,
  defaultValue,
  className,
  ...rest
}, ref) {
  const t = useTranslate('Form');

  const newId = `FormField-${useId()}`;

  const { type, validate } = onField({
    type: _type,
    validate: _validate,
    t
  });

  return (
    <Field
      ref={ref}
      component={Input}
      id={id || newId}
      type={type}
      validate={validate}
      value={defaultValue}
      className={classNames(className, 'FormField', {
        'FormField--optional': optional,
        'FormField--loading': loading
      })}
      {...rest}
    />
  );
};

FormField = React.forwardRef(FormField);

FormField.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  loading: PropTypes.bool,
  optional: PropTypes.bool,
  validate: PropTypes.func,
  defaultValue: PropTypes.any,
  className: PropTypes.string
};

export default FormField;
