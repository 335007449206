import React, { useState } from 'react';

import EnrollmentMessages from 'common-lib/messages/en/Enrollment.json';

import type { FetchCourseEnrollmentsOutputItem } from '@acadeum/api';
import { useTranslate } from '@acadeum/translate';
import type { CourseEnrollment } from '@acadeum/types';
import { toast } from '@acadeum/ui';

import {
  useCompleteTeachingCourseEnrollmentMutation,
  useDropStudentsFromHomeCourseMutation,
  useDropTeachingCourseEnrollmentMutation,
  useRemoveStudentsFromHomeCourseMutation,
  useWithdrawStudentsFromHomeCourseMutation,
  useWithdrawTeachingCourseEnrollmentMutation
} from '../../../../api/courseEnrollment';

import type { CourseEnrollmentMode } from '../../types';

import { ChangeEnrollmentStatusModal } from '../ChangeEnrollmentStatusModal';
import ReasonFormField from '../ReasonFormField';


const dropReasons = Object.keys(EnrollmentMessages.dropReason);
const withdrawReasons = Object.keys(EnrollmentMessages.withdrawReason);
const removeReasons = Object.keys(EnrollmentMessages.removeReason);

interface ChildrenProps {
  onChange: (enrollments: FetchCourseEnrollmentsOutputItem[], newStatus: CourseEnrollment['status']) => void;
}

export interface ChangeStatusProps {
  mode: CourseEnrollmentMode;
  onAfterSubmit?: () => void;
  exportData?: () => void;
  children: React.FC<ChildrenProps>;
}

export default function ChangeStatus({
  mode,
  exportData,
  onAfterSubmit,
  children
}: ChangeStatusProps) {
  const t = useTranslate('CourseEnrollmentsTable');

  const [dropStudentsFromCourseMutation] = useDropStudentsFromHomeCourseMutation();
  const [withdrawStudentsFromCourseMutation] = useWithdrawStudentsFromHomeCourseMutation();
  const [removeStudentsFromCourseMutation] = useRemoveStudentsFromHomeCourseMutation();

  const [completeTeachingCourseEnrollmentMutation] = useCompleteTeachingCourseEnrollmentMutation();
  const [withdrawTeachingCourseEnrollmentMutation] = useWithdrawTeachingCourseEnrollmentMutation();
  const [dropTeachingCourseEnrollmentMutation] = useDropTeachingCourseEnrollmentMutation();

  const [enrollments, setEnrollments] = useState<FetchCourseEnrollmentsOutputItem[]>([]);
  const [newStatus, setNewStatus] = useState<CourseEnrollment['status'] | null>();
  const [showChangeStatusConfirmationPopup, setShowChangeStatusConfirmationPopup] = useState(false);

  const onSubmitChangeStatusModal = async ({ grades, reason, reasonNotes }) => {
    const ids = enrollments.map(_ => _.id);
    switch (newStatus) {
      case 'COMPLETE':
        if (mode === 'homeInstitution') {
          throw new Error('Home institution cannot complete enrollments.');
        }
        await completeTeachingCourseEnrollmentMutation({ ids, grades });
        break;
      case 'DROPPED':
        if (mode === 'teachingInstitution') {
          await dropTeachingCourseEnrollmentMutation({ ids, reason, reasonNotes });
        } else {
          await dropStudentsFromCourseMutation({ ids, reason, reasonNotes });
        }
        break;
      case 'WITHDRAWN':
        if (mode === 'teachingInstitution') {
          await withdrawTeachingCourseEnrollmentMutation({ ids, grades, reason, reasonNotes });
        } else {
          await withdrawStudentsFromCourseMutation({ ids, reason, reasonNotes });
        }
        break;
      case 'REMOVED':
        if (mode === 'homeInstitution') {
          await removeStudentsFromCourseMutation({ ids, reason, reasonNotes });
        } else {
          throw new Error('Teaching institution cannot remove enrollments.');
        }
        break;
      default:
        throw new Error(`Unsupported target enrollment status: ${newStatus}`);
    }

    setShowChangeStatusConfirmationPopup(false);
    setNewStatus(null);
    setEnrollments([]);
    onAfterSubmit?.();

    if (newStatus === 'REMOVED') {
      toast.success('Enrollment Request has been removed successfully. ');
    } else {
      toast.success('Success! Enrollment status has been updated.');
    }
  };

  const onStatusChange: ChildrenProps['onChange'] = (enrollments, newStatus) => {
    setEnrollments(enrollments);
    setNewStatus(newStatus);
    setShowChangeStatusConfirmationPopup(true);
  };

  const reasonFormFieldLabel = t('enrollmentStatusTransitionReasonInputFieldLabel', {
    action: newStatus ? t(`status.transitionTo.${newStatus.toLowerCase()}`) : ''
  });

  return (
    <>
      {newStatus &&
        <ChangeEnrollmentStatusModal
          mode={mode}
          toStatus={newStatus}
          enrollments={enrollments}
          onSubmit={onSubmitChangeStatusModal}
          show={showChangeStatusConfirmationPopup}
          onHide={setShowChangeStatusConfirmationPopup}
          actions={[{ title: 'Download', onClick: exportData }]}
        >
          {newStatus === 'DROPPED' && (
            <ReasonFormField
              required
              label={reasonFormFieldLabel}
              labelsPath="EnrollmentsTable.dropReason"
              options={dropReasons}
            />
          )}
          {newStatus === 'WITHDRAWN' && (
            <ReasonFormField
              required
              label={reasonFormFieldLabel}
              labelsPath="EnrollmentsTable.withdrawReason"
              options={withdrawReasons}
            />
          )}
          {newStatus === 'REMOVED' && (
            <ReasonFormField
              required
              defaultValue="OTHER"
              label={reasonFormFieldLabel}
              labelsPath="EnrollmentsTable.removeReason"
              options={removeReasons}
            />
          )}
        </ChangeEnrollmentStatusModal>
      }

      {children({
        onChange: onStatusChange
      })}
    </>
  );
}
