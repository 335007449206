import type { ColumnDef } from '@tanstack/react-table';
import type { FC } from 'react';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import formatUserName from 'common-lib/lib/formatUserName';

import type { UseFetchUserRolesQuery, UseFetchUsersQuery, UseSetUsersRolesForUsersMutation } from '@acadeum/api';
import { getAuthSelector } from '@acadeum/auth';
import { userHasPermission } from '@acadeum/helpers';
import type { UseStepResult } from '@acadeum/hooks';
import { useStep } from '@acadeum/hooks';
import { ArrowsRotateIcon, GroupIcon } from '@acadeum/icons';
import { useTranslate } from '@acadeum/translate';
import type { UserRoleWithUsers } from '@acadeum/types';
import { Button, DataBlock, EmptyState, FormField, FormModal, Table, toast, createTableId } from '@acadeum/ui';

import { AssignUsersToRoleForm } from '../../ui/AssignUsersToRoleForm';
import { AssignUsersToRoleModalForm } from '../../ui/AssignUsersToRoleModalForm';

interface UsersTabProps {
  role: UserRoleWithUsers;
  useSetUsersRolesForUsersMutation: UseSetUsersRolesForUsersMutation;
  useFetchUserRolesQuery: UseFetchUserRolesQuery;
  useFetchUsersQuery: UseFetchUsersQuery;
}

type StepId = 'empty' | 'edit' | 'list';

export interface StepProps extends UsersTabProps {
  navigation: UseStepResult<StepId>['navigation'];
  hasAssignedUsers: boolean;
}

export interface Step {
  id: StepId;
  component: (props: StepProps) => JSX.Element;
}

const UsersTab: FC<UsersTabProps> = ({ 
  role,
  useSetUsersRolesForUsersMutation,
  useFetchUserRolesQuery,
  useFetchUsersQuery
}) => {
  const hasAssignedUsers = role.users && role.users.length > 0;

  const { step, navigation } = useStep({
    initialStep: hasAssignedUsers ? 'list' : 'empty',
    steps: [
      { id: 'empty', component: EmptyStep },
      { id: 'edit', component: EditStep },
      { id: 'list', component: UsersStep }
    ]
  });

  return (
    <step.component
      hasAssignedUsers={hasAssignedUsers}
      role={role}
      navigation={navigation}
      useSetUsersRolesForUsersMutation={useSetUsersRolesForUsersMutation}
      useFetchUserRolesQuery={useFetchUserRolesQuery}
      useFetchUsersQuery={useFetchUsersQuery}
    />
  );
};

export default UsersTab;

const EmptyStep = ({
  navigation
}: StepProps) => {
  const t = useTranslate('shared-admin-ui.UserRoleDetailsPage.UsersTab');

  return (
    <EmptyState
      icon={GroupIcon}
      title={t('EmptyState.title')}
      action={{
        content: t('EmptyState.action'),
        onClick: () => navigation.go('edit')
      }}
    >
      {t('EmptyState.content')}
    </EmptyState>
  );
};

const EditStep = ({
  navigation,
  role,
  hasAssignedUsers,
  useSetUsersRolesForUsersMutation,
  useFetchUsersQuery
}: StepProps) => {
  const t = useTranslate('shared-admin-ui.UserRoleDetailsPage.UsersTab');

  const [setUsersRolesForUsersMutation] = useSetUsersRolesForUsersMutation();

  return (
    <AssignUsersToRoleForm
      useFetchUsersQuery={useFetchUsersQuery}
      submitText={t('AssignUsersToRoleForm.submitText')}
      onCancel={() => navigation.go(hasAssignedUsers ? 'list' : 'empty')}
      onSubmit={async (values) => {
        await setUsersRolesForUsersMutation({
          userIds: values.userIds,
          roleIds: [role.id]
        });
        toast.success(t('AssignUsersToRoleForm.successMessage', { count: values.userIds.length }));
        navigation.go('list');
      }}
    />
  );
};

const UsersStep = ({
  role,
  useSetUsersRolesForUsersMutation,
  useFetchUserRolesQuery,
  useFetchUsersQuery
}: StepProps) => {
  const t = useTranslate('shared-admin-ui.UserRoleDetailsPage.UsersTab.UsersTable');

  const user = useSelector(getAuthSelector('user'));

  const columns = useMemo<ColumnDef<UserRoleWithUsers['users'][number]>[]>(() => [
    {
      id: 'name',
      header: t('name'),
      accessorFn: (row) => formatUserName(row),
      cell: ({ row }) => <DataBlock type="user" user={row.original}/>
    },
    {
      header: t('email'),
      accessorKey: 'email'
    }
  ], [t]);

  const [selectedRows, setSelectedRows] = useState<UserRoleWithUsers['users']>([]);
  const [showReassignRoleModal, setShowReassignRoleModal] = useState(false);
  const [showAssignUsersToRoleModalForm, setShowAssignUsersToRoleModalForm] = useState(false);

  const onAssignedUserToRole = async () => {
    setShowAssignUsersToRoleModalForm(false);
    toast.success(t('successMessage'));
  };

  return (
    <>
      <Table
        id={createTableId('userRoleUsers')}
        enableRowSelection
        enableGlobalFilter
        columns={columns}
        data={role.users}
        renderTopLeftToolbarCustomActions={({ selectedRows }) => {
          if (user && !userHasPermission(user, 'userRole:update', { orgId: user.institution.id, ownerId: null })) {
            return null;
          }
          return (
            <Button
              variant="tertiary"
              icon={ArrowsRotateIcon}
              disabled={selectedRows.length === 0}
              onClick={() => {
                setSelectedRows(selectedRows);
                setShowReassignRoleModal(true);
              }}
            >
              {t('reassign')}
            </Button>
          );
        }}
        renderTopRightToolbarCustomActions={() => {
          if (user && !userHasPermission(user, 'userRole:update', { orgId: user.institution.id, ownerId: null })) {
            return null;
          }
          return (
            <Button onClick={() => setShowAssignUsersToRoleModalForm(true)}>
              {t('assignUsers')}
            </Button>
          );
        }}
        translate={{
          searchPlaceholder: t('searchByNameOrEmail'),
          resultText: ({ totalCount }) => t('resultText', { totalCount }),
          selectedResultText: ({ totalCount, selectedRowsCount }) => t('selectedResultText', {
            totalCount,
            selectedRowsCount
          })
        }}
      />

      <ReassignRoleModalForm
        useSetUsersRolesForUsersMutation={useSetUsersRolesForUsersMutation}
        useFetchUserRolesQuery={useFetchUserRolesQuery}
        show={showReassignRoleModal}
        onHide={setShowReassignRoleModal}
        users={selectedRows}
      />

      <AssignUsersToRoleModalForm
        useSetUsersRolesForUsersMutation={useSetUsersRolesForUsersMutation}
        useFetchUsersQuery={useFetchUsersQuery}
        show={showAssignUsersToRoleModalForm}
        onHide={setShowAssignUsersToRoleModalForm}
        onAfterSubmit={onAssignedUserToRole}
        role={role}
      />
    </>
  );
};

function ReassignRoleModalForm({
  show,
  onHide,
  users,
  useSetUsersRolesForUsersMutation,
  useFetchUserRolesQuery
}) {
  const [setUsersRolesForUsersMutation] = useSetUsersRolesForUsersMutation();

  const {
    data: userRolesData,
    isLoading: loading
  } = useFetchUserRolesQuery({
    pageSize: 1000
  });

  const options = useMemo(() => {
    return userRolesData?.results.map(_ => ({
      value: _.id,
      label: _.name
    })) ?? [];
  }, [userRolesData]);

  const onSubmit = async ({ roleId }: { roleId: UserRoleWithUsers['id'] }) => {
    await setUsersRolesForUsersMutation({
      userIds: users.map(_ => _.id),
      roleIds: [roleId]
    }).unwrap();
    toast.success(`The user${users.length === 1 ? '' : 's'} has been reassigned to a new role successfully`);
    onHide(false);
  };

  return (
    <FormModal
      show={show}
      onHide={onHide}
      title="Reassign Role"
      onCancel={onHide}
      onSubmit={onSubmit}
    >
      <FormField
        name="users"
        label="Users"
        readOnly
        defaultValue={users.map(_ => formatUserName(_)).join(', ')}
      />
      <FormField
        someWidth
        name="roleId"
        type="select"
        options={options}
        label="Role"
        readOnly={loading}
        defaultValue={options[0]?.value}
      />
    </FormModal>
  );
}
