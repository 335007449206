import { useCallback, useEffect, useState } from 'react';

import { isPromise } from '@acadeum/helpers';
import { useIsMounted } from '@acadeum/hooks';

import { getFilesFromEvent, validateFileAccepted, validateFileSize } from './utils';

interface UseValidateFileOptions {
  maxSize?: number;
  minSize?: number;
  accept?: string | string[];
}

export const useValidateFile = ({ accept, minSize, maxSize }: UseValidateFileOptions) => {
  return useCallback((file) => {

    const fileAcceptedErrorMessage = validateFileAccepted(file, accept);
    if (fileAcceptedErrorMessage) {
      return fileAcceptedErrorMessage;
    }

    const sizeErrorMessage = validateFileSize(file, { maxSize, minSize });
    if (sizeErrorMessage) {
      return sizeErrorMessage;
    }
  }, [accept, minSize, maxSize]);
};

interface UseOnChangeOptions {
  maxSize?: number;
  minSize?: number;
  accept?: string | string[];
  setError?: (errorMessage?: string) => void;
  multiple?: boolean;
  setFiles?: (file?: File | FileList) => void;
  onChange?: (file?: File | FileList) => void;
  setIsLoading?: (value: boolean) => void;
}

export const useOnChange = ({
  accept,
  multiple,
  setError,
  setFiles,
  onChange,
  setIsLoading,
  minSize,
  maxSize
}: UseOnChangeOptions) => {
  const isMounted = useIsMounted();
  const validateFile = useValidateFile({ accept, minSize, maxSize });

  return useCallback(async (files: FileList) => {
    if (files) {
      for (const file of files) {
        const errorMessage = validateFile(file);
        if (errorMessage) {
          setError?.(errorMessage);
          return false;
        }
      }
      setError?.(undefined);

      const allFiles = multiple ? files : files[0];
      try {
        setFiles?.(allFiles);
        if (typeof onChange === 'function') {
          const result = onChange(allFiles);
          if (isPromise(result)) {
            setIsLoading?.(true);
            await result;
          }
        }
      } catch (error) {
        console.error(error);
        setFiles?.(undefined);
        setError?.((error && error['message']) || 'Error');
      } finally {
        if (isMounted()) {
          setIsLoading?.(false);
        }
      }
    }
  }, [
    isMounted,
    validateFile,
    multiple,
    setError,
    setFiles,
    onChange,
    setIsLoading
  ]);
};


let draggingCount = 0;

export function useDragging({
  dropzoneRef,
  handleChanges,
  isLoading
}) {
  const [dragging, setDragging] = useState(false);

  const onDragEnter = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    draggingCount++;
    const { items } = event.dataTransfer;
    if (items && items.length > 0 && !isLoading) {
      setDragging(true);
    }
  }, [isLoading]);

  const onDragLeave = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    draggingCount--;
    if (draggingCount === 0) {
      setDragging(false);
    }
  }, []);

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const handleDrop = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(false);
    draggingCount = 0;

    const files = getFilesFromEvent(event);
    if (files && files.length > 0 && !isLoading) {
      handleChanges(files, event);
    }
  }, [handleChanges, isLoading]);

  useEffect(() => {
    const node = dropzoneRef.current;
    node.addEventListener('dragenter', onDragEnter);
    node.addEventListener('dragleave', onDragLeave);
    node.addEventListener('dragover', onDragOver);
    node.addEventListener('drop', handleDrop);
    return () => {
      node.removeEventListener('dragenter', onDragEnter);
      node.removeEventListener('dragleave', onDragLeave);
      node.removeEventListener('dragover', onDragOver);
      node.removeEventListener('drop', handleDrop);
    };
  }, [
    onDragEnter,
    onDragLeave,
    onDragOver,
    handleDrop
  ]);

  return { dragging };
}
