import PropTypes from 'prop-types';
import React from 'react';

import { BaseButton as Button } from '@acadeum/ui';

import CloseIcon from './CloseIcon.svg';

import './CloseButton.scss';

export default function CloseButton({
  children,
  ...rest
}) {
  return (
    <Button
      {...rest}
      className="CloseButton">
      {children}
      <CloseIcon width="0.5rem" height="0.5rem" aria-hidden="true" className="CloseButton-icon"/>
    </Button>
  );
}

CloseButton.propTypes = {
  children: PropTypes.string.isRequired
};
