import type { UrlObject } from 'url';

import type { FC, ReactNode } from 'react';
import { createContext, useContext } from 'react';

interface Navigate {
  // Called 'goto' in courseshare app
  push: (url: UrlObject | string) => Promise<void> | void;
  // Called 'redirect' in courseshare app
  replace: (url: UrlObject | string) => Promise<void> | void;
  // Called 'goBack' in courseshare app
  back: () => Promise<void> | void;
}

const NavigateContext = createContext<Navigate | undefined>(undefined);

export const useNavigate = (): Navigate => {
  const navigate = useContext(NavigateContext);
  if (!navigate) {
    throw new Error('useNavigate must be used within a NavigateProvider');
  }
  return navigate;
};

interface NavigateProviderProps {
  children: ReactNode;
  /**
   * Should be memoized to prevent unnecessary re-renders.
   * */
  navigate: Navigate;
}

export const NavigateProvider: FC<NavigateProviderProps> = ({
  children,
  navigate
}) => {
  return (
    <NavigateContext.Provider value={navigate}>
      {children}
    </NavigateContext.Provider>
  );
};
