import { createColumnHelper } from '@tanstack/react-table';
import type { RowSelectionState } from '@tanstack/react-table';
import React, { useMemo, useState } from 'react';

import type { FetchTeachingCourseEnrollmentRequestsResponseItem } from '@acadeum/api';
import { useTranslate } from '@acadeum/translate';
import type { Id } from '@acadeum/types';
import { Button, DataBlock, Table } from '@acadeum/ui';

import { CourseEnrollmentRequestStudentDetailsModal } from '../CourseEnrollmentRequestStudentDetailsModal';

const columnHelper = createColumnHelper<FetchTeachingCourseEnrollmentRequestsResponseItem['enrollments'][number]>();

interface StudentsTableProps {
  enableRowSelection?: boolean;
  selectedEnrollmentIds?: Id[];
  setSelectedEnrollmentIds?: (ids: Id[]) => void;
  enrollmentRequest: FetchTeachingCourseEnrollmentRequestsResponseItem;
  enrollments: FetchTeachingCourseEnrollmentRequestsResponseItem['enrollments'];
}

export const CourseEnrollmentRequestsStudentTable = ({
  setSelectedEnrollmentIds,
  selectedEnrollmentIds,
  enableRowSelection,
  enrollmentRequest,
  enrollments
}: StudentsTableProps) => {
  const t = useTranslate('EnrollmentRequests');

  const columns = useMemo(() => [
    columnHelper.display({
      id: 'student.name',
      header: t('name'),
      cell: ({ row }) => (
        <StudentDetailsCell
          enrollment={row.original}
          enrollmentRequest={enrollmentRequest}
        />
      )
    }),
    columnHelper.accessor('student.id', {
      header: t('id')
    }),
    columnHelper.accessor('student.email', {
      header: t('email', { global: true })
    })
  ], [enrollmentRequest]);

  const [rowSelection, setRowSelection] = useState<RowSelectionState>(() => {
    if (!selectedEnrollmentIds) {
      return {};
    }
    return selectedEnrollmentIds.reduce<RowSelectionState>((acc, obj) => {
      acc[obj] = true;
      return acc;
    }, {});
  });

  const onRowSelectionChange = (updaterOrValue) => {
    const value = typeof updaterOrValue === 'function' ? updaterOrValue(rowSelection) : updaterOrValue;
    setRowSelection(value);
    if (setSelectedEnrollmentIds) {
      setSelectedEnrollmentIds(Object.keys(value).map(_ => Number(_)));
    }
  };

  return (
    <Table
      columns={columns}
      data={enrollments}
      rowSelectionOptions={{
        enableRowSelection: enableRowSelection,
        rowSelection,
        onRowSelectionChange
      }}
    />
  );
};

interface StudentDetailsCellProps {
  enrollmentRequest: FetchTeachingCourseEnrollmentRequestsResponseItem;
  enrollment: FetchTeachingCourseEnrollmentRequestsResponseItem['enrollments'][number];
}

const StudentDetailsCell = ({
  enrollment,
  enrollmentRequest
}: StudentDetailsCellProps) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <Button
        variant="text"
        onClick={() => setShow(true)}
      >
        <DataBlock
          type="student"
          showId={false}
          withLink={false}
          student={enrollment.student}
        />
      </Button>
      <CourseEnrollmentRequestStudentDetailsModal
        show={show}
        onHide={setShow}
        enrollment={enrollment}
        enrollmentRequest={enrollmentRequest}
      />
    </>
  );
};
