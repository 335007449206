import actions from '../actions';

import onUserAuthenticatedOrNotAuthenticated from './onUserAuthenticatedOrNotAuthenticated.js';


const {
  resetUserDataLoaded
} = actions;

export default function onNotLoggedIn() {
  onUserAuthenticatedOrNotAuthenticated();
  resetUserDataLoaded();
}
