// This is the original Algolia's `react-instantsearch` `InstantSearch.js`.
// https://github.com/algolia/react-instantsearch/blob/81a6f1856ae28b4ab00ba660c548037818749220/packages/react-instantsearch-dom/src/widgets/InstantSearch.js
// of version 5.5.

import algoliasearch from 'algoliasearch/lite';

import createInstantSearch from '../../react-instantsearch-core/core/createInstantSearch';

const InstantSearch = createInstantSearch(algoliasearch, {
  Root: 'div',
  props: {
    className: 'ais-InstantSearch__root'
  }
});

export default InstantSearch;
