import PropTypes from 'prop-types';
import React from 'react';

import { useTranslate } from '@acadeum/translate';
import { Button, Text, Title } from '@acadeum/ui';

import { formatName } from '../../helpers/format';

import Email from '../Email';
import Phone from '../Phone';

import styles from './ContactInfo.module.scss';
import EmailIcon from './Email.svg';
import PhoneIcon from './Phone.svg';
import PortfolioIcon from './Portfolio.svg';
import ProfileIcon from './Profile.svg';


export default function ContactInfo({
  title,
  contact,
  user,
  onRequestToJoin
}) {
  const t = useTranslate('ContactInfo');

  return (
    <section>
      <Title level={3} className={styles.block__title}>
        {title}
      </Title>
      {contact.firstName && (
        <Text color="graphiteDark" className={styles.block__text}>
          <ProfileIcon className={styles.block__icon}/>
          {formatName(contact)}
        </Text>
      )}
      {contact.title && (
        <Text color="graphiteDark" className={styles.block__text}>
          <PortfolioIcon className={styles.block__icon}/>
          {contact.title}
        </Text>
      )}
      {contact.email && (
        <Text color="graphiteDark" className={styles.block__text}>
          <EmailIcon className={styles.block__icon}/>
          <Email address={contact.email}/>
        </Text>
      )}
      {contact.phone && (
        <Text color="graphiteDark" className={styles.block__text}>
          <PhoneIcon className={styles.block__icon}/>
          <Phone contact={contact}/>
        </Text>
      )}
      {user && onRequestToJoin && (
        <Button
          onClick={onRequestToJoin}
          className={styles.block__button}
        >
          {t('requestToJoin')}
        </Button>
      )}
    </section>
  );
}

ContactInfo.propTypes = {
  title: PropTypes.string.isRequired,
  contact: PropTypes.shape({
    title: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string
  }),
  onRequestToJoin: PropTypes.func,
  user: PropTypes.object
};
