import { useStripe } from '@stripe/react-stripe-js';
import type { Stripe } from '@stripe/stripe-js';
import type { FC } from 'react';
import React from 'react';

import type { UseCreatePrimaryPaymentSourceMutation, UseUpdatePrimaryPaymentSourceMutation } from '@acadeum/api';
import { useTranslate } from '@acadeum/translate';
import { FormModal, Text, toast } from '@acadeum/ui';

import { BankAccountFormFields } from '../BankAccountFormFields';

import styles from './AddBankAccountModal.module.scss';

type BankAccountTypeTypes = 'current' | 'new';

export interface AddBankAccountModalProps {
  type: BankAccountTypeTypes;
  show: boolean;
  onHide: () => void;
  onAfterSubmit: () => void;
  useCreatePrimaryPaymentSourceMutation: UseCreatePrimaryPaymentSourceMutation;
  useUpdatePrimaryPaymentSourceMutation: UseUpdatePrimaryPaymentSourceMutation;
}

export const AddBankAccountModal: FC<AddBankAccountModalProps> = ({
  type,
  show,
  onHide,
  onAfterSubmit,
  useUpdatePrimaryPaymentSourceMutation,
  useCreatePrimaryPaymentSourceMutation
}) => {
  const [createPrimaryPaymentSourceMutation] = useCreatePrimaryPaymentSourceMutation();
  const [updatePrimaryPaymentSourceMutation] = useUpdatePrimaryPaymentSourceMutation();

  const stripe = useStripe() as Stripe;
  const t = useTranslate('shared-admin-ui.AddBankAccountModal');

  const onSubmit = async (values) => {
    const { holderName, holderType, accountNumber, routingNumber } = values;
    const result = await stripe.createToken('bank_account', {
      country: 'US',
      currency: 'usd',
      routing_number: routingNumber,
      account_number: accountNumber,
      account_holder_name: holderName,
      account_holder_type: holderType
    });

    const { token, error } = result;

    if (error) {
      return toast.error(error.message);
    }

    const action = type === 'current' ? createPrimaryPaymentSourceMutation : updatePrimaryPaymentSourceMutation;
    await action({ sourceId: token.id }).unwrap();

    await onAfterSubmit?.();
  };

  return (
    <FormModal
      title={t('title')}
      show={show}
      submitText={t('save', { global: true })}
      onHide={onHide}
      onCancel={onHide}
      onSubmit={onSubmit}
    >
      <Text className={styles.AddBankAccountModal__description}>
        {t('description')}
      </Text>

      <br/>

      <BankAccountFormFields/>
    </FormModal>
  );
};
