
import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { createHeadlessEditor } from '@lexical/headless';
import { $generateHtmlFromNodes } from '@lexical/html';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { LineBreakNode } from 'lexical';
import React, { memo, useEffect, useRef, useState } from 'react';

export const LexicalNodes = [
  HeadingNode,
  ListNode,
  ListItemNode,
  QuoteNode,
  CodeNode,
  CodeHighlightNode,
  TableNode,
  TableCellNode,
  TableRowNode,
  AutoLinkNode,
  LinkNode,
  LineBreakNode
];

export interface LexicalHtmlProps {
  className?: string;
  lexicalState?: string;
}

export const LexicalHtml = memo<LexicalHtmlProps>(({
  lexicalState,
  className
}) => {
  const [content, setContent] = useState<string | null>(null);

  const editorRef = useRef(createHeadlessEditor({
    nodes: LexicalNodes,
    onError(error) {
      console.error('[LexicalHtml] Error:', error);
    }
  }));

  useEffect(() => {
    const editor = editorRef.current;
    if (lexicalState) {
      const state = editor.parseEditorState(lexicalState);
      if (state) {
        editor.setEditorState(state);
      }
    }
    editor.update(() => {
      const html = $generateHtmlFromNodes(editor);
      if (html) {
        setContent(html);
      }
    });
  }, [lexicalState]);

  if (content) {
    return (
      <div className={className} dangerouslySetInnerHTML={{ __html: content }}/>
    );
  }
  return null;
});

// function printPrettyHTML(str: string) {
//   const div = document.createElement('div');
//   div.innerHTML = str.trim();
//   return prettifyHTML(div, 0).innerHTML;
// }
//
// function prettifyHTML(node: Element, level: number) {
//   const indentBefore = new Array(level++ + 1).join('  ');
//   const indentAfter = new Array(level - 1).join('  ');
//   let textNode;
//
//   for (let i = 0; i < node.children.length; i++) {
//     textNode = document.createTextNode('\n' + indentBefore);
//     node.insertBefore(textNode, node.children[i]);
//     prettifyHTML(node.children[i], level);
//     if (node.lastElementChild === node.children[i]) {
//       textNode = document.createTextNode('\n' + indentAfter);
//       node.appendChild(textNode);
//     }
//   }
//
//   return node;
// }
