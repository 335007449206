import { useMemo } from 'react';

import getLastDropDateForCourseEnrollment from 'common-lib/lib/getLastDropDateForCourseEnrollment';

import type { FetchCourseEnrollmentsOutputItem } from '@acadeum/api';
import { getEnrollmentTypeLabel } from '@acadeum/helpers';
import { useTranslate } from '@acadeum/translate';
import type { TableExportDataColumns } from '@acadeum/ui';

import { formatCountry, formatReason } from '../../../helpers/format';

type ExportColumns = TableExportDataColumns<'xlsx', FetchCourseEnrollmentsOutputItem>;

interface UseExportCourseEnrollmentsColumnsProps {
  teaching: boolean;
  finalized: boolean;
  grades: boolean;
}

export function useExportCourseEnrollmentsColumns({
  finalized,
  teaching,
  grades
}: UseExportCourseEnrollmentsColumnsProps): ExportColumns {
  const t = useTranslate();

  return useMemo(() => {
    const otherInstitutionColumns: ExportColumns = teaching ? [
      {
        id: 'Home Institution',
        value: row => row.homeInstitution?.name
      }
    ] : [
      {
        id: 'Teaching Institution',
        value: row => row.section.course.institution.name
      }
    ];

    const homeInstitutionCourseColumns: ExportColumns = teaching ? [] : [
      {
        id: 'HI Course ID',
        value: row => row.courseSubstitute?.equivalentCourse?.code
      },
      {
        id: 'HI Course Title',
        value: row => row.courseSubstitute?.equivalentCourse?.title
      }
    ];

    const refundColumns: ExportColumns = teaching || finalized ? [{
      id: 'Refunded',
      value: row => row.refunded
    }] : [];

    const gradesColumns: ExportColumns = (teaching ? false : finalized) || grades ? [
      {
        id: 'Letter Grade',
        value: row => row.letterGrade
      },
      {
        id: 'Numerical Grade',
        value: row => row.numericalGrade
      },
      {
        id: 'Grade Notes',
        value: row => row.gradeNotes
      }
    ] : [];

    const withdrawColumns: ExportColumns = finalized ? [
      {
        id: 'Student Withdraw Reason',
        value: row => formatReason(row.studentWithdrawReason, row.studentWithdrawReasonNotes, 'EnrollmentsTable.studentWithdrawReason')
      },
      {
        id: 'Home Withdraw Reason',
        value: row => formatReason(row.homeWithdrawReason, row.homeWithdrawReasonNotes, 'EnrollmentsTable.withdrawReason')
      },
      {
        id: 'Teaching Withdraw Reason',
        value: row => formatReason(row.teachingWithdrawReason, row.teachingWithdrawReasonNotes, 'EnrollmentsTable.withdrawReason')
      }
    ] : [];

    const dropColumns: ExportColumns = finalized ? [
      {
        id: 'Student Drop Reason',
        value: row => formatReason(row.studentDropReason, row.studentDropReasonNotes, 'EnrollmentsTable.studentDropReason')
      },
      {
        id: 'Home Drop Reason',
        value: row => formatReason(row.homeDropReason, row.homeDropReasonNotes, 'EnrollmentsTable.dropReason')
      },
      {
        id: 'Teaching Drop Reason',
        value: row => formatReason(row.teachingDropReason, row.teachingDropReasonNotes, 'EnrollmentsTable.dropReason')
      }
    ] : [];

    const denyColumns: ExportColumns = finalized
      ? [{
        id: 'Deny Reason',
        value: row => formatReason(row.denyReason, row.denyReasonNotes, 'EnrollmentRequest.denyReason')
      }]
      : [];

    const removeColumns: ExportColumns = finalized
      ? [{
        id: 'Remove Reason',
        value: row => formatReason(row.removeReason, row.removeReasonNotes, 'EnrollmentsTable.removeReason')
      }]
      : [];

    const columns: ExportColumns = [
      {
        id: 'Status',
        value: row => row.status
      },
      ...otherInstitutionColumns,
      {
        id: 'Enrollment Type',
        value: row => getEnrollmentTypeLabel(row)
      },
      {
        id: 'TI Course ID',
        value: row => row.section.course.code
      },
      {
        id: 'TI Course Title',
        value: row => row.section.course.title
      },
      {
        id: 'TI Course Section ID',
        value: row => row.section.id
      },
      {
        id: 'Course Cost',
        value: row => row.costForInstitution
      }, ...homeInstitutionCourseColumns, {
        id: 'Credits',
        value: row => row.section.course.hours
      },
      {
        id: 'Term',
        value: row => row.section.course.onDemand ? 'Open Learning' : row.section.term
      },
      {
        id: 'Internal Term',
        value: row => row.section.internalTerm
      },
      {
        id: 'Session',
        value: row => row.section.course.onDemand ? null : row.section.session
      },
      {
        id: 'Internal Session',
        value: row => row.section.internalSession
      },
      {
        id: 'Section',
        value: row => row.section.code
      },
      {
        id: 'Session Start Date',
        value: row => row.section.course.onDemand ? null : row.section.startDate
      },
      {
        id: 'Session Add Date',
        value: row => row.section.course.onDemand ? null : row.section.lastAddDate
      },
      {
        id: 'Session Drop Date',
        value: row => {
          if (row.section.course.onDemand && !row.startedAt) {
            return null;
          }
          return getLastDropDateForCourseEnrollment({
            startedAt: row.startedAt,
            onDemand: row.section.course.onDemand,
            lastDropDate: row.section.lastDropDate,
            onDemandDropDateDaysAfterStartDate: row.section.course.institution.onDemandCourseDropDateDaysAfterStartDate
          }).lastDropDate;
        }
      },
      {
        id: 'Session End Date',
        value: row => row.section.course.onDemand ? null : row.section.endDate
      },
      {
        id: 'HI Student ID',
        value: row => row.student?.hiStudentId
      },
      {
        id: 'Acadeum Student ID',
        value: row => row.student?.id
      },
      {
        id: 'First Name',
        value: row => row.student?.firstName
      },
      {
        id: 'Middle Name',
        value: row => row.student?.middleName
      },
      {
        id: 'Last Name',
        value: row => row.student?.lastName
      },
      {
        id: 'Phone',
        value: row => row.student?.phone
      },
      {
        id: 'Email',
        value: row => row.student?.email
      },
      {
        id: 'Address Line 1',
        value: row => row.student?.addressLine1
      },
      {
        id: 'Address Line 2',
        value: row => row.student?.addressLine2
      },
      {
        id: 'City',
        value: row => row.student?.city
      },
      {
        id: 'State',
        value: row => row.student?.state
      },
      {
        id: 'Postal Code',
        value: row => row.student?.postalCode
      },
      {
        id: 'Country',
        value: row => formatCountry(row.student?.country)
      },
      {
        id: 'Major',
        value: row => row.student?.major
      },
      {
        id: 'Level',
        value: row => row.student?.level
      },
      {
        id: 'Gender',
        value: row => row.student?.gender
      },
      {
        id: 'Date of Birth',
        value: row => row.student?.dob
      },
      {
        id: 'Start Date',
        value: row => row.student?.startDate
      },
      {
        id: 'US Citizenship',
        value: row => row.student?.citizenship
      },
      {
        id: 'State Residency',
        value: row => row.student?.residency
      },
      {
        id: 'Ethnicity',
        value: row => row.student?.ethnicity
      },
      {
        id: 'Race',
        value: row => row.student?.races
      },
      {
        id: 'Advisor Name',
        value: row => row.student?.advisorName
      },
      {
        id: 'Advisor Email',
        value: row => row.student?.advisorEmail
      },
      {
        id: 'Enroll Reason',
        value: row => formatReason(row.enrollReason, row.enrollReasonNotes, 'CourseEnrollmentsTable.enrollReason')
      },
      ...withdrawColumns,
      ...dropColumns,
      ...denyColumns,
      ...removeColumns,
      {
        id: 'Paid',
        value: row => row.paid
      },
      ...refundColumns,
      ...gradesColumns
    ];

    return columns;
  }, [t]);
}
