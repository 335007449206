import common from '../../../common.en.json';

import feeType from '../../../options/feeType.en.json';
import stripe from '../../../options/stripe.en.json';
import courseEnrollmentStatusesOptions from '../../../options/courseEnrollmentStatusesOptions.en.json';
import courseEnrollmentTypes from '../../../options/courseEnrollmentTypes.en.json';

const localization = {
  common,
  feeType,
  stripe,
  courseEnrollmentStatusesOptions,
  courseEnrollmentTypes
};

const locale = {
  'selection-options': localization
};

export default locale;
