import PropTypes from 'prop-types';
import React from 'react';

import { useTranslate } from '@acadeum/translate';
import {
  Col,
  ContentSection,
  DataBlock,
  Row,
  Separator,
  Text
} from '@acadeum/ui';

import session from '../../../../components/PropTypes';


import './SectionCard.sass';

const SectionCard = ({ section }) => {
  const t = useTranslate('CreateAdvisorRecommendation');

  return (
    <ContentSection className="SectionCard" padding="md">
      <Row>
        <Col className="SectionCard__sectionTitle" md={12}>
          {t('sectionTitle', {
            name: section.session.name
          })}
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <Text>
            {t('term')}
          </Text>
          <Text className="SectionCard__columnValue">
            {section.session.term}
          </Text>
        </Col>
        <Col md={3}>
          <Text>
            {t('session')}
          </Text>
          <Text className="SectionCard__columnValue">
            {section.session.name}
          </Text>
        </Col>
        <Col md={6}>
          <Text>
            {t('startAndEndDates')}
          </Text>
          <div className="SectionCard__columnValue">
            <DataBlock
              utc
              type="date"
              month="short"
              date={section.session.startDate}
            />
            {' - '}
            <DataBlock
              utc
              type="date"
              month="short"
              date={section.session.endDate}
            />
          </div>
        </Col>
      </Row>
      <Separator mt="md" mb="md" />
      <Row>
        <Col md={3}>
          <Text>
            {t('addDate')}
          </Text>
          <div className="SectionCard__columnValue">
            <DataBlock
              utc
              type="date"
              month="short"
              date={section.session.lastAddDate}
            />
          </div>
        </Col>
        <Col md={9}>
          <Text>
            {t('dropDate')}
          </Text>
          <div className="SectionCard__columnValue">
            <DataBlock
              utc
              type="date"
              month="short"
              date={section.session.lastDropDate}
            />
          </div>
        </Col>
      </Row>
    </ContentSection>
  );
};

SectionCard.propTypes = {
  section: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    number: PropTypes.string,
    schedule: PropTypes.string,
    session: session.isRequired
  }).isRequired
};

export default SectionCard;
