import { zodResolver } from '@hookform/resolvers/zod';
import type { FC } from 'react';
import React from 'react';

import type { UseBulkCreateOrUpdateStudentsJob } from '@acadeum/api';
import { toast } from '@acadeum/ui';

import type { DataUploadPage2Props } from '../DataUploadPage2';
import { DataUploadPage2 } from '../DataUploadPage2';

import { STUDENT_IMPORT_SCHEMA, zodStudentImportSchema } from './studentImportSchema';

export interface StudentUploadProps {
  className?: string;
  useBulkCreateOrUpdateStudentsJob: UseBulkCreateOrUpdateStudentsJob;
  onAfterUpload?: () => void;
}

const resolver = zodResolver(zodStudentImportSchema);

export const StudentUpload: FC<StudentUploadProps> = ({
  className,
  onAfterUpload,
  useBulkCreateOrUpdateStudentsJob
}) => {
  const bulkCreateOrUpdateStudents = useBulkCreateOrUpdateStudentsJob();

  const onImport: DataUploadPage2Props['onUpload'] = async ({ rows, institutionId }) => {
    await bulkCreateOrUpdateStudents({
      institutionId,
      rows
    });
    toast.success(`Success! You had ${rows.length} students updated or created`);
    onAfterUpload?.();
  };

  const validate: DataUploadPage2Props['validate'] = ({ institutionId, rows }) => {
    return bulkCreateOrUpdateStudents({
      institutionId,
      dryRun: true,
      rows
    });
  };

  return (
    <div className={className}>
      <DataUploadPage2
        resolver={resolver}
        templateUrl="https://dliov3t26vp8o.cloudfront.net/Data Upload Templates/Students.xlsx"
        schema={STUDENT_IMPORT_SCHEMA}
        onUpload={onImport}
        validate={validate}
      />
    </div>
  );
};
