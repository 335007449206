import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { XMarkIcon } from '@acadeum/icons';
import { useTranslate } from '@acadeum/translate';
import type { IconSource } from '@acadeum/types';

import type { MarginsProp } from '../../utils/useMargin';
import { useMargin } from '../../utils/useMargin';
import { BaseButton } from '../BaseButton';


import styles from './CloseButton.module.scss';

export interface CloseButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, Pick<MarginsProp, 'ml' | 'mr' | 'mb' | 'mt'> {
  size?: 'sm' | 'md';
  icon?: IconSource | false;
}

export const CloseButton = React.forwardRef<HTMLButtonElement, CloseButtonProps>(({
  children,
  className,
  size = 'md',
  icon: Icon = XMarkIcon,
  'aria-label': ariaLabel,
  ml,
  mr,
  mb,
  mt,
  ...rest
}, ref) => {
  const t = useTranslate('ui.CloseButton');

  const { marginClassNames } = useMargin({ ml, mr, mb, mt });

  ariaLabel = ariaLabel || t('defaultProps.aria-label');
  size = getSizeFromDeprecated(size);

  return (
    <BaseButton
      {...rest}
      ref={ref}
      aria-label={ariaLabel}
      className={classNames(className, marginClassNames, styles.CloseButton, styles[size])}
    >
      {typeof Icon === 'function' && (
        <Icon aria-hidden="true" className={styles.icon}/>
      )}
      {children && (
        <span className={styles.content}>
          {children}
        </span>
      )}
    </BaseButton>
  );
});

CloseButton.displayName = 'CloseButton';
CloseButton.propTypes = {
  size: PropTypes.oneOf(['sm', 'md']),
  icon: PropTypes.any
};

function getSizeFromDeprecated(size) {
  switch (size) {
    case 'small':
      return 'sm';
    case 'default':
      return 'md';
    default:
      return size;
  }
}
