import type { RowData } from '@tanstack/react-table';
import { uniq } from 'lodash-es';

import { Select } from '../../../Select';

import type { FilterItemObject } from '../types';

export const createTableDefaultFilter = <TData extends RowData, Value = string | number | boolean | undefined>({
  id,
  label,
  getFilterValue,
  getFilterValueLabel
}: {
  getFilterValue: (row: TData) => Value,
  getFilterValueLabel?: (value: Value) => string,
  label: string;
  id: string
}): FilterItemObject<TData> => {
  const getFilteredValue = (row: TData) => {
    if (!getFilterValue) {
      throw new Error();
    }
    return getFilterValue(row);
  };

  return {
    id: id,
    filter: (row, value) => getFilteredValue(row) === value,
    render({ getValue, rows, setFilterValues }) {
      const options = [
        { label: 'All', value: null },
        ...uniq(rows.map(_ => getFilteredValue(_)))
          .map(value => {
            const label = getFilterValueLabel ? getFilterValueLabel(value) : String(value);
            return { label, value };
          })
          .sort((a, b) => {
            if (typeof a.label === 'string' && typeof b.label === 'string') {
              if (a.label.toLowerCase() < b.label.toLowerCase()) {
                return -1;
              }
              if (a.label.toLowerCase() > b.label.toLowerCase()) {
                return 1;
              }
            }
            return 0;
          })
      ];

      return (
        <Select
          isFilter
          label={label}
          value={getValue() ?? null}
          // @ts-expect-error
          options={options}
          onChange={(value, option) => {
            setFilterValues(prev => {
              return ({
                ...prev,
                [id]: option
              });
            });
          }}
        />
      );
    }
  };
};
