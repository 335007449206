import React, { memo } from 'react';

import { EditCourseForm } from '@acadeum/shared-admin-ui';
import { toast } from '@acadeum/ui';


import actions from '../../../../actions';

const {
  updateCourse
} = actions;

interface CourseDetailsTabProps {
  // eslint-disable-next-line
  course: any;
  refresh: () => Promise<void>;
}

export const CourseDetailsTab = memo<CourseDetailsTabProps>(({
  course,
  refresh
}) => {
  const onSubmit = async (values) => {
    // `course.code` is not editable.
    delete values.code;
    // Remove empty-string categories.
    values.categories = values.categories.filter(Boolean);

    await updateCourse(course.id, values);
    await refresh();
    toast.success('Course was updated successfully!');
  };

  return (
    <EditCourseForm
      course={course}
      onSubmit={onSubmit}
      submitText="Save"
      onDemandCourseDropDateDaysAfterStartDate={course.institution.onDemandCourseDropDateDaysAfterStartDate}
      onDemandCourseEndDateDaysAfterStartDate={course.institution.onDemandCourseEndDateDaysAfterStartDate}
    />
  );
});
