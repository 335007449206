import React from 'react';

import { useTranslate } from '@acadeum/translate';
import { ContentSection, FormField } from '@acadeum/ui';

export interface CertificatesSectionValues {
  certificates?: string;
}

export function CertificatesSection() {
  const t = useTranslate('shared-admin-ui.CourseForm');

  return (
    <ContentSection title={t('certificates.title')}>
      <FormField
        noMargin
        name="certificates"
        label={t('certificates.certificates.label')}
        placeholder={t('certificates.certificates.placeholder')}
      />
    </ContentSection>
  );
}
