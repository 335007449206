import type { FC } from 'react';
import { useMemo, useState } from 'react';

import formatUserName from 'common-lib/lib/formatUserName';
import getLocationUrl from 'common-lib/lib/getLocationUrl';

import {
  __ACCOUNT_CENTER_URL__,
  __ADMIN_CENTER_URL__,
  __COURSESHARE_URL__,
  __MARKETPLACE_ADMIN_URL__
} from '@acadeum/consts';
import { getErrorData, openLinkInNewTab } from '@acadeum/helpers';
import { useTranslate } from '@acadeum/translate';
import type { Options, User } from '@acadeum/types';
import { Actions, Button, ConfirmActionModal, FormField, FormModal, Modal, toast } from '@acadeum/ui';
import type { ActionsProps } from '@acadeum/ui';

import {
  useLogInAsMutation,
  useStudentUserRequestPasswordResetMutation,
  useStudentUserRequestVerifyEmailMutation,
  useStudentUserVerifyEmailMutation,
  useStudentUserResetPasswordMutation,
  useAdminRequestUserPasswordResetMutation,
  useAdminRequestUserEmailVerificationMutation,
  useAdminResetUserPasswordMutation,
  useAdminVerifyUserEmailMutation,
  useActivateStudentUserMutation,
  useDeactivateStudentUserMutation,
  useDeleteStudentUserMutation,
  useFetchContactsQuery,
  useUpdateContactMutation,
  useActivateUserMutation,
  useDeactivateUserMutation,
  useFetchUsersQuery,
  useDeleteUserMutation
} from '@acadeum/api';
import type { LogInAsOutput } from '@acadeum/api';

import { DeactivateUserModal } from '../../pages/users/ui/DeactivateUserModal';

import styles from './AdminUserActions.module.scss';

export interface AdminUserActionsProps {
  user: Pick<User, 'id' | 'email' |  'isActive'> & {
    emailVerified?: User['emailVerified']
  };
  refreshFetchDataStudentsOrUsers: () => Promise<void> | unknown;
  isStudent?: boolean;
  kebab?: boolean;
  seeDetails?: boolean;
}

type AdminWebsites = 'marketplace-admin' | 'admin' | 'account' | 'courseshare';

export const AdminUserActions: FC<AdminUserActionsProps> = ({
  user,
  refreshFetchDataStudentsOrUsers,
  isStudent,
  seeDetails,
  kebab
}) => {
  const t = useTranslate('shared-admin-ui.AdminUserActions');
  const [logInAsMutation] = useLogInAsMutation();

  const [activateStudentUserMutation] = useActivateStudentUserMutation();
  const [deleteStudentUserMutation] = useDeleteStudentUserMutation();

  const [activateUserMutation] = useActivateUserMutation();
  const [deleteUserMutation] = useDeleteUserMutation();

  const [showActivateUserModal, setShowActivateUserModal] = useState(false);
  const [showDeactivateUserModal, setShowDeactivateUserModal] = useState(false);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const [showRequestEmailVerificationModal, setShowRequestEmailVerificationModal] = useState(false);
  const [showVerifyEmailModal, setShowVerifyEmailModal] = useState(false);
  const [showSendPasswordResetEmailModal, setShowSendPasswordResetEmailModal] = useState(false);
  const [showPasswordChangeModal, setShowPasswordChangeModal] = useState(false);
  const [showLogInAsUserModal, setShowLogInAsUserModal] = useState(false);
  const [logInAsUserModalTokenData, setLogInAsUserModalTokenData] = useState<LogInAsOutput | undefined>(undefined);

  const options = useMemo(() => {
    const actions: ActionsProps['actions'] = [];

    if (seeDetails) {
      if (!isStudent) {
        actions.push({
          title: 'Info',
          url: `/admin/users/${user.id}?tab=info`
        });
      }
      actions.push({
        title: t('seeUserLog'),
        url: `/admin/${isStudent ? 'student-' : ''}users/${user.id}${isStudent ? '' : '?tab=log'}`
      });
    }
    if (user.isActive) {
      actions.push({ title: t('deactivate.name'), onClick: () => setShowDeactivateUserModal(true) });
    } else {
      actions.push({ title: t('activate.name'), onClick: () => setShowActivateUserModal(true) });
      actions.push({ title: t('delete.name'), onClick: () => setShowDeleteUserModal(true) });
    }
    if (!user.emailVerified) {
      actions.push({
        title: t('requestEmailVerification.name'),
        onClick: () => setShowRequestEmailVerificationModal(true)
      });
      actions.push({ title: t('verifyEmail.name'), onClick: () => setShowVerifyEmailModal(true) });
    }
    if (!isStudent && user.isActive && user.emailVerified) {
      actions.push({
        title: t('logInAsUser.name'),
        onClick: () => {
          setLogInAsUserModalTokenData(undefined);
          setShowLogInAsUserModal(true);
        }
      });
    }
    actions.push({ title: t('sendPasswordResetEmail.name'), onClick: () => setShowSendPasswordResetEmailModal(true) });
    actions.push({ title: t('passwordChange.name'), onClick: () => setShowPasswordChangeModal(true) });

    return actions;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, seeDetails, isStudent]);

  const onActivateUser = async () => {
    await (isStudent ? activateStudentUserMutation : activateUserMutation)({ id: user.id }).unwrap();
    await refreshFetchDataStudentsOrUsers();
    setShowActivateUserModal(false);
    toast.success(t('activate.successMessage'));
  };

  const getProductUrl = (product: AdminWebsites) => {
    switch (product) {
      case 'courseshare':
        return __COURSESHARE_URL__;
      case 'admin':
        return __ADMIN_CENTER_URL__;
      case 'account':
        return __ACCOUNT_CENTER_URL__;
      case 'marketplace-admin':
        return __MARKETPLACE_ADMIN_URL__;
      default:
        throw new Error(`Unknown Acadeum product: ${product}`);
    }
  };

  const onLogInAsUser = async () => {
    setLogInAsUserModalTokenData(await logInAsMutation({ userId: user.id }).unwrap());
  };

  const getLogInAsUrlForProduct = (product: AdminWebsites) => {
    if (!logInAsUserModalTokenData) {
      console.error('No `logInAsUserModalTokenData` in `getLogInAsUrlForProduct()` function');
      return '#';
    }

    const {
      token,
      expiresIn,
      expiresAt
    } = logInAsUserModalTokenData;

    // setShowLogInAsUserModal(false);

    // toast.success(t('logInAsUser.successMessage'));

    // // Redirect to the home page after reloading the page.
    // const redirectTo = window.location.origin;
    // // Redirect to `redirectTo`.
    // // Same as `window.location = redirectTo`, but works with TypeScript.
    // // https://stackoverflow.com/questions/13106950/set-window-location-with-typescript
    // window.location.assign(redirectTo);

    return getLocationUrl({
      origin: getProductUrl(product),
      pathname: '/',
      query: {
        accessToken: token,
        accessTokenExpiresIn: expiresIn,
        accessTokenExpiresAt: expiresAt,
        userSessionEphemeral: '✓'
      }
    });
  };

  const onLogInAsUserForProduct = (product: AdminWebsites) => {
    openLinkInNewTab(getLogInAsUrlForProduct(product));
  };

  const onDeleteUser = async () => {
    try {
      await (isStudent ? deleteStudentUserMutation : deleteUserMutation)({ id: user.id }).unwrap();
      await refreshFetchDataStudentsOrUsers();
      setShowDeleteUserModal(false);
      toast.success(t('delete.successMessage'));
    } catch (err) {
      const error = getErrorData(err);
      console.error(error);
      toast.error(error.message);
    }
  };

  const [studentUserRequestVerifyEmail] = useStudentUserRequestVerifyEmailMutation();
  const [userRequestVerifyEmailAdmin] = useAdminRequestUserEmailVerificationMutation();

  const onRequestEmailVerification = async () => {
    await (isStudent ? studentUserRequestVerifyEmail : userRequestVerifyEmailAdmin)({ id: user.id }).unwrap();
    setShowRequestEmailVerificationModal(false);
    toast.success(t('requestEmailVerification.successMessage', {
      email: user.email
    }));
  };

  const [studentUserVerifyEmail] = useStudentUserVerifyEmailMutation();
  const [userVerifyEmailAdmin] = useAdminVerifyUserEmailMutation();

  const onVerifyEmail = async () => {
    await (isStudent ? studentUserVerifyEmail : userVerifyEmailAdmin)({ id: user.id }).unwrap();
    await refreshFetchDataStudentsOrUsers();
    setShowVerifyEmailModal(false);
    toast.success(t('verifyEmail.successMessage'));
  };

  const [studentUserRequestPasswordReset] = useStudentUserRequestPasswordResetMutation();
  const [userRequestPasswordResetAdmin] = useAdminRequestUserPasswordResetMutation();

  const onSubmitSendPasswordResetEmail = async () => {
    await (isStudent ? studentUserRequestPasswordReset : userRequestPasswordResetAdmin)({ id: user.id }).unwrap();
    setShowSendPasswordResetEmailModal(false);
    toast.success(t('sendPasswordResetEmail.successMessage'));
  };

  const [studentUserResetPassword] = useStudentUserResetPasswordMutation();
  const [userResetPasswordAdmin] = useAdminResetUserPasswordMutation();

  const onSubmitPasswordChange = async (values) => {
    const { password } = values;
    await (isStudent ? studentUserResetPassword : userResetPasswordAdmin)({ id: user.id, password: password }).unwrap();
    setShowPasswordChangeModal(false);
    toast.success(t('passwordChange.successMessage'));
  };

  const LOG_IN_AS_PRODUCT_OPTIONS = useMemo<Options<AdminWebsites>>(() => [
    {
      value: 'courseshare',
      label: t('logInAsUser.product.courseshare')
    },
    {
      value: 'admin',
      label: t('logInAsUser.product.admin')
    },
    {
      value: 'account',
      label: t('logInAsUser.product.account')
    },
    {
      value: 'marketplace-admin',
      label: t('logInAsUser.product.marketplace-admin')
    }
  ], [t]);

  const [deactivateStudentUserMutation] = useDeactivateStudentUserMutation();
  const onDeactivateUser = async () => {
    await deactivateStudentUserMutation({ id: user.id }).unwrap();
    await refreshFetchDataStudentsOrUsers();
    setShowDeactivateUserModal(false);
    toast.success(t('deactivate.successMessage'));
  };

  return (
    <>
      <Actions
        actions={options}
        variant={kebab ? 'kebab' : 'button'}
      />

      <ConfirmActionModal
        title={t('activate.title')}
        description={t('activate.description', { userName: <strong>{formatUserName(user)}</strong> })}
        submitText={t('activate.submitText')}
        show={showActivateUserModal}
        onSubmit={onActivateUser}
        onHide={() => setShowActivateUserModal(false)}
      />

      {isStudent ? (
        <ConfirmActionModal
          danger
          title={t('deactivate.title')}
          description={t('deactivate.description', {
            userName: <strong>{formatUserName(user)}</strong>,
            danger: (content) => <span className="block danger">{content}</span>
          })}
          submitText={t('deactivate.submitText')}
          show={showDeactivateUserModal}
          onSubmit={onDeactivateUser}
          onHide={() => setShowDeactivateUserModal(false)}
          onCancel={() => setShowDeactivateUserModal(false)}
        />
      ) : (
        <DeactivateUserModal
          show={showDeactivateUserModal}
          onHide={setShowDeactivateUserModal}
          user={user}
          useUpdateContactMutation={useUpdateContactMutation}
          useDeactivateUserMutation={useDeactivateUserMutation}
          useFetchUsersQuery={useFetchUsersQuery}
          useFetchContactsQuery={useFetchContactsQuery}
          onSuccessfulSubmit={async () => {
            await refreshFetchDataStudentsOrUsers();
          }}
        />
      )}

      <ConfirmActionModal
        danger
        title={t('delete.title')}
        description={t('delete.description', {
          userName: <strong>{formatUserName(user)}</strong>,
          danger: (content) => <span className="danger">{content}</span>
        })}
        submitText={t('delete.submitText')}
        show={showDeleteUserModal}
        onSubmit={onDeleteUser}
        onHide={() => setShowDeleteUserModal(false)}
        onCancel={() => setShowDeleteUserModal(false)}
      />

      <Modal
        title={t('logInAsUser.title')}
        onHide={() => setShowLogInAsUserModal(false)}
        show={showLogInAsUserModal}
        onSubmit={logInAsUserModalTokenData ? undefined : onLogInAsUser}
        size="narrow"
      >
        <Modal.Body>
          <div className={styles.ActionModal__content}>
            {logInAsUserModalTokenData
              ? (
                <>
                  {t('logInAsUser.chooseProduct', {
                    userName: <strong>{formatUserName(user)}</strong>
                  })}
                  <br />
                  <br />
                  {LOG_IN_AS_PRODUCT_OPTIONS.map((product, i) => (
                    <div key={product.value}>
                      <Button onClick={() => onLogInAsUserForProduct(product.value)}>
                        {product.label}
                      </Button>
                      {i < LOG_IN_AS_PRODUCT_OPTIONS.length - 1 && (
                        <>
                          <br />
                          <br />
                        </>
                      )}
                    </div>
                  ))}
                </>
              )
              : (
                <>
                  {t('logInAsUser.description', {
                    userName: <strong>{formatUserName(user)}</strong>
                  })}
                </>
              )
            }
          </div>
        </Modal.Body>
        <Modal.Footer paddingTop="large">
          <Button
            onClick={() => setShowLogInAsUserModal(false)}
            variant="secondary"
          >
            {t('cancel', { global: true })}
          </Button>
          {!logInAsUserModalTokenData && (
            <Button
              onClick={onLogInAsUser}
              variant="primary"
            >
              {t('logInAsUser.submitText')}
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      <ConfirmActionModal
        title={t('requestEmailVerification.title')}
        description={t('requestEmailVerification.description', {
          userName: <strong>{formatUserName(user)}</strong>,
          email: <strong>{user.email}</strong>
        })}
        show={showRequestEmailVerificationModal}
        onSubmit={onRequestEmailVerification}
        onHide={() => setShowRequestEmailVerificationModal(false)}
      />

      <ConfirmActionModal
        title={t('verifyEmail.title')}
        description={t('verifyEmail.description', {
          email: <strong>{user.email}</strong>
        })}
        show={showVerifyEmailModal}
        onSubmit={onVerifyEmail}
        onHide={() => setShowVerifyEmailModal(false)}
      />

      <ConfirmActionModal
        title={t('sendPasswordResetEmail.title')}
        description={t('sendPasswordResetEmail.description', {
          userName: <strong>{formatUserName(user)}</strong>,
          email: <strong>{user.email}</strong>
        })}
        submitText={t('sendPasswordResetEmail.submitText')}
        show={showSendPasswordResetEmailModal}
        onSubmit={onSubmitSendPasswordResetEmail}
        onHide={() => setShowSendPasswordResetEmailModal(false)}
      />

      <FormModal
        title={t('passwordChange.title')}
        show={showPasswordChangeModal}
        onHide={() => setShowPasswordChangeModal(false)}
        onSubmit={onSubmitPasswordChange}
      >
        <FormField
          required
          label={t('passwordChange.passwordLabel')}
          type="password"
          name="password"
        />
        <FormField
          required
          label={t('passwordChange.confirmPasswordLabel')}
          type="confirmPassword"
          name="confirmPassword"
        />
      </FormModal>
    </>
  );
};
