import type { RowData } from '@tanstack/react-table';
import { uniq } from 'lodash-es';

import parseTerm from 'common-lib/lib/parseTerm';

import type { Session } from '@acadeum/types';

import { Select } from '../../../Select';

import type { FilterItemObject } from '../types';

export const createTableSessionFilter = <TData extends RowData>({
  getSessions,
  getSession = row => (row['section']['session'] as Session)
}: {
  getSession?: (row: TData) => Pick<Session, 'name' | 'term'>
  getSessions?: (row: TData) => Pick<Session, 'name' | 'term'>[]
} = {}): FilterItemObject<TData> => {
  return {
    id: 'session',
    filter(row, value) {
      if (getSessions) {
        return getSessions(row).some(session => session.name === value);
      } else {
        return getSession(row).name === value;
      }
    },
    render({ getValue, rows, getFilterValue, setFilterValues }) {
      // If currently filtering by year, then reduce the available sessions
      // to only sessions for the selected year.
      const year = getFilterValue('year');

      // If currently filtering by term, then reduce the available sessions
      // to only sessions for the selected term.
      const term = getFilterValue('term');

      function getSessionNameIfFits(session) {
        if (year || term) {
          const [_term, _year] = parseTerm(session.term);
          if (year && _year !== year) {
            return;
          }
          if (term && _term !== term) {
            return;
          }
        }
        return session.name;
      }

      let sessions: string[];

      if (getSessions) {
        sessions = rows.reduce<string[]>((all, row) =>
          all.concat(
            getSessions(row)
              .map((session) => getSessionNameIfFits(session))
              .filter(_ => _)
          ), []);
      } else {
        sessions = rows
          .map((row) => getSessionNameIfFits(getSession(row)))
          .filter(_ => _);
      }

      sessions = uniq(sessions);

      const options = [
        { label: 'All', value: null },
        ...sessions.map((session) => ({
          label: session,
          value: session
        }))
      ];

      // Don't "scroll into view"
      // because otherwise it would scroll to the top of the page
      // when clicking on bulk "Change status" in the floating header.
      return (
        <Select
          isFilter
          label="Session"
          value={getValue() ?? null}
          options={options}
          onChange={(value, option) => {
            setFilterValues(prev => ({
              ...prev,
              session: option
            }));
          }}
        />
      );
    }
  };
};
