import PropTypes from 'prop-types';
import React, { useMemo } from 'react';


import Email from '../../components/Email';
import Phone from '../../components/Phone';
import Section from '../../components/Section';
import { formatName } from '../../helpers/format';

import useLocation from '../../hooks/useLocation';

export default function SignUpRequested() {
  const location = useLocation();

  const contacts = useMemo(() => {
    return JSON.parse(location.query.contacts);
  }, []);

  return (
    <Section title="Account requested">
      <p>
        Your user account request has been submitted to your school's administrators.
        Please wait for them to approve or deny your request.
        Contact your school's administrators in case you want to speed up the review process or if you'd like to
        communicate with them directly about the status of your account:
      </p>

      <br/>

      {contacts.map((contact) => (
        <p key={contact.email} style={{ marginTop: '1rem' }}>
          {formatName(contact)}
          {contact.title && <br/>}
          {contact.title}
          <br/>
          <Email address={contact.email}/>
          {contact.phone && (
            <>
              <br/>
              <Phone contact={contact}/>
            </>
          )}
        </p>
      ))}
    </Section>
  );
}

SignUpRequested.propTypes = {
  location: PropTypes.object
};

SignUpRequested.meta = () => ({
  title: 'Account requested'
});
