import { useDispatch } from 'react-redux';

import type { GetActionRequiredCountsOutput } from '@acadeum/api';
import { injectActionRequiredCountsEndpoints } from '@acadeum/api';

import { rtkApi } from './rtkApi';

const actionRequiredCountsEndpoints = injectActionRequiredCountsEndpoints(rtkApi);

export const {
  useGetActionRequiredCountsQuery
} = actionRequiredCountsEndpoints;

export const useActionRequiredCounts = (): GetActionRequiredCountsOutput => {
  const state = actionRequiredCountsEndpoints.endpoints.getActionRequiredCounts.useQueryState(null);
  if (!state.data) {
    return {
      newEnrollmentRequestsCount: 0,
      newStudentRequestsCount: 0,
      awaitingGradesCount: 0,
      studentsNoRaceCount: 0
    };
  }
  return state.data;
};

export const useRefreshActionRequiredCounts = () => {
  const dispatch = useDispatch();
  return () => {
    dispatch(actionRequiredCountsEndpoints.util.invalidateTags(['actionRequiredCounts']));
  };
};
