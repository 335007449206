import React from 'react';

import actions from '../../actions';
import Field from '../../components/Field';
import Form from '../../components/Form';
import FormButtons from '../../components/FormButtons';
import FormHeading from '../../components/FormHeading';
import FormSubmit from '../../components/FormSubmit';


const {
  updateCurrentUserInstitution,
  notify
} = actions;

export default function UpdateCurrentUser() {
  async function updateUser(values) {
    await updateCurrentUserInstitution(values);
    notify('Your user has been updated, reloading the page...');
    // Reload the page to get the current user re-fetched
    window.location.reload();
  }

  return (
    <section>
      <FormHeading>
        Change Your Institution
      </FormHeading>

      <Form
        autoFocus
        className="form"
        onSubmit={updateUser}>

        <Field
          name="institutionId"
          required
          label="New Institution"
          type="institution"
          superAdmin
        />

        <FormButtons>
          <FormSubmit>
            Save
          </FormSubmit>
        </FormButtons>
      </Form>
    </section>
  );
}

UpdateCurrentUser.meta = () => ({
  title: 'Change Your Institution'
});

UpdateCurrentUser.breadcrumbs = () => [
  ['Admin Tools', '/admin'],
  'Change Your Institution'
];
