import React, { useMemo } from 'react';

import { useTranslate } from '@acadeum/translate';
import { ContentSection, Button, Form, FormField, FormFooter, FormSubmit, Text, Title } from '@acadeum/ui';


const CreateStudentMethod = ({ onChooseUploadMethod }) => {
  const t = useTranslate('CreateStudentMethod');

  const options = useMemo(() => [
    {
      value: 'manual',
      label: t('manual.title'),
      description: t('manual.description')
    },
    {
      value: 'upload',
      label: t('upload.title'),
      description: t('upload.description')
    }
  ], []);

  const onSubmit = ({ method = 'upload' }) => {
    onChooseUploadMethod(method);
  };

  return (
    <>
      <Title marginBottom="xl">
        {t('title')}
      </Title>

      <Form onSubmit={onSubmit}>
        <ContentSection title={t('sectionTitle')}>
          <Text color="grey">
            {t('description')}
          </Text>
          <br/>
          <FormField
            border
            required
            name="method"
            type="radio"
            options={options}
          />
        </ContentSection>

        <FormFooter>
          <Button url="/students" variant="secondary">
            {t('cancel', { global: true })}
          </Button>
          <FormSubmit>
            {t('next', { global: true })}
          </FormSubmit>
        </FormFooter>
      </Form>
    </>
  );
};

CreateStudentMethod.meta = () => ({
  title: 'Create Student Method'
});

export default CreateStudentMethod;
