import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './LoadingEllipsis.module.scss';

export interface LoadingEllipsisProps extends React.HTMLAttributes<HTMLDivElement> {
  square?: boolean;
}

export const LoadingEllipsis = React.forwardRef<HTMLDivElement, LoadingEllipsisProps>(({
  square,
  className
}, ref) => {
  return (
    <div ref={ref} className={classNames(className, styles.LoadingEllipsis, {
      [styles['LoadingEllipsis--square']]: square
    })}>
      <div className={styles.LoadingEllipsis__dot}/>
      <div className={styles.LoadingEllipsis__dot}/>
      <div className={styles.LoadingEllipsis__dot}/>
    </div>
  );
});

LoadingEllipsis.propTypes = {
  square: PropTypes.bool,
  className: PropTypes.string
};
