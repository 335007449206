import { useMemo } from 'react';

import type { AppOptions } from '../providers/useApp';
import { useApp } from '../providers/useApp';

const getSettingsRoutes = ({ app }: AppOptions) => {
  const isCourseShare = app === 'courseshare';
  const prefix = isCourseShare ? '/settings' : '';
  
  return {
    getSettingsUrl: () => isCourseShare ? '/settings' : '/',
    getUserAdminUrl: (userId: string | number | undefined) => `/admin/users/${userId}`,
    getUserManagementUrl: () => `${prefix}/user-management`,
    getUserDetailsUrl: (userId: string | number | undefined) => `${prefix}/user-management/${userId}`,
    getCreateUserUrl: () => `${prefix}/user-management/add`,
    getUserRequestsUrl: () => `${prefix}/user-requests`,
    getAuthenticationSettingsUrl: () => prefix + (isCourseShare ? '/authentication' : '/authentication-settings'),
    getCourseMappingsUrl: () => `${prefix  }/course-mappings`,
    getFinancialSettingsUrl: () => prefix + (isCourseShare ? '/financial' : '/financial-settings'),
    getPaymentHistoryUrl: () => `${prefix  }/payment-history`,
    getTeachingPaymentHistoryUrl: () => `${prefix  }/payment-history/teaching`,
    getInvoiceHistoryUrl: () => `${prefix  }/invoice-history`,
    getTeachingInvoicesUrl: () => `${prefix  }/invoice-history/teaching`,
    getStudentPortalUrl: () => `${prefix  }/student-portal`,
    getUserRoleManagementUrl: () => `${prefix  }/user-roles`,
    getCreateUserRoleUrl: () => `${prefix  }/user-roles/add`,
    getUserRoleDetailsUrl: (roleId: string | number | undefined) => `${prefix  }/user-roles/${roleId}`,
    getEditUserRolePermissionsUrl: (roleId: string | number | undefined) => `${prefix  }/user-roles/${roleId}/edit-permissions`,
    getContactsUrl: () => `${prefix  }/contacts`
  };
};

export const useSettingsRoutes = () => {
  const { app } = useApp();
  return useMemo(() => getSettingsRoutes({ app }), [app]);
};
