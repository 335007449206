import React from 'react';

import { LoadingIndicator } from '../LoadingIndicator';

import './LoadingScreen.sass';

export default function LoadingScreen() {
  return (
    <div className="LoadingScreen d-flex align-items-center justify-content-center">
      <LoadingIndicator show/>
    </div>
  );
}
