import PropTypes from 'prop-types';
import React from 'react';

import './FormTitle.scss';

export default function FormTitle({ children }) {
  return (
    <h3 className="FormTitle subtitle">
      {children}
    </h3>
  );
}

FormTitle.propTypes = {
  children: PropTypes.string.isRequired
};
