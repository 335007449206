import classNames from 'classnames';
import type { FC } from 'react';
import React, { useMemo, useRef } from 'react';

import { useRect } from '@acadeum/hooks';
import { Text, Title } from '@acadeum/ui';

import Card from './Card';
import FigureGrey from './figure-gray.svg';
import Figure from './figure.svg';

import styles from './PageLayout.module.scss';

interface PageLayoutComposition {
  Card: typeof Card;
}

export interface PageLayoutProps {
  title: string;
  subtitle: string;
  children: React.ReactNode;
  hideFiguresInBody?: boolean;
  className?: string;
}

export const PageLayout: FC<PageLayoutProps> & PageLayoutComposition = ({
  title,
  subtitle,
  children,
  hideFiguresInBody,
  className
}: PageLayoutProps) => {
  return (
    <section className={classNames(className, styles.root)}>
      <PageLayoutHeader
        title={title}
        subtitle={subtitle}
        hideFiguresInBody={hideFiguresInBody}
      />
      <PageLayoutBody hideFiguresInBody={hideFiguresInBody}>
        {children}
      </PageLayoutBody>
    </section>
  );
};

PageLayout.Card = Card;

function PageLayoutHeader({ title, subtitle, hideFiguresInBody }) {
  const headerNode = useRef(null);
  const { height, width } = useRect(headerNode);

  const logoFigure = useLogoFigure(6, styles.svg);
  const greyLogoFigure = useGreyLogoFigure(6, styles.svg);

  return (
    <>
      {hideFiguresInBody !== true && (
        <div style={{ height, width }} className={styles.PageLayoutHeader__overlay}>
          {greyLogoFigure}
        </div>
      )}
      <header ref={headerNode} className={styles.PageLayoutHeader}>
        {logoFigure}
        <Title className={styles.PageLayoutHeader__title}>
          {title}
        </Title>
        <Text className={styles.PageLayoutHeader__subtitle}>
          {subtitle}
        </Text>
      </header>
    </>
  );
}

function PageLayoutBody({ children, hideFiguresInBody }) {
  const greyLogoFigure = useGreyLogoFigure(5, styles.svg);

  return (
    <div className={styles.PageLayoutBody}>
      <div className={styles.PageLayoutBody__content}>
        {children}
      </div>
      {hideFiguresInBody !== true && (
        <div className={styles.PageLayoutBody__overlay}>
          {greyLogoFigure}
        </div>
      )}
    </div>
  );
}

const useLogoFigure = (count, className) => useMemo(() => {
  return Array.from(Array(count).keys()).map((_) => (
    <Figure key={_} className={className}/>
  ));
}, [count, className]);

const useGreyLogoFigure = (count, className) => useMemo(() => {
  return Array.from(Array(count).keys()).map((_) => (
    <FigureGrey key={_} className={className}/>
  ));
}, [count, className]);
