import React from 'react';

import type { AsProp } from '@acadeum/types';

import styles from './ScreenReaderOnly.module.scss';


export interface ScreenReaderOnlyProps extends AsProp, React.HTMLAttributes<HTMLElement> {
}

export const ScreenReaderOnly: React.FC<ScreenReaderOnlyProps> = ({
  as = 'p',
  ...rest
}) => {
  return React.createElement(as, {
    ...rest,
    className: styles.root
  });
};

ScreenReaderOnly.displayName = 'ScreenReaderOnly';
