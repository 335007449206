import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

export default function Row({
  padding,
  alignChildren,
  singleLineEqualHeight,
  singleLineEqualHeightSL,
  children
}) {
  return (
    <div className={classNames('row', {
      'row--large-padding': padding === 'large',
      'row--flex align-children-bottom': alignChildren === 'bottom',
      'row--single-line-equal-height': singleLineEqualHeight,
      'row--single-line-equal-height-s-l': singleLineEqualHeightSL
    })}>
      {children}
    </div>
  );
}

Row.propTypes = {
  padding: PropTypes.oneOf(['large']),
  alignChildren: PropTypes.oneOf(['bottom']),
  singleLineEqualHeight: PropTypes.bool,
  singleLineEqualHeightSL: PropTypes.bool,
  children: PropTypes.node.isRequired
};
