import { without } from 'lodash-es';
import { useEffect, useState } from 'react';

export default function useRowCheckboxes(
  initialCheckedRows = [],
  __checkedRows,
  __setCheckedRows,
  maxSelectableRows,
  getRows,
  onSelectRows,
  unfilteredRows
) {
  const [_checkedRows, _setCheckedRows] = useState(initialCheckedRows);

  const checkedRows = __setCheckedRows ? __checkedRows : _checkedRows;
  const setCheckedRows = __setCheckedRows || _setCheckedRows;

  useEffect(() => {
    if (onSelectRows) {
      onSelectRows(checkedRows);
    }
  }, [checkedRows]);

  const checkRow = (id) => {
    if (maxSelectableRows) {
      if (checkedRows.length === maxSelectableRows && !checkedRows.includes(id)) {
        return;
      }
    }
    setCheckedRows(
      checkedRows.includes(id)
        ? without(checkedRows, id)
        : checkedRows.concat(id).sort((a, b) => a - b)
    );
  };

  const areAllRowsChecked = (section) => {
    const rows = getRows(section);
    return rows.length > 0 && rows.every(row => checkedRows.includes(row.id));
  };

  const toggleCheckAllRows = (section) => {
    if (areAllRowsChecked(section)) {
      // Uncheck all section rows.
      if (section) {
        const sectionRowIds = getRows(section).map(row => row.id);
        setCheckedRows(checkedRows.filter(id => !sectionRowIds.includes(id)));
      } else {
        setCheckedRows([]);
      }
    } else {
      // Check all section rows.
      let newCheckedRows;
      if (section) {
        newCheckedRows = checkedRows.concat(
          getRows(section).map(row => row.id)
            .filter(id => !checkedRows.includes(id))
        );
      } else {
        newCheckedRows = getRows().map(row => row.id);
      }
      if (maxSelectableRows) {
        if (newCheckedRows.length > maxSelectableRows) {
          newCheckedRows = newCheckedRows.slice(0, maxSelectableRows);
        }
      }
      setCheckedRows(newCheckedRows.sort((a, b) => a - b));
    }
  };

  const selectedRows = checkedRows.map((id) => {
    const rowFromCurrentData = getRows().find(row => row.id === id);
    if (rowFromCurrentData) {
      return rowFromCurrentData;
    }
    const rowFromUnfilteredRows = unfilteredRows.find(row => row.id === id);
    if (rowFromUnfilteredRows) {
      return rowFromUnfilteredRows;
    }
  })
  // Filter out `undefined` values in cases when rows were not found.
    .filter(_ => _);

  return {
    selectedRows,
    setSelectedRowIds: setCheckedRows,
    selectRow: checkRow,
    toggleSelectAllRows: toggleCheckAllRows,
    areAllRowsSelected: areAllRowsChecked
  };
}
