import validatePropertyValuesNotUndefined from './validatePropertyValuesNotUndefined.js';

export default function canCompleteCourseEnrollment({
  status,
  onDemand
}) {
  const error = validateCanCompleteCourseEnrollment({
    status,
    onDemand
  });

  return !error;
}

export function validateCanCompleteCourseEnrollment({
  status,
  onDemand
}) {
  validatePropertyValuesNotUndefined({
    status,
    onDemand,
  });

  if (onDemand) {
    if (status !== 'ACTIVE') {
      return 'invalid_status';
    }
    return;
  }

  if (status !== 'DUE') {
    return 'invalid_status';
  }
}
