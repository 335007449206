import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { downloadCSV as downloadCsv } from '@acadeum/helpers';
import { useUpdateEffect } from '@acadeum/hooks';

import actions from '../../actions';
import Action from '../../components/Action';
import Button from '../../components/Button';
import Col from '../../components/Col';
import Field from '../../components/Field';
import Form from '../../components/Form';
import Row from '../../components/Row';
import { formatCurrency, formatPercent } from '../../helpers/format';

import './Report.sass';


const {
  fetchFinancialBreakdown
} = actions;

export default function FinancialBreakdown() {
  const form = useRef();

  const [refreshing, setRefreshing] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [selectedInstitutionId, setSelectedInstitutionId] = useState();

  const financialBreakdownReport = useSelector(state => state.reports.financialBreakdown);

  async function refresh() {
    try {
      setRefreshing(true);
      await fetchFinancialBreakdown({
        startDate: startDate ? startDate.toISOString() : undefined,
        endDate: endDate ? endDate.toISOString() : undefined,
        institutionId: selectedInstitutionId
      });
    } finally {
      setRefreshing(false);
    }
  }

  useUpdateEffect(() => {
    refresh();
  }, [startDate]);

  useUpdateEffect(() => {
    refresh();
  }, [endDate]);

  useUpdateEffect(() => {
    refresh();
  }, [selectedInstitutionId]);

  function resetFilters() {
    setStartDate();
    setEndDate();
    setSelectedInstitutionId();
    form.current.reset();
  }

  function getStatusRow(status) {
    const row = financialBreakdownReport.find(row => row.status === status);
    if (row) {
      return {
        ...row
      };
    }
  }

  const hiddenColumns = ['status', 'row_order'];
  const header = Object.keys(financialBreakdownReport[0]).filter(_ => !hiddenColumns.includes(_));

  const rows = [
    getStatusRow('TOTALENROLLMENTREQUESTS'),
    getStatusRow('COURSEREQUESTS'),
    getStatusRow('STUDENTREQUESTSPENDING'),
    getStatusRow('STUDENTREQUESTSDENIED'),
    getStatusRow('OFFICIALENROLLMENTS'),
    getStatusRow('REMOVED'),
    getStatusRow('DENIED'),
    getStatusRow('REQUESTED'),
    getStatusRow('BOOKEDENROLLMENTS'),
    getStatusRow('PENDING'),
    getStatusRow('STARTEDENROLLMENTS'),
    getStatusRow('DROPPEDPRIORTOADDROP'),
    getStatusRow('ACTIVE0'),
    getStatusRow('EARNEDENROLLMENTS'),
    getStatusRow('WITHDRAWN0'),
    getStatusRow('PAIDENROLLMENTS'),
    getStatusRow('ACTIVE1'),
    getStatusRow('WITHDRAWN1'),
    getStatusRow('DUE'),
    getStatusRow('COMPLETE')
  ].filter(_ => _);

  function exportData() {
    const columns = header.map(column => ({
      title: COLUMN_TITLES[column],
      value: data => data[column]
    }));
    downloadCsv({
      fileName: 'financial_breakdown.csv',
      columns,
      data: rows
    });
  }

  const renderTable = () => {
    if (rows.length === 0) {
      return 'No results';
    }

    if (refreshing) {
      return 'Loading...';
    }

    return (
      <div className="accounting-table-wrapper">
        <table className="accounting-table">
          <thead>
            <tr>
              {header.map((_, i) => (
                <th key={i}>
                  {COLUMN_TITLES[_]}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, i) => (
              <tr key={i}>
                {header.map((_, i) => (
                  <td key={i}>
                    {format(_, row)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="reports-page reports-page--financial-breakdown">
      <div className="FinancialBreakdown-subsection">
        <Form
          autoFocus
          className="form"
          ref={form}
          onSubmit={() => null}>
          <Row>
            <Col col={3} xs={6} sm={6} md={6} className="FinancialBreakdown__column">
              <Field
                name="startDate"
                onChange={setStartDate}
                type="date"
                label="Start Date"
              />
            </Col>
            <Col col={3} xs={6} sm={6} md={6} className="FinancialBreakdown__column">
              <Field
                name="endDate"
                onChange={setEndDate}
                type="date"
                label="End Date"
              />
            </Col>
            <Col col={6} xs={6} sm={6} md={6} className="FinancialBreakdown__column">
              <Field
                name="institutionId"
                label="Filter by Institution"
                type="institution"
                superAdmin
                onChange={setSelectedInstitutionId}
                value={selectedInstitutionId}
              />
            </Col>
          </Row>

          <Row>
            <Col col={3} xs={6} sm={6} md={6} className="FinancialBreakdown__column">
              <Button
                secondary
                disabled={!startDate && !endDate && !selectedInstitutionId}
                onClick={resetFilters}>
                Reset Filters
              </Button>
            </Col>
            <Col col={3} xs={6} sm={6} md={6} className="FinancialBreakdown__column">
              <Action
                disabled={refreshing}
                icon="download"
                iconSize="l"
                onClick={exportData}>
                Download
              </Action>
            </Col>
          </Row>
        </Form>
      </div>

      {renderTable()}

    </div>
  );
}

FinancialBreakdown.meta = () => ({
  title: 'Financial Breakdown'
});

FinancialBreakdown.load = async () => {
  await fetchFinancialBreakdown();
};

FinancialBreakdown.breadcrumbs = () => [
  ['Admin Tools', '/admin'],
  'Financial Breakdown'
];

function format(key, data) {
  switch (key) {
    case 'AverageStudentSavings':
    case 'AverageEMRevenue':
    case 'AverageTMRevenue':
    case 'AverageCCRevenue':
    case 'StudentSavings':
    case 'EMRevenue':
    case 'TMRevenue':
    case 'CCRevenue':
      return formatCurrency(Number(data[key]));
    case 'PCTTotal':
    case 'PCTOfficial':
    case 'PCTBooked':
    case 'PercentStudentSavings':
    case 'EMMarkup':
      return formatPercent(Number(data[key]));
    default:
      return data[key];
  }
}

const COLUMN_TITLES = {
  status: '',
  count: '#',
  AverageStudentSavings: '$ St. Sav',
  PercentStudentSavings: '% St. Sav',
  AverageEMRevenue: 'Student',
  AverageTMRevenue: 'TM',
  AverageCCRevenue: 'CC',
  EMMarkup: 'Markup',
  StudentSavings: 'Student',
  EMRevenue: 'Em Rev',
  TMRevenue: 'TM Rev',
  CCRevenue: 'CC Rev',
  PCTTotal: '% All Reqs',
  PCTOfficial: '% Off. Reqs',
  PCTBooked: '% of Bkd'
};
