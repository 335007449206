// Copy-pasted from `react-instantsearch`.
// https://github.com/algolia/react-instantsearch/blob/81a6f1856ae28b4ab00ba660c548037818749220/packages/react-instantsearch-dom/src/components/HitsPerPage.js
//
// Changes:
//
// * Added `ariaLabel={this.props['aria-label']}` on `<Select/>`.

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { ResultsPerPageSelect } from '@acadeum/ui';

import { createClassNames } from '../core/utils';

const cx = createClassNames('HitsPerPage');

class HitsPerPage extends Component {
  static propTypes = {
    items: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number.isRequired,
        label: PropTypes.string
      })
    ).isRequired,
    currentRefinement: PropTypes.number.isRequired,
    refine: PropTypes.func.isRequired,
    className: PropTypes.string
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { items, currentRefinement, refine, className, label } = this.props;

    return (
      <div className={classNames(cx(''), className)}>
        <ResultsPerPageSelect
          label={label}
          onChange={value => refine(value)}
          pageSize={currentRefinement}
          options={items}
        />
      </div>
    );
  }
}

export default HitsPerPage;
