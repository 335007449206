import React, { useMemo, useState } from 'react';

import { useStep } from '@acadeum/hooks';

import CreateStudentMethod from '../CreateStudentMethod';

import CreateStudentDetails from './ui/CreateStudentDetails';

type AddStudentMethod = 'manual' | 'upload';

const CreateStudentPage = () => {
  const [method, setMethod] = useState<AddStudentMethod>('upload');
  const { step, navigation } = useSteps();

  const onChooseUploadMethod = (method: AddStudentMethod) => {
    setMethod(method);
    navigation.next();
  };

  return (
    <div>
      <step.component
        method={method}
        onChooseUploadMethod={onChooseUploadMethod}
      />
    </div>
  );
};

CreateStudentPage.breadcrumbs = () => [
  ['Students', '/students'],
  'Create Students'
];

export default CreateStudentPage;

function useSteps() {
  const STEPS = useMemo(() => [
    {
      id: 'selectCreateStudentMethod',
      component: (props) => <CreateStudentMethod {...props}/>
    },
    {
      id: 'createStudentDetailsPage',
      component: (props) => <CreateStudentDetails {...props}/>
    }
  ], []);

  const { step, navigation } = useStep({ steps: STEPS });

  return { STEPS, step, navigation };
}
