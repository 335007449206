import type { FC} from 'react';
import { useCallback, useMemo } from 'react';
import React from 'react';

import { isObject } from '@acadeum/helpers';

import type { Id, Option, Options } from '@acadeum/types';

import type { ConsortiumAutocompleteProps } from '../../ConsortiumAutocomplete';
import { ConsortiumAutocomplete } from '../../ConsortiumAutocomplete';

import type { BaseFilterProps, FilterDefinition } from '../context';
import { useRegisterFilter } from '../context';

export interface ConsortiumFilterDefinition<V extends number> extends FilterDefinition<V> {
  options?: Option<V | null> | Options<V | null>
}

export interface ConsortiumFilterProps extends BaseFilterProps, Omit<ConsortiumAutocompleteProps, 'value' | 'onChange'> {
  label: string;
}

export const ConsortiumFilter: FC<ConsortiumFilterProps> = ({
  name,
  label,
  getFilterLabel: propsGetFilterLabel,
  onChange: propsOnChange,
  type,
  multiple,
  ...rest
}) => {
  const getFilterLabel = useCallback((value: Id, item: ConsortiumFilterDefinition<Id>) => {
    if (propsGetFilterLabel) {
      return propsGetFilterLabel(value, item);
    }

    if (Array.isArray(item.options)) {
      const selectedItem = item.options.find(_ => _.value === value);
      if (selectedItem) {
        return selectedItem.label;
      }
    } else if(isObject(item.options)) {
      return item.options.label;
    }
    return item.label;
  }, [propsGetFilterLabel]);

  const filterDefinition = useMemo<ConsortiumFilterDefinition<Id>>(() => ({
    name,
    label,
    getFilterLabel
  }), [name, label, getFilterLabel]);

  const { value, onChange: onChange_ } = useRegisterFilter<Id, ConsortiumFilterDefinition<Id>>({
    propsOnChange,
    filter: filterDefinition
  });

  const onChange = (value, selectedOptionOrOptions) => {
    onChange_(value, {
      ...filterDefinition,
      options: selectedOptionOrOptions
    });
  };

  return (
    <ConsortiumAutocomplete
      {...rest}
      isFilter
      isFormField={false}
      multiple={multiple}
      name={name}
      type={type}
      label={label}
      value={value}
      onChange={onChange}
    />
  );
};
