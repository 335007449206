import React from 'react';
import { useSelector } from 'react-redux';

import actions from '../../actions';
import Icon from '../../components/Icon';
import Spreadsheet from '../../components/Spreadsheet';


const {
  notify,
  fetchProjections,
  updateProjections
} = actions;

const columns = [
  {
    label: 'Institution ID',
    key: 'institutionId'
  },
  {
    label: 'Institution Name',
    key: 'institutionName'
  },
  {
    label: 'Spring 1 2022',
    key: 'spring1'
  },
  {
    label: 'Spring 2 2022',
    key: 'spring2'
  },
  {
    label: 'Summer 1 2022',
    key: 'summer1'
  },
  {
    label: 'Summer 2 2022',
    key: 'summer2'
  },
  {
    label: 'Fall 1 2022',
    key: 'fall2'
  },
  {
    label: 'Fall 2 2022',
    key: 'fall2'
  },
  {
    label: 'Winter 2022',
    key: 'winter'
  }
];

export default function Projections() {
  const { projections } = useSelector(state => state.reports);

  const sendProjections = async (updatedData, id) => {
    const type = id === 'projections-normal' ? 'normal' : 'plan';
    await updateProjections({ projections: updatedData, year: 2022, type });
    notify(`Updated Projection${Object.keys(updatedData).length > 1 ? 's' : ''}`);
  };

  return (
    <section>
      <div>
        <Icon className="notification-bar__icon" name="exclamation-triangle" />
        Only for Accounting/Sales
      </div>

      <Spreadsheet
        id="projections-normal"
        name="Normal Projections for 2022"
        columns={columns}
        content={projections.normal.map(formatProjectionsForSpreadsheet)}
        onSubmit={sendProjections} />

      <Spreadsheet
        id="projections-planned"
        name="Planned Projections for 2022"
        columns={columns}
        content={projections.plan.map(formatProjectionsForSpreadsheet)}
        onSubmit={sendProjections} />
    </section>
  );
}

Projections.meta = () => ({
  title: 'Projections'
});

Projections.breadcrumbs = () => [
  ['Admin', '/admin'],
  'Projections'
];

Projections.load = async ({ user }) => {
  await fetchProjections();
  if (!user.roles.some(role => role.name === 'Projections')) {
    const error = new Error('Unauthorized');
    error.status = 403;
    throw error;
  }
};

function formatProjectionsForSpreadsheet(data) {
  return {
    0: {
      text: data.institutionId,
      editable: false
    },
    1: {
      text: data.institutionName,
      editable: false
    },
    ...data.projectionsByTerm.reduce(
      (combinedCells, currentTerm, index) => {
        return {
          ...combinedCells,
          [index + 2]: {
            text: currentTerm.projection ? currentTerm.projection.toString() : '0'
          }
        };
      }, {})
  };
}
