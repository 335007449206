import PropTypes from 'prop-types';
import React from 'react';

import validatePassword from 'common-lib/lib/validatePassword';

import { getErrorData } from '@acadeum/helpers';
import { useTranslate } from '@acadeum/translate';


import actions from '../../actions';
import Form from '../Form';
import FormDescription from '../FormDescription';
import FormField from '../FormField';
import FormFooter from '../FormFooter';
import FormHeader from '../FormHeader';
import FormSubmit from '../FormSubmit';
import FormTitle from '../FormTitle';


import './ResetPassword.sass';

const {
  goto,
  signIn,
  resetPassword,
  notifyError
} = actions;

export default function ResetPassword({
  userId,
  email,
  token,
  redirectTo,
  onAfterSubmit,
  onPasswordResetAttemptedOnSingleSignOnInstitution
}) {
  const t = useTranslate('ResetPassword');

  // https://github.com/Acadeum/Acadeum-Student-Portal/issues/232#issuecomment-809872487
  // * Minimum of 8 characters
  // * At least one uppercase letter
  // * At least one number
  // * At least one special character
  function validatePassword_(value) {
    const error = validatePassword(value);
    if (error) {
      return t(`SignUp.form.password.${  error}`, { global: true });
    }
  }

  const onSubmit = async (values) => {
    // The password mismatch situation can't normally happen,
    // because the form dynamically validates that part "on change".
    if (values.password !== values.passwordConfirmation) {
      return notifyError(PASSWORDS_NOT_MATCH_MESSAGE);
    }

    let error;

    try {
      await resetPassword({
        userId,
        token,
        newPassword: values.password
      });
    } catch (error_) {
      console.error(error_);
      error = getErrorData(error_);
    }

    if (error) {
      if (error.type === 'invalid') {
        return notifyError(t('form.password.invalid'));
      }
      if (error.code === 'token_expired') {
        return notifyError(t('expired'));
      }
      if (error.code === 'single_sign_on_authentication_required') {
        return onPasswordResetAttemptedOnSingleSignOnInstitution();
      }
      console.error(error);
      return notifyError(t('errorVerbose', { global: true }));
    }

    let loginError;

    try {
      await signIn({
        email,
        password: values.password,
        expires: false
      });
    } catch (error) {
      console.error(error);
      loginError = getErrorData(error);
    }

    if (redirectTo) {
      goto(redirectTo);
    }

    if (onAfterSubmit) {
      onAfterSubmit({ loginError });
    }
  };

  return (
    <Form onSubmit={onSubmit} className="Form--alternativeFieldLabelStyle">
      {({ watch }) => {
        const password = watch('password');

        const validatePasswordConfirmation = (passwordConfirmation) => {
          if (password !== passwordConfirmation) {
            return PASSWORDS_NOT_MATCH_MESSAGE;
          }
        };

        return (
          <>
            <FormHeader>
              <FormTitle>
                {t('title')}
              </FormTitle>
              <FormDescription>
                {t('description', {
                  email,
                  emailTag: (children) => (
                    <strong>{children}</strong>
                  )
                })}
              </FormDescription>
            </FormHeader>

            <FormField
              required
              type="password"
              name="password"
              validate={validatePassword_}
              label={t('form.password.label')}
              placeholder={t('SignUp.form.password.hint', { global: true })}
            />

            <FormField
              required
              type="password"
              name="passwordConfirmation"
              validate={validatePasswordConfirmation}
              label={t('form.passwordConfirmation.label')}
              placeholder={t('form.passwordConfirmation.hint')}
            />

            <FormFooter>
              <FormFooter.Actions>
                <FormSubmit>
                  {t('form.button.title')}
                </FormSubmit>
              </FormFooter.Actions>
            </FormFooter>
          </>
        );
      }}
    </Form>
  );
}

ResetPassword.propTypes = {
  userId: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  redirectTo: PropTypes.string,
  onAfterSubmit: PropTypes.func,
  onPasswordResetAttemptedOnSingleSignOnInstitution: PropTypes.func.isRequired
};

const PASSWORDS_NOT_MATCH_MESSAGE = 'The passwords don\'t match';
