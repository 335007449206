import classNames from 'classnames';
import React from 'react';

import { CircleExclamationIcon, CircleInfoIcon, NoAccessIcon } from '@acadeum/icons';
import { useTranslate } from '@acadeum/translate';

import type { XsProp } from '../../utils/useSx';
import { useSx } from '../../utils/useSx';

import { BaseButton } from '../BaseButton';
import { CloseButton } from '../CloseButton';
import { Link } from '../Link';

import styles from './Alert.module.scss';

export interface AlertProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'style'>, XsProp {
  variant?: 'info' | 'warn' | 'error';
  bordered?: boolean;
  show?: boolean;
  dismissible?: boolean;
  onClose?: (show: false) => void;
  closeLabel?: string;
  action?: {
    onClick?: () => void;
    content: React.ReactNode;
    url?: string;
    external?: boolean;
  };
}

export const Alert = React.forwardRef<HTMLDivElement, AlertProps>(({
  variant = 'info',
  bordered = true,
  show = true,
  dismissible,
  onClose,
  className,
  closeLabel,
  children,
  action,
  sx,
  ...rest
}, ref) => {
  const sxStyles = useSx(sx);
  const t = useTranslate('ui.Alert');
  closeLabel = closeLabel || t('defaultProps.closeLabel');

  const Icon = getIcon(variant);

  const alert = (
    <div
      role="alert"
      {...rest}
      ref={ref}
      style={sxStyles}
      className={classNames(
        className,
        styles.Alert,
        styles[`Alert--${variant}`], {
          [styles['Alert--borderNone']]: !bordered
        }
      )}
    >
      <Icon aria-hidden="true" className={styles.Alert__icon}/>
      <div className={styles.Alert__content}>
        {children}
      </div>
      {action && (
        action.url ? (
          <Link
            className={styles.action}
            to={action.url}
            external={action.external}
            onClick={action.onClick}
            removeUnderline={false}
          >
            {action.content}
          </Link>
        ) : (
          <BaseButton onClick={action?.onClick} className={styles.action}>
            {action.content}
          </BaseButton>
        )
      )}
      {dismissible && (
        <CloseButton
          size="sm"
          onClick={() => onClose?.(false)}
          aria-label={closeLabel}
          className={styles.CloseButton}
        />
      )}
    </div>
  );

  return show ? alert : null;
});

Alert.displayName = 'Alert';

function getIcon(variant: AlertProps['variant']) {
  switch (variant) {
    case 'error':
      return NoAccessIcon;
    case 'warn':
      return CircleExclamationIcon;
    case 'info':
    default:
      return CircleInfoIcon;
  }
}
