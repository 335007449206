import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import formatUserName from 'common-lib/lib/formatUserName';

import { useTranslate } from '@acadeum/translate';
import { ContentSection, Form, FormField, Link } from '@acadeum/ui';

import actions from '../../../../actions';
import StickyFooter from '../../../../components/StickyFooter';


const {
  saveAdvisorRecommendationCreationState,
  resetAdvisorRecommendationCreationState,
  notifyError,
  goto
} = actions;

export default function AddStudents({ navigation, sectionId }) {
  const { course } = useSelector(state => state.courses);
  const students = useSelector(state => state.students.allStudents);
  const { advisorRecommendationCreationState } = useSelector(state => state.advisorRecommendations);
  const selectedStudents = advisorRecommendationCreationState?.students || [];

  const t = useTranslate('CreateAdvisorRecommendationAddStudents');

  const options = useMemo(() => students.map(student => ({
    label: formatUserName(student),
    value: student.id,
    suffixLabel: student.hiStudentId ? `ID: ${student.hiStudentId}` : null,
    description: student.email,
    keywords: [formatUserName(student), student.email, student.hiStudentId],
    component:
      <Link
        monochrome
        removeUnderline={false}
        to={`/students/${student.id}`}
        // stopPropagation is used here to prevent option from being checked while navigating.
        onClick={e => e.stopPropagation()}
      >
        {formatUserName(student)}
      </Link>
  })), [students]);

  const selectedStudentIds = useMemo(() => {
    return selectedStudents.map(student => student.id);
  }, [selectedStudents]);

  const onChoiceListOptionChange = (e) => {
    const selectedStudentIds = e.target.value;

    let selectedStudents = [];

    if (selectedStudentIds !== null && selectedStudentIds.length > 0) {
      selectedStudents = students.filter(
        student => selectedStudentIds.includes(student.id)
      );
    }

    saveAdvisorRecommendationCreationState({
      students: selectedStudents
    });
  };

  const onCancel = () => {
    const url = sectionId ? `/sections/${sectionId}` : `/courses/${course.id}`;

    resetAdvisorRecommendationCreationState();
    goto(url);
  };

  const onBack = () => {
    saveAdvisorRecommendationCreationState({
      currentStepId: 'createAdvisorRecommendationCourseOverview'
    });
    navigation.previous();
  };

  const onSubmit = () => {
    if (selectedStudents.length === 0) {
      return notifyError('Select at least one student');
    }

    saveAdvisorRecommendationCreationState({
      currentStepId: 'createAdvisorRecommendationSummary'
    });
    navigation.next();
  };

  return (
    <ContentSection border={false} padding="none">
      <Form onSubmit={onSubmit}>
        <ContentSection>
          <ContentSection
            border={false}
            title={t('enrollStudents')}
            padding="none"
          >
            <FormField
              required
              showId
              name="students"
              type="choiceList"
              size="medium"
              placeholder={t('searchByName')}
              itemLabel={{
                singular: 'Student',
                plural: 'Students'
              }}
              defaultValue={selectedStudentIds}
              options={options}
              onChange={onChoiceListOptionChange}
              addNewItem={{
                label: t('addStudent'),
                url: '/student-users/add?from=advisorRecommendation'
              }}
            />
          </ContentSection>
        </ContentSection>
      </Form>

      <StickyFooter
        onCancelProps={{
          onClick: onCancel
        }}
        onBackProps={{
          onClick: onBack
        }}
        submitProps={{
          children: t('next', { global: true }),
          onSubmit
        }}
      />
    </ContentSection>
  );
}

AddStudents.propTypes = {
  navigation: PropTypes.shape({
    previous: PropTypes.func,
    next: PropTypes.func,
    go: PropTypes.func
  }).isRequired,
  sectionId: PropTypes.number
};
