import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { useTranslate } from '@acadeum/translate';
import { Text } from '@acadeum/ui';

import Button from '../Button';

import CaretDownIcon from './CaretDown.svg';

import styles from './PublicMarketingPageBanner.module.scss';

export default function PublicMarketingPageBanner({
  title,
  description,
  dismissible,
  initialShowAdditionalInformation,
  onChangeShowAdditionalInformation,
  className,
  children
}) {
  const t = useTranslate('PublicMarketingPageBanner');

  const [showAdditionalInfo, setShowAdditionalInfo] = useState(initialShowAdditionalInformation);

  const onToggleShowAdditionalInfo = () => {
    setShowAdditionalInfo(!showAdditionalInfo);
    onChangeShowAdditionalInformation(!showAdditionalInfo);
  };

  return (
    <section className={classNames(className, styles.PublicMarketingPageBanner)}>
      <div className="container">
        <Text
          mb="lg"
          as="h2"
          variant="headingLg"
        >
          {title}
        </Text>
        <Text>
          {description}
        </Text>

        {dismissible && (
          <>
            {showAdditionalInfo && (
              <div className={styles.PublicMarketingPageBanner__additionalInfo}>
                {children}
              </div>
            )}

            <Button
              text
              onClick={onToggleShowAdditionalInfo}
              className={styles.PublicMarketingPageBanner__toggleShowAdditionalInfoButton}
            >
              {showAdditionalInfo ? t('hideAdditionalInfo') : t('showAdditionalInfo')}
              <CaretDownIcon className={classNames(styles.PublicMarketingPageBanner__toggleShowAdditionalInfoButtonIcon, {
                [styles.PublicMarketingPageBanner__toggleShowAdditionalInfoButtonIcon_isCollapsed]: showAdditionalInfo
              })}/>
            </Button>
          </>
        )}
      </div>
    </section>
  );
}

PublicMarketingPageBanner.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  initialShowAdditionalInformation: PropTypes.bool,
  dismissible: PropTypes.bool,
  onChangeShowAdditionalInformation: PropTypes.func.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};
