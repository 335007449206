import type { RtkApi } from '@acadeum/redux';
import type { Consortium, Institution, Id } from '@acadeum/types';


import type { UseJob } from '../jobs';

export interface FetchCourseSharingGroupsOutput {
  page: number;
  pageSize: number;
  totalCount: number;
  results: {
    id: Id;
    name?: string;
    isActive: boolean;
    institutions: Pick<Institution, 'id' | 'name' | 'vanityUrl' | 'logoUrl'>[];
    consortia: Pick<Consortium, 'id' | 'name' | 'vanityUrl' | 'logoUrl'>[];
  }[];
}

export interface FetchCourseSharingGroupsInput {
  page?: number;
  pageSize: number;
  search?: string;
}

export interface FetchCourseSharingGroupOutput {
  id: Id;
  name?: string;
  description?: string;
  institutions: Pick<Institution, 'id' | 'name' | 'vanityUrl' | 'logoUrl'>[];
  consortia: Pick<Consortium, 'id' | 'name' | 'vanityUrl' | 'logoUrl'>[];
}

export interface CreateCourseSharingGroupInput {
  body: {
    name: string;
    description?: string;
    institutionIds?: number[];
    consortiaIds?: number[];
  }
}

export interface UpdateCourseSharingGroupInput {
  id: Id;
  body: {
    name: string;
    description?: string;
    institutionIds?: number[];
    consortiaIds?: number[];
  }
}

export interface CourseSharingGroupOutput {
  id: Id;
}

export interface DeactivateCourseSharingGroupInput {
  id: Id;
}

export const injectAdminCourseSharingGroupEndpoints = (rtkApi: RtkApi) => rtkApi
  .enhanceEndpoints({addTagTypes: ['courseSharingGroups']})
  .injectEndpoints({
    endpoints: build =>({
      activateCourseSharingGroup: build.mutation<void, {id: Id}>({
        query: ({ id }) => ({
          url: `/admin/course-sharing-groups/${id}/activate`,
          method: 'POST'
        }),
        invalidatesTags: [{ type: 'courseSharingGroups', id: 'LIST' }]
      }),
      fetchCourseSharingGroups: build.query<FetchCourseSharingGroupsOutput, FetchCourseSharingGroupsInput>({
        query: params => ({
          url: '/admin/course-sharing-groups',
          params
        }),
        providesTags: () => [{ type: 'courseSharingGroups', id: 'LIST' }]
      }),
      deleteCourseSharingGroup: build.mutation<void, {id: Id}>({
        query: ({ id }) => ({
          url: `/admin/course-sharing-groups/${id}`,
          method: 'DELETE'
        }),
        invalidatesTags: [{ type: 'courseSharingGroups', id: 'LIST' }]
      }),
      fetchCourseSharingGroup: build.query<FetchCourseSharingGroupOutput, {id: Id}>({
        query: ({ id }) => `/admin/course-sharing-groups/${id}`,
        providesTags: [{ type: 'courseSharingGroups', id: 'LIST' }]
      })
    })
  });

export const getUseCreateCourseSharingGroupJob = (useJob: UseJob) => () => {
  const job = useJob();
  return (parameters: CreateCourseSharingGroupInput) => job<CourseSharingGroupOutput>('admin-courseSharingGroups-create', parameters);
};

export const getUseUpdateCourseSharingGroupJob = (useJob: UseJob) => () => {
  const job = useJob();
  return (parameters: UpdateCourseSharingGroupInput) => job<CourseSharingGroupOutput>('admin-courseSharingGroups-update', parameters);
};

export const getUseDeactivateCourseSharingGroupJob = (useJob: UseJob) => () => {
  const job = useJob();
  return (parameters: DeactivateCourseSharingGroupInput) => job<CourseSharingGroupOutput>('admin-courseSharingGroups-deactivate', parameters);
};

export type UseDeactivateCourseSharingGroupJob = ReturnType<typeof getUseDeactivateCourseSharingGroupJob>;

export type UseActivateCourseSharingGroupMutation = ReturnType<typeof injectAdminCourseSharingGroupEndpoints>['useActivateCourseSharingGroupMutation'];
export type UseDeleteCourseSharingGroupMutation = ReturnType<typeof injectAdminCourseSharingGroupEndpoints>['useDeleteCourseSharingGroupMutation'];
