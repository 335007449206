import PropTypes from 'prop-types';
import React from 'react';

import './Loading.sass';

const loadingStyle = {
  position: 'relative',
  margin: '0px auto',
  width: '40px',
  height: '40px'
};

const svgStyle = {
  animation: 'rotate 2s linear infinite',
  height: '100%',
  transformOrigin: 'center center',
  width: '100%',
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  margin: 'auto',
  stroke: '#1DB64A'
};

const circleStyle = {
  strokeDasharray: '1,200',
  strokeDashoffset: '0',
  animation: 'dash 1.5s ease-in-out infinite',
  strokeLinecap: 'round'
};

const animation = `@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}
@keyframes dash {
    0% {
        stroke-dasharray: 1,200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89,200;
        stroke-dashoffset: -35px;
    }
    100% {
        stroke-dasharray: 89,200;
        stroke-dashoffset: -124px;
    }
}`;

export default function Loading({
  isLoading = true,
  width = '40px',
  height = '40px',
  margin = '0 auto',
  inline,
  color,
  className,
  children
}) {
  if (!isLoading) {
    if (React.Children.count(children) > 1) {
      return <div>{children}</div>;
    }
    return children;
  }

  const loaderStyle = Object.assign({}, loadingStyle);

  loaderStyle.width = width;
  loaderStyle.height = height;
  loaderStyle.margin = margin;

  if (inline) {
    loaderStyle.display = 'inline-block';
    loaderStyle.verticalAlign = 'middle';
  }

  let style = svgStyle;
  if (color) {
    style = Object.assign({}, svgStyle, { stroke: color });
  }

  return (
    <div style={loaderStyle} className={className}>
      <style>{animation}</style>
      <svg style={style} viewBox="25 25 50 50">
        <circle style={circleStyle} cx="50" cy="50" r="20" fill="none" strokeWidth="5" strokeMiterlimit="10"/>
      </svg>
    </div>
  );
}

Loading.propTypes = {
  isLoading: PropTypes.bool,
  inline: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
  screen: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  margin: PropTypes.string,
  children: PropTypes.node
};
