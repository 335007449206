import type { Api, BaseQueryFn, CreateApiOptions } from '@reduxjs/toolkit/dist/query/react';
import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/dist/query/react';
import type { EndpointDefinitions } from '@reduxjs/toolkit/src/query/endpointDefinitions';
import type { FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/query';
import { QueryStatus } from '@reduxjs/toolkit/query';
import type { RetryOptions } from '@reduxjs/toolkit/dist/query/retry';
import type { coreModuleName } from '@reduxjs/toolkit/dist/query/core/module';
import type { reactHooksModuleName } from '@reduxjs/toolkit/dist/query/react/module';

import stringifyLocationQuery from 'common-lib/lib/stringifyLocationQuery';

import { getAccessTokenFromLocalStorage, usesCookiesForAuth } from '@acadeum/auth';
import {
  getIsInvalidRequest,
  getIsMaintenanceError,
  getIsNotAuthenticatedError,
  getIsNotFoundError,
  isObject,
  parseDates,
  transformQueryParametersToStrings,
  isHttpError
} from '@acadeum/helpers';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getRtkApiIsLoadingSelector = (state: any) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return Boolean(state?.api && Object.values(state.api.queries).some((query: any) => {
    return query && query.status === QueryStatus.pending;
  }));
};

export type RtkApi<TagTypes extends string = never> = Api<BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, RetryOptions, FetchBaseQueryMeta>, NonNullable<unknown>, any, TagTypes, typeof coreModuleName | typeof reactHooksModuleName>


export const getRtkApi = <BaseQuery extends BaseQueryFn, Definitions extends EndpointDefinitions, ReducerPath extends string, TagTypes extends string = never>(
  {
    baseUrl
  }: {
    baseUrl: string
  }, {
    reducerPath,
    tagTypes,
    extractRehydrationInfo
  }: Pick<CreateApiOptions<BaseQuery, Definitions, ReducerPath, TagTypes>, 'reducerPath' | 'tagTypes' | 'extractRehydrationInfo'>
) => {
  const baseQuery = fetchBaseQuery({
    baseUrl,
    credentials: 'include',
    prepareHeaders: (headers) => {
      if (!usesCookiesForAuth()) {
        const accessToken = getAccessTokenFromLocalStorage();
        if (accessToken) {
          headers.set('Authorization', `Bearer ${accessToken}`);
        }
      }
    },
    paramsSerializer: (params) => {
      const result = {};
      for (const [key, value] of Object.entries(params)) {
        if (Array.isArray(value)) {
          if (value.length > 0) {
            result[key] = JSON.stringify(value);
          }
        } else if (isObject(value)) {
          result[key] = JSON.stringify(value);
        } else {
          result[key] = value;
        }
      }
      return stringifyLocationQuery(transformQueryParametersToStrings(result))
        .replace('?', '');
    },
    responseHandler: async (response) => {
      const data = await response.json();
      return parseDates(data);
    }
  });

  const baseQueryWithRetry = retry(baseQuery, {
    retryCondition: (error, args, { attempt, baseQueryApi }) => {
      if (baseQueryApi.type === 'query' && attempt <= 3 && isHttpError(error)) {
        const ignoredErrors = [
          getIsInvalidRequest(error),
          getIsMaintenanceError(error),
          getIsNotFoundError(error),
          getIsNotAuthenticatedError(error)
        ];
        return !ignoredErrors.some(Boolean);
      }
      return false;
    }
  });

  return createApi({
    reducerPath,
    baseQuery: baseQueryWithRetry,
    refetchOnMountOrArgChange: true,
    tagTypes,
    extractRehydrationInfo,
    endpoints: () => ({})
  });
};
