import type { FC } from 'react';
import React, { useMemo } from 'react';

import formatUserName from 'common-lib/lib/formatUserName';

import type { UseFetchUsersQuery, UseSetUsersRolesForUsersMutation } from '@acadeum/api';
import { useTranslate } from '@acadeum/translate';
import type { Id, UserRoleWithUsers } from '@acadeum/types';
import type { FormModalProps, OnSubmit } from '@acadeum/ui';
import { FormField, FormModal } from '@acadeum/ui';

import { useOnError } from '../../../../providers/useOnError';

export interface AssignUsersToRoleModalFormProps extends Pick<FormModalProps, 'show' | 'onHide'> {
  role: Pick<UserRoleWithUsers, 'id' | 'name' | 'users'>;
  onAfterSubmit?: () => void;
  useSetUsersRolesForUsersMutation: UseSetUsersRolesForUsersMutation;
  useFetchUsersQuery: UseFetchUsersQuery;
}

export const AssignUsersToRoleModalForm: FC<AssignUsersToRoleModalFormProps> = ({
  show,
  onHide,
  role,
  onAfterSubmit,
  useSetUsersRolesForUsersMutation,
  useFetchUsersQuery
}) => {
  const t = useTranslate('shared-admin-ui.AssignUsersToRoleModalForm');

  const [setUsersRolesForUsersMutation] = useSetUsersRolesForUsersMutation();

  const onSubmit: OnSubmit<{ userIds: Id[] }> = async ({ userIds }) => {
    await setUsersRolesForUsersMutation({
      userIds,
      roleIds: [role.id]
    }).unwrap();
    await onAfterSubmit?.();
  };

  return (
    <FormModal
      title={t('title', { roleName: role.name })}
      show={show}
      onHide={onHide}
      onCancel={onHide}
      onSubmit={onSubmit}
    >
      <AssignUsersToRoleModalFormContent
        role={role}
        useFetchUsersQuery={useFetchUsersQuery}
      />
    </FormModal>
  );
};

interface AssignUsersToRoleModalFormContentProps {
  role: Pick<UserRoleWithUsers, 'id' | 'name' | 'users'>;
  useFetchUsersQuery: UseFetchUsersQuery;
}

function AssignUsersToRoleModalFormContent({
  role,
  useFetchUsersQuery
}: AssignUsersToRoleModalFormContentProps) {
  const t = useTranslate('shared-admin-ui.AssignUsersToRoleModalForm');
  const onError = useOnError();

  const {
    data,
    error,
    isLoading
  } = useFetchUsersQuery({
    pageSize: 10000,
    filters: {
      status: 'ACTIVE'
    }
  });

  const options = useMemo(() => {
    if (isLoading || !data) {
      return [];
    }
    return data.results
      .filter(user => !role.users.some(_ => _.id === user.id))
      .map(_ => ({
        label: formatUserName(_),
        value: _.id,
        description: _.email
      }));
  }, [isLoading, data, role]);

  if (error) {
    return onError(error);
  }

  return (
    <FormField
      required
      name="userIds"
      type="choiceList"
      size="medium"
      placeholder={t('searchByName')}
      loading={isLoading}
      options={options}
    />
  );
}
