import classNames from 'classnames';
import React, { memo } from 'react';

import type { IconSource, IconSourceProps } from '@acadeum/types';

import type { MarginsProp } from '../../utils/useMargin';
import { useMargin } from '../../utils/useMargin';

import styles from './Icon.module.scss';

type IconSize = 'sm' | 'md';
type IconColor = 'primary' | 'secondary' | 'tertiary' | 'accent' | 'success' | 'danger' | 'warn';

const sizeClasses: Record<IconSize, string> = {
  sm: styles.sm,
  md: styles.mb
};

const colorClasses: Record<IconColor, string> = {
  primary: styles.primary,
  secondary: styles.secondary,
  tertiary: styles.tertiary,
  accent: styles.accent,
  danger: styles.danger,
  warn: styles.warn,
  success: styles.success
};

export interface IconProps extends IconSourceProps, MarginsProp {
  className?: string;
  icon?: IconSource;
  size?: IconSize;
  color?: IconColor;
}

export const Icon = memo<IconProps>(({
  className,
  icon: IconComponent,
  size,
  color,
  focusable = false,
  'aria-hidden': ariaHidden = true,
  mb,
  mt,
  mr,
  ml,
  mx,
  my,
  ...rest
}) => {
  const { marginClassNames } = useMargin({ mt, ml, mr, mb, mx, my });

  if (!IconComponent) {
    return null;
  }

  return (
    <IconComponent
      {...rest}
      focusable={focusable}
      aria-hidden={ariaHidden}
      className={classNames(
        className,
        styles.root,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        sizeClasses[size!],
        marginClassNames,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        colorClasses[color!]
      )}
    />
  );
});
