import * as SelectPrimitive from '@radix-ui/react-select';
import classNames from 'classnames';
import React, { useId } from 'react';
import type { FC } from 'react';

import { CaretDownIcon, CheckIcon } from '@acadeum/icons';

import { Icon } from '../Icon';
import { Label } from '../Label';

import styles from './ResultsPerPageSelect.module.scss';

interface ResultsPerPageSelectProps {
  label?: string;
  pageSize: number;
  onChange: (value: number) => void;
  options: number[] | {
    label: string;
    value: number;
  }[];
}

export const ResultsPerPageSelect: FC<ResultsPerPageSelectProps> = ({
  pageSize,
  onChange: onChange_,
  options,
  label = 'Show Per Page'
}) => {
  const onChange = (value) => {
    onChange_(Number(value));
  };

  const id = useId();

  return (
    <div className={styles.ResultsPerPageSelect}>
      <Label htmlFor={id} className={styles.ResultsPerPageSelect__label}>
        {label}
      </Label>
      <SelectPrimitive.Root defaultValue={String(pageSize)} onValueChange={onChange}>
        <SelectPrimitive.Trigger id={id} className={styles.SelectTrigger} aria-label={label}>
          <SelectPrimitive.Value />
          <Icon icon={CaretDownIcon} className={styles.SelectTrigger__icon}/>
        </SelectPrimitive.Trigger>
        <SelectContent align="end">
          {formatOptions(options).map(({ value, label }) => (
            <SelectItem key={value} value={String(value)}>
              {label}
            </SelectItem>
          ))}
        </SelectContent>
      </SelectPrimitive.Root>
    </div>
  );
};

/**
 * Returns an array of objects format [{ value: [someValue], label: [someLabel] }]
 * @param {Array} array
 * @returns {Array}
 */
export function formatOptions(array) {
  if (Array.isArray(array) && typeof array[0] !== 'object') {
    return array.map(val => ({
      value: val,
      label: val.toString()
    }));
  }
  return array;
}

const SelectContent = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
>(({ className, children, position = 'popper', ...props }, ref) => (
  <SelectPrimitive.Portal >
    <SelectPrimitive.Content
      ref={ref}
      className={classNames(className, styles.SelectContent)}
      position={position}
      {...props}
    >
      <SelectPrimitive.Viewport>
        {children}
      </SelectPrimitive.Viewport>
    </SelectPrimitive.Content>
  </SelectPrimitive.Portal>
));

SelectContent.displayName = SelectPrimitive.Content.displayName;

const SelectItem = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Item
    ref={ref}
    role="option"
    className={classNames(className, styles.SelectItem)}
    {...props}
  >
    <SelectPrimitive.ItemText>
      {children}
    </SelectPrimitive.ItemText>
    <SelectPrimitive.ItemIndicator>
      <Icon icon={CheckIcon} size="md"/>
    </SelectPrimitive.ItemIndicator>
  </SelectPrimitive.Item>
));

SelectItem.displayName = SelectPrimitive.Item.displayName;
