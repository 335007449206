import PropTypes from 'prop-types';
import React from 'react';
import { useController } from 'react-hook-form';

import { ChoiceList } from '../../../ChoiceList';

export const FormChoiceList = ({ name, defaultValue, rules, ...rest }) => {
  const { field } = useController({ name, defaultValue, rules });

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <ChoiceList {...rest} {...field}/>
  );
};

FormChoiceList.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
  rules: PropTypes.object.isRequired
};
