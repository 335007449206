// This `<Switch/>` component is the same one as in `client-lib` (copy-pasted from there).
// The reason why it was copy-pasted here is because the `postcss-custom-properties` plugin
// in `preserve: false` mode wouldn't "pick up" the variables used in `Switch.sass`.

import PropTypes from 'prop-types';
import React from 'react';

import { Switch as RRUISwitch } from 'react-responsive-ui';

import './Switch.sass';

let Switch = ({
  label,
  value,
  onChange,
  disabled,
  className,
  children
}, ref) => {
  return (
    <RRUISwitch
      ref={ref}
      placement="left"
      value={value}
      onChange={onChange}
      disabled={disabled}
      className={className}>
      {children || label}
    </RRUISwitch>
  );
};

Switch = React.forwardRef(Switch);

Switch.propTypes = {
  label: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node
};

export default Switch;

export let InstantSearchSwitch = ({
  Icon,
  label,
  ...rest
}, ref) => {
  let children = label;
  if (Icon) {
    children = (
      <span className="InstantSearch-ToggleIconAndLabel">
        <Icon className="InstantSearch-ToggleIcon"/>
        {label}
      </span>
    );
  }
  return (
    <Switch
      ref={ref}
      {...rest}
      className="InstantSearch-ToggleSwitch">
      {children}
    </Switch>
  );
};

InstantSearchSwitch = React.forwardRef(InstantSearchSwitch);

InstantSearchSwitch.propTypes = {
  label: PropTypes.string.isRequired,
  Icon: PropTypes.elementType
};
