import classNames from 'classnames';
import React, { useId, useState } from 'react';

import { useForwardedRef } from '@acadeum/hooks';

import { useRHFResetListener } from '../../utils/useRHFResetListener';

import styles from './Checkbox.module.scss';

export interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  indeterminate?: boolean;
  error?: string | boolean;
  border?: boolean;
  descriptionClassName?: string;
  label?: React.ReactNode;
  description?: React.ReactNode;
  type?: 'switch' | 'checkbox';
}

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(({
  indeterminate,
  disabled,
  readOnly,
  required,
  error,
  border,
  onChange: propsOnChange,
  id: propsId,
  className,
  descriptionClassName,
  label,
  description,
  type = 'checkbox',
  ...rest
}, ref) => {
  const defaultId = useId();
  const id = propsId || defaultId;

  const idLabelledby = useId();

  const { internalRef, setRef } = useForwardedRef(ref);

  /** This component is not controlled, the check state is used only for styling */
  const [checked, setChecked] = useState(internalRef.current?.checked);

  const onChecked = () => {
    setChecked(internalRef.current.checked);
  };

  const onChange = (event) => {
    onChecked();
    propsOnChange?.(event);
  };

  /** onChange doesn't work when the form is reset, so we listen to the form reset event */
  useRHFResetListener(() => {
    onChecked();
  });

  const ariaChecked = indeterminate ? 'mixed' : internalRef?.current?.checked;

  return (
    <label
      htmlFor={id}
      className={classNames(className, styles.CheckboxWrapper, {
        [styles['CheckboxWrapper--border']]: border,
        [styles['CheckboxWrapper--checked']]: checked,
        [styles['CheckboxWrapper--disabled']]: disabled,
        [styles['CheckboxWrapper--readOnly']]: readOnly,
        [styles['CheckboxWrapper--error']]: error
      })}
    >
      <CheckboxInput
        {...rest}
        id={id}
        type={type}
        onChange={onChange}
        ref={setRef}
        error={error}
        disabled={disabled || readOnly}
        required={required}
        aria-labelledby={idLabelledby}
        indeterminate={indeterminate}
        aria-checked={ariaChecked}
      />
      {(label || description) && (
        <span>
          <span id={idLabelledby} className={styles.label}>
            {label}
            {required && (
              <span className={styles.required}>*</span>
            )}
          </span>
          {description && (
            <span className={classNames(styles.description, descriptionClassName)}>
              {description}
            </span>
          )}
        </span>
      )}
    </label>
  );
});

type CheckboxInputProps = CheckboxProps;

const CheckboxInput = React.forwardRef<HTMLInputElement, CheckboxInputProps>(({
  indeterminate,
  error,
  type,
  ...rest
}, ref) => {
  return (
    <span className={type === 'checkbox' ? styles.CheckboxInput : styles.SwitchInput}>
      <input
        {...rest}
        ref={ref}
        type="checkbox"
        className={styles.input}
      />
      <span className={classNames(styles.checkmark, {
        [styles['checkmark--indeterminate']]: indeterminate,
        [styles['checkmark--error']]: error
      })} />
    </span>
  );
});
