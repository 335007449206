import PropTypes from 'prop-types';
import React from 'react';

import normalizePath from '../../helpers/normalizePath';
import Link from '../Link';


export default function PageLink({
  to,
  alias,
  location,
  children
}) {
  // Normalize `to`.
  to = normalizePath(to);
  // Normalize `alias`.
  if (alias) {
    alias = normalizePath(alias);
  }
  // Is this page "active"?
  const path = normalizePath(location.pathname);
  if (path === to || (alias && path === alias)) {
    return (
      <div className="PageLink PageLink--selected">
        {children}
      </div>
    );
  }
  return (
    <Link to={to} className="PageLink">
      {children}
    </Link>
  );
}

PageLink.propTypes = {
  location: PropTypes.object.isRequired,
  to: PropTypes.string.isRequired,
  isActive: PropTypes.func,
  alias: PropTypes.string,
  children: PropTypes.node.isRequired
};
