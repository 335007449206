
import CourseRegistrationRequest_ from 'common-lib/messages/en/CourseRegistrationRequest.json';
import Enrollment_ from 'common-lib/messages/en/Enrollment.json';

import ContactInfo from '../../../components/ContactInfo/ContactInfo.en.json';
import CourseEnrollmentsTable from '../../../components/CourseEnrollmentsTable/CourseEnrollmentsTable.en.json';
import CourseEnrollmentGradeModal from '../../../components/CourseEnrollmentsTable/ui/CourseEnrollmentGradeModal/CourseEnrollmentGradeModal.en.json';
import CourseSearch from '../../../components/CourseSearch/CourseSearch.en.json';
import DefaultTemplate from '../../../components/DefaultTemplate/DefaultTemplate.en.json';
import EmailAlreadyVerifiedModal from '../../../components/EmailAlreadyVerifiedModal/EmailAlreadyVerifiedModal.en';
import EnrollmentsTable from '../../../components/EnrollmentsTable/EnrollmentsTable.en.json';
import Footer from '../../../components/Footer/Footer.en.json';
import SearchFilterItem from '../../../components/SearchFilterItem/SearchFilterItem.en.json';
import ApproveCourse from '../../../pages/ApproveCourse/ApproveCourse.en.json';
import CourseOverviewSection from '../../../pages/CreateEnrollment/ui/CourseOverviewSection/CourseOverviewSection.en.json';
import CourseRegistrationRequest from '../../../pages/CourseRegistrationRequest/CourseRegistrationRequest.en.json';
import CreateStudent from '../../../pages/CreateStudent/CreateStudent.en.json';
import CreateStudentMethod from '../../../pages/CreateStudentMethod/CreateStudentMethod.en.json';
import Consortium from '../../../pages/Consortium/Consortium.en.json';
import CourseSharingGroup from '../../../pages/CourseSharingGroup/CourseSharingGroup.en.json';
import CourseSharingGroups from '../../../pages/CourseSharingGroups/CourseSharingGroups.en.json';
import CreateAdvisorRecommendation from '../../../pages/CreateAdvisorRecommendation/CreateAdvisorRecommendation.en.json';
import CreateAdvisorRecommendationAddStudents from '../../../pages/CreateAdvisorRecommendation/ui/AddStudents/AddStudents.en.json';
import CreateAdvisorRecommendationSummary from '../../../pages/CreateAdvisorRecommendation/ui/Summary/Summary.en.json';
import CreateCourseSharingGroup from '../../../pages/CreateCourseSharingGroup/CreateCourseSharingGroup.en.json';
import CreateEnrollment from '../../../pages/CreateEnrollment/CreateEnrollment.en.json';
import AddStudents from '../../../pages/CreateEnrollment/ui/AddStudents/AddStudents.en.json';
import EditCoursePageCourseSectionsTab from '../../../pages/EditCourse/ui/CourseSectionsTab/CourseSectionsTab.en.json';
import EditCourseSharingGroup from '../../../pages/EditCourseSharingGroup/EditCourseShareGroup.en.json';
import SearchInput from '../../../components/SearchInput/SearchInput.en.json';
import EnrollmentRequest from '../../../pages/EnrollmentRequest/EnrollmentRequest.en.json';
import EnrollmentRequests from '../../../pages/EnrollmentRequests/EnrollmentRequests.en.json';
import Enrollments from '../../../pages/Enrollments/Enrollments.en.json';
import Form from '../../../components/Form/Form.en.json';
import FinancialSettingsPage from '../../../pages/FinancialSettings/FinancialSettingsPage.en.json';
import Institution from '../../../pages/Institution/Institution.en.json';
import Students from '../../../pages/Students/Students.en.json';
import HomeStudents from '../../../pages/Students/ui/HomeStudents/HomeStudents.en.json';
import InstitutionForm from '../../../components/InstitutionForm/InstitutionForm.en.json';
import InstitutionSearch from '../../../components/InstitutionSearch/InstitutionSearch.en.json';
import InstitutionUsesSingleSignOnAuthentication
  from '../../../components/InstitutionUsesSingleSignOnAuthentication/InstitutionUsesSingleSignOnAuthentication.en.json';
import Login from '../../../components/Login/Login.en.json';
import LoginModal from '../../../components/LoginModal/LoginModal.en.json';
import PublicMarketingPageBanner from '../../../components/PublicMarketingPageBanner/PublicMarketingPageBanner.en.json';
import PublicMarketingPageBannerSection
  from '../../../components/PublicMarketingPageBannerSection/PublicMarketingPageBannerSection.en.json';
import ReasonFormField from '../../../components/ReasonFormField/ReasonFormField.en.json';
import RequestCourseBanner from '../../../components/RequestCourseBanner/RequestCourseBanner.en.json';
import RequestMoreInfoButton from '../../../components/RequestMoreInfoButton/RequestMoreInfoButton.en.json';
import RequestMoreInfoModal from '../../../components/RequestMoreInfoModal/RequestMoreInfoModal.en.json';
import RequestPasswordReset from '../../../components/RequestPasswordReset/RequestPasswordReset.en.json';
import ResetPassword from '../../../components/ResetPassword/ResetPassword.en.json';
import SignUp from '../../../components/SignUp/SignUp.en.json';
import SingleSignOnEnabled from '../../../components/SingleSignOnEnabled/SingleSignOnEnabled.en.json';
import StepsProgress from '../../../components/StepsProgress/StepsProgress.en.json';
import StudentDetails from '../../../components/StudentDetails/StudentDetails.en.json';
import StudentForm from '../../../components/StudentForm/StudentForm.en.json';
import StudentOverviewCard from '../../../components/StudentOverviewCard/StudentOverviewCard.en.json';
import ShowMore from '../../../components/ShowMore/ShowMore.en.json';
import Summary from '../../../pages/CreateEnrollment/ui/Summary/Summary.en.json';

import Grades from '../../../components/Grades/Grades.en.json';
import TeachingStudents from '../../../pages/Students/ui/TeachingStudents/TeachingStudents.en.json';
import VerifyEmail from '../../../components/VerifyEmail/VerifyEmail.en.json';


import common from './common';

EnrollmentRequest.denyReason.options = Enrollment_.denyReason;
EnrollmentsTable.dropReason.options = Enrollment_.dropReason;
EnrollmentsTable.withdrawReason.options = Enrollment_.withdrawReason;
EnrollmentsTable.studentDropReason = { options: Enrollment_.studentDropReason };
EnrollmentsTable.studentWithdrawReason = { options: Enrollment_.studentWithdrawReason };
EnrollmentsTable.removeReason.options = Enrollment_.removeReason;
StudentOverviewCard.enrollReason.options = Enrollment_.enrollReason;
CourseRegistrationRequest.denyReason.options = CourseRegistrationRequest_.denyReason;
CourseRegistrationRequest.registerReason.options = CourseRegistrationRequest_.registerReason;

const en = {
  ...common,
  CourseEnrollmentsTable,
  AddStudents,
  ApproveCourse,
  CourseEnrollmentGradeModal,
  ContactInfo,
  CourseOverviewSection,
  CourseRegistrationRequest,
  CourseSearch,
  CourseSharingGroup,
  CourseSharingGroups,
  Consortium,
  CreateAdvisorRecommendation,
  CreateAdvisorRecommendationAddStudents,
  CreateAdvisorRecommendationSummary,
  CreateCourseSharingGroup,
  CreateEnrollment,
  CreateStudent,
  CreateStudentMethod,
  DefaultTemplate,
  EditCoursePageCourseSectionsTab,
  EditCourseSharingGroup,
  EmailAlreadyVerifiedModal,
  Enrollments,
  EnrollmentRequest,
  EnrollmentRequests,
  EnrollmentsTable,
  FinancialSettingsPage,
  Footer,
  Form,
  HomeStudents,
  Institution,
  InstitutionForm,
  InstitutionSearch,
  InstitutionUsesSingleSignOnAuthentication,
  Login,
  LoginModal,
  PublicMarketingPageBanner,
  PublicMarketingPageBannerSection,
  ReasonFormField,
  RequestCourseBanner,
  RequestMoreInfoButton,
  RequestMoreInfoModal,
  RequestPasswordReset,
  ResetPassword,
  SearchFilterItem,
  SearchInput,
  SignUp,
  SingleSignOnEnabled,
  StepsProgress,
  Students,
  StudentDetails,
  StudentForm,
  StudentOverviewCard,
  Summary,
  ShowMore,
  VerifyEmail,
  Grades,
  TeachingStudents
};

export default en;
