import React, { memo } from 'react';

import formatUserName from 'common-lib/lib/formatUserName';

import { Avatar } from '@acadeum/core-ui';
import type { User } from '@acadeum/types';

import { Email } from '../../../Email';
import { Link } from '../../../Link';

import styles from './UserBlock.module.scss';

export interface UserBlockProps {
  user?: Pick<User, 'firstName' | 'lastName' | 'middleName' | 'title' | 'userPictureUrl'>;
  email?: string;
  inlineInfo?: React.ReactNode;
  url?: string;
  showUserPicture?: boolean;
}

export const UserBlock: React.FC<UserBlockProps> = memo(({
  url,
  user,
  email,
  inlineInfo,
  showUserPicture
}) => {
  return (
    <div className={styles.UserBlock}>
      {showUserPicture && (
        <Avatar
          size={32}
          type="user"
          url={user?.userPictureUrl}
        />
      )}
      <div>
        <div className={styles.row}>
          <Link to={url} className={styles.name}>
            {formatUserName(user)}
          </Link>
          {inlineInfo}
        </div>
        {email && <Email address={email} className={styles.email}/>}
      </div>
    </div>
  );
});
