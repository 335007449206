import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { ChevronLeftIcon, ChevronRightIcon } from '@acadeum/icons';
import { Button } from '@acadeum/ui';

import styles from './MinimizeButton.module.scss';

export interface MinimizeButtonProps {
  className?: string;
  onClick?: () => void;
  minimized?: boolean;
}

export const MinimizeButton: React.FC<MinimizeButtonProps> = ({
  className,
  onClick,
  minimized
}) => {
  const title = minimized ? 'Detail view' : 'Icon view';

  return (
    <Button
      size="small"
      title={title}
      aria-label={title}
      onClick={onClick}
      className={classNames(className, styles.MinimizeButton, {
        [styles['MinimizeButton--minimized']]: minimized
      })}
    >
      {minimized ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
    </Button>
  );
};

MinimizeButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  minimized: PropTypes.bool
};
