import React from 'react';

import { Grades } from '../../components/Grades';
import type { AppPage } from '../../helpers/app.types';

const EnrollingGrades: AppPage = () => (
  <Grades mode="homeInstitution"/>
);

EnrollingGrades.meta = () => ({
  title: 'Enrolling Grades'
});

EnrollingGrades.breadcrumbs = () => ['Enrolling Grades'];

export default EnrollingGrades;
