import classNames from 'classnames';
import type { CSSProperties, MutableRefObject} from 'react';
import React, { memo, useEffect, useRef, useState } from 'react';

import { KeyCode } from '../../../../types';

import { Input } from '../../../Input';

import styles from './EditableInput.module.scss';

export interface EditableInputProps<L extends string = 'hex' | 'r' | 'g' | 'b' | 'h' | 's' | 'l' | 'a'> {
  min?: number;
  max?: number;
  style?: CSSProperties;
  value?: string | number;
  label: L;
  onChange?: (value: { [K in L]?: string }) => void;
}

const getNumberValue = value => Number(String(value).replace(/%/g, ''));

export const EditableInput = memo<EditableInputProps>(({
  min,
  max,
  style,
  label,
  value: propsValue = '',
  onChange: propsOnChange
}) => {
  const inputRef = useRef() as MutableRefObject<HTMLInputElement>;

  const [value, setValue] = useState(propsValue);
  const [blurValue, setBlurValue] = useState<null | string | number>(propsValue);

  useEffect(() => {
    if (value !== propsValue) {
      if (inputRef.current === document.activeElement) {
        setBlurValue(propsValue);
      } else {
        setValue(propsValue);
        if (!blurValue) {
          setBlurValue(propsValue);
        }
      }
    }
  }, [propsValue, value, blurValue]);

  const setUpdatedValue = (value) => {
    if (typeof value === 'string') {
      if (value.includes('%')) {
        value = value.replace('%', '');
      }
    }

    if (!isNaN(value)) {
      if (typeof min === 'number') {
        if (value < min) {
          value = min;
        }
      }
      if (typeof max === 'number') {
        if (value > max) {
          value = max;
        }
      }
    }

    setValue(value);
    propsOnChange?.({ [label]: value });
  };

  const onChange = (event) => {
    const value = event.target.value;
    setUpdatedValue(value);
  };

  const onKeyDown = (event) => {
    const value = getNumberValue(event.target.value);
    if (!isNaN(value) && [KeyCode.ArrowDown, KeyCode.ArrowUp].includes(event.keyCode)) {
      const updatedValue = event.keyCode === KeyCode.ArrowUp ? value + 1 : value - 1;
      setUpdatedValue(updatedValue);
    }
  };

  const onBlur = () => {
    if (blurValue) {
      setValue(blurValue);
      setBlurValue(null);
    }
  };

  return (
    <div className={classNames(styles.EditableInput)} style={style}>
      <Input 
        ref={inputRef} 
        value={value} 
        onChange={onChange} 
        onKeyDown={onKeyDown} 
        onBlur={onBlur}
        onFocus={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
      />
    </div>
  );
});
