import React from 'react';

import { UserAccountsPage } from '@acadeum/shared-admin-ui';

const AdminUsersPage = () => {
  return (
    <UserAccountsPage />
  );
};

AdminUsersPage.meta = () => ({
  title: 'User Accounts'
});

export default AdminUsersPage;
