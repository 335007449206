import { userHasPermission } from '@acadeum/helpers';
import { AddUserPage } from '@acadeum/shared-admin-ui';

import { useFetchUserRolesQuery } from '../../api/userRoles';
import { useCreateUserMutation } from '../../api/users';
import type { AppPage } from '../../helpers/app.types';


const AddUser: AppPage = () => {
  return (
    <AddUserPage
      useFetchUserRolesQuery={useFetchUserRolesQuery}
      useCreateUserMutation={useCreateUserMutation}
    />
  );
};

AddUser.meta = () => ({
  title: 'Add User'
});

AddUser.when = ({ user }) => userHasPermission(user, 'user:create', {
  orgId: user?.institution.id,
  ownerId: null
});

export default AddUser;
