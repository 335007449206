import races from 'common-lib/constants/races.json';
import formatRace from 'common-lib/lib/formatRace';

import { getTranslationLanguage } from '../translate';

const language = getTranslationLanguage();

export const RACE_OPTIONS = races.map((race) => ({
  value: race,
  label: formatRace(race, { language, input: true, own: false })
}));

export const RACE_OWN_OPTIONS = races.map((race) => ({
  value: race,
  label: formatRace(race, { language, input: true, own: true })
}));
