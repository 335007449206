import React from 'react';

import { HStack } from '@acadeum/core-ui';
import { useTranslate } from '@acadeum/translate';

import { ContentSection, Link, Text } from '@acadeum/ui';

import ContactInfo from '../../../../components/ContactInfo/ContactInfo';
import InfoSection from '../../../../components/InfoSection';
import WelcomeLetter from '../../../../components/WelcomeLetter';
import { formatCountry } from '../../../../helpers/format';

import styles from './GeneralInfo.module.scss';

const GeneralInfo = ({
  accreditations,
  institution,
  partnership,
  membership
}) => {
  const t = useTranslate('Institution');

  return (
    <>
      <ContentSection
        fullWidth
        padding={false}
        border={false}
        title={t('about')}
      >
        {accreditations.length > 0 && (
          <InfoSection title={t('accreditations.title')}>
            {t('accreditations.name', {
              name: institution.name
            })}
            {' '}
            {accreditations.length === 1 && accreditations[0]}
            {accreditations.length > 1 && t('organizationsList', {
              list: () => (
                <ul className={styles.list}>
                  {accreditations.map((_, i) => <li key={i}>{_}</li>)}
                </ul>
              )
            })}
          </InfoSection>
        )}

        {institution.data.academicCalendarUrl && (
          <InfoSection title={t('academicCalendar')}>
            <Link removeUnderline={false} to={institution.data.academicCalendarUrl}>
              {institution.data.academicCalendarUrl}
            </Link>
          </InfoSection>
        )}

        <InfoSection title={t('address')}>
          <address>
            {institution.addressLine1} {institution.addressLine2} {institution.city}, {institution.state} {institution.postalCode} {formatCountry(institution.country)}
          </address>
        </InfoSection>

        {membership && (
          <InfoSection title={t('membership.title')}>
            {t('membership.name', {
              name: institution.name
            })}
            {' '}
            {membership.length === 1 && membership[0]}
            {membership.length > 1 && t('organizationsList', {
              list: () => (
                <ul className={styles.list}>
                  {membership.map((_, i) => <li key={i}>{_}</li>)}
                </ul>
              )
            })}
          </InfoSection>
        )}

        {partnership && (
          <InfoSection title={t('partnership.title')}>
            {t('partnership.name', {
              list: institution.name
            })}
            {' '}
            {partnership.length === 1 && partnership[0]}
            {partnership.length > 1 && t('organizationsList', {
              list: () => (
                <ul className={styles.list}>
                  {partnership.map((_, i) => <li key={i}>{_}</li>)}
                </ul>
              )
            })}
          </InfoSection>
        )}
      </ContentSection>

      <br/>
      <hr/>
      <br/>

      <ContentSection
        fullWidth
        padding={false}
        border={false}
        title={t('contact')}
      >
        <HStack gap="xl" align="start">
          <ContactInfo
            contact={institution.primaryContact}
            title={t('primaryContact')}
          />
          {institution.enrollingStudentContact && (
            <ContactInfo
              contact={institution.enrollingStudentContact}
              title={t('enrollingStudentContact')}
            />
          )}
          {institution.teachingStudentContact && (
            <ContactInfo
              contact={institution.teachingStudentContact}
              title={t('teachingStudentContact')}
            />
          )}
        </HStack>
      </ContentSection>

      {institution.teachingStudentWelcomeLetter && (
        <>
          <br/>
          <hr/>
          <br/>
          <ContentSection
            fullWidth
            padding={false}
            border={false}
            title={t('welcomeLetter')}
          >
            <WelcomeLetter
              mode="institution"
              welcomeLetter={institution.teachingStudentWelcomeLetter}
            />
          </ContentSection>
        </>
      )}

      {institution.description && (
        <>
          <br/>
          <hr/>
          <br/>
          <ContentSection
            fullWidth
            padding={false}
            border={false}
            title={t('description')}
          >
            <Text>
              {institution.description}
            </Text>
          </ContentSection>
        </>
      )}
    </>
  );
};

export default GeneralInfo;
