import { unflattenObject } from '@acadeum/helpers';
import type { RtkApi } from '@acadeum/redux';
import type {
  Course,
  CourseEnrollment,
  CourseSection,
  CourseSubstitute,
  EquivalentCourse,
  Id,
  Institution,
  PaginatedResponse,
  Session,
  SortByState,
  Student,
  User
} from '@acadeum/types';

import {
  ACTION_REQUIRED_COUNTS_ENDPOINTS_TAG_TYPES,
  COURSE_ENROLLMENT_ENDPOINTS_TAG_TYPES,
  COURSE_ENROLLMENT_REQUEST_ENDPOINTS_TAG_TYPES
} from '../consts';

export interface AdminFetchCourseEnrollmentInput {
  id: Id;
}

export interface AdminFetchCourseEnrollmentOutput extends Pick<CourseEnrollment, 'id' | 'status' | 'type' | 'createdAt' | 'letterGrade'> {
  section: Pick<CourseSection, 'id' | 'number'> & {
    session: Pick<Session,
      'id'
      | 'name'
      | 'term'
      | 'cost'
      | 'startDate'
      | 'endDate'
      | 'lastAddDate'
      | 'lastDropDate'
    > & {
      course: Pick<Course, 'id' | 'code' | 'title'> & {
        institution: Pick<Institution, 'id' | 'name' | 'vanityUrl'> & {
          logo: Institution['logoUrl'] // TODO: Remove `logo`
        }
      }
    }
  };
  student: Pick<Student, 'id' | 'email' | 'hiStudentId' | 'firstName' | 'middleName' | 'lastName'> & {
    institution: Pick<Institution, 'id' | 'name' | 'vanityUrl'> & {
      logo: Institution['logoUrl'] // TODO: Remove `logo`
    }
  };
}

export interface AdminFetchCourseEnrollmentsInput {
  page?: number;
  pageSize?: number;
  filters?: object;
  sort?: { by: string, asc: boolean }[];
}

export type AdminFetchCourseEnrollmentsOutput = PaginatedResponse<Pick<CourseEnrollment,
  'id' |
  'status' |
  'type' |
  'costForStudent' |
  'paid' |
  'refunded' |
  'letterGrade' |
  'enrollReason' |
  'enrollReasonNotes' |
  'studentDropReason' |
  'studentDropReasonNotes' |
  'studentWithdrawReason' |
  'studentWithdrawReasonNotes' |
  'homeDropReason' |
  'homeDropReasonNotes' |
  'homeWithdrawReason' |
  'homeWithdrawReasonNotes' |
  'teachingDropReason' |
  'teachingDropReasonNotes' |
  'teachingWithdrawReason' |
  'teachingWithdrawReasonNotes' |
  'denyReason' |
  'denyReasonNotes' |
  'removeReason' |
  'removeReasonNotes' |
  'createdAt' |
  'startedAt' |
  'endedAt'
> & {
  paymentChargeId?: string;
  costForInstitution: number;
  costForInstitutionOriginal?: number;
  costForInstitutionChangeReasonNotes?: string;
  student: Pick<Student,
    'id' |
    'hiStudentId' |
    'firstName' |
    'middleName' |
    'lastName' |
    'email' |
    'phone' |
    'advisorName' |
    'advisorEmail'
  > & {
    institution: Pick<Institution, 'id' | 'name' | 'vanityUrl' | 'logoUrl' | 'onDemandCourseDropDateDaysAfterStartDate' | 'onDemandCourseEndDateDaysAfterStartDate'>
  };
  section: Pick<CourseSection, 'id' | 'number'> & {
    session: Pick<Session, 'id' | 'term' | 'cost' | 'startDate' | 'endDate' | 'lastAddDate' | 'lastDropDate' | 'name'> & {
      course: Pick<Course, 'id' | 'code' | 'title' | 'level' | 'requiredTexts' | 'onDemand'> & {
        category: string;
        pathToSyllabus?: string;
        institution: Pick<Institution, 'id' | 'name' | 'vanityUrl' | 'logoUrl' | 'onDemandCourseDropDateDaysAfterStartDate' | 'onDemandCourseEndDateDaysAfterStartDate'>
      }
    }
  };
  courseSubstitute: CourseSubstitute;
}>;

export interface AdminChangeCourseEnrollmentStatusInput {
  id: Id;
  status: CourseEnrollment['status'];
}

export interface AdminMarkCourseEnrollmentAsConsortialInput {
  ids: Id[];
}

export interface AdminMarkCourseEnrollmentAsUnpaidInput {
  ids: Id[];
}

export interface FetchCourseEnrollmentsPropertyValuesInput {
  mode: 'homeInstitution' | 'teachingInstitution';
  filters?: {
    status?: CourseEnrollment['status'][];
    hasLetterGrade?: boolean;
  };
}

export interface FetchCourseEnrollmentsPropertyValuesOutput {
  letterGrade: string[];
  course: Pick<Course, 'id' | 'code' | 'title'>[];
  homeInstitutionIds: Id[];
  teachingInstitutionIds: Id[];
  section: {
    code: string[];
    term: string[];
    session: string[];
  };
}

export interface DropStudentsFromCourseHomeInput {
  ids: number[];
  reason: string;
  reasonNotes: string;
}

export interface WithdrawStudentsFromCourseHomeInput {
  ids: number[];
  reason: string;
  reasonNotes: string;
}

export interface RemoveStudentsFromCourseHomeInput {
  ids: number[];
  reason: string;
  reasonNotes: string;
}

export interface ApproveCourseEnrollmentInput {
  ids: Id[];
  enrollmentRequestId: Id;
}

export interface DenyCourseEnrollmentInput {
  ids: Id[];
  enrollmentRequestId: Id;
  reason: string;
  reasonNotes: string;
}

export interface CompleteCourseEnrollmentInput {
  ids: Id[];
  grades?: {
    letterGrade: string;
    numericalGrade: number;
    gradeNotes?: string;
  };
}

export interface WithdrawCourseEnrollmentInput {
  ids: Id[];
  grades: {
    letterGrade: string;
    numericalGrade: number;
    gradeNotes?: string;
  };
  reason: string;
  reasonNotes?: string;
}

export interface DropCourseEnrollmentInput {
  ids: Id[];
  reason: string;
  reasonNotes: string;
}

export interface FetchCourseEnrollmentInput {
  id: Id;
  require?: ('grade')[];
  include?: ('grade' | 'teachingInstitutionStudentContact')[];
  mode: 'homeInstitution' | 'teachingInstitution';
}

export interface FetchCourseEnrollmentOutput extends Pick<CourseEnrollment, 'id' | 'type' | 'letterGrade' | 'numericalGrade' | 'gradeNotes'> {
  student: Pick<Student, 'id' | 'hiStudentId' | 'firstName' | 'middleName' | 'lastName' | 'email'> & {
    institution: Pick<Institution, 'id' | 'name' | 'vanityUrl'>
  };
  section: Pick<CourseSection, 'id' | 'term' | 'session' | 'lastAddDate' | 'lastDropDate' | 'startDate' | 'endDate' | 'lastWithdrawalDate'> & {
    course: Pick<Course, 'id' | 'code' | 'title'> & {
      institution: Pick<Institution, 'id' | 'name' | 'vanityUrl'> & {
        gradingScale?: string;
        gradingScaleUrl?: string;
        gradingScaleNotes?: string;
      }
    }
  };
  teachingInstitutionStudentContact: Pick<User, 'firstName' | 'lastName' | 'email' | 'title' | 'phone' | 'phoneExt'>;
}

export interface UpdateTeachingCourseEnrollmentGradeInput {
  id: Id;
  letterGrade: string;
  numericalGrade?: number;
  gradeNotes?: string;
}

export interface FetchCourseEnrollmentsInput {
  page?: number;
  pageSize?: number;
  search?: string;
  export?: boolean;
  mode: 'homeInstitution' | 'teachingInstitution';
  filters?: {
    id?: Id | Id[];
    status?: CourseEnrollment['status'][];
    hasGrade?: boolean;
    letterGrade?: string;
    student?: {
      institution?: {
        id?: Id;
      }
    }
    enrollmentRequest?: {
      section?: {
        code?: string;
        session?: string;
        term?: string;
        course?: {
          id?: Id;
          institution?: {
            id?: Id;
          }
        }
      }
    }
  };
  sort?: SortByState<
    'type'
    | 'status'
    | 'student.name'
    | 'student.institution.name'
    | 'enrollmentRequest.section.code'
    | 'enrollmentRequest.section.term'
    | 'enrollmentRequest.section.session'
    | 'enrollmentRequest.section.course.code'
    | 'enrollmentRequest.section.course.title'
    | 'enrollmentRequest.section.course.hours'
    | 'enrollmentRequest.section.course.institution.name'
  >;
}

export interface FetchCourseEnrollmentsOutputItem extends Pick<CourseEnrollment,
  // Properties that included always in the response.
  'id'
  | 'status'
  | 'type'
  | 'createdAt'
  | 'startedAt'
  | 'endedAt'
  | 'letterGrade'
  | 'numericalGrade'
  // Properties that included only when `export` is `true`.
  | 'enrollReason'
  | 'enrollReasonNotes'
  | 'studentDropReason'
  | 'studentDropReasonNotes'
  | 'studentWithdrawReason'
  | 'studentWithdrawReasonNotes'
  | 'homeDropReason'
  | 'homeDropReasonNotes'
  | 'homeWithdrawReason'
  | 'homeWithdrawReasonNotes'
  | 'teachingDropReason'
  | 'teachingDropReasonNotes'
  | 'teachingWithdrawReason'
  | 'teachingWithdrawReasonNotes'
  | 'denyReason'
  | 'denyReasonNotes'
  | 'removeReason'
  | 'removeReasonNotes'
  | 'gradeNotes'
  | 'paid'
  | 'refunded'
  | 'costForInstitution'
  | 'costForStudent'
  | 'costForStudentIsEstimated'
> {
  // `homeInstitution` included only when `mode: "teachingInstitution"`.
  homeInstitution?: Pick<Institution, 'id' | 'name' | 'logoUrl' | 'vanityUrl'>;
  student?: Pick<Student,
    // Properties that included always in the response.
    'id'
    | 'hiStudentId'
    | 'firstName'
    | 'middleName'
    | 'lastName'
    | 'email'
    // Properties that included only when `export` is `true`.
  > & Partial<Pick<Student,
    'phone'
    | 'addressLine1'
    | 'addressLine2'
    | 'city'
    | 'state'
    | 'country'
    | 'postalCode'
    | 'major'
    | 'residency'
    | 'level'
    | 'gender'
    | 'races'
    | 'ethnicity'
    | 'dob'
    | 'citizenship'
    | 'startDate'
    | 'advisorName'
    | 'advisorEmail'>>;
  // `enrollmentRequest.courseSubstitute` included only when `mode: 'homeInstitution'` and `export` is `true`.
  courseSubstitute?: Pick<CourseSubstitute, 'id'> & {
    equivalentCourse?: Pick<EquivalentCourse, 'code' | 'title'>
  };
  section: Pick<CourseSection,
    // Properties that included always in the response.
    'id'
    | 'code'
    | 'term'
    | 'session'
    | 'cost'
    | 'startDate'
    | 'endDate'
    | 'lastAddDate'
    | 'lastDropDate'
    | 'lastWithdrawalDate'
    // Properties that included only when `export` is `true`.
  > & Partial<Pick<CourseSection, 'internalTerm' | 'internalSession'>> & {
    course: Pick<Course,
      //   Properties that included always in the response.
      'id'
      | 'code'
      | 'title'
      | 'hours'
      | 'onDemand'
    > & {
      institution: Pick<Institution,
        // Properties that included always in the response.
        'id'
        | 'onDemandCourseDropDateDaysAfterStartDate'
        | 'onDemandCourseEndDateDaysAfterStartDate'
        // Properties that included only when `mode: "homeInstitution"`.
        | 'name'
        | 'logoUrl'
        | 'vanityUrl'
      >
    }
  };
}

export type FetchCourseEnrollmentsOutput = PaginatedResponse<FetchCourseEnrollmentsOutputItem>

export const injectCourseEnrollmentEndpoints = (rtkApi: RtkApi) =>
  rtkApi
    .enhanceEndpoints({
      addTagTypes: [
        ...COURSE_ENROLLMENT_ENDPOINTS_TAG_TYPES,
        ...ACTION_REQUIRED_COUNTS_ENDPOINTS_TAG_TYPES,
        ...COURSE_ENROLLMENT_REQUEST_ENDPOINTS_TAG_TYPES
      ]
    })
    .injectEndpoints({
      endpoints: (build) => ({
        fetchCourseEnrollments: build.query<FetchCourseEnrollmentsOutput, FetchCourseEnrollmentsInput>({
          query: (params_) => {
            const params = structuredClone(params_);
            if (params?.filters) {
              params.filters = unflattenObject(params.filters);
              delete params?.filters?.['status_'];
            }
            return {
              url: '/course-enrollments',
              params
            };
          },
          providesTags: [{ type: 'courseEnrollment', id: 'LIST' }]
        }),
        fetchCourseEnrollment: build.query<FetchCourseEnrollmentOutput, FetchCourseEnrollmentInput>({
          query: ({ id, ...params }) => {
            return {
              url: `/course-enrollments/${id}`,
              params
            };
          },
          providesTags: (result, error, arg) => [{ type: 'courseEnrollment', id: arg.id }]
        }),
        fetchCourseEnrollmentsPropertyValues: build.query<FetchCourseEnrollmentsPropertyValuesOutput, FetchCourseEnrollmentsPropertyValuesInput>({
          query: (params) => {
            return {
              url: '/course-enrollments/property-values',
              params
            };
          },
          providesTags: [{ type: 'courseEnrollment', id: 'PROPERTY_VALUES' }]
        }),

        // Acadeum Admin Endpoints
        adminFetchEnrollment: build.query<AdminFetchCourseEnrollmentOutput, AdminFetchCourseEnrollmentInput>({
          query: (params) => ({
            url: '/enrollmentrequeststudents/admin/read',
            params
          }),
          providesTags: (result, error, arg) => [{ type: 'adminCourseEnrollment', id: arg.id }]
        }),
        adminFetchCourseEnrollments: build.query<AdminFetchCourseEnrollmentsOutput, AdminFetchCourseEnrollmentsInput>({
          query: (params) => ({
            url: '/admin/course-enrollments',
            params
          }),
          providesTags: [{ type: 'adminCourseEnrollment', id: 'LIST' }]
        }),
        adminChangeCourseEnrollmentStatus: build.mutation<void, AdminChangeCourseEnrollmentStatusInput>({
          query: body => ({
            url: '/enrollmentrequeststudents/admin/changestatus',
            method: 'PUT',
            body
          }),
          invalidatesTags: (result, error, arg) => {
            return [
              { type: 'actionRequiredCounts', id: 'COUNTS' },
              { type: 'adminCourseEnrollment', id: arg.id },
              { type: 'courseEnrollment', id: 'LIST' },
              { type: 'courseEnrollment', id: arg.id }
            ];
          }
        }),
        adminMarkCourseEnrollmentAsConsortial: build.mutation<void, AdminMarkCourseEnrollmentAsConsortialInput>({
          query: (body) => ({
            url: '/enrollmentrequeststudents/admin/markConsortial',
            method: 'POST',
            body
          }),
          invalidatesTags: (result, error, arg) => {
            return [
              { type: 'courseEnrollment', id: 'LIST' },
              ...(arg.ids.flatMap(id => [
                { type: 'adminCourseEnrollment', id },
                { type: 'courseEnrollment', id }
              ]))
            ];
          }
        }),
        adminMarkCourseEnrollmentAsUnpaid: build.mutation<void, AdminMarkCourseEnrollmentAsUnpaidInput>({
          query: body => ({
            url: '/enrollments/mark-unpaid',
            method: 'POST',
            body
          }),
          invalidatesTags: (result, error, arg) => {
            return [
              { type: 'courseEnrollment', id: 'LIST' },
              ...(arg.ids.flatMap(id => [
                { type: 'adminCourseEnrollment', id },
                { type: 'courseEnrollment', id }
              ]))
            ];
          }
        }),

        // Home Course Enrollments
        dropStudentsFromHomeCourse: build.mutation<void, DropStudentsFromCourseHomeInput>({
          query: (body) => ({
            url: '/enrollmentrequeststudents/em/drop',
            method: 'PUT',
            body
          }),
          async onQueryStarted(arg) {
            Intercom?.('trackEvent', 'Drop students from course (EM)', {
              ids: JSON.stringify(arg.ids),
              reason: arg.reason,
              reasonNotes: arg.reasonNotes
            });
          },
          invalidatesTags: (result, error, arg) => [
            { type: 'actionRequiredCounts', id: 'COUNTS' },
            { type: 'courseEnrollment', id: 'LIST' },
            ...(arg.ids.flatMap(id => [
              { type: 'adminCourseEnrollment', id },
              { type: 'courseEnrollment', id }
            ]))
          ]
        }),
        withdrawStudentsFromHomeCourse: build.mutation<void, WithdrawStudentsFromCourseHomeInput>({
          query: (body) => ({
            url: '/enrollmentrequeststudents/em/withdraw',
            method: 'PUT',
            body
          }),
          async onQueryStarted(arg) {
            Intercom?.('trackEvent', 'Withdraw students from course (EM)', {
              ids: JSON.stringify(arg.ids),
              reason: arg.reason,
              reasonNotes: arg.reasonNotes
            });
          },
          invalidatesTags: (result, error, arg) => [
            { type: 'actionRequiredCounts', id: 'COUNTS' },
            { type: 'courseEnrollment', id: 'LIST' },
            ...(arg.ids.flatMap(id => [
              { type: 'adminCourseEnrollment', id },
              { type: 'courseEnrollment', id }
            ]))
          ]
        }),
        removeStudentsFromHomeCourse: build.mutation<void, RemoveStudentsFromCourseHomeInput>({
          query: (body) => ({
            url: '/enrollmentrequeststudents/em/remove',
            method: 'PUT',
            body
          }),
          async onQueryStarted(arg) {
            Intercom?.('trackEvent', 'Remove students from course', {
              ids: JSON.stringify(arg.ids),
              reason: arg.reason,
              reasonNotes: arg.reasonNotes
            });
          },
          invalidatesTags: (result, error, arg) => [
            { type: 'actionRequiredCounts', id: 'COUNTS' },
            { type: 'courseEnrollment', id: 'LIST' },
            ...(arg.ids.flatMap(id => [
              { type: 'adminCourseEnrollment', id },
              { type: 'courseEnrollment', id }
            ]))
          ]
        }),
        approveTeachingCourseEnrollment: build.mutation<void, ApproveCourseEnrollmentInput>({
          query: (body) => ({
            url: '/enrollmentrequeststudents/tm/approve',
            method: 'PUT',
            body
          }),
          onQueryStarted(arg) {
            Intercom?.('trackEvent', 'Approve student enrollments', {
              ids: JSON.stringify(arg.ids),
              enrollmentRequestId: arg.enrollmentRequestId
            });
          },
          invalidatesTags: (result, error, arg) => [
            { type: 'actionRequiredCounts', id: 'COUNTS' },
            { type: 'teachingCourseEnrollmentRequests', id: 'LIST' },
            { type: 'teachingCourseEnrollmentRequests', id: arg.enrollmentRequestId },
            { type: 'courseEnrollment', id: 'LIST' },
            ...(arg.ids.flatMap(id => [
              { type: 'adminCourseEnrollment', id },
              { type: 'courseEnrollment', id }
            ]))
          ]
        }),
        denyTeachingCourseEnrollment: build.mutation<void, DenyCourseEnrollmentInput>({
          query: (body) => ({
            url: '/enrollmentrequeststudents/tm/deny',
            method: 'PUT',
            body
          }),
          onQueryStarted(arg) {
            Intercom?.('trackEvent', 'Deny student enrollments', {
              ids: JSON.stringify(arg.ids),
              enrollmentRequestId: arg.enrollmentRequestId,
              reason: arg.reason,
              reasonNotes: arg.reasonNotes
            });
          },
          invalidatesTags: (result, error, arg) => [
            { type: 'actionRequiredCounts', id: 'COUNTS' },
            { type: 'teachingCourseEnrollmentRequests', id: 'LIST' },
            { type: 'teachingCourseEnrollmentRequests', id: arg.enrollmentRequestId },
            { type: 'courseEnrollment', id: 'LIST' },
            ...(arg.ids.flatMap(id => [
              { type: 'adminCourseEnrollment', id },
              { type: 'courseEnrollment', id }
            ]))
          ]
        }),
        completeTeachingCourseEnrollment: build.mutation<void, CompleteCourseEnrollmentInput>({
          query: (body) => ({
            url: '/enrollmentrequeststudents/tm/complete',
            method: 'PUT',
            body
          }),
          onQueryStarted(arg) {
            Intercom?.('trackEvent', 'Complete student enrollments', {
              ids: JSON.stringify(arg.ids),
              grades: JSON.stringify(arg.grades)
            });
          },
          invalidatesTags: (result, error, arg) => [
            { type: 'actionRequiredCounts', id: 'COUNTS' },
            { type: 'courseEnrollment', id: 'LIST' },
            ...(arg.ids.flatMap(id => [
              { type: 'adminCourseEnrollment', id },
              { type: 'courseEnrollment', id }
            ]))
          ]
        }),
        withdrawTeachingCourseEnrollment: build.mutation<void, WithdrawCourseEnrollmentInput>({
          query: (body) => ({
            url: '/enrollmentrequeststudents/tm/withdraw',
            method: 'PUT',
            body
          }),
          onQueryStarted(arg) {
            Intercom?.('trackEvent', 'Withdraw students from course (TM)', {
              ids: JSON.stringify(arg.ids),
              grades: JSON.stringify(arg.grades),
              reason: arg.reason,
              reasonNotes: arg.reasonNotes
            });
          },
          invalidatesTags: (result, error, arg) => [
            { type: 'actionRequiredCounts', id: 'COUNTS' },
            { type: 'courseEnrollment', id: 'LIST' },
            ...(arg.ids.flatMap(id => [
              { type: 'adminCourseEnrollment', id },
              { type: 'courseEnrollment', id }
            ]))
          ]
        }),
        dropTeachingCourseEnrollment: build.mutation<void, DropCourseEnrollmentInput>({
          query: (body) => ({
            url: '/enrollmentrequeststudents/tm/drop',
            method: 'PUT',
            body
          }),
          onQueryStarted(arg) {
            Intercom?.('trackEvent', 'Drop students from course (TM)', {
              ids: JSON.stringify(arg.ids),
              reason: arg.reason,
              reasonNotes: arg.reasonNotes
            });
          },
          invalidatesTags: (result, error, arg) => [
            { type: 'actionRequiredCounts', id: 'COUNTS' },
            { type: 'courseEnrollment', id: 'LIST' },
            ...(arg.ids.flatMap(id => [
              { type: 'adminCourseEnrollment', id },
              { type: 'courseEnrollment', id }
            ]))
          ]
        }),

        // Grades
        updateTeachingCourseEnrollmentGrade: build.mutation<void, UpdateTeachingCourseEnrollmentGradeInput>({
          query: ({ id, ...rest }) => ({
            url: `/grades/${id}`,
            method: 'PUT',
            body: rest
          }),
          onQueryStarted: () => {
            Intercom?.('trackEvent', 'edit grades');
          },
          invalidatesTags: (result, error, arg) => [
            { type: 'actionRequiredCounts', id: 'COUNTS' },
            { type: 'courseEnrollmentGrade', id: arg.id },
            { type: 'adminCourseEnrollment', id: arg.id },
            { type: 'courseEnrollment', id: arg.id }
          ]
        })
      })
    });
