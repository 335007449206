import PropTypes from 'prop-types';
import React from 'react';

import formatDate from 'common-lib/lib/formatDate.js';

import type { Locale } from '@acadeum/types';

export interface FormatDateProps extends Omit<React.HTMLAttributes<HTMLTimeElement>, 'children'> {
  day?: 'numeric';
  month?: 'numeric' | 'short' | 'long';
  year?: 'numeric' | false;
  locale?: Locale;
  date?: Date | number;
  utc: boolean;
  time?: boolean;
  weekday?: 'short';
  children?: (date: string) => React.ReactNode;
}

const propTypes = {
  day: PropTypes.oneOf(['numeric']),
  month: PropTypes.oneOf(['numeric', 'short', 'long']),
  weekday: PropTypes.oneOf(['short']),
  year: PropTypes.oneOf(['numeric', false]),
  locale: PropTypes.oneOf(['en']),
  date: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.number
  ]),
  utc: PropTypes.bool.isRequired,
  time: PropTypes.bool,
  children: PropTypes.func
};

export const FormatDate = ({
  day = 'numeric',
  month = 'numeric',
  year = 'numeric',
  locale = 'en',
  date,
  utc,
  time,
  weekday,
  children,
  ...rest
}: FormatDateProps) => {
  if (!date) {
    return null;
  }

  // Convert a number (timestamp) to a `Date`.
  date = typeof date === 'number' ? new Date(date) : date;

  const formattedDate = formatDate(date, { day, month, year, locale, utc, time, weekday });

  return (
    <time
      dateTime={date.toISOString()}
      title={date.toString()}
      {...rest}>
      {typeof children === 'function' ? children(formattedDate) : formattedDate}
    </time>
  );
};

FormatDate.displayName = 'FormatDate';
FormatDate.propTypes = propTypes;
