import classNames from 'classnames';
import * as React from 'react';

import styles from './Pagination.module.scss';

const Pagination = ({
  className,
  ...props
}: React.ComponentProps<'nav'>) => (
  <nav
    role="navigation"
    aria-label="pagination"
    className={classNames(className)}
    {...props}
  />
);
Pagination.displayName = 'Pagination';

const PaginationContent = React.forwardRef<
  HTMLUListElement,
  React.ComponentProps<'ul'>
>(({
  className,
  ...props
}, ref) => (
  <ul
    ref={ref}
    className={classNames(styles.reset, className)}
    {...props}
  />
));
PaginationContent.displayName = 'PaginationContent';

const PaginationItem = React.forwardRef<
  HTMLLIElement,
  React.ComponentProps<'li'>
>(({
  className,
  ...props
}, ref) => (
  <li
    ref={ref}
    className={classNames(styles.reset, className)}
    {...props}
  />
));
PaginationItem.displayName = 'PaginationItem';

type PaginationLinkProps = {
  active?: boolean;
  disabled?: boolean;
} & React.ComponentProps<'a'>

const PaginationLink = ({
  active,
  disabled,
  className: propsClassName,
  ...props
}: PaginationLinkProps) => {
  const className = classNames(propsClassName);
  if (disabled) {
    return (
      <span
        aria-disabled
        aria-hidden
        className={className}
        {...props}
      />
    );
  }

  return (
    <a
      aria-current={active ? 'page' : undefined}
      className={className}
      {...props}
    />
  );
};
PaginationLink.displayName = 'PaginationLink';

const PaginationPrevious = (props: React.ComponentProps<typeof PaginationLink>) => (
  <PaginationLink
    aria-label="Go to previous page"
    {...props}
  />
);
PaginationPrevious.displayName = 'PaginationPrevious';

const PaginationNext = (props: React.ComponentProps<typeof PaginationLink>) => (
  <PaginationLink
    aria-label="Go to next page"
    {...props}
  />
);
PaginationNext.displayName = 'PaginationNext';

const PaginationEllipsis = (props: React.ComponentProps<'span'>) => (
  <span
    aria-hidden
    title="More pages"
    {...props}
  />
);
PaginationEllipsis.displayName = 'PaginationEllipsis';

export {
  Pagination,
  PaginationContent,
  PaginationLink,
  PaginationItem,
  PaginationPrevious,
  PaginationNext,
  PaginationEllipsis
};
