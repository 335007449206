import type { FC } from 'react';
import React, { useMemo, useState } from 'react';

import { HStack } from '@acadeum/core-ui';
import { FilterIcon } from '@acadeum/icons';
import type { IconSource } from '@acadeum/types';

import { Button } from '../Button';
import type { CheckboxProps } from '../Checkbox';
import { Checkbox } from '../Checkbox';
import { Icon } from '../Icon';
import { Popover, PopoverContent, PopoverTrigger } from '../Popover';
import { Text } from '../Text';

import styles from './Filter.module.scss';

interface FilterOption {
  label: string;
  name: string;
  disabled?: boolean;
  type?: CheckboxProps['type'];
  icon?: IconSource;
  checked?: boolean;
}

export interface FilterProps {
  options: FilterOption[];
  onResetShownFilters: () => void;
  onToggleShowFilter: (name: string) => void;
}

export const Filter: FC<FilterProps> = ({
  options,
  onToggleShowFilter,
  onResetShownFilters
}) => {
  const [show, setShow] = useState(false);

  const renderedOptions = useMemo(() => options.map(option => {
    const {
      disabled,
      name,
      checked,
      type = 'checkbox',
      label,
      icon
    } = option;

    return (
      <div key={name} className={styles.item}>
        <Checkbox
          name={name}
          type={type}
          disabled={disabled}
          checked={Boolean(checked)}
          onChange={() => onToggleShowFilter(name)}
          label={(
            <HStack as="span" gap="sm">
              <Icon icon={icon} size="md"/>
              {label}
            </HStack>
          )}
        />
      </div>
    );
  }), [
    options,
    onToggleShowFilter
  ]);

  const countEnabledCheckedOptions = useMemo(() => {
    return options.filter(option => option.disabled ? false : Boolean(option.checked)).length;
  }, [options]);

  return (
    <Popover open={show} onOpenChange={setShow}>
      <PopoverTrigger asChild>
        <Button
          icon={FilterIcon}
          variant="tertiary"
        >
          More Filters
          {countEnabledCheckedOptions > 0 && (
            <span className={styles.count}>
              {countEnabledCheckedOptions}
            </span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent align="end" className={styles.content}>
        <div className={styles.header}>
          <Text color="black">
            Filters
          </Text>
          <Button
            ml="auto"
            variant="secondary"
            size="small"
            onClick={onResetShownFilters}
          >
            Clear
          </Button>
        </div>
        <div className={styles.list}>
          {renderedOptions}
        </div>
      </PopoverContent>
    </Popover>
  );
};
