import React from 'react';
import { useSelector } from 'react-redux';

import SectionTab from '../SectionTab';
import Tabs from '../Tabs';


export default function CourseRegistrationRequestsNav() {
  const tabs = CourseRegistrationRequestsNav.SECTIONS;

  return (
    <Tabs>
      {tabs.map(_ => <SectionTab key={_.key} section={_}/>)}
    </Tabs>
  );
}

CourseRegistrationRequestsNav.SECTIONS = [
  {
    key: 'new',
    title: 'New',
    link: '/course-registration-requests/new',
    alias: '/course-registration-requests',
    // eslint-disable-next-line react-hooks/rules-of-hooks
    count: () => useSelector(state => state.studentRequests.newStudentRequestsCount),
    // eslint-disable-next-line react-hooks/rules-of-hooks
    notificationsCount: () => useSelector(state => state.studentRequests.newStudentRequestsCount)
  },
  {
    key: 'finalized',
    title: 'Finalized',
    link: '/course-registration-requests/finalized',
    // eslint-disable-next-line react-hooks/rules-of-hooks
    count: () => useSelector(state => state.studentRequests.finalizedStudentRequestsCount)
  }
];
