import PropTypes from 'prop-types';
import React, { useCallback, useRef } from 'react';

import { useTranslate } from '@acadeum/translate';

import Button from '../Button';
import Form from '../Form';
import FormDescription from '../FormDescription';
import FormFooter from '../FormFooter';
import FormHeader from '../FormHeader';
import FormSubmit from '../FormSubmit';
import FormTitle from '../FormTitle';
import Modal, { waitForModalToClose } from '../Modal';

export default function FormModal({
  title,
  description,
  onSubmit,
  onCancel,
  submitText,
  onHide,
  onAfterSubmit,
  autoFocus,
  children,
  ...rest
}) {
  const t = useTranslate();
  const form = useRef();

  const onSubmit_ = useCallback(async (values) => {
    const result = await onSubmit(values);
    onHide();
    if (onAfterSubmit) {
      await waitForModalToClose();
      onAfterSubmit(result);
    }
  }, [
    onSubmit,
    onAfterSubmit
  ]);

  const header = (
    <FormHeader>
      <FormTitle>
        {title}
      </FormTitle>
      {description &&
        <FormDescription>
          {description}
        </FormDescription>
      }
    </FormHeader>
  );

  const footer = (
    <FormFooter>
      <FormFooter.Actions>
        {onCancel && (
          <Button
            onClick={onCancel}
            type="button"
            secondary
            border
          >
            {t('cancel', { global: true })}
          </Button>
        )}
        <FormSubmit>
          {submitText}
        </FormSubmit>
      </FormFooter.Actions>
    </FormFooter>
  );

  return (
    <Modal onHide={onHide} {...rest}>
      <Form
        ref={form}
        autoFocus={autoFocus}
        onSubmit={onSubmit_}>
        {typeof children === 'function'
          ? (args) => (
            <>
              {header}
              {children(args)}
              {footer}
            </>
          ) : (
            <>
              {header}
              {children}
              {footer}
            </>
          )
        }
      </Form>
    </Modal>
  );
}

FormModal.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.node, // .isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func
  ]).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  submitText: PropTypes.string.isRequired,
  onAfterSubmit: PropTypes.func,
  onHide: PropTypes.func.isRequired,
  autoFocus: PropTypes.bool
};
