import {
  getInstitutionInputParams as getInstitutionInputParams_
} from '@acadeum/helpers';

import {
  getMemberInstitutionOptionByValue,
  getMemberInstitutionOptionsByName
} from '../../../helpers/memberInstitutions';


export function getInstitutionInputParamsForReactResponsiveUIAutocomplete(parameters) {
  const { fetchOptions, findOptionByValue } = getInstitutionInputParams(parameters);

  return {
    async: true,
    getOptions: fetchOptions,
    getOption: findOptionByValue
  };
}

export function getInstitutionInputParams(parameters) {
  return getInstitutionInputParams_({
    ...parameters,
    getMemberInstitutionOptionByValue,
    getMemberInstitutionOptionsByName
  });
}
