import type { OnChangeFn, RowData, RowSelectionState } from '@tanstack/react-table';
import { flatten } from 'lodash-es';
import React, { useCallback, useMemo, useState } from 'react';

import { queryTypes } from '@acadeum/helpers';

import { ClientFilters } from './ClientFilters';
import { isFilterItemObject } from './helpers';
import type { FilterItem } from './types';

interface UseFiltersOptions<TData extends RowData> {
  onRowSelectionChange: OnChangeFn<RowSelectionState>;
  rows: TData[];
  filters?: FilterItem<TData>[] | FilterItem<TData>[][];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useQueryStates?: any;
}

export const useClientFilters = <TData extends RowData>({
  filters: filters_,
  rows,
  onRowSelectionChange,
  useQueryStates
}: UseFiltersOptions<TData>) => {
  const filters = filters_ && flatten(filters_);
  const initialValue = useMemo(() => {
    if (useQueryStates && filters) {
      return filters.reduce((res, item) => {
        if (isFilterItemObject(item)) {
          return { ...res, [item.id]: queryTypes.json() };
        }
        return res;
      }, {});
    }
    return {};
  }, [filters, useQueryStates]);

  const useStateHook = useMemo(() => useQueryStates || useState, [useQueryStates]);

  const [filterValues, setFilterValues] = useStateHook(initialValue);

  const filteredRows = useMemo(() => {
    if (!filters) {
      return rows;
    }

    return rows.filter(row => {
      for (const filter of filters) {
        if (!isFilterItemObject(filter)) {
          continue;
        }

        const value = filterValues[filter.id]?.value;
        if (value !== undefined && value !== null && !filter.filter(row, value)) {
          return false;
        }
      }
      return true;
    });
  }, [rows, filterValues]);

  const renderFilters = useCallback(() => {
    if (!filters_) {
      return null;
    }

    return (
      <ClientFilters
        filters={filters_}
        filterValues={filterValues}
        setFilterValues={setFilterValues}
        allRows={rows}
      />
    );
  }, [filters, filterValues, setFilterValues, rows]);

  return {
    renderFilters,
    filteredRows,
    filterValues,
    setFilterValues: (filters) => {
      setFilterValues(filters);
      onRowSelectionChange({});
    }
  };
};
