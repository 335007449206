import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import actions from '../../actions';
import FormField from '../FormField';
import FormModal from '../FormModal';


const {
  getStripeTermsOfServiceAcceptedStatus,
  acceptStripeTermsOfService
} = actions;

// need a place to store data on agreement acceptance
// need to setup redux async checks
// need to setup endpoints

export default function StripeConnectAgreementModal() {
  const [refresh, setRefresh] = useState();

  useEffect(() => {
    getStripeTermsOfServiceAcceptedStatus();
  }, [refresh]);

  const termsOfService = useSelector(state => state.stripe.termsOfService);

  const onSubmit = async () => {
    await acceptStripeTermsOfService();
    setRefresh({});
  };

  if (!termsOfService) {
    return null;
  }

  if (typeof termsOfService == 'string') {
    return null;
  }

  return (
    <FormModal
      show={!termsOfService.date}
      onHide={() => {}}
      closeIcon={false}
      title="In Order to Proceed…"
      description="You must agree to Stripe Services Agreement and Stripe Connected Account Agreement before using Acadeum Course Share."
      submitText="Agree"
      onSubmit={onSubmit}>
      <FormField
        required
        multiline
        type="checkbox"
        name="servicesAgreement"
        label={(
          <>
            I agree to
            {' '}
            <a target="_blank" href="https://stripe.com/legal">
              Stripe Services Agreement
            </a>
          </>
        )}
      />
      <FormField
        required
        multiline
        type="checkbox"
        name="connectedAccountAgreement"
        label={(
          <>
            I agree to
            {' '}
            <a target="_blank" href="https://stripe.com/connect-account/legal">
              Stripe Connected Account Agreement
            </a>
          </>
        )}
      />
    </FormModal>
  );
}
