import type { FC, ReactNode } from 'react';
import { createContext, useContext } from 'react';

import type { WebSite } from '@acadeum/consts';

export interface AppOptions {
  app: WebSite;
}

const AppContext = createContext<AppOptions | undefined>(undefined);

interface SettingsRoutesProviderProps extends AppOptions {
  children: ReactNode;
}

export const useApp = () => {
  const app = useContext(AppContext);

  if (!app) {
    throw new Error('useApp must be used within a AppProvider');
  }

  return app;
};

export const AppProvider: FC<SettingsRoutesProviderProps> = ({
  children,
  app
}) => {
  return (
    <AppContext.Provider value={{ app }}>
      {children}
    </AppContext.Provider>
  );
};
