import React, { forwardRef, useEffect, useMemo, useRef } from 'react';
import { Select } from 'react-responsive-ui';

import COUNTRY_REGIONS from 'common-lib/constants/countryRegions.json';

import type { Options } from '@acadeum/types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CountryRegionSelect = forwardRef<any, any>(({
  country,
  onChange,
  includeNotAvailableOption,
  notAvailableLabel = NOT_AVAILABLE_VALUE,
  ...rest
}, ref) => {

  const options = useMemo(() => {
    let options: Options<string> = [];
    if (country && COUNTRY_REGIONS[country]) {
      options = options.concat(COUNTRY_REGIONS[country].map((region) => ({
        label: region,
        value: region
      })));
    }
    if (includeNotAvailableOption || !country || !COUNTRY_REGIONS[country]) {
      options = [{ label: NOT_AVAILABLE_VALUE, value: NOT_AVAILABLE_VALUE }].concat(options);
    }
    return options;
  }, [
    country,
    includeNotAvailableOption
  ]);

  const hasMounted = useRef(false);
  useEffect(() => {
    if (hasMounted.current) {
      if (!country) {
        onChange();
      } else if (COUNTRY_REGIONS[country]) {
        onChange();
      } else {
        onChange(NOT_AVAILABLE_VALUE);
      }
    }
  }, [country]);

  useEffect(() => {
    hasMounted.current = true;
  }, []);

  return (
    <Select
      ref={ref}
      options={options}
      autocomplete
      autocompleteShowAll
      placeholder="Choose"
      noCountrySelectedLabel="Choose country"
      notAvailableLabel={notAvailableLabel}
      {...rest}
    />
  );
});

export default CountryRegionSelect;

const NOT_AVAILABLE_VALUE = 'N/A';
