import type { Table, RowData, RowSelectionState } from '@tanstack/react-table';
import React from 'react';


import { DownloadButton } from '../../../DownloadButton';

import type { useDataExport } from '../../hooks/useDataExport';
import { ColumnVisibility } from '../ColumnVisibility';
import { GlobalFilter } from '../GlobalFilter';
import { TotalResultsCount } from '../TotalResultsCount';
import type { TotalResultsCountProps } from '../TotalResultsCount';


import styles from './TopToolbar.module.scss';

export interface TopToolbarProps<TData> {
  table: Table<TData>;
  totalCount: number;
  isLoading?: boolean;
  isFetching?: boolean;
  enableGlobalFilter?: boolean;
  hasColumnVisibility?: boolean;
  dataExportProps: ReturnType<typeof useDataExport>;
  rowSelection: RowSelectionState;
  resultText: TotalResultsCountProps<TData>['resultText'];
  selectedResultText: TotalResultsCountProps<TData>['selectedResultText'];
  leftCustomActions?: React.ReactNode;
  rightCustomActions?: React.ReactNode;
  searchPlaceholder?: string;
  searchAriaLabel?: string;
  hideTotalResultsCount?: boolean;
  rearrangeable?: boolean;
}

export const TopToolbar = <TData extends RowData>({
  table,
  totalCount,
  isLoading,
  isFetching,
  enableGlobalFilter,
  hasColumnVisibility,
  dataExportProps,
  rowSelection,
  resultText,
  selectedResultText,
  leftCustomActions,
  rightCustomActions,
  searchPlaceholder,
  searchAriaLabel,
  hideTotalResultsCount,
  rearrangeable
}: TopToolbarProps<TData>) => {
  const { exportData, hasExport, disabledExport, hideDownloadButton } = dataExportProps;

  if (
    hideTotalResultsCount
    && !hasExport
    && !leftCustomActions
    && !hasColumnVisibility
    && !enableGlobalFilter
    && !rightCustomActions
  ) {
    return null;
  }

  return (
    <div className={styles.TopToolbar}>
      {!hideTotalResultsCount && (
        <TotalResultsCount
          table={table}
          totalCount={totalCount}
          isLoading={isLoading}
          isFetching={isFetching}
          rowSelection={rowSelection}
          resultText={resultText}
          selectedResultText={selectedResultText}
          rearrangeable={rearrangeable}
        />
      )}
      {(hasExport || leftCustomActions) && (
        <div className={styles.leftSide}>
          {!hideDownloadButton && exportData && totalCount > 0 && (
            <DownloadButton disabled={disabledExport} onClick={exportData}/>
          )}
          {leftCustomActions}
        </div>
      )}
      {(hasColumnVisibility || enableGlobalFilter || rightCustomActions) && (
        <div className={styles.rightSide}>
          {rightCustomActions}
          {hasColumnVisibility && <ColumnVisibility table={table}/>}
          {enableGlobalFilter && (
            <GlobalFilter
              placeholder={searchPlaceholder}
              ariaLabel={searchAriaLabel}
              table={table}
            />
          )}
        </div>
      )}
    </div>
  );
};
