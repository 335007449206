import PropTypes from 'prop-types';
import React from 'react';

import type { InputProps } from '../Input';
import { Input } from '../Input';

export interface PhoneExtensionInput extends Omit<InputProps, 'onChange'> {
  onChange: (value: string) => void;
}

export const PhoneExtensionInput = React.forwardRef<HTMLInputElement, PhoneExtensionInput>(({
  onChange: propsOnChange,
  ...rest
}, ref) => {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const value = inputValue.split('').filter(_ => _ >= '0' && _ <= '9').join('');
    propsOnChange(value);
  };

  return (
    <Input
      {...rest}
      ref={ref}
      onChange={onChange}
    />
  );
});

PhoneExtensionInput.propTypes = {
  onChange: PropTypes.func.isRequired
};

export default PhoneExtensionInput;
