import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { EllipsisVerticalIcon } from '@acadeum/icons';
import type { IconSource } from '@acadeum/types';

import { Code } from '../../types';

import { BaseButton } from '../BaseButton';
import { Button } from '../Button';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../DropdownMenu';
import { Icon } from '../Icon';
import { Link } from '../Link';

import styles from './Actions.module.scss';

interface Action {
  title: React.ReactNode;
  url?: string;
  external?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  danger?: boolean;
  className?: string;
  icon?: IconSource;
}

export interface ActionsProps {
  className?: string;
  defaultOpen?: boolean;
  disabled?: boolean;
  label?: string;
  actions: Action[];
  variant?: 'kebab' | 'button' | 'kebabButton';
}

export const Actions: React.FC<ActionsProps> = ({
  className,
  defaultOpen,
  disabled,
  label,
  actions,
  variant = 'button'
}) => {
  const [open, setOpen] = React.useState(defaultOpen);

  return (
    <DropdownMenu
      // There used `modal` since when the dropdown is used in the modal, it should be rendered as a  modal dropdown,
      // not a regular dropdown.  This is because the regular dropdown will be closed after opening because `<Modal/>`
      // component steals the focus from the dropdown.
      modal
      open={open}
      onOpenChange={setOpen}
    >
      <DropdownMenuTrigger asChild>
        <CustomToggle
          variant={variant}
          label={label}
          disabled={disabled}
          className={className}
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="end"
        sideOffset={2}
        className={styles.DropdownMenuContent}
        onKeyDown={(event) => {
          // Prevent the default behavior of the escape key to avoid closing the `<Modal/>` component
          // when the dropdown is used inside the modal.
          if (event.key === Code.Escape) {
            event.preventDefault();
            setOpen(false);
          }
        }}
      >
        {actions.map((item, index) => {
          const ItemIcon = item.icon;
          const children = (
            <>
              {item.title}
              {ItemIcon && (
                <Icon ml="auto" icon={ItemIcon}/>
              )}
            </>
          );
          return (
            <DropdownMenuItem
              asChild
              key={index}
              disabled={item.disabled}
              onClick={item.onClick}
              danger={item.danger}
            >
              {item.url ? (
                <Link
                  monochrome
                  removeUnderline
                  to={item.url}
                  external={item.external}
                >
                  {children}
                </Link>
              ) : (
                <BaseButton>{children}</BaseButton>
              )}
            </DropdownMenuItem>
          );
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

Actions.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  defaultOpen: PropTypes.bool,
  variant: PropTypes.oneOf(['kebab', 'button', 'kebabButton']),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  actions: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.node.isRequired,
    url: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    danger: PropTypes.bool
  }).isRequired).isRequired
};

interface CustomToggleProps {
  disabled?: boolean;
  label?: string;
  onClick?: () => void;
  variant: ActionsProps['variant'];
  className?: string;
}

const CustomToggle = React.forwardRef<HTMLButtonElement, CustomToggleProps>(({
  disabled,
  label,
  onClick,
  variant,
  className,
  ...rest
}, ref) => {
  label = label || 'Actions';
  const commonProps = {
    ...rest,
    ref,
    disabled,
    onClick
  };

  if (variant === 'kebab') {
    return (
      <BaseButton
        {...commonProps}
        aria-label={label}
        className={classNames(styles.KebabToggle, className)}
      >
        <EllipsisVerticalIcon/>
      </BaseButton>
    );
  }

  if (variant === 'kebabButton') {
    return (
      <BaseButton
        {...commonProps}
        aria-label={label}
        className={classNames(styles.KebabButton, className)}
      >
        <EllipsisVerticalIcon className="control-icon"/>
      </BaseButton>
    );
  }

  return (
    <Button
      {...commonProps}
      className={className}
      variant="secondary"
      disclosure
    >
      {label}
    </Button>
  );
});
