import getLocationUrl from 'common-lib/lib/getLocationUrl';

import { __ACCOUNT_CENTER_URL__ } from '@acadeum/consts';

export function getAccountCenterLoginUrl(): string {
  if (typeof window === 'undefined') {
    // On server-side, fallback to root
    return '/';
  }

  const { origin, href, pathname } = window.location;

  const isNonReturnablePage = ['/logout', '/error', '/404'].some(path => pathname.startsWith(path));

  const redirectTo = isNonReturnablePage ? origin : href;

  return getLocationUrl({
    origin: __ACCOUNT_CENTER_URL__,
    pathname: '/',
    query: {
      logIn: '✓',
      redirectTo
    }
  });
}
