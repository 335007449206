import type { FC } from 'react';
import React from 'react';

import type { UseFetchUserLogQuery } from '@acadeum/api';
import { AppLoading } from '@acadeum/ui';

import { UserAuthHistoryTable } from '../../../../../components/UserAuthHistoryTable';
import { useOnError } from '../../../../../providers/useOnError';

interface UserLogProps {
  userId: number;
  useFetchUserLogQuery: UseFetchUserLogQuery;
}

const UserLog: FC<UserLogProps> = ({
  userId,
  useFetchUserLogQuery
}) => {
  const onError = useOnError();

  const {
    error,
    isLoading,
    data: history
  } = useFetchUserLogQuery({ id: userId });

  if (error) {
    return onError(error);
  }

  if (isLoading) {
    return <AppLoading inline/>;
  }

  return (
    <UserAuthHistoryTable hideTitle history={history}/>
  );
};

export default UserLog;
