import React from 'react';

import { useTranslate } from '@acadeum/translate';
import { ContentSection, FormField, FormFieldArray } from '@acadeum/ui';

export interface FacultyCredentialsSectionValues {
  facultyCredentials: {
    name?: string;
    url?: string;
  }[];
}

export function FacultyCredentialsSection() {
  const t = useTranslate('shared-admin-ui.CourseForm');

  return (
    <ContentSection title={t('facultyCredentials.title')}>
      <FormFieldArray
        name="facultyCredentials"
        addButtonText="Add Faculty Credential"
        border={false}
      >
        {({ index }) => (
          <>
            <FormField
              name={`facultyCredentials.${index}.name`}
              label={t('facultyCredentials.name.label')}
              placeholder={t('facultyCredentials.name.placeholder')}
            />
            <FormField
              type="url"
              name={`facultyCredentials.${index}.url`}
              label={t('facultyCredentials.url.label')}
              placeholder={t('facultyCredentials.url.placeholder')}
            />
          </>
        )}
      </FormFieldArray>
    </ContentSection>
  );
}
