import React from 'react';

import formatUserName from 'common-lib/lib/formatUserName';

import { CaretDownIcon } from '@acadeum/icons';
import type { IconSource, UserProfile } from '@acadeum/types';
import {
  BaseButton,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
  Icon,
  Link,
  Separator,
  Text,
  UserPicture
} from '@acadeum/ui';

import styles from './UserMenu.module.scss';

interface Action {
  title: string;
  url?: string;
  onClick?: () => void;
  icon?: IconSource;
}

export interface UserMenuProps {
  user: Pick<UserProfile, 'firstName' | 'lastName' | 'middleName' | 'title' | 'roles' | 'userPictureUrl' | 'institution'>;
  actions: Action[];
}

export const UserMenu: React.FC<UserMenuProps> = ({ user, actions }) => {
  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger
        asChild
        title="You Profile"
        className={styles.trigger}
      >
        <BaseButton>
          <UserPicture
            user={user}
            size={32}
          />
          <Icon icon={CaretDownIcon}/>
        </BaseButton>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className={styles.content}>
        <DropdownMenuLabel>
          <Text as="h2">
            {formatUserName(user)}
          </Text>
          <Text as="p" variant="bodySm" color="secondary">
            {user.roles.map(_ => _.name).join(', ')}
          </Text>
        </DropdownMenuLabel>
        <Separator m="xs"/>

        {actions.map((item) => {
          const { url, title, onClick } = item;
          return (
            <DropdownMenuItem
              asChild
              key={title}
              onClick={onClick}
            >
              <Link
                removeUnderline
                monochrome
                to={url}
              >
                {title}
              </Link>
            </DropdownMenuItem>
          );
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
