export function unflattenObject(obj: Record<string, any>): Record<string, any> {
  const result: Record<string, any> = {};

  for (const [rawKey, value] of Object.entries(obj)) {
    if (!rawKey.includes('.')) {
      result[rawKey] = value;
      continue;
    }

    const pathParts = rawKey.split('.');
    let current = result;
    for (let i = 0; i < pathParts.length; i++) {
      const keyPart = pathParts[i];

      if (i === pathParts.length - 1) {
        current[keyPart] = value;
      } else {
        if (current[keyPart] == null || typeof current[keyPart] !== 'object') {
          current[keyPart] = {};
        }
        current = current[keyPart];
      }
    }
  }

  return result;
}
