import type { Id } from '@acadeum/types';

interface ErrorTypeByCode {
  401: 'authentication_error';
  403: 'unauthorized';
  404: 'not_found';
  409: 'conflict';
  422: 'invalid_request';
  429: 'rate_limit';
  500: 'error'; // Default
  503: 'maintenance';
}

type KnownHttpStatusCode = keyof ErrorTypeByCode;
type ErrorType = ErrorTypeByCode[KnownHttpStatusCode] | 'invalid';
type HttpStatusCode = KnownHttpStatusCode | number;

// eslint-disable-next-line @typescript-eslint/naming-convention
interface ErrorData_ {
  /** The error message */
  message?: string;
  /** Error type */
  type?: ErrorType;
  /** Available error `code`s: server-lib/utility/errors.js */
  code?: string;
  /** If the error is related to a specific input field */
  field?: string;
  /** If the error is related to a specific input field value */
  value?: any;
  /** entity or model */
  subject?: string;
  /** entity or model id */
  subjectId?: Id;
  /** entity or model ids */
  subjectIds?: Id[];
}

export interface ErrorData extends ErrorData_ {
  /** when `errors` is provided, that's the array of errors */
  errors?: ErrorData_[];

  // unknown properties (Ex: 'contacts' in 'user_account_request_pending')
  [key: string]: unknown;
}

export interface HttpError extends Error {
  status: HttpStatusCode;
  data: ErrorData;
}

export const isHttpError = (error: unknown): error is HttpError => {
  return typeof error === 'object' && error !== null && 'status' in error && 'data' in error;
};

export const getIsNotFoundError = (error: HttpError) => error.status === 404;

export const getIsNotAuthenticatedError = (error: HttpError) => (
  error.status === 401 ||
  error.data.code === 'access_token_expired' ||
  error.data.code === 'access_token_invalid' ||
  (
    // `value` contains the inactive user ID when the action was performed
    // not by the user themselves, but rather by an Acadeum admin.
    !error.data.value &&
    (error.data.code === 'account_inactive' || error.data.code === 'institution_inactive')
  )
);

export const getIsMaintenanceError = (error: HttpError) => error.status === 503;

export const getIsInvalidRequest = (error: HttpError) => {
  return error.status &&
    error.status >= 400 &&
    error.status < 500 &&
    error.status !== 401 &&
    error.status !== 404;
};

export const getIsServerError = (error: HttpError) => {
  return error.status >= 500 && error.status < 600 && error.status !== 503;
};

export const getIsUnauthorizedError = (error: HttpError) => error.status === 403;
