import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import ConfirmationModal from '../ConfirmationModal';

import './NotificationModal.sass';

export default function NotificationModal({
  notification,
  onNotificationDisplayed
}) {
  const [show, setShow] = useState();
  // eslint-disable-next-line
  const [values, setValues] = useState([]);
  const [value, setValue] = useState();
  const [valueType, setValueType] = useState();
  const [valueIsSimpleText, setValueIsSimpleText] = useState();

  // Displays the next notification in the queue
  const next = () => {
    // Get the next notification from the queue
    // (will be `undefined` if the queue is empty)
    const value = values.shift();

    setValue(value);
    setShow(Boolean(value));

    // This state property is used instead of `value.isSimpleText`
    // so that is stays while the modal is closing.
    setValueIsSimpleText(value && value.isSimpleText);

    // Storing `valueType` as a separate state variable
    // so that its value is kept after `value` is set to
    // the next one (or to `undefined`).
    // Otherwise the type-specific styling of the container
    // would be reset when a user clicks on the close button.
    // This way the type-specific styling of the container
    // is effective until the modal notification is hidden completely.
    setValueType(value && value.type);
  };

  // Adds a notification to the queue
  const push = (newValue) => {
    // Add the notification to the queue
    values.push(newValue);

    // If the notification queue was empty
    // then kick-start it.
    if (!value) {
      next();
    }
  };

  useEffect(() => {
    let value = notification;
    // On show.
    if (value) {
      // Normalize value (make it a plain javascript object)
      // if it's a string or a react element.
      if (!isValueObject(value)) {
        value = {
          content: value,
          isSimpleText: !React.isValidElement(value)
        };
      }

      // Add the notification to the queue
      push(value);
      // Reset the `value` property immediately
      onNotificationDisplayed();

      // Validate `aria-label`.
      if (typeof value.content !== 'string') {
        if (!value.contentLabel) {
          console.warn('Using `notify()` with non-string content requires passing a `contentLabel` option which will be `aria-label` attribute value');
        }
      }
    }
  }, [notification]);

  useEffect(() => {
    if (!show) {
      // Show next after the current one finishes the close animation.
      setTimeout(next, 150);
    }
  }, [show]);

  const onHide = () => setShow(false);
  const onOk = undefined;
  const title = '';
  const text = value && valueIsSimpleText ? value.content : undefined;
  const children = value && (valueIsSimpleText ? null : value.content);

  return (
    <ConfirmationModal
      show={show}
      onHide={onHide}
      onOk={onOk}
      title={title}
      text={text}
      className={classNames('NotificationModal', {
        'NotificationModal--customContent': !valueIsSimpleText,
        'NotificationModal--text': valueIsSimpleText,
        'NotificationModal--generic': !valueType,
        'NotificationModal--error': valueType === 'error',
        'NotificationModal--warning': valueType === 'warning'
      })}
      overlayClassName="NotificationModal__overlay">
      {children}
    </ConfirmationModal>
  );
}

const notificationContentType = PropTypes.oneOfType([PropTypes.node, PropTypes.string]);

const notificationObjectType = PropTypes.shape({
  content: notificationContentType,
  type: PropTypes.string,
  duration: PropTypes.number
});

NotificationModal.propTypes = {
  // Content (either a message, or an object)
  notification: PropTypes.oneOfType([
    notificationContentType,
    notificationObjectType
  ]),

  // Should be called after a notification has been displayed.
  onNotificationDisplayed: PropTypes.func.isRequired
};

// Test whether it's not a string or a React element.
function isValueObject(value) {
  return typeof value === 'object' && !value.props;
}
