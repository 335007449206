import type { FC } from 'react';
import React, { useMemo, useState } from 'react';

import type { FetchTeachingCourseEnrollmentRequestsResponseItem } from '@acadeum/api';
import { getEnrollmentTypeLabel } from '@acadeum/helpers';
import { useTranslate } from '@acadeum/translate';

import type { ModalProps } from '@acadeum/ui';
import { Accordion, Button, DataBlock, FormSubmit, Grid, HStack, Modal, Tag, Text, toast } from '@acadeum/ui';

import { useApproveTeachingCourseEnrollmentMutation } from '../../../../api/courseEnrollment';
import { OnDemandBadge } from '../../../../components/CourseBadges/ui/OnDemandBadge';

import { CourseEnrollmentRequestsStudentTable } from '../CourseEnrollmentRequestsStudentTable';
import { DenyCourseEnrollmentModal } from '../DenyCourseEnrollmentModal';

import styles from './CourseEnrollmentRequestDetailsModal.module.scss';

interface ModalInfoProps extends Pick<ModalProps, 'onHide' | 'show'> {
  enrollmentRequest: FetchTeachingCourseEnrollmentRequestsResponseItem;
}

export const CourseEnrollmentRequestDetailsModal: FC<ModalInfoProps> = ({
  show,
  onHide,
  enrollmentRequest
}) => {
  const t = useTranslate('EnrollmentRequests');

  const [approveTeachingCourseEnrollmentMutation] = useApproveTeachingCourseEnrollmentMutation();

  const [showDenyModal, setShowDenyModal] = useState(false);

  const {
    requestedEnrollments,
    processedEnrollments
  } = useMemo(() => {
    return enrollmentRequest.enrollments.reduce<{
      requestedEnrollments: FetchTeachingCourseEnrollmentRequestsResponseItem['enrollments'];
      processedEnrollments: FetchTeachingCourseEnrollmentRequestsResponseItem['enrollments'];
    }>((acc, item) => {
      if (item.status === 'REQUESTED') {
        acc.requestedEnrollments.push(item);
      } else {
        acc.processedEnrollments.push(item);
      }
      return acc;
    }, {
      requestedEnrollments: [],
      processedEnrollments: []
    });
  }, [enrollmentRequest]);

  const [selectedEnrollmentIds, setSelectedEnrollmentIds] = useState(() => requestedEnrollments.map(_ => _.id));
  const selectedEnrollments = useMemo(() => {
    return selectedEnrollmentIds.map(id => {
      const found = requestedEnrollments.find(_ => _.id === id);
      if (!found) {
        throw new Error(`Enrollment with id ${id} not found`);
      }
      return found;
    });
  }, [selectedEnrollmentIds, requestedEnrollments]);

  const onDemand = enrollmentRequest.section.course.onDemand;

  const content = [
    ...(onDemand ? [] : [
      {
        title: t('courseDates'),
        content: (
          <>
            <DataBlock
              utc
              type="date"
              month="long"
              as="span"
              date={enrollmentRequest.section.startDate}
            />
            {' - '}
            <DataBlock
              utc
              type="date"
              month="long"
              as="span"
              date={enrollmentRequest.section.endDate}
            />
          </>
        )
      },
      {
        title: t('addDate'),
        content: (
          <DataBlock
            utc
            type="date"
            month="long"
            date={enrollmentRequest.section.lastAddDate}
          />
        )
      },
      {
        title: t('dropDate'),
        content: (
          <DataBlock
            utc
            type="date"
            month="long"
            date={enrollmentRequest.section.lastDropDate}
          />
        )
      }
    ]),
    {
      title: t('course'),
      content: enrollmentRequest.section.course.title
    },
    ...(onDemand ? [] : [
      {
        title: t('term'),
        content: enrollmentRequest.section.term
      },
      {
        title: t('session'),
        content: enrollmentRequest.section.session
      }
    ]),
    {
      title: t('homeInstitution'),
      content: <DataBlock hideLogo type="institution" institution={enrollmentRequest.institution}/>
    }
  ];

  const onAfterSubmit = async (actionType) => {
    setSelectedEnrollmentIds([]);
    toast.success(<>{t(actionType === 'approve' ? 'approveMessageSuccess' : 'denyMessageSuccess', {
      count: selectedEnrollmentIds.length,
      strong: (children) => (
        <strong>
          {children}
        </strong>
      )
    })}</>);
    onHide(false);
  };

  const onApproveSelectedEnrollments = async () => {
    if (selectedEnrollmentIds.length === 0) {
      return toast.warn('Select some students first');
    }
    await approveTeachingCourseEnrollmentMutation({
      ids: selectedEnrollmentIds,
      enrollmentRequestId: enrollmentRequest.id
    }).unwrap();
    await onAfterSubmit('approve');
  };

  const onAfterDeny = async () => {
    await onAfterSubmit('deny');
  };

  const onDenySelectedEnrollments = () => {
    if (selectedEnrollmentIds.length === 0) {
      return toast.warn('Select some students to deny');
    }
    setShowDenyModal(true);
  };

  return (
    <>
      <Modal
        show={show}
        size="wide"
        onSubmit={requestedEnrollments.length > 0 ? onApproveSelectedEnrollments : undefined}
        onHide={onHide}
        title={t('title')}
      >
        <Modal.Body>
          <div className={styles.ModalInfo__block}>
            <HStack gap="sm" mb="lg">
              <Text as="h3" variant="subtitle2">
                {t('titleModal')}
              </Text>
              <Tag>{getEnrollmentTypeLabel(enrollmentRequest.enrollments[0])}</Tag>
              {onDemand && (
                <OnDemandBadge/>
              )}
            </HStack>
            <Grid
              container
              wrap="wrap"
              columns={24}
              rowSpacing={2}
            >
              {content.map((_, index) => (
                <React.Fragment key={index}>
                  <Grid size={7}>{_.title}</Grid>
                  <Grid size={17}>{_.content}</Grid>
                </React.Fragment>
              ))}
            </Grid>
            <br/>
            <Accordion
              defaultOpen
              title={requestedEnrollments.length > 0
                ? t('totalStudents', { totalCount: requestedEnrollments.length })
                : t('totalStudents', { totalCount: processedEnrollments.length })}
            >
              {requestedEnrollments.length > 0 ? (
                <CourseEnrollmentRequestsStudentTable
                  enableRowSelection
                  selectedEnrollmentIds={selectedEnrollmentIds}
                  setSelectedEnrollmentIds={setSelectedEnrollmentIds}
                  enrollments={requestedEnrollments}
                  enrollmentRequest={enrollmentRequest}
                />
              ) : (
                <CourseEnrollmentRequestsStudentTable
                  enrollments={processedEnrollments}
                  enrollmentRequest={enrollmentRequest}
                />
              )}
            </Accordion>
          </div>
        </Modal.Body>
        {requestedEnrollments.length > 0 && (
          <Modal.Footer>
            <Button onClick={onDenySelectedEnrollments} variant="secondary">
              {t('deny')}
            </Button>
            <FormSubmit>
              {t('accept')}
            </FormSubmit>
          </Modal.Footer>
        )}
      </Modal>

      <DenyCourseEnrollmentModal
        show={showDenyModal}
        onHide={setShowDenyModal}
        onAfterSubmit={onAfterDeny}
        enrollmentRequest={enrollmentRequest}
        enrollmentsToDeny={selectedEnrollments}
      />
    </>
  );
};

export default CourseEnrollmentRequestDetailsModal;
