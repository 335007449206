import React, { useState } from 'react';

import actions from '../../actions';
import Field from '../../components/Field';
import Form from '../../components/Form';
import FormButtons from '../../components/FormButtons';
import FormHeading from '../../components/FormHeading';
import FormSubmit from '../../components/FormSubmit';


const { createStripeAccount } = actions;

export default function CreateStripeAccount() {
  const [created, setCreated] = useState();

  async function onSubmit(values) {
    // The user will have to reset this password anyway
    // values.password = String(Math.random());
    await createStripeAccount(values);
    setCreated(true);
  }

  return (
    <section>
      <FormHeading>
        Create a stripe account
      </FormHeading>

      {created &&
        <p>
          The user has been created and emailed
        </p>
      }

      {!created &&
        <Form
          autoFocus
          className="form"
          onSubmit={onSubmit}>

          <Field
            label="EMAIL"
            type="email"
            name="email"
            required />

          <FormButtons>
            <FormSubmit>
              Create Stripe Account
            </FormSubmit>
          </FormButtons>
        </Form>
      }
    </section>
  );
}

CreateStripeAccount.meta = () => ({
  title: 'Create a Stripe Teaching Member Account'
});

CreateStripeAccount.breadcrumbs = () => [
  ['Admin Tools', '/admin'],
  'Create Stripe Account'
];
