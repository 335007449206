import { upperFirst } from 'lodash-es';

// eslint-disable-next-line
type EventDefaultMap = Record<string, any>;

type Unsubscriber = () => void;

type Handlers<EventsMap extends { [key: string]: unknown }> = {
  [Key in keyof EventsMap]: (detail: EventsMap[Key]) => void
} & {
  [Key in keyof EventsMap as `on${Capitalize<string & Key>}`]: (listener: (detail: EventsMap[Key]) => void) => Unsubscriber;
}

export const createObserver = <EventsMap extends EventDefaultMap>(
  ...events: (keyof EventsMap & string)[]
): Handlers<EventsMap> => {
  const eventEmitter = new EventTarget();

  return events.reduce((acc, eventName) => ({
    ...acc,
    [eventName]: (detail) => eventEmitter.dispatchEvent(new CustomEvent(eventName, { detail })),
    [`on${upperFirst(eventName)}`]: (listener: (detail: unknown) => void) => {
      const eventListener = ({ detail }: Partial<CustomEvent>) => listener(detail);

      eventEmitter.addEventListener(eventName, eventListener);

      return () => eventEmitter.removeEventListener(eventName, eventListener);
    }
  }), {}) as Handlers<EventsMap>;
};
