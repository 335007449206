import classNames from 'classnames';
import React from 'react';

import { Link } from '../Link';

import styles from './Breadcrumbs.module.scss';

export interface BreadcrumbsProps extends React.HTMLAttributes<HTMLElement> {
  children: (string | [string, string])[];
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ children, className, ...rest }) => {
  const hasLinks = children.filter(_ => typeof _ !== 'string').length > 0;
  const Container = hasLinks ? 'nav' : 'div';

  return (
    <Container
      className={classNames(className, styles.Breadcrumbs)}
      aria-label="Breadcrumbs"
      {...rest}
    >
      <ul className={styles.Breadcrumbs__items}>
        {children.map((breadcrumb, i) => (
          <li className={styles.Breadcrumbs__item} key={i}>
            {typeof breadcrumb === 'string' ? (
              breadcrumb
            ) : (
              <Link className={styles.Breadcrumbs__link} to={breadcrumb[1]}>
                {breadcrumb[0]}
              </Link>
            )}
          </li>
        ))}
      </ul>
    </Container>
  );
};

export default Breadcrumbs;
