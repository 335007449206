import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './AcadeumBackground.sass';

export default function AcadeumBackground({ className, children }) {
  return (
    <div className={classNames('AcadeumBackground', className)}>
      {children}
    </div>
  );
}

AcadeumBackground.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};
