import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { memo, useMemo } from 'react';


import type {
  FetchBackupPaymentSourceOutput,
  FetchFinancialSettingsStatusOutput,
  FetchPrimaryPaymentSourceOutput,
  UseFetchBackupPaymentSourceQuery,
  UseFetchFinancialSettingsStatusQuery,
  UseFetchPrimaryPaymentSourceQuery
} from '@acadeum/api';
import { CircularProgress, HStack } from '@acadeum/ui';

import { FinancialNavigationProvider, useFinancialNavigationContext, FINANCIAL_SCREEN } from './context';
import type { FinancialDashboardScreenProps } from './ui/FinancialDashboardScreen';
import { FinancialDashboardScreen } from './ui/FinancialDashboardScreen';
import { FinancialStartScreen } from './ui/FinancialStartScreen';
import type { ManageBankAccountScreenProps } from './ui/ManageBankAccountScreen';
import { ManageBankAccountScreen } from './ui/ManageBankAccountScreen';
import type { ManageCreditCardScreenProps } from './ui/ManageCreditCardScreen';
import { ManageCreditCardScreen } from './ui/ManageCreditCardScreen';
import { StepperScreen } from './ui/StepperScreen';


export interface FinancialSettingsPageProps extends Omit<FinancialSettingsPageContentProps, 'financialSettingsStatus' | 'primaryPaymentSource' | 'backupPaymentSource'> {
  stripeApiKey: string;
  useFetchBackupPaymentSourceQuery: UseFetchBackupPaymentSourceQuery;
  useFetchFinancialSettingsStatusQuery: UseFetchFinancialSettingsStatusQuery;
  useFetchPrimaryPaymentSourceQuery: UseFetchPrimaryPaymentSourceQuery;
}

export const FinancialSettingsPage = memo<FinancialSettingsPageProps>(({
  stripeApiKey,
  useFetchPrimaryPaymentSourceQuery,
  useFetchFinancialSettingsStatusQuery,
  useFetchBackupPaymentSourceQuery,
  ...rest
}) => {
  const stripe = useMemo(() => loadStripe(stripeApiKey), [stripeApiKey]);

  const {
    data: financialSettingsStatus,
    isLoading: isLoadingFinancialSettingsStatus,
    isSuccess
  } = useFetchFinancialSettingsStatusQuery();
  const {
    data: primaryPaymentSource,
    isLoading: isLoadingPrimaryPaymentSource
  } = useFetchPrimaryPaymentSourceQuery();
  const {
    data: backupPaymentSource,
    isLoading: isLoadingBackupPaymentSource
  } = useFetchBackupPaymentSourceQuery();

  const isLoading = isLoadingFinancialSettingsStatus || isLoadingPrimaryPaymentSource || isLoadingBackupPaymentSource;

  const initialScreen = financialSettingsStatus?.financialOnboardingComplete === false && !financialSettingsStatus.backupPaymentSourceStatus && !financialSettingsStatus.primaryPaymentSourceStatus
    ? FINANCIAL_SCREEN.FINANCIAL_START
    : FINANCIAL_SCREEN.DASHBOARD;

  if (isLoading || !isSuccess) {
    return (
      <HStack justify="center" style={{ height: '20rem' }}>
        <CircularProgress/>
      </HStack>
    );
  }

  return (
    <Elements stripe={stripe}>
      <FinancialNavigationProvider initialScreen={initialScreen}>
        <div>
          <FinancialSettingsPageContent
            {...rest}
            backupPaymentSource={backupPaymentSource}
            primaryPaymentSource={primaryPaymentSource}
            financialSettingsStatus={financialSettingsStatus}
          />
        </div>
      </FinancialNavigationProvider>
    </Elements>
  );
});

interface FinancialSettingsPageContentProps extends Pick<ManageCreditCardScreenProps, 'useUpdateBackupPaymentSourceMutation' | 'useCreateBackupPaymentSourceMutation' | 'useDeleteBackupPaymentSourceMutation'>,
  Pick<ManageBankAccountScreenProps, 'useUpdatePrimaryPaymentSourceMutation' | 'useCreatePrimaryPaymentSourceMutation'>,
  Pick<FinancialDashboardScreenProps, 'useVerifyPrimaryPaymentSourceMutation'> {
  financialSettingsStatus: FetchFinancialSettingsStatusOutput;
  primaryPaymentSource?: FetchPrimaryPaymentSourceOutput;
  backupPaymentSource?: FetchBackupPaymentSourceOutput;
}

function FinancialSettingsPageContent({
  primaryPaymentSource,
  backupPaymentSource,
  financialSettingsStatus,
  useUpdateBackupPaymentSourceMutation,
  useCreateBackupPaymentSourceMutation,
  useDeleteBackupPaymentSourceMutation,
  useCreatePrimaryPaymentSourceMutation,
  useUpdatePrimaryPaymentSourceMutation,
  useVerifyPrimaryPaymentSourceMutation
}: FinancialSettingsPageContentProps) {

  const { screen } = useFinancialNavigationContext();

  if (screen === FINANCIAL_SCREEN.MANAGE_CREDIT_CARD && backupPaymentSource) {
    return (
      <ManageCreditCardScreen
        useUpdateBackupPaymentSourceMutation={useUpdateBackupPaymentSourceMutation}
        useCreateBackupPaymentSourceMutation={useCreateBackupPaymentSourceMutation}
        useDeleteBackupPaymentSourceMutation={useDeleteBackupPaymentSourceMutation}
        backupPaymentSource={backupPaymentSource}
      />
    );
  }
  if (screen === FINANCIAL_SCREEN.MANAGE_BANK_ACCOUNT && primaryPaymentSource) {
    return (
      <ManageBankAccountScreen
        type="current"
        primaryPaymentSource={primaryPaymentSource}
        useUpdatePrimaryPaymentSourceMutation={useUpdatePrimaryPaymentSourceMutation}
        useCreatePrimaryPaymentSourceMutation={useCreatePrimaryPaymentSourceMutation}
      />
    );
  }
  if (screen === FINANCIAL_SCREEN.MANAGE_NEW_BANK_ACCOUNT) {
    return (
      <ManageBankAccountScreen
        type="new"
        primaryPaymentSource={primaryPaymentSource}
        useUpdatePrimaryPaymentSourceMutation={useUpdatePrimaryPaymentSourceMutation}
        useCreatePrimaryPaymentSourceMutation={useCreatePrimaryPaymentSourceMutation}
      />
    );
  }
  if (screen === FINANCIAL_SCREEN.STEPPER) {
    return (
      <StepperScreen
        useUpdatePrimaryPaymentSourceMutation={useUpdatePrimaryPaymentSourceMutation}
        useCreatePrimaryPaymentSourceMutation={useCreatePrimaryPaymentSourceMutation}
        useCreateBackupPaymentSourceMutation={useCreateBackupPaymentSourceMutation}
        useUpdateBackupPaymentSourceMutation={useUpdateBackupPaymentSourceMutation}
        financialSettingsStatus={financialSettingsStatus}
        backupPaymentSource={backupPaymentSource}
        primaryPaymentSource={primaryPaymentSource}
      />
    );
  }
  if (screen === FINANCIAL_SCREEN.FINANCIAL_START) {
    return <FinancialStartScreen/>;
  }
  return (
    <FinancialDashboardScreen
      useVerifyPrimaryPaymentSourceMutation={useVerifyPrimaryPaymentSourceMutation}
      useUpdatePrimaryPaymentSourceMutation={useUpdatePrimaryPaymentSourceMutation}
      useCreatePrimaryPaymentSourceMutation={useCreatePrimaryPaymentSourceMutation}
      useCreateBackupPaymentSourceMutation={useCreateBackupPaymentSourceMutation}
      useUpdateBackupPaymentSourceMutation={useUpdateBackupPaymentSourceMutation}
      financialSettingsStatus={financialSettingsStatus}
      backupPaymentSource={backupPaymentSource}
      primaryPaymentSource={primaryPaymentSource}
    />
  );

}
