import React from 'react';

import { firstParagraph } from '@acadeum/helpers';
import { useTranslate } from '@acadeum/translate';

import { ContentSection, Text } from '@acadeum/ui';


import ContactInfo from '../../../../components/ContactInfo';
import InfoSection from '../../../../components/InfoSection';

const GeneralInfo = ({
  consortium,
  requestToJoin,
  user
}) => {
  const t = useTranslate('Consortium');

  return (
    <>
      <ContentSection
        fullWidth
        padding={false}
        border={false}
        title={t('about')}
      >
        <InfoSection title={t('purpose')}>
          <Text markdown>
            {firstParagraph(consortium.goals)}
          </Text>
        </InfoSection>
        <InfoSection title={t('membership')}>
          <Text>
            {consortium.requirements}
          </Text>
        </InfoSection>
      </ContentSection>
      <br/>
      <hr/>
      <br/>
      <ContentSection
        fullWidth
        padding={false}
        border={false}
        title={t('contact')}
      >
        <ContactInfo
          contact={consortium.primaryContact}
          title={t('primaryContact')}
          onRequestToJoin={requestToJoin}
          user={user}
        />
      </ContentSection>
      <br/>
      <hr/>
      <br/>
      <ContentSection
        fullWidth
        padding={false}
        border={false}
        title={t('description')}
      >
        <Text markdown>
          {consortium.description}
        </Text>
      </ContentSection>
    </>
  );
};
export default GeneralInfo;
