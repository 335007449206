import React from 'react';

import { Button } from '../Button';
import type { FormSubmitProps } from '../FormSubmit';
import { FormSubmit } from '../FormSubmit';
import type { ModalProps } from '../Modal';
import { Modal } from '../Modal';

import styles from './ConfirmActionModal.module.scss';

export interface ConfirmActionModalProps extends Pick<ModalProps, 'title' | 'show' | 'onHide' | 'onSubmit' | 'centered'> {
  description: React.ReactNode;
  submitText?: string;
  cancelText?: string;
  onCancel?: (show: false) => void;
  danger?: boolean;
  submitProps?: FormSubmitProps;
}

export const ConfirmActionModal: React.FC<ConfirmActionModalProps> = ({
  title,
  description,
  submitText = 'Confirm',
  cancelText = 'Cancel',
  submitProps,
  onSubmit,
  show,
  onHide,
  onCancel,
  danger,
  centered
}) => {
  return (
    <Modal
      title={title}
      onHide={onHide}
      show={show}
      onSubmit={onSubmit}
      size="narrow"
      centered={centered}
    >
      <Modal.Body>
        <div className={styles.ConfirmActionModal__content}>
          {description}
        </div>
      </Modal.Body>
      <Modal.Footer paddingTop="large">
        {onCancel && (
          <Button
            onClick={() => onCancel(false)}
            variant={danger ? 'danger-outline' : 'secondary'}
          >
            {cancelText}
          </Button>
        )}
        <FormSubmit icon={submitProps?.icon} variant={danger ? 'danger' : 'primary'}>
          {submitText}
        </FormSubmit>
      </Modal.Footer>
    </Modal>
  );
};
