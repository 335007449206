import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './FormButtons.sass';

export default function FormButtons({
  justify,
  align,
  margin,
  style,
  children
}) {
  return (
    <div style={style} className={classNames('form__buttons', {
      'form__buttons--apart': justify === 'apart',
      'form__buttons--left': align === 'left',
      'form__buttons--no-margin': margin === false
    })}>
      {children}
    </div>
  );
}

FormButtons.propTypes = {
  justify: PropTypes.oneOf(['apart']),
  align: PropTypes.oneOf(['left']),
  margin: PropTypes.bool,
  style: PropTypes.object,
  children: PropTypes.node.isRequired
};
