// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import PropTypes from 'prop-types';
import React from 'react';

import { Input } from '../../../Input';
import { PasswordInput } from '../../../PasswordInput';

export const FormInput = React.forwardRef((props, ref) => {
  if (props.type === 'password') {
    return (
      <PasswordInput
        ref={ref}
        {...props}
      />
    );
  }

  return (
    <Input
      ref={ref}
      {...props}
    />
  );
});

FormInput.propTypes = {
  type: PropTypes.string
};

export default FormInput;
