import classNames from 'classnames';
import React from 'react';

import type { IconSource } from '@acadeum/types';

import type { ActionButtonProps } from '../ActionButton';
import { ActionButton } from '../ActionButton';
import { Icon } from '../Icon';
import { Title } from '../Title';

import styles from './EmptyState.module.scss';

export interface EmptyStateProps {
  title?: React.ReactNode;
  children?: React.ReactNode;
  icon?: IconSource;
  action?: ActionButtonProps;
  size?: 'small' | 'medium' | 'full';
}

export const EmptyState: React.FC<EmptyStateProps> = ({
  title,
  children,
  icon,
  action,
  size
}) => {

  return (
    <div className={classNames(styles.root, {
      [styles[`root--${size}`]]: size
    })}>
      {icon && (
        <div className={styles.iconWrapper}>
          <Icon icon={icon} className={styles.icon}/>
        </div>
      )}
      {title && (
        <Title level={2} className={styles.title}>
          {title}
        </Title>
      )}
      {children && (
        <div className={styles.content}>
          {children}
        </div>
      )}
      {action && (
        <ActionButton {...action} className={classNames(action.className, styles.action)}/>
      )}
    </div>
  );
};
