import React, { memo, useMemo } from 'react';

import { useTranslate } from '@acadeum/translate';
import type { TabsProps } from '@acadeum/ui';
import { Tabs } from '@acadeum/ui';

import actions from '../../actions';

import type { CourseEnrollmentMode} from '../CourseEnrollmentsTable';

import { CourseEnrollmentsTable } from '../CourseEnrollmentsTable';

import { useUserCanReadHomeCourseEnrollmentGrade, useUserCanReadTeachingCourseEnrollmentGrade } from './hooks';

const { goto } = actions;

export interface GradesProps {
  mode: CourseEnrollmentMode;
}

export const Grades = memo<GradesProps>(({
  mode
}) => {
  const t = useTranslate('Grades');
  const canReadHomeGrades = useUserCanReadHomeCourseEnrollmentGrade();
  const canReadTeachingGrades = useUserCanReadTeachingCourseEnrollmentGrade();

  const tabs = useMemo(() => {
    const result: TabsProps['tabs'] = [];

    if (canReadHomeGrades) {
      result.push({
        key: 'homeInstitution',
        title: t('Enrolling Grades'),
        body: <CourseEnrollmentsTable isGrade mode="homeInstitution"/>
      });
    }

    if (canReadTeachingGrades) {
      result.push({
        key: 'teachingInstitution',
        title: t('Teaching Grades'),
        body: <CourseEnrollmentsTable isGrade mode="teachingInstitution"/>
      });
    }

    return result;
  }, [mode, canReadHomeGrades, canReadTeachingGrades]);

  return (
    <Tabs
      keepMounted={false}
      title={t('Grades')}
      tabs={tabs}
      initialActiveTab={mode}
      onChangeTab={({ key }) => goto(key === 'homeInstitution' ? '/grades/enrolling' : '/grades/teaching')}
    />
  );
});
