import PropTypes from 'prop-types';
import React from 'react';

import actions from '../../actions';
import Button from '../Button';
import Link from '../Link';


import './Error.sass';

const {
  goto,
  goBackTwoPages,
  showSignInModal
} = actions;

export default function ErrorSection({ type, code, url }) {
  return (
    <section className="container">
      <h1 className="Error__title">
        {getErrorTitle({ type, code })}
      </h1>

      <p className="Error__description">
        {type === 'authentication_error'
          ? (
            <>
              <Link
                to="/"
                onClick={(event) => {
                  event.preventDefault();
                  showSignInModal();
                }}>
                Login
              </Link>
              {' '}
              to continue to the requested page.
            </>
          )
          : (type === 'not_found'
            ? (
              <>
                The <ConditionalLink url={url}>page</ConditionalLink> you're looking for doesn't exist.
                {url && (
                  <>
                    <br/>
                    <Button
                      inline
                      link="/"
                      className="Error__button">
                      Go Home
                    </Button>
                  </>
                )}
              </>
            )
            : (
              <>
                An error happened.
                <Button
                  className="Error__button"
                  onClick={() => {
                    if (url) {
                      goto(url);
                    } else {
                      goBackTwoPages();
                    }
                  }}>
                  Go Back
                </Button>
              </>
            )
          )
        }
      </p>
    </section>
  );
}

ErrorSection.propTypes = {
  type: PropTypes.string,
  code: PropTypes.string,
  url: PropTypes.string
};

function getErrorTitle({ type, code }) {
  switch (type) {
    case 'authentication_error':
      return 'Login Required';
    case 'unauthorized':
      return 'Unauthorized';
    case 'not_found':
      return 'Not Found';
  }
  switch (code) {
    case 'account_inactive':
      return 'Account Inactive';
    case 'institution_inactive':
      return 'Institution Inactive';
  }
  return 'Error';
}

function ConditionalLink({ url, children, ...rest }) {
  if (url) {
    return (
      <Link to={url} {...rest}>
        {children}
      </Link>
    );
  }
  return children;
}

ConditionalLink.propTypes = {
  url: PropTypes.string,
  children: PropTypes.node.isRequired
};
