import { userHasPermission } from '@acadeum/helpers';

import actions from '../actions';

const {
  getEnrollmentsCounts,
  getTeachingEnrollmentsCounts,
  getAllStudentsCount,
  getStudentRequestsCounts,
  getTeachingStudentsCount,
  fetchEnrollmentRequestsTM,
  fetchEnrollmentRequests
} = actions;

export function loadEnrollmentsPage({ user }) {
  const promises = [];
  if (userHasPermission(user, 'homeCourseEnrollment:read', { orgId: user.institution.id })) {
    promises.push(
      getEnrollmentsCounts(),
      fetchEnrollmentRequests()
    );
  }
  if (user.institution.teaching && userHasPermission(user, 'teachingCourseEnrollment:read', { orgId: user.institution.id })) {
    promises.push(
      getTeachingEnrollmentsCounts(),
      fetchEnrollmentRequestsTM()
    );
  }
  return Promise.all(promises);
}

export function loadHomeStudentsPage({ user }) {
  const promises = [
    getAllStudentsCount()
  ];
  if (user.institution.teaching && userHasPermission(user, 'teachingStudent:read', { orgId: user.institution.id })) {
    promises.push(getTeachingStudentsCount());
  }
  return Promise.all(promises);
}

export function loadTeachingStudentsPage({ user }) {
  const promises = [
    getTeachingStudentsCount()
  ];
  if (userHasPermission(user, 'homeStudent:read', { orgId: user.institution.id })) {
    promises.push(getAllStudentsCount());
  }
  return Promise.all(promises);
}

export function loadStudentRequestsPage(promises) {
  return Promise.all([
    ...getPromises(promises),
    getStudentRequestsCounts()
  ]);
}

function getPromises(promises) {
  if (Array.isArray(promises)) {
    return promises;
  } 
  return [promises];
  
}
