import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import getLocationUrl from 'common-lib/lib/getLocationUrl';

import {
  getAccountCenterLoginUrl,
  getIsMaintenanceError,
  getIsNotAuthenticatedError,
  isHttpError
} from '@acadeum/helpers';

import { httpErrorSlice, selectHttpError } from './httpErrorSlice';

interface UseHttpErrorHandlerProps {
  useAccountCenterLoginUrl?: boolean;
  loginUrl?: string;
  redirect: (url: string) => void;
  warn: (content: string) => void;
}

/**
 * Client-side hook for handling HTTP errors triggered by the Redux store.
 *
 * Since server-side redirects are not possible from within getErrorHandlerMiddleware(),
 * this hook provides a client-side fallback for redirecting users based on common HTTP error types.
 *
 * Supported behaviors:
 * - 401 (Not Authenticated): Redirects to login page with optional `redirectTo` query param
 * - 403 (Unauthorized): Redirects to generic error page
 * - 404 (Not Found): Do nothing
 * - 503 (Maintenance): Triggers a notification to inform the user
 *
 * Use this hook in your top-level component (e.g. `_app.tsx` or layout)
 * to ensure consistent handling of server responses across the app.
 */
export function useHttpErrorHandler({
  useAccountCenterLoginUrl,
  loginUrl,
  redirect,
  warn
}: UseHttpErrorHandlerProps) {
  const dispatch = useDispatch();
  const httpError = useSelector(selectHttpError);

  const clearError = useCallback(
    () => dispatch(httpErrorSlice.actions.clearError()),
    [dispatch]
  );

  useEffect(() => {
    if (!isHttpError(httpError)) {
      return;
    }

    const redirectWithQuery = (pathname: string) => {
      redirect(getLocationUrl({
        pathname,
        query: {
          ...httpError?.data,
          redirectTo: window.location.href
        }
      }, { relative: true }));
    };

    clearError();

    // Not found errors are handled by useOnError() hook.
    // if (getIsNotFoundError(httpError)) {
    //   return redirectWithQuery('/404');
    // }

    // if (getIsUnauthorizedError(httpError)) {
    //   return redirectWithQuery('/error');
    // }

    if (getIsNotAuthenticatedError(httpError)) {
      if (loginUrl) {
        return redirect(loginUrl);
      }

      if (useAccountCenterLoginUrl) {
        return redirect(getAccountCenterLoginUrl());
      }

      return redirectWithQuery('/login');
    }

    if (getIsMaintenanceError(httpError)) {
      return warn('The servers are undergoing maintenance. Check back again soon.');
    }

    // console.error('HTTP error:', httpError);
  }, [httpError, clearError, redirect, warn, useAccountCenterLoginUrl]);
}
