import type { FC, ReactNode } from 'react';

import { LinkProvider } from '@acadeum/core-ui';
import type { LinkLikeComponent } from '@acadeum/core-ui';
import { IntlProvider } from '@acadeum/translate';
import type { Locale } from '@acadeum/types';

import { DEFAULT_LANGUAGE } from '../../translate';

import type { GetConsortiumInputParams } from '../../utils/consortiumAutocomplete';
import { GetConsortiumInputParamsContext } from '../../utils/consortiumAutocomplete';
import type { GetCourseLink } from '../../utils/courseLink';
import { GetCourseLinkContext } from '../../utils/courseLink';


import type { GetInstitutionAccreditationInputParams } from '../../utils/institutionAccreditationAutocomplete';
import { GetInstitutionAccreditationInputParamsContext } from '../../utils/institutionAccreditationAutocomplete';

import type { GetInstitutionAffiliationInputParams } from '../../utils/institutionAffiliationAutocomplete';
import { GetInstitutionAffiliationInputParamsContext } from '../../utils/institutionAffiliationAutocomplete';
import { GetInstitutionInputParamsContext } from '../../utils/institutionAutocomplete';
import type { GetInstitutionInputParams } from '../../utils/institutionAutocomplete';
import { GetInstitutionLinkContext } from '../../utils/institutionLink';
import type { GetInstitutionLink } from '../../utils/institutionLink';
import { GetStudentLinkContext } from '../../utils/studentLink';
import type { GetStudentLink } from '../../utils/studentLink';


import type { UseUploadImageMutation } from '../../utils/uploadImage';
import { UseUploadImageMutationContext } from '../../utils/uploadImage';

export interface AppProviderProps {
  children: ReactNode;
  language: Locale;
  messages: Record<string, string>;
  linkComponent: LinkLikeComponent;
  useUploadImageMutation?: UseUploadImageMutation;
  getCourseLink?: GetCourseLink;
  getStudentLink?: GetStudentLink;
  getInstitutionLink?: GetInstitutionLink;
  getInstitutionInputParams?: GetInstitutionInputParams;
  getConsortiumInputParams?: GetConsortiumInputParams;
  getInstitutionAccreditationInputParams?: GetInstitutionAccreditationInputParams;
  getInstitutionAffiliationInputParams?: GetInstitutionAffiliationInputParams;
}

export const AppProvider: FC<AppProviderProps> = ({
  children,
  language,
  messages,
  linkComponent,
  getCourseLink,
  getStudentLink,
  getInstitutionLink,
  getInstitutionInputParams,
  getConsortiumInputParams,
  useUploadImageMutation,
  getInstitutionAccreditationInputParams,
  getInstitutionAffiliationInputParams
}) => {
  return (
    <IntlProvider
      locale={language}
      messages={messages}
      defaultLocale={DEFAULT_LANGUAGE}
    >
      <LinkProvider linkComponent={linkComponent}>
        <GetInstitutionLinkContext.Provider value={getInstitutionLink}>
          <GetCourseLinkContext.Provider value={getCourseLink}>
            <GetStudentLinkContext.Provider value={getStudentLink}>
              <GetInstitutionInputParamsContext.Provider value={getInstitutionInputParams}>
                <GetConsortiumInputParamsContext.Provider value={getConsortiumInputParams}>
                  <GetInstitutionAccreditationInputParamsContext.Provider value={getInstitutionAccreditationInputParams}>
                    <GetInstitutionAffiliationInputParamsContext.Provider value={getInstitutionAffiliationInputParams}>
                      <UseUploadImageMutationContext.Provider value={useUploadImageMutation}>
                        {children}
                      </UseUploadImageMutationContext.Provider>
                    </GetInstitutionAffiliationInputParamsContext.Provider>
                  </GetInstitutionAccreditationInputParamsContext.Provider>
                </GetConsortiumInputParamsContext.Provider>
              </GetInstitutionInputParamsContext.Provider>
            </GetStudentLinkContext.Provider>
          </GetCourseLinkContext.Provider>
        </GetInstitutionLinkContext.Provider>
      </LinkProvider>
    </IntlProvider>
  );
};

AppProvider.displayName = 'AppProvider';
