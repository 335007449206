import { debounce } from 'lodash-es';
import { useEffect, useState, useMemo } from 'react';

export type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4x' | '5xl';

interface Breakpoints {
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
  '2xl': number;
  '3xl': number;
  '4xl': number;
  '5xl': number;
}

export type ScreenSizesBooleans = {
  [key in keyof Breakpoints]: boolean
};

const SCREEN_SIZES_BREAKPOINTS = {
  xs: 0,
  sm: 480,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1440,
  '3xl': 1600,
  '4xl': 1800,
  '5xl': 2600
};

export function useScreenSize(SCREEN_SIZES: Breakpoints = SCREEN_SIZES_BREAKPOINTS) {
  const [screenSizes, setScreenSizes] = useState<undefined | ScreenSizesBooleans>();
  const [isSmallScreen, setSmallScreen] = useState<undefined | boolean>();
  const [isLargeScreen, setLargeScreen] = useState<undefined | boolean>();

  useEffect(() => {
    const onResize = () => {
      setSmallScreen(getSmallScreen());
      setLargeScreen(getLargeScreen());
      setScreenSizes(getScreenSizes());
    };

    onResize();
    const debouncedHandleResize = debounce(onResize, 200);
    window.addEventListener('resize', debouncedHandleResize);
    return () => window.removeEventListener('resize', debouncedHandleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const SCREEN_SIZES_LIST = useMemo(() => {
    return Object.keys(SCREEN_SIZES).map((key) => ({
      key,
      minWidth: SCREEN_SIZES[key]
    })).sort((a, b) => {
      return a > b ? 1 : (a < b ? -1 : 0);
    });
  }, [SCREEN_SIZES]);

  function getScreenSizes() {
    return SCREEN_SIZES_LIST.reduce((acc, size) => {
      acc[size.key] = window.innerWidth >= size.minWidth;
      return acc;
    }, {} as ScreenSizesBooleans);
  }

  const getSmallScreen = () => window.innerWidth < SCREEN_SIZES.md;
  const getLargeScreen = () => window.innerWidth >= SCREEN_SIZES.lg;

  return {
    screenSizes,
    isSmallScreen,
    isLargeScreen
  };
}
