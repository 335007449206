import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import { mergeRegister } from '@lexical/utils';
import { $getSelection, $isRangeSelection, SELECTION_CHANGE_COMMAND } from 'lexical';
import type { NodeSelection, RangeSelection, BaseSelection } from 'lexical';
import { useCallback, useEffect, useRef, useState } from 'react';

import { EditIcon } from '@acadeum/icons';

import { BaseButton } from '../../../../../BaseButton';
import { Icon } from '../../../../../Icon';

import { useModalLexicalEditorContext } from '../../../../../Modal/context/hooks';
import { getSelectedNode } from '../../../../helpers';

import styles from './FloatingLinkEditor.module.scss';

const LowPriority = 1;

export function FloatingLinkEditor({ editor }) {
  const editorRef = useRef(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const mouseDownRef = useRef(false);
  const [linkUrl, setLinkUrl] = useState('');
  const [isEditMode, setEditMode] = useState(false);
  const [lastSelection, setLastSelection] = useState<RangeSelection | NodeSelection | BaseSelection | null>(null);

  const modalLexicalEditorContext = useModalLexicalEditorContext();

  useEffect(() => {
    modalLexicalEditorContext?.onShow();
    return () => {
      modalLexicalEditorContext?.onClose();
    };
  }, [modalLexicalEditorContext]);

  const updateLinkEditor = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent)) {
        setLinkUrl(parent.getURL());
      } else if ($isLinkNode(node)) {
        setLinkUrl(node.getURL());
      } else {
        setLinkUrl('');
      }
    }
    const editorElem = editorRef.current;
    const nativeSelection = window.getSelection();
    const activeElement = document.activeElement;

    if (editorElem === null) {
      return;
    }

    const rootElement = editor.getRootElement();
    if (
      selection !== null &&
      !nativeSelection?.isCollapsed &&
      rootElement !== null &&
      rootElement.contains(nativeSelection?.anchorNode)
    ) {
      const domRange = nativeSelection?.getRangeAt(0);
      let rect;
      if (nativeSelection?.anchorNode === rootElement) {
        let inner = rootElement;
        while (inner.firstElementChild != null) {
          inner = inner.firstElementChild;
        }
        rect = inner.getBoundingClientRect();
      } else {
        rect = domRange?.getBoundingClientRect();
      }

      if (!mouseDownRef.current) {
        positionEditorElement(editorElem, rect);
      }
      setLastSelection(selection);
    } else if (!activeElement || activeElement.className !== 'link-input') {
      positionEditorElement(editorElem, null);
      setLastSelection(null);
      setEditMode(false);
      setLinkUrl('');
    }

    return true;
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(
        ({ editorState }) => {
          editorState.read(() => {
            updateLinkEditor();
          });
        }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateLinkEditor();
          return true;
        },
        LowPriority
      )
    );
  }, [editor, updateLinkEditor]);

  useEffect(() => {
    editor.getEditorState().read(() => {
      updateLinkEditor();
    });
  }, [editor, updateLinkEditor]);

  useEffect(() => {
    if (isEditMode && inputRef.current) {
      inputRef.current?.focus();
    }
  }, [isEditMode]);

  const onChange = (event) => {
    setLinkUrl(event.target.value);
  };

  const onKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (lastSelection !== null) {
        if (linkUrl !== '') {
          editor.dispatchCommand(TOGGLE_LINK_COMMAND, linkUrl);
        }
        setEditMode(false);
      }
    } else if (event.key === 'Escape') {
      event.preventDefault();
      setEditMode(false);
    }
  };

  return (
    <div ref={editorRef} className={styles.FloatingLinkEditor}>
      {isEditMode ? (
        <input
          ref={inputRef}
          className={styles.input}
          value={linkUrl}
          onChange={onChange}
          onKeyDown={onKeyDown}
        />
      ) : (
        <>
          <div className={styles.preview}>
            <a
              href={linkUrl}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link}
            >
              {linkUrl}
            </a>
            <BaseButton
              className={styles.button}
              role="button"
              tabIndex={0}
              onMouseDown={(event) => event.preventDefault()}
              onClick={() => setEditMode(true)}
            >
              <Icon icon={EditIcon}/>
            </BaseButton>
          </div>
        </>
      )}
    </div>
  );
}

function positionEditorElement(editor, rect) {
  if (rect === null) {
    editor.style.opacity = '0';
    editor.style.top = '-1000px';
    editor.style.left = '-1000px';
  } else {
    editor.style.opacity = '1';
    editor.style.top = `${rect.top + rect.height + window.scrollY + 10}px`;
    editor.style.left = `${rect.left + window.scrollX - editor.offsetWidth / 2 + rect.width / 2}px`;
  }
}
