import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { userHasPermission } from '@acadeum/helpers';
import { useTranslate, useTranslateOptions } from '@acadeum/translate';
import { NavSegmented, Tabs } from '@acadeum/ui';

import actions from '../../actions';
import type { CourseEnrollmentLifecyclePhase, CourseEnrollmentMode } from '../../components/CourseEnrollmentsTable';
import { CourseEnrollmentsTable } from '../../components/CourseEnrollmentsTable';
import type { ReduxState } from '../../helpers/app.types';

const { goto } = actions;

const enrollmentLifecycleByInstitution: { [Key in CourseEnrollmentMode]: CourseEnrollmentLifecyclePhase[] } = {
  homeInstitution: ['live', 'finalized'],
  teachingInstitution: ['accepted', 'finalized']
};

interface EnrollmentsProps {
  mode: CourseEnrollmentMode;
  status: CourseEnrollmentLifecyclePhase;
}

export default function Enrollments({
  mode = 'homeInstitution',
  status = 'live'
}: EnrollmentsProps) {
  const t = useTranslate('Enrollments');
  const user = useSelector((state: ReduxState) => state.auth.user);

  const tabs = useMemo(() => [
    ...(userHasPermission(user, 'homeCourseEnrollment:read', { orgId: user.institution.id }) ? [
      {
        title: t('homeEnrollments'),
        key: 'homeInstitution',
        body: (
          <>
            <EnrollmentsNavSegmented mode="homeInstitution" lifecyclePhase={status}/>
            <br/>
            <br/>
            <CourseEnrollmentsTable mode="homeInstitution" lifecyclePhase={status}/>
          </>
        )
      }
    ] : []),
    ...(user.institution.teaching && userHasPermission(user, 'teachingCourseEnrollment:read', { orgId: user.institution.id }) ? [
      {
        title: t('teachingEnrollments'),
        key: 'teachingInstitution',
        body: (
          <>
            <EnrollmentsNavSegmented mode="teachingInstitution" lifecyclePhase={status}/>
            <br/>
            <br/>
            <CourseEnrollmentsTable mode="teachingInstitution" lifecyclePhase={status}/>
          </>
        )
      }
    ] : [])
  ], [user]);

  return (
    <Tabs
      keepMounted={false}
      title={t('title')}
      initialActiveTab={mode}
      tabs={tabs}
      onChangeTab={({ key }) => {
        const path = key === 'homeInstitution'
          ? '/enrollments/live'
          : '/enrollments/teaching/accepted';
        goto(path);
      }}
    />
  );
}

Enrollments.meta = () => ({
  title: 'Manage Enrollments'
});

Enrollments.breadcrumbs = () => [
  'Manage Enrollments'
];

interface EnrollmentsNavSegmentedProps {
  mode: CourseEnrollmentMode;
  lifecyclePhase: CourseEnrollmentLifecyclePhase;
}

function EnrollmentsNavSegmented({
  mode,
  lifecyclePhase
}: EnrollmentsNavSegmentedProps) {
  const toggleOptions = useTranslateOptions('Enrollments', enrollmentLifecycleByInstitution[mode]);
  return (
    <NavSegmented
      type="single"
      options={toggleOptions}
      value={lifecyclePhase}
      onValueChange={(type: typeof lifecyclePhase) => {
        if (type) {
          const path = mode === 'homeInstitution' ? '/enrollments' : '/enrollments/teaching';
          goto(`${path}/${type}`);
        }
      }}
    />
  );
}
