import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { useWindowSize } from '@acadeum/hooks';
import { Sticky } from '@acadeum/ui';

import Action from '../Action';
import SearchInput from '../SearchInput';

import '../Filters/Filters.sass';

export default function TableControls({
  sticky = true,
  rows,
  controls,
  filters,
  filterValues,
  setFilterValues,
  searchQuery,
  setSearchQuery,
  searchHint,
  searchAriaLabel,
  searchInputWidth,
  buttons,
  design,
  children
}) {
  const { width: windowWidth } = useWindowSize();

  sticky = windowWidth < 768 ? false : sticky;

  const Component = sticky ? Sticky : 'div';

  if (!filters && !(controls || buttons) && !setSearchQuery) {
    return children;
  }

  return (
    <>
      <Component
        position="top"
        className="data-table__controls"
        // {...componentProps}
      >
        <div className="container data-table__controls-container">
          {/* Filters */}
          {filters && filters.map((filter) => (
            <div className="Filters-filter" key={filter.id}>
              <span>
                {filter.name}
              </span>
              {filter.render({
                rows,
                value: filterValues[filter.id],
                setFilterValues,
                getFilterValue: (id) => filterValues[id]
              })}
            </div>
          ))}

          {/* A spacer which places filters on the left and other controls on the right. */}
          {design !== 'v2' &&
            <div className="data-table__controls-spacer"/>
          }

          {/* Actions */}
          {(controls || buttons) &&
            <div className="data-table__controls-other">
              {controls &&
                <TableControlsActions controls={controls}/>
              }
              {controls && buttons &&
                <div className="data-table__other-controls-spacer"/>
              }
              {buttons}
            </div>
          }

          {/* Search */}
          {setSearchQuery &&
            <SearchInput
              placeholder={searchHint}
              aria-label={searchAriaLabel}
              value={searchQuery}
              onChange={setSearchQuery}
              className={classNames('data-table__search', {
                'data-table__search--wide': searchInputWidth === 'wide'
              })}
            />
          }
        </div>
      </Component>

      {/* Using a "spacer" element instead of `margin-bottom`
          because it won't work when `react-sticky` replaces it
          with its own placeholder that has no `margin`. */}
      <div className="data-table__controls-vertical-spacer"/>

      {children}
    </>
  );
}

const controlPropType = PropTypes.oneOfType([
  PropTypes.object,
  PropTypes.func
]);

TableControls.propTypes = {
  sticky: PropTypes.bool,
  rows: PropTypes.arrayOf(PropTypes.object),
  controls: PropTypes.arrayOf(controlPropType),
  filters: PropTypes.arrayOf(PropTypes.object),
  filterValues: PropTypes.object,
  setFilterValues: PropTypes.func,
  searchQuery: PropTypes.string,
  setSearchQuery: PropTypes.func,
  searchHint: PropTypes.string,
  searchInputWidth: PropTypes.oneOf(['wide']),
  buttons: PropTypes.node,
  design: PropTypes.oneOf(['v2']),
  children: PropTypes.node.isRequired
};

function TableControlsActions({ controls }) {
  return (
    <React.Fragment>
      {controls.map((control, i) => {
        if (control.type === 'action') {
          return (
            <Action
              key={i}
              onClick={control.action}
              link={control.link}
              onNavigate={control.onNavigate}
              icon={control.icon}
              iconSize="l">
              {control.text}
            </Action>
          );
        }
        return React.createElement(control, { key: i });
      })}
    </React.Fragment>
  );
}

TableControlsActions.propTypes = {
  controls: PropTypes.arrayOf(controlPropType)
};
