import React, { useMemo } from 'react';
import type { FC } from 'react';

import { fakePresets, fakeGradientPresets } from '../constants';
import { usePicker } from '../context';

interface PresetsProps {
  isGradient: boolean;
  presets?: string[];
}

const Presets: FC<PresetsProps> = ({ isGradient, presets = [] }) => {
  const { value, onChange, handleChange } = usePicker();

  const getPresets = () => {
    if (presets?.length > 0) {
      return presets?.slice(0, 18);
    } else if (isGradient) {
      return fakeGradientPresets;
    } else {
      return fakePresets;
    }
  };

  const handlePresetClick = (preset: string) => {
    if (preset?.includes('gradient')) {
      onChange(preset);
    } else {
      handleChange(preset);
    }
  };

  const presetsListMaxWidth = useMemo(() => {
    const count = Math.ceil(getPresets().length / 2);
    return `${count * 32 + (count - 1) * 8}px`;
  }, [presets]);


  return (
    <div className="rbgcp-presets-wrap">
      <div
        style={{ background: value }}
        className="rbgcp-preset-color-preview"
      />
      <div style={{ maxWidth: presetsListMaxWidth }} className="rbgcp-presets-list">
        {getPresets().map((p: any, key: number) => (
          <div
            key={`${p}-${key}`}
            style={{
              background: p,
              border: p === 'rgba(255,255,255, 1)' ? '1px solid #96959c' : '',
              cursor: 'pointer'
            }}
            className="rbgcp-preset-color"
            onClick={() => handlePresetClick(p)}
          />
        ))}
      </div>
    </div>
  );
};

export default Presets;
