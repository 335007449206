import classNames from 'classnames';
import React from 'react';

import { Avatar } from '@acadeum/core-ui';
import type { Institution as InstitutionType } from '@acadeum/types';

import type { GetInstitutionLink } from '../../utils/institutionLink';
import { useGetInstitutionLink } from '../../utils/institutionLink';
import type { LinkProps } from '../Link';
import { Link } from '../Link';


import styles from './InstitutionLink.module.scss';

type Institution = Pick<InstitutionType, 'id' | 'vanityUrl' | 'name' | 'logoUrl'>

interface InstitutionLinkPropsWithInstitution {
  institution: Institution;
  children?: undefined;
}

interface InstitutionLinkPropsWithChildren {
  children: Institution;
  institution: undefined;
}

export type InstitutionLinkProps =
  Omit<LinkProps, 'children'>
  & (InstitutionLinkPropsWithInstitution | InstitutionLinkPropsWithChildren)
  & {
  getInstitutionLink?: GetInstitutionLink;
  includeLogo?: boolean;
  withLogo?: boolean;
};

export const InstitutionLink: React.FC<InstitutionLinkProps> = ({
  getInstitutionLink,
  institution,
  children,
  includeLogo,
  withLogo: propsWithLogo,
  className,
  ...rest
}) => {
  const withLogo = propsWithLogo || includeLogo;

  if (!institution) {
    if (typeof children !== 'object') {
      throw new Error('`<InstitutionLink/>` is supposed to receive either an `institution` property or an `institution` children.');
    }
    institution = children;
  }

  const getInstitutionLink_ = useGetInstitutionLink();
  getInstitutionLink = getInstitutionLink || getInstitutionLink_;

  const className_ = classNames(className, styles.InstitutionLink);
  const hasLogo = !!institution.logoUrl && withLogo;

  const content = (
    <span className={classNames({
      [styles.withLogo]: hasLogo
    })}>
      {withLogo && (
        <Avatar
          size={32}
          type="institution"
          url={institution.logoUrl}
        />
      )}
      {institution.name}
    </span>
  );
  if (getInstitutionLink) {
    return (
      <Link
        {...rest}
        to={getInstitutionLink(institution)}
        className={className_}
      >
        {content}
      </Link>
    );
  }

  return (
    <span
      {...rest}
      className={className_}
    >
      {content}
    </span>
  );
};
