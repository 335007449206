import PropTypes from 'prop-types';
import React from 'react';

import AdvisorRecommendationSection from '../AdvisorRecommendationSection';

export default function AdvisorRecommendationSections({
  onDemand,
  sections,
  value,
  onChange,
  selectable,
  error,
  onDemandCourseDropDateDaysAfterStartDate,
  onDemandCourseEndDateDaysAfterStartDate
}) {
  // const [selectedSections, setSelectedSections] = useState([]);
  const selectedSections = value;

  return sections.map((section) => (
    <AdvisorRecommendationSection
      key={section.id}
      section={section}
      onDemand={onDemand}
      onDemandCourseEndDateDaysAfterStartDate={onDemandCourseEndDateDaysAfterStartDate}
      onDemandCourseDropDateDaysAfterStartDate={onDemandCourseDropDateDaysAfterStartDate}
      value={selectedSections && selectedSections.includes(section.id)}
      onChange={(selected) => {
        if (selected) {
          if (!selectedSections.includes(section.id)) {
            const newValue = selectedSections.concat(section.id);
            // setSelectedSections(newValue);
            // if (onChange) {
            onChange(newValue);
            // }
          }
        } else {
          if (selectedSections.includes(section.id)) {
            const newValue = selectedSections.filter(_ => _ !== section.id);
            // setSelectedSections(newValue);
            // if (onChange) {
            onChange(newValue);
            // }
          }
        }
      }}
      selectable={selectable}
      error={error}
    />
  ));
}

AdvisorRecommendationSections.propTypes = {
  sections: PropTypes.array,
  onChange: PropTypes.func,
  selectable: PropTypes.bool,
  error: PropTypes.bool,
  onDemandCourseDropDateDaysAfterStartDate: PropTypes.number
};
