import type { FC } from 'react';

import { Tag } from '@acadeum/ui';

interface AdminUserStatusListProps {
  user: {
    emailVerified?: boolean;
    isActive?: boolean;
    singleSignOn?: boolean;
    hasPassword?: boolean;
  }
}

export const AdminUserStatusList: FC<AdminUserStatusListProps> = ({ user }) => {
  return (
    <>
      {user.emailVerified ? (
        <Tag variant="verified"/>
      ) : (
        <Tag variant="unverified"/>
      )}
      {user.isActive ? (
        <Tag variant="active"/>
      ) : (
        <Tag variant="inactive"/>
      )}
      {user.singleSignOn && (
        <Tag variant="sso"/>
      )}
      {!user.singleSignOn && !user.hasPassword && (
        <Tag variant="passwordNotSet"/>
      )}
    </>
  );
};
