// Copy-pasted from `react-instantsearch`.
// https://github.com/algolia/react-instantsearch/blob/81a6f1856ae28b4ab00ba660c548037818749220/packages/react-instantsearch-dom/src/components/Pagination.js
// Version 5.5
//
// Changes:
//
// * Added "scroll to top" feature on page button click.
// * Added a `...` item to indicate that there're "more pages" available. Starts at "// BEGIN MODIFIED CODE." and ends at "// END MODIFIED CODE.".
// * default props "ariaPage: currentRefinement => `Page ${currentRefinement.toString()}`"

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { translatable } from 'react-instantsearch-core';

import { Pagination as UIPagination } from '@acadeum/ui';

import { createClassNames } from '../core/utils';

const cx = createClassNames('Pagination');

class Pagination extends Component {
  static propTypes = {
    nbPages: PropTypes.number.isRequired,
    currentRefinement: PropTypes.number.isRequired,
    refine: PropTypes.func.isRequired,
    createURL: PropTypes.func.isRequired,
    canRefine: PropTypes.bool.isRequired,

    translate: PropTypes.func.isRequired,
    listComponent: PropTypes.func,

    showFirst: PropTypes.bool,
    showPrevious: PropTypes.bool,
    showNext: PropTypes.bool,
    showLast: PropTypes.bool,
    padding: PropTypes.number,
    totalPages: PropTypes.number,
    className: PropTypes.string
  };

  render() {
    return (
      <div
        className={classNames(cx('', !this.props.canRefine && '-noRefinement'), this.props.className)}
      >
        <UIPagination
          state={{
            pageCount: this.props.nbPages,
            currentPage: this.props.currentRefinement
          }}
          onPageChange={value => this.props.refine(value)}
          renderLink={this.props.createURL}
        />
      </div>
    );
  }
}

export default translatable({
  previous: '‹',
  next: '›',
  first: '«',
  last: '»',
  page: currentRefinement => currentRefinement.toString(),
  ariaPrevious: 'Previous page',
  ariaNext: 'Next page',
  ariaFirst: 'First page',
  ariaLast: 'Last page',
  ariaPage: currentRefinement => `Page ${currentRefinement.toString()} selected`
})(Pagination);
