import { flatten, max as getMax, groupBy, sortBy, unzip } from 'lodash-es';
import React, { useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useSelector } from 'react-redux';

import actions from '../../actions';
import '../../helpers/initializeCharts';
import { getWeek } from '../../helpers/date';

import { getGraphOptions } from './helpers';

const sum = arr => arr.reduce((a, b) => a + b);

const {
  fetchWeeklyBreakdown
} = actions;

export default function WeeklyBreakdown() {
  //eslint-disable-next-line
  const [max, setMax_] = useState();
  const chartReference = useRef();

  const weeklyBreakdown = useSelector(state => state.reports.weeklyBreakdown);

  const now = new Date();
  const currentYear = (now).getFullYear();
  const currentWeek = getWeek(now);

  const years = [];
  let year = 2016;
  while (year <= new Date().getFullYear()) {
    years.push(year);
    year++;
  }

  const labels = [];
  for (let i = 0; i <= 52; i++) {
    labels.push(i);
  }

  const createDataset = (label, sourceData) => year => {
    const data = sortBy(sourceData.filter(_ => _.year === year), 'week');
    const returnData = [];
    for (let i = 0; i <= 52; i++) {
      const d = data.filter(_ => _.week === i)[0];
      if (d) {
        returnData.push(d.count);
      } else if (year === currentYear && i >= currentWeek) {
        returnData.push(null);
      }
      else {
        returnData.push(0);
      }
    }
    return {
      ...getGraphOptions(year, returnData, { stacked: true }),
      label: `${year} ${label}`
    };
  };

  const courseRequests = years.map(createDataset('Course Request', Object.values(weeklyBreakdown[0])));
  const deniedRequests = years.map(createDataset('Denied Request', Object.values(weeklyBreakdown[1])));
  const pendingRequests = years.map(createDataset('Pending Request', Object.values(weeklyBreakdown[2])));
  const unbookedEnrollments = years.map(createDataset('Unbooked Enrollment', Object.values(weeklyBreakdown[3])));
  const bookedEnrollmentsUnpaid = years.map(createDataset('Booked Enrollment Unpaid', Object.values(weeklyBreakdown[4])));
  const bookedEnrollmentsPaid = years.map(createDataset('Booked Enrollment Paid', Object.values(weeklyBreakdown[5])));

  const data = {
    labels,
    datasets: sortBy(
      courseRequests
        .concat(deniedRequests)
        .concat(pendingRequests)
        .concat(unbookedEnrollments)
        .concat(bookedEnrollmentsUnpaid)
        .concat(bookedEnrollmentsPaid),
      ['yAxisID']
    ).reverse()
  };

  const setMax = () => {
    // Hypothetically. this function did something in some past.
    // It requires `Legend` plugin to be `register`-ed in `helpers/initializeCharts.js`.
    // Sill, with the `Legend` plugin enabled, it doesn't seem to do anything.
    // return;
    // const visible = chartReference.current.legend.legendItems.filter(_ => !_.hidden).map(_ => _.text);
    // const datasets = data.datasets.filter(_ => visible.includes(_.label));
    // const grouped = groupBy(datasets, 'yAxisID');
    // const result = getMax(flatten(Object
    //   .values(grouped)
    //   .map(_ => unzip(_.map(_ => _.data)))
    //   .map(_ => _.map(_ => sum(_)))));
    // if (result !== max) {
    //   setMax_(result);
    // }
  };

  const grouped = groupBy(data.datasets, 'yAxisID');
  let result = Object.values(grouped).map(_ => unzip(_.map(_ => _.data)));
  result = result.map(_ => _.map(_ => sum(_)));
  const m = getMax(flatten(result));

  const yAxes = years.map(_ => ({
    ticks: {
      min: 0,
      max: max || m
    },
    type: 'linear',
    display: false,
    stacked: true,
    id: _.toString()
  }));

  yAxes[0].display = true;

  const scales = {};
  let i = 0;
  while (i < yAxes.length) {
    scales[`y${i > 0 ? i : ''}`] = {
      ...yAxes[i]
    };
    i++;
  }

  // datasets: datasets.concat(datasets.map( _ => ({
  //   ..._,
  //   label: _.label+"2"
  // }))),

  // const datasetsSum = years.map(year => {
  //   const data = sortBy(weeklyBreakdown.filter(_ => _.year === year), 'week');
  //   const returnData = [];
  //   let sum = 0;
  //   for (let i = 0; i <= 52; i++) {
  //     const d = data.filter(_ => _.week === i)[0];
  //     if (d) {
  //       sum += d.count;
  //     }
  //     if (year === currentYear && i >= currentWeek) {
  //       returnData.push(null);
  //     } else {
  //       returnData.push(sum);
  //     }
  //   }
  //   return getGraphOptions(year, returnData);
  // });

  // const dataSum = {
  //   labels,
  //   datasets: datasetsSum
  // };

  // const datasetsDerivative = years.map(year => {
  //   const data = sortBy(weeklyBreakdown.filter(_ => _.year === year), 'week');
  //   const returnData = [];
  //   let pastChange = 0;
  //   for (let i = 0; i <= 52; i++) {
  //     const d = data.filter(_ => _.week === i)[0];
  //     if (d) {
  //       returnData.push(d.count - pastChange);
  //       pastChange = d.count;
  //     } else if (year === currentYear && i >= currentWeek) {
  //       returnData.push(null);
  //       pastChange = 0;
  //     }
  //     else {
  //       returnData.push(0);
  //       pastChange = 0;
  //     }
  //   }
  //   return getGraphOptions(year, returnData);
  // });

  // const dataDerivative = {
  //   labels,
  //   datasets: datasetsDerivative
  // };

  return (
    <div>
      <h1>
        Weekly Enrollment Activity: 1st Derivative
      </h1>
      <Line
        data={data}
        options={{
          scales
        }}
        ref={chartReference}
        onClick={setMax}
      />
    </div>
  );
}

WeeklyBreakdown.meta = () => ({
  title: 'Weekly Enrollment Activity: 1st Derivative'
});

WeeklyBreakdown.load = async () => {
  await fetchWeeklyBreakdown();
};

WeeklyBreakdown.breadcrumbs = () => [
  ['Admin Tools', '/admin'],
  'Weekly Enrollments'
];
