import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';

import type { Code } from '../../types';

type KeyEvent = 'keydown' | 'keyup';

export interface KeypressListenerProps {
  code: Code;
  handler(event: KeyboardEvent): void;
  keyEvent: KeyEvent;
  options?: AddEventListenerOptions;
}

const propTypes = {
  code: PropTypes.string.isRequired,
  handler: PropTypes.func.isRequired,
  keyEvent: PropTypes.oneOf(['keydown', 'keyup']).isRequired,
  options: PropTypes.object
};

export const KeypressListener = ({
  code,
  handler,
  keyEvent,
  options
}: KeypressListenerProps) => {
  const handleKeyEvent = useCallback((event: KeyboardEvent) => {
    if (event.code === code) {
      handler(event);
    }
  }, [handler, code]);

  useEffect(() => {
    document.addEventListener(keyEvent as KeyEvent, handleKeyEvent, options);
    return () => {
      document.removeEventListener(keyEvent as KeyEvent, handleKeyEvent, options);
    };
  }, [keyEvent, handleKeyEvent, options]);

  return null;
};

KeypressListener.propTypes = propTypes;
