import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { useTranslate } from '@acadeum/translate';

import ConfirmationModal from '../ConfirmationModal';
import EmailAlreadyVerifiedModal from '../EmailAlreadyVerifiedModal';
import Modal from '../Modal';
import VerifyEmail from '../VerifyEmail';

export default function VerifyEmailModal({
  verificationTokenExpired
}) {
  const t = useTranslate('VerifyEmail');

  const [showModal, setShowModal] = useState();
  const [showConfirmation, setShowConfirmation] = useState();
  const [showAlreadyVerifiedModal, setShowAlreadyVerifiedModal] = useState();

  const onAfterSubmitVerifyEmail = () => {
    setShowModal(false);
    setShowConfirmation(true);
  };

  const onAlreadyVerified = () => {
    setShowModal(false);
    setShowAlreadyVerifiedModal(true);
  };

  useEffect(() => {
    if (verificationTokenExpired) {
      setShowModal(true);
    }
  }, [verificationTokenExpired]);

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}>
        <VerifyEmail
          verificationTokenExpired={verificationTokenExpired}
          onAfterSubmit={onAfterSubmitVerifyEmail}
          onAlreadyVerified={onAlreadyVerified}
        />
      </Modal>

      <ConfirmationModal
        onHide={() => setShowConfirmation(false)}
        show={showConfirmation}
        title={t('confirmation.title')}
        text={t('confirmation.text')}
      />

      <EmailAlreadyVerifiedModal
        show={showAlreadyVerifiedModal}
        onHide={() => setShowAlreadyVerifiedModal()}
      />
    </>
  );
}

VerifyEmailModal.propTypes = {
  verificationTokenExpired: PropTypes.bool
};
