import formatCountry_ from 'common-lib/lib/formatCountry';
import formatCourseLevel_ from 'common-lib/lib/formatCourseLevel';
import formatCurrency_ from 'common-lib/lib/formatCurrency';
import formatDate_ from 'common-lib/lib/formatDate';
import formatEthnicity_ from 'common-lib/lib/formatEthnicity';
import formatGender_ from 'common-lib/lib/formatGender';
import { default as formatName_ } from 'common-lib/lib/formatName';
import formatNumber_ from 'common-lib/lib/formatNumber';
import formatPercent_ from 'common-lib/lib/formatPercent';
import formatRaces_ from 'common-lib/lib/formatRaces';
import formatStudentLevel_ from 'common-lib/lib/formatStudentLevel';
import formatTerm_ from 'common-lib/lib/formatTerm';
import formatYesOrNo_ from 'common-lib/lib/formatYesOrNo';

import { getCountryRegionLabel as getCountryRegionLabel_ } from '@acadeum/helpers';

import { getTranslationLanguage, t } from '../translate';

export { default as formatName } from 'common-lib/lib/formatName';
export { default as formatPhone } from 'common-lib/lib/formatPhone';
export { default as formatContactPhone } from 'common-lib/lib/formatContactPhone';

export function formatYesOrNo(value) {
  return formatYesOrNo_(value, { language: getTranslationLanguage() });
}

export function formatCountry(country) {
  return formatCountry_(country, { language: getTranslationLanguage() });
}

export function formatGender(gender) {
  return formatGender_(gender, { language: getTranslationLanguage() });
}

export function formatStudentLevel(level) {
  return formatStudentLevel_(level, { language: getTranslationLanguage() });
}

export function formatCourseLevel(level) {
  return formatCourseLevel_(level, { language: getTranslationLanguage() });
}

export function formatTerm(term) {
  return formatTerm_(term, { language: getTranslationLanguage() });
}

export function formatRaces(races, { separator } = {}) {
  return formatRaces_(races, {
    language: getTranslationLanguage(),
    separator
  });
}

export function formatEthnicity(ethnicity) {
  return formatEthnicity_(ethnicity, { language: getTranslationLanguage() });
}

export function formatDate(value, options) {
  return formatDate_(value, {
    ...options,
    locale: getTranslationLanguage()
  });
}

export function formatCurrency(value, options) {
  return formatCurrency_(value, {
    ...options,
    locale: getTranslationLanguage()
  });
}

export function formatNumber(value, options) {
  return formatNumber_(value, {
    ...options,
    locale: getTranslationLanguage()
  });
}

export function formatPercent(value, options) {
  return formatPercent_(value, {
    ...options,
    locale: getTranslationLanguage()
  });
}

export function getCountryRegionLabel(country) {
  return getCountryRegionLabel_(country, { language: getTranslationLanguage() });
}

export function formatUserName(user) {
  // If it's a person.
  if (user.firstName) {
    return formatName_(user);
  }
  // If it's a "Group User".
  return user.title;
}

export function formatReason(reason, reasonNotes, messagesPath) {
  if (reason) {
    if (reason === 'OTHER') {
      return reasonNotes;
    }
    return t(`${messagesPath}.options.${reason}`);
  }
}
