/* eslint-disable @typescript-eslint/no-explicit-any */
import { get, set, unset } from 'lodash-es';

import { parseDates } from './parseDates';

export function getDataFromStorage(
  key: string,
  { type, useSessionStorage }: { type?: 'string'; useSessionStorage?: boolean } = {}
): null | any {
  if (!hasStorage({ useSessionStorage })) {
    return null;
  }
  let data = getStorage({ useSessionStorage }).getItem(key);
  if (data === null) {
    return null;
  }
  if (type !== 'string') {
    data = JSON.parse(data);
    // Convert stringified `Date` ISO timestamps to `Date` objects.
    parseDates(data);
  }
  return data as any;
}

export function saveDataToStorage(
  key: string,
  data: any,
  { useSessionStorage }: { useSessionStorage?: boolean } = {}
) {
  if (!hasStorage({ useSessionStorage })) {
    return;
  }
  if (typeof data !== 'string') {
    data = JSON.stringify(data);
  }
  getStorage({ useSessionStorage }).setItem(key, data);
}

export function removeDataFromStorage(
  key: string,
  { useSessionStorage }: { useSessionStorage?: boolean } = {}
) {
  if (!hasStorage({ useSessionStorage })) {
    return;
  }
  getStorage({ useSessionStorage }).removeItem(key);
}

export function saveDataToStorageWithExpiry(
  key: string,
  value: any,
  { expiresIn, useSessionStorage }: { expiresIn: number; useSessionStorage?: boolean }
) {
  if (!hasStorage({ useSessionStorage })) {
    return;
  }
  const item = {
    value: value,
    expiresAt: Date.now() + expiresIn
  };
  getStorage({ useSessionStorage }).setItem(key, JSON.stringify(item));
}

export function getDataFromStorageWithExpiry(key: string, { useSessionStorage }: { useSessionStorage?: boolean } = {}) {
  if (!hasStorage({ useSessionStorage })) {
    return null;
  }

  const itemStr = getStorage({ useSessionStorage }).getItem(key);

  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  if (Date.now() > item.expiresAt) {
    getStorage({ useSessionStorage }).removeItem(key);
    return null;
  }
  return item.value;
}

export function hasLocalStorage() {
  try {
    return typeof localStorage !== 'undefined' && localStorage !== null;
  } catch (error) {
    // SecurityError
    // Failed to read the 'localStorage' property from 'Window': Access is denied for this document.
    console.error(error);
    return false;
  }
}

function hasSessionStorage() {
  try {
    return typeof sessionStorage !== 'undefined' && sessionStorage !== null;
  } catch (error) {
    // SecurityError
    // Failed to read the 'sessionStorage' property from 'Window': Access is denied for this document.
    console.error(error);
    return false;
  }
}

function hasStorage({ useSessionStorage }: { useSessionStorage?: boolean }) {
  if (useSessionStorage) {
    return hasSessionStorage();
  } else {
    return hasLocalStorage();
  }
}

function getStorage({ useSessionStorage }: { useSessionStorage?: boolean }) {
  if (useSessionStorage) {
    // `sessionStorage` is specific to a browser tab.
    // It gets cleared when the tab is closed.
    // developer.mozilla.org/docs/Web/API/Window/sessionStorage
    return sessionStorage;
  }
  return localStorage;
}

export function saveDeepDataToStorage(
  key: string,
  value: any,
  { useSessionStorage }: { useSessionStorage?: boolean } = {}
) {
  if (!hasStorage({ useSessionStorage })) {
    return;
  }
  const [firstKey, ...otherKeys] = key.split('.');
  const data = getDataFromStorage(firstKey, { useSessionStorage }) || {};
  set(data, otherKeys, value);
  saveDataToStorage(firstKey, data);
}

export function removeDeepDateFromStorage(
  key: string,
  { useSessionStorage }: { useSessionStorage?: boolean } = {}
) {
  if (!hasStorage({ useSessionStorage })) {
    return;
  }
  const [firstKey, ...otherKeys] = key.split('.');
  const data = getDataFromStorage(firstKey, { useSessionStorage });
  if (!data) {
    return;
  }
  unset(data, otherKeys);
  saveDataToStorage(firstKey, data);
}

export function getDeepDataFromStorage<V>(
  key: string,
  { useSessionStorage }: { useSessionStorage?: boolean } = {}
): V | null {
  if (!hasStorage({ useSessionStorage })) {
    return null;
  }
  const [firstKey, ...otherKeys] = key.split('.');
  const data = getDataFromStorage(firstKey, { useSessionStorage }) || {};
  return get(data, otherKeys);
}
