import { debounce } from 'lodash-es';
import type { MutableRefObject } from 'react';
import React, { memo, useCallback, useMemo, useRef } from 'react';
import type { CropperRef, RawAspectRatio, DrawOptions } from 'react-advanced-cropper';
import { CircleStencil, Cropper, RectangleStencil } from 'react-advanced-cropper';

import { ArrowRotateLeftIcon } from '@acadeum/icons';
import type { URLOrDataURL } from '@acadeum/types';

import { Button } from '../Button';

import styles from './PictureCrop.module.scss';

type PictureCropVariant = 'circle' | 'square' | 'rectangle';

export interface PictureCropProps {
  drawOptions?: DrawOptions;
  aspectRatio?: RawAspectRatio;
  variant: PictureCropVariant;
  image?: URLOrDataURL;
  onChange?: (picture?: URLOrDataURL) => void;
}

export const PictureCrop = memo<PictureCropProps>(({
  variant = 'rectangle',
  drawOptions,
  aspectRatio,
  image,
  onChange: propsOnChange
}) => {
  const cropperRef = useRef() as MutableRefObject<CropperRef>;

  const stencilComponent = useMemo(() => {
    return variant === 'circle' ? CircleStencil : RectangleStencil;
  }, [variant]);

  const imageMimeType = useMemo(() => {
    return image && image.substring(image.indexOf(':') + 1, image.indexOf(';'));
  }, [image]);

  const onChange = useCallback(debounce((cropper: CropperRef) => {
    const dataUrl = cropper?.getCanvas(drawOptions)?.toDataURL(imageMimeType);
    if (propsOnChange) {
      propsOnChange(dataUrl);
    }
  }, 500), [propsOnChange, drawOptions, imageMimeType]);

  const rotate = useCallback(() => {
    cropperRef.current.rotateImage(-90);
  }, [cropperRef]);

  return (
    <div className={styles.wrapper}>
      <Cropper
        ref={cropperRef}
        src={image}
        onChange={onChange}
        className={styles.cropper}
        stencilComponent={stencilComponent}
        stencilProps={{
          aspectRatio,
          lines: true,
          grid: variant !== 'circle',
          boundingBoxClassName: styles.boundingBox,
          overlayClassName: styles.stencilOverlay,
          handlerClassNames: {
            default: styles.stencilHandler,
            north: styles['stencilHandler--north'],
            eastNorth: styles['stencilHandler--eastNorth'],
            east: styles['stencilHandler--east'],
            eastSouth: styles['stencilHandler--eastSouth'],
            south: styles['stencilHandler--south'],
            westSouth: styles['stencilHandler--westSouth'],
            west: styles['stencilHandler--west'],
            westNorth: styles['stencilHandler--westNorth']
          }
        }}
      />
      <div>
        <Button
          icon={ArrowRotateLeftIcon}
          variant="text"
          onClick={rotate}
        >
          Rotate
        </Button>
      </div>
    </div>
  );
});

PictureCrop.displayName = 'AvatarCrop';

