import { pick } from 'lodash-es';
import type { FC } from 'react';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import formatUserName from 'common-lib/lib/formatUserName';

import type { FetchUserRolesOutput } from '@acadeum/api';
import type { UseCreateUserMutation } from '@acadeum/api';
import { getAuthSelector } from '@acadeum/auth';
import { getErrorData, isAcadeumAdministrator, FormError } from '@acadeum/helpers';
import { useTranslate } from '@acadeum/translate';


import {
  Button,
  Col,
  ContentSection,
  Email,
  Form,
  FormField,
  FormFooter,
  FormSubmit,
  Row,
  Separator,
  toast
} from '@acadeum/ui';

import { useSettingsRoutes } from '../../../../../hooks/useSettingsRoutes';
import { useNavigate } from '../../../../../providers/useNavigate';

import styles from './AddUserForm.module.scss';

interface UserFormData {
  email: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  institutionId?: number;
  phone: string;
  phoneExt: string;
  title: string;
  roleIds: number[];
  sftp?: boolean;
  type: 'GROUP' | 'INDIVIDUAL';
}

interface FormValues {
  groupOrOfficeAccount?: boolean;
  title: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  email: string;
  phone: string;
  phoneExt: string;
  roleId: number;
  sftp?: string;
}

interface FormData {
  data: UserFormData,
  clearForm: () => void
}

export interface AddUserFormProps {
  roles: FetchUserRolesOutput['results'];
  useCreateUserMutation: UseCreateUserMutation;
}

export const AddUserForm: FC<AddUserFormProps> = ({
  roles,
  useCreateUserMutation
}) => {
  const [createUserMutation] = useCreateUserMutation();

  const [groupOrOfficeAccount, setGroupOrOfficeAccount] = useState(false);
  const user = useSelector(getAuthSelector('user'));
  const router = useNavigate();
  const { getUserDetailsUrl, getUserManagementUrl } = useSettingsRoutes();

  const t = useTranslate('shared-admin-ui.AddUserForm');

  const roleOptions = useMemo(() => {
    return roles.map((role) => ({
      value: role.id,
      label: role.name,
      description: role.description
    }));
  }, [roles]);

  const onCreateUser = async ({ data, clearForm }: FormData) => {
    try {
      const { id } = await createUserMutation({
        ...data,
        redirectToWebsite: 'account'
      }).unwrap();
      toast.success(t('successMessage', {
        userName: <strong>{formatUserName(data)}'s</strong>
      }));
      clearForm();
      void router.push(`${getUserDetailsUrl(id)}?tab=info`);
    } catch (error: unknown) {
      const errorData = getErrorData(error);
      if (errorData.status === 409 || errorData.code === 'email_occupied') {
        toast.error(<>{t('errors.email_occupied', { email: (address) => <Email address={address}/> })}</>);
        throw new Error();
      }
      throw error;
    }
  };

  const onSubmit = async (values: FormValues, { clearForm }) => {
    const userProperties = pick(values, [
      'firstName',
      'lastName',
      'middleName',
      'title',
      'email',
      'phone',
      'phoneExt'
    ]);

    const data: UserFormData = {
      ...userProperties,
      type: values.groupOrOfficeAccount ? 'GROUP' : 'INDIVIDUAL',
      roleIds: [values.roleId]
    };

    if (isAcadeumAdministrator(user)) {
      data.sftp = values.sftp === 'true';
      data.institutionId = user?.institution.id;
    }

    if (data.roleIds.length === 0) {
      throw new FormError(t('noRoleChosen'));
    }

    return onCreateUser({ data, clearForm });
  };

  const onAccountTypeChange = (e) => {
    setGroupOrOfficeAccount(e.target.checked);
  };

  return (
    <Form className={styles.CreateUserForm} onSubmit={onSubmit}>
      <div className={styles.CreateUserForm__inner}>
        <ContentSection title={t('accountProperties')}>
          <FormField
            type="switch"
            name="groupOrOfficeAccount"
            label={t('groupAccount')}
            onChange={onAccountTypeChange}
          />
        </ContentSection>
        <Separator mt="none"/>
        <ContentSection title={t('personalInformation')}>
          <Row>
            {!groupOrOfficeAccount && (
              <>
                <Col md={6}>
                  <FormField
                    required
                    name="firstName"
                    label={t('firstName')}
                    placeholder={t('firstNamePlaceholder')}
                  />
                </Col>
                <Col md={6}>
                  <FormField
                    required
                    name="lastName"
                    label={t('lastName')}
                    placeholder={t('lastNamePlaceholder')}
                  />
                </Col>
                <Col md={6}>
                  <FormField
                    name="middleName"
                    label={t('middleName')}
                    placeholder={t('middleNamePlaceholder')}
                  />
                </Col>
              </>
            )}
            <Col md={6}>
              <FormField
                required
                name="title"
                label={t('userTitle')}
                placeholder={t('userTitlePlaceholder')}
              />
            </Col>
            <Col md={groupOrOfficeAccount ? 6 : 12}>
              <FormField
                required
                name="email"
                label={t('email')}
                placeholder={t('emailPlaceholder')}
              />
            </Col>
            <Col md={6}>
              <FormField
                required
                type="phone"
                name="phone"
                label={t('phone')}
                placeholder={t('phonePlaceholder')}
              />
            </Col>
            <Col md={6}>
              <FormField
                name="phoneExt"
                label={t('phoneExt')}
                placeholder={t('phoneExtPlaceholder')}
              />
            </Col>
          </Row>
        </ContentSection>
        <Separator mt="none"/>
        <ContentSection title={t('role', { global: true })}>
          <FormField
            required
            sameWidth
            type="select"
            name="roleId"
            label={t('userRole')}
            placeholder={t('userRolePlaceholder')}
            options={roleOptions}
          />
        </ContentSection>
        <Separator mt="none"/>
        {isAcadeumAdministrator(user) && (
          <ContentSection title={t('dataTransfer')}>
            <FormField
              required
              border
              type="radio"
              name="sftp"
              label={t('SFTPAccess')}
              defaultValue={false}
              options={[
                {
                  value: false,
                  label: 'No'
                }, {
                  value: true,
                  label: 'Yes'
                }
              ]}
            />
            <FormField
              required
              border
              type="radio"
              name="courseImport"
              label={t('courseUploads')}
              defaultValue={false}
              options={[
                {
                  value: false,
                  label: 'No'
                }, {
                  value: true,
                  label: 'Yes'
                }
              ]}
            />
          </ContentSection>
        )}
      </div>
      <FormFooter>
        <Button variant="secondary" url={getUserManagementUrl()}>
          {t('cancel', { global: true })}
        </Button>
        <FormSubmit>
          {t('create', { global: true })}
        </FormSubmit>
      </FormFooter>
    </Form>
  );
};
