import React from 'react';
import { useSelector } from 'react-redux';

import { T, useTranslate } from '@acadeum/translate';

import actions from '../../actions';
import getHubSpotCookieValue from '../../helpers/getHubSpotCookieValue';
import useRequestMoreInfoPublicMarketingPageType from '../../hooks/useRequestMoreInfoPublicMarketingPageType';
import Button from '../Button';
import FormField from '../FormField';
import FormModal from '../FormModal';


import styles from './RequestMoreInfoModal.module.scss';

const {
  notify,
  submitHubSpotForm,
  setShowRequestMoreInfoModal
} = actions;

const HUBSPOT_FORM_ID = 'd17c4868-c775-47b9-9d92-2800e8779105';

export default function RequestMoreInfoModal() {
  const t = useTranslate('RequestMoreInfoModal');

  const { showRequestMoreInfoModal } = useSelector(state => state.app);

  const pageType = useRequestMoreInfoPublicMarketingPageType();

  const onSubmit = async ({
    userType,
    jobTitle,
    firstName,
    lastName,
    email,
    institutionName
  }) => {
    // This "payload" is sent to HubSpot.
    // https://github.com/Acadeum/Tickets/issues/655#issuecomment-1011306024
    const payload = {
      'submittedAt': Date.now(), // This millisecond timestamp is optional.
      'fields': [
        {
          'objectTypeId': '0-1',
          'name': 'acs_user_type',
          'value': userType === 'student' ? 'a student user' : 'an institution user'
        },
        ...(userType === 'student' ? [] : [{
          'objectTypeId': '0-1',
          'name': 'jobtitle',
          'value': jobTitle
        }]),
        {
          'objectTypeId': '0-1',
          'name': 'firstname',
          'value': firstName
        },
        {
          'objectTypeId': '0-1',
          'name': 'lastname',
          'value': lastName
        },
        {
          'objectTypeId': '0-1',
          'name': 'email',
          'value': email
        },
        {
          'objectTypeId': '0-1',
          'name': 'school_name',
          'value': institutionName
        }
      ],
      'context': {
        'hutk': getHubSpotCookieValue(),
        'pageUri': `${window.location.host}${window.location.pathname}`,
        'pageName': pageType
      }
    };

    await submitHubSpotForm(HUBSPOT_FORM_ID, payload);
  };

  const onAfterSubmit = () => {
    notify('Thank you for your request. An Acadeum rep will be with you shortly.');
  };

  return (
    <FormModal
      title={t('title')}
      description={t('description')}
      submitText={t('submit')}
      onSubmit={onSubmit}
      onAfterSubmit={onAfterSubmit}
      show={showRequestMoreInfoModal}
      onHide={() => setShowRequestMoreInfoModal()}>
      {({ watch }) => {
        const userType = watch('userType');
        return (
          <>
            <section>
              <h2 className={styles.RequestMoreInfoModal__sectionTitle}>
                {t('selectUserType.title')}
              </h2>
              <FormField
                required
                type="radio"
                name="userType"
                label={t('selectUserType.title')}
                labelHidden
                options={[{
                  value: 'student',
                  label: t('selectUserType.student')
                }, {
                  value: 'institution',
                  label: t('selectUserType.institution')
                }]}
              />
              {userType === 'institution' &&
                <FormField
                  required
                  label={t('selectUserType.form.jobTitle.label')}
                  name="jobTitle"
                />
              }
            </section>
            <section>
              <h2 className={styles.RequestMoreInfoModal__sectionTitle}>
                {t('provideMoreInfo.title')}
              </h2>
              <FormField
                required
                name="firstName"
                type="name"
                label={t('provideMoreInfo.form.firstName.label')}
                placeholder={t('provideMoreInfo.form.firstName.hint')}
              />
              <FormField
                required
                name="lastName"
                type="name"
                label={t('provideMoreInfo.form.lastName.label')}
                placeholder={t('provideMoreInfo.form.lastName.hint')}
              />
              <FormField
                required
                name="email"
                type="email"
                label={t('provideMoreInfo.form.email.label')}
                placeholder={t('provideMoreInfo.form.email.hint')}
              />
              <FormField
                required
                name="institutionName"
                label={t('provideMoreInfo.form.institution.label')}
                placeholder={t('provideMoreInfo.form.institution.hint')}
              />
            </section>
            <div className={styles.RequestMoreInfoModal__cancel}>
              <T
                label="RequestMoreInfoModal.cancel"
                button={(children) => (
                  <Button
                    text
                    onClick={() => setShowRequestMoreInfoModal()}
                    className={styles.RequestMoreInfoModal__cancelButton}>
                    {children}
                  </Button>
                )}
              />
            </div>
          </>
        );
      }}
    </FormModal>
  );
}
