import type { RtkApi } from '@acadeum/redux';
import type { Id, Institution, InstitutionContactUser, Marketplace } from '@acadeum/types';

export interface FetchInstitutionInput {
  id: Institution['id'] | Institution['vanityUrl'];
  include?: (
    'primaryContact' |
    'enrollingStudentContact' |
    'teachingStudentContact' |
    'marketplace' |
    'accreditations' |
    'affiliations'
  )[];
  edit?: boolean;
}

export interface FetchInstitutionOutput extends Pick<Institution,
  'id' |
  'name' |
  'logoUrl' |
  'fullName' |
  'shortName' |
  'description' |
  'level' |
  'funding' |
  'vanityUrl' |
  'city' |
  'state' |
  'postalCode' |
  'addressLine1' |
  'addressLine2' |
  'country' |
  'teachingStudentWelcomeLetter' |
  'teachingStudentWelcomeLetterForMarketplace' |
  'isTeaching' |
  'url' |
  'public' |
  'onDemandCourseDropDateDaysAfterStartDate' |
  'onDemandCourseEndDateDaysAfterStartDate' |
  'qualityMattersDesignation'
> {
  data: Pick<Institution['data'], 'academicCalendar' | 'academicCalendarUrl' | 'gradingScale' | 'gradingScaleUrl' | 'gradingScaleNotes' | 'affiliations' | 'levels' | 'accreditations' | 'membership' | 'partnership'>;
  primaryContact?: InstitutionContactUser;
  enrollingStudentContact?: InstitutionContactUser;
  teachingStudentContact?: InstitutionContactUser;
  marketplace?: Pick<Marketplace, 'id'>
}

export interface FetchInstitutionForEditingOutput extends Institution {
  isActive: boolean;
  acceptedPaymentMethod: string;
  consortialEnrollmentHomeInstitutionIds?: number[];
  // eslint-disable-next-line
  consortialEnrollmentHomeInstitutions?: any;
  costPerCreditHour: number;
  description?: string;
  emailDomains: string[];
  featuredInStudentApp: boolean;
  stripeDestinationId?: string;
  studentApp: boolean;
  studentCostPerCreditHour: number;
  studentInformationSystem?: string;
  studentPersonalDataProtection: boolean;
  // eslint-disable-next-line
  systemAdministratorContact?: any;
  transcriptRequestUrl?: string;
  // eslint-disable-next-line
  webhooks?: any;
}

interface DeleteInstitutionInput {
  id: Id;
}

interface FetchInstitutionCourseAdditionalInfoInput {
  id: Id;
  courseId: Id;
}

export const injectInstitutionsEndpoints = (rtkApi: RtkApi) => rtkApi
  .injectEndpoints({
    endpoints: build => ({
      fetchInstitution: build.query<FetchInstitutionOutput, FetchInstitutionInput>({
        query: ({ id, ...params }) => ({
          url: `/institutions/${id}`,
          params
        })
      }),
      fetchInstitutionForEditing: build.query<FetchInstitutionForEditingOutput, {id: Id}>({
        query: ({ id }) => ({
          url: `/institutions/${id}`,
          method: 'GET',
          params: {
            edit: '✓',
            include: ['accreditations', 'affiliations']
          }
        })
      }),
      institutionSetGroups: build.mutation<void, {id: Id, groupIds: Id[]}>({
        query: ({ id, groupIds }) => ({
          url: `/institutions/${id}/groups`,
          method: 'POST',
          body: {
            groupIds
          }
        })
      }),
      createInstitution: build.mutation<Institution, Institution>({
        query: body => ({
          url: '/institutions',
          method: 'POST',
          body
        })
      }),
      deleteInstitution: build.mutation<void, DeleteInstitutionInput>({
        query: ({ id }) => ({
          url: `/institutions/${id}`,
          method: 'DELETE'
        })
      }),
      fetchInstitutionCourseAdditionalInfo: build.query<void, FetchInstitutionCourseAdditionalInfoInput>({
        query: ({ id, courseId }) => ({
          url: `/institutions/${id}/courses/${courseId}`,
          method: 'get'
        })
      })
    })
  });

export type UseFetchInstitutionQuery = ReturnType<typeof injectInstitutionsEndpoints>['useFetchInstitutionQuery'];
export type UseLazyFetchInstitutionQuery = ReturnType<typeof injectInstitutionsEndpoints>['useLazyFetchInstitutionQuery'];
