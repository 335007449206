import type { ParsedUrlQuery, ParsedUrlQueryInput } from 'querystring';

import { useCallback } from 'react';

// eslint-disable-next-line
interface QueryParams { [key: string]: any | undefined }

export type UseQueryParametersCallback = (
  queryParams: QueryParams,
  options: { removeQueryParameters: (parametersToRemove: QueryParams) => void; }
) => void

export interface UseQueryParametersOptions {
  query: ParsedUrlQuery;
  useRouterReady: (callback: () => void) => void;
  replaceQuery: (query?: string | ParsedUrlQueryInput | null | undefined) => void;
}

export type UseQueryParametersHook = (
  callback: UseQueryParametersCallback
) => void

/**
 * Reads URL query parameters when the application has loaded.
 * @param {function} [callback] — NBSPGets called once, after the page has loaded.
 * The first argument is an object with URL query parameters.
 * The second argument is an object having a function called `removeQueryParameters()` that receives an object of query
 *   parameter names which should be removed from the current page's URL.
 * @param {object} [options.query] — URL query parameters.
 * @param {function} [options.replaceQuery] — Replaces URL query parameters in the web browser's address bar.
 * @param {function} [options.useRouterReady] — A hook that calls the `callback()` when the client-side "router" has
 *   been "initialized", whatever that means. That's just a workaround for Next.js's weird behavior when its `router`
 *   can't be used right away.
 */
export function useQueryParameters(callback: UseQueryParametersCallback, {
  query,
  replaceQuery,
  // For some weird reason, at the Student site, which uses `next.js`,
  // query parameters aren't immediately available in `router.query`
  // on page load. Instead, they become available later, after the router
  // is "ready", whatever they mean by that concept.
  useRouterReady
}: UseQueryParametersOptions) {
  const removeQueryParameters = useCallback((parametersToRemove) => {
    // This is not required when `removeQueryParameters()` only runs after
    // the component has mounted, because components don't get mounted on server side.
    // // Calling `router.replace()` in Next.js on server side would throw an error:
    // // No router instance found. you should only use "next/router" inside the client side of your app.
    // // https://nextjs.org/docs/messages/no-router-instance
    // if (typeof window === 'undefined') {
    //   return;
    // }
    // Don't modify the `location`'s query directly,
    // because `location` object is supposed to be "immutable".
    const newQuery: ParsedUrlQueryInput = {};
    for (const parameterName in query) {
      if (!(parameterName in parametersToRemove)) {
        newQuery[parameterName] = query[parameterName];
      }
    }
    replaceQuery(newQuery);
  }, [
    query,
    replaceQuery
  ]);

  const checkQueryParameters = useCallback(() => {
    const values: QueryParams = {};
    for (const name in query) {
      let value = query[name];
      if (value === '') {
        value = undefined;
      }
      if (value !== undefined) {
        values[name] = value;
      }
    }
    callback(values, { removeQueryParameters });
  }, [
    query,
    callback,
    removeQueryParameters
  ]);

  // Run the `callback()` asynchronously,
  // i.e. not during the intial render,
  // because `callback()` is likely to contain `setState()` calls,
  // and those aren't allowed (strictly speaking) by React during render.
  //
  // Also, for some weird reason, at the Student site, which uses `next.js`,
  // query parameters aren't immediately available in `router.query`
  // on page load. Instead, they become available later, after the router
  // is "ready", whatever they mean by that concept.
  // So it's not just `useEffect(checkQueryParameters, [])`.
  //
  useRouterReady(checkQueryParameters);
}
