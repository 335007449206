import { cloneDeep } from 'lodash-es';
import { createConnector } from 'react-instantsearch-dom';

import { Select } from '@acadeum/ui';

import { getCourseSearchAttributeName } from '../getCourseSearchAttributeName';

import { OldDesigner } from './OldDesigner';

export const getCourseDivisionAttributeNameByView = (view) => {
  return getCourseSearchAttributeName('session.course.division', view);
};

function _refine(props, searchState, nextRefinement) {
  const courseDivisionAttributeName = getCourseDivisionAttributeNameByView(searchState.view);
  return {
    ...searchState,
    [courseDivisionAttributeName]: nextRefinement
  };
}

const connectWithQuery = createConnector({
  displayName: 'CourseDivisionFilter',
  getProvidedProps(props, searchState) {
    const courseDivisionAttributeName = getCourseDivisionAttributeNameByView(searchState.view);
    const currentRefinement = searchState[courseDivisionAttributeName] || [];
    return { currentRefinement };
  },
  refine(props, searchState, nextRefinement) {
    return _refine(props, searchState, nextRefinement);
  },
  getSearchParameters(searchParameters, props, searchState_) {
    // Create a deep clone of searchState to avoid mutating the original object.
    // Mutating the original searchState directly causes errors like:
    // "Cannot assign to read only property '0' of object '[object Array]'"
    // https://acadeum.atlassian.net/browse/CS-480
    const searchState = cloneDeep(searchState_);
    const courseDivisionAttributeName = getCourseDivisionAttributeNameByView(searchState.view);
    const filters = (searchState[courseDivisionAttributeName] || [])
      .sort((a, b) => a.from - b.from)
      .map(({ from, to }) => {
        if (to === undefined) {
          return `${courseDivisionAttributeName} >= ${from}`;
        }
        return `${courseDivisionAttributeName} >= ${from} AND ${courseDivisionAttributeName} <= ${to}`;
      })
      .join(' OR ');

    return searchParameters.setQueryParameter('filters', filters);
  },
  cleanUp(props, searchState_) {
    const nextSearchState = cloneDeep(searchState_);
    const courseDivisionAttributeName = getCourseDivisionAttributeNameByView(searchState_.view);
    delete nextSearchState[courseDivisionAttributeName];
    return nextSearchState;
  },
  getMetadata(props, searchState) {
    const courseDivisionAttributeName = getCourseDivisionAttributeNameByView(searchState.view);
    return {
      id: courseDivisionAttributeName,
      items: (searchState[courseDivisionAttributeName] || []).map(({ from, to }) => ({
        label: `Course Division: ${to === undefined ? `${from} - ` : `${from} - ${to}`}`,
        attribute: courseDivisionAttributeName,
        value: function (nextState) {
          // Remove item from the list
          const nextRefinement = (nextState[courseDivisionAttributeName] || []).filter(
            refinement => refinement.from !== from || refinement.to !== to
          );
          return _refine(props, nextState, nextRefinement);
        }
      }))
    };
  }
});

const items = [
  { label: '0 - 99', from: 0, to: 99 },
  { label: '100 - 199', from: 100, to: 199 },
  { label: '200 - 299', from: 200, to: 299 },
  { label: '300 - 399', from: 300, to: 399 },
  { label: '400 - 499', from: 400, to: 499 },
  { label: '500 - 999', from: 500, to: 999 },
  { label: '1000 - 1999', from: 1000, to: 1999 },
  { label: '2000 - 2999', from: 2000, to: 2999 },
  { label: '3000 - 3999', from: 3000, to: 3999 },
  { label: '4000 - 4999', from: 4000, to: 4999 },
  { label: '5000 - 9999', from: 5000, to: 9999 },
  { label: '10000 - Any', from: 10000 }
];

const options = items.map(({ label, from, to }) => ({
  label,
  value: to === undefined ? `${from} - ` : `${from} - ${to}`,
  from,
  to
}));

const DivisionSelect = ({ currentRefinement = [], refine, newDesigner }) => {
  const onChange = (values) => {
    const selectedOptions = values?.map(value => options.find(option => option.value === value));
    refine(selectedOptions);
  };

  const selectedValues = currentRefinement.map(({ from, to }) => to === undefined ? `${from} - ` : `${from} - ${to}`);
  if (newDesigner) {
    return (
      <Select
        isFilter
        multiple
        label="Course Division"
        value={selectedValues}
        onChange={onChange}
        options={options}
      />
    );
  }

  return (
    <OldDesigner
      value={selectedValues}
      options={options}
      onChange={onChange}
    />
  );
};

export const DivisionFilter = connectWithQuery(DivisionSelect);
