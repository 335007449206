import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Avatar } from '@acadeum/core-ui';

import { ArrowUpRightFromSquareIcon } from '@acadeum/icons';
import { useTranslate } from '@acadeum/translate';

import type { TabsProps } from '@acadeum/ui';

import { Button, Link, Tabs, Tag, Title } from '@acadeum/ui';

import actions from '../../actions';
import CourseSearch from '../../components/CourseSearch';
import InstitutionSearch from '../../components/InstitutionSearch';
import WithPublicMarketingPageBanner from '../../components/WithPublicMarketingPageBanner';


import type { AppPage, ReduxState } from '../../helpers/app.types';
import isAcadeumAdministrator from '../../helpers/isAcadeumAdministrator';


import styles from './Consortium.module.scss';
import GeneralInfo from './ui/GeneralInfo';


const {
  fetchConsortium,
  requestToJoinConsortium,
  notify
} = actions;

const Consortium: AppPage = () => {
  const t = useTranslate('Consortium');

  const { consortium } = useSelector((state: ReduxState) => state.consortia);
  const { user } = useSelector((state: ReduxState) => state.auth);

  async function requestToJoin() {
    await requestToJoinConsortium(consortium.id);
    notify('Your join request has been sent.');
  }

  const tabs: TabsProps['tabs'] = useMemo(() => [
    {
      title: t('generalInfo'),
      key: 'general-info',
      body: (
        <GeneralInfo
          consortium={consortium}
          requestToJoin={requestToJoin}
          user={user}
        />
      )
    },
    {
      title: t('memberInstitutions'),
      key: 'member-institutions',
      // eslint-disable-next-line
      // @ts-ignore
      body: <InstitutionSearch consortiumName={consortium.name}/>
    },
    {
      title: t('courses'),
      key: 'courses',
      // eslint-disable-next-line
      // @ts-ignore
      body: <CourseSearch consortiumName={consortium.name}/>
    }
  ], []);

  return (
    <section>
      <header className={styles.Consortium__header}>
        <Avatar
          size={96}
          type="institution"
          url={consortium.logoUrl}
          name={`${consortium.name} logo`}
        />
        <div className={styles['Consortium__header-buttons-wrapper']}>
          {user && isAcadeumAdministrator(user) && (
            <Button
              className={classNames(styles['Consortium__header-button'])}
              url={`/consortia/${consortium.vanityUrl || consortium.id}/edit`}
            >
              {t('edit', { global: true })}
            </Button>
          )}
        </div>
        <Title className={styles['Consortium__header-title']}>
          {consortium.name}
        </Title>
        <Link to={consortium.website} className={styles['Consortium__header-link']}>
          {t('visitWebsite', {
            icon: () => <ArrowUpRightFromSquareIcon className={styles['Consortium__link-svg']}/>
          })}

        </Link>

        <div className={styles['Consortium__tags-wrapper']}>
          <Tag>
            {t('institutionsCount', {
              numbers: consortium.institutions.length
            })}
          </Tag>

          {consortium.courseLevels && consortium.courseLevels.map((courseLevel, i) => (
            <Tag key={i}>
              {courseLevel}
            </Tag>
          ))}
        </div>
      </header>

      <Tabs title={t('title')} tabs={tabs}/>
    </section>
  );
};

Consortium.meta = ({ useSelector }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const consortium = useSelector(state => state.consortia.consortium);
  return {
    title: consortium.shortName || consortium.name
  };
};

Consortium.breadcrumbs = () => [
  ['Consortia', '/consortia'],
  'Consortium'
];

Consortium.load = async ({ params: { id } }) => {
  await fetchConsortium(id);
};

export default WithPublicMarketingPageBanner(Consortium, { margin: true });
