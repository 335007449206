import { debounce } from 'lodash-es';
import PropTypes from 'prop-types';
import type { FC, MutableRefObject} from 'react';
import React, { useRef, memo } from 'react';
import type { CropperRef} from 'react-advanced-cropper';
import { Cropper, CircleStencil } from 'react-advanced-cropper';

import { ArrowRotateLeftIcon } from '@acadeum/icons';

import { Button } from '../Button';

import styles from './AvatarCrop.module.scss';

export interface AvatarCropProps {
  image?: string;
  onChange?: (cropper: CropperRef) => void;
}

const propTypes = {
  image: PropTypes.string,
  onChange: PropTypes.func
};

export const AvatarCrop: FC<AvatarCropProps> = memo<AvatarCropProps>(({
  image,
  onChange: propsOnChange
}) => {
  const cropperRef = useRef() as MutableRefObject<CropperRef>;

  const onChange = (cropper: CropperRef) => {
    if (propsOnChange) {
      propsOnChange(cropper);
    }
  };

  const rotate = () => {
    cropperRef.current.rotateImage(-90);
  };

  return (
    <div className={styles.wrapper}>
      <Cropper
        ref={cropperRef}
        src={image}
        onChange={debounce(onChange, 500)}
        className={styles.cropper}
        stencilComponent={CircleStencil}
        stencilProps={{
          lines: false,
          boundingBoxClassName: styles.circleStencilBoundingBox,
          overlayClassName: styles.circleStencilOverlay,
          handlerClassNames: {
            default: styles.circleStencilHandler,
            westNorth: styles['circleStencilHandler--westNorth'],
            eastNorth: styles['circleStencilHandler--eastNorth'],
            eastSouth: styles['circleStencilHandler--eastSouth'],
            westSouth: styles['circleStencilHandler--westSouth']
          }
        }}
      />
      <div>
        <Button
          icon={ArrowRotateLeftIcon}
          variant="text"
          onClick={rotate}
        >
          Rotate
        </Button>
      </div>
    </div>
  );
});

AvatarCrop.displayName = 'AvatarCrop';
AvatarCrop.propTypes = propTypes;
