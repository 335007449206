import type { Email, Id, Password, UserProfile } from '@acadeum/types';

import { adminApi } from '../adminApi';

export interface LogInInput {
  expires?: boolean;
  email: string;
  password: string;
  redirectTo?: string;
}

export interface LogInOutput {
  token: string;
  expiresIn: number;
  expiresAt: number;
  user: UserProfile;
}

export interface RequestPasswordResetInput {
  email: string;
  redirectTo?: string;
  redirectToWebsite: 'courseshare' | 'admin' | 'account';
}

export interface PasswordResetInput {
  userId: Id;
  newPassword: Password;
  token: string;
}

export interface CheckPasswordResetTokenInput {
  userId: number;
  token: string;
}

export interface RefreshAccessTokenInput {
  expiresIn?: number;
}

export interface RefreshAccessTokenOutput {
  token: string;
  expiresIn: number;
  expiresAt: number;
}

export interface RequestVerifyEmailInput {
  email: Email;
  redirectTo?: string;
  redirectToWebsite: 'courseshare' | 'admin' | 'account';
}

export interface RequestChangeEmailInput {
  email: Email;
  redirectTo?: string;
  redirectToWebsite: 'courseshare' | 'admin' | 'account';
}

interface ChangePasswordInput {
  password: string;
  newPassword: string;
  expiresIn?: number;
}

interface ChangePasswordOutput {
  token: string;
  expiresIn: number;
  expiresAt: number;
}

interface AuthenticateOutput {
  user: UserProfile;
}

interface LogInAsInput {
  userId: Id;
  expires?: boolean;
  redirectTo?: string;
  redirectToWebsite?: 'courseshare' | 'admin' | 'account';
}

export interface LogInAsOutput {
  token: string;
  expiresIn: number;
  expiresAt: number;
  user: AuthenticateOutput['user'];
}

const authEndpoints = adminApi
  .injectEndpoints({
    endpoints: build => ({
      authenticate: build.query<AuthenticateOutput, void>({
        query: () => '/authenticate'
      }),
      logInAs: build.mutation<LogInAsOutput, LogInAsInput>({
        query: (body) => ({
          url: '/sign-in-as',
          method: 'POST',
          body
        })
      }),
      logIn: build.mutation<LogInOutput, LogInInput>({
        query: (body) => ({
          url: '/sign-in',
          method: 'POST',
          body
        })
      }),
      // Only `if(getAuthCookieConfig()) { logOut }`
      logOut: build.mutation<void, void>({
        query: () => ({
          url: '/authentication/cookie',
          method: 'DELETE'
        })
      }),
      requestPasswordReset: build.mutation<void, RequestPasswordResetInput>({
        query: (body) => ({
          url: '/request-password-reset',
          method: 'POST',
          body
        })
      }),
      resetPassword: build.mutation<void, PasswordResetInput>({
        query: (body) => ({
          url: '/password-reset',
          method: 'POST',
          body
        })
      }),
      checkPasswordResetToken: build.query<void, CheckPasswordResetTokenInput>({
        // The Redux action name is required here
        // so that the corresponding Redux events would be called:
        // * `CHECK_PASSWORD_RESET_TOKEN_PENDING`
        // * `CHECK_PASSWORD_RESET_TOKEN_ERROR`
        // * `CHECK_PASSWORD_RESET_TOKEN_SUCCESS`
        // 'CHECK_PASSWORD_RESET_TOKEN',
        query: (params) => ({
          url: '/check-password-reset-token',
          params
        }),
        extraOptions: {
          maxRetries: 1
        }
      }),
      refreshAccessToken: build.query<RefreshAccessTokenOutput, RefreshAccessTokenInput>({
        query: (params) => ({
          url: '/refresh-access-token',
          params
        })
      }),
      requestVerifyEmail: build.mutation<void, RequestVerifyEmailInput>({
        query: (body) => ({
          url: '/request-verify-email',
          method: 'POST',
          body
        })
      }),
      requestChangeEmail: build.mutation<void, RequestChangeEmailInput>({
        query: (body) => ({
          url: '/request-change-email',
          method: 'POST',
          body
        })
      }),
      cancelChangeEmail: build.mutation<void, void>({
        query: () => ({
          url: '/cancel-change-email',
          method: 'GET'
        })
      }),
      changePassword: build.mutation<ChangePasswordOutput, ChangePasswordInput>({
        query: (body) => ({
          url: '/change-password',
          method: 'POST',
          body
        })
      })
    })
  });

export const {
  useCancelChangeEmailMutation,
  useRequestChangeEmailMutation,
  useRequestVerifyEmailMutation,
  useChangePasswordMutation,
  useRequestPasswordResetMutation,
  useResetPasswordMutation,
  useLazyCheckPasswordResetTokenQuery,
  useLogInMutation,
  useLogInAsMutation,
  useLogOutMutation,
  useLazyAuthenticateQuery,
  useLazyRefreshAccessTokenQuery
} = authEndpoints;
