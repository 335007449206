import React, { useEffect, useRef } from 'react';

import { waitForJob, getErrorData } from '@acadeum/helpers';

import { Breadcrumbs } from '@acadeum/ui';

import actions from '../../actions';
import { institutionEndpoints } from '../../api/institutions';
import InstitutionForm from '../../components/InstitutionForm';
import Section from '../../components/Section';

import isAcadeumAdministrator from '../../helpers/isAcadeumAdministrator';


import { getStore } from '../../store';

const {
  notifyError,
  goto,
  runJob,
  getJobStatus
} = actions;

const EditInstitution = ({ institution }) => {

  const stopPollingJobStatus = useRef();

  useEffect(() => {
    return () => {
      if (stopPollingJobStatus.current) {
        stopPollingJobStatus.current();
      }
    };
  }, []);

  async function onSubmit(values) {
    // If no `state` was selected then set the value to "N/A".
    // That's a legacy value that has been historically used for "State is unknown".
    if (!values.state) {
      values.state = 'N/A';
    }
    // `minorityServingInstitutionTypes` field is not editable in `ASP-site`.
    values.minorityServingInstitutionTypes = institution.minorityServingInstitutionTypes;
    const onUpdated = () => goto(`/institutions/${values.vanityUrl || institution.id}`);
    const version = institution.version || 0;
    values.version = version + 1;
    try {
      await waitForJob(
        await runJob('institutions-update', {
          id: institution.id,
          body: values
        }),
        getJobStatus,
        (cancel) => stopPollingJobStatus.current = cancel
      );
      onUpdated();
    } catch (error) {
      if (getErrorData(error).message === 'copper_update_error') {
        notifyError(`The update has happened successfully, but you need to go to copper and add the correct institution id: ${  institution.id}`);
        return onUpdated();
      }
      if (getErrorData(error).code === 'version_mismatch' ||
        getErrorData(error).message === 'Version.Mismatch') {
        notifyError('Someone seems to have edited institution data while you were doing the same. Save your changes somewhere, refresh the page, reapply the changes, and submit again.');
        return;
      }
      throw error;
    }
  }

  return (
    <>
      <Breadcrumbs
        children={[
          ['Institutions', '/institutions'],
          [institution.name, `/institutions/${institution.vanityUrl || institution.id}`],
          'Edit'
        ]}
      />
      <Section title={institution.name}>
        <InstitutionForm
          onSubmit={onSubmit}
          submitTitle="Update"
          institution={institution}
        />

        {/* {institution.stripeCustomerId &&
        <AnnualFeesForm institution={institution} />
      } */}
      </Section>
    </>
  );
};

EditInstitution.meta = ({ props }) => {

  return {
    title: `Edit ${props.institution.shortName || props.institution.name}`
  };
};

EditInstitution.load = async ({ user, params }) => {
  const store = getStore();
  const { dispatch } = store;
  const { data: institution, error } = await dispatch(
    institutionEndpoints.fetchInstitutionForEditing.initiate({
      id: params.id
    })
  );

  if (!isAcadeumAdministrator(user)) {
    const error = new Error('Unauthorized');
    error.data = {
      type: 'unauthorized'
    };
    throw error;
  }
  if (error) {
    goto('/error');
  }
  return {
    props: {
      institution
    }
  };
};

export default EditInstitution;
