import classNames from 'classnames';
import type { FC } from 'react';
import React from 'react';

import styles from './PageLoader.module.scss';

export interface PageLoaderProps {
  className?: string;
}

export const PageLoader: FC<PageLoaderProps> = ({
  className
}) => {
  return (
    <div className={classNames(className, styles.root)}>
      <div className={styles.loader}/>
      <span className={styles.text}>Loading</span>
    </div>
  );
};
