import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

let PrePostFixInput = ({
  component: Input = 'input',
  prefix,
  postfix,
  value,
  onChange,
  type,
  ...rest
}, ref) => {
  const onChange_ = useCallback((value) => {
    if (value === undefined || value === null) {
      return onChange();
    }

    if (prefix) {
      if (value.indexOf(prefix) === 0) {
        value = value.slice(prefix.length);
      }
      // The user may put their cursor before the prefix and start inputting characters.
      else if (value.indexOf(prefix) >= 0) {
        value = value.slice(0, value.indexOf(prefix)) + value.slice(value.indexOf(prefix) + 1);
      }
    }
    if (postfix) {
      if (value.indexOf(postfix) === value.length - postfix.length) {
        value = value.slice(0, value.length - postfix.length);
      }
      // The user may put their cursor after the postfix and start inputting characters.
      else if (value.indexOf(postfix) >= 0) {
        value = value.slice(0, value.indexOf(postfix)) + value.slice(value.indexOf(postfix) + 1);
      }
    }

    if (!value) {
      return onChange();
    }

    // By default, it only supports integers (not floating-point numbers)
    // when `type` property is `"number"`.
    // This way, it can pass `value: Number` to `onChange()`.
    // If in some future floating-point number input is required,
    // remove `parseFloat()` and pass the string to `onChange()`.
    // After that, find all places with `type="percent"` in the project
    // and parse those values manually via `parseFloat()`.
    // Perhaps also reuse the code from 'src/components/Input/types/currency.js':
    // there's a `fixCurrencyInput(input)` function.
    if (type === 'number') {
      value = parseFloat(value);
      if (isNaN(value)) {
        value = undefined;
      }
      // To support floating-point numbers (pseudocode):
      // value = removePrePostFix(value)
      // value = fixCurrencyInput(value)
      // value = addPrePostFix(value)
    }

    onChange(value);
  }, [onChange]);

  return (
    <Input
      {...rest}
      ref={ref}
      value={addPrePostFix(value, prefix, postfix)}
      onChange={onChange_}/>
  );
};

PrePostFixInput = React.forwardRef(PrePostFixInput);

PrePostFixInput.propTypes = {
  component: PropTypes.elementType.isRequired,
  prefix: PropTypes.string,
  postfix: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  onChange: PropTypes.func.isRequired
};

export default PrePostFixInput;

function addPrePostFix(value, prefix, postfix) {
  if (value === undefined || value === null) {
    value = '';
  }
  if (prefix) {
    value = prefix + value;
  }
  if (postfix) {
    value = value + postfix;
  }
  return value;
}
