import { useMemo, useState } from 'react';
import type { FC } from 'react';
import { useSelector } from 'react-redux';

import { getErrorData } from '@acadeum/helpers';
import type { Student as IStudent } from '@acadeum/types';
import { Text, Title, Actions, exportTableData, toast } from '@acadeum/ui';
import type { ActionsProps } from '@acadeum/ui';

import actions from '../../actions';
import StudentDetails from '../../components/StudentDetails';
import type { AppPage, ReduxState } from '../../helpers/app.types';
import { formatName } from '../../helpers/format';


import { useExportDataColumns } from '../Students/ui/HomeStudents';


import styles from './Student.module.scss';
import { ModalDelete } from './ui/ModalDelete';

const {
  fetchStudent,
  goto
} = actions;

const EnrollingStudent: AppPage = () => {
  const student = useSelector((state: ReduxState) => state.students.student);
  return <Student type="EM" student={student} useExportDataColumns={useExportDataColumns} />;
};

EnrollingStudent.meta = ({ useSelector }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const student = useSelector(state => state.students.student);
  return {
    title: formatName(student)
  };
};

EnrollingStudent.breadcrumbs = (state) => [
  ['Students', '/students'],
  formatName(state.students.student)
];

EnrollingStudent.load = async ({ params: { id } }) => {
  await fetchStudent(id);
};
export default EnrollingStudent;

interface StudentProps {
  student: IStudent;
  type: 'EM' | 'TM';
  // eslint-disable-next-line
  useExportDataColumns: any;
}

export const Student: FC<StudentProps> = ({ student, type, useExportDataColumns }) => {
  const [showModalDeactivate, setShowModalDeactivate] = useState<boolean>();

  const exportDataColumns = useExportDataColumns();

  const exportDataStudent = async () => {
    try {
      await exportTableData({
        type: 'xlsx',
        fileName: 'HI - Enrolling Students',
        exportDataColumns,
        data: [student]
      });
    } catch (err) {
      const error = getErrorData(err);
      toast.warn(error.message);
    }
  };

  const actions: ActionsProps['actions'] = useMemo(() => {
    const result: ActionsProps['actions'] = [
      {
        title: 'Download',
        onClick: exportDataStudent
      }
    ];
    if (type === 'EM') {
      result.push({
        title: 'Deactivate',
        onClick: () => {
          setShowModalDeactivate(true);
        }
      });
    }
    return result;
  }, [type]);

  return (
    <section>
      <div className={styles.Student__top}>
        <div>
          <Title className={styles.Student__title}>
            {formatName(student)}
          </Title>
          <Text color="grey" variant="bodyMd" className={styles.Student__studentId}>
            ID: {student.hiStudentId}
          </Text>
        </div>
        <Actions actions={actions}
        />
      </div>
      <StudentDetails
        student={student}
        type={type}
        showEnrollments
      />
      {type === 'EM' && (
        <ModalDelete
          studentId={student.id}
          studentName={formatName(student)}
          onHide={setShowModalDeactivate}
          show={showModalDeactivate}
          onAfterSubmit={() => {
            goto(`/students/${student.id}`);
          }}
        />
      )}
    </section>
  );
};
