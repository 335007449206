import { isEqual } from 'lodash-es';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { joinAnd, waitForJob } from '@acadeum/helpers';

import actions from '../../actions';
import ConfirmActionModal from '../../components/ConfirmActionModal';
import ConsortiumForm from '../../components/ConsortiumForm';
import Section from '../../components/Section';

import isAcadeumAdministrator from '../../helpers/isAcadeumAdministrator';


const {
  fetchConsortiumForEditing,
  goto,
  runJob,
  getJobStatus
} = actions;

export default function EditConsortium() {
  const consortium = useSelector(state => state.consortia.consortium);

  const [values, setValues] = useState();
  const [showConfirmModal, setShowConfirmModal] = useState();
  const [confirmModalText, setConfirmModalText] = useState();

  const stopPollingJobStatus = useRef();

  useEffect(() => {
    return () => {
      if (stopPollingJobStatus.current) {
        stopPollingJobStatus.current();
      }
    };
  }, []);

  async function updateConsortium_(values) {
    // await updateConsortium(consortium.id, values);

    await waitForJob(
      await runJob('consortia-update', {
        id: consortium.id,
        body: values
      }),
      getJobStatus,
      (cancel) => stopPollingJobStatus.current = cancel
    );

    goto(`/consortia/${values.vanityUrl || consortium.id}`);
  }

  async function onUpdateConsortium(values, pickedInstitutions) {
    // If member institutions changed then show a confirmation modal.
    if (!isEqual(values.institutions, consortium.institutions.map(_ => _.id))) {
      setValues(values);
      setShowConfirmModal(true);
      setConfirmModalText(getConfirmModalText(consortium.institutions, pickedInstitutions));
      return;
    }
    // If member institutions didn't change then update the consortium.
    await updateConsortium_(values);
  }

  const onConfirmUpdateConsortium = async () => {
    await updateConsortium_(values);
  };

  const getConfirmModalText = (oldInstitutions, newInstitutions) => {
    const oldInstitutionsIds = oldInstitutions.map(_ => _.id);
    const newInstitutionsIds = newInstitutions.map(_ => _.id);
    const removedInstitutions = oldInstitutions.filter(_ => newInstitutionsIds.indexOf(_.id) < 0);
    const addedInstitutions = newInstitutions.filter(_ => oldInstitutionsIds.indexOf(_.id) < 0);
    return (
      <React.Fragment>
        Are you sure you want to
        {removedInstitutions.length > 0 &&
          <React.Fragment>
            {' remove '}
            {joinAnd(removedInstitutions.map((_, i) => <strong key={i}>{_.name}</strong>))}
            {' from'}
          </React.Fragment>
        }
        {removedInstitutions.length > 0 && addedInstitutions.length > 0 &&
          ' and'
        }
        {addedInstitutions.length > 0 &&
          <React.Fragment>
            {' add '}
            {joinAnd(addedInstitutions.map((_, i) => <strong key={i}>{_.name}</strong>))}
            {' to'}
          </React.Fragment>
        }
        {' '}
        this consortium?
      </React.Fragment>
    );
  };

  return (
    <div>
      <Section title={consortium.name}>
        <ConsortiumForm
          onSubmit={onUpdateConsortium}
          submitTitle="Save"
          consortium={consortium} />
      </Section>

      {/* Confirm changing consortium members modal. */}
      <ConfirmActionModal
        title="Confirm changing consortium's member institutions"
        description={confirmModalText}
        submitText="Confirm"
        onSubmit={onConfirmUpdateConsortium}
        show={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
      />
    </div>
  );
}

EditConsortium.meta = ({ useSelector }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const consortium = useSelector(state => state.consortia.consortium);
  return {
    title: consortium.name
  };
};

EditConsortium.breadcrumbs = (state) => {
  const { consortium } = state.consortia;
  return [
    ['Consortia', '/consortia'],
    [consortium.name, `/consortia/${consortium.vanityUrl || consortium.id}`],
    'Edit'
  ];
};

EditConsortium.load = async ({ user, params: { id } }) => {
  if (!isAcadeumAdministrator(user)) {
    const error = new Error('Unauthorized');
    error.data = {
      type: 'unauthorized'
    };
    throw error;
  }
  await fetchConsortiumForEditing(id);
};
