import React from 'react';

import { Button } from '../Button';
import type { ModalProps } from '../Modal';
import { Modal } from '../Modal';
import { Text } from '../Text';

export interface ConfirmationModalProps extends Pick<ModalProps, 'title' | 'show' | 'onHide'> {
  children: React.ReactNode;
  onOk?: () => void
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  title,
  children,
  show,
  onHide,
  onOk
}) => {
  const onConfirm = () => {
    if (onOk) {
      return onOk();
    }
    onHide(false);
  };

  return (
    <Modal
      title={title}
      onHide={onHide}
      show={show}
      size="narrow"
    >
      <Modal.Body>
        <Text as="div" color="black">
          {children}
        </Text>
      </Modal.Body>
      <Modal.Footer paddingTop="large">
        <Button variant="secondary" onClick={onConfirm}>
          Continue
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
