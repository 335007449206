import classNames from 'classnames';
import { clamp } from 'lodash-es';
import React from 'react';

import { hsvaToHslaString } from '../../helpers/convert';
import type { HSVA } from '../../types';
import { Checkboard } from '../Checkboard';
import type { InteractionPosition } from '../Interactive';
import { Interactive } from '../Interactive';
import { Pointer } from '../Pointer';


import styles from './Alpha.module.scss';


interface AlphaProps {
  className?: string;
  hsva: HSVA;
  onChange: (newAlpha: { a: number }) => void;
}

export const Alpha = ({ className, hsva, onChange }: AlphaProps) => {
  const handleMove = (interaction: InteractionPosition) => {
    onChange?.({
      a: interaction.left
    });
  };

  const handleKey = (offset: InteractionPosition) => {
    onChange({
      a: clamp(hsva.a + offset.left, 0, 1)
    });
  };


  const colorFrom = hsvaToHslaString({ ...hsva, a: 0 });
  const colorTo = hsvaToHslaString({ ...hsva, a: 1 });

  const gradientStyle = {
    backgroundImage: `linear-gradient(90deg, ${colorFrom}, ${colorTo})`
  };

  const ariaValue = Math.round(hsva.a * 100);

  return (
    <div className={classNames(className, styles.Alpha)}>
      <div className={styles.gradient} style={gradientStyle}/>
      <Checkboard className={styles.Checkboard}/>
      <Interactive
        onMove={handleMove}
        onKey={handleKey}
        aria-label="Alpha"
        aria-valuetext={`${ariaValue}%`}
        aria-valuenow={ariaValue}
        aria-valuemin="0"
        aria-valuemax="100"
      >
        <Pointer
          className={styles.Pointer}
          left={hsva.a}
        />
      </Interactive>
    </div>
  );
};
