import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import formatUserName from 'common-lib/lib/formatUserName';

import { useTranslate } from '@acadeum/translate';
import {
  ContentSection,
  Form,
  FormField,
  Link,
  StickyFormFooter
} from '@acadeum/ui';

import actions from '../../../../actions';

const {
  goto,
  updateEnrollmentState,
  notifyError
} = actions;

export default function AddStudents({ navigation }) {
  const { section } = useSelector(state => state.sections);
  const { students } = useSelector(state => state.students);
  const { enrollmentState } = useSelector(state => state.enroll);

  return (
    <AddStudentsForm
      section={section}
      students={students || []}
      navigation={navigation}
      enrollmentState={enrollmentState}
    />
  );
}

AddStudents.propTypes = {
  navigation: PropTypes.shape({
    previous: PropTypes.func,
    next: PropTypes.func,
    go: PropTypes.func
  })
};

function AddStudentsForm({
  navigation,
  students,
  enrollmentState
}) {
  const { sectionId, students: selectedStudents = [] } = enrollmentState;

  const t = useTranslate('AddStudents');

  const options = useMemo(() => students.map(student => ({
    label: formatUserName(student),
    value: student.id,
    suffixLabel: student.hiStudentId ? `ID: ${student.hiStudentId}` : null,
    description: student.email,
    keywords: [formatUserName(student), student.email, student.hiStudentId],
    component:
      <Link
        monochrome
        removeUnderline={false}
        to={`/students/${student.id}`}
        // stopPropagation is used here to prevent option from being checked while navigating.
        onClick={e => e.stopPropagation()}
      >
        {formatUserName(student)}
      </Link>
  })), [students]);

  function validateStudentsList(studentsList) {
    const { seats } = enrollmentState;
    // Make sure at least one student is checked
    // display desired numbe of students for enrollment
    // make sure that number is below max
    // get array of student id's
    // pass them along with number of enrollments to the enrollment request
    if (!studentsList || studentsList.length === 0) {
      return `You must select ${seats === 1 ? 'a student' : 'students'}`;
    }
    if (studentsList.length < seats) {
      return `The number of selected students (${studentsList.length}) must match the number of seats requested (${seats})`;
    }
  }

  const selectedStudentIds = useMemo(() => {
    return selectedStudents.map(student => student.id);
  }, [selectedStudents]);

  const matchSelectedStudents = (values) => {
    const { students: selectedStudentIds } = values;

    if (!selectedStudentIds.length) {
      return [];
    }

    return students.filter(
      student => selectedStudentIds.includes(student.id)
    );
  };

  const onChoiceListOptionChange = (e) => {
    const selectedStudentIds = e.target.value;

    let selectedStudents = [];

    if (selectedStudentIds !== null && selectedStudentIds.length > 0) {
      selectedStudents = students.filter(
        student => selectedStudentIds.includes(student.id)
      );
    }

    updateEnrollmentState({
      students: selectedStudents
    });
  };

  const onBackAction = async (values) => {
    updateEnrollmentState({
      students: matchSelectedStudents(values),
      currentStepId: 'courseOverview'
    });
    navigation.previous();
  };

  const onSubmitAction = async (values) => {
    const validateStudentsListError = validateStudentsList(values.students);
    if (validateStudentsListError) {
      return notifyError(validateStudentsListError);
    }

    updateEnrollmentState({
      students: matchSelectedStudents(values),
      currentStepId: 'summary'
    });
    navigation.next();
  };

  const onSubmit = async (values) => {
    const { formAction, ...restValues } = values;

    switch (formAction) {
      case 'back':
        await onBackAction(restValues);
        break;
      case 'submit':
        await onSubmitAction(restValues);
        break;
    }
  };

  return (
    <ContentSection border={false} padding="none">
      <Form onSubmit={onSubmit}>
        <ContentSection>
          <ContentSection
            border={false}
            title={t('enrollStudents')}
            padding="none"
          >
            <FormField
              required
              showId
              name="students"
              type="choiceList"
              size="medium"
              placeholder={t('searchByName')}
              itemLabel={{
                singular: 'Student',
                plural: 'Students'
              }}
              defaultValue={selectedStudentIds}
              options={options}
              onChange={onChoiceListOptionChange}
              addNewItem={{
                label: t('addStudent'),
                url: '/students/add?from=enrollment'
              }}
            />
          </ContentSection>
        </ContentSection>

        <StickyFormFooter
          onCancelProps={{
            onClick: () => goto(`/sections/${sectionId}`)
          }}
          onBackProps={{
            action: 'back',
            skipValidation: true
          }}
          submitProps={{
            action: 'submit',
            children: t('next', { global: true })
          }}
        />
      </Form>
    </ContentSection>
  );
}

AddStudentsForm.propTypes = {
  navigation: PropTypes.shape({
    previous: PropTypes.func,
    next: PropTypes.func,
    go: PropTypes.func
  }).isRequired,
  students: PropTypes.arrayOf(PropTypes.object).isRequired,
  enrollmentState: PropTypes.shape({
    sectionId: PropTypes.number,
    students: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      hiStudentId: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired
    })),
    courseSubstituteId: PropTypes.number
  }).isRequired
};
