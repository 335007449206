import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import type { AsProp } from '../../utils/props';
import type { MarginsProp } from '../../utils/useMargin';
import { useMargin } from '../../utils/useMargin';

import styles from './Row.module.scss';

type Alignment = 'start' | 'center' | 'end' | 'top' | 'middle' | 'bottom' | 'around' | 'between';

// alignment(col)/lg - 0+
// md - 0-1280px
// sm - 0-768px
// xs - 0-414px

export interface RowProps extends AsProp, Pick<MarginsProp, 'mb'> {
  className?: string;
  children?: React.ReactNode;
  reverse?: boolean;
  alignment?: Alignment | Alignment[];
  alignmentMd?: Alignment | Alignment[];
  alignmentSm?: Alignment | Alignment[];
  alignmentXs?: Alignment | Alignment[];
}

export const Row: React.FC<RowProps> = ({
  as: As = 'div',
  className,
  children,
  reverse,
  alignment,
  alignmentMd,
  alignmentSm,
  alignmentXs,
  mb
}) => {
  const alignments = alignment && (Array.isArray(alignment)
    ? alignment.map((a) => styles[`${a}-lg`]).join(' ')
    : styles[`${alignment}-lg`]);

  const alignmentsMd = alignmentMd && (Array.isArray(alignmentMd)
    ? alignmentMd.map((a) => styles[`${a}-md`]).join(' ')
    : styles[`${alignmentMd}-md`]);

  const alignmentsSm = alignmentSm && (Array.isArray(alignmentSm)
    ? alignmentSm.map((a) => styles[`${a}-sm`]).join(' ')
    : styles[`${alignmentSm}-sm`]);

  const alignmentsXs = alignmentXs && (Array.isArray(alignmentXs)
    ? alignmentXs.map((a) => styles[`${a}-xs`]).join(' ')
    : styles[`${alignmentXs}-xs`]);

  const { marginClassNames } = useMargin({ mb });

  return (
    <As className={classNames(className, styles.row, marginClassNames, {
      [styles.reverse]: reverse,
      alignments,
      alignmentsMd,
      alignmentsSm,
      alignmentsXs
    })}>
      {children}
    </As>
  );
};

const ALIGNMENT = ['start', 'center', 'end', 'top', 'middle', 'bottom', 'around', 'between'];

Row.propTypes = {
  as: PropTypes.elementType,
  className: PropTypes.string,
  children: PropTypes.node,
  reverse: PropTypes.bool,
  alignment: PropTypes.oneOfType([
    PropTypes.oneOf(ALIGNMENT),
    PropTypes.arrayOf(PropTypes.oneOf(ALIGNMENT))
  ]),
  alignmentMd: PropTypes.oneOfType([
    PropTypes.oneOf(ALIGNMENT),
    PropTypes.arrayOf(PropTypes.oneOf(ALIGNMENT))
  ]),
  alignmentSm: PropTypes.oneOfType([
    PropTypes.oneOf(ALIGNMENT),
    PropTypes.arrayOf(PropTypes.oneOf(ALIGNMENT))
  ]),
  alignmentXs: PropTypes.oneOfType([
    PropTypes.oneOf(ALIGNMENT),
    PropTypes.arrayOf(PropTypes.oneOf(ALIGNMENT))
  ])
} as NonNullable<unknown>;
