import React from 'react';

import { Button } from '../Button';
import type { FormSubmitProps } from '../FormSubmit';
import { FormSubmit } from '../FormSubmit';
import type { ModalProps } from '../Modal';
import { Modal } from '../Modal';

export interface FormModalProps extends ModalProps {
  onCancel?: (show: false) => void;
  submitText?: string;
  formSubmitProps?: FormSubmitProps;
  danger?: boolean;
  hideFooter?: boolean;
}

export const FormModal: React.FC<FormModalProps> = ({
  hideFooter,
  onCancel,
  show,
  onHide,
  onSubmit,
  submitText = 'Submit',
  children,
  title,
  formSubmitProps,
  danger,
  ...rest
}) => {
  return (
    <Modal
      {...rest}
      title={title}
      show={show}
      onHide={onHide}
      onSubmit={onSubmit}
    >
      {(methods) => (
        <>
          <Modal.Body>
            {typeof children === 'function' ? children(methods) : children}
          </Modal.Body>
          {!hideFooter && (
            <Modal.Footer>
              {onCancel && (
                <Button
                  onClick={() => onCancel(false)}
                  variant={danger ? 'danger-outline' : 'secondary'}
                >
                  Cancel
                </Button>
              )}
              <FormSubmit
                variant={danger ? 'danger' : 'primary'}
                {...formSubmitProps}
              >
                {submitText}
              </FormSubmit>
            </Modal.Footer>
          )}
        </>
      )}
    </Modal>
  );
};
