import { ReduxModule } from 'react-pages';

const redux = new ReduxModule();

export const fetchUsers = redux.action(
  () => async http => {
    return http.get('/users', {
      // Omit deactivated users.
      filters: JSON.stringify({ status: 'ACTIVE' })
    });
  },
  'users'
);

export const fetchUser = redux.action(
  (id, { allSettings } = {}) => http => http.get(`/users/${id}`, {
    allSettings: allSettings ? '✓' : undefined
  }),
  'user'
);

export const deleteUser = redux.action(
  (id) => http => http.delete(`/users/${id}`)
);

export const deactivateUser = redux.action(
  (id) => http => http.post(`/users/${id}/deactivate`)
);

export const activateUser = redux.action(
  (id) => http => http.post(`/users/${id}/activate`)
);

export const updateUser = redux.action(
  (id, data) => http => http.put(`/users/${id}`, data)
);

export const fetchUserRoles = redux.action(
  () => async http => {
    const { results } = await http.get('/user-roles', { pageSize: 100 });
    return results;
  },
  'userRoles'
);

export default redux.reducer();
