import config from 'config';

import { getRtkApi } from '@acadeum/redux';


export const rtkApi = getRtkApi({
  baseUrl: config.apiUrl
}, {
  reducerPath: 'api'
});
