import classNames from 'classnames';
import type { FC } from 'react';
import React from 'react';

import type { FetchCourseEnrollmentsOutputItem } from '@acadeum/api';
import { useTranslate } from '@acadeum/translate';
import type { CourseEnrollment } from '@acadeum/types';
import type { FormModalProps } from '@acadeum/ui';
import { Blank, ContentSection, DataBlock, FormField, FormModal, FormRow, Text } from '@acadeum/ui';

import { getStudentLink } from '../../../../helpers/getStudentLink';

import type { CourseEnrollmentMode } from '../../types';

import styles from './ChangeEnrollmentStatusModal.module.scss';

interface ChangeEnrollmentStatusModalProps extends Pick<FormModalProps, 'show' | 'onHide' | 'actions'> {
  show?: boolean;
  onSubmit: (values) => void;
  mode: CourseEnrollmentMode;
  children?: React.ReactNode;
  toStatus: CourseEnrollment['status'];
  enrollments: FetchCourseEnrollmentsOutputItem[];
}

export const ChangeEnrollmentStatusModal: FC<ChangeEnrollmentStatusModalProps> = ({
  onSubmit: propsOnSubmit,
  show,
  onHide,
  mode,
  actions,
  children,
  toStatus,
  enrollments
}) => {
  const t = useTranslate('CourseEnrollmentsTable');

  const isLetterGradeVisible = toStatus === 'COMPLETE' || (toStatus === 'WITHDRAWN' && mode === 'teachingInstitution');

  const onSubmit = async (values) => {
    const grades = {};
    // Fill in `grades` from `values`.
    if (isLetterGradeVisible) {
      for (const key of Object.keys(values)) {
        if (key.includes(':')) {
          const [id, type] = key.split(':');
          if (!grades[id]) {
            grades[id] = {};
          }
          switch (type) {
            case 'letter-grade':
              grades[id].letterGrade = values[key];
              break;
            case 'numerical-grade':
              grades[id].numericalGrade = values[key];
              break;
            case 'notes':
              grades[id].gradeNotes = values[key];
              break;
          }
        }
      }
    }
    // Call `onSubmit()`.
    await propsOnSubmit({
      grades,
      reason: values.reason,
      reasonNotes: values.reasonNotes
    });
  };

  return (
    <FormModal
      show={show}
      onCancel={onHide}
      onHide={onHide}
      title={t('enrollmentStatusTransitionDescription', {
        action: t(`status.transitionTo.${toStatus.toLowerCase()}`)
      })}
      submitText={toStatus === 'REMOVED' ? 'Remove' : 'Submit'}
      onSubmit={onSubmit}
      danger={toStatus === 'REMOVED'}
      actions={actions}
    >
      {enrollments.map(enrollment => {
        const canInputGradesForSomeEnrollments = isLetterGradeVisible && ['CONSORTIAL', 'DUAL'].includes(enrollment.type);
        return (
          <div className={classNames(styles.courseEnrollmentCard)} key={enrollment.id}>
            {canInputGradesForSomeEnrollments && (
              <>
                <Text variant="subtitle2" mb="md">
                  {t('grade')}
                </Text>
                <FormRow>
                  <FormField
                    required
                    label={t('letterGrade')}
                    name={`${enrollment.id}:letter-grade`}
                    defaultValue={getLetterGradeDefaultValue(enrollment, toStatus)}
                  />
                  <FormField
                    label={t('numericalGrade')}
                    name={`${enrollment.id}:numerical-grade`}
                    defaultValue={enrollment.numericalGrade}
                    type="number"
                  />
                </FormRow>
                <FormField
                  multiline
                  label={t('note')}
                  name={`${enrollment.id}:notes`}
                  defaultValue={enrollment.gradeNotes}
                  placeholder={t('typeHere')}
                />
                <hr/>
              </>
            )}
            <Text variant="subtitle2" mb="md">
              {t('details')}
            </Text>
            <ContentSection padding={false} border={false} title={t('studentName')}>
              {enrollment.student ? (
                <DataBlock
                  type="student"
                  student={enrollment.student}
                  url={getStudentLink(enrollment.student)}
                />
              ) : (
                <Blank/>
              )}
            </ContentSection>
            <ContentSection
              padding={false}
              border={false}
              title={mode === 'teachingInstitution' ? t('homeInstitution') : t('teachingInstitution')}
            >
              <Text>
                {mode === 'teachingInstitution'
                  ? enrollment.homeInstitution?.name
                  : enrollment.section.course.institution.name}
              </Text>
            </ContentSection>
            <ContentSection padding={false} border={false} title={t('course')}>
              <Text>
                {enrollment.section.course.title}
              </Text>
              <Text color="grey" variant="bodySm">
                {enrollment.section.course.code}
              </Text>
            </ContentSection>
            <ContentSection padding={false} border={false} title={t('courseDates')}>
              <Text>
                <DataBlock
                  utc
                  as="span"
                  type="date"
                  month="long"
                  date={enrollment.section.startDate}
                />
                {' - '}
                <DataBlock
                  utc
                  as="span"
                  type="date"
                  month="long"
                  date={enrollment.section.endDate}
                />
              </Text>
              <Text color="grey" variant="bodySm">
                {t('dropDate', {
                  date: () => (
                    <DataBlock
                      utc
                      as="span"
                      type="date"
                      month="long"
                      date={enrollment.section.lastDropDate}
                    />
                  )
                })}
              </Text>
            </ContentSection>
            <ContentSection padding={false} border={false} title={t('requestDate')}>
              <DataBlock
                utc
                type="date"
                month="long"
                date={enrollment.createdAt}
              />
            </ContentSection>
          </div>
        );
      })}
      {children}
    </FormModal>
  );
};

function getLetterGradeDefaultValue(courseEnrollment: Pick<CourseEnrollment, 'letterGrade'>, toStatus: CourseEnrollment['status']) {
  if (courseEnrollment.letterGrade) {
    return courseEnrollment.letterGrade;
  }
  // A student can be withdrawn with a grade.
  // Example: "W" (for "withdrawn").
  if (toStatus === 'WITHDRAWN') {
    return 'W';
  }
}
