import type { FC } from 'react';

import getLocationUrl from 'common-lib/lib/getLocationUrl';
import isValidBase64 from 'common-lib/lib/isValidBase64';

import type { AuthCookieConfig } from '@acadeum/auth';
import { getAccessTokenFromLocalStorage, isUserSessionEphemeral, shouldUseCookies } from '@acadeum/auth';
import { useTranslate } from '@acadeum/translate';
import { Form, FormField, FormFooter, FormSubmit, Text } from '@acadeum/ui';
import type { OnSubmit } from '@acadeum/ui';

import { useSettingsRoutes } from '../../../hooks/useSettingsRoutes';

import styles from './SingleSignOnSamlSettingsForm.module.scss';

interface SamlSettingsValues {
  entryPoint: string;
  certificate: string;
}

interface SingleSignOnSamlSettingsFormProps {
  authCookieConfig: AuthCookieConfig | undefined;
  entryPoint?: string;
  certificate?: string;
  domain: 'admin' | 'student';
  apiUrl: string;
}

export const SingleSignOnSamlSettingsForm: FC<SingleSignOnSamlSettingsFormProps> = ({
  authCookieConfig,
  entryPoint,
  certificate,
  domain,
  apiUrl
}) => {
  const t = useTranslate('shared-admin-ui.SingleSignOnSamlSettingsForm');
  const { getAuthenticationSettingsUrl } = useSettingsRoutes();

  const onSubmit: OnSubmit<SamlSettingsValues> = async ({ certificate, entryPoint }) => {
    // Issue: https://acadeum.atlassian.net/browse/CS-617?focusedCommentId=14021
    // Using a Promise to temporarily pause sync code:
    // - Keeps the toast visible while the async task runs.
    // - Disables the Submit button until the process is finished.
    await new Promise(() => {
      window.location = getLocationUrl({
        origin: apiUrl,
        pathname: `/authentication-settings/single-sign-on/${domain}/update`,
        query: {
          redirectToWebsite: 'admin',
          redirectTo: getAuthenticationSettingsUrl(),
          // The API has to identify the user somehow.
          // Normally, it does it through cookies.
          // But cookies aren't suitable for `localhost`,
          // so an explicit `accessToken` URL query parameter should be passed in that case.
          // It's also used in a "Log In As" scenario.
          accessToken: shouldUseCookies(authCookieConfig, isUserSessionEphemeral())
            ? undefined
            : getAccessTokenFromLocalStorage(),
          saml: JSON.stringify({ entryPoint, certificate: parseCertificate(certificate) })
        }
      });
    });
  };

  return (
    <Form onSubmit={onSubmit}>
      <FormField
        required
        className={styles.FormField}
        label={t('entryPoint.label')}
        type="url"
        name="entryPoint"
        placeholder={t('entryPoint.placeholder')}
        defaultValue={entryPoint}
      />
      <Text color="grey">
        {t('entryPoint.description')}
      </Text>
      <br/>

      <FormField
        required
        multiline
        className={styles.FormField}
        label={t('certificate.label')}
        name="certificate"
        validate={validateCertificate}
        placeholder={t('certificate.placeholder')}
        defaultValue={certificate}
      />
      <Text color="grey">
        {t('certificate.description')}
      </Text>
      <br />

      <FormFooter align="left">
        <FormSubmit>
          {t('save', { global: true })}
        </FormSubmit>
      </FormFooter>
    </Form>
  );
};

const validateCertificate = (value) => {
  if (!value) {
    return;
  }
  value = parseCertificate(value);
  if (!value) {
    return;
  }
  value = value.replace(/\n/g, '');
  if (!value) {
    return;
  }
  if (!isValidBase64(value)) {
    return 'Invalid certificate';
  }
};

const parseCertificate = (certificate) => {
  // "The "BEGIN CERTIFICATE" and "END CERTIFICATE" lines should be stripped out
  //  and the certificate should be provided on a single line."
  return certificate
    .replace('-----BEGIN CERTIFICATE-----', '')
    .replace('-----END CERTIFICATE-----', '')
    .replace(/ /g, '')
    .trim();
};
