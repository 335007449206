import PropTypes from 'prop-types';
import React from 'react';
import { useController } from 'react-hook-form';

import { DateRangePicker } from '../../../DateRangePicker';

export const FormDateRangeInput = ({
  name,
  defaultValue,
  rules,
  utc,
  ...rest
}) => {
  const { field } = useController({ name, defaultValue, rules });

  return (
    <DateRangePicker utc={utc} {...rest} {...field}/>
  );
};

FormDateRangeInput.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.instanceOf(Date),
  rules: PropTypes.object.isRequired,
  utc: PropTypes.bool.isRequired
};
