import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { createTableSessionFilter, createTableTermFilter, createTableYearFilter, DataBlock, Table } from '@acadeum/ui';

import actions from '../../actions';
import Button from '../../components/Button';
import Section from '../../components/Section';
import { formatName } from '../../helpers/format';


import { loadStudentRequestsPage } from '../../helpers/routes';

import useExportDataColumns from './useExportDataColumns';


const {
  fetchNewStudentRequests,
  fetchStudentRequestsForExport
} = actions;

export default function CourseRegistrationRequestsNew() {
  const requests = useSelector(state => state.studentRequests.studentRequests);

  const exportDataColumns = useExportDataColumns();

  if (requests.length === 0) {
    return (
      <Section
        description="There are no pending course registration requests from students at your institution."
      />
    );
  }

  return (
    <Section
      description="These are requests from students at your institution to take the following courses through Acadeum.">
      <Table
        id="courseRegistrationRequestsNew"
        hasColumnVisibility
        enableRowSelection
        enableGlobalFilter
        clientFilters={filters}
        getColumnCanGlobalFilter={column => column.id === 'student'}
        columns={columns}
        data={requests}
        exportOptions={{
          type: 'xlsx',
          fileName: 'Student Registration Requests - HI - New',
          exportDataColumns,
          fetchDataForExport: (ids, { rowsIds }) => fetchStudentRequestsForExport(ids.length > 0 ? ids : rowsIds, { type: 'requested' })
        }}
        translate={{
          noDataMessage: 'You have no pending student requests'
        }}
      />
    </Section>
  );
}

CourseRegistrationRequestsNew.propTypes = {
  students: PropTypes.arrayOf(PropTypes.object),
  requests: PropTypes.arrayOf(PropTypes.object)
};

CourseRegistrationRequestsNew.meta = () => ({
  title: 'Student Requests'
});

CourseRegistrationRequestsNew.load = async () => {
  await loadStudentRequestsPage(fetchNewStudentRequests());
};

CourseRegistrationRequestsNew.breadcrumbs = () => [
  ['Student Requests', '/course-registration-requests'],
  'New'
];

export function getStudentRequestsTableColumns() {
  return [
    {
      accessorFn: (row) => formatName(row.student),
      id: 'student',
      header: 'STUDENT name',
      enableSorting: true,
      cell: ({ row }) => (
        <DataBlock
          type="student"
          student={row.original.student}
        />
      )
    },
    {
      accessorKey: 'section.session.course.institution',
      header: 'TEACHING INSTITUTION',
      cell: props => <DataBlock type="institution" institution={props.getValue()} />
    },
    {
      id: 'course',
      header: 'Course',
      cell: props => (
        <DataBlock
          type="course"
          course={props.row.original.section.session.course}
          url={`/sections/${props.row.original.section.id}`}
        />
      )
    },
    {
      accessorKey: 'createdAt',
      header: 'REQUESTED DATE',
      enableSorting: true,
      cell: ({ getValue }) => <DataBlock type="date" date={getValue()} utc={false} />
    },
    {
      accessorKey: 'section.session.course.hours',
      enableSorting: true,
      header: 'CREDITS'
    },
    {
      id: 'approveDue',
      header: 'Approve Due',
      enableSorting: true,
      accessorFn: original => original.section.session.course.onDemand ? '' : original.section.session.lastAddDate,
      meta: {
        emptyWhen: ({ row }) => row.section.session.course.onDemand
      },
      cell: ({ row }) => <DataBlock utc type="date" date={row.original.section.session.lastAddDate} />
    }
  ];
}

const columns = [
  ...getStudentRequestsTableColumns(),
  {
    id: 'actions',
    cell: ({ row }) => (
      <Button
        link={`/course-registration-requests/${row.original.id}`}>
        Review
      </Button>
    )
  }
];

const filters = [
  createTableYearFilter(),
  createTableTermFilter(),
  createTableSessionFilter()
];
