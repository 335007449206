import { useEffect, useRef } from 'react';

import type { JobResult, JobId } from '@acadeum/helpers';
import { waitForJob } from '@acadeum/helpers';
import type { RtkApi } from '@acadeum/redux';

const getJobApi = (rtkApi: RtkApi) => {
  return rtkApi.injectEndpoints({
    overrideExisting: true,
    endpoints: build => ({
      runJob: build.mutation<JobId, { name: string; parameters: unknown }>({
        query: (data) => ({
          url: '/job',
          method: 'POST',
          body: data
        }),
        extraOptions: {
          retryCondition: () => false
        }
      }),
      getJobStatus: build.query<unknown, JobId>({
        query: (jobId) => `/job/${jobId}`,
        extraOptions: {
          retryCondition: () => false
        }
      })
    })
  });
};

export type UseJob = () => <T>(name: string, parameters: unknown) => Promise<T>;

export function getUseJob(rtkApi: RtkApi) {
  const { useRunJobMutation, useLazyGetJobStatusQuery } = getJobApi(rtkApi);
  return () => {
    const stopPollingJobStatus = useRef<() => void>();

    const [runJob] = useRunJobMutation();
    const [getJobStatus] = useLazyGetJobStatusQuery();

    useEffect(() => {
      return () => {
        if (typeof stopPollingJobStatus.current === 'function') {
          stopPollingJobStatus.current();
        }
      };
    }, []);

    async function job<T>(name, parameters) {
      return await waitForJob<T>(
        await runJob({ name, parameters }).unwrap(),
        (jobId) => getJobStatus(jobId).unwrap() as Promise<JobResult<T>>,
        (cancel) => stopPollingJobStatus.current = cancel
      ) as T;
    }

    return job;
  };
}
