import { createColumnHelper } from '@tanstack/react-table';

import { useTranslate } from '@acadeum/translate';
import { DataBlock, Email } from '@acadeum/ui';
import type { FetchAdminStudentUsersOutput, FetchAdminUsersOutput } from '@acadeum/api';

import { AdminUserStatusList } from '../../../components/AdminUserStatusList';
import { AdminUserActions } from '../../../components/AdminUserActions';

type TableData =
  (Awaited<FetchAdminStudentUsersOutput>['results'][number])
  | (Awaited<FetchAdminUsersOutput>['results'][number]);

const columnHelper = createColumnHelper<TableData>();

export const useColumns = ({
  refresh,
  isStudents
}) => {
  const t = useTranslate('shared-admin-ui.UserAccountsPage');
  return [
    columnHelper.display({
      id: 'name',
      header: t('columns.name'),
      enableSorting: false,
      cell: ({ row }) => (
        <DataBlock
          type="user"
          user={row.original}
          inlineInfo={<AdminUserStatusList user={row.original}/>}
        />
      )
    }),
    columnHelper.accessor('email', {
      header: t('columns.email'),
      enableSorting: false,
      cell: (info) => <Email address={info.getValue()}/>
    }),
    columnHelper.accessor('institution.name', {
      header: t('columns.homeInstitution'),
      enableSorting: false
    }),
    columnHelper.accessor('latestLoginDate', {
      header: t('columns.login'),
      enableSorting: true,
      cell: (info) => <DataBlock type="relativeDate" date={info.getValue()}/>
    }),
    columnHelper.display({
      id: 'actions',
      cell: ({ row }) => (
        <AdminUserActions
          kebab
          seeDetails
          isStudent={isStudents}
          user={row.original}
          refreshFetchDataStudentsOrUsers={refresh}
        />
      )
    })
  ];
};
