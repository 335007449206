import React from 'react';

import { AcceptIcon } from '@acadeum/icons';

import type { ActionButtonProps } from '../ActionButton';
import { ActionButton } from '../ActionButton';
import type { ModalProps } from '../Modal';
import { Modal } from '../Modal';
import { Text } from '../Text';
import { Title } from '../Title';

import styles from './SuccessModal.module.scss';

export interface SuccessModalProps extends Pick<ModalProps, 'show' | 'onHide' | 'title' | 'size' | 'backdrop'> {
  children: React.ReactNode;
  secondaryAction?: ActionButtonProps;
  action?: ActionButtonProps;
}

export const SuccessModal: React.FC<SuccessModalProps> = ({
  children,
  title,
  secondaryAction,
  action,
  ...rest
}) => {
  return (
    <Modal
      {...rest}
      title={(
        <div className={styles.SuccessModal__header}>
          <AcceptIcon className={styles.SuccessModal__checkIcon}/>
          <Title className={styles.SuccessModal__title}>
            {title || 'Success!'}
          </Title>
        </div>
      )}
    >
      <Modal.Body>
        <Text as="div" color="black" className={styles.SuccessModal__content}>
          {children}
        </Text>
      </Modal.Body>
      {(action || secondaryAction) && (
        <Modal.Footer paddingTop="large" justify="center">
          {secondaryAction && (
            <ActionButton variant="secondary" {...secondaryAction}/>
          )}
          {action && (
            <ActionButton {...action}/>
          )}
        </Modal.Footer>
      )}
    </Modal>
  );
};
