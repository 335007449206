import PropTypes from 'prop-types';
import React from 'react';

import styles from './Outputs.module.scss';

export const Outputs = ({ children }) => {
  return (
    <div className={styles.Outputs}>
      {children}
    </div>
  );
};

Outputs.propTypes = {
  children: PropTypes.node.isRequired
};
