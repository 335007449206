import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { gaEvent } from '@acadeum/helpers';

import actions from '../../actions';
import InstitutionUsesSingleSignOnAuthentication from '../InstitutionUsesSingleSignOnAuthentication';
import Modal, { waitForModalToClose } from '../Modal';
import RequestPasswordReset from '../RequestPasswordReset';
import RequestPasswordResetConfirmationModal from '../RequestPasswordResetConfirmationModal';


const {
  hideRequestPasswordResetModal
} = actions;

export default function RequestPasswordResetModal() {
  const [showPasswordResetAttemptedOnSingleSignOnInstitutionModal, setShowPasswordResetAttemptedOnSingleSignOnInstitutionModal] = useState();

  const showRequestPasswordResetModal = useSelector(state => state.passwordReset.requestPasswordResetModalIsShown);
  const onHideRequestPasswordResetModal = hideRequestPasswordResetModal;

  const [showResetPasswordSuccessModal, setShowResetPasswordSuccessModal] = useState();

  return (
    <>
      <Modal
        show={showRequestPasswordResetModal}
        onHide={onHideRequestPasswordResetModal}
        onCancelGaLabel="Cancel Reset Password">
        <RequestPasswordReset
          onPasswordResetAttemptedOnSingleSignOnInstitution={async () => {
            onHideRequestPasswordResetModal();
            await waitForModalToClose();
            setShowPasswordResetAttemptedOnSingleSignOnInstitutionModal(true);
          }}
          onAfterSubmit={async () => {
            gaEvent({
              category: 'Buttons',
              action: 'Clicked',
              label: 'Continue'
            });
            onHideRequestPasswordResetModal();
            await waitForModalToClose();
            setShowResetPasswordSuccessModal(true);
          }}
        />
      </Modal>

      <RequestPasswordResetConfirmationModal
        show={showResetPasswordSuccessModal}
        onHide={() => setShowResetPasswordSuccessModal()}
      />

      <InstitutionUsesSingleSignOnAuthentication
        show={showPasswordResetAttemptedOnSingleSignOnInstitutionModal}
        onHide={() => setShowPasswordResetAttemptedOnSingleSignOnInstitutionModal()}
      />
    </>
  );
}
