import React from 'react';

import actions from '../../actions';
import type { AppPage } from '../../helpers/app.types';
import { loadTeachingStudentsPage } from '../../helpers/routes';


import Students from '../Students';

const { getStudentsTMFiltersValues } = actions;

const TeachingStudents: AppPage = () => {
  return (
    <Students type="TM" />
  );
};

TeachingStudents.meta = () => ({
  title: 'Teaching Students'
});

TeachingStudents.load = async ({ user }) => {
  await loadTeachingStudentsPage({ user });
  await getStudentsTMFiltersValues(['level', 'major', 'advisorName']);
};

TeachingStudents.breadcrumbs = () => ['Teaching Students'];

export default TeachingStudents;
