import classNames from 'classnames';
import React from 'react';

import type { MarginsProp } from '../../utils/useMargin';
import { useMargin } from '../../utils/useMargin';

import styles from './StepsProgress.module.scss';

export interface StepsProgressProps extends Pick<MarginsProp, 'mb'> {
  step: string;
  steps: string[];
  stepNames: {
    [key: string]: string
  };
  marginBottom?: boolean;
  riftNumber?: number;
  className?: string;
  children?: React.ReactNode;
}

export const StepsProgress: React.FC<StepsProgressProps> = React.memo(({
  step,
  steps,
  stepNames,
  marginBottom,
  riftNumber,
  className,
  children,
  mb
}) => {
  const activeIndex = steps.indexOf(step);

  const { marginClassNames } = useMargin({ mb });

  const renterTitleRift = (index) => {
    if (typeof riftNumber === 'number' && activeIndex >= riftNumber) {
      if (index >= riftNumber) {
        return stepNames[steps[index]];
      }
    } else {
      return stepNames[steps[index]];
    }
  };

  return (
    <div className={classNames(className, marginClassNames)}>
      <ol
        className={classNames(styles['StepsProgress-step-wrapper'], {
          [styles['StepsProgress-margin-bottom']]: marginBottom
        })}
      >
        {steps.map((someStep, i) => {
          const ariaLabel = activeIndex < i ? `Previous step number ${i + 1}}` :
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore TODO: Fix this component
            step === i ? `Current step number ${i + 1}` : `Next step number ${i + 1}`;

          return (
            <li
              key={someStep}
              className={classNames(styles['StepsProgress-step'], {
                [styles['StepsProgress-step-current']]: i === activeIndex,
                [styles['StepsProgress-step-complete']]: activeIndex > i
              })}>
              <span
                aria-label={ariaLabel}
                aria-current={activeIndex === i ? 'step' : undefined}
                className={styles['StepsProgress-step-number']}
              >
                {i + 1}
              </span>
              {riftNumber ? renterTitleRift(i) : stepNames[steps[i]]}
            </li>
          );
        })}
      </ol>
      <div>
        {children}
      </div>
    </div>
  );
});
