import Enrollments from '../Enrollments';

const EnrollmentsTeaching = () => {
  return (
    <Enrollments mode="teachingInstitution" status="accepted"/>
  );
};
EnrollmentsTeaching.meta = () => ({
  title: 'Teaching Enrollments'
});

EnrollmentsTeaching.breadcrumbs = () => [
  ['Manage Enrollments', '/enrollments'],
  'Teaching Enrollments'
];

export default EnrollmentsTeaching;
