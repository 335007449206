import classNames from 'classnames';
import type { CSSProperties } from 'react';
import React from 'react';

import type { AsProp } from '../../utils/props';
import { Col } from '../Col';
import { Row } from '../Row';
import { Separator } from '../Separator';
import { Text } from '../Text';


import styles from './ContentSection.module.scss';

export interface ContentSectionProps extends AsProp {
  className?: string;
  header?: React.ReactNode;
  title?: React.ReactNode;
  description?: { content: React.ReactNode, separator?: boolean; } | React.ReactNode;
  children: React.ReactNode;
  fullWidth?: boolean;
  border?: boolean;
  padding?: 'lg' | 'md' | false;
  style?: CSSProperties;
}

export const ContentSection: React.FC<ContentSectionProps> = ({
  as: Component = 'section',
  className,
  children,
  title,
  description,
  border = true,
  padding = 'lg',
  fullWidth,
  style,
  header
}) => {
  return (
    <Component
      style={style}
      className={classNames(className, styles.ContentSection, {
        [styles[`padding-${padding}`]]: padding,
        [styles.border]: border,
        [styles.fullWidth]: fullWidth
      })}>
      {header && (
        <>
          {header}
          <Separator m="lg"/>
        </>
      )}
      <Row>
        {title && (
          <Col system={24} md={7} className={styles.header}>
            <Text
              as="h3"
              color="black"
              variant="headingSm"
            >
              {title}
            </Text>
          </Col>
        )}
        <Col system={24} md={title ? 17 : 24}>
          {description && (
            <>
              <Text variant="bodyLg" color="secondary">
                {typeof description === 'object' && 'content' in description ? description.content : description}
              </Text>
              {(typeof description !== 'object' || ('separator' in description && description.separator !== false)) && (
                <Separator mt="md" mb="lg"/>
              )}
            </>
          )}
          {children}
        </Col>
      </Row>
    </Component>
  );
};
