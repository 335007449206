import config from 'config';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import getLocationUrl from 'common-lib/lib/getLocationUrl';

import { getAccessTokenFromLocalStorage } from '@acadeum/auth';

import Button from '../../components/Button';
import Loader from '../../components/Loader';

import useLocation from '../../hooks/useLocation';

import './ZendeskLogin.sass';

// Zendesk redirects to this page when a user tries logging in
// directly on Acadeum Zendesk Website rather than on this website
// through the "Support" link in the top bar.
export default function ZendeskLogin() {
  const location = useLocation();

  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);

  const {
    return_to,
    kind,
    message
  } = location.query;

  const singleSignOnUrl = getLocationUrl({
    origin: config.apiUrl,
    pathname: '/zendesk/single-sign-on',
    query: {
      // Even though the Course Share website normally uses cookies for authentication,
      // cookies aren't used when the website is run in development on `localhost`.
      // So local storage is still used for storing access token when running on `localhost`.
      accessToken: getAccessTokenFromLocalStorage(),
      redirectTo: return_to
    }
  });

  useEffect(() => {
    // Section "Error handling": https://support.zendesk.com/hc/en-us/articles/4408845838874#topic_cff_kst_kk
    // If 'kind' is not undefined it is always an error.
    if (kind) {
      setLoading(false);
      setError(true);
      setTimeout(() => {
        throw new Error(`Zendesk SSO error: ${message}. Url: ${window.location}`);
      }, 0);
    } else {
      window.location = singleSignOnUrl;
    }
  }, []);

  return (
    <section className="ZendeskLogin">
      {loading &&
        <>
          <Loader isImposter/>
          <br/>
          <br/>
        </>
      }

      <h1>
        {loading && 'Logging you into Acadeum Support'}
        {error && 'There was an error while logging you into Acadeum Support.'}
      </h1>

      {error &&
        <>
          <br/>
          <br/>
          <Button onClick={() => {
            setError(false);
            setLoading(true);
            window.location = singleSignOnUrl;
          }}>
            Login to Acadeum Support
          </Button>
        </>
      }
    </section>
  );
}

ZendeskLogin.propTypes = {
  location: PropTypes.object
};

ZendeskLogin.meta = () => ({
  title: 'Login to Acadeum Support'
});
