import type { InferProps } from 'prop-types';
import PropTypes from 'prop-types';
import React from 'react';
// import classNames from 'classnames';

const propTypes = {
  page: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  getPageUrl: PropTypes.func,
  maxPageLinks: PropTypes.number.isRequired,
  scrollToTop: PropTypes.func.isRequired,
  As: PropTypes.elementType.isRequired,
  PageButton: PropTypes.elementType.isRequired,
  Ellipsis: PropTypes.elementType.isRequired,
  PrevNextButton: PropTypes.elementType.isRequired,
  BackToTop: PropTypes.elementType.isRequired
};

export type CCPaginationProps = InferProps<typeof propTypes>

export const CCPagination: React.FC<CCPaginationProps> = ({
  page,
  pageCount,
  setPage,
  getPageUrl,
  maxPageLinks,
  scrollToTop,
  As,
  PageButton,
  PrevNextButton,
  Ellipsis,
  BackToTop
}) => {
  const showFromPage = Math.max(Math.floor(page - maxPageLinks / 2), 1);
  const showToPage = Math.min(showFromPage + maxPageLinks - 1, pageCount);

  const shownPages: number[] = [];
  let shownPageNumber = showFromPage;
  while (shownPageNumber <= showToPage) {
    shownPages.push(shownPageNumber);
    shownPageNumber++;
  }

  const createGoToPage = (page) => {
    return (event) => {
      if (event && typeof event.preventDefault === 'function') {
        event.preventDefault();
      }
      scrollToTop();
      // `setTimeout` is required here so that the `scrollTo` could change
      // the scroll position, and the browser could fix the new scroll position.
      setTimeout(() => setPage(page), 0);
    };
  };

  const getUrl = (page) => {
    if (getPageUrl) {
      return getPageUrl(page);
    }
  };

  const prevPageButton = (
    <PrevNextButton
      direction="previous"
      disabled={page === 1}
      href={getUrl(page - 1)}
      onClick={createGoToPage(page - 1)}
    />
  );

  const nextPageButton = (
    <PrevNextButton
      direction="next"
      disabled={page === pageCount}
      href={getUrl(page + 1)}
      onClick={createGoToPage(page + 1)}
    />
  );

  const dots = (
    <Ellipsis/>
  );

  const renderPagination = () => (
    <As>
      {prevPageButton}
      {showFromPage > 1 &&
        renderPageLink(1)
      }
      {showFromPage > 2 &&
        dots
      }
      {shownPages.map(renderPageLink)}
      {showToPage < pageCount - 1 &&
        dots
      }
      {showToPage < pageCount &&
        <PageButton
          active={page === pageCount}
          href={getUrl(pageCount)}
          onClick={createGoToPage(pageCount)}>
          {pageCount}
        </PageButton>
      }
      {nextPageButton}
    </As>
  );

  const renderPageLink = (pageNumber) => (
    <PageButton
      key={pageNumber}
      active={pageNumber === page}
      href={getUrl(pageNumber)}
      onClick={createGoToPage(pageNumber)}>
      {pageNumber}
    </PageButton>
  );

  if (pageCount === 0) {
    return null;
  }

  return (
    <div className="Pagination-wrapper">
      {pageCount > 1 ? renderPagination() : <div/>}
      <div className="Pagination Pagination-backToTopContainer">
        <BackToTop onClick={scrollToTop}/>
      </div>
    </div>
  );
};

CCPagination.propTypes = propTypes;
