import React from 'react';

import { useTranslate } from '@acadeum/translate';
import { ContentSection, FormField } from '@acadeum/ui';

export interface NotesSectionValues {
  notes?: string;
}

export function NotesSection() {
  const t = useTranslate('shared-admin-ui.CourseForm');

  return (
    <ContentSection title={t('notes.title')}>
      <FormField
        noMargin
        multiline
        name="notes"
        label={t('notes.notes.label')}
        helpText={t('notes.notes.helpText')}
        placeholder={t('notes.notes.placeholder')}
      />
    </ContentSection>
  );
}
