import React, { memo } from 'react';

import { Avatar } from '@acadeum/core-ui';
import type { Institution as InstitutionType } from '@acadeum/types';

import { useGetInstitutionLink } from '../../../../utils/institutionLink';

import { Link } from '../../../Link';

import styles from './InstitutionBlock.module.scss';

type Institution = (Pick<InstitutionType, 'id'> | Pick<InstitutionType, 'vanityUrl'>) & {
  name: InstitutionType['name'];
  logoUrl?: InstitutionType['logoUrl'];
}

export interface InstitutionBlockProps {
  institution: Institution;
  url?: string | false;
  hideLogo?: boolean;
}

export const InstitutionBlock = memo<InstitutionBlockProps>(({
  institution,
  hideLogo,
  url: propsUrl
}) => {
  const getInstitutionLink = useGetInstitutionLink();

  const url = propsUrl !== undefined ? propsUrl : getInstitutionLink?.(institution);

  return (
    <div className={styles.InstitutionBlock}>
      {!hideLogo && (
        <Avatar
          size={32}
          type="institution"
          // institution.logo is fallback for old code
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          url={institution.logoUrl || institution.logo}
        />
      )}
      {url ? (
        <Link external to={url}>
          {institution.name}
        </Link>
      ) : (
        <span>
          {institution.name}
        </span>
      )}
    </div>
  );
});
