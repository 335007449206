import PropTypes from 'prop-types';
import React from 'react';

import { InfoTooltip } from '@acadeum/ui';

import FormFieldErrorBadge from '../FormFieldErrorBadge';

import './FormFieldLabel.sass';

export default function FormFieldLabel({
  id,
  forInputId,
  required,
  tooltip,
  error,
  children
}) {
  return (
    <div className="form-input__label">
      <div className="form-input__label-left">
        <label
          id={id}
          htmlFor={forInputId}
          className="form-input__label-text">
          {children}
          {required &&
            <span className="form-input__label-asterisk">&nbsp;*</span>
          }
        </label>

        {tooltip &&
          <InfoTooltip>
            {tooltip}
          </InfoTooltip>
        }
      </div>

      {error && (
        <FormFieldErrorBadge/>
      )}
    </div>
  );
}

FormFieldLabel.propTypes = {
  id: PropTypes.string,
  forInputId: PropTypes.string,
  error: PropTypes.bool,
  children: PropTypes.string.isRequired,
  required: PropTypes.bool
};
