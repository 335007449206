/* eslint-disable */
// @ts-nocheck
import React, { forwardRef, useCallback, useEffect, useMemo, useState, useRef } from 'react';
import classNames from 'classnames';
import { uniqBy } from 'lodash-es';

import { SeeDetailsIcon, SpinnerThirdIcon as Loading, PlusIcon, SearchIcon } from '@acadeum/icons';
import { useIsMounted, useToggle } from '@acadeum/hooks';
import { IconSource } from '@acadeum/types';

import { SearchBar } from '../SearchBar';
import { Button } from '../Button';
import { Listbox } from '../Listbox';
import { Checkbox } from '../Checkbox';
import { EmptyState } from '../EmptyState';

import { ChoiceListListboxContext, ChoiceListListboxOptionContext } from './context';

import { useFilteredOptions } from '../../utils/useFilteredOptions';

import styles from './ChoiceList.module.scss';

export const EMPTY_VALUE = null;

type Option = {
  label: string;
  value: any;
  suffixLabel?: string;
  description?: string;
}
type Options = Option[];
type TransformOptions = (options: Options) => Options;
type AddNewItemProps = {
  label: string;
  url?: string;
  onClick?: () => {};
}

interface ChoiceListProps {
  async?: boolean;
  fetchOptions: (value: string) => Promise<Options>;
  findOptionByValue: (value: any) => Options;
  options: Options;
  transformOptions: TransformOptions;
  onChange: (value: any[]) => string;
  placeholder?: string;
  value?: any;
  notFoundLabel?: string;
  notFoundIcon?: IconSource;
  emptyStateTitle?: string;
  emptyStateIcon?: IconSource;
  itemLabel?: string | {
    plural: string;
    singular: string;
  };
  loading?: boolean;
  size: 'medium' | 'large';
  addNewItem: AddNewItemProps;
}

export const ChoiceList = forwardRef<HTMLInputElement, ChoiceListProps>(({
  async,
  fetchOptions: propsFetchOptions,
  findOptionByValue: propsFindOptionByValue,
  options: propsOptions,
  transformOptions: propsTransformOptions,
  onChange: propsOnChange,
  placeholder,
  value: propsValue,
  notFoundLabel = 'There are no results matching your search',
  notFoundIcon: NotFoundIcon = SearchIcon,
  emptyStateTitle = 'Once you start typing, the results will be shown here.',
  emptyStateIcon: EmptyStateIcon = SearchIcon,
  itemLabel: propsItemLabel,
  loading: propsLoading,
  size,
  addNewItem
}, ref) => {
  const itemLabel = (typeof propsItemLabel === 'object' ? propsItemLabel.singular : propsItemLabel) || 'Item';
  const itemLabelPlural = (typeof propsItemLabel === 'object'
    ? (propsItemLabel.plural || propsItemLabel.singular)
    : propsItemLabel) || 'Items';

  const {
    options,
    fetchOptions,
    findOptionByValue,
    findOptionsByValues,
    isLoading
  } = useFilteredOptions({
    async,
    options: propsOptions,
    fetchOptions: propsFetchOptions,
    findOptionByValue: propsFindOptionByValue,
    transformOptions: propsTransformOptions,
    isLoading: propsLoading
  });

  const { value: isViewAll, toggle: toggleViewAll } = useToggle(true);
  const [listboxId, setListboxId] = useState<string>();
  const [textFieldFocused, setTextFieldFocused] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<Options>(propsValue || []);
  const [activeOptionId, setActiveOptionId] = useState<string>();
  const [inputValue, setInputValue] = useState('');

  const isMounted = useIsMounted();

  const selectOptionsForValues = async (values) => {
    if (!values) {
      return setSelectedOptions([]);
    }

    const matchingSelectedOptions = selectedOptions.filter(option => values.some(value => option.value === value));
    if (matchingSelectedOptions.length === values.length) {
      return;
    }

    const foundOptions = await findOptionsByValues(values);
    if (isMounted()) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (foundOptions && foundOptions.length === values.length) {
        return setSelectedOptions(foundOptions as Options);
      }
    }
  };

  useEffect(() => {
    if (isMounted()) {
      void selectOptionsForValues(propsValue);
    }
  }, [propsValue]);

  /**
   * This is necessary if the value is defined during the mount, but no options yet.
   * Used inside Admin-Center-Site [CreateRoleForm].
   * */
  const initialEmptyOptions = useRef(Array.isArray(options) ? options.length === 0 : true);
  const initialEmptyOptionsWorked = useRef(false);

  useEffect(() => {
    if (isMounted() && !initialEmptyOptionsWorked.current) {
      if (initialEmptyOptions.current && options?.length) {
        void selectOptionsForValues(propsValue);
        initialEmptyOptionsWorked.current = true;
      }
    }
  }, [options]);

  const updateText = useCallback(async (value) => {
    setInputValue(value);
    await fetchOptions(value);
  }, [fetchOptions]);

  const listboxContextValue = useMemo(() => ({
    listboxId,
    setListboxId,
    textFieldFocused,
    setActiveOptionId
  }), [
    listboxId,
    setListboxId,
    textFieldFocused,
    setActiveOptionId
  ]);

  const onChange = async (options) => {
    setSelectedOptions(options);
    // await updateText('');
    if (propsOnChange) {
      const values = options.map(_ => _.value);
      propsOnChange(values.length === 0 ? EMPTY_VALUE : values);
    }
  };

  const onSelect = async (value) => {
    const getSelectedOptions = (option) => {
      return selectedOptions.includes(option) ? selectedOptions.filter((_) => _.value !== value) : [
        ...selectedOptions,
        option
      ];
    };

    const matchedSelectedOption = selectedOptions.find(_ => _.value === value);
    if (matchedSelectedOption) {
      const options = getSelectedOptions(matchedSelectedOption);
      await onChange(options);
      return;
    }

    const matchedOption = options.find(_ => _.value === value);
    if (matchedOption) {
      const options = getSelectedOptions(matchedOption);
      await onChange(options);
      return;
    }

    if (async) {
      const foundOption = await findOptionByValue(value);
      if (isMounted()) {
        const options = getSelectedOptions(foundOption);
        await onChange(options);
      }
    }
  };

  const onFocusTextField = useCallback(() => {
    setTextFieldFocused(true);
  }, [setTextFieldFocused]);

  const onBlurTextField = useCallback(() => {
    setTextFieldFocused(false);
  }, [setTextFieldFocused]);

  const renderToggleAllOptions = () => {
    const matchingSelectedOptions = selectedOptions.filter(_ => options.some(option => option.value === _.value));
    const selectedOptionsCount = matchingSelectedOptions.length;

    const toggleAll = async () => {
      if (selectedOptionsCount > 0) {
        const nonMatchingSelectedOptions = selectedOptions.filter(_ => !options.some(option => option.value === _.value));
        await onChange(nonMatchingSelectedOptions);
      } else {
        await onChange(uniqBy([...options, ...selectedOptions], 'value'));
      }
    };

    const generateLabel = () => {
      if (selectedOptionsCount) {
        return selectedOptionsCount + ' of ' + options.length + ' ' + (options.length === 1
          ? `${itemLabel} selected`
          : `${itemLabelPlural} selected`);
      }

      return options.length + ' ' + (options.length === 1
        ? itemLabel
        : itemLabelPlural);
    };

    return (
      <Checkbox
        className={styles.ChoiceList__label}
        checked={selectedOptionsCount > 0}
        indeterminate={selectedOptionsCount > 0 && selectedOptionsCount < options.length}
        onChange={toggleAll}
        label={generateLabel()}
      />
    );
  };

  return (
    <div>
      <div className={styles.ChoiceList__header}>
        <SearchBar
          ref={ref}
          className={styles.ChoiceList__SearchBar}
          placeholder={placeholder}
          aria-activedescendant={activeOptionId}
          aria-controls={listboxId}
          onFocus={onFocusTextField}
          onBlur={onBlurTextField}
          onValueChange={updateText}
          value={inputValue}
          clearNone
        />
        <div className={styles.ChoiceList__toggleWrap}>
          <Button icon={SeeDetailsIcon} variant="text-with-padding" onClick={toggleViewAll}>
            {isViewAll ? `View Selected (${selectedOptions.length})` : 'View All'}
          </Button>
        </div>
      </div>
      {isViewAll && options.length > 0 && (
        <div className={styles.ChoiceList__commonToggleContainer}>
          {renderToggleAllOptions()}
          {addNewItem && (
            <Button
              icon={PlusIcon}
              variant="text-inline"
              url={addNewItem.url}
              onClick={addNewItem.onClick}
            >
              {addNewItem.label}
            </Button>
          )}
        </div>
      )}
      {!isViewAll && selectedOptions.length > 0 && (
        <div className={styles.ChoiceList__commonToggleContainer}>
          <Checkbox
            className={styles.ChoiceList__label}
            checked={selectedOptions.length > 0}
            onChange={async () => await onChange([])}
            label={selectedOptions.length + ' ' + (selectedOptions.length === 1 ? itemLabel : itemLabelPlural)}
          />
        </div>
      )}
      <ChoiceListListboxContext.Provider value={listboxContextValue}>
        <Listbox
          appearance="white"
          onSelect={onSelect}
          className={classNames(styles.Listbox, {
            [styles[`Listbox--${size}`]]: size
          })}
        >
          <ChoiceListListboxOptionContext.Provider value={{ multiple: true }}>
            {isViewAll ? (
              isLoading ? (
                <Listbox.EmptyOption className={styles.EmptyOption}>
                  <EmptyState
                    size="small"
                    icon={({ className }) => <Loading className={classNames(className, styles.SvgLoading)}/>}
                    children="Loading..."
                  />
                </Listbox.EmptyOption>
              ) : options.length > 0 ? (
                options.map((option, index) => {
                  const {
                    value,
                    label,
                    suffixLabel,
                    description,
                    component
                  } = option;

                  return (
                    <Listbox.Option
                      description={description}
                      className={styles.Option}
                      key={`option-${index}`}
                      value={value}
                      selected={(selectedOptions.find(option => option.value === value)?.value) === value}
                    >
                      {component ? component : label}
                      {suffixLabel && (
                        <span className={styles.ChoiceList__id}>{suffixLabel}</span>
                      )}
                    </Listbox.Option>
                  );
                })
              ) : (
                <Listbox.EmptyOption className={styles.EmptyOption}>
                  {inputValue.length > 0 ? (
                    <EmptyState
                      size="small"
                      icon={NotFoundIcon}
                      children={notFoundLabel}
                    />
                  ) : (
                    <EmptyState
                      size="small"
                      icon={EmptyStateIcon}
                      children={emptyStateTitle}
                    />
                  )}
                </Listbox.EmptyOption>
              )
            ) : (
              selectedOptions.length === 0 ? (
                <Listbox.EmptyOption className={styles.EmptyOption}>
                  <EmptyState
                    size="small"
                    icon={EmptyStateIcon}
                    children="No Option Selected"
                  />
                </Listbox.EmptyOption>
              ) : (
                selectedOptions.map((option, index) => {
                  const {
                    value,
                    label,
                    suffixLabel,
                    description,
                    component
                  } = option;

                  return (
                    <Listbox.Option
                      className={styles.Option}
                      key={`option-${index}`}
                      description={description}
                      value={value}
                      selected={(selectedOptions.find(option => option.value === value)?.value) === value}
                    >
                      {component ? component : label}
                      {suffixLabel && (
                        <span className={styles.ChoiceList__id}>{suffixLabel}</span>
                      )}
                    </Listbox.Option>
                  );
                })
              )
            )}
          </ChoiceListListboxOptionContext.Provider>
        </Listbox>
      </ChoiceListListboxContext.Provider>
    </div>
  );
});
