import { ReduxModule } from 'react-pages';

const redux = new ReduxModule();

export const fetchStudent = redux.action(
  (id) => (http) => http.get(`/students/${id}`),
  (state, student) => ({
    ...state,
    student
  })
);

export const fetchStudents = redux.action(
  ({ page, pageSize, search }) => async (http) => {
    return http.get('/students', { page, pageSize, search });
  }
);

export const setStudents = redux.simpleAction(
  (state, students) => ({
    ...state,
    students,
    studentsCount: students.length
  })
);

export const fetchAllStudents = redux.action(
  // The `totalCount` is not written to Redux state as `allStudentsCount`
  // because there's a `search` parameter in the arguments of this action
  // and that means that `totalCount` would be not the count of all Home Students
  // but rather the count of all Home Students matching the `search` filter.
  // And `allStudentsCount` is used to show a counter on the Home Students tab,
  // so it should correspond to the total count of all Home Students.
  ({ page, pageSize, search, filters }) => async (http) => {
    return http.get('/all-students', {
      page,
      pageSize,
      search,
      filters: JSON.stringify(filters)
    });
  }
);

export const setAllStudents = redux.simpleAction(
  (state, allStudents) => ({
    ...state,
    allStudents
  })
);

export const fetchStudentsNoRace = redux.action(
  () => async (http) => {
    return http.get('/students', {
      pageSize: 100000000000,
      raceAndEthnicityNotSpecified: '✓'
    });
  },
  (state, { results, totalCount }) => ({
    ...state,
    studentsNoRace: results,
    studentsNoRaceCount: totalCount
  })
);

export const fetchStudentsForExport = redux.action(
  ({ ids, page, pageSize }) => async (http) => {
    // It doesn't return an object of shape `{ results, page, ... }`.
    // Instead, it just returns a list of results.
    return http.get('/students/export', {
      ids: JSON.stringify(ids),
      page,
      pageSize
    });
  }
);

export const fetchTeachingStudents = redux.action(
  // The `totalCount` is not written to Redux state as `teachingStudentsCount`
  // because there's a `search` parameter in the arguments of this action
  // and that means that the `totalCount` would be not the count of all Teaching Students
  // but rather the count of all Teaching Students matching the `search` filter.
  // And `teachingStudentsCount` is used to show a counter on the Teaching Students tab,
  // so it should correspond to the total count of all Teaching Students.
  ({ page, pageSize, search, filters }) => async (http) => {
    return http.get('/students-TM', {
      page,
      pageSize,
      search,
      filters: JSON.stringify(filters)
    });
  }
);

export const fetchTeachingStudentsForExport = redux.action(
  ({ ids, page, pageSize }) => (http) => {
    return http.get('/students-TM', {
      dataExport: '✓',
      ids: JSON.stringify(ids),
      page,
      pageSize
    });
  }
);

export const addStudent = redux.action(
  (values) => (http) => {
    Intercom('trackEvent', 'add student');
    return http.post('/students', values);
  }
);

export const editStudent = redux.action(
  (id, values) => (http) => {
    Intercom('trackEvent', 'edit student');
    return http.put(`/students/${id}`, values);
  }
);

export const getStudentsCounts = redux.action(
  () => (http) => http.get('/counts/students'),
  (state, counts) => ({
    ...state,
    ...counts
  })
);

export const getAllStudentsCount = redux.action(
  () => (http) => http.get('/all-students/count'),
  (state, count) => ({
    ...state,
    allStudentsCount: count
  })
);

export const setStudentsCount = redux.simpleAction(
  (state, count) => ({
    ...state,
    studentsCount: count
  })
);

export const setTeachingStudentsCount = redux.simpleAction(
  (state, count) => ({
    ...state,
    teachingStudentsCount: count
  })
);

export const getTeachingStudentsCount = redux.action(
  () => (http) => http.get('/students-TM', { pageSize: 0 }),
  (state, { totalCount }) => {
    return {
      ...state,
      teachingStudentsCount: totalCount
    };
  }
);

export const changeStudentEmail = redux.action(
  (data) => http => http.post('/students/change-email', data)
);

export const deleteStudent = redux.action(
  (id) => http => http.delete(`/students/${id}`)
);

export const bulkChangeStudentStatus = redux.action(
  (action, ids) => http => http.post(`/student-users/bulk-activate-or-deactivate?action=${action}&ids=${JSON.stringify(ids)}`)
);

export const getStudentsFiltersValues = redux.action(
  (filters) => (http) => http.get(`/students/property-values?properties=${JSON.stringify(filters)}`),
  (state, filters) => {
    return {
      ...state,
      studentFilters: filters
    };
  }
);

export const getStudentsTMFiltersValues = redux.action(
  (filters) => (http) => http.get(`/students-TM/property-values?properties=${JSON.stringify(filters)}`),
  (state, filters) => {
    return {
      ...state,
      studentFilters: filters
    };
  }
);

export default redux.reducer();
