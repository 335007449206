import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component, useId } from 'react';
import ReactGA from 'react-ga';
import { connectRange } from 'react-instantsearch-dom';

import './InstantSearchDate.sass';

// Note on time zone: the `date` from the "Date Picker" component should be in `UTC+0` time zone.
// It should also have the time set to `00:00` in that time zone.

class InstantSearchDate__ extends Component {
  static propTypes = {
    min: PropTypes.number,
    max: PropTypes.number,
    currentRefinement: PropTypes.object,
    defaultRefinement: PropTypes.object,
    refine: PropTypes.func.isRequired,
    canRefine: PropTypes.bool.isRequired,
    till: PropTypes.bool,
    label: PropTypes.string,
    gaLabel: PropTypes.string,
    className: PropTypes.string,
    DatePickerComponent: PropTypes.elementType.isRequired
  };

  state = {};

  // When some other filter has changed
  // this filter gets new `{ min, max }`
  // values as part of `currentRefinement`.
  // This handler keeps `this.state.date`
  // in sync with what Algolia thinks
  // the currently selected date is.
  componentDidUpdate() {
    const {
      till,
      canRefine,
      currentRefinement
    } = this.props;

    // According to Algolia, `canRefine` means that
    // there are "no available ranges for the current query".
    // In other words, it's supposed to mean "anything found".
    // Still, even if no results were found it can be `true`.
    // E.g. when changing `startDate` to September 18th.
    // http://localhost:3002/?%22range%5Bsession.startDate%5D%5Bmin%5D%22=1476738000000&%22range%5Bsession.startDate%5D%5Bmax%5D%22=1476705600000&%22range%5Bsession.endDate%5D%5Bmin%5D%22=1481284800000&%22range%5Bsession.endDate%5D%5Bmax%5D%22=1506718799999&%22page%22=1&%22refinementList%5Bsession.course.categories%5D%5B0%5D%22=%22Criminal%20Justice%22&%22refinementList%5Bsession.course.institution.name%5D%5B0%5D%22=%22UIW%22&%22refinementList%5Bsession.term%5D%5B0%5D%22=%22Fall%202016%22&%22query%22=%22Criminology%22&%22hitsPerPage%22=%2260%22

    if (canRefine) {
      const { min, max } = currentRefinement;

      if (till ? max !== this.state.max : min !== this.state.min) {
        // Set the time to be `12:00` in the user's timezone
        let date = new Date(till ? max : min);
        date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        date = new Date(date.getTime() + ONE_DAY/2);

        // `this.state.date` must be in sync with
        // what Algolia thinks the currently selected date is.
        this.setState({ date, min, max });
      }
    }
  }

  onChange = (date) => {
    const {
      min,
      max,
      currentRefinement,
      refine,
      till,
      onChange
    } = this.props;

    if (onChange) {
      onChange(date);
    }

    this.setState({ date });

    if (!date) {
      if (till) {
        return refine({
          min: currentRefinement.min,
          max: undefined
        });
      } 
      return refine({
        min: undefined,
        max: currentRefinement.max
      });
      
    }

    if (till) {
      // Assuming that `date` has the time set to `00:00` in some timezone,
      // set the time to be `23:59:...` in the same timezone.
      const timestamp = getToDateFilterValueForDate(date);
      // Refine search results using the selected date
      if (currentRefinement.max !== (max ? Math.min(timestamp, max) : timestamp)) {
        refine({
          min: currentRefinement.min,
          max: max ? Math.min(timestamp, max) : timestamp
        });
      }
    } else {
      // Assuming that `date` has the time set to `00:00` in some timezone,
      // set the time to be `00:00:...` in the same timezone.
      const timestamp = getFromDateFilterValueForDate(date);
      // Refine search results using the selected date
      if (currentRefinement.min !== (min ? Math.max(timestamp, min) : timestamp)) {
        refine({
          min: min ? Math.max(timestamp, min) : timestamp,
          max: currentRefinement.max
        });
      }
    }
  };

  onOpen = () => {
    const { gaLabel } = this.props;
    ReactGA.event({
      category: 'Filters',
      action: 'Clicked',
      label: gaLabel || '(date)'
    });
  };

  render() {
    const {
      min,
      max,
      // canRefine,
      currentRefinement,
      label,
      tabIndex,
      className,
      DatePickerComponent
    } = this.props;

    const { date } = this.state;

    // According to Algolia, `canRefine` means that
    // there are "no available ranges for the current query".
    // In other words, it's supposed to mean "anything found".
    // Still, even if no results were found it can be `true`.
    // E.g. when changing `startDate` to September 18th.
    // http://localhost:3002/?%22range%5Bsession.startDate%5D%5Bmin%5D%22=1476738000000&%22range%5Bsession.startDate%5D%5Bmax%5D%22=1476705600000&%22range%5Bsession.endDate%5D%5Bmin%5D%22=1481284800000&%22range%5Bsession.endDate%5D%5Bmax%5D%22=1506718799999&%22page%22=1&%22refinementList%5Bsession.course.categories%5D%5B0%5D%22=%22Criminal%20Justice%22&%22refinementList%5Bsession.course.institution.name%5D%5B0%5D%22=%22UIW%22&%22refinementList%5Bsession.term%5D%5B0%5D%22=%22Fall%202016%22&%22query%22=%22Criminology%22&%22hitsPerPage%22=%2260%22

    // If a user selected `min` or `max` date
    // then don't show the date input value.
    // This is done because initially
    // `currentRefinement` is `{ min, max }`
    // and therefore it can be confusing to a user.
    // By hiding date input value the interface
    // is communicating to the user that
    // no date restrictions are being set.
    let hideDate = false;
    if (currentRefinement) {
      // Don't show date when no date filter has been applied by the user.
      hideDate = currentRefinement.min === min && currentRefinement.max === max;
    }

    const id = `Field-${this.props.id}`;

    return (
      <div className={classNames('InstantSearch-Date', className)}>
        {label &&
          <label htmlFor={id} className="InstantSearch-Date-label">
            {label}
          </label>
        }
        <div className="InstantSearch-Date-datePickerContainer">
          <DatePickerComponent
            id={id}
            value={hideDate ? undefined : date}
            onChange={this.onChange}
            onOpen={this.onOpen}
            tabIndex={tabIndex}
            minDate={min}
            maxDate={max}
            aria-label={label} />

          {/* <InstantSearchDateCalendar className="InstantSearch-Date-datePickerIcon" /> */}
        </div>
      </div>
    );
  }
}

const InstantSearchDate_ = connectRange(InstantSearchDate__);

const InstantSearchDate = (props) => {
  const id = useId();
  return (
    <InstantSearchDate_ id={id} {...props}/>
  );
};

export default InstantSearchDate;

// Almost one day is added for `till` dates because
// the term "date" is not so obvious when filtering by it.
// For example, Jan 1st, 2001, 00:00 would be output as "01/01/2001",
// and 23:59 of the same date would be output the same way,
// so they're both "Jan 1st, 2001".
// At the same time, filtering by "from date" being "01/01/2001 00:00" would provide
// different results compared to filtering by "from date" being "01/01/2001 23:59".
// Therefore, `till` dates are stretched to their "maximum" (to `23:59`)
// so that all matching search results are shown.
const ONE_DAY = 24 * 60 * 60 * 1000;

// The user selects "from" date having `00:00` time in some time zone.
// The "from" date used in a search query also has some time in some time zone.
// Therefore, no conversion is required.
export function getDateForFromDateFilterValue(timestamp) {
  return new Date(timestamp);
}

// The user selects "from" date having `00:00` time in some time zone.
// The "from" date used in a search query also has some time in some time zone.
// Therefore, no conversion is required.
function getFromDateFilterValueForDate(date) {
  return date.getTime();
}

// The user selects "to" date having `00:00` time in some time zone.
// The "to" date used in a search query has `23:59:...` time in some time zone.
// This way, "to" date filter behaves correctly when dates aren't forced
// to have `00:00` time in some time zone.
// The conversion from the date used in a search query would be subtracting almost one day.
export function getDateForToDateFilterValue(timestamp) {
  return new Date(timestamp - (ONE_DAY - 1));
}

// The user selects "to" date having `00:00` time in some time zone.
// The "to" date used in a search query has `23:59:...` time in some time zone.
// This way, "to" date filter behaves correctly when dates aren't forced
// to have `00:00` time in some time zone.
// The conversion to the date used in a search query would be adding almost one day.
function getToDateFilterValueForDate(date) {
  return date.getTime() + (ONE_DAY - 1);
}
