import type { FC } from 'react';
import React from 'react';

import type { FetchCourseEnrollmentsOutput } from '@acadeum/api';
import { useTranslate } from '@acadeum/translate';
import type { ModalProps } from '@acadeum/ui';
import { Blank, ContentSection, DataBlock, Modal, Text } from '@acadeum/ui';

import type { CourseEnrollmentMode } from '../../types';

import styles from './CourseEnrollmentsDetailsModal.module.scss';

interface CourseEnrollmentsDetailsModalProps extends Pick<ModalProps, 'onHide' | 'show'> {
  downloadRow: () => Promise<void>;
  enrollments: FetchCourseEnrollmentsOutput['results'];
  mode: CourseEnrollmentMode;
}

export const CourseEnrollmentsDetailsModal: FC<CourseEnrollmentsDetailsModalProps> = ({
  enrollments,
  onHide,
  show,
  downloadRow,
  mode
}) => {
  const t = useTranslate('CourseEnrollmentsTable');

  if (enrollments.length === 0) {
    return null;
  }

  return (
    <Modal
      onHide={onHide}
      show={show}
      status={enrollments[0].status}
      title={t('enrollment')}
      actions={[
        {
          title: 'Download',
          onClick: downloadRow
        }
      ]}
    >
      <Modal.Body>
        {enrollments.map(data => {
          return (
            <div key={data.id} className={styles.content}>
              <ContentSection padding={false} border={false} title={t('student')}>
                {data.student ? (
                  <DataBlock
                    type="student"
                    student={data.student}
                  />
                ) : (
                  <Blank/>
                )}
              </ContentSection>
              {mode === 'homeInstitution' && (
                <ContentSection
                  padding={false}
                  border={false}
                  title={t('teachingInstitution')}
                >
                  <DataBlock
                    hideLogo
                    type="institution"
                    institution={data.section.course.institution}
                  />
                </ContentSection>
              )}
              {mode === 'teachingInstitution' && (
                <ContentSection
                  padding={false}
                  border={false}
                  title={t('homeInstitution')}
                >
                  {data.homeInstitution ? (
                    <DataBlock
                      type="institution"
                      hideLogo
                      institution={data.homeInstitution}
                    />
                  ) : (
                    <Blank/>
                  )}
                </ContentSection>
              )}
              <ContentSection padding={false} border={false} title={t('enrollmentType')}>
                <Text>
                  {data.type}
                </Text>
                {data.letterGrade && (
                  <Text color="grey" variant="bodySm">
                    {data.letterGrade}
                  </Text>
                )}
              </ContentSection>
              <ContentSection padding={false} border={false} title={t('course')}>
                <DataBlock
                  type="course"
                  course={data.section.course}
                />
              </ContentSection>
              <ContentSection padding={false} border={false} title={t('dates')}>
                <DataBlock
                  withLastDropDate={mode === 'homeInstitution'}
                  type="courseSessionDates"
                  session={data.section}
                />
              </ContentSection>
            </div>
          );
        })}
      </Modal.Body>
    </Modal>
  );
};
