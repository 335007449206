import React from 'react';
import { useSelector } from 'react-redux';

import { CourseSharingGroupForm } from '@acadeum/shared-admin-ui';
import { useTranslate} from '@acadeum/translate';
import { Page } from '@acadeum/ui';

import actions from '../../actions';
import { useUpdateCourseSharingGroupJob } from '../../api/admin/courseSharingGroups';
import type { ReduxState } from '../../helpers/app.types';


const { fetchCourseSharingGroup, goto } = actions;

const EditCourseSharingGroup = () => {
  const courseSharingGroup = useSelector((state: ReduxState) => state.courseSharingGroups.courseSharingGroup);

  const t = useTranslate('EditCourseSharingGroup');

  const updateCourseSharingGroup = useUpdateCourseSharingGroupJob();

  const defaultValues = {
    institutionIds: courseSharingGroup.institutions.map(_ => _.id),
    consortiumIds: courseSharingGroup.consortia.map(_ => _.id),
    ...courseSharingGroup
  };

  const onSubmit = async (values) => {
    if (!values.institutionIds) {
      values.institutionIds = [];
    }
    if (!values.consortiumIds) {
      values.consortiumIds = [];
    }

    await updateCourseSharingGroup({id: courseSharingGroup.id, body: values});
    goto('/admin/course-sharing-groups');
  };

  return (
    <Page
      title={t('title')}
      breadcrumbs={[
        [t('home', { global: true }), '/'],
        [t('adminTools', { global: true }), '/admin'],
        [t('courseSharingGroups'), '/admin/course-sharing-groups'],
        [t('courseSharingGroup'), `/admin/course-sharing-groups/${courseSharingGroup.id}`],
        t('edit', { global: true })
      ]}
    >
      <CourseSharingGroupForm
        defaultValues={defaultValues}
        submitText={t('save', { global: true })}
        cancelUrl="/admin/course-sharing-groups"
        onSubmit={onSubmit}
      />
    </Page>
  );
};

EditCourseSharingGroup.load = async ({ params: { id } }) => {
  await fetchCourseSharingGroup(id);
};

export default EditCourseSharingGroup;
