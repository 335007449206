import type { FC } from 'react';
import React, { useMemo } from 'react';

import formatUserName from 'common-lib/lib/formatUserName';

import type { FetchUserRolesOutput, UseSetUsersRolesForUsersMutation } from '@acadeum/api';
import { useTranslate } from '@acadeum/translate';
import type { User } from '@acadeum/types';
import { FormField, FormModal } from '@acadeum/ui';
import type { FormModalProps, OnSubmit } from '@acadeum/ui';

interface FormValues {
  roleId: number;
}

export interface ReassignUserRoleToUsersOrUserModalProps extends Pick<FormModalProps, 'show' | 'onHide'> {
  roles: FetchUserRolesOutput['results'];
  users: Pick<User, 'id' | 'firstName' | 'middleName' | 'lastName' | 'title'>[];
  onSuccessfulSubmit?: () => void;
  useSetUsersRolesForUsersMutation: UseSetUsersRolesForUsersMutation;
}

export const ReassignUserRoleToUsersOrUserModal: FC<ReassignUserRoleToUsersOrUserModalProps> = ({
  show,
  onHide,
  roles,
  users,
  onSuccessfulSubmit,
  useSetUsersRolesForUsersMutation
}) => {
  const t = useTranslate('shared-admin-ui.ReassignUserRoleToUsersOrUserModal');

  const [setUsersRolesForUsersMutation] = useSetUsersRolesForUsersMutation();

  const userRoleOptions = useMemo(() => roles.map((role) => ({
    value: role.id,
    label: role.name,
    description: role.description
  })), [roles]);

  const onSubmit: OnSubmit<FormValues> = async ({ roleId }) => {
    await setUsersRolesForUsersMutation({
      roleIds: [roleId],
      userIds: users.map(user => user.id)
    }).unwrap();
    onHide(false);
    onSuccessfulSubmit?.();
  };

  const userOptions = useMemo(() => {
    return users.map(_ => ({
      value: _.id,
      label: formatUserName(_)
    }));
  }, [users]);

  return (
    <FormModal
      show={show}
      title={t('reassignRole')}
      onHide={onHide}
      onCancel={onHide}
      onSubmit={onSubmit}
      submitText={t('reassign')}
    >
      {userOptions.length === 1 ? (
        <FormField
          readOnly
          name="fullName"
          label={t('user')}
          defaultValue={userOptions[0].label}
        />
      ) : (
        <FormField
          readOnly
          multiple
          id="users"
          type="select"
          name="users"
          label={t('users')}
          options={userOptions}
          defaultValue={userOptions.map(_ => _.value)}
        />
      )}
      <FormField
        required
        type="select"
        name="roleId"
        label={t('role', { global: true })}
        placeholder={t('placeholder')}
        options={userRoleOptions}
      />
    </FormModal>
  );
};
