import { Email } from 'read-excel-file';

import { parsePhone } from '@acadeum/helpers';
import {
  COUNTRY_OPTIONS,
  ETHNICITY_OPTIONS,
  GENDER_OPTIONS,
  getCountryRegionOptions,
  RACE_OPTIONS,
  STUDENT_LEVEL_OPTIONS,
  YES_OR_NO_OPTIONS
} from '@acadeum/selection-options';
import type { Options } from '@acadeum/types';
import { FormField } from '@acadeum/ui';
import { z } from '@acadeum/validate';

const zodStudentObjectSchema = z.object({
  id: z.string().optional().nullable(), // It's a field that `useArrayField` uses to identify the row.
  rowIndex: z.number(), // rowIndex is used to identify the row in the CSV file.
  status: z.string().optional().nullable(), // status is to indicate row status.

  hiStudentId: z.string(),
  firstName: z.name(),
  middleName: z.name().optional().nullable(),
  lastName: z.name(),
  email: z.email(),
  phone: z.phone().optional().nullable(),
  level: z.studentLevel(),
  gender: z.union([
    z.literal(true), // Male
    z.literal(false), // Female
    z.literal(null) // Unspecified
  ]).optional().nullable(),
  dob: z.dob(),
  citizenship: z.boolean(),
  advisorName: z.string().optional().nullable(),
  advisorEmail: z.email().optional().nullable(),
  major: z.string().optional().nullable(),
  startDate: z.date()
    .min(new Date(2000, 0, 1), { message: 'Start date cannot be earlier than January 1, 2000.' })
    .optional().nullable(),
  addressLine1: z.string(),
  addressLine2: z.string().optional().nullable(),
  city: z.string(),
  country: z.country(),
  state: z.string(),
  postalCode: z.string(),
  residency: z.string().optional().nullable(),
  notes: z.string().optional().nullable(),
  ethnicity: z.studentEthnicity(),
  races: z.races({ own: false })
}).superRefine((data, ctx) => {
  z.enforceCountryRegionConstraint({
    property: 'state'
  }, { data, ctx });
  z.enforceCountryRegionConstraint({
    property: 'residency',
    required: false
  }, { data, ctx });
});

export const zodStudentImportSchema = z.object({
  rows: z.array(zodStudentObjectSchema),
  formAction: z.string().optional()
});

export const STUDENT_IMPORT_SCHEMA = {
  'STUDENT ID': {
    prop: 'hiStudentId',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        name={`rows.${props.row.index}.hiStudentId`}
      />
    )
  },
  'STUDENT FIRST NAME': {
    prop: 'firstName',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        name={`rows.${props.row.index}.firstName`}
      />
    )
  },
  'STUDENT MIDDLE NAME': {
    prop: 'middleName',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        name={`rows.${props.row.index}.middleName`}
      />
    )
  },
  'STUDENT LAST NAME': {
    prop: 'lastName',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        name={`rows.${props.row.index}.lastName`}
      />
    )
  },
  'STUDENT EMAIL': {
    prop: 'email',
    type: Email,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="email"
        name={`rows.${props.row.index}.email`}
      />
    )
  },
  'STUDENT MOBILE PHONE': {
    prop: 'phone',
    parse(value) {
      if (!value) {
        return null;
      }
      // Even though Excel shows "Text" as the data type for phone number cells
      // having values like "2146845526", under the hood it's still written as a number
      // in such Excel files for some weird reason.
      // So the code here manually converts any numbers to strings.
      // https://github.com/Acadeum/Tickets/issues/1364
      if (typeof value === 'number') {
        value = String(value);
      }
      return parsePhone(value, { defaultCountry: 'US' });
    },
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="phone"
        name={`rows.${props.row.index}.phone`}
      />
    )
  },
  'LEVEL': {
    prop: 'level',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="select"
        options={STUDENT_LEVEL_OPTIONS}
        name={`rows.${props.row.index}.level`}
      />
    )
  },
  'GENDER': {
    prop: 'gender',
    oneOf: [
      true,
      false,
      null
    ],
    parse(value) {
      // `false` corresponds to "Female".
      // `true` corresponds to "Male".
      // `null` corresponds to "Unspecified".
      switch (value) {
        case 'Female':
          return false;
        case 'Male':
          return true;
        case 'Unspecified':
          return null;
      }
    },
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="select"
        options={GENDER_OPTIONS}
        name={`rows.${props.row.index}.gender`}
      />
    )
  },
  'DATE OF BIRTH': {
    prop: 'dob',
    type: Date,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="date"
        name={`rows.${props.row.index}.dob`}
      />
    )
  },
  'US CITIZENSHIP': {
    prop: 'citizenship',
    parse(value) {
      if (!value) {
        return null;
      }
      return value.trim().toLowerCase() === 'y' || value.trim().toLowerCase() === 'yes';
    },
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="select"
        options={YES_OR_NO_OPTIONS}
        name={`rows.${props.row.index}.citizenship`}
      />
    )
  },
  'ADVISOR NAME': {
    prop: 'advisorName',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        name={`rows.${props.row.index}.advisorName`}
      />
    )
  },
  'ADVISOR EMAIL': {
    prop: 'advisorEmail',
    type: Email,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="email"
        name={`rows.${props.row.index}.advisorEmail`}
      />
    )
  },
  'STUDENT MAJOR': {
    prop: 'major',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        name={`rows.${props.row.index}.major`}
      />
    )
  },
  'START DATE': {
    prop: 'startDate',
    type: Date,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="date"
        name={`rows.${props.row.index}.startDate`}
      />
    )
  },
  'ADDRESS - LINE 1': {
    prop: 'addressLine1',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        name={`rows.${props.row.index}.addressLine1`}
      />
    )
  },
  'ADDRESS - LINE 2': {
    prop: 'addressLine2',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        name={`rows.${props.row.index}.addressLine2`}
      />
    )
  },
  'ADDRESS - CITY': {
    prop: 'city',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        name={`rows.${props.row.index}.city`}
      />
    )
  },
  'ADDRESS - STATE': {
    prop: 'state',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="select"
        options={getCountryRegionOptions(props.row.original.country)}
        name={`rows.${props.row.index}.state`}
      />
    )
  },
  'ADDRESS - COUNTRY': {
    prop: 'country',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="select"
        options={COUNTRY_OPTIONS}
        name={`rows.${props.row.index}.country`}
      />
    )
  },
  'ADDRESS - ZIP CODE': {
    prop: 'postalCode',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        name={`rows.${props.row.index}.postalCode`}
      />
    )
  },
  'RESIDENCY': {
    prop: 'residency',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="select"
        options={getCountryRegionOptions(props.row.original.country)}
        name={`rows.${props.row.index}.residency`}
      />
    )
  },
  'NOTES': {
    prop: 'notes',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        autoRows
        multiline
        showErrorTooltip
        name={`rows.${props.row.index}.notes`}
      />
    )
  },
  'ETHNICITY': {
    prop: 'ethnicity',
    type: String,
    required: true,
    parse: (value) => parseEthnicity(value, ETHNICITY_OPTIONS),
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="select"
        options={ETHNICITY_OPTIONS}
        name={`rows.${props.row.index}.ethnicity`}
      />
    )
  },
  'RACE': {
    prop: 'races',
    type: String,
    parse(names) {
      const values = names
        .split(',')
        .map(_ => _.trim());

      return values.map((name) => {
        for (const race of RACE_OPTIONS) {
          if (race.label === name) {
            return race.value;
          }
        }
      });
    },
    field: (props) => (
      <FormField
        noMargin
        multiple
        showErrorTooltip
        type="select"
        options={RACE_OPTIONS}
        name={`rows.${props.row.index}.races`}
      />
    )
  }
};

function parseEthnicity(
  ethnicity: string | undefined,
  options: Options<string>
) {
  if (ethnicity?.toLowerCase() === 'not reported') {
    return '-';
  }
  const ethnicityValue = options.find(_ => _.value === ethnicity);
  if (ethnicityValue) {
    return ethnicityValue.value;
  }
}
