import config from 'config';
import React from 'react';
import { useSelector } from 'react-redux';

import useLocation from '../../hooks/useLocation';

import ConsortiumSearch from './ConsortiumSearch_';
import ConsortiumSearchResult from './ConsortiumSearchResult';


export default function CCInstitutionSearch(props) {
  const { isNarrowContent } = useSelector(state => state.app);

  const location = useLocation();

  return (
    <ConsortiumSearch
      {...props}
      layout="rowGridTwoColumns"
      component={ConsortiumSearchResult}
      isNarrowContent={isNarrowContent}
      indexPrefix={config.algolia.prefix}
      appId={config.algolia.appID}
      apiKey={config.algolia.apiKey}
      resultsPerPage={[30, 60, 90]}
      location={location}
    />
  );
}
