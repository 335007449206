import type { RowData, Table } from '@tanstack/react-table';
import React, { useState } from 'react';

import { ColumnIcon } from '@acadeum/icons';

import { Button } from '../../../Button';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger
} from '../../../DropdownMenu';

import { ACTIONS_COLUMN_ID, EXPANDING_COLUMN_ID, SELECT_COLUMN_ID } from '../../utils/consts';

import styles from './ColumnVisibility.module.scss';


export interface ColumnVisibilityProps<TData> {
  table: Table<TData>;
}

export const ColumnVisibility = <TData extends RowData>({
  table
}: ColumnVisibilityProps<TData>) => {
  const [show, setShow] = useState(false);
  const preventClose = (e: Event) => e.preventDefault();
  return (
    <DropdownMenu open={show} onOpenChange={setShow}>
      <DropdownMenuTrigger asChild>
        <Button
          icon={ColumnIcon}
          variant="tertiary"
          disclosure={show ? 'up' : 'down'}
          title="Toggle column visibility"
          aria-label=" Toggle column visibility"
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className={styles.menu}>
        <DropdownMenuCheckboxItem
          checked={table.getIsAllColumnsVisible() ? true : table.getIsSomeColumnsVisible() ? 'indeterminate' : false}
          onCheckedChange={table.toggleAllColumnsVisible}
          onSelect={preventClose}
        >
          <strong>
            All
          </strong>
        </DropdownMenuCheckboxItem>
        {table.getAllLeafColumns().map(column => {
          if ([EXPANDING_COLUMN_ID, SELECT_COLUMN_ID].includes(column.id)) {
            return null;
          }

          const title = column.columnDef?.meta?.columnVisibilityTitle
            || (column.id === ACTIONS_COLUMN_ID ? 'Actions' : null)
            || column.columnDef.header;

          return (
            <DropdownMenuCheckboxItem
              key={column.id}
              checked={column.getIsVisible()}
              onCheckedChange={column.toggleVisibility}
              onSelect={preventClose}
              className={styles.item}
            >
              <>
                {title}
              </>
            </DropdownMenuCheckboxItem>
          );
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
