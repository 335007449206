import type {
  ColumnFiltersState,
  OnChangeFn,
  PaginationState,
  RowData,
  RowSelectionState,
  SortingState,
  TableOptions
} from '@tanstack/react-table';
import { debounce } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';

import { PAGE_SIZE_OPTIONS, queryTypes } from '@acadeum/helpers';
import type { SortByState } from '@acadeum/types';

type GlobalFilterState = string;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UseQueryStateHook = any;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UseQueryStatesHook = any;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
// type ClientFiltersState = Record<string, any>;

export interface TableColumnFiltersOptions<TData extends RowData> {
  manualFiltering?: TableOptions<TData>['manualFiltering'];
  enableColumnFilters?: TableOptions<TData>['enableColumnFilters'];
  columnFilters: ColumnFiltersState;
  onColumnFiltersChange: OnChangeFn<ColumnFiltersState>;
}

export interface TableGlobalFilterOptions<TData extends RowData> {
  manualFiltering?: TableOptions<TData>['manualFiltering'];
  enableGlobalFilter?: TableOptions<TData>['enableGlobalFilter'];
  globalFilter: GlobalFilterState;
  onGlobalFilterChange: OnChangeFn<GlobalFilterState>;
}

export interface TablePaginationOptions<TData extends RowData> {
  manualPagination?: TableOptions<TData>['manualPagination'];
  pagination: PaginationState;
  onPaginationChange: OnChangeFn<PaginationState>;
  totalCount: number;
  pageCount?: number;
  pageSizeOptions?: number[];
}

interface PaginationResultOptions<TData extends RowData> {
  pageSizeOptions: number[];
  pagination: PaginationState;
  onPaginationChange: OnChangeFn<PaginationState>;
  manualPagination?: TableOptions<TData>['manualPagination'];
  totalCount?: number,
  pageCount?: number;
}

export interface TableSortingOptions<TData extends RowData> {
  enableSorting?: TableOptions<TData>['enableSorting'];
  manualSorting?: TableOptions<TData>['manualSorting'];
  sorting: SortByState;
  onSortingChange: OnChangeFn<SortByState>;
}

interface SortingOptionsResult<TData extends RowData> {
  enableSorting?: TableOptions<TData>['enableSorting'];
  manualSorting?: TableOptions<TData>['manualSorting'];
  sorting: SortingState;
  onSortingChange: OnChangeFn<SortingState>;
}

export interface TableRowSelectionOptions<TData extends RowData> {
  enableRowSelection?: TableOptions<TData>['enableRowSelection'];
  rowSelection: RowSelectionState;
  onRowSelectionChange: OnChangeFn<RowSelectionState>;
}

export interface UseTableStateOptions<TData extends RowData> {
  sortingOptions?: TableSortingOptions<TData>;
  paginationOptions?: TablePaginationOptions<TData>;
  globalFilterOptions?: TableGlobalFilterOptions<TData>;
  columnFiltersOptions?: TableColumnFiltersOptions<TData>;
  rowSelectionOptions?: TableRowSelectionOptions<TData>;
}

const useGlobalFilter = <TData extends RowData>(
  globalFilterOptions?: TableGlobalFilterOptions<TData>,
  useQueryStateHook?: UseQueryStateHook
): TableGlobalFilterOptions<TData> => {
  if (globalFilterOptions) {
    return {
      manualFiltering: true,
      enableGlobalFilter: true,
      ...globalFilterOptions
    };
  }

  if (useQueryStateHook) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [globalFilter, onGlobalFilterChange] = useQueryStateHook('search', queryTypes.string.withDefault(''));
    return { globalFilter, onGlobalFilterChange };
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [globalFilter, onGlobalFilterChange] = useState('');
  return { globalFilter, onGlobalFilterChange };
};

const usePagination = <TData extends RowData>(
  options?: TablePaginationOptions<TData>,
  useQueryStatesHook?: UseQueryStatesHook
): PaginationResultOptions<TData> => {
  if (options) {
    return {
      manualPagination: true,
      pageSizeOptions: PAGE_SIZE_OPTIONS,
      pageCount: options ? Math.ceil(options.totalCount / options.pagination.pageSize) : -1,
      ...options
    };
  }

  if (useQueryStatesHook) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [paginationState, setPagination] = useQueryStatesHook(
      {
        page: queryTypes.integer,
        pageSize: queryTypes.integer
      },
      {},
      { scroll: false }
    );

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const pagination: PaginationState = useMemo(() => ({
      pageIndex: (paginationState.page ?? 1) - 1,
      pageSize: paginationState.pageSize ?? 10
    }), [
      paginationState.page,
      paginationState.pageSize
    ]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const onPaginationChange: OnChangeFn<PaginationState> = useCallback(debounce(async (updaterOrValue) => {
      const value = typeof updaterOrValue === 'function' ? updaterOrValue(pagination) : updaterOrValue;
      // Update the state only if it has changed to prevent an infinite update
      if (value.pageIndex !== pagination.pageIndex || value.pageSize !== pagination.pageSize) {
        await setPagination({ page: value.pageIndex + 1, pageSize: value.pageSize });
      }
    }, 100), [pagination.pageSize, pagination.pageIndex]);

    return {
      pageSizeOptions: PAGE_SIZE_OPTIONS,
      pagination,
      onPaginationChange
    };
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [pagination, onPaginationChange] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10
  });
  return {
    pageSizeOptions: PAGE_SIZE_OPTIONS,
    pagination,
    onPaginationChange
  };
};

const useSorting = <TData extends RowData>(
  options?: TableSortingOptions<TData>,
  useQueryStateHook?: UseQueryStateHook
): SortingOptionsResult<TData> => {
  if (options) {
    const sorting = formatSortByToTableStandardFromApiStandard(options.sorting);
    return {
      manualSorting: true,
      enableSorting: true,
      ...options,
      sorting,
      onSortingChange: (updaterOrValue) => {
        const value = typeof updaterOrValue === 'function' ? updaterOrValue(sorting) : updaterOrValue;
        options.onSortingChange(formatSortByToApiStandardFromTableStandard(value));
      }
    };
  }

  if (useQueryStateHook) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [sort, setSort] = useQueryStateHook('sort', queryTypes.array(queryTypes.json<SortingState[number]>()).withDefault([]));
    return {
      sorting: sort,
      onSortingChange: setSort
    };
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [sort, setSort] = useState<SortingState>([]);

  return {
    sorting: sort,
    onSortingChange: setSort
  };
};

const useColumnFilters = <TData extends RowData>(
  options?: TableColumnFiltersOptions<TData>,
  useQueryStateHook?: UseQueryStateHook
): TableColumnFiltersOptions<TData> => {
  if (options) {
    return {
      manualFiltering: true,
      enableColumnFilters: false,
      ...options
    };
  }

  if (useQueryStateHook) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [columnFilters, onColumnFiltersChange] = useQueryStateHook('columnFilters', queryTypes.array(queryTypes.json<ColumnFiltersState[number]>()).withDefault([]));
    return { columnFilters, onColumnFiltersChange };
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [columnFilters, onColumnFiltersChange] = useState<ColumnFiltersState>([]);
  return { columnFilters, onColumnFiltersChange };
};

const useRowSelection = <TData extends RowData>(
  options?: TableRowSelectionOptions<TData>
): TableRowSelectionOptions<TData> => {
  if (options) {
    return {
      enableRowSelection: true,
      ...options
    };
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [rowSelection, onRowSelectionChange] = useState<RowSelectionState>({});
  return { rowSelection, onRowSelectionChange };
};

export const useTableState = <TData extends RowData>(
  {
    sortingOptions,
    paginationOptions,
    globalFilterOptions,
    columnFiltersOptions,
    rowSelectionOptions
  }: UseTableStateOptions<TData>,
  useQueryStatesHook?: UseQueryStatesHook,
  useQueryStateHook?: UseQueryStateHook
) => {
  const paginationParams = usePagination(paginationOptions, useQueryStatesHook);

  const globalFilterParams = useGlobalFilter(globalFilterOptions, useQueryStateHook);
  const columnFiltersParams = useColumnFilters(columnFiltersOptions, useQueryStateHook);
  const sortingParams = useSorting(sortingOptions, useQueryStateHook);

  const rowSelectionParams = useRowSelection(rowSelectionOptions);

  return {
    ...paginationParams,
    ...sortingParams,
    ...globalFilterParams,
    ...columnFiltersParams,
    ...rowSelectionParams
  };
};

function formatSortByToApiStandardFromTableStandard(sortBy?: SortingState): SortByState {
  if (!sortBy) {
    return [];
  }
  return sortBy.map(_ => ({ by: _.id, asc: !_.desc }));
}

function formatSortByToTableStandardFromApiStandard(sortBy?: SortByState): SortingState {
  if (!sortBy) {
    return [];
  }
  return sortBy.map(_ => ({ id: _.by, desc: !_.asc }));
}
