import { useCallback } from 'react';

import getLocationUrl from 'common-lib/lib/getLocationUrl';

import type { UseOnError } from '@acadeum/types';

import actions from '../actions';

import useLocation from './useLocation';

const { goto } = actions;

interface ErrorPageQuery {
  url: string;
  status?: string;
}

const useOnError: UseOnError = () => {
  const location = useLocation();

  return useCallback((error) => {
    console.error('An error happened. Redirect to an error page.');
    console.error(error);

    if (process.env.NODE_ENV === 'development') {
      throw error;
    }

    const query: ErrorPageQuery = {
      url: getLocationUrl(location)
    };

    if (error && error['status']) {
      query.status = error['status'];
    }

    void goto(getLocationUrl({
      pathname: '/error',
      query
    }));

    return null;
  }, []);
};

export default useOnError;
