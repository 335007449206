import classNames from 'classnames';
import type { FC } from 'react';
import React from 'react';

import {
  Pagination as CorePagination,
  PaginationContent as CorePaginationContent,
  PaginationEllipsis as CorePaginationEllipsis,
  PaginationItem as CorePaginationItem,
  PaginationLink as CorePaginationLink,
  PaginationNext as CorePaginationNext,
  PaginationPrevious as CorePaginationPrevious
} from '@acadeum/core-ui';
import { CaretLeftIcon, CaretRightIcon } from '@acadeum/icons';

import styles from './Pagination.module.scss';

type PaginationState = {
  currentPage: number;
  totalCount: number;
  pageSize: number;
  pageCount?: never;
} | {
  currentPage: number;
  pageCount: number;
  totalCount?: never;
  pageSize?: never;
};

export interface PaginationProps {
  state: PaginationState;
  onPageChange: (page: number) => void;
  renderLink?: (page: number) => string;
}

export const Pagination: FC<PaginationProps> = ({
  state,
  onPageChange,
  renderLink: renderLink_
}) => {
  const maxPageLinks = 4;

  const { currentPage, totalCount, pageSize, pageCount } = state;

  const totalPages = typeof pageCount === 'number' ? pageCount : Math.ceil(totalCount / pageSize);

  const showBeforePage = Math.max(Math.floor(currentPage - maxPageLinks / 2), 1);
  const showAfterPage = Math.min(showBeforePage + maxPageLinks - 1, totalPages);

  const shownPages: number[] = [];
  for (let i = showBeforePage; i <= showAfterPage; i++) {
    shownPages.push(i);
  }

  const onClick = (pageNumber) => (event) => {
    event.preventDefault();
    if (onPageChange) {
      onPageChange(pageNumber);
    }
  };

  const renderLink = (page) => {
    if (renderLink_) {
      return renderLink_(page);
    }
    return '#';
  };

  return (
    <CorePagination>
      <CorePaginationContent className={styles.list}>
        <CorePaginationItem>
          <CorePaginationPrevious
            className={classNames(styles.link, styles.prev)}
            disabled={currentPage === 1}
            href={renderLink(currentPage - 1)}
            onClick={currentPage === 1 ? undefined : onClick(currentPage - 1)}
          >
            <CaretLeftIcon className="control-icon"/>
          </CorePaginationPrevious>
        </CorePaginationItem>

        {showBeforePage > 1 && (
          <CorePaginationItem>
            <CorePaginationLink
              className={styles.link}
              href={renderLink(1)}
              onClick={onClick(1)}>
              1
            </CorePaginationLink>
          </CorePaginationItem>
        )}
        {showBeforePage > 2 && (
          <CorePaginationItem>
            <CorePaginationEllipsis>
              ...
            </CorePaginationEllipsis>
          </CorePaginationItem>
        )}
        {shownPages.map(pageNumber => (
          <CorePaginationItem key={pageNumber}>
            <CorePaginationLink
              active={pageNumber === currentPage}
              className={styles.link}
              href={renderLink(pageNumber)}
              onClick={onClick(pageNumber)}
            >
              {pageNumber}
            </CorePaginationLink>
          </CorePaginationItem>
        ))}
        {showAfterPage < totalPages - 1 && (
          <CorePaginationItem>
            <CorePaginationEllipsis>
              ...
            </CorePaginationEllipsis>
          </CorePaginationItem>
        )}
        {showAfterPage < totalPages && (
          <CorePaginationItem>
            <CorePaginationLink
              className={styles.link}
              href={renderLink(totalPages)}
              onClick={onClick(totalPages)}
            >
              {totalPages}
            </CorePaginationLink>
          </CorePaginationItem>
        )}

        <CorePaginationItem>
          <CorePaginationNext
            className={classNames(styles.link, styles.next)}
            disabled={currentPage === totalPages}
            href={renderLink(currentPage + 1)}
            onClick={currentPage === totalPages ? undefined : onClick(currentPage + 1)}
          >
            <CaretRightIcon className="control-icon"/>
          </CorePaginationNext>
        </CorePaginationItem>
      </CorePaginationContent>
    </CorePagination>
  );
};
