import type { RtkApi } from '@acadeum/redux';
import type { CoursePricing } from '@acadeum/types';

export type SetHomeStudentCourseEnrollmentPricingInput = CoursePricing | void;
export type FetchHomeStudentCourseEnrollmentPricingOutput = CoursePricing;

export const injectCoursePricingEndpoints = (rtkApi : RtkApi ) =>  rtkApi
  .enhanceEndpoints({ addTagTypes: ['homeStudentCourseEnrollmentPricing'] })
  .injectEndpoints({
    endpoints: build =>({
      fetchHomeStudentCourseEnrollmentPricing: build.query<FetchHomeStudentCourseEnrollmentPricingOutput, void>({
        query: () => '/enrolling-student-course-pricing',
        providesTags: ['homeStudentCourseEnrollmentPricing']
      }),
      setHomeStudentCourseEnrollmentPricing: build.mutation<void, SetHomeStudentCourseEnrollmentPricingInput>({
        query: body => ({
          url: '/enrolling-student-course-pricing',
          method: 'PUT',
          body
        }),
        invalidatesTags: ['homeStudentCourseEnrollmentPricing']
      })
    })
  });
