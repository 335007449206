import type { RowData, TableOptions } from '@tanstack/react-table';
import { cloneDeep } from 'lodash-es';
import React, { useMemo } from 'react';


import type { CheckboxProps } from '../../Checkbox';
import { Checkbox } from '../../Checkbox';

import { ExpandButton } from '../ui/ExpandButton';
import { MultiSelectDropdown } from '../ui/MultiSelectDropdown';

import { EXPANDING_COLUMN_ID, SELECT_COLUMN_ID } from '../utils/consts';

export type RowSelectionType = CheckboxProps['type'];

interface UsePreparedColumnsOptions<TData> {
  columns: TableOptions<TData>['columns'];
  enableExpanding?: TableOptions<TData>['enableExpanding'];
  enableRowSelection?: TableOptions<TData>['enableRowSelection'];
  canSelectAll: boolean;
  rowSelectionType?: RowSelectionType;
  selectedRowsCount: number;
  totalCount: number;
}

export const usePreparedColumnsAndSelectionStateStore = <TData extends RowData>({
  enableRowSelection,
  enableExpanding,
  columns,
  canSelectAll,
  rowSelectionType,
  selectedRowsCount,
  totalCount
}: UsePreparedColumnsOptions<TData>) => {
  return useMemo(() => {
    const cols = cloneDeep(columns);

    if (enableRowSelection) {
      cols.unshift({
        id: SELECT_COLUMN_ID,
        size: 55,
        header: ({ table }) => (
          <MultiSelectDropdown
            table={table}
            canSelectAll={canSelectAll}
            rowSelectionType={rowSelectionType}
            selectedRowsCount={selectedRowsCount}
            totalCount={totalCount}
          />
        ),
        cell: ({ row }) => {
          if (!row.getCanSelect()) {
            return null;
          }

          return (
            <Checkbox
              type={rowSelectionType}
              checked={row.getIsSelected()}
              disabled={!row.getCanSelect()}
              indeterminate={row.getIsSomeSelected()}
              onChange={row.getToggleSelectedHandler()}
            />
          );
        }
      });
    }

    if (enableExpanding) {
      cols.unshift({
        id: EXPANDING_COLUMN_ID,
        size: 40,
        header: ({ table }) => (
          <ExpandButton
            onClick={table.getToggleAllRowsExpandedHandler()}
            isExpanded={table.getIsAllRowsExpanded()}
          />
        ),
        cell: ({ row }) => {
          if (!row.getCanExpand()) {
            return <></>;
          }
          return (
            <ExpandButton
              onClick={row.getToggleExpandedHandler()}
              isExpanded={row.getIsExpanded()}
            />
          );
        }

      });
    }

    return cols.map(column => {
      // `id` building is not consistent when using `accessorKey`.
      // Therefore, each column must have an explicit id
      // https://github.com/TanStack/table/issues/4754
      if (!column.id) {
        if (!column['accessorKey']) {
          throw new Error('You must specify an id, or use an accessorKey on the column');
        }
        column.id = column['accessorKey'];
      }
      return column;
    });
  }, [
    columns,
    canSelectAll,
    enableRowSelection,
    selectedRowsCount,
    totalCount
  ]);
};
