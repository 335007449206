import type { FC } from 'react';
import React, { useState } from 'react';

import type {
  FetchBackupPaymentSourceOutput,
  FetchFinancialSettingsStatusOutput,
  FetchPrimaryPaymentSourceOutput
} from '@acadeum/api';
import { useTranslate } from '@acadeum/translate';
import { Button, ConfirmActionModal, FormFooter } from '@acadeum/ui';


import { FINANCIAL_SCREEN, useFinancialNavigationContext } from '../../context';
import { getCreditCardStatuses } from '../../lib/getCreditCardStatuses';

import type { CreditCardFormProps } from '../CreditCardForm';
import { CreditCardForm } from '../CreditCardForm';
import { PaymentCard } from '../PaymentCard';
import { StepWrapper } from '../StepWrapper';

export interface CreditCardStepProps extends Pick<CreditCardFormProps, 'useUpdateBackupPaymentSourceMutation' | 'useCreateBackupPaymentSourceMutation'> {
  financialSettingsStatus: FetchFinancialSettingsStatusOutput;
  backupPaymentSource?: FetchBackupPaymentSourceOutput;
  primaryPaymentSource?: FetchPrimaryPaymentSourceOutput;
}

export const CreditCardStep: FC<CreditCardStepProps> = ({
  useUpdateBackupPaymentSourceMutation,
  useCreateBackupPaymentSourceMutation,
  financialSettingsStatus,
  primaryPaymentSource,
  backupPaymentSource
}) => {
  const { changeView } = useFinancialNavigationContext();
  const t = useTranslate('shared-admin-ui.CreditCardStep');

  const paymentMethod = financialSettingsStatus?.paymentMethod;

  const [showCancelAddCardModal, setShowCancelAddCardModal] = useState(false);
  const [showAddCardForm, setShowAddCardForm] = useState(paymentMethod === 'CARD');

  const title = paymentMethod === 'CARD' ? t('creditCard') : t('title');
  const description = paymentMethod === 'CARD' ? t('descriptionCCIsDefault') : t('description');

  const creditCardExists = Boolean(backupPaymentSource);
  const paymentMethodsExists = Boolean(creditCardExists || primaryPaymentSource);

  const onShowForm = () => {
    setShowAddCardForm(true);
  };

  const onSkipAddCard = () => {
    changeView({
      screen: paymentMethodsExists ? FINANCIAL_SCREEN.DASHBOARD : FINANCIAL_SCREEN.FINANCIAL_START
    });
  };

  const onCancelAddCreditCard = () => {
    if (creditCardExists) {
      onSkipAddCard();
    } else {
      setShowCancelAddCardModal(true);
    }
  };

  const goToDashboard = () => changeView({ screen: FINANCIAL_SCREEN.DASHBOARD });
  const onBack = () => changeView({ activeStep: 0 });

  return (
    <StepWrapper
      title={title}
      description={creditCardExists && !showAddCardForm ? t('descriptionIfCCExists') : description}
      onBack={paymentMethod === 'ACH' ? onBack : undefined}
    >
      {showAddCardForm ? (
        <CreditCardForm
          useCreateBackupPaymentSourceMutation={useCreateBackupPaymentSourceMutation}
          useUpdateBackupPaymentSourceMutation={useUpdateBackupPaymentSourceMutation}
          onAfterSubmit={goToDashboard}
          onCancel={onCancelAddCreditCard}
          update={creditCardExists}
        />
      ) : (creditCardExists ? (
        <>
          <PaymentCard
            title={t('creditCardInfo.title')}
            description={t('creditCardInfo.description', {
              lastFourDigits: backupPaymentSource?.card.lastFourDigits
            })}
            statuses={getCreditCardStatuses(backupPaymentSource, primaryPaymentSource)}
          />
          <FormFooter marginTop="medium">
            <FormFooter>
              <Button
                variant="secondary"
                onClick={onShowForm}
              >
                {t('replace')}
              </Button>
              <Button onClick={onSkipAddCard}>
                {t('keep')}
              </Button>
            </FormFooter>
          </FormFooter>
        </>
      ) : (
        <FormFooter marginTop="medium">
          <FormFooter>
            <Button
              variant="secondary"
              onClick={onSkipAddCard}
            >
              {t('skip')}
            </Button>
            <Button onClick={onShowForm}>
              {t('addNow')}
            </Button>
          </FormFooter>
        </FormFooter>
      ))}


      <ConfirmActionModal
        danger
        show={showCancelAddCardModal}
        title={t('cancelSetupModal.title')}
        description={t('cancelSetupModal.description', {
          danger: text => <><br/> <span className="danger">{text}</span></>
        })}
        submitText={t('cancel', { global: true })}
        cancelText={t('cancelSetupModal.continue')}
        onHide={() => setShowCancelAddCardModal(false)}
        onCancel={() => setShowCancelAddCardModal(false)}
        onSubmit={onSkipAddCard}
      />
    </StepWrapper>
  );
};
