import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { userHasPermission } from '@acadeum/helpers';
import { PlusIcon } from '@acadeum/icons';
import { useTranslate } from '@acadeum/translate';
import { Button, Title, Tabs } from '@acadeum/ui';
import type { TabsProps } from '@acadeum/ui';

import actions from '../../actions';
import type { ReduxState } from '../../helpers/app.types';
import { loadHomeStudentsPage } from '../../helpers/routes';

import HomeStudents from './ui/HomeStudents';
import TeachingStudents from './ui/TeachingStudents';


const { fetchStudentsNoRace, getStudentsFiltersValues, goto } = actions;

interface StudentsProps {
  type?: 'EM' | 'TM';
}

import styles from './Students.module.scss';

const Students = ({ type = 'EM' }: StudentsProps) => {
  const t = useTranslate('Students');

  const user = useSelector((state: ReduxState) => state.auth.user);

  const tabs: TabsProps['tabs'] = useMemo(() => {
    const tabs: TabsProps['tabs'] = [];

    if (userHasPermission(user, 'homeStudent:read', {
      orgId: user.institution.id
    })) {
      tabs.push({
        title: t('enrollingStudents'),
        key: 'EM',
        body: <HomeStudents />
      });
    }

    if (userHasPermission(user, 'teachingStudent:read', {
      orgId: user.institution.id
    })) {
      tabs.push({
        title: t('teachingStudents'),
        key: 'TM',
        body: <TeachingStudents />
      });
    }

    return tabs;
  }, [
    user
  ]);

  return (
    <>
      <div className={styles.header}>
        <Title marginBottom="none">
          {t('title')}
        </Title>
        {type === 'EM' && (
          <Button icon={PlusIcon} variant="secondary" url="/students/add">
            {t('btnCreateStudent')}
          </Button>
        )}
      </div>
      <Tabs
        keepMounted={false}
        title="students"
        initialActiveTab={type}
        onChangeTab={({ key }) => goto(`/students/${key === 'TM' ? 'teaching' : ''}`)}
        tabs={tabs}
      />
    </>
  );
};

Students.propTypes = {
  students: PropTypes.arrayOf(PropTypes.object),
  allStudents: PropTypes.arrayOf(PropTypes.object),
  studentsNoRace: PropTypes.arrayOf(PropTypes.object),
  location: PropTypes.object
};

Students.meta = () => ({
  title: 'Enrolling Students'
});

Students.load = async ({ location, user }) => {
  if (location.query.raceAndEthnicityNotSpecified) {
    await fetchStudentsNoRace();
  }
  await loadHomeStudentsPage({ user });
  await getStudentsFiltersValues(['level', 'major', 'advisorName']);
};

Students.breadcrumbs = () => ['Students'];

export default Students;
