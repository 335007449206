import { injectAuthenticationSettingsEndpoints } from '@acadeum/api';

import { rtkApi } from './rtkApi';


const authenticationSettingsApi = injectAuthenticationSettingsEndpoints(rtkApi);

export const {
  useFetchSingleSignOnConfigQuery,
  useResetSingleSignOnConfigMutation
} = authenticationSettingsApi;
