import type { FC, ReactNode } from 'react';
import React from 'react';

import { CircleExclamationSolidIcon, CircleInfoIcon } from '@acadeum/icons';

import { BaseTooltip, BaseTooltipContent, BaseTooltipTrigger } from '../BaseTooltip';

import styles from './InfoTooltip.module.scss';

export interface InfoTooltipProps {
  children?: ReactNode;
  variant?: 'info' | 'error';
}

export const InfoTooltip: FC<InfoTooltipProps> = ({
  variant,
  children
}) => {
  const Icon = variant === 'error' ? CircleExclamationSolidIcon : CircleInfoIcon;
  return (
    <BaseTooltip>
      <BaseTooltipTrigger asChild>
        <span className={styles.trigger}>
          <Icon className={styles.icon}/>
        </span>
      </BaseTooltipTrigger>
      <BaseTooltipContent>
        {children}
      </BaseTooltipContent>
    </BaseTooltip>
  );
};

InfoTooltip.displayName = 'InfoTooltip';
