import type { RtkApi } from '@acadeum/redux';

import { ACTION_REQUIRED_COUNTS_ENDPOINTS_TAG_TYPES } from '../consts';

export interface GetActionRequiredCountsOutput {
  newEnrollmentRequestsCount: number;
  newStudentRequestsCount: number;
  awaitingGradesCount: number;
  studentsNoRaceCount: number;
}

export const injectActionRequiredCountsEndpoints = (rtkApi: RtkApi) =>
  rtkApi
    .enhanceEndpoints({ addTagTypes: ACTION_REQUIRED_COUNTS_ENDPOINTS_TAG_TYPES })
    .injectEndpoints({
      endpoints: build => ({
        getActionRequiredCounts: build.query<GetActionRequiredCountsOutput, null>({
          query: () => '/counts/action-required',
          providesTags: [{ type: 'actionRequiredCounts', id: 'COUNTS' }]
        })
      })
    });
