import classNames from 'classnames';
import PropTypes from 'prop-types';

import { useLayoutCell } from '../hooks';

import styles from '../Layout.module.scss';

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  span: PropTypes.number,
  rows: PropTypes.number
};

export const Cell = ({
  children,
  className,
  span,
  rows = 1
}) => {
  const { cols } = useLayoutCell();
  const style = {
    gridArea: `span ${rows} / span ${typeof span === 'number' ? span : cols} / auto / auto`
  };
  return (
    <div
      style={style}
      className={classNames(className, styles.Cell)}
    >
      {children}
    </div>
  );
};

Cell.propTypes = propTypes;
