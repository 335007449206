import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';

import { useSettingsRoutes } from '@acadeum/shared-admin-ui';
import { DataBlock, Text } from '@acadeum/ui';

import actions from '../../actions';
import AdvisorRecommendationSections from '../../components/AdvisorRecommendationSections';
import Button from '../../components/Button';
import Output from '../../components/Output';
import Row from '../../components/Row';
import Section from '../../components/Section';

import Subsection from '../../components/Subsection';
import TextInputModal from '../../components/TextInputModal';

import checkUserPermissions from '../../helpers/checkUserPermissions';
import { formatName } from '../../helpers/format';
import getInstitutionLink from '../../helpers/getInstitutionLink';

const {
  fetchAdvisorRecommendation,
  removeAdvisorRecommendation,
  notify,
  goto
} = actions;

export default function AdvisorRecommendation() {
  const recommendation = useSelector(state => state.advisorRecommendations.recommendation);

  const { getUserDetailsUrl } = useSettingsRoutes();

  const [showRemoveRecommendationModal, setShowRemoveRecommendationModal] = useState();

  const onConfirmRemoveRecommendation = async ({ notes }) => {
    await removeAdvisorRecommendation(recommendation.id, { notes });
    notify('The Advisor Recommendation has been removed');
    goto('/advisor-recommendations');
  };

  const {
    course,
    sections,
    student,
    studentUser,
    registrationRequestId
  } = recommendation;

  return (
    <Fragment>
      <Section
        className="recommendation recommendation__details"
        title={`Advisor Recommendation Details — ${recommendation.status.replaceAll('_', ' ')}`}>

        <Subsection
          title="Submitted By"
          marginBottom="large"
        >
          <Row>
            <DataBlock
              type="user"
              showUserPicture
              user={recommendation.advisor}
              email={recommendation.advisor.email}
              url={getUserDetailsUrl(recommendation.advisor.id)}
            />
          </Row>
        </Subsection>

        <Subsection
          title="Course Overview"
          marginBottom="large">
          <Row>
            <Output
              label="Teaching Member Institution"
              linkTo={getInstitutionLink(course.institution)}
              value={course.institution.name}
              col={4}
            />
            <Output
              label="Course"
              linkTo={`/courses/${course.id}`}
              value={`${course.code} ${course.title}`}
              col={8}
            />
          </Row>
        </Subsection>

        <Subsection
          title="Sections Overview"
          marginBottom="large">
          <AdvisorRecommendationSections
            sections={sections}
            onDemand={course.onDemand}
            onDemandCourseDropDateDaysAfterStartDate={course.institution.onDemandCourseDropDateDaysAfterStartDate}
            onDemandCourseEndDateDaysAfterStartDate={course.institution.onDemandCourseEndDateDaysAfterStartDate}
            disabled
          />
        </Subsection>

        <Subsection
          title="Student Overview"
          marginBottom="large">
          <Row>
            <Output
              label="Name"
              linkTo={student ? `/students/${student.id}` : undefined}
              value={student ? formatName(student) : formatName(studentUser)}
              col={3}
            />
            <Output
              label="Student ID"
              value={(student ? student.hiStudentId : studentUser.hiStudentId) || '—'}
              col={3}
            />
            <Output
              label="Student Email"
              type="email"
              value={student ? student.email : studentUser.email}
              col={6}
            />
          </Row>
        </Subsection>

        <Subsection title="Recommendation Note">
          <Text split>
            {recommendation.notes}
          </Text>
        </Subsection>

        <div style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '2.5rem'
        }}>
          {recommendation.status === 'SENT' &&
            <Button
              onClick={() => setShowRemoveRecommendationModal(true)}>
              Remove
            </Button>
          }
          {recommendation.status === 'REGISTRATION_REQUESTED' &&
            <Button
              link={`/course-registration-requests/${registrationRequestId}`}>
              See Registration Requested
            </Button>
          }
        </div>
      </Section>

      <TextInputModal
        title="Removing Recommendation"
        description="Please provide your rationale for removing this advisor recommendation. This will be emailed to the student notifying them that the advisor recommendation has been removed."
        submitText="Remove"
        onSubmit={onConfirmRemoveRecommendation}
        show={showRemoveRecommendationModal}
        onHide={() => setShowRemoveRecommendationModal(false)}
      />
    </Fragment>
  );
}

AdvisorRecommendation.propTypes = {
  recommendation: PropTypes.object
};

AdvisorRecommendation.meta = () => ({
  title: 'Advisor Recommendation'
});

AdvisorRecommendation.load = async ({ user, params: { id } }) => {
  checkUserPermissions(user, 'homeCourseAdvisorRecommendation:read', {
    orgId: user.institution.id
  });
  await fetchAdvisorRecommendation(id);
};

AdvisorRecommendation.breadcrumbs = () => [
  ['Advisor Recommendations', '/advisor-recommendations'],
  'Details'
];
