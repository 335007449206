import classNames from 'classnames';
import React, { useMemo, useRef } from 'react';

import {
  __ACCOUNT_CENTER_URL__,
  __ADMIN_CENTER_URL__,
  __COURSESHARE_URL__,
  __MARKETPLACE_ADMIN_URL__
} from '@acadeum/consts';
import { isAcadeumAdministrator, userHasPermission } from '@acadeum/helpers';
import type { UserProfile } from '@acadeum/types';
import {
  BaseButton,
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Link,
  OnFocusOutOrTapOutside,
  Separator
} from '@acadeum/ui';

import styles from './BentoBox.module.scss';
import BentoIcon from './BentoIcon.svg';
import CheckmarkIcon from './Checkmark.svg';

import { useFade } from './hooks';


interface Action {
  onClick?: () => void;
  label: string;
}

export interface BentoBoxProps {
  actions?: Action[];
  user?: UserProfile;
}

export const BentoBox: React.FC<BentoBoxProps> & {
  Mobile: typeof BentoBoxMobile
} = ({ actions, user }) => {
  const menu = useMenu(user);

  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger
        className={styles.BentoBox__toggle}
        title="Open Acadeum applications menu"
        aria-label="Acadeum applications"
      >
        <BentoIcon focusable="false" aria-hidden="true"/>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="end"
        sideOffset={4}
      >
        {menu.map((item, index) => (
          <DropdownMenuItem
            asChild
            key={index}
            disabled={item.disabled || item.selected}
            className={classNames(styles.BentoBox__menuItem, {
              [styles['BentoBox__menuItem--active']]: item.selected
            })}
          >
            <Link
              useHtmlLink
              to={item.url}
            >
              {item.selected && <CheckmarkIcon />}
              {item.selected && ' '}
              {item.label}
            </Link>
          </DropdownMenuItem>
        ))}
        {Array.isArray(actions) && (
          <>
            {actions.length > 0 && (
              <DropdownMenuSeparator className={styles.BentoBox__divider}/>
            )}
            {actions.map((item, index) => (
              <DropdownMenuItem
                key={index}
                onClick={item.onClick}
                className={styles.BentoBox__actionsItem}
              >
                {item.label}
              </DropdownMenuItem>
            ))}
          </>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export type BentoBoxMobileProps = BentoBoxProps

const BentoBoxMobile: React.FC<BentoBoxMobileProps> = ({ actions, user }) => {
  const [show, setShow, animationClass, onAnimationEnd] = useFade(false);

  const buttonRef = useRef() as React.MutableRefObject<HTMLButtonElement>;
  const menuRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const onTapOutside = () => {
    if (show) {
      setShow(false);
    }
  };

  const onClick = () => {
    setShow(!show);
  };

  return (
    <>
      <Button
        size="small"
        title="Open Acadeum applications menu"
        aria-label="Acadeum applications"
        variant="text-with-padding"
        ref={buttonRef}
        onClick={onClick}
        className={styles.BentoBoxMobile__toggle}
      />
      {show && (
        <div onAnimationEnd={onAnimationEnd} className={classNames(styles.BentoBoxMobile__overlay, animationClass)}>
          <OnFocusOutOrTapOutside
            toggleRef={buttonRef}
            containerRef={menuRef}
            onFocusOut={onTapOutside}
            onTapOutside={onTapOutside}
          >
            <BentoBoxMobileMenu
              ref={menuRef}
              actions={actions}
              user={user}
            />
          </OnFocusOutOrTapOutside>
        </div>
      )}
    </>
  );
};
BentoBox.Mobile = BentoBoxMobile;

type BentoBoxMobileMenuProps = BentoBoxProps

const BentoBoxMobileMenu = React.forwardRef<HTMLDivElement, BentoBoxMobileMenuProps>(({
  actions,
  user,
  ...rest
}, ref) => {
  const menu = useMenu(user);

  return (
    <div
      ref={ref}
      {...rest}
      className={styles.BentoBoxMobile__menu}
    >
      {menu.map((item, index) => (
        <Link
          useHtmlLink
          key={index}
          to={item.url}
          disabled={item.selected || item.disabled}
          className={classNames(styles.BentoBox__menuItem, {
            [styles['BentoBox__menuItem--active']]: item.selected
          })}
        >
          {item.selected && <CheckmarkIcon />}
          {item.selected && ' '}
          {item.label}
        </Link>
      ))}
      {Array.isArray(actions) && actions.length > 0 && (
        <>
          {actions.length > 0 && (
            <Separator className={styles.BentoBox__divider} />
          )}
          {actions.map((item, index) => (
            <BaseButton
              key={index}
              onClick={item.onClick}
              className={styles.BentoBox__actionsItem}
            >
              {item.label}
            </BaseButton>
          ))}
        </>
      )}
    </div>
  );
});

interface MenuItem {
  url: string;
  label: string;
  selected?: boolean;
  disabled?: boolean;
}

function useMenu(user?: UserProfile): MenuItem[] {
  return useMemo(() => {
    if (typeof window === 'undefined') {
      return [];
    }

    const { origin } = window.location;

    const showMarketplaceMenuItem = isAcadeumAdministrator(user)
      || (user?.institution.useMarketplace && userHasPermission(user, 'marketplace:read', { orgId: user.institution.id }));

    return [
      {
        url: __COURSESHARE_URL__,
        label: 'Course Share',
        selected: origin === __COURSESHARE_URL__
      },
      {
        url: __ACCOUNT_CENTER_URL__,
        label: 'Account Center',
        selected: origin === __ACCOUNT_CENTER_URL__
      },
      {
        url: __ADMIN_CENTER_URL__,
        label: 'Admin Center',
        selected: origin === __ADMIN_CENTER_URL__
      },
      ...(showMarketplaceMenuItem ? [
        {
          url: __MARKETPLACE_ADMIN_URL__,
          label: 'Marketplace',
          selected: origin === __MARKETPLACE_ADMIN_URL__
        }
      ] : [])
    ];
  }, [user]);
}
