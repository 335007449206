import React from 'react';
import { useSelector } from 'react-redux';

import actions from '../../actions';

import { CreateUser } from './CreateUser_';


const {
  fetchUserRoles
} = actions;

export default function CreateUserPage() {
  const { userRoles } = useSelector(state => state.users);
  const { user } = useSelector(state => state.auth);

  return (
    <CreateUser
      adminPanel
      userRoles={userRoles}
      currentUser={user}
    />
  );
}

CreateUserPage.load = async () => {
  await fetchUserRoles();
};

CreateUserPage.meta = () => ({
  title: 'Create User'
});

CreateUserPage.breadcrumbs = () => [
  ['Admin Tools', '/admin'],
  'Create User'
];
