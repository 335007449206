import Enrollments from '../Enrollments';

const EnrollmentsLive = () => {
  return (
    <Enrollments mode="homeInstitution" status="live"/>
  );
};

EnrollmentsLive.meta = () => ({
  title: 'Live Enrollments'
});

EnrollmentsLive.breadcrumbs = () => [
  ['Manage Enrollments', '/enrollments'],
  'Live'
];
export default EnrollmentsLive;
