import React, { memo } from 'react';

import { EMPTY_SCHEDULE_TEXT } from '@acadeum/helpers';

import { Col } from '../../../Col';
import { Row } from '../../../Row';

import styles from '../../SectionCard.module.scss';
import { SectionContent } from '../SectionContent';


export interface ScheduleRowProps {
  schedule?: string;
}

export const ScheduleRow = memo<ScheduleRowProps>(({
  schedule
}) => {
  return (
    <Row as="dl" className={styles.row}>
      <Col>
        <SectionContent
          label="Schedule"
          value={schedule || EMPTY_SCHEDULE_TEXT}
        />
      </Col>
    </Row>
  );
});
