import React, { memo } from 'react';

import { Button } from '../../../Button';

import { FormFooter } from '../../../FormFooter';
import { CourseSectionApprovedAndScheduledStatus } from '../CourseSectionApprovedAndScheduledStatus';

export interface SectionCardFooterProps {
  showApprovedIcon?: boolean;
  isScheduled?: boolean;
  isStartingTooLateToRegister?: boolean;
  recommendProps?: {
    onRecommend?: () => void;
    url?: string;
  };
  isAvalableForEnrollment?: boolean;
  onDemand?: boolean;
  enrollProps?: {
    onEnroll?: () => void;
    url?: string;
  },
  courseUrl?: string;
  editScheduleUrl?: string;
  onResetSchedule?: () => void;
}

export const SectionCardFooter = memo<SectionCardFooterProps>(({
  showApprovedIcon,
  isScheduled,
  isStartingTooLateToRegister,
  recommendProps,
  enrollProps,
  courseUrl,
  editScheduleUrl,
  onResetSchedule,
  isAvalableForEnrollment,
  onDemand
}) => {
  return (
    <FormFooter>
      {/* "Scheduled" or "Not Scheduled" status. */}
      {/* Is irrelevant for on-demand courses because those don't have a concept of a "schedule". */}
      {showApprovedIcon && !onDemand && (
        <CourseSectionApprovedAndScheduledStatus
          isScheduled={Boolean(isScheduled)}
          isStartingTooLateToRegister={isStartingTooLateToRegister}
          editScheduleUrl={editScheduleUrl}
          onResetSchedule={onResetSchedule}
        />
      )}

      {/* "Recommend" button */}
      {recommendProps && (
        <Button
          variant="secondary"
          onClick={recommendProps.onRecommend}
          url={recommendProps.url}
        >
          Recommend
        </Button>
      )}

      {/* "Enroll" button */}
      {isAvalableForEnrollment && enrollProps && (
        <Button
          onClick={enrollProps.onEnroll}
          url={enrollProps.url}
        >
          Enroll
        </Button>
      )}

      {/* "See Available Sections" button. */}
      {/* Navigates to the course page where it lists any other available sections. */}
      {/* On-Demand courses are supposed to only have a single section,
          so there's no point in showing "See Available Sections" button
          because there're no other sections of the course. */}
      {!isAvalableForEnrollment && !onDemand && courseUrl && (
        <Button url={courseUrl}>
          See Available Sections
        </Button>
      )}
    </FormFooter>
  );
});
