import classNames from 'classnames';
import React, { memo } from 'react';

import formatName from 'common-lib/lib/formatName';

import type { Student } from '@acadeum/types';

import { useGetStudentLink } from '../../../../utils/studentLink';

import { Link } from '../../../Link';

import styles from './StudentBlock.module.scss';

export interface StudentBlockProps {
  student: Pick<Student, 'id' | 'firstName' | 'middleName' | 'lastName' | 'hiStudentId'>;
  teaching?: boolean;
  url?: false | string;
  showId?: boolean;
  withLink?: boolean;
}

export const StudentBlock: React.FC<StudentBlockProps> = memo(({
  student,
  url: propsUrl,
  teaching,
  showId = true,
  withLink = true
}) => {
  const getStudentLink = useGetStudentLink();
  const url = withLink && (propsUrl || getStudentLink?.(student, { teaching }));

  return (
    <div className={classNames(styles.StudentBlock)}>
      {url ? (
        <Link external to={url}>{formatName(student)}</Link>
      ) : (
        <span>{formatName(student)}</span>
      )}
      {showId && student.hiStudentId && (
        <span className={styles.id}>
          ID: {student.hiStudentId}
        </span>
      )}
    </div>
  );
});
