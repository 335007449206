import { capitalize } from 'lodash-es';
import type { FC } from 'react';
import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import formatName from 'common-lib/lib/formatName';


import { getAuthSelector } from '@acadeum/auth';
import { isHttpError, userHasPermission } from '@acadeum/helpers';
import { BriefcaseIcon, EnvelopeIcon, PhoneIcon, ProfileIcon } from '@acadeum/icons';
import { useTranslate } from '@acadeum/translate';
import type { ModalProps, OnSubmit } from '@acadeum/ui';
import {
  Blank,
  Card,
  CircularProgress,
  Col,
  DataBlock,
  Email,
  FormField,
  FormModal,
  FormRow,
  HStack,
  Icon,
  Link,
  Phone,
  Row,
  Separator,
  ShowMoreButton,
  Text,
  toast,
  VStack
} from '@acadeum/ui';

import {
  useFetchCourseEnrollmentQuery,
  useUpdateTeachingCourseEnrollmentGradeMutation
} from '../../../../api/courseEnrollment';
import getInstitutionLink from '../../../../helpers/getInstitutionLink';

import type { CourseEnrollmentMode } from '../../types';

export interface CourseEnrollmentGradeModalProps extends Pick<ModalProps, 'onHide' | 'show'> {
  enrollmentId: number;
  downloadRow: () => Promise<void>;
  mode: CourseEnrollmentMode;
}

interface FormValues {
  letterGrade: string;
  numericalGrade?: number;
  gradeNotes?: string;
}

export const CourseEnrollmentGradeModal: FC<CourseEnrollmentGradeModalProps> = ({
  onHide,
  show,
  downloadRow,
  enrollmentId,
  mode
}) => {
  const t = useTranslate('CourseEnrollmentGradeModal');
  const [updateTeachingCourseEnrollmentGradeMutation] = useUpdateTeachingCourseEnrollmentGradeMutation();

  const user = useSelector(getAuthSelector('user'));
  const canEdit = userHasPermission(user, 'teachingCourseEnrollmentGrade:update', {
    orgId: user?.institution.id,
    ownerId: null
  });

  const [expandedDetails, setExpandedDetails] = useState(false);

  const { data, isLoading } = useFetchCourseEnrollmentQuery({
    id: enrollmentId,
    mode,
    require: ['grade'],
    include: mode === 'homeInstitution'
      ? ['grade', 'teachingInstitutionStudentContact']
      : ['grade']
  }, {
    skip: !show
  });

  const onSubmit: OnSubmit<FormValues> = async (values) => {
    try {
      await updateTeachingCourseEnrollmentGradeMutation({
        ...values,
        id: enrollmentId
      }).unwrap();
      onHide(false);
      toast.success(t('Grade has been updated successfully'));
    } catch (error) {
      if (isHttpError(error)) {
        switch (error.data.message) {
          case 'not_consortial':
            return toast.error('Enrollment is not a consortium type');
          case 'no_grade':
            return toast.error('Missing Letter Grade');
        }
      }
      throw error;
    }
  };

  function renderDetails() {
    if (!data) {
      return null;
    }

    return (
      <>
        <Separator/>
        <VStack gap="md" align="none">
          <Text variant="subtitle2">
            {t('Details')}
          </Text>
          {[
            { title: t('Student'), value: <DataBlock type="student" student={data.student}/> },
            {
              title: t('Home Institution'),
              value: <DataBlock type="institution" hideLogo institution={data.student.institution}/>
            },
            { title: t('Enrollment Type'), value: capitalize(data.type) },
            { title: t('Course'), value: <DataBlock type="course" course={data.section.course}/> },
            { title: t('Course Dates'), value: <DataBlock type="courseSessionDates" session={data.section}/> }
          ].map(({ value, title }) => (
            <Row key={title}>
              <Col col={5}>{title}</Col>
              <Col col={7}>{value || <Blank/>}</Col>
            </Row>
          ))}
        </VStack>
      </>
    );
  }

  return (
    <FormModal
      show={show}
      onHide={onHide}
      title={canEdit && mode === 'teachingInstitution' ? t('Edit Grade Details') : t('Grade Details')}
      hideFooter={!(canEdit && mode === 'teachingInstitution') || !data}
      actions={[{ title: t('Download'), onClick: downloadRow }]}
      onSubmit={onSubmit}
    >
      {isLoading || !data ? (
        <HStack justify="center" style={{ height: '20rem' }}>
          <CircularProgress/>
        </HStack>
      ) : (
        <>
          {mode === 'homeInstitution' ? (
            <VStack gap="md" align="none" mb="sm">
              <HStack justify="between">
                <Text variant="subtitle2">
                  {t('Grade')}
                </Text>
                {data.section.course.institution.gradingScale ? (
                  <Link
                    external
                    suffixIcon="external"
                    to={`${getInstitutionLink(data.section.course.institution)}/?initialActiveTab=gradingScale`}
                  >
                    {t('View Grading Scale')}
                  </Link>
                ) : (
                  <Text color="secondary">
                    {t('No grading scale available')}
                  </Text>
                )}
              </HStack>
              {[
                { title: t('Letter Grade'), value: data.letterGrade },
                { title: t('Numerical Grade'), value: data.numericalGrade },
                { title: t('Note'), value: data.gradeNotes }
              ].map(({ title, value }) => (
                <Row key={title}>
                  <Col col={5}>{title}</Col>
                  <Col col={7}>{value || <Blank/>}</Col>
                </Row>
              ))}
            </VStack>
          ) : (
            <>
              <FormRow>
                <FormField
                  required
                  autoFocus
                  name="letterGrade"
                  label={t('Letter Grade')}
                  defaultValue={data.letterGrade}
                  readOnly={!canEdit}
                />
                <FormField
                  type="number"
                  name="numericalGrade"
                  label={t('Numerical Grade')}
                  defaultValue={data.numericalGrade}
                  readOnly={!canEdit}
                />
              </FormRow>
              <FormField
                multiline
                label={t('Note')}
                name="gradeNotes"
                defaultValue={data.gradeNotes}
                readOnly={!canEdit}
              />
            </>
          )}
          {mode === 'homeInstitution' ? (
            <>
              <ShowMoreButton
                expanded={expandedDetails}
                onClick={setExpandedDetails}
              />
              {expandedDetails && renderDetails()}
            </>
          ) : (
            renderDetails()
          )}
          {mode === 'homeInstitution' && (
            <>
              <Separator/>
              <Card variant="gray">
                <VStack gap="md" align="none">
                  <Text variant="subtitle2">
                    {t('Teaching Student Contact')}
                  </Text>
                  {[
                    { icon: ProfileIcon, title: t('Contact'), value: formatName(data.teachingInstitutionStudentContact) },
                    { icon: BriefcaseIcon, title: t('Title'), value: data.teachingInstitutionStudentContact.title },
                    { icon: EnvelopeIcon, title: t('Email'), value: <Email address={data.teachingInstitutionStudentContact.email}/> },
                    { icon: PhoneIcon, title: t('Phone'), value: <Phone contact={data.teachingInstitutionStudentContact}/> }
                  ].map(({ title, value, icon }) => (
                    <Row key={title}>
                      <Col col={5}>
                        <HStack>
                          <Icon icon={icon} mr="sm" size="md" style={{ color: 'var(--color-gray-400)' }}/>
                          {title}
                        </HStack>
                      </Col>
                      <Col col={7}>{value || <Blank/>}</Col>
                    </Row>
                  ))}
                </VStack>
              </Card>
            </>
          )}
        </>
      )}
    </FormModal>
  );
};
