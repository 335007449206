import React, { useId } from 'react';
import { useWatch } from 'react-hook-form';

import { getLastDropDateMessageForOnDemandCourseEnrollment, getEndDateMessageForOnDemandCourseEnrollment } from '@acadeum/helpers';
import { useTranslate } from '@acadeum/translate';
import { ContentSection, FormFieldWrapper, Input } from '@acadeum/ui';

interface AcademicSectionProps {
  onDemandCourseDropDateDaysAfterStartDate?: number;
  onDemandCourseEndDateDaysAfterStartDate?: number;
}

export function OnDemandDatesSection({
  onDemandCourseDropDateDaysAfterStartDate,
  onDemandCourseEndDateDaysAfterStartDate
}: AcademicSectionProps) {
  const t = useTranslate('shared-admin-ui.CourseForm.onDemandDates');
  const id = useId();

  const fakeDropDateId = `fakeDropDate${  id}`;
  const fakeEndDateId = `fakeEndDate${  id}`;

  const onDemand = useWatch({ name: 'onDemand' });

  if (!onDemand) {
    return null;
  }

  return (
    <ContentSection title={t('title')}>
      <FormFieldWrapper
        id={fakeDropDateId}
        label={t('onDemandCourseDropDateDaysAfterStartDate.label')}
      >
        <Input
          readOnly
          id={fakeDropDateId}
          value={getLastDropDateMessageForOnDemandCourseEnrollment(onDemandCourseDropDateDaysAfterStartDate)}
        />
      </FormFieldWrapper>
      <FormFieldWrapper
        id={fakeEndDateId}
        label={t('onDemandCourseEndDateDaysAfterStartDate.label')}
        helpText={t('onDemandCourseEndDateDaysAfterStartDate.helpText')}
      >
        <Input
          readOnly
          id={fakeEndDateId}
          value={getEndDateMessageForOnDemandCourseEnrollment(onDemandCourseEndDateDaysAfterStartDate)}
        />
      </FormFieldWrapper>
    </ContentSection>
  );
}
