// https://medium.com/swlh/how-to-build-a-multilingual-website-in-next-js-2924eeb462bc

import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

interface TProps {
  label: string;

  // eslint-disable-next-line
  [key: string]: any;
}

/**
 * This React component can be used as an alternative to `useTranslate()` hook.
 */
export function T({ label, ...rest }: TProps) {
  // https://formatjs.io/docs/react-intl/components/
  // A simple `t()` call would work too, but it wouldn't support advanced formatting
  // like plurals, dates, number formatting, React element "tags" inside messages, etc.
  // return t(children, rest);
  return <FormattedMessage id={label} values={rest}/>;
}

T.propTypes = {
  label: PropTypes.string.isRequired
};
