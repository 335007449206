import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { FormSubmitAnnouncer } from '@acadeum/core-ui';
import { useHttpErrorHandler } from '@acadeum/redux';
import { AppLoading, toast } from '@acadeum/ui';

import actions from '../actions';
import { useGetActionRequiredCountsQuery } from '../api/actionRequiredCounts';
import CompleteFinancialOnboarding from '../components/CompleteFinancialOnboarding';
import { DefaultTemplate } from '../components/DefaultTemplate';
import LoginModal from '../components/LoginModal';
import NotificationModal from '../components/NotificationModal';
import RequestPasswordResetModal from '../components/RequestPasswordResetModal';
import ResetPasswordModal from '../components/ResetPasswordModal';
import SiteIcon from '../components/SiteIcon';
import StripeConnectAgreementModal from '../components/StripeConnectAgreementModal';


import userHasPermission from '../helpers/userHasPermission';
import useAuthentication from '../hooks/useAuthentication';


import './App.sass';

const {
  onNotificationDisplayed,
  getFinancialSettingsStatus,
  goto
} = actions;

export default function App({
  children
}) {
  const { user, isAuthenticationLoading } = useSelector(state => state.auth);
  const notification = useSelector(state => state.notificationModal.notification);

  useHttpErrorHandler({
    useAccountCenterLoginUrl: true,
    warn: (content) => toast.warn(content),
    redirect: (url) => goto(url)
  });

  useGetActionRequiredCountsQuery(null, {
    skip: !user,
    // Refresh notifications every 30 minutes.
    // Could be "every minute" but I guess that would put
    // some unnecessary load on the database.
    // How much extra load? Didn't estimate.
    pollingInterval: 30 * 60 * 1000
  });

  // eslint-disable-next-line no-unused-vars
  const unusedRerenderFlag = useSelector(state => state.app.rerenderFlag);

  const [isPasswordResetLink, setIsPasswordResetLink] = useState();

  useEffect(() => {
    if (user) {
      // Load global constants that affect the UI
      if (userHasPermission(user, 'paymentMethod:read', { orgId: user.institution.id })) {
        getFinancialSettingsStatus();
      }
    }
  }, [user]);

  const { isAccessTokenInUrl } = useAuthentication();

  return (
    <div>
      <SiteIcon/>

      {isAuthenticationLoading && (
        <AppLoading initial/>
      )}

      {/* Form submission status announcer for screen readers. */}
      <FormSubmitAnnouncer/>

      {/* Login Modal */}
      <LoginModal/>

      {/* Reset Password Modal */}
      <ResetPasswordModal setIsPasswordResetLink={setIsPasswordResetLink}/>

      {/* Request Password Reset Modal */}
      <RequestPasswordResetModal/>

      {/* Complete Financial Onboarding Modal */}
      <CompleteFinancialOnboarding/>

      {/* Notification messages */}
      <NotificationModal
        notification={notification}
        onNotificationDisplayed={onNotificationDisplayed}
      />

      {/* This is required for stripe custom accounts https://stripe.com/docs/connect/updating-accounts#tos-acceptance*/}
      {user && (
        <StripeConnectAgreementModal/>
      )}

      {/* The page. */}
      <DefaultTemplate>
        {React.cloneElement(children, {
          isPasswordResetLink,
          isAccessTokenInUrl
        })}
      </DefaultTemplate>
    </div>
  );
}

App.propTypes = {
  children: PropTypes.node
};

// The "base" website `<meta/>` tags.
// All pages' `<meta/>` tags are applied on top of these `<meta/>` tags.
App.meta = () => ({
  title: 'Acadeum Course Share | Academic Sharing Platform',
  description: 'Acadeum helps higher education to better meet student needs by sharing access to online courses, tuition revenue, and best practices.',
  image: 'https://dliov3t26vp8o.cloudfront.net/acadeum/banners/Acadeum.Logo.Social.Sharing.Banner.1200x628.png',
  locale: 'en_US'
});
