import { isFileUrl } from '@acadeum/helpers';
import { DocumentViewIcon, LinkIcon } from '@acadeum/icons';
import { Accordion, Link, Text } from '@acadeum/ui';

export function FacultyCredentialsSection({
  course
}) {
  const credentials = course.facultyCredentials;

  const hasCredentials = Boolean(credentials.length);

  let content;
  if (hasCredentials) {
    content = (
      credentials.map((credential, index) => (
        <div key={index}>
          <Link external to={credential.url}>
            {isFileUrl(credential.url) ? (
              <DocumentViewIcon className="control-icon control-icon--left"/>
            ) : (
              <LinkIcon className="control-icon control-icon--left"/>
            )}
            {credential.name}
          </Link>
        </div>
      ))
    );
  } else {
    content = 'No faculty credentials have been added to this course yet.';
  }

  return (
    <Accordion title="Faculty Credentials" defaultOpen={hasCredentials}>
      <Text as="div" color="grey">
        {content}
      </Text>
    </Accordion>
  );
}
